import { takeEvery, fork, put, all, call, } from "redux-saga/effects";
import Parse, { idempotency } from "parse";
import * as LeavesHelper from "../../../helpers/ParseHelpers/leaves_helper";
import * as actionTypes from "./actionTypes";
import * as leaveModuleActions from "./actions";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function* getLeaveModuleForSchoolIdSaga({ payload: { filter } }) {
  try {
    const response = yield call(LeavesHelper.getLeaveModuleForSchoolId, filter);
    if (response) {
      yield put(leaveModuleActions.updateLeaveModule(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* editLeaveDetailsSaga({ payload: { leaves } }) {
  try {
    const response = yield call(LeavesHelper.editLeaveDetails, leaves);
    if (response) {
      yield put(leaveModuleActions.updateLeaveModule(response));
      response.pin();
    }
  } catch (error) {
  }
}

function* getLeavePoliciesForSchoolIdSaga({ payload: {ownerId, ownerType}  }) {
  // console.log("SchoolId", schoolId)
  // console.log("ID:", id);
  try {
    const response = yield call(LeavesHelper.getLeavePoliciesForSchoolId,ownerId, ownerType);
    if (response) {
      yield put(leaveModuleActions.updateLeavePolicy(response));

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getLeavePolicyItemsForPolicyIdSaga({ payload: { policyId } }) {
  try {
    const response = yield call(LeavesHelper.getLeavePolicyItems, policyId)
    if (response) {
      yield put(leaveModuleActions.updateLeavePolicyItemsForPolicyId(response))
      // console.log("LeavePoliciesItemsSaga", response)
    }
  } catch (err) {
    console.log(err)
  }
}

function* getPayrollCategoriesForOwnerIdSaga({ payload: { ownerId, ownerType } }) {
  try {
    const response = yield call(LeavesHelper.getPayrollCategoriesForOwnerId, ownerId, ownerType);
    if (response) {
      yield put(leaveModuleActions.updatePayrollCategories(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* deletePayrollCategorySaga({ payload: { payRollCategories } }) {
  try {
    const response = yield call(LeavesHelper.deletePayrollCategory, payRollCategories);
    if (response) {
      yield put(leaveModuleActions.updatePayrollCategories([response]));
      response.unPin();
      toastr.success("Successfully Deleted", { timeOut: 2000 });
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getMasterPayslipForTeacherId({ payload: { teacherId } }) {
  try {
    const response = yield call(LeavesHelper.getMasterPayslipForTeacherId, teacherId);
    yield put(leaveModuleActions.updateMasterPayslipForTeacherId(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getHolidayListSaga({ payload: { ownerId, ownerType } }) {
  // console.log("Saga - ownerId:", ownerId, "ownerType:", ownerType);
  try {
    const response = yield call(LeavesHelper.getHolidayList, ownerId, ownerType);
    if (response) {
      yield put(leaveModuleActions.updateHolidayList(response));
      // console.log("SagaResponse", response)
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getMasterPayslipItemsForMasterPayslipId({ payload: { masterPayslipId } }) {
  try {
    const response = yield call(LeavesHelper.getMasterPayslipItemsForMasterPayslipId, masterPayslipId);
    yield put(leaveModuleActions.updateMasterPayslipItemsForMasterPayslipId(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getPayslipForMonth({ payload: { teacherId, month } }) {
  try {
    const response = yield call(LeavesHelper.getPayslipForMonth, teacherId, month);
    yield put(leaveModuleActions.updatePayslipForMonth(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getPayslipForId({ payload: { teacherId, payslipId } }) {
  try {
    const response = yield call(LeavesHelper.getPayslipForId, teacherId, payslipId);
    yield put(leaveModuleActions.updatePayslipForId(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getPayslipItemsFoPayslipId({ payload: { payslipId } }) {
  try {
    const response = yield call(LeavesHelper.getPayslipItemsForPayslipId, payslipId);
    yield put(leaveModuleActions.updatePayslipItemsForPayslipId(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getPayslipItemsFoPayslipIds({ payload: { payslipIds } }) {
  try {
    const response = yield call(LeavesHelper.getPayslipItemsForPayslipIds, payslipIds);
    yield put(leaveModuleActions.updatePayslipItemsForPayslipIds(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getPayslipsForMonthAndSchoolId({ payload: { schoolId, month } }) {
  try {
    const response = yield call(LeavesHelper.getPayslipsForMonthAndSchoolId, schoolId, month);
    yield put(leaveModuleActions.updatePayslipsForMonthAndSchoolId(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getMasterPayslipsForOwnerIdAndOwnertype({ payload: { ownerId, ownerType } }) {
  try {
    const response = yield call(LeavesHelper.getMasterPayslipsForOwnerIdAndOwnertype, ownerId, ownerType);
    yield put(leaveModuleActions.updateMasterPayslipsForOwnerIdAndOwnertype(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* editPayslip({ payload: { payslip } }) {
  try {
      const response = yield call(LeavesHelper.editPayslip, payslip);
      if (response === "Error") {
          toastr.error("Please try again", { timeOut: 3000 });
      } else
          if (response) {
              yield put(leaveModuleActions.updatePayslipsForMonthAndSchoolId(response));
              response.pin();
              toastr.success("Payslip sent to the teacher successfully.", { timeOut: 3000 });
          } else {
          }
  } catch (error) {

  }
}

export function* watchgetLeaveModuleForSchoolIdSaga() {
  yield takeEvery(actionTypes.GET_LEAVE_MODULE_FOR_OWNER_ID, getLeaveModuleForSchoolIdSaga);
}

export function* watchgetLeavePolicysForSchoolIdSaga() {
  yield takeEvery(actionTypes.GET_LEAVE_POLICY, getLeavePoliciesForSchoolIdSaga);
}

export function* watcheditLeaveDetailsSaga() {
  yield takeEvery(actionTypes.EDIT_LEAVE_DETAILS, editLeaveDetailsSaga);
}

export function* watchGetLeavePolicyItems() {
  yield takeEvery(actionTypes.GET_LEAVE_POLICY_ITEMS_FOR_POLICY_ID, getLeavePolicyItemsForPolicyIdSaga);
}

export function* watchgetPayrollCategoriesForOwnerIdSaga() {
  yield takeEvery(actionTypes.GET_LEAVE_CATEGORIES_FOR_OWNER_ID, getPayrollCategoriesForOwnerIdSaga);
}

export function* watchdeletePayrollCategorySaga() {
  yield takeEvery(actionTypes.DELETE_PAYROLL_CATEGORIES, deletePayrollCategorySaga);
}

export function* watchGetMasterPayslipForTeacherId() {
  yield takeEvery(actionTypes.GET_MASTER_PAYSLIP_FOR_TEACHER_ID, getMasterPayslipForTeacherId);
}

export function* watchGetMasterPayslipItemsForMasterPayslipId() {
  yield takeEvery(actionTypes.GET_MASTER_PAYSLIP_ITEMS_FOR_MASTER_PAYSLIP_ID, getMasterPayslipItemsForMasterPayslipId);
}

export function* watchGetPayslipForMonth() {
  yield takeEvery(actionTypes.GET_PAYSLIP_FOR_MONTH, getPayslipForMonth);
}

export function* watchGetPayslipItemsForPayslipId() {
  yield takeEvery(actionTypes.GET_PAYSLIP_ITEMS_FOR_PAYSLIP_ID, getPayslipItemsFoPayslipId);
}

export function* watchGetPayslipsForMonthAndSchoolId() {
  yield takeEvery(actionTypes.GET_PAYSLIPS_FOR_SCHOOL_ID_AND_MONTH, getPayslipsForMonthAndSchoolId);
}

export function* watchGetPayslipForId() {
  yield takeEvery(actionTypes.GET_PAYSLIP_FOR_ID, getPayslipForId);
}

export function* watchGetMasterPayslipsForOwnerIdAndOwnertype() {
  yield takeEvery(actionTypes.GET_PAYSLIPS_FOR_OWNER_ID_AND_OWNER_TYPE, getMasterPayslipsForOwnerIdAndOwnertype);
}
export function* watchgetHolidayListSaga() {
  yield takeEvery(actionTypes.GET_HOLIDAY_LIST, getHolidayListSaga);
}
export function* watchGetPayslipItemsForPayslipIds() {
  yield takeEvery(actionTypes.GET_PAYSLIP_ITEMS_FOR_PAYSLIP_IDS, getPayslipItemsFoPayslipIds);
}
export function* watchEditPayslip() {
  yield takeEvery(actionTypes.EDIT_PAYSLIP, editPayslip);
}

  function* LeavesSaga() {
    yield all([
      fork(watchgetLeaveModuleForSchoolIdSaga),
      fork(watchgetLeavePolicysForSchoolIdSaga),
      fork(watcheditLeaveDetailsSaga),
      fork(watchGetLeavePolicyItems),
      fork(watchgetPayrollCategoriesForOwnerIdSaga),
      fork(watchdeletePayrollCategorySaga),
      fork(watchGetMasterPayslipForTeacherId),
      fork(watchGetMasterPayslipItemsForMasterPayslipId),
      fork(watchGetPayslipForMonth),
      fork(watchGetPayslipForId),
      fork(watchGetPayslipItemsForPayslipId),
      fork(watchGetPayslipsForMonthAndSchoolId),
      fork(watchGetMasterPayslipsForOwnerIdAndOwnertype),
      fork(watchgetHolidayListSaga),
      fork(watchGetPayslipItemsForPayslipIds),
      fork(watchEditPayslip),
    ]);
  }

  export default LeavesSaga;