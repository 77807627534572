import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Media, Table} from "reactstrap";
//Import Star Ratings
import StarRatings from 'react-star-ratings';
import { Element, animateScroll as scroller } from 'react-scroll'

import { getParentsForIds } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const RatingCardsDetails = (props) => {


    const handleCardTap = (id) => {
        props.handleCardTap(id)
    }

    useEffect(() => {
        if (props.selectedCardNumber != null) {
            scroller.scrollTo('ratingCard' + props.selectedCardNumber, {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -100, // Scrolls to element + 50 pixels down the page

            })
        }
    }, [props.selectedCardNumber]);


    const getParentNameForId = (id) => {
        var valueToReturn = ""
        if (props.parents != null && props.parents.length > 0) {
            var parentObjs = props.parents.filter(parent => parent.id === id)
            if (parentObjs != null && parentObjs.length > 0) {
                valueToReturn = parentObjs[0].attributes.Name ?? ""
            }

        }
        return (valueToReturn)

    }

    useEffect(() => {
        if (props.allParentIds != null && props.allParentIds.length > 0) {
            props.getParentsForIds(props.allParentIds)
        }
    }, [props.allParentIds]);

    return (
        <React.Fragment>

            {
                props.ratingCardList.map((ratingCard, key) =>

                    <Col xl="12" sm="6" key={key}>
                        <Element name={"ratingCard" + key}>
                            <Card>
                                <CardBody>

                                    <Media>
                                        <div className='d-flex'>
                                            <div className="avatar-md m-2">
                                                <span className="avatar-title rounded-circle bg-light text-primary font-size-16" style={{ width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <i className="bx bx-buildings" style={{ fontSize: "48px" }}></i>
                                                </span>
                                            </div>

                                            <Media className="overflow-hidden" body>
                                                <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">{ratingCard.name}</Link></h5>
                                                <p className="text-muted mb-4">{ratingCard.paramDisplayName ?? ratingCard.description}</p>

                                                {ratingCard.scaleType === 1 && <h5><StarRatings
                                                    rating={parseFloat(ratingCard.ratingValue.toFixed(1))}
                                                    starRatedColor="#F1B44C"
                                                    starEmptyColor="#2D363F"
                                                    numberOfStars={5}
                                                    name='rating'
                                                    starDimension="14px"
                                                    starSpacing="3px"
                                                />      {parseFloat(ratingCard.ratingValue.toFixed(1))}</h5>}

                                                {ratingCard.scaleType === 3 && <span><h5><i className="fas fa-thumbs-up text-warning"></i>{" " + ratingCard.upCount}
                                                    <i className="ml-2 fas fa-thumbs-down text-warning"></i>{" " + ratingCard.downCount}</h5></span>
                                                }

                                                <div className="table-responsive" style={{ width: '800px' }}>
                                                    <Table className="table mt-4">

                                                        <thead className="thead-light">
                                                            <tr>

                                                                <th>Name(Father/Mother)</th>

                                                                <th>Rating</th>

                                                                <th>Created On</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ratingCard.ratings.map((rating, i) =>
                                                                <tr key={"_user_" + i} >

                                                                    <td> {getParentNameForId(rating.attributes.createdBy)}</td>
                                                                    <td>
                                                                        {rating.attributes.value}
                                                                    </td>
                                                                    <td>
                                                                        {rating.attributes.createdAt.toLocaleDateString()}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Media>
                                            <div onClick={() => {
                                                handleCardTap(ratingCard.id)
                                            }} className="avatar-md mr-3 float-right">
                                                <span className="avatar-title rounded-circle bg-white text-secondary font-size-16">
                                                    <div style={{ fontSize: "38px" }}><i className="mdi mdi-close"></i></div>
                                                </span>
                                            </div>
                                        </div>
                                    </Media>


                                </CardBody>
                            </Card>
                        </Element>

                    </Col>
                )
            }
        </React.Fragment>

    );
}

const mapStatetoProps = state => {
    const { parents } = state.Parent;
    return { parents };
}
export default withRouter(connect(mapStatetoProps, { getParentsForIds })(RatingCardsDetails));