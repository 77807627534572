import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  getParentFromParse,
  deleteParentFromParse,
  getParentsForIds,
  updateParentToParse,
} from "../../../helpers/ParseHelpers/parent_helper";
import * as Constants from "../../../Constents";
import * as ParentHelper from "../../../helpers/ParseHelpers/parent_helper";
import * as ParentActions from "./actions";
import * as actionTypes from "./actionTypes";
import Parse from "parse";

function* getAParent({ payload: { parentId } }) {
  try {
    const response = yield call(getParentFromParse, parentId);
    localStorage.setItem("parentId", JSON.stringify(response));
    yield put(addOrupdateParent(response));
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* updateSelectedParent({ payload: { parentObj } }) {
  try {
    const response = yield call(updateParentToParse, parentObj);
    if(response){
      yield put(ParentActions.updateParents([response]))
    }
  } catch (error) {
    
  }
}
function* deleteAParent({ payload: { parentId } }) {
  try {
    const response = yield call(deleteParentFromParse, parentId);
    localStorage.removeItem("parentId");
    if (response) {
      yield put(ParentActions.deleteParent(response));
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getParentsFromParse({ payload: { parentIds } }) {
  try {
    var key = Constants.PARENTS_UPDATED_TIME + "_";
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      ParentHelper.getParentsForIds,
      parentIds,
      null,
      true
    );
    yield put(ParentActions.updateParents(localResponse));

    const serverResponse = yield call(
      ParentHelper.getParentsForIds,
      parentIds,
      null,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(ParentActions.updateParents(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getParentsForFamilyIds({ payload: { familyIds ,fromLocal} }) {
  try {
    if (fromLocal) {
      const localResponse = yield call(
        ParentHelper.getParentsForFamilyIds,
        familyIds,true
      );
      if (localResponse.length > 0) {
        yield put(ParentActions.updateParents(localResponse));
      }
    }
    const serverResponse = yield call(
      ParentHelper.getParentsForFamilyIds,
      familyIds
    );
    if (serverResponse.length > 0) {
      yield put(ParentActions.updateParents(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* addParentsToDB({ payload: { parents } }) {
  try {
    const response = yield call(ParentHelper.addParentsToParse, parents);
    if (response.length > 0) {
      yield put(ParentActions.updateParents(response));
    }
  } catch (error) {

  }
}
function* getParentsForFamilyId({ payload: { familyId } }) {
  try {
    const serverResponse = yield call(
      ParentHelper.getParentsForFamilyId,
      familyId
    );

    if (serverResponse && serverResponse.length > 0) {
      yield put(ParentActions.setSelectedParents(serverResponse));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetParent() {
  yield takeEvery(actionTypes.ADD_OR_UPDATE_PARENT, getAParent);
}
export function* watchGetParentsForFamilyId() {
  yield takeEvery(actionTypes.GET_PARENTS_FOR_FAMILY_ID, getParentsForFamilyId);
}
export function* watchGetParents() {
  yield takeEvery(actionTypes.GET_PARENTS, getParentsFromParse);
}
export function* watchDeleteParent() {
  yield takeEvery(actionTypes.DELETE_PARENT, deleteAParent);
}
export function* watchParentUpdate() {
  yield takeEvery(actionTypes.UPDATE_A_PARENT, updateSelectedParent);
}
export function* watchGetParentsForFamilyIds() {
  yield takeEvery(
    actionTypes.GET_PARENTS_FOR_FAMILY_IDS,
    getParentsForFamilyIds
  );
}
export function* watchAddParentToDB() {
  yield takeEvery(actionTypes.ADD_PARENTS_TO_DB, addParentsToDB);
}

function* ParentSaga() {
  yield all([
    fork(watchGetParents),
    fork(watchGetParent),
    fork(watchDeleteParent),
    fork(watchParentUpdate),
    fork(watchGetParentsForFamilyIds),
    fork(watchAddParentToDB),
    fork(watchGetParentsForFamilyId),
  ]);
}
export default ParentSaga;
