
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip, Input, Dropdown, DropdownMenu, DropdownItem, Form, Container, Label, Card, CardBody, FormGroup } from "reactstrap";
import * as WhiteLable from "../../components/Common/WhiteLable";
import QRCode from 'qrcode'
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const EnquiryResharePopup = (props) => {

    const HOST_NAME = WhiteLable.getHost();
    const RESHARE_ENQUIRY_FORM = HOST_NAME + "/enquiry-refill/school/" + props.enquiryObject.attributes.schoolId + props.enquiryObject.id;

    const [loading, setLoading] = useState(false)
    const [reshareEnquiry, setReshareEnquiry] = useState(false)
    const [enquiryReshareQrCode, setEnquiryReshareQrCode] = useState(undefined)


    const handleSaveEnquiryFormReshareDate = (enquiry) => {
        setLoading(true)
        enquiry.set('enquiryFormResharedOn', new Date);

        enquiry.save()
            .then((enquiryObject) => {
                // console.log(enquiryObject)
                setLoading(false)
                setReshareEnquiry(true)
            })
            .catch((error) => {

            });
    }

    const getEnquiryLink = () => {
        return (
            WhiteLable.getHost() + "/enquiry-refill/school/" +
            props.enquiryObject.attributes.schoolId +
            "/" + props.enquiryObject.id
        )
    }

    function copyEnquiryLink(str, event) {
        document.oncopy = function (event) {
            event.clipboardData.setData("Text", str);
            event.preventDefault();
        };
        document.execCommand("Copy");
        document.oncopy = undefined;
        toastr.success("Link copied to clipboard", { timeOut: 1000 });
    }


    useEffect(() => {
        reshareEnquiryFormQr()
    }, []);

    function reshareEnquiryFormQr() {
        QRCode.toDataURL(RESHARE_ENQUIRY_FORM, { errorCorrectionLevel: 'H' })
            .then(url => {
                setEnquiryReshareQrCode(url)
            })
            .catch(err => {
                console.error(err)
            })
    }
    // console.log(props.enquiryObject)

    return (
        <div>
            <Modal isOpen={props.showEnquiryResharePopup}>
                <ModalHeader
                    toggle={() => {
                        props.closeEnquiryResharePopup()
                    }}
                >
                    Resharing Enquiry Form
                </ModalHeader>
                <ModalBody>
                    {reshareEnquiry ? (
                        <div style={{ position: 'relative', zIndex: 1 }}>
                            <p className='ml-4 '>
                                {"Student Name: "}<strong><span>{props.enquiryObject.attributes.kidName}</span></strong>
                            </p>
                            <Row>
                                <Col lg={10}>
                                    <p
                                        disabled
                                        type="text"
                                        className=" m-2 ml-4 text-primary"
                                        id="linkarea"
                                    >
                                        {getEnquiryLink()}
                                    </p>
                                </Col>
                                <Col
                                    lg={2}
                                    id="enquiryLink"
                                    onClick={(e) => {
                                        copyEnquiryLink(getEnquiryLink());
                                    }}
                                >
                                    <div className="px-2 border">
                                        <div className="d-flex flex-column align-items-center">
                                            <h1
                                                style={{ cursor: "pointer" }}
                                                className="text-primary"
                                            >
                                                <i className="bx bxs-copy"></i>
                                            </h1>
                                        </div>
                                        <p className='' style={{ cursor: "pointer", marginTop: '30px' }}>copy</p>
                                        <UncontrolledTooltip
                                            placement="auto"
                                            target="enquiryLink"
                                        >
                                            copy to clipboard
                                        </UncontrolledTooltip>
                                    </div>

                                </Col>
                            </Row>
                            <Row>
                                <div className="col-md-12 d-flex justify-content-center align-items-center" style={{ marginLeft: '-15px' }}>
                                    <img className="img-responsive" src={enquiryReshareQrCode} style={{ width: "100px", marginTop: "1%" }} />
                                </div>
                            </Row>
                        </div>
                    ) : (
                        <p className="m-4">Are you sure you want to reshare the Enquiry form<br /><br></br>
                            <b>Note:</b> This link will be valid for the next one week or until the parent submits. </p>
                    )}
                </ModalBody>

                <ModalFooter>
                    {reshareEnquiry && !loading && <Row className='d-flex'>
                        <Col>
                            {(!props.enquiryObject.attributes.fatherMobile || !props.enquiryObject.attributes.motherMobile) && <button
                                type="button"
                                className="btn btn-success btn-sm m-1"
                                onClick={() => {
                                    let schoolName = props.selectedSchool && props.selectedSchool.attributes.Name
                                    let link = getEnquiryLink();
                                    const message = `Dear Parent,\n\n${schoolName} Preschool has shared an Enquiry link for you to refill.\n\nPlease click the link below to fill it:\n${link}\n\nThis link will be valid for the next one week, so please complete it before then.\n\n ${WhiteLable.getAppName()}`;
                                    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
                                    window.open(whatsappUrl);
                                    // web.whatsapp.com
                                }}
                            ><a className="bx bxl-whatsapp text-white"></a>Send via Whatsapp</button>}
                            {props.enquiryObject.attributes.motherMobile && <button
                                type="button"
                                className="btn btn-success btn-sm m-1"
                                onClick={() => {
                                    let phoneNumber = props.enquiryObject.attributes.motherMobile;
                                    let schoolName = props.selectedSchool && props.selectedSchool.attributes.Name
                                    let parentName = props.enquiryObject.attributes.motherName ?? ' ';
                                    let link = getEnquiryLink();
                                    const message = `Dear ${parentName},\n\n${schoolName} Preschool has shared an enquiry link for you to refill.\n\nPlease click the link below to fill it:\n${link}\n\nThis link will be valid for the next one week, so please complete it before then.\n\nPowered by ${WhiteLable.getAppName()}`;
                                    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
                                    window.open(whatsappUrl);
                                    // web.whatsapp.com
                                }}
                            ><a className="bx bxl-whatsapp text-white"></a>Mother</button>}
                            {props.enquiryObject.attributes.fatherMobile && <button
                                type="button"
                                className="btn btn-success btn-sm m-1"
                                onClick={() => {
                                    let phoneNumber = props.enquiryObject.attributes.fatherMobile;
                                    let schoolName = props.selectedSchool && props.selectedSchool.attributes.Name
                                    let parentName = props.enquiryObject.attributes.fatherName ?? ' ';
                                    let link = getEnquiryLink();
                                    const message = `Dear ${parentName},\n\n${schoolName} Preschool has shared an enquiry link for you to refill.\n\nPlease click the link below to fill it:\n${link}\n\nThis link will be valid for the next one week, so please complete it before then.\n\nPowered by ${WhiteLable.getAppName()}`;
                                    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
                                    window.open(whatsappUrl);
                                    // web.whatsapp.com
                                }}
                            ><a className="bx bxl-whatsapp text-white"></a>Father</button>}
                            <button
                                type="button"
                                className="btn btn-info btn-sm"
                                onClick={() => {

                                }}
                            >
                                Send Link To Parent
                            </button>
                        </Col>
                    </Row>}
                    {!loading ? (<Row>
                        <Col>
                            <div className="d-flex justify-content-end">
                                {!reshareEnquiry && <button
                                    type="button"
                                    onClick={() => {
                                        props.closeEnquiryResharePopup()
                                    }}
                                    className="btn btn-secondary waves-effect m-1"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>}
                                {!reshareEnquiry && <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light m-1"
                                    onClick={() => {
                                        handleSaveEnquiryFormReshareDate(props.enquiryObject);
                                    }}
                                >
                                    Reshare
                                </button>}
                            </div>
                        </Col>
                    </Row>
                    ) : (
                        <button type="button" className="btn btn-light waves-effect">
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                            Resharing
                        </button>
                    )}
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default EnquiryResharePopup








