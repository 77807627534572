import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Input, Label, FormGroup } from "reactstrap";
import Select from 'react-select';
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelecetdSchool } from '../../store/actions';

const NewPayrollTemplate = (props) => {

  const match = matchPath(props.history.location.pathname, {
    path: "/payroll-new-template/:schoolId",
    exact: true,
    strict: false,
});

const schoolId = match && match.params.schoolId;
  const [title, setTitle] = useState('');
  const [tableData, setTableData] = useState([{
    category: { value: 'Basic', label: 'Basic' },
    amountType: { value: 'Amount', label: 'Amount' },
    amount: '',
  }]);

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
        props.setSelecetdSchool(schoolId);
    }
}, [props.selectedSchool, schoolId]);

  const categoryOptions = [
    { value: 'Basic', label: 'Basic' },
    { value: 'HRA', label: 'HRA' },
    { value: 'Bonus', label: 'Bonus' },
    { value: 'Tax', label: 'Tax' },
    { value: 'Insurance', label: 'Insurance' },
    { value: 'Provident Fund', label: 'Provident Fund' },
    { value: 'Travel', label: 'Travel' },
    { value: 'Medical', label: 'Medical' },
    { value: 'Other', label: 'Other' },
  ];

  const amountTypeOptions = [
    { value: 'Amount', label: 'Amount' },
    { value: 'Percentage', label: 'Percentage' },
  ];

  const handleCategoryAdd = () => {
    setTableData([...tableData, { category: null, amountType: null, amount: 0 }]);
  };

  const handleCategoryRemove = (index) => {
    const updatedTableData = tableData.filter((_, i) => i !== index);
    setTableData(updatedTableData);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Row>
          <Col className="d-flex align-items-start">
            <div style={{ cursor: "pointer" }} onClick={() => { window.history.back() }}>
              <ul className="list-unstyled">
                <div className=''>
                  <i className="bx bx-chevron-left h1 text-primary"></i>
                </div>
              </ul>
            </div>
            <div className='m-2'>
              <h4 className="font-size-18">New Payroll Template</h4>
            </div>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Row>
              <Col lg="5">
                <FormGroup>
                  <Label><strong>Title</strong></Label>
                  <Input
                    type="text"
                    className='form-control form-control sm'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)} 
                  />
                </FormGroup>
              </Col>
            </Row>

            {tableData.map((row, index) => (
              <React.Fragment key={index}>
                <Row>
                  <Col lg="3" className="d-flex align-items-center">
                    <FormGroup className="w-100">
                      <Label>Select Category</Label>
                      <Select
                        options={categoryOptions}
                        value={row.category}
                        onChange={(selectedCategory) => {
                          const updatedTableData = [...tableData];
                          updatedTableData[index].category = selectedCategory;
                          setTableData(updatedTableData); 
                        }}
                        placeholder="Select Category"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="3">
                    <FormGroup>
                      <Label>Select Amount Type</Label>
                      <Select
                        options={amountTypeOptions}
                        value={row.amountType}
                        onChange={(selectedAmountType) => {
                          const updatedTableData = [...tableData];
                          updatedTableData[index].amountType = selectedAmountType;
                          setTableData(updatedTableData); 
                        }}
                        placeholder="Select Amount Type"
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="2">
                    <FormGroup>
                      <Label>Amount</Label>
                      <div className="d-flex align-items-center">
                        <Input
                          type="number"
                          value={row.amount}
                          onChange={(e) => {
                            const updatedTableData = [...tableData];
                            updatedTableData[index].amount = e.target.value;
                            setTableData(updatedTableData); 
                          }}
                          placeholder="Enter Amount"
                        />
                        <span className="ml-2">{row.amountType?.value === 'Amount' ? '₹' : '%'}</span>
                      </div>
                    </FormGroup>
                  </Col>

                  <Col lg="1">
                    <Label className="ml-2 float-right text-danger mt-0" onClick={() => handleCategoryRemove(index)} style={{ fontSize: "20px", cursor: "pointer" }}>
                    {tableData.length > 1 && <i className="bx bx-minus-circle"></i>}
                    </Label>
                  </Col>
                </Row>
              </React.Fragment>
            ))}

            <Row>
              <Col lg="12" className="text-end mt-3">
                <Button color="primary" onClick={handleCategoryAdd}>
                  <i className="bx bx-plus-circle"></i> Add Category
                </Button>
              </Col>
            </Row>

            <Row>
              <Col lg="12" className="text-end mt-3">
                <Button color="primary">
                  Save Template
                </Button>
              </Col>
            </Row>

          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  return { selectedSchool };
};

export default connect(mapStatetoProps, {
  setSelecetdSchool,
})(withRouter(withNamespaces()(NewPayrollTemplate)));
