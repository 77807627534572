import React, { useEffect, useState } from "react";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Parse from "parse";
import {
    Card, CardBody, CardImg,
    Container,
    Col, Row,
    FormGroup,
    Label,
    Button,
    Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink,
} from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import { setSelecetdSchool, getClassroomsForSchoolId } from "../../store/actions";
import Moment from 'moment';

//Lightbox
import Lightbox from "react-image-lightbox";
import VideoLightbox from "react-image-video-lightbox";
import "react-image-lightbox/style.css";

const Gallery = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/gallery/:id",
        exact: true,
        strict: false,
    });

    const classMatch = matchPath(props.history.location.pathname, {
        path: "/gallery/:id/:classroomId",
        exact: true,
        strict: false,
    });

    const tempMatch = match ?? classMatch;
    const schoolId = tempMatch.params.id;
    const classroomId = classMatch && classMatch.params.classroomId;

    const [fromDate, setFromDate] = useState(new Date());
    const [fromDateError, setFromDateError] = useState(false);
    const [albumData, setAlbumData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isGallery, setisGallery] = useState(false);
    const [photoIndex, setphotoIndex] = useState(0);
    const [displayImages, setDisplayImages] = useState([]);
    const [displayVideos, setDisplayVideos] = useState([]);
    const [showLoadMoreImages, setShowLoadMoreImages] = useState(false);
    const [showLoadMoreVideos, setShowLoadMoreVideos] = useState(false);

    const [showLoadMore, setShowLoadMore] = useState(false);
    const [displayName, setDisplayName] = useState('Gallery');

    const [selctedClassroomId, setSelctedClassroomId] = useState('All');
    const [toggleGallery, setToggleGallery] = useState(false);
    const [imageData, setImageData] = useState([]);
    const [videoData, setVideoData] = useState([]);
    const [activeTab, setActiveTab] = useState("1");


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        props.getClassroomsForSchoolId(schoolId)
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.getClassroomsForSchoolId(schoolId)
    }, [schoolId]);

    useEffect(() => {
        if (classroomId && props.classrooms) {
            for (const cr of props.classrooms) {
                if (cr.id == classroomId) {
                    setDisplayName(cr.attributes.Name + " Gallery");
                    break;
                }
            }
        }
    }, [classroomId, props.classrooms]);

    useEffect(() => {
        handelGetAlbums();
    }, [schoolId]);

    const toggleCustom = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const handelGetAlbums = () => {
        setLoading(true)
        let month = fromDate.getMonth() + 1;
        let year = fromDate.getFullYear();
        if (classMatch || selctedClassroomId != 'All') {
            let classId;
            if (classMatch) {
                classId = classroomId
            } else {
                classId = selctedClassroomId;
            }
            Parse.Cloud.run("getPicturesForClass", {
                classId: classId,
                month: month,
                year: year,
            }).then((response) => {
                initialLoad(response)
            }, (error) => {
                console.log("error" + error.message)
            });
        } else {
            Parse.Cloud.run("getPicturesForSchool", {
                schoolId: schoolId,
                month: month,
                year: year,
            }).then((response) => {
                initialLoad(response)
            }, (error) => {
                console.log("error" + error.message)
            });
        }

    }

    const initialLoad = (response) => {
        setLoading(false)
        // console.log(response)
        let images = [];
        let videos = [];
        for (let i = 0; i < response.length; i++) {
            if (response[i].Image) {
                images.push(response[i]);
            } else if (response[i].Video) {
                videos.push(response[i]);
            }
        }
        setImageData(images);
        setVideoData(videos);

        setDisplayImages(images.slice(0, 36));
        setShowLoadMoreImages(images.length > 36);

        setDisplayVideos(videos.slice(0, 36));
        setShowLoadMoreVideos(videos.length > 36);
        setAlbumData(response);

    }

    const handleLoadMoreImages = () => {
        let newLength = displayImages.length + 36;
        if (imageData.length > newLength) {
            setDisplayImages(imageData.slice(0, newLength));
            setShowLoadMoreImages(true);
        } else {
            setDisplayImages(imageData);
            setShowLoadMoreImages(false);
        }
    };

    const handleLoadMoreVideos = () => {
        let newLength = displayVideos.length + 36;
        if (videoData.length > newLength) {
            setDisplayVideos(videoData.slice(0, newLength));
            setShowLoadMoreVideos(true);
        } else {
            setDisplayVideos(videoData);
            setShowLoadMoreVideos(false);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container>
                    <Breadcrumbs title={displayName} breadcrumbItem="Starter Page" />
                    <Card>
                        <Row>
                            {match && <Col lg={4} xs={6}>
                                <FormGroup className="m-2 p-1">
                                    <Label >
                                        {props.t("Select classroom")} :
                                    </Label>

                                    <select className="form-control mr-2"
                                        value={selctedClassroomId}
                                        onChange={(e) => {
                                            setSelctedClassroomId(e.target.value);
                                        }}
                                    >
                                        <option className="text-primary" value="All" key={0} >All</option>
                                        {props.classrooms.map((val, key) => (<option key={val.id} className={val.attributes.classroomType === 2 ? 'text-danger' : 'text-primary'} value={val.id} >{val.attributes.Name}</option>))}
                                    </select>

                                </FormGroup>

                            </Col>}
                            <Col lg={4} xs={6}>
                                <FormGroup className="m-2 p-1">
                                    <Label >
                                        {props.t("Select Month")} :
                                    </Label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={(date) => {

                                            setFromDateError(false)
                                            setFromDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Month")}
                                        startDate={fromDate}
                                        dateFormat="MMM-yyyy"
                                        required
                                        errorMessage="Please select"
                                        showMonthYearPicker
                                    />
                                </FormGroup>
                                {fromDateError && <p className="text-danger ml-2">Please select month</p>}
                            </Col>
                            <Col lg={3} >
                                <div className="mt-4 mb-1 p-1">
                                    <button
                                        // disabled={loadingState === 2}
                                        onClick={(e, v) => {
                                            let next = true;

                                            if (!fromDate) {
                                                setFromDateError(true);
                                                next = false;
                                            }
                                            if (next) {
                                                handelGetAlbums();
                                            }

                                        }}
                                        className="w-md mt-2  btn btn-primary"
                                    >
                                        {props.t("Apply")}
                                    </button>


                                </div>
                            </Col>
                        </Row>
                    </Card>
                    {videoData.length > 0 && (

                        <Nav pills className="navtab-bg nav-justified mb-2">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({ active: activeTab === "1" })}
                                    onClick={() => toggleCustom("1")}
                                >
                                    {props.t("Photos").toUpperCase()}
                                </NavLink>
                            </NavItem>

                            {videoData.length > 0 && (
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({ active: activeTab === "2" })}
                                        onClick={() => toggleCustom("2")}
                                    >
                                        {props.t("Video").toUpperCase()}
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>)}


                    <Card className="text-center">
                        {!loading && activeTab === "1" && imageData.length > 0 && (
                            <CardBody>
                                <div className="popup-gallery">
                                    <div className="row">
                                        {displayImages.map((album, key) => (
                                            <div className="col-md-2" key={key}>
                                                <img
                                                    src={album.ThumbnailImage ? album.ThumbnailImage._url : album.Image._url}
                                                    alt=""
                                                    className=" rounded mr-4 img-fluid border mb-2"
                                                    style={{ background: "#FFFFFF", height: "25mm", width: "100%", objectFit: "fill" }}
                                                    onClick={() => {
                                                        setisGallery(false);
                                                        setphotoIndex(key);
                                                        setisGallery(true)
                                                    }}

                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </CardBody>
                        )}

                        <div className="align-center">
                            {showLoadMore && <Button
                                style={{ bottom: 0, }}

                                color="primary"
                                className=" p-2 "
                                onClick={() => {
                                    handleLoadMoreImages()
                                }}
                            >
                                Load More...
                            </Button>}
                        </div>

                        {!loading && activeTab === "2" && videoData.length > 0 && (
                            <CardBody>
                                <div className="popup-gallery">
                                    <div className="row">
                                        {displayVideos.map((album, key) => (
                                            <div className="col-md-2" key={key}>
                                                <div style={{ position: 'relative', width: '100%' }}>
                                                    <img
                                                        src={album.ThumbnailImage ? album.ThumbnailImage._url : ''}
                                                        alt=""
                                                        className="img-fluid border mb-2"
                                                        style={{
                                                            display: "block",
                                                            width: "100%",
                                                            height: "auto",
                                                        }}
                                                        onClick={() => {
                                                            setphotoIndex(key);
                                                            setToggleGallery(true);
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            backgroundColor: "rgba(0, 0, 0, 0.6)",
                                                            borderRadius: "50%",
                                                            padding: "6px",
                                                            zIndex: 2,
                                                        }}
                                                    >
                                                        <i className="bx bx-play-circle" onClick={() => {
                                                            setphotoIndex(key);
                                                            setToggleGallery(true);
                                                        }}style={{ color: "#fff", fontSize: "20px" }}></i>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </CardBody>
                        )}
                        {!loading && showLoadMoreVideos && (
                            <div className="align-center">
                                <Button
                                    color="primary"
                                    className="p-2"
                                    onClick={handleLoadMoreVideos}
                                >
                                    Load More Videos...
                                </Button>
                            </div>
                        )}

                        {loading && <CardBody >
                            <Label className="text-success">
                                <i className="bx bx-hourglass bx-spin mr-2"></i> Loading...{" "}
                            </Label>
                        </CardBody>}

                        {!loading && imageData.length === 0 && videoData.length === 0 && <CardBody >
                            <Label className="text-success ">
                                <i> No photos to display for selected month</i>
                            </Label>
                        </CardBody>}



                        <div style={{ height: "400px" }}></div>
                    </Card>
                    {(isGallery && activeTab === "1" && displayImages.length > 0) ? (
                        <Lightbox
                            mainSrc={albumData[photoIndex].Image._url}
                            nextSrc={albumData[(photoIndex + 1) % albumData.length].Image._url}
                            prevSrc={
                                albumData[(photoIndex + albumData.length - 1) % albumData.length].Image._url
                            }
                            enableZoom={true}
                            onCloseRequest={() => { setisGallery(false) }}
                            onMovePrevRequest={() => { setphotoIndex((photoIndex + albumData.length - 1) % albumData.length) }}
                            onMoveNextRequest={() => { setphotoIndex((photoIndex + 1) % albumData.length) }}
                            imageCaption={albumData[photoIndex + 1] && "Date & Time: " + Moment(albumData[photoIndex + 1].DateTime).format('DD-MM-YYYY hh:mm a')}
                        />
                    ) : null}
                    <Modal
                        isOpen={toggleGallery}
                        toggle={() => setToggleGallery(!toggleGallery)}
                        size="lg"
                        centered
                    >
                        <ModalHeader toggle={() => setToggleGallery(!toggleGallery)}>Video</ModalHeader>
                        <ModalBody className="text-center">
                            {activeTab === "2" && displayVideos.length > 0 && (
                                <video
                                    controls
                                    style={{ width: "100%", maxHeight: "500px" }}
                                    src={displayVideos[photoIndex].Video._url}
                                >
                                    Your browser does not support the video tag.
                                </video>
                            )}
                            <div className="mt-2 text-muted">
                                {albumData[photoIndex] &&
                                    "Date & Time: " + Moment(albumData[photoIndex].DateTime).format('DD-MM-YYYY hh:mm a')}
                            </div>
                        </ModalBody>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    return { selectedSchool, classrooms };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool, getClassroomsForSchoolId })(Gallery)));