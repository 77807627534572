import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "reactstrap";
import { matchPath } from "react-router";
import { withRouter, Link } from "react-router-dom";
import AppStore from "../../assets/images/appstore.webp";
import PlayStore from "../../assets/images/playstore.webp";
import StarRatings from "react-star-ratings";
import QRCode from 'qrcode'
import * as WhiteLable from "../../components/Common/WhiteLable";

const AppLogin = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/apps",
        exact: true,
        strict: false,
    });

    const lilTriangleIOS = "https://apps.apple.com/in/app/liltriangle/id956406796"
    const lilTriangleAndroid = "https://play.google.com/store/apps/details?id=com.junior"

    const [isLiltriangle, setIsLiltriangle] = useState(true);
    const [appQRCode, setShowAppQrCode] = useState();

    const [appLinks, setAppLinks] = useState({ android: lilTriangleAndroid, ios: lilTriangleIOS })


    useEffect(() => {

        if (WhiteLable.isSanfortApp()) {
            setAppLinks({
                android: "https://play.google.com/store/apps/details?id=com.sanfort.lilapp",
                ios: "https://apps.apple.com/in/app/sanfort/id6630373153",
            })
            setIsLiltriangle(false)
        } else if (WhiteLable.isJumpStartApp()) {
            setAppLinks({
                android: "https://play.google.com/store/apps/details?id=com.liltriangle.jumpstart",
                ios: "https://apps.apple.com/app/jumpstart-preschool/id6504999981"
            })
            setIsLiltriangle(false)
        } else if (WhiteLable.isMakoonsApp()) {
            setAppLinks({
                android: "https://play.google.com/store/apps/details?id=com.makoons.lilapp",
                ios: "https://apps.apple.com/in/app/makoons/id6742899313"
            })
            setIsLiltriangle(false)
        } else if (WhiteLable.isArtklubApp()) {
            setAppLinks({
                android: "https://play.google.com/store/apps/details?id=com.artklub.lilapp",
                ios: "https://apps.apple.com/us/app/artklub/id6737152283"
            })
            setIsLiltriangle(false)
        } else if (WhiteLable.isCuddlingBeeApp()) {
            setAppLinks({
                android: "",
                ios: ""
            })
            setIsLiltriangle(false)
        }

        formQr(WhiteLable.getAppOneLink())


    }, []);

    function formQr(link) {
        QRCode.toDataURL(link, { errorCorrectionLevel: 'H' })
            .then(url => {
                setShowAppQrCode(url)
            })
            .catch(err => {
                console.error(err)
            })
    }


    return (

        <React.Fragment>

            <Row className="justify-content-center">
                <Col lg={12} className="col-12 mt-0 mb-0 text-center flex-1 content p-0 shadow rounded bg-white position-relative">
                    <Link to="#" className="d-inline-block">
                        <img src={WhiteLable.getAppLogoWithName()} height="70" alt="Logo" className='m-2' />
                    </Link>
                    <div className="mt-0">
                        <h3 className="mt-0 d-flex align-items-center justify-content-center">
                            <i className="bx bx-mobile-alt me-3" style={{ fontSize: '60px', color: 'black' }}></i>
                            Download our App now
                        </h3>
                    </div>
                </Col>

                <section>
                    <div className="flex-1 content p-3 shadow rounded bg-white position-relative">
                        <Container className={"xl mt-10"} >
                            <div className="flex-1 content p-3 shadow rounded bg-white position-relative">
                                <div className="flex-1 content p-3 rounded shadow bg-light p-3"  >
                                    <Row className="justify-content-center">
                                        <Col lg={5} md={4} xs={4} className="text-center py-4">
                                            <a
                                                href={appLinks.ios}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={AppStore} className="m-3" height="50" alt="Download on App Store" /> </a>{" "}<br />
                                            <b><span className="py-4">4.6</span>
                                                <br></br></b>
                                            <StarRatings
                                                items={3}
                                                rating={4.6}
                                                starRatedColor="#F17425"
                                                numberOfStars={5}
                                                name="rating"
                                                starDimension="15px"
                                                starSpacing="1px"
                                            />
                                        </Col>
                                        <Col lg={5} md={4} xs={4} className="text-center py-4">
                                            <a
                                                href={appLinks.android}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={PlayStore} className="m-3" height="50" alt="Download on App Store" /></a><br />
                                            <b>
                                                <span className="py-4">
                                                    <b className='' style={{ marginBottom: '30px' }}>4.8</b>
                                                </span>
                                            </b><br />
                                            <StarRatings
                                                items={3}
                                                rating={4.8}
                                                starRatedColor="#F17425"
                                                numberOfStars={5}
                                                name="rating"
                                                starDimension="15px"
                                                starSpacing="1px"
                                            />
                                        </Col>
                                        <Col lg={5} md={4} xs={4} className="text-center py-3">
                                            <img src={appQRCode} className="mt-1 mb-2" height="255" alt="Scan above QR CODE" /><br />
                                            <h6>Scan & Download App</h6>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Container>
                    </div>
                </section>
            </Row>
        </React.Fragment>
    );
}

export default AppLogin;