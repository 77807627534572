import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Container, Label, Card, CardBody } from "reactstrap";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import {
    getAKid, setSelecetdSchool, getClassroomsForSchoolId, getSubjectsForExamId, getExamForExamId, getExamResultsForExamId,
    getGroupForId, getGroupMapsForGroupId, getTeachersForSchool, getExamResultsForExamIdAndKidId,
    saveGroup, getAllKids
} from "../../store/actions";
import Header from './Header';

const MarksCard = (props) => {

    const matchKid = matchPath(props.history.location.pathname, {
        path: "/marks-card/:schoolId/:examId/:kidId",
        exact: true,
        strict: false,
    });

    const matchGroup = matchPath(props.history.location.pathname, {
        path: "/marks-cards/:schoolId/:examId/:groupId",
        exact: true,
        strict: false,
    });

    const match = matchKid ?? matchGroup

    const kidId = matchKid && matchKid.params.kidId;
    const schoolId = match && match.params.schoolId;
    const examId = match && match.params.examId;
    const groupId = matchGroup && matchGroup.params.groupId;


    const [tableData, setTableData] = useState([]);
    const [selectedExamKids, setSelectedExamKids] = useState([])
    const [kidSubjectList, setKidSubjectList] = useState([])
    const [duplicate, setDuplicate] = useState(false);
    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId])

    useEffect(() => {
        if (kidId) {
            props.getAKid(kidId);
        }
    }, [kidId]);


    useEffect(() => {
        if (schoolId) {
            props.getAllKids(schoolId);
        }
    }, [schoolId])

    useEffect(() => {
        if (examId) {
            props.getSubjectsForExamId(examId);
            props.getExamForExamId(examId);
            if (kidId) {
                props.getExamResultsForExamIdAndKidId(examId, kidId)
            } else {
                props.getExamResultsForExamId(examId)
            }

        }
    }, [examId]);

    useEffect(() => {
        if (groupId) {
            props.getGroupForId(groupId);
            props.getGroupMapsForGroupId(groupId);
        }
    }, [groupId])

    useEffect(() => {

        if (props.groupmaps && props.groupmaps.length > 0 && props.kids) {
            let tempSelectedKidList = []


            for (var i = 0; i < props.groupmaps.length; i++) {
                if (props.groupmaps[i].attributes.groupId === groupId) {
                    let kidObjList = props.kids.filter((kid) =>
                        kid.id === props.groupmaps[i].attributes.userId
                    )
                    if (kidObjList.length > 0) {
                        tempSelectedKidList.push(kidObjList[0]);
                    }
                }
            }
            setSelectedExamKids(tempSelectedKidList)
        }
    }, [props.groupmaps, props.kids]);

    useEffect(() => {
        if (props.subjects && props.subjects.length > 0) {
            let theorySubjects = []
            let practicalSubjects = []
            let subjects = [];
            for (const sub of props.subjects) {
                if (sub.attributes.examId == examId) {
                    if (sub.attributes.theoryMarks) {
                        theorySubjects.push(sub)
                    } else {
                        practicalSubjects.push(sub)
                    }
                }
            }
            for (const s of theorySubjects) {

                let newSubject = {
                    subjectId: uuidv4(),
                    id: s.id,
                    parseObject: s,
                    subjectName: s.attributes.name,
                    examStartDate: new Date(s.attributes.examDate),
                    theory: s.attributes.theoryMarks,
                    passing: s.attributes.passMarks,
                    totalMarks: s.attributes.maxMarks,

                    practicalData: [
                    ]
                }


                let pList = []
                for (const p of practicalSubjects) {

                    if (p.attributes.subjectId == s.id) {
                        let pObj = {
                            practicalId: uuidv4(),
                            id: p.id,
                            parseObject: p,
                            practicalName: p.attributes.name,
                            practicalStartDate: new Date(p.attributes.examDate),
                            practicalMarks: p.attributes.practicalMarks,
                            passingMarks: p.attributes.passMarks,
                            totalMarks: p.attributes.maxMarks,
                        }
                        pList.push(pObj)
                    }
                }

                newSubject.practicalData = pList;
                subjects.push(newSubject)


            }
            setTableData(subjects)
        }

    }, [props.subjects])

    useEffect(() => {

        if (tableData && tableData.length > 0) {
            let kidList = [];

            if (kidId && props.selectedKid) {
                kidList.push(props.selectedKid);
            } else {
                kidList = kidList.concat(selectedExamKids);
            }

            let mainList = [];
            for (const kid of kidList) {

                let kidTableDate = [...tableData];
                let kidSubjects = [];
                let totalMarks = 0;
                let totalMaxMarks = 0;
                let totalMinMarks = 0;
                let totalPracticalMinMarks = 0;
                let totalPracticalMarks = 0;
                let totalPracticalMaxMarks = 0;
                let allSubjectsPassed = false;

                for (const i in kidTableDate) {
                    let subject = { ...kidTableDate[i] };
                    subject.result = getExamSubjectResult(kid.id, subject.id);
                    subject.theoryMarks = subject.result && subject.result.attributes.theoryMarks;

                    let practicalData = [];


                    if (subject.practicalData && subject.practicalData.length > 0) {
                        for (const j in subject.practicalData) {
                            let ps = { ...subject.practicalData[j] };
                            ps.result = getExamSubjectResult(kid.id, subject.id, ps.id);
                            ps.practicalMarks = ps.result && ps.result.attributes.practicalMarks;

                            // Calculate total practical marks and max marks
                            totalPracticalMarks += ps.practicalMarks || 0;
                            totalPracticalMaxMarks += ps.totalMarks || 0;
                            totalPracticalMinMarks += ps.passingMarks || 0;

                            practicalData.push(ps);
                        }
                    }

                    subject.practicalData = practicalData;

                    // Calculate total theory marks and max marks
                    totalMarks += subject.theoryMarks || 0;
                    totalMaxMarks += subject.theory || 0;
                    totalMinMarks += subject.passing || 0;

                    // Check if subject is passed based on obtained marks and passing marks
                    if (subject.theoryMarks > subject.passing || totalPracticalMarks > subject?.practicalData[0]?.passingMarks) {
                        allSubjectsPassed = true;
                    }
                    kidSubjects.push(subject);
                }

                // Calculate total percentage
                let pntMarks = totalPracticalMarks + totalMarks;
                let pntMaxMarks = totalPracticalMaxMarks + totalMaxMarks;
                const percentage = (pntMarks / pntMaxMarks) * 100;
                console.log(totalPracticalMarks)
                console.log(totalMarks)
                console.log(totalMaxMarks)

                mainList.push({
                    id: kid.id,
                    kid: kid,
                    subjects: kidSubjects,
                    result: {
                        totalMarks: totalMarks + totalPracticalMarks,
                        totalMaxMarks: totalMaxMarks + totalPracticalMaxMarks,
                        totalMinMarks: totalMinMarks + totalPracticalMinMarks,

                        totalTheoryMarks: totalMarks,
                        totalMaxTheoryMarks: totalMaxMarks,
                        totalMinTheoryMarks: totalMinMarks,

                        totalPracticalMarks: totalPracticalMarks,
                        totalMaxPracticalMarks: totalPracticalMaxMarks,
                        totalMinPracticalMarks: totalPracticalMinMarks,


                        percentage: percentage.toFixed(2),
                        passed: allSubjectsPassed,
                    },
                });
            }
            setKidSubjectList(mainList);
        }
    }, [props.selectedKid, selectedExamKids, tableData, props.examResults]);

    const getExamSubjectResult = (kidId, subjectId, practicalId) => {
        for (let sr of props.examResults) {
            if (sr.attributes.kidId === kidId && sr.attributes.subjectId === subjectId && sr.attributes.practicalId === practicalId) {
                return sr;
            }
        }
    };

    function numberToWords(number) {
        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
        const bigs = ['', 'Thousand', 'Million', 'Billion'];

        function convertLessThanOneThousand(n) {
            if (n === 0) {
                return '';
            } else if (n < 10) {
                return units[n];
            } else if (n < 20) {
                return teens[n - 10];
            } else if (n < 100) {
                return tens[Math.floor(n / 10)] + ' ' + units[n % 10];
            } else {
                return units[Math.floor(n / 100)] + ' Hundred ' + convertLessThanOneThousand(n % 100);
            }
        }

        function convertBigNumbers(n, bigIndex) {
            if (n === 0) {
                return '';
            } else if (n < 1000) {
                return convertLessThanOneThousand(n) + ' ' + bigs[bigIndex];
            } else {
                return convertBigNumbers(Math.floor(n / 1000), bigIndex + 1) + ' ' + convertLessThanOneThousand(n % 1000);
            }
        }

        if (number === 0) {
            return 'Zero';
        } else if (number < 0) {
            return 'Minus ' + numberToWords(Math.abs(number));
        } else {
            return convertBigNumbers(number, 0);
        }
    }

    const getResult = (totalPer) => {
        if (props.selectedExam && props.selectedExam) {
            if (props.selectedExam?.attributes?.resultType == 2) {
                return getGrade(totalPer)
            }
        }
        return getClass(totalPer)
    }
    const getClass = (totalPer) => {

        if (totalPer < 40) {
            return 'Fail'
        } else if (totalPer >= 40 && totalPer < 50) {
            return 'Pass Marks'
        } else if (totalPer >= 50 && totalPer < 60) {
            return 'Second Class'
        } else if (totalPer >= 60 && totalPer < 80) {
            return 'First Class'
        } else if (totalPer >= 80) {
            return 'Distinction'
        }
    }

    const getGrade = (totalPer) => {

        if (totalPer < 50) {
            return 'C Grade'
        } else if (totalPer >= 50 && totalPer < 60) {
            return 'C+ Grade'
        } else if (totalPer >= 60 && totalPer < 70) {
            return 'B Grade'
        } else if (totalPer >= 70 && totalPer < 80) {
            return 'B+ Grade'
        } else if (totalPer >= 80 && totalPer < 90) {
            return 'A Grade'
        } else if (totalPer >= 90) {
            return 'A+ Grade'
        }
    }

    const getUI = (kid, kidSubjects) => {
        return (
            <div >
                {
                    !(props.selectedSchool) ? (<Col xs="12">
                        <div className="text-center my-3">
                            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading.....</Label>
                        </div>

                    </Col>) :
                        (<div style={{ pageBreakBefore: 'always' }}>
                            <div className="col-md-12" style={{ height: '3.3cm', }}>
                                {duplicate && <div className='d-none d-print-block' style={{
                                    borderWidth: '4px',
                                    borderStyle: 'solid',
                                    borderColor: '#495057',
                                    borderRadius: '8px',
                                    color: '#495057',
                                    opacity: 0.5,
                                    position: 'absolute',
                                    zIndex: 1,
                                    //left: '40%',
                                    // marginTop: '0.5%',
                                    left: '80%',
                                    fontSize: '25pt',
                                    // transform: 'rotate(45deg)',
                                    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif'
                                }}>
                                    Duplicate
                                </div>}
                            </div>

                            <div>
                                <table style={{ width: '110%', textAlign: 'center', height: '4cm', marginLeft: '0%', marginTop: '8.5%' }}>
                                    <tbody>
                                        <tr>
                                            <td colSpan="2" style={{ height: '1.5cm', textAlign: 'center', width: '9cm' }}>
                                                <span className="d-print-none">NAME OF THE CANDIDATE</span>
                                                <br></br>
                                                <b style={{ position: 'absolute', marginTop: '0.4%', marginLeft: '-1.5cm' }}>{kid && kid.attributes.Name}</b>
                                            </td>
                                            <td style={{ height: '1.5cm', textAlign: 'center', width: '9cm' }}>
                                                <span className="d-print-none">COURSE</span>
                                                <br></br>
                                                <b style={{ position: 'absolute', marginTop: '0.4%', marginLeft: '-5cm' }}>{`${props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.name} ${props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.batch ? "-" : ''} ${props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.batch ? props.selectedExam.attributes.batch : ''}`}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ height: '1.5cm', textAlign: 'center', width: '4.5cm' }}>
                                                <span className="d-print-none">REGISTER NUMBER</span>
                                                <br></br>
                                                <b style={{ marginTop: '0%', marginLeft: '-5%', position: 'absolute' }}>{kid && kid.attributes.admissionNumber}</b>
                                            </td>
                                            <td style={{ height: '1.5cm', textAlign: 'center', width: '4.5cm' }}>
                                                <span className="d-print-none">YEAR</span>
                                                <br></br>
                                                <b style={{ marginTop: '0%', marginLeft: '-7%', position: 'absolute' }}>{props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.tag ? props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.tag[0] : ''}</b>
                                            </td>
                                            <td style={{ height: '1.5cm', textAlign: 'center', width: '9cm' }}>
                                                <span className="d-print-none">INSTITUTION NAME AND ADDRESS</span>
                                                <br></br>
                                                <b style={{ marginLeft: '-23%', position: "absolute", width: '31%', fontSize: '11px', marginTop: '-0.3cm' }}>{props.selectedSchool.attributes.Name} <br></br>{props.selectedSchool.attributes.Address}</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table style={{ marginTop: '5%', width: '100%', marginLeft: '0%', }}>
                                <tbody>
                                    <tr>
                                        <td style={{ height: '1.2cm', width: '1.7cm', textAlign: 'center' }}><span className="d-print-none">SL. NO.</span></td>
                                        <td style={{ height: '1.2cm', width: '6cm', textAlign: 'center' }}><span className="d-print-none">SUBJECT</span></td>
                                        <td style={{ height: '1.2cm', width: '2cm', textAlign: 'center' }}><span className="d-print-none">MAXIMUM MARKS</span></td>
                                        <td style={{ height: '1.2cm', width: '2cm', textAlign: 'center' }}><span className="d-print-none">MINIMUM MARKS</span></td>
                                        <td style={{ height: '1.2cm', width: '2cm', textAlign: 'center' }}><span className="d-print-none">MARKS OBTAINED</span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ height: '12cm' }}>
                                <table style={{ width: '100%', }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '1.7cm', textAlign: 'center' }}></td>
                                            <th style={{ width: '6cm', textAlign: 'center' }}>Theory</th>
                                            <td style={{ width: '2cm', textAlign: 'center' }}></td>
                                            <td style={{ width: '2cm', textAlign: 'center' }}></td>
                                            <td style={{ width: '2cm', textAlign: 'center' }}></td>
                                        </tr>
                                        {kidSubjects && kidSubjects.subjects && kidSubjects.subjects.map((sub, subKey) => (
                                            <tr key={subKey} style={{ fontSize: '13px' }}>
                                                <td style={{ width: '1.7cm', textAlign: 'center' }}><b>{subKey + 1}</b></td>
                                                <td style={{ width: '6cm', textAlign: 'center' }}><b>{sub.subjectName}</b></td>
                                                <td style={{ width: '2cm', textAlign: 'center' }}><b>{sub.theory} </b></td>
                                                <td style={{ width: '2cm', textAlign: 'center' }}><b>{sub.passing}</b></td>
                                                <td style={{ width: '2cm', textAlign: 'center' }}><b>{sub.theoryMarks}</b> </td>
                                            </tr>
                                        ))}
                                        {kidSubjects && (<tr>
                                            <td style={{ width: '1.7cm', textAlign: 'center' }}></td>
                                            <th style={{ width: '6cm', textAlign: 'center' }}>Total</th>
                                            <th style={{ width: '2cm', textAlign: 'center' }}>{kidSubjects.result.totalMaxTheoryMarks}</th>
                                            <th style={{ width: '2cm', textAlign: 'center' }}>{kidSubjects.result.totalMinTheoryMarks}</th>
                                            <th style={{ width: '2cm', textAlign: 'center' }}>{kidSubjects.result.totalTheoryMarks}</th>
                                        </tr>)}
                                        <tr>
                                            <td style={{ width: '1.7cm', textAlign: 'center' }}></td>
                                            <th style={{ width: '6cm', textAlign: 'center' }}>Practical</th>
                                            <td style={{ width: '2cm', textAlign: 'center' }}></td>
                                            <td style={{ width: '2cm', textAlign: 'center' }}></td>
                                            <td style={{ width: '2cm', textAlign: 'center' }}></td>
                                        </tr>
                                        {kidSubjects && kidSubjects.subjects && kidSubjects.subjects.map((sub, subKey) => (
                                            sub.practicalData && sub.practicalData.map((practical, itemKey) => (
                                                <tr style={{ fontSize: '13px' }}>
                                                    <td style={{ width: '1.7cm', textAlign: 'center' }}><b>{subKey + 1}</b></td>
                                                    <td style={{ width: '6cm', textAlign: 'center' }}><b>{practical.practicalName} </b></td>
                                                    <td style={{ width: '2cm', textAlign: 'center' }}><b>{practical.totalMarks}</b></td>
                                                    <td style={{ width: '2cm', textAlign: 'center' }}><b>{practical.passingMarks}</b></td>
                                                    <td style={{ width: '2cm', textAlign: 'center' }}><b>{practical.practicalMarks}</b></td>
                                                </tr>))
                                        ))}
                                        {kidSubjects && (<tr>
                                            <td style={{ width: '1.7cm', textAlign: 'center' }}></td>
                                            <th style={{ width: '6cm', textAlign: 'center' }}>Total</th>
                                            <th style={{ width: '2cm', textAlign: 'center' }}>{kidSubjects.result.totalMaxPracticalMarks}</th>
                                            <th style={{ width: '2cm', textAlign: 'center' }}> {kidSubjects.result.totalMinPracticalMarks}</th>
                                            <th style={{ width: '2cm', textAlign: 'center' }}>{kidSubjects.result.totalPracticalMarks}</th>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            <table style={{ marginTop: '-1%', width: '100%', marginLeft: '0%' }}>
                                <tbody>
                                    {kidSubjects && (<tr>
                                        <td style={{ height: '1cm', width: '7.7cm', textAlign: 'center', paddingTop: '3%' }}><span className="d-print-none">GRAND TOTAL</span></td>
                                        <th style={{ height: '1cm', width: '2cm', textAlign: 'center', paddingTop: '3%' }}>{kidSubjects.result.totalMaxMarks}</th>
                                        <th style={{ height: '1cm', width: '2cm', textAlign: 'center', paddingTop: '3%' }}>{kidSubjects.result.totalMinMarks}</th>
                                        <th style={{ height: '1cm', width: '2cm', textAlign: 'center', paddingTop: '3%' }}>{kidSubjects.result.totalMarks}</th>
                                    </tr>)}
                                </tbody>
                            </table>
                            <table style={{ width: '100%', textAlign: 'center', marginLeft: '0cm', marginTop: '0.6cm' }}>
                                <tbody>
                                    {kidSubjects && (<tr>
                                        <td style={{ height: '2.3cm', width: '12cm', textAlign: 'center' }}>
                                            <span className="d-print-none">Total Marks Obtained (In words)</span>
                                            <br></br><b>{numberToWords(kidSubjects.result.totalMarks)}</b>
                                        </td>
                                        <td style={{ height: '2.3cm', width: '7cm', textAlign: 'center' }}>
                                            <span className="d-print-none">Class Obtained</span>
                                            <br></br><b>{getResult(kidSubjects.result.percentage)}</b>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                            <div style={{ marginTop: '2.5cm', marginLeft: '1.9cm' }}>
                                <span><b>{moment(props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.certificateDate).format("DD/MM/YYYY")}</b></span>
                            </div>
                        </div>
                        )
                }
            </div>
        )
    }

    return (
        <React.Fragment>
            <Header certificate={kidId ? 'Marks Card' : 'Marks Cards'}
                matchGroup={matchGroup}
                exam={props.selectedExam && props.selectedExam}
                selectedKid={kidId ? props.selectedKid : ''}
                onClickSwitch={() => {
                    setDuplicate(!duplicate)
                }}
                duplicate={duplicate}
            />
            {kidSubjectList && kidSubjectList.length > 0 ? <div className="account-pages">
                {kidId && props.selectedKid && <Container fluid='md'>{getUI(props.selectedKid, kidSubjectList[0])}</Container>}

                {
                    groupId && kidSubjectList.map((ksl) =>

                        <Container fluid='md' key={ksl.id}>{getUI(ksl.kid, ksl)}</Container>
                    )
                }
            </div> :
                <div>
                    {/* Loading */}
                </div>

            }
        </React.Fragment >

    )
}

const mapStatetoProps = (state) => {

    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    const { subjects } = state.Subjects;
    const { selectedExam } = state.Examination;
    const { examResults } = state.ExamResults;
    const { resultGroup, singleGroupHolder } = state.Group;
    const { groupmaps } = state.GroupMap;
    const { kids, admissionKids, selectedKid } = state.Kid;


    return {
        selectedKid, selectedSchool, classrooms, subjects, selectedExam, examResults,
        resultGroup, singleGroupHolder, groupmaps, kids, admissionKids
    };
};

export default (withRouter(connect(mapStatetoProps, {
    getAKid, setSelecetdSchool, getClassroomsForSchoolId, getSubjectsForExamId, getExamForExamId, getExamResultsForExamId,
    getGroupForId, getGroupMapsForGroupId, getTeachersForSchool, getExamResultsForExamIdAndKidId,
    saveGroup, getAllKids
})(MarksCard)));