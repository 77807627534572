import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Card, CardBody, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Badge} from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelecetdSchool, getLeaveModuleForOwnerId, editLeaveDetails } from '../../store/actions';
import Moment from "moment";

function LeaveDetails(props) {
    const match = matchPath(props.history.location.pathname, {
        path: "/leave-details/:schoolId/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;
    const id = match && match.params.id;

    const [toggleId, setToggleId] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(null);
    

    useEffect(() => {
        if (schoolId) {
            props.setSelecetdSchool(schoolId);
            props.getLeaveModuleForOwnerId({ownerType:"school",ownerId:schoolId});
        }
    }, [schoolId]);

    useEffect(() => {
        const leave = props.leavesApplied.find(leave => leave.id === id);
        if (leave) {
            setCurrentStatus(leave.attributes.status);
        }
    }, [props.leavesApplied, id]);

    const handleStatusChange = (newStatus) => {
        const leaveToEdit = props.leavesApplied.find(leave => leave.id === id);
        if (leaveToEdit) {
            leaveToEdit.set('status', newStatus);
            props.editLeaveDetails(leaveToEdit);
            setCurrentStatus(newStatus);
        }
    };

    const getStatus = (status) => {
        if (status == 0) {
            return "Requested";
        } else if (status == 1) {
            return "Approved";
        } else if (status == 2) {
            return "Pending";
        } else if (status == 3) {
            return "Rejected";
        }
    };

    function getStatusColor(status) {

        if (status == 0) {
          return "primary";
        } else if (status == 1) {
          return "success ";
        } else if (status == 2) {
          return "warning";
        } else if (status == 3) {
          return "danger";
        }
    }

    

    return (
        <React.Fragment>
            <div className='page-content'>
                <Row className='d-flex align-items-start'>
                    <Col className="d-flex align-items-start">
                        <div style={{ cursor: "pointer" }} onClick={() => { window.history.back() }}>
                            <ul className="list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className='m-2'>
                            <h4>Leave details</h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody>
                                <Table className="table mb-0 table-bordered">
                                    <tbody>
                                        {props.leavesApplied.filter(leave => leave.id === id).map(leave => (
                                                <React.Fragment key={leave.id}>
                                                    <tr>
                                                        <th scope="row" style={{ width: "200px" }}>Date</th>
                                                        <td>{Moment(leave.attributes.createdAt).format('DD-MMM-YYYY hh:mm a')}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row" style={{ width: "200px" }}>Reason</th>
                                                        <td>{leave.attributes.reason}</td>
                                                    </tr>
                                                    <tr>
                                                    <th scope="row" style={{ width: "200px" }}>Status</th>
                                                    <td>
                                                        <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                                                            <DropdownToggle className="bg-white border-0" caret>
                                                                <Badge className={"font-size-12 badge-soft-" + getStatusColor(currentStatus)} pill>
                                                                    {getStatus(currentStatus)} <i className="mdi mdi-chevron-down"></i>
                                                                </Badge>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {[0, 1, 2, 3].map(status => (
                                                                    <DropdownItem key={status} onClick={() => handleStatusChange(status)}>
                                                                        {getStatus(status)}
                                                                    </DropdownItem>
                                                                ))}
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                                </React.Fragment>
                                            ))}
                                        {props.leavesApplied.filter(leave => leave.id === id).length === 0 && (
                                            <tr>
                                                <td colSpan="2">Leave details not found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { leavesApplied } = state.LeaveModule;
    return { leavesApplied, selectedSchool };
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool, getLeaveModuleForOwnerId,editLeaveDetails })(LeaveDetails)));