import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip, Input, Dropdown, DropdownMenu, DropdownItem, Form, Container, Label, Card, CardBody, FormGroup } from "reactstrap";
import * as WhiteLable from "../../components/Common/WhiteLable";
import { connect } from "react-redux";
import { matchPath } from "react-router"
import QRCode from 'qrcode'
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { setSelecetdSchool } from "../../store/actions";

const PaymentLinkSharePopup = (props) => {

    const HOST_NAME = WhiteLable.getHost();
    const PAYMENT_LINK_ = HOST_NAME + "/invoice/" + props.invoiceObject.id + "/" + 1;

    const [paymentLinkCode, setPaymentLinkCode] = useState(undefined)


    const getPaymentLink = () => {
        return (
            WhiteLable.getHost() + "/invoice/" + props.invoiceObject.id + "/" + 1
        )
    }

    function copyPaymentLink(str, event) {
        document.oncopy = function (event) {
            event.clipboardData.setData("Text", str);
            event.preventDefault();
        };
        document.execCommand("Copy");
        document.oncopy = undefined;
        toastr.success("Link copied to clipboard", { timeOut: 1000 });
    }


    useEffect(() => {
        paymentLinkQrCode()
        console.log(props.invoiceObject)
    }, [props.invoiceObject]);

    function paymentLinkQrCode() {
        QRCode.toDataURL(PAYMENT_LINK_, { errorCorrectionLevel: 'H' })
            .then(url => {
                setPaymentLinkCode(url)
            })
            .catch(err => {
                console.error(err)
            })
    }

    const getSchoolName = (schoolId) => {
        if (props.schools && props.schools.length > 0) {
            let temp = props.schools.filter(
                (school) => school.id === schoolId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    return (
        <div>
            <Modal isOpen={props.paymentSharePopup}>
                <ModalHeader
                    toggle={() => {
                        props.closePaymentSharePopup()
                    }}
                >
                    Payment Link
                </ModalHeader>
                <ModalBody>
                    <Row className='m-2'>
                        <Col>
                            <p className='ml-4 my-0'><b>{"Student Name"}:</b> {props.invoiceObject.childName}</p>
                            <p className='ml-4 my-0'><b>{"Title"}:</b> {props.invoiceObject.invoiceTitle}</p>
                            <p className='ml-4 my-0'><b>{"Invoice amount "}:</b> {props.invoiceObject.totalAmount}</p>
                            <p className='ml-4 my-0'><b>{"Pending amount "}:</b> {props.invoiceObject.balanceAmount}</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={10}>
                            <p
                                disabled
                                type="text"
                                className=" m-2 ml-4 text-primary"
                                id="linkarea"
                            >
                                {getPaymentLink()}
                            </p>
                        </Col>
                        <Col
                            lg={2}
                            id="enquiryLink"
                            onClick={(e) => {
                                copyPaymentLink(getPaymentLink());
                            }}
                        >
                            <div className="px-2 border">
                                <div className="d-flex flex-column align-items-center">
                                    <h1
                                        style={{ cursor: "pointer" }}
                                        className="text-primary"
                                    >
                                        <i className="bx bxs-copy"></i>
                                    </h1>
                                </div>
                                <p className='' style={{ cursor: "pointer", marginTop: '30px' }}>copy</p>
                                <UncontrolledTooltip
                                    placement="auto"
                                    target="enquiryLink"
                                >
                                    copy to clipboard
                                </UncontrolledTooltip>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <div className="col-md-12 d-flex justify-content-center align-items-center" style={{}}>
                            <img className="img-responsive" src={paymentLinkCode} style={{ width: "100px" }} />
                        </div>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Row className='d-flex justify-content-end'>
                        <Col>
                            <button
                                type="button"
                                className="btn btn-success btn-sm m-1"
                                onClick={() => {
                                    let schoolName = getSchoolName(props.invoiceObject.Obj.attributes.schoolId)
                                    let link = getPaymentLink();
                                    const message = `Dear Parent,\n\n${schoolName} Preschool has shared an payment link to pay the amount.\n\nPlease click the link below to Pay it:\n${link}\n\nPowered by ${WhiteLable.getAppName()}`;
                                    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
                                    window.open(whatsappUrl);
                                    // web.whatsapp.com
                                }}
                            ><a className="bx bxl-whatsapp text-white"></a>Send via Whatsapp</button>
                            <button
                                type="button"
                                className="btn btn-info btn-sm m-1"
                                onClick={() => {
                                    props.sendNotifyToParent(props.invoiceObject.id, props.invoiceObject.childId);
                                }}
                            >
                                Notify Parents
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    props.closePaymentSharePopup()
                                }}
                                className="btn btn-secondary waves-effect btn-sm m-1"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </div>
    )
}

const mapStatetoProps = (state) => {

    const { selectedSchool, schools } = state.School;

    return { selectedSchool, schools };
};

export default connect(mapStatetoProps, {
    setSelecetdSchool,
})(PaymentLinkSharePopup);