import React, { useEffect, useState } from 'react';
import {
    Container, Row, TabContent, TabPane, Card, Form,
    Input,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    CardText, Nav, NavItem, NavLink, CardBody, Label, CardHeader, Button, Col, UncontrolledTooltip
} from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { matchPath } from "react-router";
import Parse from 'parse';
import TopBar from './Topbar';
import toastr from 'toastr';
import { setSelecetdSchool } from '../../store/actions';
import SweetAlert from "react-bootstrap-sweetalert";


const ExpenseSupplier = (props) => {

    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [expenseSupplierList, setExpenseSupplierList] = useState([

    ]);
    const [newSupplierName, setNewSupplierName] = useState('');
    const [newCompanyName, setNewCompanyName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newLandlineNumber, setNewLandlineNumber] = useState('');
    const [newMobileNumber, setNewMobileNumber] = useState('');
    const [newWebsite, setNewWebsite] = useState('');
    const [newAddress, setNewAddress] = useState('');
    const [supplierNameError, setSupplierNameError] = useState(false);
    const [editParseObject, setEditParseObject] = useState(undefined);
    const [deleteParseObject, setDeleteParseObject] = useState(undefined);

    const SUPPLIER_NAME = "SUPPLIER_NAME"
    const COMPANY_NAME = "COMPANY_NAME"
    const EMAIL = "EMAIL"
    const MOBILE_NUMBER = "MOBILE_NUMBER"
    const WEBSITE = "WEBSITE"
    const LANDLINE_NUMBER = "LANDLINE_NUMBER"
    const ADDRESS = "ADDRESS"


    useEffect(() => {
        if (props.expenseSupplierList && props.expenseSupplierList.length > 0) {
            setExpenseSupplierList(props.expenseSupplierList)
        }
    }, [props.expenseSupplierList])


    const list = [
        {
            name: "airtel",
            companyName: "",
            mobile: "",
            phone: "",
            email: "",
            website: "",
            address: "",

        },
        {
            name: "Shivam Patel",
            companyName: "",
            mobile: "9438763651",
            phone: "--",
            email: "shivampatel066@gmail.com",
            website: "--",
            address: "#40, main road",
        },

    ]

    const getEditPermission = (item) => {
        if (item.isDefault) {
            return false
        }

        if (item.expenseSupplierParseObject.attributes.instituteId) {
            if (props.userTeacher.attributes.Role > 2) {
                return true
            } else {
                return false
            }
        }

        return true
    }



    const handleOnClickAddNewSupplier = () => {
        if (newSupplierName == undefined || newSupplierName === "") {
            setSupplierNameError(true)
            return
        }

        let Supplier = Parse.Object.extend("Supplier");
        let supplier = new Supplier();

        if (editParseObject) {
            supplier = editParseObject
        }
        supplier.set("name", newSupplierName)
        supplier.set("companyName", newCompanyName ? newCompanyName : undefined)
        supplier.set("email", newEmail ? newEmail : undefined)
        supplier.set("mobile", newMobileNumber ? newMobileNumber : undefined)
        supplier.set("phone", newLandlineNumber ? newLandlineNumber : undefined)
        supplier.set("address", newAddress ? newAddress : undefined)
        supplier.set("website", newWebsite ? newWebsite : undefined)
        supplier.set("instituteId", props.selectedSchool.attributes.instituteId);
        supplier.set("schoolId", props.selectedSchool.id);



        props.toggleSupplierModal(false)
        resetValues()
        setEditParseObject(undefined);
        props.editExpenseSupplier(supplier);
        toastr.success("Supplier added successfully")

    }

    const handleOnClickDelete = (parseObject) => {
        if (parseObject) {
            parseObject.set("isDeleted", true)
            props.editExpenseSupplier(parseObject);
            toastr.success("Supplier removed successfully")
            setDeleteParseObject(undefined)
        }
    }

    const fillValues = (parseObject) => {
        if (parseObject) {
            let obj = parseObject.attributes

            setNewSupplierName(obj.name ?? "")
            setNewCompanyName(obj.companyName ?? "")
            setNewMobileNumber(obj.mobile ?? "")
            setNewEmail(obj.email ?? "")
            setNewLandlineNumber(obj.phone ?? "")
            setNewAddress(obj.address ?? "")
            setNewWebsite(obj.website ?? "")
        }
    }

    const resetValues = () => {
        setNewSupplierName("")
        setNewCompanyName("")
        setNewMobileNumber("")
        setNewEmail("")
        setNewLandlineNumber("")
        setNewAddress("")
        setNewWebsite("")
    }


    const getFormGroup = (heading, name, type, value, parameter) => {

        return (
            <FormGroup className="position-relative">
                <Label>{heading}</Label>
                {parameter === SUPPLIER_NAME && <Label

                    className="text-danger ml-1"
                >
                    *
                </Label>
                }
                {parameter === SUPPLIER_NAME && supplierNameError && <Label className="label-xsmall text-danger ml-2">
                    (this field is mandatory)
                </Label>
                }
                {parameter !== ADDRESS && <Input
                    name={name}
                    id={name}
                    type={type}
                    className="form-control"
                    placeholder={heading}
                    value={value}
                    onChange={(e) => {
                        let value = e.target.value
                        if (parameter === SUPPLIER_NAME) {
                            if (value.length > 0) {
                                setSupplierNameError(false)
                            } else {
                                setSupplierNameError(true)
                            }
                            setNewSupplierName(value)
                        } else if (parameter === MOBILE_NUMBER) {
                            setNewMobileNumber(value)
                        } else if (parameter === COMPANY_NAME) {
                            setNewCompanyName(value)
                        } else if (parameter === LANDLINE_NUMBER) {
                            setNewLandlineNumber(value)
                        } else if (parameter === ADDRESS) {
                            setNewAddress(value)
                        } else if (parameter === WEBSITE) {
                            setNewWebsite(value)
                        } else if (parameter === EMAIL) {
                            setNewEmail(value)
                        }
                    }}
                />}
                {
                    parameter === ADDRESS && <textarea
                        name={name}
                        id={name}
                        rows="3"
                        className="form-control"
                        placeholder={heading}
                        value={value}
                        onChange={(e) => { setNewAddress(e.target.value) }}
                    ></textarea>
                }
            </FormGroup>
        )
    }

    return (

        <React.Fragment>
            {deleteParseObject && (

                <SweetAlert
                    title={props.t('Are you sure?')}
                    warning
                    showCancel
                    confirmBtnText="Remove"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        handleOnClickDelete(deleteParseObject)

                    }}
                    onCancel={() => {
                        setDeleteParseObject(undefined);
                    }}>
                    {props.t("You won't be able to revert this!")}

                </SweetAlert>

            )}
            <Card>


                <CardBody>
                    <Row className='justify-content-between'>
                        <Col lg={10}>
                            <h4 className='m-2'>
                            {props.t("Supplier")}
                            </h4>
                        </Col>
                        <Col className='Justify-content-end' lg={2}>
                            {props.writePermission && <Button
                                type="button"
                                color="success"
                                className="btn waves-effect waves-light m-2"
                                onClick={() => {
                                    resetValues()
                                    props.toggleSupplierModal(true)
                                }}
                            >
                               {props.t('New Supplier')}
                            </Button>}
                        </Col>
                    </Row>
                    {expenseSupplierList && expenseSupplierList.length > 0 && <div className="table-responsive m-3">
                        <table className="table table-centered mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th style={{ width: "1%" }}>
                                        #
                                    </th>
                                    <th>
                                        {props.t("Name")}
                                    </th>
                                    <th>
                                        {props.t("Company Name")}
                                    </th>
                                    <th>
                                        {props.t("Contact Details")}
                                    </th>
                                    <th >
                                        {props.t("Address")}
                                    </th>
                                    {props.writePermission && <th style={{ textAlign: "center", width: "10%" }}><Label className='ml-1 pl-1'>Actions</Label></th>}

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    expenseSupplierList.map((item, key) => (
                                        <tr key={key}>
                                            <td>
                                                <Label><small style={{ fontSize: "14px" }}>{key + 1} </small></Label>
                                            </td>
                                            <td>
                                                <div><h5 className='font-size-14 text-dark'>{item.name}</h5></div>
                                            </td>
                                            <td>
                                                <div><Label>{item.companyName ?? "-"}</Label></div>
                                            </td>
                                            <td>
                                                {item.mobile && <div><Label>Mobile : {item.mobile}</Label></div>}
                                                {item.phone && <div><Label>Phone : {item.phone}</Label></div>}
                                                {item.email && <div><Label>Email : {item.email}</Label></div>}
                                                {item.website && <div><Label>Website : {item.website}</Label></div>}
                                            </td>
                                            <td>
                                                <div><Label>{item.address ?? "-"}</Label></div>
                                            </td>
                                            {props.writePermission && getEditPermission(item) && <td style={{ textAlign: "center" }}>
                                                <ul className="list-inline font-size-18 contact-links mb-0">

                                                    <li className="list-inline-item pl-1 m-1"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setEditParseObject(item.expenseSupplierParseObject)
                                                            fillValues(item.expenseSupplierParseObject)
                                                            props.toggleSupplierModal(true)

                                                        }}
                                                        id={"expense-supplier-edit" + key}>
                                                        <i className="bx bx-edit-alt text-dark"></i>
                                                        <UncontrolledTooltip placement="top" target={"expense-supplier-edit" + key}>
                                                            {props.t('Edit')}
                                                        </UncontrolledTooltip>

                                                    </li>
                                                    <li className="list-inline-item pl-1 m-1"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setDeleteParseObject(item.expenseSupplierParseObject)
                                                        }}
                                                        id={"expense-supplier-delete" + key}>
                                                        <i className="bx bx-trash text-danger"></i>
                                                        <UncontrolledTooltip placement="top" target={"expense-supplier-delete" + key}>
                                                            {props.t('Delete')}
                                                        </UncontrolledTooltip>

                                                    </li>
                                                   { true && <li className="list-inline-item m-1 pl-1"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            props.addExpense(item.expenseSupplierParseObject)
                                                        }}
                                                        id={"expense-category-add" + key}>
                                                        <i className="bx bx-plus-circle text-primary"></i>
                                                        <UncontrolledTooltip placement="top" target={"expense-category-add" + key}>
                                                            {props.t('Add Expense')}
                                                        </UncontrolledTooltip>

                                                    </li>}


                                                </ul>
                                            </td>}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>}
                </CardBody></Card>

            {expenseSupplierList && expenseSupplierList.length === 0 &&
                <Card className="mt-3">
                    <CardBody>
                        <center ><Label className='text-center' style={{ textAlign: "center" }}>No Suppliers to Show !!</Label></center>
                    </CardBody>
                </Card>
            }

            <Modal isOpen={props.showModal} size="xl">
                <ModalHeader toggle={() => {
                    if (props.showModal) {
                        setEditParseObject(undefined)
                    } else {
                        resetValues()
                    }
                    props.toggleSupplierModal(!props.showModal)

                }}>
                    <h5 className="modal-title mt-0">{editParseObject ? props.t("Edit Payee") : props.t("Add Payee")}</h5>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Row>
                        <Col lg={12}>
                            <Form onSubmit={() => { }}>
                                <Row className="mt-4">
                                    <Col md="6">
                                        {getFormGroup("Supplier Name", "supplierName", "text", newSupplierName, SUPPLIER_NAME)}
                                    </Col>

                                    <Col md="6">
                                        {getFormGroup("Company Name", "companyName", "text", newCompanyName, COMPANY_NAME)}


                                    </Col>
                                    <Col md="6">
                                        {getFormGroup("Email", "email", "text", newEmail, EMAIL)}
                                    </Col>
                                    <Col md="6">
                                        {getFormGroup("Landline Number", "landlineNumber", "text", newLandlineNumber, LANDLINE_NUMBER)}


                                    </Col>
                                    <Col md="6">
                                        {getFormGroup("Mobile Number", "mobileNumber", "text", newMobileNumber, MOBILE_NUMBER)}

                                    </Col>

                                    <Col md="6">
                                        {getFormGroup("Website", "website", "text", newWebsite, WEBSITE)}

                                    </Col>
                                    <Col md="12">
                                        {getFormGroup("Address", "address", "text", newAddress, ADDRESS)}



                                    </Col>


                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button
                        color='primary'
                        onClick={() => {
                            handleOnClickAddNewSupplier()
                        }}
                        disabled={props.isLoadingExpenseSupplier}

                    >
                        {props.isLoadingExpenseSupplier && <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}

                        {editParseObject ? "Save" : "Add Payee"}
                    </Button>

                </ModalFooter>
            </Modal>


        </React.Fragment>

    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { isLoadingExpenseSupplier } = state.ExpenseSupplier;
    const { userTeacher } = state.Login;
    return { selectedSchool, isLoadingExpenseSupplier,userTeacher };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool })(ExpenseSupplier)));
