import * as actions from "./actionTypes";

export const getLeaveModuleForOwnerId = (filter) => {
    return {
        type: actions.GET_LEAVE_MODULE_FOR_OWNER_ID,
        payload: { filter }
    }
}

export const updateLeaveModule = (leaves) => {
    return {
        type: actions.UPDATE_LEAVE_MODULE,
        payload: { leaves }
    }
}

export const editLeaveDetails = (leaves) => {
    return {
        type: actions.EDIT_LEAVE_DETAILS,
        payload: { leaves }
    }
}

export const getLeavePolicy = (ownerId, ownerType) => {
    return {
      type: actions.GET_LEAVE_POLICY,
      payload: {ownerId, ownerType}
    };
};

export const updateLeavePolicy = (leavesPolicies) => {
    return {
        type: actions.UPDATE_LEAVE_POLICY,
        payload: { leavesPolicies }
    }
}

export const getLeavePolicyItemsForPolicyId = (policyId) => {
    return {
        type: actions.GET_LEAVE_POLICY_ITEMS_FOR_POLICY_ID,
        payload: { policyId },
    };
};

export const updateLeavePolicyItemsForPolicyId = (leavesPoliciesItems) => {
    return {
        type: actions.UPDATE_LEAVE_POLICY_ITEMS_FOR_POLICY_ID,
        payload: { leavesPoliciesItems },
    };
};

export const getPayrollCategoriesForOwnerId = (ownerId, ownerType) => {
    return {
        type: actions.GET_LEAVE_CATEGORIES_FOR_OWNER_ID,
        payload: { ownerId, ownerType }
    }
}

export const updatePayrollCategories = (payrollCategories) => {
    return {
        type: actions.UPDATE_PAYROLL_CATEGORIES,
        payload: { payrollCategories }
    }
}

export const deletePayrollCategory = (payRollCategories) => {
    return {
        type: actions.DELETE_PAYROLL_CATEGORIES,
        payload: { payRollCategories }
    }
}

export const getMasterPayslipForTeacherId = (teacherId) => {
    return {
        type: actions.GET_MASTER_PAYSLIP_FOR_TEACHER_ID,
        payload: { teacherId },
    };
};

export const updateMasterPayslipForTeacherId = (masterPayslip) => {
    return {
        type: actions.UPDATE_MASTER_PAYSLIP_FOR_TEACHER_ID,
        payload: { masterPayslip },
    };
};


export const getMasterPayslipItemsForMasterPayslipId = (masterPayslipId) => {
    return {
        type: actions.GET_MASTER_PAYSLIP_ITEMS_FOR_MASTER_PAYSLIP_ID,
        payload: { masterPayslipId },
    };
};

export const updateMasterPayslipItemsForMasterPayslipId = (masterPayslipItems) => {
    return {
        type: actions.UPDATE_MASTER_PAYSLIP_ITEMS_FOR_MASTER_PAYSLIP_ID,
        payload: { masterPayslipItems },
    };
};

export const getPayslipForMonth = (teacherId, month) => {
    return {
        type: actions.GET_PAYSLIP_FOR_MONTH,
        payload: { teacherId, month },
    };
};

export const updatePayslipForMonth = (payslip) => {
    return {
        type: actions.UPDATE_PAYSLIP_FOR_MONTH,
        payload: { payslip },
    };
};

export const getPayslipForId = (teacherId, payslipId) => {
    return {
        type: actions.GET_PAYSLIP_FOR_ID,
        payload: { teacherId, payslipId },
    };
};

export const updatePayslipForId = (selectedPayslip) => {
    return {
        type: actions.UPDATE_PAYSLIP_FOR_ID,
        payload: { selectedPayslip },
    };
};

export const getPayslipItemsForPayslipId = (payslipId) => {
    return {
        type: actions.GET_PAYSLIP_ITEMS_FOR_PAYSLIP_ID,
        payload: { payslipId },
    };
};

export const updatePayslipItemsForPayslipId = (payslipItems) => {
    return {
        type: actions.UPDATE_PAYSLIP_ITEMS_FOR_PAYSLIP_ID,
        payload: { payslipItems },
    };
};


export const getPayslipsForMonthAndSchoolId = (schoolId, month) => {
    return {
        type: actions.GET_PAYSLIPS_FOR_SCHOOL_ID_AND_MONTH,
        payload: { schoolId, month },
    };
};

export const updatePayslipsForMonthAndSchoolId = (payslips) => {
    return {
        type: actions.UPDATE_PAYSLIPS_FOR_SCHOOL_ID_AND_MONTH,
        payload: { payslips },
    };
};

export const getMasterPayslipsForOwnerIdAndOwnertype = (ownerId, ownerType) => {
    return {
        type: actions.GET_PAYSLIPS_FOR_OWNER_ID_AND_OWNER_TYPE,
        payload: { ownerId, ownerType },
    };
};

export const updateMasterPayslipsForOwnerIdAndOwnertype = (masterPayslips) => {
    return {
        type: actions.UPDATE_PAYSLIPS_FOR_OWNER_ID_AND_OWNER_TYPE,
        payload: { masterPayslips },
    };
};



export const getHolidayList = (ownerId, ownerType) => {
    return {
        type: actions.GET_HOLIDAY_LIST,
        payload: { ownerId, ownerType }
    }
}

export const updateHolidayList = (holidayList) => {
    return {
        type: actions.UPDATE_HOLIDAY_LIST,
        payload: { holidayList }
    }
}

export const getPayslipItemsForPayslipIds = (payslipIds) => {
    return {
        type: actions.GET_PAYSLIP_ITEMS_FOR_PAYSLIP_IDS,
        payload: { payslipIds },
    };
};

export const updatePayslipItemsForPayslipIds = (allPayslipItems) => {
    return {
        type: actions.UPDATE_PAYSLIP_ITEMS_FOR_PAYSLIP_IDS,
        payload: { allPayslipItems },
    };
};

export const editPayslip = (payslip) =>{
    return{
        type: actions.EDIT_PAYSLIP,
        payload: {payslip}
    }
  }

  export const setSelectedMonth = (selectedMonth) =>{
    return{
        type: actions.SET_SELECTED_MONTH,
        payload: {selectedMonth}
    }
  }