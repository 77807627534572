import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import { setSelecetdSchool, getPayrollCategoriesForOwnerId, updatePayrollCategories, deletePayrollCategory } from '../../store/actions';
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import Parse from 'parse';
import toastr from 'toastr';
import SweetAlert from "react-bootstrap-sweetalert";



const PayRollCategories = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/payroll-categories/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;

    const [openPayrollModal, setOpenPayrollModal] = useState(false);
    const [payrollTitle, setPayrollTitle] = useState('');
    const [payrollDescription, setPayrollDescription] = useState('');
    const [categoryTitleError, setCategoryTitleError] = useState(false);
    const [categoryType, setCategoryType] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [categoryToEdit, setCategoryToEdit] = useState(null);



    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool]);

    const handleCategoryChange = (selectedOption) => {
        setSelectedCategory(selectedOption);
    };

    const categoryTypes = [
        { value: 'Earnings', label: 'Earnings' },
        { value: 'Deductions', label: 'Deductions' },
        { value: 'Reimbursements', label: 'Reimbursements' }
    ];

    const handleEditDetails = (payrollCategory) => {
        setCategoryToEdit(payrollCategory);
        setCategoryType({
            value: payrollCategory.attributes.type === 1 ? 'Earnings' : payrollCategory.attributes.type === 2 ? 'Deductions' : 'Reimbursements',
            label: payrollCategory.attributes.type === 1 ? 'Earnings' : payrollCategory.attributes.type === 2 ? 'Deductions' : 'Reimbursements'
        });
        setSelectedCategory(payrollCategory.attributes.title);
        setPayrollDescription(payrollCategory.attributes.description);
        setOpenPayrollModal(true);
    };

    useEffect(() => {
        if (schoolId) {
            props.getPayrollCategoriesForOwnerId(schoolId, "School");
        }
    }, [schoolId]);

    const saveCategories = () => {
        if (!categoryType) {
            toastr.error("Please select a category type.");
            return;
        }

        let typeValue;
        if (categoryType.value === 'Earnings') {
            typeValue = 1;
        } else if (categoryType.value === 'Deductions') {
            typeValue = 2;
        } else if (categoryType.value === 'Reimbursements') {
            typeValue = 3;
        } else {
            toastr.error("Invalid category type.");
            return;
        }

        let PayrollCategory = Parse.Object.extend("PaySlipCategories");
        let payrollCategory = new PayrollCategory();

        if (categoryToEdit) {
            payrollCategory = props.payrollCategories.find(category => category.id === categoryToEdit.id);
        } else {
            payrollCategory.set("ownerId", schoolId);
            payrollCategory.set("ownerType", "School");
        }

        payrollCategory.set("title", selectedCategory ? selectedCategory : '');
        payrollCategory.set("type", typeValue);
        payrollCategory.set("description", payrollDescription);

        payrollCategory.save().then((result) => {
            props.updatePayrollCategories([result]);
            toastr.success(categoryToEdit ? "Category updated successfully" : "Category added successfully");
            setOpenPayrollModal(false);
        }).catch((error) => {
            toastr.error("Error saving category: " + error.message);
        });
    };

    const deleteCategory = () => {
        props.deletePayrollCategory(categoryToEdit)
        setShowDeleteAlert(false);
    }


    return (
        <React.Fragment>
            <div className='page-content'>
                {showDeleteAlert && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Delete"
                        cancelBtnText="Cancel"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="success"
                        title="Alert!"
                        onCancel={() => {
                            setShowDeleteAlert(false);
                        }}
                        onConfirm={() => {
                            deleteCategory()
                        }}
                    >
                        {"Are you sure you want to delete?"}
                        <br />
                    </SweetAlert>
                )}
                <Row className="d-flex justify-content-between align-items-start">
                    <Col lg={6} className="d-flex align-items-start">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { window.history.back() }}>
                            <ul className="list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className='m-2'>
                            <h4 className="font-size-18">Payroll Categories</h4>
                        </div>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <div className="mt-4 mt-sm-0 float-sm-right form-inline">
                            <button className="btn btn-primary float-right" onClick={() => {
                                setPayrollTitle('');
                                setPayrollDescription('');
                                setCategoryType(null);
                                setCategoryToEdit(null);
                                setOpenPayrollModal(true)
                            }}>New Payroll Categories</button>
                        </div>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col className="table-responsive">
                                <Table className="table table-nowrap m-0">
                                    <thead>
                                        <tr>
                                            <th>{props.t("#")}</th>
                                            <th>{props.t("Title")}</th>
                                            <th>{props.t("Category Type")}</th>
                                            <th>{props.t("Description")}</th>
                                            <th>{props.t("Edit")}</th>
                                            <th>{props.t("Delete")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.payrollCategories.map((payrollCategory, index) => (
                                            <tr key={payrollCategory.id}>
                                                <td>{index + 1}</td>
                                                <td>{payrollCategory.attributes.title}</td>
                                                <td>
                                                    {payrollCategory.attributes.type == 1
                                                        ? "Earnings"
                                                        : payrollCategory.attributes.type == 2
                                                            ? "Deductions"
                                                            : payrollCategory.attributes.type == 3
                                                                ? "Reimbursements"
                                                                : "Unknown"}
                                                    <div><small className='m-0'>{payrollCategory.attributes.ownerId == 'default' ? ("Default") : ""}</small></div>
                                                </td>
                                                <td>{payrollCategory.attributes.description}</td>
                                                <td>
                                                    {payrollCategory.attributes.ownerId != 'default' ?
                                                        (<div className="flex-fill text-primary" onClick={() => {
                                                            handleEditDetails(payrollCategory);
                                                        }}><i className="bx bx-edit-alt"></i>
                                                        </div>) : (<div className="flex-fill text-primary" >{"-"}
                                                        </div>)}
                                                </td>
                                                <td>
                                                    {(payrollCategory.attributes.ownerId != 'default') ? (<div className="flex-fill text-danger" onClick={() => {
                                                        setCategoryToEdit(payrollCategory);
                                                        setShowDeleteAlert(true);
                                                    }}
                                                    ><i className="bx bxs-trash-alt"></i>
                                                    </div>) : "-"}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>

            <Modal isOpen={openPayrollModal} size="md" toggle={() => {
                setSelectedCategory(null)
                setOpenPayrollModal(!openPayrollModal)
            }}>
                <ModalHeader toggle={() => {
                    setSelectedCategory(null)
                    setOpenPayrollModal(!openPayrollModal)
                }}>{categoryToEdit ? "Edit Category" : "Add Category"}</ModalHeader>
                <ModalBody>
                    <Row noGutters>
                        <Col lg="12">
                            <FormGroup>
                                <Label className="mb-0">Select Category:</Label>
                                <Label className="text-danger ml-1">*</Label>
                                <Select
                                    value={categoryType}
                                    onChange={(value) => {
                                        setCategoryType(value)
                                    }}
                                    options={categoryTypes}
                                    placeholder="Select a category"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="12">
                            <FormGroup>
                                <Label className="mb-0">Category Title:</Label>
                                <Label className="text-danger ml-1">*</Label>
                                <Input
                                    value={selectedCategory}
                                    onChange={(e) => {
                                        setSelectedCategory(e.target.value)
                                    }}
                                    className=''>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg="12">
                            <FormGroup className="mt-2 mb-2">
                                <Label className="mb-0">Description:</Label>
                                <Input
                                    type="textarea"
                                    value={payrollDescription}
                                    onChange={(e) => setPayrollDescription(e.target.value)}
                                    placeholder="Enter Description"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        saveCategories()
                    }}>
                        {categoryToEdit ? "Update Category" : "Save Category"}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { payrollCategories } = state.LeaveModule;
    return { selectedSchool, payrollCategories };
};

export default connect(mapStatetoProps, {
    setSelecetdSchool, getPayrollCategoriesForOwnerId, updatePayrollCategories, deletePayrollCategory
})(withRouter(withNamespaces()(PayRollCategories)));


