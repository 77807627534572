import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { withNamespaces } from "react-i18next";
import Parse from "parse";
import Moment from "moment";
import DatePicker from "react-datepicker";
import * as ReduxUtil from "../../store/ReduxUtil"
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Card, CardBody, Table, Label, Button, Input, Nav, NavItem, NavLink, Collapse, TabContent, TabPane, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup } from "reactstrap";
import { setSelecetdSchool, toggleLeftmenu, getInstitutesForTools, getSchoolsForTools, updateInstituteForTools,} from "../../store/actions";
import PaginationView from '../../components/Common/PaginationView';
import LicenseSubscriptionUpdateModal from './LicenseSubscriptionUpdateModal';

const schoolList = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/tools-institute-view/",
        exact: true,
        strict: false,
    });

    const [institute, setInstitute] = useState([]);
    const [searchInstitute, setSearchInstitute] = useState([]);
    const [allSchoolAnalytics, setAllSchoolAnalytics] = useState([])
    const [allSchool, setAllSchool] = useState([])
    const [instituteDetails, setInstituteDetails] = useState([]);

    const [selectedInstituteSchools, setSelectedInstituteSchools] = useState([])
    const [selectedInstituteId, setSelectedInstituteId] = useState(null);
    const [showPopup, setShowPopup] = useState(0);
    const [selectedSchoolPopup, setSelectedSchoolPopup] = useState()
    const [modalSubscriptionDate, setModalSubscriptionDate] = useState(new Date());
    const [modalGracePeriod, setModalGracePeriod] = useState();
    const [showLoginAlert, setShowLoginAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    const [subScriptionEndAfterGrace, setSubScriptionEndAfterGrace] = useState();
    const [subscriptionEndDate, setSubscriptionEndDate] = useState();

    const [displayInstitute, setDisplayInstitute] = useState([]);
    const [currentPage, setCurrentPage] = useState("1");
    const [allInstitutes, setAllInstitutes] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [licenceInstitute, setLicenceInstitute] = useState(undefined);
    const PAGE_SIZE = 10;
    
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    useEffect(() => {
        // console.log("****")
        setLoading(true)
        props.toggleLeftmenu(false);
        console.log("t1", new Date())
        // Parse.Cloud.run("function_getInstitutes", {
        // }).then((result) => {
        //     setInstitute(result);
        //     // setSearchInstitute(result);
        //     getAnalytics(result)
        //     // getAllSchoolTable(result,[])
        // },
        //     (error) => {
        //         console.log(error);
        //     }
        // );

        // console.log(props.toolsInstitutes)
        // props.getInstitutesForTools()

        if (!props.toolsSchools || props.toolsSchools.length == 0) {
            props.getSchoolsForTools()
        }
        if (!props.toolsInstitutes || props.toolsInstitutes == 0) {
            props.getInstitutesForTools()
        }

    }, []);

    useEffect(() => {
        if (props.toolsInstitutes && props.toolsInstitutes.length > 0 && props.toolsSchools && props.toolsSchools.length > 0) {

            const query = new Parse.Query('SchoolAnalytics');
            query.limit(5000);
            query.find().then((result) => {
                setAllSchoolAnalytics(result);
                // console.log(result)
                // getAllSchoolTable(instituteList, result)
                console.log("t2", new Date())
                prepareData(result)

            })
                .catch(error => {

                });
        }

    }, [props.toolsInstitutes, props.toolsSchools])



    // const getAllSchoolTable = (instituteList, analyticsList) => {
    //     Parse.Cloud.run("function_getAllSchoolFromTable", {
    //     }).then((result) => {
    //         console.log("t2",new Date())
    //         setAllSchool(result);
    //         prepareData(instituteList, analyticsList, result)
    //     },
    //         (error) => {
    //             console.log(error);
    //         }
    //     );
    // }

    const prepareData = (analyticsList) => {
        console.log("t3", new Date())
        let list = []
        let tschools = props.toolsSchools
        let tschoolAnalytics = analyticsList

        let instAnlyticsDictionary = {}
        for (const s of analyticsList) {   
            let list = instAnlyticsDictionary[s.attributes.instituteId]??[]
            if (list != null) {
                list.push(s)
            }
            instAnlyticsDictionary[s.attributes.instituteId] = list;
        }

        let instSchoolDictionary = {}
        for (const s of props.toolsSchools) {
            let list = instSchoolDictionary[s.attributes.instituteId]??[]
            if (list != null) {
                list.push(s)
            }
            instSchoolDictionary[s.attributes.instituteId] = list;
        }
            
        for (const inst of props.toolsInstitutes) {

            let schoolAnalytics = []
            let schoolAnalytics2 = []
            let kidCount = 0;

            // for (const s of tschoolAnalytics) {
            //     if (s.attributes.instituteId == inst.id) {
            //         schoolAnalytics.push(s)
            //         kidCount = kidCount + s.attributes.kidsCount;
            //     } else {
            //         schoolAnalytics2.push(s)
            //     }
            // }
            // tschoolAnalytics = [...schoolAnalytics2];

            schoolAnalytics = instAnlyticsDictionary[inst.id]??[]

            let schools = []
            let schools2 = []

            // for (const s of tschools) {
            //     if (!s.attributes.deleted) {
            //         if (s.attributes.instituteId == inst.id) {
            //             schools.push(s)
            //         } else {
            //             schools2.push(s)
            //         }
            //     }
            // }
            // tschools = [...schools2];

            schools = instSchoolDictionary[inst.id]??[]

            let schoolObjectList = [];

            for (const s of schools) {
                if (!s.deleted) {
                    let sa = schoolAnalytics.filter((i) => i.attributes.schoolId == s.id);

                    if (sa && sa.length > 0) {

                        schoolObjectList.push({
                            id: s.id,
                            schoolObject: s,
                            schoolAnalytics: sa[0],
                        })
                    } else {
                        schoolObjectList.push({
                            id: s.id,
                            schoolObject: s,
                            schoolAnalytics: undefined,
                        })
                    }
                }
            }


            for (const sa of schoolAnalytics) {
                kidCount = kidCount + sa.attributes.kidsCount;
            }

            let instObject = {
                id: inst.id,
                name: inst.attributes.name,
                institute: inst,
                schools: schoolObjectList,
                kidCount: kidCount,
            }

            list.push(instObject);
        }
        setSearchInstitute(list)
        setInstituteDetails(list)
        setLoading(false);
        console.log("t4", new Date())
    }

    function searchData(search) {
        // var tempList = [...searchInstitute];
        if (search === undefined || search === null || search.length === 0) {
            setInstituteDetails(searchInstitute);
        } else if (searchInstitute) {
            let filter = searchInstitute.filter((item) =>
                (item.name.toLowerCase().includes(search.toLowerCase()) || search === item.id)
            );
            setInstituteDetails(filter);
        }
    }

    const dataRefresh = () => {
        setLoading(true);
        props.getInstitutesForTools();
        props.getSchoolsForTools();
        setDisplayInstitute([])
    }


    const getSchoolNames = (institute) => {
        if (institute.id == selectedInstituteId) {
            setSelectedInstituteId(undefined);
            setSelectedInstituteSchools([]);
            return;
        }
        setSelectedInstituteId(institute.id);
        //let schools = allSchool.filter((school) => school.attributes.instituteId === instituteId);
        // console.log(schools)
        setSelectedInstituteSchools(institute.schools)

    };

    const getDate = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };

    const handleSave = () => {

        Parse.Cloud.run("function_updateSubscriptionExpiry", {
            schoolId: selectedSchoolPopup.id,
            subscriptionExpiryDate: modalSubscriptionDate,
            gracePeriod: parseInt(modalGracePeriod),
        }).then((result) => {
            console.log(result)
            let updatedListAll = ReduxUtil.updateParseObjects(allSchool, [result.SchoolAnalytics]);
            console.log(updatedListAll)
            setAllSchool(updatedListAll);
            // console.log(selectedInstituteSchools)
            // let updatselectedSchoolsList = ReduxUtil.updateParseObjects(selectedInstituteSchools, [result.SchoolAnalytics]);
            // console.log(updatselectedSchoolsList);
            // setSelectedInstituteSchools(updatselectedSchoolsList);
        },
            (error) => {
                console.log(error);
            }
        );
    }

    const handleInstituteSubscription = () => {

        Parse.Cloud.run("function_UpdateInstituteSubscription", {
            instituteId: selectedSchoolPopup.id,
            date: modalSubscriptionDate,
            gracePeriod: parseInt(modalGracePeriod),
        }).then((result) => {
            console.log(result)
            let updatedListAll = ReduxUtil.updateParseObjects(result.toolsInstitutes, [result]);
            //console.log(updatedListAll)
            setAllInstitutes(updatedListAll);
            // console.log(selectedInstituteSchools)
            // let updatselectedSchoolsList = ReduxUtil.updateParseObjects(selectedInstituteSchools, [result.SchoolAnalytics]);
            // console.log(updatselectedSchoolsList);
            // setSelectedInstituteSchools(updatselectedSchoolsList);

            props.updateInstituteForTools(updatedListAll)


        },
            (error) => {
                console.log(error);
            }
        );
    }
    const handleGrantAccess = (instituteId) => {

        Parse.Cloud.run("function_getSchoolAdminAccess", {
            instituteId: instituteId,

            gracePeriod: parseInt(modalGracePeriod),
        }).then((result) => {
            // console.log(result)
            setShowLoginAlert(true);


        },
            (error) => {
                console.log(error);
            }
        );
    }

    useEffect(() => {
        let newDate = new Date(modalSubscriptionDate);
        newDate.setDate(newDate.getDate() + modalGracePeriod);
        const today = new Date();
        const diffTime = newDate.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));
        setSubScriptionEndAfterGrace(diffDays);

        setSubscriptionEndDate(newDate);

    }, [modalSubscriptionDate, modalGracePeriod])

    const getFormattedDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };



    const getMessage = (lastMessageSent) => {
        if (!lastMessageSent) {
            return { message: '--', color: 'black' };
        }
        const currentDate = new Date();
        const timeDifference = Math.abs(currentDate.getTime() - lastMessageSent.getTime());
        const differenceInDays = Math.ceil(timeDifference / (1000 * 3600 * 24));

        if (differenceInDays < 7) {
            return { message: 'Active', color: 'green' };
        } else if (differenceInDays < 15) {
            return { message: '7 days ago', color: 'darkyellow' };
        } else {
            return { message: 'Inactive', color: 'red' };
        }
    };


    useEffect(() => {
        // console.log(instituteDetails)
        // if (false) {


        //     let list = []
        //     for (const inst of institute) {

        //         let schoolAnalytics = allSchoolAnalytics.filter((s) => s.attributes.instituteId == inst.id);

        //         let schools = allSchool.filter((s) => s.attributes.instituteId == inst.id);



        //         let schoolObjectList = [];

        //         for (const s of schools) {
        //             let sa = schoolAnalytics.filter((s) => s.attributes.schoolId == s.id);

        //             if (sa && sa.length > 0) {

        //                 schoolObjectList.push({
        //                     id: s.is,
        //                     schoolObject: s,
        //                     schoolAnalytics: sa[0],
        //                 })
        //             } else {
        //                 schoolObjectList.push({
        //                     id: s.is,
        //                     schoolObject: s,
        //                     schoolAnalytics: undefined,
        //                 })
        //             }
        //         }

        //         let kidCount = 0;

        //         for (const sa of schoolAnalytics) {
        //             kidCount = kidCount + sa.attributes.kidsCount;
        //         }

        //         let instObject = {
        //             id: inst.id,
        //             name: inst.attributes.name,
        //             institute: inst,
        //             schools: schoolObjectList,
        //             kidCount: kidCount,
        //         }

        //         list.push(instObject);
        //     }
        //     setSearchInstitute(list)
        //     setInstituteDetails(list)
        // }
    }, [institute, allSchoolAnalytics, allSchool]);

    useEffect(() => {
        let is = (parseInt(currentPage) - 1) * PAGE_SIZE;
        let ie = (parseInt(currentPage) * PAGE_SIZE) - 1;
        if (ie > instituteDetails.length) {
            ie = instituteDetails.length
        }
        if (ie != 0) {
            let tempSchools = [];
            for (let i = is; i <= ie; i++) {
                if (instituteDetails.length > i) {
                    tempSchools.push(instituteDetails[i]);
                }
            }
            setDisplayInstitute(tempSchools)
        } else {
            setDisplayInstitute([])
        }

    }, [instituteDetails, currentPage]);

    return (
        <div className='page-content  w-100 '  >
            {showLoginAlert && (
                <SweetAlert
                    success
                    showCancel
                    cancelBtnText="Cancel"
                    confirmBtnText="Login!!"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="primary"
                    title="Success!"
                    onCancel={() => {
                        setShowLoginAlert(false);
                    }}
                    onConfirm={() => {
                        window.open('https://dashboard.liltriangle.com/login', '_blank');
                    }}
                >
                    <div>
                        {" "}
                        {props.t("There you go access granted")}
                    </div>
                </SweetAlert>
            )}
            <div className="search-box position-fixed" style={{ width: "75%" }} >
                <Row>
                    <Col lg={5} md={6} xs={12}>
                        <div className="position-relative">
                            <Input
                                type="text"
                                className="form-control border-1 mb-3 search-input"
                                placeholder="Search..."
                                onChange={(e) => {
                                    searchData(e.target.value);
                                }}
                            />
                            <i
                                className="bx bx-search-alt search-icon"
                                style={{
                                    fontSize: "20px",
                                    color: "blue"
                                }}
                            ></i>
                        </div>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <div className="float-right">
                            <p className="text-success" style={{ cursor: "pointer" }} onClick={dataRefresh}>
                                <u>
                                    <i className="bx bx-refresh font-size-16 align-middle"></i>
                                    Refresh
                                </u>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="mt-5">
                <Card style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    <CardBody>
                        <Row>
                            <Col className='float-right'>
                                <div className='float-right'>
                                    <PaginationView
                                        listSize={instituteDetails.length}
                                        pageSize={PAGE_SIZE}
                                        intervalSize={7}
                                        initialInterval={15}
                                        handelCurrentPage={
                                            (cp) => {
                                                setCurrentPage(cp);
                                            }
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className="mt-1 ">
                            <div className={"border rounded p-3 mb-1 "} style={{ backgroundColor: "white" }}>
                                <Row style={{ height: "8px", paddingBottom: "20px" }}>
                                    <Col lg={1}>
                                        <div >
                                            <div className="">
                                                <Label className="mb-1 font-size-14">
                                                    #
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} >
                                        <div >
                                            <div className="">
                                                <Label className="mb-1 font-size-14">
                                                    Institutes({instituteDetails.length})
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div >
                                            <Label className="mb-1 font-size-14">
                                                Licence Details
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div >
                                            <Label className="mb-1 font-size-14">
                                                Branches
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div >
                                            <Label className="mb-1 font-size-14">
                                                Childrens
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="float-right">
                                            <Label className="mb-1 font-size-14">
                                                Access
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="float-right">
                                            <Label className="mb-1 font-size-14">
                                                All Schools
                                            </Label>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {displayInstitute.map((outerItem, key) => (
                                <Card className="mb-1 bg-whites" key={outerItem.id}>
                                    <CardBody>
                                        <div >
                                            <Row>
                                                <Col lg={1}>
                                                    <div className="d-flex">
                                                        {key + 1 + ((parseInt(currentPage) - 1) * PAGE_SIZE)}
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="pt-1">
                                                        <Label className='text-primary  font-size-14"' style={{ cursor: "pointer" }}
                                                            onClick={(e) => {
                                                                getSchoolNames(outerItem);
                                                            }}
                                                        >
                                                            {outerItem.institute.attributes.name}
                                                        </Label>
                                                        <br />
                                                        <small>{outerItem.institute.id}</small>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    {/* <Row className="align-items-center">
                                                        <Col className='m-0 p-0'>
                                                            <p className='m-0 p-0'>{outerItem.institute.attributes.subscriptionExpiryDate ? getDate(outerItem.institute.attributes.subscriptionExpiryDate) : "--"}</p>
                                                            {outerItem.institute.attributes.gracePeriod ? <p className='m-0 p-0'><strong>+{outerItem.institute.attributes.gracePeriod} days</strong></p> : ""}
                                                        </Col>
                                                        <Col className='m-2 p-0'>
                                                            <Label className='text-success m-2 p-0' style={{ cursor: "pointer", marginRight:'20px' }} onClick={() => {
                                                                setSelectedSchoolPopup(outerItem);
                                                                setModalSubscriptionDate(outerItem.institute.attributes.subscriptionExpiryDate);
                                                                setModalGracePeriod(outerItem.institute.attributes.gracePeriod ? outerItem.institute.attributes.gracePeriod : 0);
                                                                setShowPopup(2);
                                                            }}>
                                                                <u>Edit</u>
                                                            </Label>
                                                        </Col>
                                                    </Row> */}
                                                    <Row className="align-items-center">
                                                        <Col className='m-2 p-0'>
                                                            <button className="btn btn-primary btn-sm" onClick={() => {
                                                                setModalOpen(!modalOpen);
                                                                setLicenceInstitute(outerItem);
                                                            }}>Licence Details</button>
                                                        </Col>
                                                    </Row>
                                                </Col>


                                                <Col>
                                                    <div className=" text-center" style={{ marginRight: '20px' }}>
                                                        <Label>{outerItem.schools.length}</Label>

                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className=" text-center">
                                                        <Label>{outerItem.kidCount}</Label>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="float-right">
                                                        <div className="mt-1 pt-1">
                                                            <i
                                                                className="bx bx-lock-open-alt"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    fontSize: "24px",
                                                                    color: "#00FF00"
                                                                }}
                                                                onClick={() => {
                                                                    handleGrantAccess(outerItem.id);
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="float-right">
                                                        <div className="mt-1 pt-1">
                                                            <Label className='text-primary ' style={{ cursor: "pointer" }} onClick={() => {
                                                                getSchoolNames(outerItem);
                                                            }}>
                                                                <u>Schools</u></Label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Collapse
                                            isOpen={selectedInstituteId === outerItem.id}
                                        >
                                            <div className="m-3">
                                                <Table className="table mb-0 table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Branches</th>
                                                            <th>Childrens</th>
                                                            <th>App Status</th>
                                                            <th>Subscription</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {selectedInstituteSchools.map((innerItem, key) => (
                                                            <tr key={key}>
                                                                <td>
                                                                    <Link target='_blank' to={'/tools-school-settings/' + innerItem.id}>
                                                                        {innerItem.schoolObject.attributes.Name}
                                                                        <br />
                                                                        {innerItem.schoolObject.id}
                                                                    </Link>
                                                                </td>

                                                                <td>
                                                                    {innerItem.schoolAnalytics && innerItem.schoolAnalytics.attributes.kidsCount}
                                                                </td>
                                                                <td>
                                                                    {innerItem.schoolAnalytics && <span style={{ color: getMessage(innerItem.schoolAnalytics.attributes.lastMessageSent).color }}>
                                                                        {getMessage(innerItem.schoolAnalytics.attributes.lastMessageSent).message}
                                                                    </span>}
                                                                </td>
                                                                <td><Row>
                                                                    <Col><p>{innerItem.schoolObject.attributes.subscriptionExpiryDate ? getDate(innerItem.schoolObject.attributes.subscriptionExpiryDate) : "--"}</p>
                                                                        {innerItem.schoolObject.attributes.gracePeriod ? <p><strong>+{innerItem.schoolObject.attributes.gracePeriod} days</strong></p> : ""}
                                                                    </Col>
                                                                    <Col><Label className='text-success ' style={{ cursor: "pointer" }} onClick={() => {
                                                                        setSelectedSchoolPopup(innerItem);
                                                                        setModalSubscriptionDate(innerItem.schoolObject.attributes.subscriptionExpiryDate);
                                                                        setModalGracePeriod(innerItem.schoolObject.attributes.gracePeriod ? innerItem.schoolObject.attributes.gracePeriod : 0)
                                                                        setShowPopup(1)
                                                                    }}>
                                                                        <u>Edit</u></Label></Col></Row>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Collapse>
                                    </CardBody>
                                </Card>
                            ))
                            }
                        </div>
                    </CardBody>
                </Card>
            </div>
            <Modal
                size="md"
                isOpen={showPopup > 0 ? true : false}
                toggle={() => {
                    setShowPopup(0)
                    setSelectedSchoolPopup(undefined)
                    setModalSubscriptionDate(undefined)
                    setModalGracePeriod(undefined)
                }}
            >
                <ModalHeader
                    toggle={() => {
                        setShowPopup(0)
                        setSelectedSchoolPopup(undefined)
                        setModalSubscriptionDate(undefined)
                        setModalGracePeriod(undefined)
                    }}
                >
                    {" "}
                    Subscription date
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col lg="5">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>Subscription end date :</Label>
                                    <DatePicker
                                        selected={modalSubscriptionDate}
                                        onChange={(date) => {
                                            setModalSubscriptionDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={"Select Date"}
                                        dateFormat="dd/MM/yyyy"
                                        showFullMonthYearPicker
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="5">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>Grace Period:</Label>
                                    <Input className="form-control"
                                        type="number"
                                        min={0}
                                        id="gracePeriod"
                                        placeholder="Grace Period"
                                        required
                                        value={modalGracePeriod}
                                        onChange={(e) => {
                                            setModalGracePeriod(e.target.value)
                                        }}
                                    />

                                </FormGroup>
                            </Col>

                        </Row>
                        <p className='text-danger'>Subscription Ends in <b>{subScriptionEndAfterGrace ? subScriptionEndAfterGrace : "--"}</b> days, and date is <b>{subscriptionEndDate ? getFormattedDate(subscriptionEndDate) : "--"}</b></p>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            if (showPopup == 1) {
                                handleSave();
                            }
                            if (showPopup == 2) {
                                handleInstituteSubscription()
                            }

                            setShowPopup(0)
                        }}
                    >
                        <i className="align-middle"></i>
                        Save
                    </button>
                </ModalFooter>
            </Modal>
            {loading && (
                <Col xs="12">
                    <div className="text-center py-4">
                        <Link to="#" className="text-success">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                            Loading....{" "}
                        </Link>
                    </div>
                </Col>
            )}
            {displayInstitute.length === 0 && !loading && (
                <Col>
                    <h3 className="text-center text-info mt-3">
                        {props.t("No Data Found For The Filter")}
                    </h3>
                </Col>
            )}

            <LicenseSubscriptionUpdateModal showPopup={modalOpen} institute={licenceInstitute} handelClose={() => { setModalOpen(false) }} />
        </div>
    )
}

const mapStatetoProps = (state) => {
    const { selectedSchool, toolsInstitutes, toolsSchools } = state.School;
    return { selectedSchool, toolsInstitutes, toolsSchools };
};
export default connect(mapStatetoProps, {
    setSelecetdSchool, toggleLeftmenu, getInstitutesForTools, getSchoolsForTools, updateInstituteForTools,
})(withRouter(withNamespaces()(schoolList)));


