import React, { useState, useEffect } from "react";
import {Row,} from "reactstrap";
import { withNamespaces } from 'react-i18next';

//Import Cards
import RatingCards from "./RatingCards";

//Import Image
import img1 from "../../assets/images/companies/img-1.png";
import RatingCardsDetails from "./RatingCardsDetails";




const SchoolRatings = (props) => {


    const [ratingCardList, setRatingCardList] = useState([])
    const [shouldShowCardList, setShouldShowCardList] = useState(true)
    const [selectedCard, setSelectedCard] = useState(0)
    const [allParentIds, setAllParentIds] = useState([])

    useEffect(() => {
        initialSetup()
    }, [props.ratingParams, props.schoolRatings]);

    const initialSetup = () => {
        if (props.ratingParams != null && props.ratingParams.length > 0 && props.schoolRatings.length > 0) {
            var i = 0
            var ratingCardTemp = []
            var allParentsIds = []
            for (i = 0; i < props.ratingParams.length; i++) {
                var j = 0;
                var ratingObjects = []
                var isScaleTypeStars = false
                var isScaleThumb = false
                if (props.ratingParams[i].attributes.scaleType === 1) {
                    isScaleTypeStars = true
                } else if (props.ratingParams[i].attributes.scaleType === 3) {
                    isScaleThumb = true
                }
                var avgRatingValue = 0.0
                var totalRatingValues = 0.0
                var upCount = 0
                var downCount = 0


                for (j = 0; j < props.schoolRatings.length; j++) {

                    if (props.schoolRatings[j].attributes.paramId === props.ratingParams[i].id) {

                        if (!allParentsIds.includes(props.schoolRatings[j].attributes.createdBy)) {
                            allParentsIds.push(props.schoolRatings[j].attributes.createdBy)
                        }

                        ratingObjects.push(props.schoolRatings[j])
                        if (isScaleTypeStars) {
                            totalRatingValues = totalRatingValues + parseFloat(props.schoolRatings[j].attributes.value)
                        }
                        else if (isScaleThumb) {
                            if (props.schoolRatings[j].attributes.value.toLowerCase() === "up") {
                                upCount += 1
                            } else if (props.schoolRatings[j].attributes.value.toLowerCase() === "down") {
                                downCount += 1
                            }
                        }
                    }

                }

                if (isScaleTypeStars) {
                    avgRatingValue = totalRatingValues / ratingObjects.length
                }

                var cardObject = {
                    id: i + 1,
                    img: img1,
                    name: "",
                    description: props.ratingParams[i].attributes.parameter,
                    paramDisplayName: props.ratingParams[i].attributes.paramDisplayName,
                    ratingValue: avgRatingValue,
                    upCount: upCount,
                    downCount: downCount,

                    scaleType: props.ratingParams[i].attributes.scaleType,
                    ratings: ratingObjects
                };
                ratingCardTemp.push(cardObject)
            }

            setAllParentIds(allParentsIds)
            // console.log("allParentsIds",allParentsIds)
            setRatingCardList(ratingCardTemp)

        }
    }


    const handleCardTap = (key) => {
        setShouldShowCardList(!shouldShowCardList)
        setSelectedCard(key - 1)
    }


    return (
        <React.Fragment>
            <Row className="ml-0 mr-0">
                {shouldShowCardList ?
                    <RatingCards
                        projects={ratingCardList}
                        handleCardTap={(key) => { handleCardTap(key) }} />
                :
                    <RatingCardsDetails
                        ratingCardList={ratingCardList}
                        handleCardTap={(key) => { handleCardTap(key) }}
                        selectedCardNumber={selectedCard}
                        allParentIds={allParentIds} />
                }
            </Row>  
        </React.Fragment >
    );
};



export default withNamespaces()(SchoolRatings);