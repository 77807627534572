import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
    Row, Col, Card, CardBody, Nav, NavItem, NavLink, Container, Button, Modal,
    ModalHeader, ModalBody, ModalFooter, Input, Form, FormGroup, Table,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip
} from "reactstrap";
import classnames from "classnames";
import Parse from 'parse';
import toastr from 'toastr';
import { connect } from "react-redux";
import { matchPath } from "react-router";
import DatePicker from "react-datepicker";

const WhatsappTemplates = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/tools-whatsapp-templates/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;

    const [activeTab, setActiveTab] = useState("1");
    const [modal, setModal] = useState(false);
    const [message, setMessage] = useState("");
    const [headerVariables, setHeaderVariables] = useState([]);
    const [bodyVariables, setBodyVariables] = useState([]);    
    const [buttonVariables, setButtonVariables] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const [senderNumber, setSenderNumber] = useState("");
    const [headerVariableType, setHeaderVariableType] = useState("None");
    const [headerVariableValue, setHeaderVariableValue] = useState("");
    const [mediaFile, setMediaFile] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [templateToEdit, setTemplateToEdit] = useState(null);

    const [existingTemplates, setExistingTemplates] = useState([]);
    const [followupDays, setFollowupDays] = useState(""); 
    const [followupTime, setFollowupTime] = useState(new Date());

    const [enquiryFollowups, setEnquiryFollowups] = useState([]);
    const [whatsappTemplateMaps, setWhatsappTemplateMaps]= useState([]);

    const [enquiryFollowupsList, setEnquiryFollowupsList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [schoolObject, setSchoolObject] = useState(null);



    const [standerdTemplates, setStanderdTemplates] = useState([
        { id: 1, value: "ENQUIRY_WELCOME", name: "Enquiry Welcome",},
        { id: 2, value: "ADMISSION", name: "Admission",},
        { id: 3, value: "APP_INVITE", name: "App Invite",},
        { id: 4, value: "INVOICE_CREATION", name: "Invoice Creation",},
        { id: 5, value: "PAYMENT_REMINDER", name: "Payment Reminder",},
        { id: 6, value: "TRANSACTION", name: "Transaction",},
    ]);

    const [addButtonVariables, setAddButtonVariables] = useState([
        { id: 1, names: "button_1", key: ""},
        { id: 2, names: "button_2",key: "" },
        { id: 3, names: "button_3", key: ""},
    ]);

    const toggleCustom = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        const WhatsappTemplateMap = Parse.Object.extend("WhatsappTemplateMap");
        const query = new Parse.Query(WhatsappTemplateMap);
        query.equalTo("schoolId", schoolId);
    
        query.find().then((results) => {

            setWhatsappTemplateMaps(results);
            fetchEnquiryFollowups();
            


        }).catch((error) => {
            console.log("Error fetching existing templates:", error);
            // toastr.error("Error fetching existing templates.");
        });
    }, [schoolId]);

    const fetchEnquiryFollowups = () => {
        const query = new Parse.Query('EnquiryFollowup');
        query.equalTo("schoolId", schoolId)
        query.ascending("followupNumber")
        query.find().then((results) => {
            // console.log("Fetched Enquiry Follow-ups:", results);
            setEnquiryFollowups(results);
        }).catch((error) => {
            console.error("Error fetching enquiry follow-ups:", error);
        });
    };

    useEffect(() => {
        if (schoolId) {
            Parse.Cloud.run("function_getSchoolDetailsForSchoolId", { schoolId })
                .then((result) => {
                    // console.log("School Details Result:", result);
                    setSchoolObject(result);
                })
                .catch((error) => {
                    console.error("Error fetching school details:", error);
                });
        }
    }, [schoolId]);



    useEffect(() => { 

        //tab 1
        let standerdTemps = []

        for(const st of standerdTemplates){
            let exwm = undefined
            for(const wm of whatsappTemplateMaps){
                if(st.value == wm.attributes.type){
                    exwm = wm 
                }
            }
            st.whatsappTemplateMap = exwm



            if(st.value == "ENQUIRY_WELCOME"){
                let welcomFolloup = enquiryFollowups.find((ef) => (ef.attributes.followupName == "Welcome"))
                st.welcomFolloup = welcomFolloup
            }
            if(st.value == "ADMISSION"){
                let admissionFolloup = enquiryFollowups.find((ef) => (ef.attributes.followupName == "Admission"))
                st.admissionFolloup = admissionFolloup
            }

            standerdTemps.push(st)
        }
        setStanderdTemplates(standerdTemps)

        //tab 2


        let followups=[]


        for(const ef of enquiryFollowups){
            if(ef.attributes.followupName=="followup" ){
                let eObject = {
                    id:ef.id,
                    enquiryFollowup:ef,
                    enquiryFollowupNumber :ef.attributes.followupNumber
                }
                let whatsappTemplateMap ;
                for(const wt of whatsappTemplateMaps){
                    if(wt.attributes.type.endsWith(ef.attributes.followupNumber+"")){
                        whatsappTemplateMap=wt
                    }
                }
                eObject.whatsappTemplateMap = whatsappTemplateMap;
                followups.push(eObject);
            }
            
        }

        // console.log("followups:",followups)

        setEnquiryFollowupsList(followups)


    }, [whatsappTemplateMaps,enquiryFollowups,]);


    const handleTemplateClick = (template) => {
        setSelectedTemplate(template);
        setMessage("");
        setBodyVariables([]);
        setButtonVariables([]);
        setAddButtonVariables([
        { id: 1, names: "button_1",  key: "" },
        { id: 2, names: "button_2", key: "" },
        { id: 3, names: "button_3",key: "" },
        ]);
        setHeaderVariableType("None");
        setHeaderVariableValue("");
        setMediaFile(null);
        setModal(true);
        setTemplateName("");
        setSenderNumber("")
        fetchTemplateData(template);
    };
    
    const fetchTemplateData = (template) => {
        
        
        let waTemplateObject = template.whatsappTemplateMap;

        if (!waTemplateObject) {
            setTemplateToEdit(null);
            
        }else{
            // console.log("Result", waTemplateObject);
            setTemplateToEdit(waTemplateObject);

            setTemplateName(waTemplateObject.attributes.templateName);
            setSenderNumber(waTemplateObject.attributes.phoneNumber);
            setMessage(waTemplateObject.attributes.sampleMessage);

            const variableMap = waTemplateObject.attributes.variableMap || {};
            const fetchedHeaderType = variableMap.header_1?.type || "None";
            setHeaderVariableType(fetchedHeaderType);

            if (fetchedHeaderType.toLowerCase() === "text") {
                setHeaderVariableValue(variableMap.header_1?.key || "");
            } else {
                setHeaderVariableValue("");
            }
            
            
            const headerFile = waTemplateObject.attributes.headerFile; 
            if (headerFile) {
                setMediaFile(headerFile.url());
            } else {
                setMediaFile(null);
            }
            // Set Body Variables
            const bodyVariablesArray = [];
            for (const key in variableMap) {
                if (key.startsWith("body_")) {
                    bodyVariablesArray.push({
                        id: Number(key.split("_")[1]), 
                        placeholder: `{{${key}}}`,
                        selectedVariable: variableMap[key]?.key || "",
                    });
                }
            }
            setBodyVariables(bodyVariablesArray);
            // Set Button Variables
            setAddButtonVariables(
                addButtonVariables.map((button) => {
                    const savedButton = variableMap[button.names]; 
                    return {
                        ...button,
                        key: savedButton?.key || button.key,
                        type: "text",
                        subtype: "url"
                    };
                })
            )

        }
        if (template.enquiryFollowup) {
            
            let followupResult = template.enquiryFollowup
            // console.log("Followupresult",followupResult)

            setFollowupDays(followupResult.attributes.noofDaysFromEnquiry || "");
            setFollowupTime(followupResult.attributes.followupTime);
            setMessage(followupResult.attributes.message || "");
        }
    };
    
    
    
    const handleAddBodyVariable = () => {
        const newBodyVariable = {
            id: bodyVariables.length + 1,
            placeholder: `{{body_${bodyVariables.length + 1}}}`,
            selectedVariable: '',
        };
        setBodyVariables((prevBodyVariables) => [...prevBodyVariables, newBodyVariable]);
    };

    const handleDeleteBodyVariable = (id) => {
        const updatedBodyVariables = bodyVariables.filter((variable) => variable.id !== id);
        setBodyVariables(updatedBodyVariables);
    };

    const handleDeleteButtonVariable = (id) => {
        const updatedButtonVariables = addButtonVariables.filter((buttonVariable) => buttonVariable.id !== id);
        setAddButtonVariables(updatedButtonVariables);
    };
    
    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    }

    const handleVariableChange = (id, value) => {
        const updatedBodyVariables = bodyVariables.map((variable) =>
            variable.id === id
                ? { ...variable, selectedVariable: value }
                : variable
        );
        setBodyVariables(updatedBodyVariables);
    };

    const handleSave = () => {
        if (activeTab === "1") {
            if (!templateName || !senderNumber || !message) {
                if (!templateName) {
                    toastr.error("Template name is required.");
                } 
                if (!senderNumber) {
                    toastr.error("Sender number is required.");
                } 
                if (!message) {
                    toastr.error("Message is required.");
                }
                setLoading(false);
                return;
            }
        }
        
        if (activeTab === "2") {
            if (!followupTime || !followupDays || !selectedTemplate?.enquiryFollowupNumber) {
                if (!followupTime) {
                    toastr.error("Follow-up time is required.");
                }
                if (!followupDays) {
                    toastr.error("Number of days from enquiry is required.");
                }
                if (!selectedTemplate?.enquiryFollowupNumber) {
                    toastr.error("Follow-up number is required.");
                }
                setLoading(false);
                return;
            }
        }
        setLoading(true);
        const variableMap = {};
        // Body Variables
        for (let index = 0; index < bodyVariables.length; index++) {
            let variable = bodyVariables[index];
            if (variable.selectedVariable) {
                let bodyKey = `body_${index + 1}`;
                variableMap[bodyKey] = {
                    type: "text",
                    key: variable.selectedVariable,
                };
            }
        }
        // Button Variables
        for (let index = 0; index < addButtonVariables.length; index++) {
            let buttonVariable = addButtonVariables[index];
            if (buttonVariable.key && buttonVariable.key.trim() !== "") {
                let buttonKey = `button_${index + 1}`;
                variableMap[buttonKey] = {
                    subtype: "url",
                    type: "text",
                    key: buttonVariable.key,
                };
            }
        }
    
        // HEADER Variables
        if (headerVariableType !== "None") {
            if (headerVariableType === "text" && headerVariableValue.trim() !== "") {
                variableMap["header_1"] = {
                    key: headerVariableValue,
                    type: "text",
                };
            } else if (["image", "video", "document"].includes(headerVariableType)) {
                if (!mediaFile && !templateToEdit) {
                    toastr.error("Please upload a media file for the header.");
                    setLoading(false);
                    return;
                }
    
                let mediaFileUrl = mediaFile;
    
                if (mediaFile && mediaFile instanceof File) {
                    const parseFile = new Parse.File(mediaFile.name, mediaFile);
                    parseFile.save().then((savedFile) => {
                        mediaFileUrl = savedFile.url();
                        variableMap["header_1"] = {
                            type: headerVariableType.toLowerCase(),
                            value: mediaFileUrl,
                        };
                        saveTemplate(variableMap);
                        setLoading(false);
                    }).catch((error) => {
                        console.error("Error saving media file:", error);
                        toastr.error("Error uploading media file.");
                        setLoading(false);
                    });
                    return;
                } else if (templateToEdit && mediaFileUrl) {
                    variableMap["header_1"] = {
                        type: headerVariableType.toLowerCase(),
                        value: mediaFileUrl,
                    };
                } else {
                    toastr.error("Invalid media file.");
                    setLoading(false);
                    return;
                }
            }
        }
        saveTemplate(variableMap);
        setLoading(false);
    };
    
    const saveTemplate = (variableMap) => {
        let photoFile = null;
        if (mediaFile && mediaFile instanceof File) {
            photoFile = new Parse.File(mediaFile.name, mediaFile);
        }
    
        let templateType = selectedTemplate?.value;
    
        


        const WhatsappTemplates = Parse.Object.extend('WhatsappTemplateMap');
        const template = selectedTemplate.whatsappTemplateMap ? selectedTemplate.whatsappTemplateMap : new WhatsappTemplates();
        // console.log("Template",template)

        template.set("variableMap", variableMap);
        template.set("phoneNumber", senderNumber);
        template.set("templateName", templateName);
        template.set("schoolId", schoolId);
        template.set("sampleMessage", message);

        if (photoFile) {
            template.set("headerFile", photoFile);
        }
        if (activeTab === "2") {
            templateType = `ENQUIRY_FOLLOWUP_${selectedTemplate.enquiryFollowupNumber}`;
        }

        template.set("type", templateType);

        template.save().then((result) => {
            

            const EnquiryFollowup = Parse.Object.extend("EnquiryFollowup");
            let followup
            let updatedObject = selectedTemplate;
            updatedObject.whatsappTemplateMap = result;
            if(activeTab==2){
                
                followup = selectedTemplate.enquiryFollowup ??  new EnquiryFollowup();

                followup.set("noofDaysFromEnquiry", parseInt(followupDays));
                followup.set("message", message);
                followup.set("schoolId", schoolId);
                followup.set("followupName", "followup");
                followup.set("followupTime", followupTime);
                followup.set("whatsappTemplateId", result.id);
                followup.set("followupNumber", selectedTemplate.enquiryFollowupNumber);
                

                followup.save().then((rFolllowup)=>{
                    updatedObject.enquiryFollowup = rFolllowup;
                    if(updatedObject.id.startsWith("new_")){
                        let list = [...enquiryFollowupsList]
                        updatedObject.id = followup.id;
                        list.push(updatedObject)
                        setEnquiryFollowupsList(list);
                        updateSave()
                    }else{
                        let list = [];
                        for(const ef of enquiryFollowupsList){
                            if(ef.id==updatedObject.id){
                                list.push(updatedObject)
                            }else{
                                list.push(ef)
                            }
                        }
                        setEnquiryFollowupsList(list);
                        updateSave()
                    }
                })
            }else if (activeTab === "1" && selectedTemplate.value == "ADMISSION") {

                
                followup = selectedTemplate.admissionFolloup ??  new EnquiryFollowup();

                followup.set("message", message);
                followup.set("schoolId", schoolId);
                followup.set("followupName", "Admission");
                followup.set("whatsappTemplateId", result.id);
                followup.set("followupNumber", -1);
                followup.save().then((rFolllowup)=>{
                    updatedObject.admissionFolloup = rFolllowup;
                    let list = [];
                    for(const ef of standerdTemplates){
                        if(ef.id==updatedObject.id){
                            list.push(updatedObject)
                        }else{
                            list.push(ef)
                        }
                    }
                    setSelectedTemplate(list);
                    updateSave()
                })
                
                
            } else if (activeTab === "1" && selectedTemplate.value == "ENQUIRY_WELCOME") {

                
                followup = selectedTemplate.welcomFolloup ??  new EnquiryFollowup();

                followup.set("message", message);
                followup.set("schoolId", schoolId);
                followup.set("followupName", "Welcome");
                followup.set("whatsappTemplateId", result.id);
                followup.set("followupNumber", 0);
                followup.save().then((rFolllowup)=>{
                    updatedObject.welcomFolloup = rFolllowup;
                    let list = [];
                    for(const ef of standerdTemplates){
                        if(ef.id==updatedObject.id){
                            list.push(updatedObject)
                        }else{
                            list.push(ef)
                        }
                    }
                    setSelectedTemplate(list);
                    updateSave()
                })

                
                
            } else if (activeTab === "1"){
                let list = [];
                for(const ef of standerdTemplates){
                    if(ef.id==updatedObject.id){
                        list.push(updatedObject)
                    }else{
                        list.push(ef)
                    }
                }
                setSelectedTemplate(list);
                updateSave()
            }

            

            
            
        });
        
       
    };

    const updateSave=(result)=>{
        toastr.success("Saved successfully");
            setModal(false);
            setLoading(false);
            setTemplateToEdit(result);
            fetchTemplateData(result);
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setMediaFile(file);
            setHeaderVariableValue(file.name);
        }
    };
    
    return (
        <div className="page-content">
            <Container fluid>
                <Row className="d-flex align-items-start">
                    <Col className="d-flex align-items-start">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                window.history.back();
                            }}
                        >
                            <ul className="list-unstyled">
                                <div>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <Row>
                            <Col className="m-0">
                                <h4 className="mt-2">
                                    {props.t("Whatsapp Templates")} ({schoolObject?.attributes?.Name})

                                </h4>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Card>
                    <CardBody>
                        <Nav pills className="navtab-bg nav-justified mb-2">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({ active: activeTab === "1" })}
                                    onClick={() => {
                                        toggleCustom("1");
                                    }}
                                >
                                    {props.t("Standard").toUpperCase()}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({ active: activeTab === "2" })}
                                    onClick={() => {
                                        toggleCustom("2");
                                    }}
                                >
                                    {props.t("Enquiry Followup").toUpperCase()}
                                </NavLink>
                            </NavItem>
                        </Nav>

                        {activeTab === "1" && (
                            <div className="table-responsive">
                                <Table className="table-centered table-nowrap table-hover mt-2">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" style={{ width: "70px" }}>#</th>
                                            <th scope="col">{props.t("Type")}</th>
                                            <th scope="col">{props.t("Action")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {standerdTemplates.map((template, index) => {
                                            return (
                                                <tr key={template.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{template.name}</td>
                                                    <td>
                                                        <Button
                                                            color="primary"
                                                            onClick={() => handleTemplateClick(template)}
                                                        >
                                                            {template.whatsappTemplateMap ? props.t("Update") : props.t("Add")}

                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        )}

                        <div className="text-end">
                            {activeTab === "2" && (
                                <Button color="primary" className="text-end ms-3 mt-2" onClick={() => {
                                    let d = new Date();
                                    setSelectedTemplate({
                                        id: "new_" + d.getMilliseconds(),
                                        enquiryFollowup:undefined,
                                        whatsappTemplateMap: undefined,
                                        enquiryFollowupNumber: enquiryFollowupsList.length + 1
                                    });
                                    setTemplateName("");
                                    setSenderNumber("");
                                    setMessage("");
                                    setBodyVariables([]);
                                    setButtonVariables([]);
                                    setAddButtonVariables([
                                        { id: 1, names: "button_1", key: "" },
                                        { id: 2, names: "button_2", key: "" },
                                        { id: 3, names: "button_3", key: "" },
                                    ]);
                                    setFollowupDays("");
                                    setFollowupTime(null);
                                    setModal(true);
                                }}>
                                    Enquiry Followup
                                </Button>
                            )}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {activeTab === "2" && (
                                <Table className="table-centered table-nowrap table-hover mt-2">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" style={{ width: "70px" }}>#</th>
                                        <th scope="col">{props.t("Name")}</th>
                                        <th scope="col">{props.t("Action")}</th>
                                    </tr>
                                </thead>
                                    <tbody>
                                        {enquiryFollowupsList.map((template, index) => {
                                            return (
                                                <tr key={template.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{"Followup "+template.enquiryFollowupNumber}</td>
                                                    <td>
                                                        <Button
                                                            color="primary"
                                                            onClick={() => handleTemplateClick(template)}
                                                        >
                                                            {props.t("Update")}
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>

                            </Table>
                            )}

                        </div>


                        <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
                            <ModalHeader toggle={() => setModal(!modal)}>
                                {selectedTemplate && activeTab=="2" ? "Followup "+ selectedTemplate.enquiryFollowupNumber: `${selectedTemplate && selectedTemplate.name}`}
                            </ModalHeader>

                            <ModalBody>
                                <Row className="mb-4">
                                    <Form>
                                        <FormGroup>
                                            <Row className="mt-2">
                                                <Col md={4}>
                                                    <label>Template Name</label>
                                                    <label className="text-danger">*</label>
                                                    </Col>
                                                <Col md={6}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter"
                                                        value={templateName}
                                                        onChange={(e) => setTemplateName(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Row>
                                                <Col md={4}>
                                                    <label>Sender Number</label>
                                                    <label className="text-danger">*</label>
                                                    </Col>
                                                <Col md={6}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter"
                                                        value={senderNumber}
                                                        onChange={(e) => setSenderNumber(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Form>
                                    <Col md={12}>
                                        <h6>{props.t("Message")}<span style={{ color: "red" }}>*</span></h6>
                                        <Card className="p-3">
                                            <textarea
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                rows={5}
                                                style={{
                                                    width: "100%",
                                                    resize: "none",
                                                    fontFamily: "monospace",
                                                    border: "1px solid #ccc",
                                                    padding: "10px",
                                                }}
                                            />
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12} className="mt-0">
                                        <p>Header</p>
                                        <Row className="align-items-center">
                                            <Col lg='3' className="mb-2">
                                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                                    <DropdownToggle tag="button" className="btn btn-light">
                                                        {headerVariableType} <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => setHeaderVariableType("None")}>None</DropdownItem>
                                                        <DropdownItem onClick={() => setHeaderVariableType("text")}>Text</DropdownItem>
                                                        <DropdownItem onClick={() => setHeaderVariableType("video")}>Video</DropdownItem>
                                                        <DropdownItem onClick={() => setHeaderVariableType("image")}>Image</DropdownItem>
                                                        <DropdownItem onClick={() => setHeaderVariableType("document")}>Document</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>

                                            <Col lg='6'>
                                                {headerVariableType === "text" && (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Input
                                                            type="text"
                                                            placeholder="Enter header text"
                                                            value={headerVariableValue}
                                                            onChange={(e) => setHeaderVariableValue(e.target.value)}
                                                        />
                                                        <div
                                                            className="flex-fill text-center"
                                                            style={{ cursor: "pointer", marginLeft: '10px' }}
                                                            onClick={() => {
                                                                setHeaderVariableType("None");
                                                                setHeaderVariableValue("");
                                                            }}
                                                        >
                                                            <i className="bx bxs-trash text-danger"></i>
                                                        </div>
                                                    </div>
                                                )}
                                                {["image", "video", "document"].includes(headerVariableType) && (
                                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                        <Input
                                                            type="file"
                                                            accept={
                                                                headerVariableType === "image"
                                                                    ? "image/png, image/jpeg, image/gif"
                                                                    : headerVariableType === "video"
                                                                        ? "video/mp4, video/x-m4v, video/*"
                                                                        : "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                            }
                                                            onChange={handleFileChange}
                                                            style={{ display: 'block', marginTop: '10px' }}
                                                        />

                                                        {mediaFile && (
                                                            <div
                                                                className="flex-fill text-center"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setMediaFile(null);
                                                                    setHeaderVariableValue("");
                                                                }}
                                                            >
                                                                <i className="bx bxs-trash text-danger"></i>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}

                                                {mediaFile && (
                                                    <div style={{ marginTop: "10px" }}>
                                                        File: <a href={mediaFile} target="_blank" rel="noopener noreferrer">View</a>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <p className="mt-0">Body</p>
                                        <Button color="secondary" className="mt-0 mb-2" onClick={handleAddBodyVariable}>
                                            {props.t("Add Body Variable")}
                                        </Button>
                                    </Col>
                                </Row>

                                {bodyVariables.map((variable) => (
                                    <Row key={variable.id} className="mt-3">
                                        <Col md={5}>
                                            <Input
                                                value={variable.placeholder}
                                                style={{ marginBottom: "10px" }}
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <select
                                                value={variable.selectedVariable}
                                                onChange={(e) => handleVariableChange(variable.id, e.target.value)}
                                                className="form-control"
                                                style={{ padding: "10px", fontSize: "12px" }}
                                            >   
                                                {selectedTemplate?.name === "Enquiry Welcome" &&  (
                                                    <>
                                                        <option value="disabled">Select</option>
                                                        <option value="parentName">Parent Name</option>
                                                        <option value="studentName">Student Name</option>
                                                        <option value="schoolName">School Name</option>
                                                        <option value="source">Source</option>
                                                        <option value="class">Class</option>
                                                        <option value="enquiryDate">Enquiry Date</option>
                                                        <option value="phoneNumber">Phone Number</option>
                                                        <option value="gender">Gender</option>
                                                        <option value="email">Email</option>
                                                    </>
                                                )}
                                                {activeTab === "2" && (
                                                    <>
                                                        <option value="disabled">Select</option>
                                                        <option value="parentName">Parent Name</option>
                                                        <option value="name">Student Name</option>
                                                        <option value="schoolName">School Name</option>
                                                        <option value="class">Class</option>
                                                        <option value="source">Source</option>
                                                        <option value="enquiryDate">Enquiry Date</option>
                                                        <option value="phoneNumber">Phone Number</option>
                                                        <option value="gender">Gender</option>
                                                        <option value="email">Email</option>
                                                    </>
                                                )}
                                                {selectedTemplate?.name === "Admission" && (
                                                    <>
                                                        <option value="disabled">Select</option>
                                                        <option value="parentName">Parent Name</option>
                                                        <option value="schoolName">School Name</option>
                                                        <option value="studentName">Student Name</option>
                                                    </>
                                                )}
                                                {selectedTemplate?.name === "App Invite" && (
                                                    <>
                                                        <option value="disabled">Select</option>
                                                        <option value="parentName">Parent Name</option>
                                                        <option value="studentName">Student Name</option>
                                                        <option value="schoolName">School Name</option>
                                                    </>
                                                )}
                                                {selectedTemplate?.name === "Invoice Creation" && (
                                                    <>
                                                        <option value="disabled">Select</option>
                                                        <option value="parentName">Parent Name</option>
                                                        <option value="studentName">Kid Name</option>
                                                        <option value="schoolName">School Name</option>
                                                        <option value="invoiceNumber">Invoice Number</option>
                                                        <option value="title">Invoice Title</option>
                                                        <option value="amount">Invoice Amount</option>
                                                    </>
                                                )}
                                                {selectedTemplate?.name === "Payment Reminder" && (
                                                    <>
                                                        <option value="disabled">Select</option>
                                                        <option value="parentName">Parent Name</option>
                                                        <option value="schoolName">School Name</option>
                                                        <option value="studentName">Kid Name</option>
                                                        <option value="invoiceNumber">Invoice Number</option>
                                                        <option value="title">Invoice Title</option>
                                                        <option value="amount">Invoice Amount</option>
                                                        <option value="paid">Paid Amount</option>
                                                        <option value="pending">Pending Amount</option>
                                                    </>
                                                )}
                                                {selectedTemplate?.name === "Transaction" && (
                                                    <>
                                                        <option value="disabled">Select</option>
                                                        <option value="parentName">Parent Name</option>
                                                        <option value="schoolName">School Name</option>
                                                        <option value="studentName">Kid Name</option>
                                                        <option value="amount">Amount</option>
                                                        <option value="paymentType">Payment Type</option>
                                                        <option value="invoiceNumber">Invoice Number</option>
                                                    </>
                                                )}
                                            </select>
                                        </Col>
                                        <Col lg="1">
                                            <div
                                                className="flex-fill text-center"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    handleDeleteBodyVariable(variable.id)
                                                }}
                                            >
                                                <i className="bx bxs-trash text-danger"></i>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                                <Col md={12}>
                                    <p className="mt-0">Button</p>


                                    {addButtonVariables.map((buttonVariable, index) => (
                                        <Row className="mt-3" key={buttonVariable.id}>
                                            {/* 1st Column */}
                                            <Col lg="2">
                                                <Input
                                                    type="text"
                                                    value={`{{${buttonVariable.names}}}`}
                                                    readOnly
                                                />
                                            </Col>
                                            <Col lg="4">
                                                <select
                                                value={buttonVariable.key}
                                                onChange={(e) => {
                                                    const updatedVariables = [...addButtonVariables];
                                                    updatedVariables[index].key = e.target.value;
                                                    setAddButtonVariables(updatedVariables);
                                                }}
                                                className="form-control"
                                                style={{ padding: "10px", fontSize: "12px" }}
                                            >   
                                                {selectedTemplate?.name === "Admission" && (
                                                    <>
                                                        <option value="disabled">Select</option>
                                                        <option value="admissionLink">Admission Link</option>
                                                    </>
                                                )}
                                                {(selectedTemplate?.name === "Payment Reminder" || selectedTemplate?.name === "Invoice Creation")&& (
                                                    <>
                                                        <option value="disabled">Select</option>
                                                        <option value="paymentLink">payment Link</option>
                                                    </>
                                                )}
                                                {selectedTemplate?.name === "Transaction" && (
                                                    <>
                                                        <option value="disabled">Select</option>
                                                        <option value="receiptLink">Receipt Link</option>
                                                    </>
                                                )}
                                                </select>

                                            </Col>
                                            <Col lg="2">
                                            <div
                                                className="flex-fill text-center ml-0"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    handleDeleteButtonVariable(buttonVariable.id)
                                                }}
                                            >
                                                <i className="bx bxs-trash text-danger"></i>
                                            </div>
                                        </Col>
                                        </Row>
                                    ))}
                                </Col>
                                <Row className="mt-3">
                                    <Col lg="6">
                                        {activeTab === "2" && (
                                            <FormGroup>
                                                <label>{props.t("Number of Days from Enquiry")}</label>
                                                <Input
                                                    type="number"
                                                    value={followupDays}
                                                    onChange={(e) => {
                                                        setFollowupDays(e.target.value)}}
                                                    placeholder="Enter number of days"
                                                />
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col lg="6">
                                        {activeTab === "2" && (
                                            <FormGroup>
                                                <label>{props.t("Followup Time")}</label>
                                                <DatePicker
                                                    selected={followupTime}
                                                    className="form-control mb-1"
                                                    onChange={(date) => {
                                                        setFollowupTime(date)}}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={10}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                />

                                            </FormGroup>
                                        )}
                                    </Col>
                                </Row>
                            </ModalBody>

                            <ModalFooter>
                                <Button color="primary" onClick={handleSave}>
                                    {templateToEdit ? props.t("Update") : props.t("Add")}
                                </Button>
                            </ModalFooter>
                        </Modal>

                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default withRouter(withNamespaces()(WhatsappTemplates));