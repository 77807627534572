import React, { useEffect, useState } from 'react';
import { Container, FormGroup, Label, Button, Row, Col, Form, Card, CardBody, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom/cjs/react-router-dom.min';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool, getLeavePolicy, updateLeavePolicy, getLeavePolicyItemsForPolicyId, getTeachersForSchool } from '../../store/actions';
import { matchPath } from "react-router";
import Parse from "parse";
import Moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from 'toastr';
import SimpleBar from "simplebar-react";

const LeavesPolicies = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/leave-policies/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-Leave-Policy/:franchiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.schoolId;
    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

    const [policyModalOpen, setPolicyModalOpen] = useState(false);
    const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
    const [editDetailsModalOpen, setEditDetailsModalOpen] = useState(false);

    const [leaves, setLeaves] = useState([]);
    const [title, setTitle] = useState('')
    const [days, setDays] = useState('');
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [titleError, setTitleError] = useState('');
    const [addLeaveError, setAddLeaveError] = useState('');
    const [addLeaveDaysError, setAddLeaveDaysError] = useState('');
    const [loading, setLoading] = useState(false);
    const [assignTeachersModalOpen, setAssignTeachersModalOpen] = useState(false);
    const [assignedTeachers, setAssignedTeachers] = useState([]);


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool]);

    useEffect(() => {
        if (schoolId) {
            props.getLeavePolicy(schoolId, 'school')
            props.getTeachersForSchool(schoolId)
        }
    }, [schoolId])

    useEffect(() => {
        if (policyModalOpen) {
            setLeaves('');
            setTitle('');
            setTitleError('');
            setAddLeaveError('');
            setAddLeaveDaysError('');
        }
    }, [policyModalOpen]);


    const handleDeletePolicy = () => {
        selectedPolicy.set("isDeleted", true);
        selectedPolicy.set("deletedBy", props.userTeacher.id);
        selectedPolicy.set("deletedOn", new Date());
    
        const LeavePolicyItem = Parse.Object.extend("LeavePolicyItems");
        const query = new Parse.Query(LeavePolicyItem);
        query.equalTo("policyId", selectedPolicy.id);
    
        query.find().then((items) => {
            items.forEach((item) => {
                item.set("isDeleted", true);
                item.set("deletedBy", props.userTeacher.id);
                item.set("deletedOn", new Date());
                item.save();
            });
    
            selectedPolicy.save();
            setShowDeleteAlert(false);
            props.updateLeavePolicy([selectedPolicy]);
        });
    };

    const handleAddLeave = () => {
        setLeaves([...leaves, { leave: '', days: '' }]);
    };

    const handleRemoveLeave = (index) => {
        const updatedLeaves = leaves.filter((_, i) => i !== index);
        setLeaves(updatedLeaves);
    };

    const handleLeaveChange = (index, value) => {
        const updatedLeaves = [...leaves];
        updatedLeaves[index].leave = value;
        setLeaves(updatedLeaves);
    };

    const handleDaysChange = (index, value) => {
        const updatedLeaves = [...leaves];
        updatedLeaves[index].days = value;
        setLeaves(updatedLeaves);
    };

    const handleViewDetails = (leavePolicy) => {
        setSelectedPolicy(leavePolicy);
        props.getLeavePolicyItemsForPolicyId(leavePolicy.id);
        setViewDetailsModalOpen(true);
    };

    useEffect(() => {
        if (props.leavesPoliciesItems && selectedPolicy) {

            const list = props.leavesPoliciesItems.filter(item => item.attributes.policyId === selectedPolicy.id).map(item => ({
                id: item.id,
                leave: item.attributes.title,
                days: item.attributes.days,
                object: item,
            }));

            setLeaves(list);
        }
    }, [props.leavesPoliciesItems, selectedPolicy]);

    const getDesignation = (role) => {
        if (role === 3) {
            return props.t("Director");
        } else if (role === 2) {
            return props.t("Center Head");
        } else {
            return props.t("Teacher");
        }
    };

    const handlePolicyAssign = () => {
        setLoading(true);
    
        const Teacher = Parse.Object.extend("Teacher");
        const teacherPromises = props.teachers.map((teacher) => {
            const query = new Parse.Query(Teacher);
            return query.get(teacher.id).then((teacherObj) => {
                if (assignedTeachers.includes(teacher.id)) {
                    teacherObj.set("leavePolicyId", selectedPolicy.id);
                } else {
                    if (teacherObj.get("leavePolicyId") === selectedPolicy.id) {
                        teacherObj.unset("leavePolicyId");
                    }
                }
                return teacherObj.save();
            }).catch((error) => {
                console.error('Error assigning teacher policy:', error);
            });
        });
    
        Promise.all(teacherPromises)
            .then(() => {
                toastr.success("Teacher policies assigned successfully", { timeOut: 1500 });
                setLoading(false);
                setAssignTeachersModalOpen(false);
            })
            .catch((error) => {
                console.error('Error saving teacher policies:', error);
                toastr.error("Error saving teacher policies", { timeOut: 3000 });
                setLoading(false);
            });
    };

    useEffect(() => {
        setAssignedTeachers([...assignedTeachers]);
    }, [assignTeachersModalOpen]);

    const handleSelectAllChange = () => {
        if (assignedTeachers.length === props.teachers.length) {
            setAssignedTeachers(assignedTeachers);
        } else {
            setAssignedTeachers(props.teachers.map(teacher => teacher.id));
        }
    };
    
    const handleEditDetails = (leavePolicy) => {
        setSelectedPolicy(leavePolicy);
        setTitle(leavePolicy.attributes.policyTitle);
        props.getLeavePolicyItemsForPolicyId(leavePolicy.id);
        setEditDetailsModalOpen(true);
    };

    
    const savePolicy = (isEdit) => {
        let isValid = true;
        if (!title) {
            setTitleError(true);
            isValid = false;
        }

        if (leaves.some(leave => !leave.leave)) {
            setAddLeaveError(true);
            isValid = false;
        }

        if (leaves.some(leave => !leave.days || isNaN(leave.days) || leave.days <= 0)) {
            setAddLeaveDaysError(true);
            isValid = false;
        }

        if (isValid) {
            let leavePolicyToUpdate;
            if (isEdit) {
                if (!selectedPolicy) {
                    console.error("Error.");
                    return;
                }
                leavePolicyToUpdate = selectedPolicy;
            } else {
                leavePolicyToUpdate = new (Parse.Object.extend('LeavePolicy'))();
            }
            const leaveTypes = leaves.map(leave => leave.leave);
            const leaveDays = leaves.map(leave => leave.days.toString());
            const totalDays = leaves.reduce((total, leave) => total + (parseInt(leave.days) || 0), 0);

            leavePolicyToUpdate.set("policyTitle", title);
            leavePolicyToUpdate.set("policyType", leaveTypes);
            leavePolicyToUpdate.set("leaveDays", leaveDays);
            leavePolicyToUpdate.set("totalDays", totalDays);

            if (!isEdit) {
                leavePolicyToUpdate.set("ownerId", schoolId);
                leavePolicyToUpdate.set("ownerType", "school");
                leavePolicyToUpdate.set("createdBy", Parse.User.current().attributes.teacherId)
            }


            leavePolicyToUpdate.save().then((result) => {
                toastr.success('Leave policy added successfully');
                props.updateLeavePolicy([result])
                setPolicyModalOpen(false);

                const LeavePolicyItem = Parse.Object.extend('LeavePolicyItems');
                const nLeavePolicyItems = leaves.map((leave, index) => {
                    let leavePolicyItem = new LeavePolicyItem();
                    if (leave.object) {
                        leavePolicyItem = leave.object;
                    } else {
                        leavePolicyItem.set("policyId", result.id)
                    }
                    leavePolicyItem.set("title", leave.leave);
                    leavePolicyItem.set("days", parseInt(leave.days));
                    leavePolicyItem.set("order", index);

                    return leavePolicyItem;
                });

                let dList = [];
                if (isEdit && props.leavesPoliciesItems) {
                    for (const item of props.leavesPoliciesItems) {
                        if (item.attributes.policyId === selectedPolicy.id) {
                            let ex = nLeavePolicyItems.find((i) => i.id === item.id);
                
                            if (!ex) {
                
                                item.set("isDeleted", true);
                                item.set("deletedBy", props.userTeacher.id); 
                                item.set("deletedOn", new Date());
                                dList.push(item);
                            }
                        }
                    }
                }
                return Parse.Object.saveAll([...nLeavePolicyItems, ...dList]);
            }).then((savedResults) => {
                // console.log("LeavePolicyItems updated:", savedResults);
                // console.log("savedResults[0].get('policyId')", savedResults[0].get('policyId'));
                setEditDetailsModalOpen(false);
            }).catch((error) => {
                console.error('Error updating policy or items:', error);
                toastr.error('Error updating policy or items: ' + error.message)
            });
        }
    };

    const handleCheckboxChange = (teacherId) => {
        setAssignedTeachers((prevSelected) => {
            if (prevSelected.includes(teacherId)) {
                // console.log("TeacherId",teacherId)
                return prevSelected.filter(id => id !== teacherId);
            } else {
                return [...prevSelected, teacherId];
            }
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Row>
                    <Col className="d-flex align-items-start">
                        <div style={{ cursor: "pointer" }} onClick={() => { window.history.back() }}>
                            <ul className="list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className='m-2'>
                            <h4 className="font-size-18">Leave Policies</h4>
                        </div>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <div className="mt-4 mt-sm-0 float-sm-right form-inline">
                            <button className="btn btn-primary float-right" onClick={() => setPolicyModalOpen(true)}>New Policy</button>
                        </div>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col className="table-responsive">
                                <Table className="table table-nowrap m-0">
                                    <thead>
                                        <tr>
                                            <th>{props.t("#")}</th>
                                            <th>{props.t("Title")}</th>
                                            <th>{props.t("Total Days")}</th>
                                            <th>{props.t("Created At")}</th>
                                            <th>{props.t("View Details")}</th>
                                            <th>{props.t("Edit")}</th>
                                            <th>{props.t("Delete")}</th>
                                            <th>{props.t("Assign Policy")}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.leavesPolicies.map((leavePolicy, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{leavePolicy.attributes.policyTitle}</td>
                                                <td>{leavePolicy.attributes.totalDays}</td>
                                                <td>{Moment(leavePolicy.attributes.createdAt).format("DD-MMM-YYYY")}</td>
                                                <td><Button color='primary' size='sm' className='btn-rounded waves-effect waves-light' onClick={() => handleViewDetails(leavePolicy)}>View Details</Button></td>
                                                <td><div className="flex-fill text-primary" onClick={() => {
                                                    handleEditDetails(leavePolicy);
                                                }}><i className="bx bx-edit-alt"></i></div></td>
                                                <td><div className="flex-fill text-danger" onClick={() => {
                                                    setSelectedPolicy(leavePolicy);
                                                    setShowDeleteAlert(true);
                                                }}
                                                ><i className="bx bxs-trash-alt"></i></div></td>
                                                <td>
                                                    <div className="flex-fill text-primary" onClick={() => {
                                                        setSelectedPolicy(leavePolicy);
                                                        const listAssign = props.teachers.filter(teacher => teacher.attributes.leavePolicyId === leavePolicy.id).map(teacher => teacher.id)
                                                        setAssignedTeachers(listAssign)
                                                        setAssignTeachersModalOpen(true);
                                                    }}>
                                                        <i className='bx bx-user-check' style={{ fontSize: '20px' }}></i>
                                                    </div>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>

            <Modal isOpen={policyModalOpen} size="lg" toggle={() => setPolicyModalOpen(!policyModalOpen)}>
                <ModalHeader toggle={() => setPolicyModalOpen(!policyModalOpen)}>New Policy</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg='6'>
                            <Form>
                                <FormGroup>
                                    <Label>Policy Title</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        id="description"
                                        placeholder="Add Title"
                                        value={title}
                                        onChange={(e) => {
                                            setTitle(e.target.value);
                                            setTitleError(false)
                                        }}
                                    />
                                    {titleError && <div style={{ color: 'red' }}>Title Name is required</div>}
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Col className="table-responsive">
                        <Table className="table table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>{props.t("Slno")}</th>
                                    <th>{props.t("Leave Type")}</th>
                                    <th>{props.t("No of Days")}</th>
                                    <th>{props.t("Action")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leaves && leaves.length > 0 && leaves.map((leave, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>

                                        <td>
                                            <Input
                                                className="form-control"
                                                type="text"
                                                value={leave.leave}
                                                onChange={(e) => {
                                                    handleLeaveChange(index, e.target.value);
                                                    setAddLeaveError(false);
                                                }}
                                            />
                                            {addLeaveError && <div style={{ color: 'red' }}>Leave Type is required</div>}
                                        </td>

                                        <td>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                value={leave.days}
                                                onChange={(e) => {
                                                    handleDaysChange(index, e.target.value);
                                                    setAddLeaveDaysError(false);
                                                }}
                                            />
                                            {addLeaveDaysError && <div style={{ color: 'red' }}>Leave Days is required</div>}
                                        </td>

                                        <td>
                                            {index !== 0 && (
                                                <Label
                                                    className="text-danger"
                                                    onClick={() => handleRemoveLeave(index)}
                                                    style={{ fontSize: "15px", cursor: "pointer" }}
                                                >
                                                    <i className="bx bx-minus-circle"></i>
                                                </Label>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>


                    <Row className="mt-4">
                        <Col className="d-flex justify-content-end">
                            <Button color="primary" onClick={handleAddLeave} style={{ fontSize: "15px", marginLeft: '5px' }}>
                                <i className="bx bx-plus-circle"></i> Add Leaves
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Col className='d-flex justify-content-end'>
                        <div className="mt-0 mt-sm-0 float-sm-right form-inline">
                            <button className="btn btn-primary" onClick={() => (savePolicy())} disabled={loading}>
                                {loading ? (
                                    <>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                        {props.t('Creating...')}
                                    </>
                                ) : (
                                    props.t('Create Policy')
                                )}
                            </button>
                        </div>
                    </Col>
                </ModalFooter>
            </Modal>
            <Modal isOpen={viewDetailsModalOpen} size="md" toggle={() => setViewDetailsModalOpen(!viewDetailsModalOpen)}>
                <ModalHeader toggle={() => setViewDetailsModalOpen(!viewDetailsModalOpen)}>Policy Details</ModalHeader>
                <ModalBody>
                    {selectedPolicy && leaves && (
                        <div>
                            <Label><strong>{props.t("Title")}</strong></Label>
                            <Input
                                type="text"
                                className='form-control form-control sm'
                                value={selectedPolicy.attributes.policyTitle}
                                disabled={true}
                            />
                            <Table className="table table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>{props.t("Slno")}</th>
                                        <th>{props.t("Leave Types")}</th>
                                        <th>{props.t("Leave Days")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaves.map((leave, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{leave.leave}</td>
                                            <td>{leave.days}</td>
                                        </tr>
                                    ))}
                                </tbody>

                                <tfoot>
                                    <tr>
                                        <td colSpan={2} className='text-center'><strong>{props.t("Total")}</strong></td>
                                        <td><strong>{leaves.reduce((total, l) => total + parseInt(l.days || 0), 0)}</strong></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    )}
                </ModalBody>
            </Modal>
            <Modal isOpen={editDetailsModalOpen} size="md" toggle={() => setEditDetailsModalOpen(!editDetailsModalOpen)}>
                <ModalHeader toggle={() => setEditDetailsModalOpen(!editDetailsModalOpen)}>Edit Policy</ModalHeader>
                <ModalBody>
                    {!leaves
                        && <p>Loading</p>}
                    {selectedPolicy && leaves && (
                        <div>
                            <Label><strong>{props.t("Title")}</strong></Label>
                            <Input
                                type="text"
                                className='form-control form-control sm'
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value)
                                    setTitleError(false);
                                }}
                            />
                            {titleError && <div style={{ color: 'red' }}>Please enter the title</div>}
                            <Table className="table table-nowrap mt-2">
                                <thead>
                                    <tr>
                                        <th>{props.t("Slno")}</th>
                                        <th>{props.t("Leave Types")}</th>
                                        <th>{props.t("Leave Days")}</th>
                                        <th>{props.t("Actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaves.map((leave, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td><Input
                                                type="text"
                                                className='form-control form-control sm'
                                                value={leave.leave}
                                                onChange={(e) => {
                                                    handleLeaveChange(index, e.target.value)
                                                    setAddLeaveError(false)
                                                }}
                                            />
                                                {addLeaveError && <div style={{ color: 'red' }}>Leave Type is required</div>}
                                            </td>
                                            <td><Input
                                                type="number"
                                                className='form-control form-control sm'
                                                value={leave.days}
                                                onChange={(e) => {
                                                    handleDaysChange(index, e.target.value)
                                                    setAddLeaveDaysError(false);
                                                }}
                                            />
                                                {addLeaveDaysError && <div style={{ color: 'red' }}>Leave Days is required</div>}
                                            </td>
                                            <td>
                                                {index > 0 &&
                                                    <div className="float-right mt-0" style={{ verticalAlign: "middle" }}>
                                                        <Label className="ml-2 float-right text-danger" onClick={() => handleRemoveLeave(index)}
                                                            style={{ fontSize: "20px", cursor: "pointer" }}>
                                                            <i className="bx bx-minus-circle"></i>
                                                        </Label>
                                                    </div>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={2} className='text-center'><strong>{props.t("Total")}</strong></td>
                                        <td><strong>{leaves.reduce((total, leave) => total + (parseInt(leave.days) || 0), 0)}</strong></td>
                                        <td>
                                            <Label className="text-primary" onClick={handleAddLeave} style={{ fontSize: "15px", cursor: "pointer" }}>
                                                <i className="bx bx-plus-circle"></i> Add Leaves
                                            </Label>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Col className='d-flex justify-content-end'>
                        <div className="mt-0 mt-sm-0 float-sm-right form-inline">
                            <button className="btn btn-primary float-right" onClick={() => (savePolicy(true))}>Update Policy</button>
                        </div>
                    </Col>
                </ModalFooter>
            </Modal>
            <Modal isOpen={assignTeachersModalOpen} size="md" toggle={() => setAssignTeachersModalOpen(!assignTeachersModalOpen)}>
                <ModalHeader toggle={() => setAssignTeachersModalOpen(!assignTeachersModalOpen)}>
                    {props.t("Select teachers for policy - ") + (selectedPolicy ? selectedPolicy.attributes.policyTitle : '')}
                </ModalHeader>
                <ModalBody>
                    <p>Assign Leave Policies.</p>
                    <div className="mb-2">
                        <input
                            type="checkbox"
                            id="selectAll"
                            checked={assignedTeachers.length === props.teachers.length}
                            onChange={handleSelectAllChange}
                        />
                        <label htmlFor="selectAll" className="ms-2 m-0">
                            {props.t("Select All")}
                        </label>
                    </div>
                    <SimpleBar style={{ maxHeight: '600px' }} autoHide={false}>
                    <Table className="table-centered table-nowrap table-hover">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" style={{ width: "45px" }}>#</th>
                                <th></th>
                                <th scope="col">{props.t("Name")}</th>
                                <th scope="col">{props.t("Designation")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.teachers.map((teacher, index) => {
                                const isChecked = assignedTeachers.includes(teacher.id);
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={() => handleCheckboxChange(teacher.id)}/>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                {!teacher.attributes.Photo || !teacher.attributes.Photo._url ? (
                                                    <div className="avatar-xs me-2">
                                                        <span className="avatar-title rounded-circle">
                                                            {teacher.attributes.Name.charAt(0)}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div className="me-2">
                                                        <img
                                                            className="rounded-circle avatar-xs"
                                                            src={teacher.attributes.Photo._url}
                                                            alt={teacher.attributes.Name}
                                                        />
                                                    </div>
                                                )}
                                                {teacher.attributes.Name}
                                            </div>
                                        </td>
                                        <td>{getDesignation(teacher.attributes.Role)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    </SimpleBar>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handlePolicyAssign}>
                        {props.t("Save Changes")}
                    </Button>
                </ModalFooter>
            </Modal>
            {showDeleteAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowDeleteAlert(false);
                    }}
                    onConfirm={() => handleDeletePolicy()}
                >
                    {"Are you sure you want to delete?"}
                    <br />
                </SweetAlert>
            )}
        </React.Fragment>
    );
};

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { teachers } = state.Teacher;
    const { userTeacher } = state.Login;
    const { leavesPolicies, leavesPoliciesItems, } = state.LeaveModule;
    return { selectedSchool, userTeacher, leavesPolicies, leavesPoliciesItems, teachers };
};

export default connect(mapStatetoProps, {
    setSelecetdSchool, getLeavePolicy, updateLeavePolicy, getLeavePolicyItemsForPolicyId, getTeachersForSchool
})(withRouter(withNamespaces()(LeavesPolicies)))