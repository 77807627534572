import Parse from 'parse';
import { printLog } from './loghelper';

const parseLoginUser = (email, password) => {
    printLog("HL: login_helper : parseLoginUser")
    return new Promise((resolve, reject) => {
      
      
        Parse.User.logIn(email, password)
        .then((user) => {
            resolve({type:"Success",user:user});
        
        }, (error) => {
            resolve({ type:"Error",message:error.message });
        })
    });
};

const parseLogoutUser = () => {
    printLog("HL: login_helper : parseLogoutUser")

    return new Promise((resolve, reject) => {
        Parse.User.logOut().then(() => {
            Parse.Object.unPinAllObjects().then(() => {
                resolve();
            },(error) => {
                resolve(error.message);
            });
        }, (error) => {
            resolve(error.message);
          });
      });
}

const  getUser = () => {
    printLog("HL: login_helper : getUser")

    try {

        return new Promise((resolve, reject) => {
            if (Parse.User.current()) {
                if (Parse.User.current().attributes.teacherId) {
                    var Teacher = Parse.Object.extend("Teacher");
                    var query = new Parse.Query(Teacher);
                    query.get(Parse.User.current().attributes.teacherId).then(
                        (result) => {
                            resolve(result);
                        },
                        (error) => {
                            resolve(null);
                        }
                    );
                } else {
                    resolve(null);
                }
            } else {
                resolve(null);
            }
          });	
	} catch (error) { }
}


const forgoPassword = (email) => {
    printLog("HL: login_helper : forgoPassword")

    return new Promise((resolve, reject) => {
        try {
            Parse.User.requestPasswordReset(email)
                .then( (success) =>{
                    // console.log(success);
                    resolve({ type: "success", message: "Password reset request was sent successfully, please check your email for password reset link ",object:success });
                }).catch( (error) =>{
                    resolve({ type: "error", message: "Error: " + error.code + " " + error.message });
                });
          } catch (e) {
  
          }
      });
   
}

const  registerUserParse = (userEmail,password,appName) => {
    printLog("HL: login_helper : registerUserParse")

    try {

        return new Promise((resolve, reject) => {
            var user = new Parse.User();
            user.set("password", password);
            user.set("email", userEmail);
            user.set("username", userEmail);
            if (appName ==="Adigurukul") {
                user.set("refferedBy", "Adigurukul");
            }
            if(appName === "Sanfort"){
                user.set("refferedBy", "Sanfort");
            }
            if(appName === "JumpStart"){
                user.set("refferedBy", "JumpStart");
            }
            if(appName === "Makoons"){
                user.set("refferedBy", "Makoons");
            }
            if(appName === "CudlingBee"|| appName === "Cudling Bee"){
                user.set("refferedBy", "CudlingBee");
            }
            if(appName === "ArtKlub"){
                user.set("refferedBy", "ArtKlub");
            }
            if(appName === "TLC"||appName == "The Learning Curve"){
                user.set("refferedBy", "TLC");
            }
            try {
            user.signUp().then((user) => {
                if (user) {
                    resolve({user:user,responce:"success"})
                }
            })
            .catch((error) => { 
                if (error.code == 202) {
                    resolve( "taken");
                } else {
                    resolve( "wrong");
                }
            });
            } catch (error) {
                resolve("Error: " + error.code + " " + error.message);
            }
          });	
	} catch (error) { }
}



export {parseLoginUser,parseLogoutUser,getUser,forgoPassword,registerUserParse}

