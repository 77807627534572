import React, { useState, useEffect } from 'react';
import { Col, Row, Card, CardBody, Form, FormGroup, Label, Button, Input } from "reactstrap";
import moment from 'moment';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import classnames from "classnames";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
    setSelecetdSchool,
    getClassroomsForSchoolId,
    getAllKids,
} from "../../../store/actions";
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Bonafide from "./bonafide";
import Graduation from './graduationCertificate';
import Prize from "./prizeCertificate";
import Post from "./post";
import SaveAsPdf from '../../../components/Common/SaveAsPdf';

const newCertificate = (props) => {

    const matchCertificate = matchPath(props.history.location.pathname, {
        path: "/new-certificates/:schoolId",
        exact: true,
        strict: false,
    });


    const matchWithType = matchPath(props.history.location.pathname, {
        path: "/new-certificates/:schoolId/:type",
        exact: true,
        strict: false,
    });

    const today = new Date()

    const match = matchCertificate ?? matchWithType;
    const schoolId = match && match.params.schoolId;
    const postType = match && match.params.type;

    const [parent, setParent] = useState();

    const [styleType, setStyleType] = useState("1");

    const [bonafide, setBonafide] = useState(matchCertificate ? true : false)
    const [Passing, setPassing] = useState(false)
    const [prize, setPrize] = useState(false)
    const [post, setPost] = useState(matchWithType ? true : false)

    const [classRoom, setClassRoom] = useState();
    const [name, setName] = useState();
    const [father, setFather] = useState();
    const [mother, setMother] = useState();
    const [gender, setGender] = useState("1");
    const [event, setEvent] = useState();
    const [date, setDate] = useState();
    const [dob, setDob] = useState();
    const [wishText, setWishText] = useState();

    const [prizeType, setPrizeType] = useState("1");

    const currentYear = new Date().getFullYear();
    const currentAcademicYear = `${currentYear}-${(currentYear + 1).toString().slice(-2)}`;
    const [academicYear, setAcademicYear] = useState(currentAcademicYear);
    const [showSaveAsPdfModal, setShowSaveAsPdfModal] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [machineType, setMachineType] = useState('');

    const options = [];
    for (let i = currentYear - 10; i <= currentYear + 5; i++) {
        const academicYearOption = `${i}-${(i + 1).toString().slice(-2)}`;
        options.push(academicYearOption);
    }


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId])

    useEffect(() => {
        props.getClassroomsForSchoolId(schoolId);
    }, [schoolId]);


    const getYear = () => {
        let year = today.getFullYear();
        if (today.getMonth() <= 2) {
            year = year - 1;
        }

        return `${year}-${year + 1 - 2000}`
    }

    const printCertificate = () => {
        setTimeout(() => {
            window.print()
        }, 300);
        window.print()
    }
     
    useEffect(() => {
        const savedValue = localStorage.getItem('dontShowAgain');
        if (savedValue === 'true') {
            setDontShowAgain(true);
        }
    
        const userAgent = navigator.userAgent.toLowerCase();
        
        if (userAgent.includes('mac')) {
            setMachineType('Mac');
        } else if (userAgent.includes('windows')) {
            setMachineType('Windows');
        } else {
            setMachineType('Unknown');
        }
    }, []);
    
    const handleNextClick = () => {
        setShowSaveAsPdfModal(false);
        setTimeout(() => {
            printCertificate();
        }, 300);
    }

    useEffect(() => {
        if (props.selectedKid) {
            let parent = {}
            if (props.selectedKid.parents) {
                for (let k in props.selectedKid.parents) {
                    if (props.selectedKid.parents[k].attributes.Relation === "Mother") {
                        parent.mother = props.selectedKid.parents[k];
                    } else {
                        parent.father = props.selectedKid.parents[k];
                    }
                }
            }
            setParent(parent)
        }
    }, [props.selectedKid])
    

    return (
        <React.Fragment>
            <div className="page-content">
                <Row>
                    <Col lg={8} md={8} xs={12}>
                        <h4 className='d-print-none'>{post ? 'Teachers Day Post' : 'New Certificates'}</h4>
                    </Col>
                </Row>
                <Card className="d-print-none">
                    <CardBody>
                        <Row>
                            <div className="d-flex justify-content-start">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Button
                                        color={bonafide ? 'primary' : 'light'}
                                        className={`mr-1`}
                                        onClick={() => {
                                            setBonafide(true);
                                            setPassing(false);
                                            setPrize(false);
                                            setPost(false)
                                        }}
                                    >
                                        Bonafide Certificate
                                    </Button>
                                </FormGroup>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Button
                                        color={Passing ? 'primary' : 'light'}
                                        className={`mr-1`}
                                        onClick={() => {
                                            setPassing(true);
                                            setBonafide(false);
                                            setPrize(false);
                                            setPost(false)
                                        }}
                                    >
                                        Passing certificate
                                    </Button>
                                </FormGroup>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Button
                                        color={prize ? 'primary' : 'light'}
                                        className={`mr-1`}
                                        onClick={() => {
                                            setPrize(true);
                                            setBonafide(false);
                                            setPassing(false);
                                            setPost(false)
                                        }}
                                    >
                                        Prize Certificate
                                    </Button>
                                </FormGroup>
                                {<FormGroup className="mt-2 mb-2 p-1">
                                    <Button
                                        color={post ? 'primary' : 'light'}
                                        className={`mr-1`}
                                        onClick={() => {
                                            setPost(true)
                                            setPrize(false);
                                            setBonafide(false);
                                            setPassing(false);
                                        }}
                                    >
                                        {"Ugadi & Ramadan Post"}
                                    </Button>
                                </FormGroup>}
                            </div>
                        </Row>
                    </CardBody>
                </Card>

                <Row style={{ position: 'relative' }}>
                    <Col>
                        {bonafide && <Bonafide
                            styleType={styleType}
                            selectedSchool={props.selectedSchool}
                            classRoom={classRoom}
                            name={name}
                            father={father}
                            mother={mother}
                            academicYear={academicYear}
                            gender={gender}
                            date={date}
                            dob={dob} />}
                        {Passing && <Graduation
                            styleType={styleType}
                            selectedSchool={props.selectedSchool}
                            classRoom={classRoom}
                            name={name}
                            father={father}
                            mother={mother}
                            academicYear={academicYear}
                            gender={gender}
                            date={date}
                            dob={dob} />}
                        {prize && <Prize
                            styleType={styleType}
                            prizeType={prizeType}
                            selectedSchool={props.selectedSchool}
                            classRoom={classRoom}
                            name={name}
                            father={father}
                            mother={mother}
                            academicYear={academicYear}
                            gender={gender}
                            date={date}
                            dob={dob}
                            event={event} />}

                    </Col>
                    <Col>
                        <div className="d-print-none" style={{ position: 'absolute', bottom: 0, }}>
                            {prize && <Button
                                onClick={() => {
                                    if (['Windows', 'Mac'].includes(machineType)) {
                                        if (!dontShowAgain) {
                                            setShowSaveAsPdfModal(true); 
                                        } else {
                                            printCertificate();
                                        }
                                    }else{
                                        printCertificate();
                                    }
                                }}
                                className="waves-effect waves-light"
                                color="success"
                            >
                                Preview/Print
                            </Button>}
                            <SaveAsPdf
                                showModal={showSaveAsPdfModal}
                                toggleModal={() => setShowSaveAsPdfModal(false)}
                                dontShowAgain={dontShowAgain}
                                setDontShowAgain={setDontShowAgain}
                                onNextClick={handleNextClick}
                                machineType={machineType}
                            />

                        </div>
                        <div className="d-print-none" style={{ position: 'absolute', bottom: 0, }}>
                            {Passing && <Button
                                onClick={() => {
                                    if (['Windows', 'Mac'].includes(machineType)) {
                                        if (!dontShowAgain) {
                                            setShowSaveAsPdfModal(true); 
                                        } else {
                                            printCertificate();
                                        }
                                    }else{
                                        printCertificate();
                                    }

                                    
                                }}
                                className="waves-effect waves-light"
                                color="success"
                            >
                                Preview/Print
                            </Button>}
                            <SaveAsPdf
                                showModal={showSaveAsPdfModal}
                                toggleModal={() => setShowSaveAsPdfModal(false)}
                                dontShowAgain={dontShowAgain}
                                setDontShowAgain={setDontShowAgain}
                                onNextClick={handleNextClick}
                                machineType={machineType}
                            />
                        </div>
                        <div className="d-print-none" style={{ position: 'absolute', bottom: 0, }}>
                            {bonafide && <Button
                                onClick={() => {
                                    if (['Windows', 'Mac'].includes(machineType)) {
                                        if (!dontShowAgain) {
                                            setShowSaveAsPdfModal(true); 
                                        } else {
                                            printCertificate();
                                        }
                                    }else{
                                        printCertificate();
                                    }
                                }}
                                className="waves-effect waves-light"
                                color="success"
                            >
                                Preview/Print
                            </Button>}
                            <SaveAsPdf
                                showModal={showSaveAsPdfModal}
                                toggleModal={() => setShowSaveAsPdfModal(false)}
                                dontShowAgain={dontShowAgain}
                                setDontShowAgain={setDontShowAgain}
                                onNextClick={handleNextClick}
                                machineType={machineType}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs='12'>
                        {post && <Post
                            schoolLogo={'https://parsefiles.back4app.com/n1BIxpDhrdEUUwxqMla37DBZoMlFIWLRVLVKXK0a/d486a3d8df79a13dbcf1c4bd6e9595f9_click_school_logo.png'}
                            selectedSchool={props.selectedSchool}
                            postType={postType}
                        />}
                    </Col>



                </Row>

                {!post && (<div className='py-4'>
                    <Card className='d-print-none '>
                        <CardBody>
                            <Row >
                                <Col md="4" xs="12" xl="4" lg="4">
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Select style :</Label>
                                        <select
                                            className="form-control "
                                            value={styleType}
                                            onChange={(e) => {
                                                setStyleType(e.target.value);
                                            }}
                                        >
                                            <option value={1}>Style 1</option>
                                            <option value={2}>Style 2</option>
                                            <option value={3}>Style 3</option>
                                            <option value={4}>Style 4</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                {prize && <Col>
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Select Prize:</Label>
                                        <select
                                            defaultValue={"Select Prize"}
                                            className="form-control "
                                            value={prizeType}
                                            onChange={(e) => {
                                                setPrizeType(e.target.value);
                                            }}
                                        >
                                            <option value={1}>Participation</option>
                                            <option value={2}>First Prize</option>
                                            <option value={3}>Second Prize</option>
                                            <option value={4}>Third Prize</option>
                                        </select>
                                    </FormGroup>
                                </Col>}
                                {<Col md="4" xs="12" xl="4" lg="4">
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Name :</Label>
                                        <Input className="form-control"
                                            type="text"
                                            id="displayName"
                                            placeholder="Name"
                                            required
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>}

                                <Col md="4" xs="12" xl="4" lg="4">
                                    {!prize && !Passing && <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Class  :</Label>
                                        <Input className="form-control"
                                            type="text"
                                            id="displayName"
                                            placeholder="Class"
                                            required
                                            value={classRoom}
                                            onChange={(e) => {
                                                setClassRoom(e.target.value)
                                            }}
                                        />
                                    </FormGroup>}
                                    {prize && <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Prize event  :</Label>
                                        <Input className="form-control"
                                            type="text"
                                            id="displayName"
                                            placeholder="Event"
                                            required
                                            value={event}
                                            onChange={(e) => {
                                                setEvent(e.target.value)
                                            }}
                                        />
                                    </FormGroup>}
                                </Col>
                                {!bonafide && <Col>
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Date :</Label>
                                        <DatePicker
                                            selected={date}
                                            onChange={(dt) => {
                                                dt ? setDate(dt) : setDate(new Date());
                                            }}
                                            className="form-control"
                                            placeholderText={"Select Date"}
                                            // startDate={fromDate}
                                            // endDate={toDate}
                                            // maxDate={selectedEndDate}
                                            dateFormat="dd/MM/yyyy"
                                            showFullMonthYearPicker
                                        />
                                    </FormGroup>
                                </Col>}
                                <Col>
                                </Col>
                            </Row>
                            {<Row >
                                {<Col md="4" xs="12" xl="4" lg="4">
                                    {!prize && !Passing && <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Father :</Label>
                                        <Input className="form-control"
                                            type="text"
                                            id="displayName"
                                            placeholder="Father"
                                            required
                                            value={father}
                                            onChange={(e) => {
                                                setFather(e.target.value)
                                            }}
                                        />
                                    </FormGroup>}
                                </Col>}
                                {<Col md="4" xs="12" xl="4" lg="4">
                                    {!prize && !Passing && <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Mother :</Label>
                                        <Input className="form-control"
                                            type="text"
                                            id="displayName"
                                            placeholder="Mother"
                                            required
                                            value={mother}
                                            onChange={(e) => {
                                                setMother(e.target.value)
                                            }}
                                        />
                                    </FormGroup>}
                                </Col>}
                                {!prize && !Passing && <Col md="4" xs="12" xl="4" lg="4">
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Academic year:</Label>
                                        <Input
                                            className="form-control"
                                            type="select"
                                            id="academicYear"
                                            value={academicYear}
                                            onChange={(e) => {
                                                setAcademicYear(e.target.value);
                                            }}
                                        >
                                            {options.map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>}
                            </Row>}
                            {<Row >
                                <Col md="4" xs="12" xl="4" lg="4">
                                    {!prize && !Passing && <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Date of Birth:</Label>
                                        <DatePicker
                                            selected={dob}
                                            onChange={(dt) => {
                                                setDob(dt);
                                            }}
                                            className="form-control"
                                            placeholderText="Select Date"
                                            // startDate={fromDate}
                                            // endDate={toDate}
                                            // maxDate={selectedEndDate}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            maxDate={new Date()}
                                        />
                                    </FormGroup>
                                    }
                                </Col>
                                {!prize && !Passing && <Col md="4" xs="12" xl="4" lg="4">
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Gender :</Label>
                                        <select
                                            className="form-control "
                                            value={gender}
                                            onChange={(e) => {
                                                setGender(e.target.value);
                                            }}
                                        >
                                            <option>Select Gender</option>
                                            <option value={2}>Male</option>
                                            <option value={3}>Female</option>
                                        </select>
                                    </FormGroup>
                                </Col>}
                            </Row>}
                        </CardBody>
                    </Card>
                </div>)}
            </div>
        </React.Fragment >
    );
}

const mapStatetoProps = (state) => {

    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    const { kids } = state.Kid;

    return { selectedSchool, classrooms, kids };
};

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool,
    getClassroomsForSchoolId,
    getAllKids,
})(newCertificate)));