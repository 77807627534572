import * as actions from "./actionTypes";

const initialState = {
  academicCycles: [], curriculumAcademicCycles: [], loadState: undefined, selectedAcademicCycle: undefined,
};

const AcademicCycle = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_ACADEMIC_CYCLE:
      var academicCycles = [...state.academicCycles];
      var temp = state.academicCycles.filter(
        (academicCycle) =>
          academicCycle.id === action.payload.academicCycle.id
      );
      if (temp.length > 0) {

        if (action.payload.academicCycle.attributes.isDeleted) {

          var others = state.academicCycles.filter(
            (academicCycle) =>
              academicCycle.id !== action.payload.academicCycle.id
          );
          state = { ...state, academicCycles: others };

        } else {
          var index = academicCycles.indexOf(temp[0]);
          academicCycles[index] = action.payload.academicCycle;
          state = { ...state, academicCycles: academicCycles };
        }


      } else {
        if (!action.payload.academicCycle.attributes.isDeleted) {
          academicCycles.push(action.payload.academicCycle);
          state = { ...state, academicCycles: academicCycles };
        }

      }
      break;
    case actions.UPDATE_ACADEMIC_CYCLES:
      var academicCycles = [...state.academicCycles];

      if (action.payload.type === "replace") {
        state = { ...state, academicCycles: action.payload.academicCycles };
      } else if (academicCycles.length > 0) {
        for (const latest in action.payload.academicCycles) {
          var temp = academicCycles.filter(
            (academicCycle) =>
              academicCycle.id === action.payload.academicCycles[latest].id
          );


          if (temp.length > 0) {
            if (action.payload.academicCycles[latest].attributes.isDeleted) {

              var others = academicCycles.filter(
                (academicCycle) =>
                  academicCycle.id !== action.payload.academicCycles[latest].id
              );

              academicCycles = others;
            } else {
              var index = academicCycles.lastIndexOf(temp[0]);
              academicCycles[index] = action.payload.academicCycles[latest];
            }

          } else if (!action.payload.academicCycles[latest].attributes.isDeleted) {
            academicCycles.push(action.payload.academicCycles[latest]);
          }
        }
        state = { ...state, academicCycles: academicCycles };
      } else {
        var others = action.payload.academicCycles.filter(
          (academicCycle) => !academicCycle.attributes.isDeleted
        );
        state = { ...state, academicCycles: others };
      }
      break;
    case actions.UPDATE_CURRICULUM_ACADEMIC_CYCLES:
      var curriculumAcademicCycles = [...state.curriculumAcademicCycles];
      if (action.payload.type === "replace") {
        state = { ...state, curriculumAcademicCycles: action.payload.curriculumAcademicCycles };
      } else if (curriculumAcademicCycles.length > 0) {
        for (const latest in action.payload.curriculumAcademicCycles) {
          var temp = curriculumAcademicCycles.filter(
            (academicCycle) =>
              academicCycle.id === action.payload.curriculumAcademicCycles[latest].id
          );


          if (temp.length > 0) {
            if (action.payload.curriculumAcademicCycles[latest].attributes.isDeleted) {

              var others = curriculumAcademicCycles.filter(
                (academicCycle) =>
                  academicCycle.id !== action.payload.curriculumAcademicCycles[latest].id
              );

              curriculumAcademicCycles = others;
            } else {
              var index = curriculumAcademicCycles.lastIndexOf(temp[0]);
              curriculumAcademicCycles[index] = action.payload.curriculumAcademicCycles[latest];
            }

          } else {
            curriculumAcademicCycles.push(action.payload.curriculumAcademicCycles[latest]);
          }
        }
        state = { ...state, curriculumAcademicCycles: curriculumAcademicCycles };
      } else {
        var others = action.payload.curriculumAcademicCycles.filter(
          (academicCycle) => academicCycle.attributes.isDeleted !== true
        );
        state = { ...state, curriculumAcademicCycles: others };
      }

      break;


    case actions.DELETE_ACADEMIC_CYCLE:
      var academicCycles = state.academicCycles.filter(
        (academiccycle) => academiccycle.id !== action.payload.academicCycle.id
      );
      var curriculumAcademicCycles = state.curriculumAcademicCycles.filter(
        (academiccycle) => academiccycle.id !== action.payload.academicCycle.id
      );

      state = { ...state, academicCycles: academicCycles, curriculumAcademicCycles: curriculumAcademicCycles };


      break;

    case actions.SET_CYCLE_LOADING_STATE:
      if (action.payload.state === "request") {
        state = { ...state, academicCycles: [], loadState: action.payload.state };
      } else {
        state = { ...state, loadState: action.payload.state };
      }
      break;

    case actions.CLEAR_ACADEMIC_CYCLES:
      state = { ...state, academicCycles: [], curriculumAcademicCycles: [] };
      break;
    case actions.UPDATE_SELECTED_ACADEMIC_CYCLE:
      state = { ...state, selectedAcademicCycle: action.payload.academicCycle };
      break;
    case actions.GET_CURRICULUM_CYCLES_CYCLE_ID:
      state = { ...state, curriculumAcademicCycles: [] };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AcademicCycle;
