import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Input,
    Label,
    Table,
    Container,
    UncontrolledTooltip
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Parse from "parse";
import SweetAlert from "react-bootstrap-sweetalert";
import { updateMasterPayslipForTeacherId } from '../../store/actions';

const PayslipWithAttendanceModal = (props) => {

    const MONTHS = ["jan", "feb", "mar", "apr"]

    const [isLoading, setIsLoading] = useState(false);
    const [weekOffDays, setWeekOffDays] = useState([]);
    const [weekOff, setWeekOff] = useState([]);
    const [leaveInterval, setLeaveInterval] = useState([])
    const [selectedMonthMap, setSelectedMonthMap] = useState([]);
    const [attendanceObject, setAttendanceObject] = useState([]);
    const [compensation, setCompensation] = useState()
    const [showSaveAlert, setShowSaveAlert] = useState(false)


    useEffect(() => {
        if (props.masterPayslip && props.masterPayslip) {
            if (props.masterPayslip.attributes.perDayCompensation) {
                setCompensation(props.masterPayslip.attributes.perDayCompensation)
            } else {
                setCompensation((props.masterPayslip.attributes.salaryPerMonth / attendanceObject.totalDays).toFixed(2))
            }

        }
    }, [props.masterPayslip])

    useEffect(() => {
        let nonWorkingDays = []
        if (props.selectedTeacher && props.selectedTeacher &&
            props.selectedTeacher.attributes.workingDaysMap &&
            props.selectedTeacher.attributes.workingDaysMap.length > 0) {

            let workingDaysMap = props.selectedTeacher.attributes.workingDaysMap;
            nonWorkingDays = [0, 1, 2, 3, 4, 5, 6]
            for (const day of workingDaysMap) {
                if (day.working) {
                    nonWorkingDays = nonWorkingDays.filter(item => item !== day.id);
                }
            }

        } else if (props.selectedSchool && props.selectedSchool.attributes.workingDays) {
            let workingDays = props.selectedSchool.attributes.workingDays
            let allDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            for (const day in allDays) {
                if (!workingDays.includes(allDays[day])) {
                    nonWorkingDays.push(parseInt(day))
                }
            }
        } else {
            nonWorkingDays.push(0)
        }

        let t = props.selectedMonth.split("-")
        let month = MONTHS.indexOf(t[0]);

        let monthMap = {
            month: month,
            year: t[1]
        }

        setSelectedMonthMap(monthMap)

        const startDate = new Date(t[1], month, 1);
        const endDate = new Date(t[1], month + 1, 0, 23, 59, 59, 999);

        setLeaveInterval({
            start: startDate,
            end: endDate
        })

        let nonWorkingDates = []

        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
            if (nonWorkingDays.includes(d.getDay())) {
                let nd = new Date(d)
                nonWorkingDates.push(nd.getDay())
            }
        }
        setWeekOffDays(nonWorkingDays)
        setWeekOff(nonWorkingDates);

        getAttendanceForSelectedTeacherAndMonth(startDate, endDate, monthMap)

    }, [props.selectedSchool, props.selectedTeacher]);


    const getAttendanceForSelectedTeacherAndMonth = (startDate, endDate, monthMap) => {
        setIsLoading(true)
        var TeacherAttendance = Parse.Object.extend("TeacherAttendance");
        var queryA = new Parse.Query(TeacherAttendance);

        queryA.equalTo("schoolId", props.selectedSchool.id);
        queryA.equalTo("teacherId", props.selectedTeacher.id);
        queryA.greaterThanOrEqualTo("createdAt", startDate);
        queryA.lessThanOrEqualTo("createdAt", endDate);
        queryA.exists("ReachedSchoolAt");
        queryA.ascending("createdAt");
        queryA.limit(1000);


        queryA.find().then(
            (attendance) => {
                // console.log("attendance", attendance)
                var HolidayList = Parse.Object.extend("HolidayList");
                var queryH = new Parse.Query(HolidayList);

                queryH.equalTo("ownerId", props.selectedSchool.id);
                queryH.greaterThanOrEqualTo("holidayDate", startDate);
                queryH.lessThanOrEqualTo("holidayDate", endDate);
                queryH.ascending("holidayDate");
                queryH.notEqualTo("isDeleted", true);
                queryH.limit(100);


                queryH.find().then(
                    (holidays) => {
                        // console.log("holidays", holidays)
                        var Leaves = Parse.Object.extend("Leaves");
                        var queryL = new Parse.Query(Leaves);
                        var queryL1 = new Parse.Query(Leaves);
                        var queryL2 = new Parse.Query(Leaves);


                        queryL1.greaterThanOrEqualTo("startDate", startDate);
                        queryL1.lessThanOrEqualTo("startDate", endDate);

                        queryL2.greaterThanOrEqualTo("endDate", startDate);
                        queryL2.lessThanOrEqualTo("endDate", endDate);

                        queryL = Parse.Query.or(queryL1, queryL2);
                        queryL.equalTo("ownerId", props.selectedSchool.id);
                        queryL.equalTo("userId", props.selectedTeacher.id);
                        queryL.equalTo("status", 1);
                        queryL.notEqualTo("isDeleted", true);
                        queryL.limit(100);


                        queryL.find().then(
                            (leaves) => {
                                // console.log("leaves", leaves)
                                getDatesInMonth(holidays, attendance, leaves, monthMap)
                            },
                            (error) => {

                            }
                        );
                    },
                );
            },
        );
    }



    function getDatesInMonth(holidays, attendanceList, leaveList, mountMap) {


        let year = mountMap.year;
        let month = mountMap.month

        let dates = [];
        let date = new Date(year, month, 1); // Month is 0-based in JS

        let totalDays = 0;
        let totalLOP = 0;
        let totalWorkingDays = 0;
        let totalWeekOffs = 0;
        let totalHolidays = 0;
        let totalPrasent = 0;
        let totalExtraDays = 0;

        let holidayList = [];
        for (let po of holidays) {
            holidayList.push(po.attributes.holidayDate.getDate())
        }


        while (date.getMonth() === month) {

            let isWeekoff = weekOff.includes(date.getDay())
            let isHoliDay = holidayList.includes(date.getDate())
            let leaveObject = getLeaveObject(leaveList, date)
            let attendanceObject = getAttendanceObject(attendanceList, date)
            let attendanceAnalytics = getAttendanceAnalytics(attendanceObject)

            let dayLop = 0;

            if (isWeekoff) {
                totalWeekOffs++
            } else if (isHoliDay) {
                totalHolidays++
            }

            if (!isWeekoff && !isHoliDay) {

                totalWorkingDays++

                if (leaveObject && leaveObject.lop) {
                    let leaveMode = leaveObject.attributes.leaveMode
                    if (leaveMode == 1 || leaveMode == 2) {
                        //half day
                        dayLop = 0.5
                    } else if (leaveMode == 3 || leaveMode == 5) {
                        //full day
                        dayLop = 1
                    } else if (leaveMode >= 4) {
                        //multiple days
                        let leSD = lv.attributes.startDate;
                        let leED = lv.attributes.endDate;

                        let startDate = new Date(leSD.getFullYear(), leSD.getMonth(), leSD.getDate(), 0, 0, 0, 0);
                        let endDate = new Date(leED.getFullYear(), leED.getMonth(), leED.getDate(), 23, 59, 59, 999);

                        if (leaveMode == 6) {
                            //both half Days
                            if (startDate.getDay() == date.getDay() || endDate.getDay() == date.getDay()) {
                                dayLop = 0.5;
                            } else {
                                dayLop = 1;
                            }
                        } else if (leaveMode == 4) {
                            //1st half 
                            if (startDate.getDay() == date.getDay()) {
                                dayLop = 0.5;
                            } else {
                                dayLop = 1;
                            }

                        } else if (leaveMode == 7) {
                            //2nd half 
                            if (endDate.getDay() == date.getDay()) {
                                dayLop = 0.5;
                            } else {
                                dayLop = 1;
                            }
                        }

                    }
                }

                if (attendanceAnalytics && attendanceAnalytics.status == "prasent") {
                    if (dayLop == 0.5) {
                        totalPrasent = totalPrasent + dayLop
                    }
                    if (dayLop == 0) {
                        totalPrasent = totalPrasent + 1
                    }
                    if (dayLop == 1) {
                        totalPrasent = totalPrasent + 1
                        dayLop = 0
                    }
                } else {
                    if (dayLop == 0) {
                        dayLop = 1
                    }
                }




            } else if (attendanceAnalytics && attendanceAnalytics.status == "prasent") {
                //get other conditions
                totalPrasent++
                totalExtraDays = totalExtraDays + 1;
            }
            totalLOP = totalLOP + dayLop;
            dates.push({
                date: new Date(date),
                day: date.getDay(),

                isWeekoff: isWeekoff,
                isHoliDay: isHoliDay,
                attendanceObject: attendanceObject,
                attendanceAnalytics: attendanceAnalytics,

                dayLop: dayLop,
                extraDay: totalExtraDays,


            }); // Format as YYYY-MM-DD
            date.setDate(date.getDate() + 1); // Move to the next day
        }

        totalDays = dates.length

        console.log("dates", dates)
        console.log("totalWorkingDays", totalWorkingDays)
        console.log("totalWeekOffs", totalWeekOffs)
        console.log("totalHolidays", totalHolidays)
        console.log("totalPrasent", totalPrasent)
        console.log("totalExtraDays", totalExtraDays)
        console.log("totalLOP", totalLOP)

        if (!props.masterPayslip.attributes.perDayCompensation) {
            setCompensation((props.masterPayslip.attributes.salaryPerMonth / totalDays).toFixed(2))
        }

        let summaryObject = { dates, totalDays, totalWorkingDays, totalWeekOffs, totalHolidays, totalPrasent, totalExtraDays, totalLOP }
        setAttendanceObject(summaryObject)
        setIsLoading(false)
        return summaryObject;
    }


    const getAttendanceAnalytics = (attendance) => {

        if (attendance) {
            let atObject = {}
            let inTime = attendance.attributes.ReachedSchoolAt;
            let outTime = attendance.attributes.LeftSchoolAt;
            let workingDaysMap = props.selectedTeacher.attributes.workingDaysMap;
            let inTimeDiff = 0
            let outTimeDiff = 0
            if (inTime) {
                atObject.status = "prasent"

                if (outTime) {
                    atObject.totalTime = Math.floor(Math.abs(outTime - inTime) / (1000 * 60));
                }

                let staffInTime;
                let staffOutTime;
                if (workingDaysMap && workingDaysMap.length > 0) {


                    for (const wd of workingDaysMap) {
                        if (wd.id == inTime.getDay()) {
                            staffInTime = wd.inTime;
                            staffOutTime = wd.outTime
                            break;
                        }
                    }


                }
                if (!staffInTime) {
                    staffInTime = props.selectedSchool.attributes.startTime
                    staffOutTime = props.selectedSchool.attributes.EndTime
                }

                if (staffInTime) {
                    let ait = staffInTime.getHours() * 60 + staffInTime.getMinutes();
                    let rit = inTime.getHours() * 60 + inTime.getMinutes();
                    inTimeDiff = rit - ait
                }
                if (staffOutTime && outTime) {
                    let aot = staffOutTime.getHours() * 60 + staffOutTime.getMinutes();
                    let rot = outTime.getHours() * 60 + outTime.getMinutes();
                    outTimeDiff = rot - aot
                }

                atObject.inTime = inTime;
                atObject.outTime = outTime;
                atObject.inTimeDiff = inTimeDiff;
                atObject.outTimeDiff = outTimeDiff;



                return atObject;
            }

        }
        return { status: "absent" };
    }
    const getAttendanceObject = (attendanceList, date) => {

        let attendance
        for (const at of attendanceList) {
            if (at.attributes.ReachedSchoolAt.getDate() == date.getDate()) {
                attendance = at;
                break
            }
        }
        return attendance;
    }
    const getLeaveObject = (leaveList, date) => {

        let leave
        for (const lv of leaveList) {
            if (lv.attributes.status == 1) {
                if (lv.attributes.leaveMode > 3) {
                    let leSD = lv.attributes.startDate;
                    let leED = lv.attributes.endDate;

                    let startDate = new Date(leSD.getFullYear(), leSD.getMonth(), leSD.getDate(), 0, 0, 0, 0);
                    let endDate = new Date(leED.getFullYear(), leED.getMonth(), leED.getDate(), 23, 59, 59, 999);

                    if (date >= startDate && date <= endDate) {
                        return leave;
                    }

                } else {
                    if (lv.attributes.startDate.getDate() == date.getDate()) {
                        return leave;
                    }
                }
            }


        }
        return undefined;
    }

    const saveCompensationInMasterPayslip = () => {
        console.log("props.masterPayslip", props.masterPayslip)
        let temp = props.masterPayslip;
        temp.set("perDayCompensation", parseFloat(compensation));
        temp.save().then(
            (result) => {
                // console.log("result", result)
            },
            (error) => {

            }
        );
    };

    return (
        <React.Fragment>
            {showSaveAlert && (<SweetAlert
                title={props.t('Are you sure?')}
                warning
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                    saveCompensationInMasterPayslip()
                    props.onDoneButtonClick(attendanceObject, compensation)
                    setShowSaveAlert(false)
                }}
                onCancel={() => {
                    setShowSaveAlert(false)
                }}>
                {props.t("Do you want to add LOP and Over Time into the payslip.")}

            </SweetAlert>)}
            <Container fluid={true}>

                {(isLoading) ? (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Label className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading...{" "}
                                </Label>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col lg={12} className="mx-auto">
                            <Row>
                                <Col lg={2}></Col>
                                <Col lg={8}>
                                    <Table className="table mb-0 table-bordered">
                                        <tbody>
                                            <tr>
                                                <th scope="row" style={{ width: "200px", whiteSpace: "nowrap" }}>Total Days :</th>
                                                <td>{attendanceObject.totalDays}<br /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row" style={{ width: "200px", whiteSpace: "nowrap" }}> Working Days :</th>
                                                <td>{attendanceObject.totalWorkingDays}<br /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row" style={{ width: "200px", whiteSpace: "nowrap" }}> Week Offs:</th>
                                                <td>{attendanceObject.totalWeekOffs}<br /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row" style={{ width: "200px", whiteSpace: "nowrap" }}> Holidays:</th>
                                                <td>{attendanceObject.totalHolidays}<br /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row" style={{ width: "200px", whiteSpace: "nowrap" }}> Prasent:</th>
                                                <td>{attendanceObject.totalPrasent}<br /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row" style={{ width: "200px", whiteSpace: "nowrap" }}>Over Time (Days) :</th>
                                                <td>{attendanceObject.totalExtraDays}<br /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row" style={{ width: "200px", whiteSpace: "nowrap" }}> LOP (Days) :</th>
                                                <td>{attendanceObject.totalLOP}<br /></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table className="table table-bordered" style={{ marginTop: '10px' }}>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Compensation (Per Day):</th>
                                                <td>
                                                    <Input type="number"
                                                        id="exampleInput"
                                                        placeholder="Enter Compensation here"
                                                        value={compensation}
                                                        onChange={(e) => {
                                                            setCompensation(e.target.value)
                                                        }} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <div>
                                                <Label id='LOP'>
                                                    LOP <b>(LOP (Days) x Compensation (Per Day))</b>:
                                                </Label>
                                                <Input
                                                    type="number"
                                                    id="lopInput" 
                                                    placeholder="Enter Compensation here"
                                                    value={compensation * attendanceObject.totalLOP}
                                                    onChange={(e) => {
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div>
                                                <Label>
                                                Over Time <b>(O.T (Days) x Compensation (Per Day))</b>:
                                                </Label>
                                                <Input
                                                    type="number"
                                                    id="overtimeInput" // Changed to a unique ID
                                                    placeholder="Enter Compensation here"
                                                    value={compensation * attendanceObject.totalExtraDays}
                                                    onChange={(e) => {
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary" onClick={() => {
                                    setShowSaveAlert(true)
                                }}>
                                    Done
                                </button>
                            </div>
                        </Col>
                    </Row>
                )}

            </Container>
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {


};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, { updateMasterPayslipForTeacherId })(PayslipWithAttendanceModal)
    )
);
