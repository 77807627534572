import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool, setSelectedMonth } from '../../store/actions';
import { matchPath } from "react-router";

const Index = props => {
    const match = matchPath(props.history.location.pathname, {
        path: "/payroll-leave-dashboard/:id",
        exact: true,
        strict: false,
    });

    const match1 = matchPath(props.history.location.pathname, {
        path: "/payroll-leave-dashboard/:franchiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.id;
    const franchiseId = match1.params.franchiseId


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Row className='d-flex align-items-start'>
                    <Col className="d-flex align-items-start">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { window.history.back() }}>
                            <ul className=" list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className='m-2'>
                            <h4 className="font-size-18">Payroll Management</h4>
                        </div>
                    </Col>
                </Row>
                <section>
                    <Container fluid="sm">
                        <Card>
                            <h4 className="text-start m-2 ms-3">Leave Module</h4>
                            <Row className="checkout-tabs">
                                <Col>
                                    <Nav className="d-flex flex-row" pills>
                                        <Col lg={3}>
                                            <Card>
                                                <CardBody>
                                                    <NavItem>
                                                        <NavLink
                                                            onClick={() => {
                                                                props.history.push(schoolId ? `/leaves/${schoolId}` : `/franchise-leaves/${props.franchiseId}`);
                                                            }}
                                                        >
                                                            <i className="bx bxs-folder-plus d-block check-nav-icon mt-4 mb-2"></i>
                                                            <p className="font-weight-bold mb-4">
                                                                Applied Leaves
                                                            </p>
                                                        </NavLink>
                                                    </NavItem>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={3}>
                                            <Card>
                                                <CardBody>
                                                    <NavItem>
                                                        <NavLink
                                                            onClick={() => {
                                                                props.history.push(schoolId ? `/leave-policies/${schoolId}` : `/leave-templates/${props.franchiseId}`);
                                                            }}
                                                        >
                                                            <i className="bx bxs-book d-block check-nav-icon mt-4 mb-2 "></i>
                                                            <p className="font-weight-bold mb-4">
                                                                Leave Policies
                                                            </p>
                                                        </NavLink>
                                                    </NavItem>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={3}>
                                            <Card>
                                                <CardBody>
                                                    <NavItem>
                                                        <NavLink
                                                            onClick={() => {
                                                                props.history.push(schoolId ? `/policies-assigning/${schoolId}` : `/franchise-leaves/${props.franchiseId}`);
                                                            }}
                                                        >
                                                            <i className="bx bxs-copy d-block check-nav-icon mt-4 mb-2"></i>
                                                            <p className="font-weight-bold mb-4">
                                                                Policy Assigning
                                                            </p>
                                                        </NavLink>
                                                    </NavItem>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={3}>
                                            <Card>
                                                <CardBody>
                                                    <NavItem>
                                                        <NavLink
                                                            onClick={() => {
                                                                props.history.push(schoolId ? `/holiday-list/${schoolId}` : `/franchise-leaves/${props.franchiseId}`);
                                                            }}
                                                        >
                                                            <i className="bx bxs-folder d-block check-nav-icon mt-4 mb-2"></i>
                                                            <p className="font-weight-bold mb-4">
                                                                Holiday List
                                                            </p>
                                                        </NavLink>
                                                    </NavItem>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Nav>
                                </Col>
                            </Row>
                            <h4 className="text-start m-2 ms-3">Payroll Module</h4>
                            <Row className="checkout-tabs">
                                <Col>
                                    <Nav className="d-flex flex-row" pills>
                                        <Col lg={3}>
                                            <Card>
                                                <CardBody>
                                                    <NavItem>
                                                        <NavLink
                                                            onClick={() => {
                                                                props.history.push(schoolId ? `/master-payroll/${schoolId}` : `/franchise-leaves/${props.franchiseId}`);
                                                            }}
                                                        >
                                                            <i className="bx bx-stats d-block check-nav-icon mt-4 mb-2"></i>
                                                            <p className="font-weight-bold mb-4">
                                                                Payroll Setup
                                                            </p>
                                                        </NavLink>
                                                    </NavItem>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={3}>
                                            <Card>
                                                <CardBody>
                                                    <NavItem>
                                                        <NavLink
                                                            onClick={() => {
                                                                props.setSelectedMonth(undefined)
                                                                props.history.push(schoolId ? `/payroll/${schoolId}` : `/franchise-leaves/${props.franchiseId}`);
                                                            }}
                                                        >
                                                            <i className="bx bx-money d-block check-nav-icon mt-4 mb-2"></i>
                                                            <p className="font-weight-bold mb-4">
                                                                Salary Day
                                                            </p>
                                                        </NavLink>
                                                    </NavItem>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={3}>
                                            <Card>
                                                <CardBody>
                                                    <NavItem>
                                                        <NavLink
                                                            onClick={() => {
                                                                props.history.push(schoolId ? `/payroll-categories/${schoolId}` : `/franchise-leaves/${props.franchiseId}`);
                                                            }}
                                                        >
                                                            <i className="bx bx-food-menu d-block check-nav-icon mt-4 mb-2 "></i>
                                                            <p className="font-weight-bold mb-4">
                                                                Payroll Categories
                                                            </p>
                                                        </NavLink>
                                                    </NavItem>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Nav>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </section>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = state => {
    return {};
};

export default connect(mapStatetoProps, {
    setSelecetdSchool, setSelectedMonth
})(withRouter(withNamespaces()(Index)));