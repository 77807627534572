import * as actions from "./actionTypes";

export const updateInventoryItem = (inventoryItem) => {
  return {
    type: actions.UPDATE_INVENTORY_ITEM,
    payload: { inventoryItem },
  };
};

export const deleteInventoryItem = (inventoryItem) => {
  return {
    type: actions.DELETE_INVENTORY_ITEM,
    payload: { inventoryItem },
  };
};

export const updateDeleteInventoryItem = (inventoryItem) =>{
  return{
      type:actions.UPDATE_DELETE_INVENTORY_ITEM,
      payload: {inventoryItem},
  }
}

export const updateInventoryItems = (inventoryItems,replace) => {
  return {
    type: actions.UPDATE_INVENTORY_ITEMS,
    payload: { inventoryItems,replace },
  };
};

// export const getInventoryItemsForownerId = (ownerId) => {
//   return {
//     type: actions.GET_INVENTORY_ITEM_FOR_OWNER_ID,
//     payload: { ownerId },
//   };
// };

export const getInventoryItemForOwnerId = (ownerId, ownerType, showInStore, categorySetting, schoolId) => {
  return {
    type: actions.GET_INVENTORY_ITEMS,
    payload: { ownerId, ownerType, showInStore, categorySetting, schoolId },
  };
};

export const setInventoryItemInitialLoading = (state) => {
  return {
    type: actions.SET_INVENTORY_ITEM_INITIAL_LOADING,
    payload: { state },
  };
};

// export const clearCuriculams = () => {
//   return {
//     type: actions.CLEAR_INVENTORY_ITEM,
//     payload: {  },
//   };
// };

export const updateInventoryItemInitialLoading = (state) => {
  return {
    type: actions.UPDATE_INVENTORY_ITEM_INITIAL_LOADING,
    payload: { state },
  };
};

export const addNewInventoryItem = (inventoryItem,cycles,schoolId,history) => {
  return {
    type: actions.ADD_NEW_INVENTORY_ITEM,
    payload: { inventoryItem,cycles,schoolId,history },
  };
};

export const setSelectedInventoryItem = (inventoryItemId) => {
  return {
    type: actions.SET_SELECTED_INVENTORY_ITEM,
    payload: { inventoryItemId },
  };
};


export const updateSelectedInventoryItem = (inventoryItem) => {
  return {
    type: actions.UPDATE_SELECTED_INVENTORY_ITEM,
    payload: { inventoryItem },
  };
};

export const getInventoryItemForItemIds = (itemIds ) => {
  return {
    type: actions.GET_INVENTORY_ITEMS_FOR_ITEM_IDS,
    payload: { itemIds },
  };
};

export const updateInventoryItemForItemIds = (inventoryItems) => {
  return {
    type: actions.UPDATE_INVENTORY_ITEMS_FOR_ITEM_IDS,
    payload: { inventoryItems },
  };
};

export const editInventoryItem = (inventoryItem) =>{
  return{
      type: actions.EDIT_INVENTORY_ITEM,
      payload: {inventoryItem}
  }
}

export const getInventoryItemMatrixForItemId = (itemId) => {
  return {
    type: actions.GET_INVENTORY_ITEM_MATRIX_FOR_ITEM_ID,
    payload: { itemId },
  };
};

export const updateInventoryItemMatrixForItemId = (inventoryItemMatrixs) => {
  return {
    type: actions.UPDATE_INVENTORY_ITEM_MATRIX_FOR_ITEM_ID,
    payload: { inventoryItemMatrixs },
  };
};

export const getInventoryItemMatrixForItemIds = (itemIds) => {
  return {
    type: actions.GET_INVENTORY_ITEM_MATRIX_FOR_ITEM_IDS,
    payload: { itemIds },
  };
};

export const updateInventoryItemMatrixForItemIds = (inventoryMatrixs) => {
  return {
    type: actions.UPDATE_INVENTORY_ITEM_MATRIX_FOR_ITEM_IDS,
    payload: { inventoryMatrixs },
  };
};

export const getInventorySubItemForItemIds = (itemIds) => {
  return {
    type: actions.GET_INVENTORY_SUB_ITEM_FOR_ITEM_IDS,
    payload: { itemIds },
  };
};

export const updateInventorySubItemForItemIds = (inventorySubItems) => {
  return {
    type: actions.UPDATE_INVENTORY_SUB_ITEM_FOR_ITEM_IDS,
    payload: { inventorySubItems },
  };
};

export const getInventoryItemCategoryPriceForItemIds = (itemIds, categoryId) => {
  return {
    type: actions.GET_INVENTORY_ITEM_CATEGORY_PRICE_FOR_ITEM_IDS,
    payload: { itemIds },
  };
};

export const updateInventoryItemCategoryPriceForItemIds = (inventoryItemCategoryPrices) => {
  return {
    type: actions.UPDATE_INVENTORY_ITEM_CATEGORY_PRICE_FOR_ITEM_IDS,
    payload: { inventoryItemCategoryPrices },
  };
};