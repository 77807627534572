import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table, Input, Button,
    Label, FormGroup, CardHeader
} from "reactstrap";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { matchPath } from "react-router";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Topbar from "../Topbar";
import Moment from 'moment';
import Parse from "parse";
import { setSelecetdSchool, getInventoryItemForOwnerId, setSelectedSaleOrder, setSelectedSaleOrderItems, setSelecetdTeacher, getAKid, getFranchise } from '../../../store/actions';
import Switch from "react-switch";
import { Offsymbol, OnSymbol } from '../../../components/Common/CommonFunctions';


const PrintSaleOrder = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/print-sale-order/:schoolId/:saleOrderId",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-print-sale-order/:franchiseId/:saleOrderId",
        exact: true,
        strict: false,
    });

    const tempMatch = match ?? franchiseMatch;
    const saleOrderId = tempMatch && tempMatch.params.saleOrderId;

    const schoolId = match && match.params.schoolId;
    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

    const [tableData, setTableData] = useState([{

        itemId: uuidv4(),
        item: undefined,
        units: undefined,
        tax: undefined,
        quantity: undefined,
        unitCost: undefined,
        totalCost: undefined,
    }]);
    const [isLoading, setIsLoading] = useState(false);
    const [freightCharge, setFreightCharge] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0);
    const [showSaveAsPdfModal, setShowSaveAsPdfModal] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [machineType, setMachineType] = useState('');
    const [showPrintOrder, setShowPrintOrder] = useState(1)
    const [dueItems, setDueItems] = useState([]);
    const [isSummaryOrderedChecked, setIsSummaryOrderedChecked] = useState(true);
    const [isSummaryDeliveredChecked, setIsSummaryDeliveredChecked] = useState(true);
    const [isDueOrderedChecked, setIsDueOrderedChecked] = useState(true);
    const [isDueDeliveredChecked, setIsDueDeliveredChecked] = useState(true);
    const [saleOrderLog, setSaleOrderLog] = useState([])
    const [showAllItemsWithGst, setShowAllItemsWithGst] = useState(false);


    const STUDENT = "Student"
    const STAFF = "Staff"
    const SCHOOL = "School"

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        const orderValue = props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.orderValue ? parseFloat(props.selectedInventorySaleOrder.attributes.orderValue) : 0;
        let total
        if (freightCharge) {
            total = parseFloat(orderValue) + parseFloat(freightCharge)
        } else {
            total = orderValue
        }

        setGrandTotal(total);
    }, [props.selectedInventorySaleOrder, freightCharge]);

    useEffect(() => {
        if (franchiseId) {
            props.getFranchise(franchiseId);
            props.getInventoryItemForOwnerId(franchiseId, "franchise")
        }
    }, [franchiseId]);

    useEffect(() => {
        props.setSelectedSaleOrder(saleOrderId);
        props.setSelectedSaleOrderItems(saleOrderId);
    }, [saleOrderId]);


    useEffect(() => {
        if (props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.id == saleOrderId) {
            let buyerType = props.selectedInventorySaleOrder.attributes.buyerType
            let buyerId = props.selectedInventorySaleOrder.attributes.buyerId;
            if (buyerType == STAFF) {
                props.setSelecetdTeacher(buyerId);
            } else if (buyerType == STUDENT) {
                props.getAKid(buyerId);
            } else {
                props.setSelecetdSchool(buyerId);
            }
            setFreightCharge(props.selectedInventorySaleOrder.attributes.freightCharges)
        }
    }, [props.selectedInventorySaleOrder]);

    useEffect(() => {
        if (saleOrderId && props.selectedInventorySaleOrderItems && props.selectedInventorySaleOrderItems.length > 0) {
            let items = []
            for (const si of props.selectedInventorySaleOrderItems) {
                let newItem = {
                    itemId: uuidv4(),
                    itemName: si.attributes.itemName,
                    item: si,
                    units: si.attributes.unit,
                    tax: si.attributes.tax,
                    quantity: si.attributes.quantity,
                    unitCost: (si.attributes.unitCost).toFixed(2),
                    totalCost: (si.attributes.totalCost).toFixed(2),
                }
                items.push(newItem)
            }
            setTableData(items);
        }
    }, [props.selectedInventorySaleOrderItems])



    useEffect(() => {
        if (props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.id == saleOrderId) {
            if (props.selectedInventorySaleOrderItems) {
                if (props.selectedKid) {
                    if (props.selectedTeacher) {
                        if (props.selectedSchool) {
                            if (props.selectedSchool || props.franchise) {
                                setIsLoading(false);
                            }
                        }
                    }
                }
            }
        }
    }, [props.selectedInventorySaleOrder, props.selectedInventorySaleOrderItems, props.selectedKid, props.selectedTeacher, props.selectedSchool])

    const getSaleOrderDueItems = () => {
        let InventorySaleOrderDueItems = Parse.Object.extend("InventorySaleOrderDueItems");
        let query = new Parse.Query(InventorySaleOrderDueItems);

        query.equalTo("saleOrderId", props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.id);

        query.find().then(
            (dueItems) => {
                setDueItems(dueItems)
            },
            (error) => {
                console.log("Error dueItems:", error);
            }
        );
    }

    useEffect(() => {
        var InventorySaleOrderLog = Parse.Object.extend("InventorySaleOrderLog");
        var query = new Parse.Query(InventorySaleOrderLog);
        query.equalTo("saleOrderId", saleOrderId)
        query.equalTo('toStatus', 2);
        query.notEqualTo('isDeleted', true);
        query.ascending('createdAt');
        query.first().then(
            (results) => {
                if (results) {
                    console.log("sllog", results)
                    setSaleOrderLog(results)
                }
            },
            (error) => {

            }
        );

    }, [saleOrderId])

    const printOrder = () => {
        // var css = "@page { size: A3;margin:10mm 0 10mm 0; }",
        //     head = document.head || document.getElementsByTagName("head")[0],
        //     style = document.createElement("style");

        // style.type = "text/css";
        // style.media = "print";

        // if (style.styleSheet) {
        //     style.styleSheet.cssText = css;
        // } else {
        //     style.appendChild(document.createTextNode(css));
        // }

        // head.appendChild(style);
        setTimeout(() => {
            window.print();
        }, 300);
    }

    useEffect(() => {
        const savedValue = localStorage.getItem('dontShowAgain');
        if (savedValue === 'true') {
            setDontShowAgain(true);
        }
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes('mac')) {
            setMachineType('Mac');
        } else if (userAgent.includes('windows')) {
            setMachineType('Windows');
        } else {
            setMachineType('Unknown');
        }
    }, []);

    const getItemName = (id) => {
        for (const item of props.inventoryItems) {
            if (item.id == id) {
                return item.attributes.name;
            }
        }
        return "_";
    }

    const getItemUnit = (id) => {
        for (const item of props.inventoryItems) {
            if (item.id == id) {
                return item.attributes.unit;
            }
        }
        return "_";
    }

    const getItemGST = (id) => {
        for (const item of props.inventoryItems) {
            if (item.id == id) {
                return item.attributes.taxRate;
            }
        }
        return "_";
    }

    const getItemCostPrice = (id) => {
        for (const item of props.inventoryItems) {
            if (item.id == id) {
                return item.attributes.pretaxPrice;
            }
        }
        return "_";
    }
    const getItemSellingPrice = (id) => {
        for (const item of props.inventoryItems) {
            if (item.id == id) {
                return item.attributes.sellingPrice;
            }
        }
        return "_";
    }

    const getUI = () => {
        return (<div className="page-content">
            <div className="d-print-none">
                {/* <Topbar title="Sale order Receipt " hideButton={true} /> */}
                <Row className='d-flex align-items-start'>
                    <Col className="d-flex align-items-start">
                        <div className=' m-0'
                            style={{ cursor: "pointer" }}
                            onClick={() => { window.history.back() }}>
                            <ul className=" list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className=' m-0'>
                            <Button
                                className="btn m-1"
                                color={showPrintOrder == 1 ? "primary" : "secondary"}
                                onClick={() => {
                                    setShowPrintOrder(1)
                                }}>
                                Sale Order
                            </Button>
                            <Button
                                className="btn m-1"
                                color={showPrintOrder == 2 ? "primary" : "secondary"}
                                onClick={() => {
                                    setShowPrintOrder(2)
                                }}>
                                Challan/ Receipt
                            </Button>
                            <Button
                                className="btn m-1"
                                color={showPrintOrder == 3 ? "primary" : "secondary"}
                                onClick={() => {
                                    setShowPrintOrder(3)
                                }}>
                                Order Overview
                            </Button>
                            <Button
                                className="btn m-1"
                                color={showPrintOrder == 4 ? "primary" : "secondary"}
                                onClick={() => {
                                    setShowPrintOrder(4)
                                    getSaleOrderDueItems()
                                }}>
                                Order Due
                            </Button>
                        </div>
                    </Col>
                    <Col className="d-flex align-items-end justify-content-end">
                        <div className=' m-0'>
                            <Button
                                className="btn m-1"
                                color="success"
                                onClick={() => {
                                    window.print()
                                }}>
                                Print
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            {(showPrintOrder === 1 || showPrintOrder === 2) && (<Row>
                <div className="d-flex justify-content-end">
                    <div className="d-print-none">
                        <label><b>With Tax</b></label>
                    </div>
                    <div className="m-1 d-print-none">
                        <Switch
                            height={15}
                            width={30}
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            onColor={"#6add7d"}
                            checked={showAllItemsWithGst}
                            onChange={() => {
                                setShowAllItemsWithGst(!showAllItemsWithGst);
                            }}
                        />
                    </div>
                </div>
            </Row>)}
            {props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.status !== 0 ? (
                (showPrintOrder === 1 || showPrintOrder === 2) && (
                    <Row style={{ marginTop: "10px" }}>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className="Reciept-title">
                                        <Row className="d-flex justify-content-between">
                                            <Col className="d-flex justify-content-start">
                                                <img
                                                    src={props.franchise && props.franchise.attributes.logo ? props.franchise.attributes.logo._url : ""}
                                                    style={{ height: "6rem" }}
                                                />
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <div className="d-flex justify-content-start align-items-start">
                                                    <address className="float-right">
                                                        <span><strong>{props.franchise && props.franchise.attributes.name}</strong></span>
                                                        <br />
                                                        <span>{props.franchise && props.franchise.attributes.address}</span>
                                                        <br />
                                                        <span>{props.franchise && props.franchise.attributes.primaryPhone}, {props.franchise && props.franchise.attributes.secondaryPhone}</span>
                                                        <br />
                                                        <span>{props.franchise && props.franchise.attributes.email}</span>
                                                    </address>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr />
                                    <Row className="d-flex justify-content-between">
                                        <Col className="d-flex justify-content-start">
                                            <div>
                                                <h4>{showPrintOrder === 2 ? "Challan/ Receipt" : "Proforma Invoice"}</h4>
                                            </div>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <div className="float-right">
                                                <Label>{showPrintOrder === 2 ? "Challan/ Receipt" : "Proforma Invoice"} Number: #{props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.saleOrderNumber}</Label>
                                                <br />
                                                <Label>{showPrintOrder === 2 ? "Challan/ Receipt" : "Proforma Invoice"} Date: {showPrintOrder === 2 ? Moment(saleOrderLog && saleOrderLog.attributes.createdAt).format('DD-MMM-YYYY') : Moment(props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.createdAt).format('DD-MMM-YYYY')}</Label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="d-flex justify-content-between">
                                        <Col className="d-flex justify-content-start">
                                            <address className="mr-2">
                                                <strong><u>Details of Service provider</u></strong>
                                                <br />
                                                <span><strong>{props.franchise && props.franchise.attributes.name}</strong></span>
                                                <br />
                                                <span>{props.franchise && props.franchise.attributes.address}</span>
                                                <br />
                                                <span>{props.franchise && props.franchise.attributes.primaryPhone}, {props.franchise && props.franchise.attributes.secondaryPhone}</span>
                                                <br />
                                                <span>{props.franchise && props.franchise.attributes.email}</span>
                                            </address>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <address className="float-right">
                                                <strong><u>Details of Service Receiver</u></strong>
                                                <br />
                                                <span><strong>{props.selectedSchool && props.selectedSchool.attributes.Name}</strong></span>
                                                <br />
                                                <span>{props.selectedSchool && props.selectedSchool.attributes.Address}</span>
                                                <br />
                                                <span>{props.selectedSchool && props.selectedSchool.attributes.primaryPhoneNumber}, {props.selectedSchool && props.selectedSchool.attributes.secondaryPhoneNumber}</span>
                                                <br />
                                                <span>{props.selectedSchool && props.selectedSchool.attributes.EMail}</span>
                                            </address>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <div className="py-2 mt-3">
                                        <h3 className="font-size-15 font-weight-bold">
                                            Items summary
                                        </h3>
                                    </div>
                                    <div className="table-responsive">
                                        {showAllItemsWithGst ?
                                            <table className="table table-centered table-bordered mb-0 table-sm">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Items</th>
                                                        <th>Unit</th>
                                                        <th>Quantity</th>
                                                        <th>GST</th>
                                                        <th>Pretax Price</th>
                                                        <th>After Tax Price</th>
                                                        <th>Final Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        let serialNumber = 1;
                                                        return props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes && props.selectedInventorySaleOrder.attributes.acceptedItems &&
                                                            props.selectedInventorySaleOrder.attributes.acceptedItems.flatMap((item, key) => {
                                                                if (item.combinations && item.combinations.length > 0) {
                                                                    return item.combinations.flatMap((combItem, combKey) => {
                                                                        if (combItem.selectedQuntity === 0) return [];
                                                                        return (
                                                                            <tr key={`${key}-${combKey}`}>
                                                                                <td>{serialNumber++}</td>
                                                                                <td>
                                                                                    <b>{item.itemName}</b>{`: ${combItem.isColor ? combItem.colorValue : ''} ${combItem.isSize ? '& ' + combItem.sizeValue : ''}`}
                                                                                </td>
                                                                                <td>{getItemUnit(item.id)}</td>
                                                                                <td>{combItem.selectedQuntity}</td>
                                                                                <td>{`${getItemGST(item.id)} %`}</td>
                                                                                <td>{getItemCostPrice(item.id)}</td>
                                                                                <td>{getItemSellingPrice(item.id)}</td>
                                                                                <td><b>{combItem.finalPrice}</b></td>
                                                                            </tr>
                                                                        );
                                                                    });
                                                                } else {
                                                                    if (item.quantity === 0) return [];
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{serialNumber++}</td>
                                                                            <td><b>{item.itemName}</b></td>
                                                                            <td>{getItemUnit(item.id)}</td>
                                                                            <td>{item.quantity}</td>
                                                                            <td>{`${getItemGST(item.id)} %`}</td>
                                                                            <td>{getItemCostPrice(item.id)}</td>
                                                                            <td>{getItemSellingPrice(item.id)}</td>
                                                                            <td><b>{getItemSellingPrice(item.id) * item.quantity}</b></td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            });
                                                    })()}
                                                </tbody>
                                            </table> :
                                            <table className="table table-centered table-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "70px" }}>#</th>
                                                        <th>Items</th>
                                                        <th>Unit</th>
                                                        <th>Quantity</th>
                                                        <th>Avg Unit Cost</th>
                                                        <th>Total Cost</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData.map((item, key) => (
                                                        <tr key={key}>
                                                            <td>{key + 1}</td>
                                                            <td>{item.itemName}</td>
                                                            <td>{item.units}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{item.unitCost}</td>
                                                            <td>{item.totalCost}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>}
                                        {<hr />}
                                        {<Row>
                                            <Col>
                                                <div className="d-flex justify-content-end">
                                                    <FormGroup className="mb-0 d-flex align-items-center">
                                                        <Row>
                                                            <Col>
                                                                <Label className="mb-0">
                                                                    Total Amount:
                                                                </Label>
                                                            </Col>
                                                            <Col>
                                                                <Input
                                                                    placeholder="0.00"
                                                                    type="number"
                                                                    disabled={true}
                                                                    className="form-control me-2"
                                                                    value={props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.orderValue}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                        </Row>}
                                        {showPrintOrder === 2 && (
                                            <>
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex justify-content-end">
                                                            <FormGroup className="mb-0 d-flex align-items-center">
                                                                <Row>
                                                                    <Col>
                                                                        <Label className="mb-0">
                                                                            Freight Charge:
                                                                        </Label>
                                                                    </Col>
                                                                    <Col>
                                                                        <Input
                                                                            placeholder="0.00"
                                                                            type="number"
                                                                            disabled={true}
                                                                            className="form-control me-2"
                                                                            value={freightCharge}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col>
                                                        <div className="d-flex justify-content-end">
                                                            <FormGroup className="mb-0 d-flex align-items-center">
                                                                <Row>
                                                                    <Col className="float-end">
                                                                        <Label className="mb-0">
                                                                            Grand Total:
                                                                        </Label>
                                                                    </Col>
                                                                    <Col className="float-end">
                                                                        <Input
                                                                            placeholder="0.00"
                                                                            type="number"
                                                                            disabled={true}
                                                                            className="form-control me-2"
                                                                            value={grandTotal}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        {!showAllItemsWithGst && <hr />}
                                        <div className="d-flex justify-content-end d-print-none">
                                            <Label
                                                onClick={() => {
                                                    printOrder()
                                                }}
                                                className="btn btn-success waves-effect waves-light mr-2"
                                            >
                                                <i className="fa fa-print"></i>
                                            </Label>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )
            ) : (
                ((showPrintOrder === 1 || showPrintOrder === 2) && (<Row style={{ marginTop: "20px" }} className="d-print-none">
                    <Col>
                        <Card>
                            <CardHeader className="text-center">
                                <h5>{franchiseId ? "Please Approve the sale order to get challan." : "Order not yet accepted to generate challan."}</h5>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>))
            )}


            {(showPrintOrder == 3 && props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.status != 0) && (
                <Row style={{ marginTop: "10px" }}>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <div className="Reciept-title">
                                    <Row className="d-flex justify-content-between">
                                        <Col className="d-flex justify-content-start">
                                            <img
                                                src={props.franchise && props.franchise.attributes.logo ? props.franchise.attributes.logo._url : ""}
                                                style={{ height: "6rem" }}
                                            />
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <div className="d-flex justify-content-start align-items-start">
                                                <address className="float-right">
                                                    <span><strong>{props.franchise && props.franchise.attributes.name}</strong></span>
                                                    <br />
                                                    <span>{props.franchise && props.franchise.attributes.address}</span>
                                                    <br />
                                                    <span>{props.franchise && props.franchise.attributes.primaryPhone}, {props.franchise && props.franchise.attributes.secondaryPhone}</span>
                                                    <br />
                                                    <span>{props.franchise && props.franchise.attributes.email}</span>
                                                </address>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <hr />
                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-start">
                                        <div>
                                            <h4>Order Overview</h4>
                                        </div>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <div className="float-right">
                                            <Label>{"Order"} Number: #{props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.saleOrderNumber}</Label>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-start">
                                        <address className="mr-2">
                                            <strong><u>Details of Service provider</u></strong>
                                            <br />
                                            <span><strong>{props.franchise && props.franchise.attributes.name}</strong></span>
                                            <br />
                                            <span>{props.franchise && props.franchise.attributes.address}</span>
                                            <br />
                                            <span>{props.franchise && props.franchise.attributes.primaryPhone}, {props.franchise && props.franchise.attributes.secondaryPhone}</span>
                                            <br />
                                            <span>{props.franchise && props.franchise.attributes.email}</span>
                                        </address>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <address className="float-right">
                                            <strong><u>Details of Service Receiver</u></strong>
                                            <br />
                                            <span><strong>{props.selectedSchool && props.selectedSchool.attributes.Name}</strong></span>
                                            <br />
                                            <span>{props.selectedSchool && props.selectedSchool.attributes.Address}</span>
                                            <br />
                                            <span>{props.selectedSchool && props.selectedSchool.attributes.primaryPhoneNumber}, {props.selectedSchool && props.selectedSchool.attributes.secondaryPhoneNumber}</span>
                                            <br />
                                            <span>{props.selectedSchool && props.selectedSchool.attributes.EMail}</span>
                                        </address>
                                    </Col>
                                </Row>
                                <hr />
                                <div className="py-2 mt-3">
                                    <Row>
                                        <Col>
                                            <h3 className="font-size-15 font-weight-bold">
                                                Items summary
                                            </h3>
                                        </Col>
                                        <Col className="d-print-none">
                                            <Row>
                                                <Col>
                                                    <div className="form-check">
                                                        <Input
                                                            type="checkbox"
                                                            id="orderedQuantityCheckbox"
                                                            checked={isDueOrderedChecked}
                                                            onChange={() => setIsDueOrderedChecked(!isDueOrderedChecked)}
                                                            className="form-check-input"
                                                        />
                                                        <Label for="orderedQuantityCheckbox" className="form-check-label">
                                                            Show Ordered Quantity
                                                        </Label>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form-check">
                                                        <Input
                                                            type="checkbox"
                                                            id="deliveredQuantityCheckbox"
                                                            checked={isDueDeliveredChecked}
                                                            onChange={() => setIsDueDeliveredChecked(!isDueDeliveredChecked)}
                                                            className="form-check-input"
                                                        />
                                                        <Label for="deliveredQuantityCheckbox" className="form-check-label">
                                                            Show Due Quantity
                                                        </Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-centered table-bordered mb-0 table-sm">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Items Name</th>
                                                {isDueOrderedChecked && <th>Ordered Quantity</th>}
                                                {<th>{props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.status == 1 ? "Delivered Quantity" : "Delivering Quantity"}</th>}
                                                {isDueDeliveredChecked && <th>Due Quantity</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.acceptedItems.map((dueItem, key) => (
                                                <React.Fragment key={key}>
                                                    <tr>
                                                        <td>
                                                            <Label><b>{key + 1}</b></Label>
                                                        </td>
                                                        <td>
                                                            <Label>{dueItem.itemName}</Label>
                                                        </td>
                                                        {isDueOrderedChecked && <td>
                                                            <Label className="font-size-12 mb-1">{dueItem.quantity}</Label>
                                                        </td>}
                                                        {<td>
                                                            <Label className="font-size-12 mb-1">{dueItem.deliveringQuantity}</Label>
                                                        </td>}
                                                        {isDueDeliveredChecked && <td>
                                                            <Label className="font-size-12 mb-1"><b>{dueItem.dueQuantity}</b></Label>
                                                        </td>}
                                                    </tr>
                                                    {dueItem && dueItem.matrix && dueItem.matrix.combinations && dueItem.matrix.combinations.length > 0 && (
                                                        <tr>
                                                            <td colSpan="5">
                                                                <div className="table-responsive text-center" style={{ width: '100%' }}>
                                                                    <table className="table table-centered table-bordered mb-0 table-sm" style={{ width: '90%', margin: 'auto' }}>
                                                                        <thead className="thead-light">
                                                                            <tr>
                                                                                {dueItem.matrix.combinations.some(matrixItem => matrixItem.isColor) && <th>Color Value</th>}
                                                                                {dueItem.matrix.combinations.some(matrixItem => matrixItem.isSize) && <th>Size Value</th>}
                                                                                {isDueOrderedChecked && <th>Ordered Quantity</th>}
                                                                                {<th>{props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.status == 1 ? "Delivered Quantity" : "Delivering Quantity"}</th>}
                                                                                {isDueDeliveredChecked && <th>Due Quantity</th>}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {dueItem.matrix.combinations.map((matrixItem, matrixKey) => (
                                                                                <tr key={matrixKey}>
                                                                                    {matrixItem.isColor && <td>{matrixItem.colorValue}</td>}
                                                                                    {matrixItem.isSize && <td>{matrixItem.sizeValue}</td>}
                                                                                    {isDueOrderedChecked && <td>{matrixItem.selectedQuntity}</td>}
                                                                                    {<td>{matrixItem.deliveringQuantity}</td>}
                                                                                    {isDueDeliveredChecked && <td><b>{matrixItem.dueQuantity}</b></td>}
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
            {(showPrintOrder == 4 && props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.status != 0) && (
                <Row style={{ marginTop: "10px" }}>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <div className="Reciept-title">
                                    <Row className="d-flex justify-content-between">
                                        <Col className="d-flex justify-content-start">
                                            <img
                                                src={props.franchise && props.franchise.attributes.logo ? props.franchise.attributes.logo._url : ""}
                                                style={{ height: "6rem" }}
                                            />
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <div className="d-flex justify-content-start align-items-start">
                                                <address className="float-right">
                                                    <span><strong>{props.franchise && props.franchise.attributes.name}</strong></span>
                                                    <br />
                                                    <span>{props.franchise && props.franchise.attributes.address}</span>
                                                    <br />
                                                    <span>{props.franchise && props.franchise.attributes.primaryPhone}, {props.franchise && props.franchise.attributes.secondaryPhone}</span>
                                                    <br />
                                                    <span>{props.franchise && props.franchise.attributes.email}</span>
                                                </address>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <hr />
                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-start">
                                        <div>
                                            <h4>Order Due</h4>
                                        </div>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <div className="float-right">
                                            <Label>{"Order"} Number: #{props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.saleOrderNumber}</Label>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-start">
                                        <address className="mr-2">
                                            <strong><u>Details of Service provider</u></strong>
                                            <br />
                                            <span><strong>{props.franchise && props.franchise.attributes.name}</strong></span>
                                            <br />
                                            <span>{props.franchise && props.franchise.attributes.address}</span>
                                            <br />
                                            <span>{props.franchise && props.franchise.attributes.primaryPhone}, {props.franchise && props.franchise.attributes.secondaryPhone}</span>
                                            <br />
                                            <span>{props.franchise && props.franchise.attributes.email}</span>
                                        </address>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <address className="float-right">
                                            <strong><u>Details of Service Receiver</u></strong>
                                            <br />
                                            <span><strong>{props.selectedSchool && props.selectedSchool.attributes.Name}</strong></span>
                                            <br />
                                            <span>{props.selectedSchool && props.selectedSchool.attributes.Address}</span>
                                            <br />
                                            <span>{props.selectedSchool && props.selectedSchool.attributes.primaryPhoneNumber}, {props.selectedSchool && props.selectedSchool.attributes.secondaryPhoneNumber}</span>
                                            <br />
                                            <span>{props.selectedSchool && props.selectedSchool.attributes.EMail}</span>
                                        </address>
                                    </Col>
                                </Row>
                                <hr />
                                <div className="py-2 mt-3">
                                    <Row>
                                        <Col>
                                            <h3 className="font-size-15 font-weight-bold">
                                                Due Items summary
                                            </h3>
                                        </Col>
                                        <Col className="d-print-none">
                                            <Row>
                                                <Col>
                                                    <div className="form-check">
                                                        <Input
                                                            type="checkbox"
                                                            id="orderedQuantityCheckbox"
                                                            checked={isSummaryOrderedChecked}
                                                            onChange={() => setIsSummaryOrderedChecked(!isSummaryOrderedChecked)}
                                                            className="form-check-input"
                                                        />
                                                        <Label for="orderedQuantityCheckbox" className="form-check-label">
                                                            Show Ordered Quantity
                                                        </Label>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form-check">
                                                        <Input
                                                            type="checkbox"
                                                            id="deliveredQuantityCheckbox"
                                                            checked={isSummaryDeliveredChecked}
                                                            onChange={() => setIsSummaryDeliveredChecked(!isSummaryDeliveredChecked)}
                                                            className="form-check-input"
                                                        />
                                                        <Label for="deliveredQuantityCheckbox" className="form-check-label">
                                                            {props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.status == 1 ? "Show Delivered Quantity" : "Show Delivering Quantity"}
                                                        </Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-centered table-bordered mb-0 table-sm">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Items Name</th>
                                                {isSummaryOrderedChecked && <th>Ordered Quantity</th>}
                                                {isSummaryDeliveredChecked && <th>{props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.status == 1 ? "Delivered Quantity" : "Delivering Quantity"}</th>}
                                                <th>Due Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dueItems.map((dueItem, key) => (
                                                <React.Fragment key={key}>
                                                    <tr>
                                                        <td>
                                                            <Label><b>{key + 1}</b></Label>
                                                        </td>
                                                        <td>
                                                            <Label>{getItemName(dueItem.attributes.itemId)}</Label>
                                                        </td>
                                                        {isSummaryOrderedChecked && <td>
                                                            <Label className="font-size-12 mb-1">{dueItem.attributes.orderdQuantity}</Label>
                                                        </td>}
                                                        {isSummaryDeliveredChecked && <td>
                                                            <Label className="font-size-12 mb-1">{dueItem.attributes.deliveringQuantity}</Label>
                                                        </td>}
                                                        <td>
                                                            <Label className="font-size-12 mb-1"><b>{dueItem.attributes.dueQuantity}</b></Label>
                                                        </td>
                                                    </tr>
                                                    {dueItem.attributes.matrix && dueItem.attributes.matrix.length > 0 && (
                                                        <tr>
                                                            <td colSpan="5">
                                                                <div className="table-responsive text-center" style={{ width: '100%' }}>
                                                                    <table className="table table-centered table-bordered mb-0 table-sm" style={{ width: '90%', margin: 'auto' }}>
                                                                        <thead className="thead-light">
                                                                            <tr>
                                                                                {dueItem.attributes.matrix.some(matrixItem => matrixItem.isColor) && <th>Color Value</th>}
                                                                                {dueItem.attributes.matrix.some(matrixItem => matrixItem.isSize) && <th>Size Value</th>}
                                                                                {isSummaryOrderedChecked && <th>Ordered Quantity</th>}
                                                                                {isSummaryDeliveredChecked && <th>{props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.status == 1 ? "Delivered Quantity" : "Delivering Quantity"}</th>}
                                                                                <th>Due Quantity</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {dueItem.attributes.matrix.map((matrixItem, matrixKey) => (
                                                                                <tr key={matrixKey}>
                                                                                    {matrixItem.isColor && <td>{matrixItem.colorValue}</td>}
                                                                                    {matrixItem.isSize && <td>{matrixItem.sizeValue}</td>}
                                                                                    {isSummaryOrderedChecked && <td>{matrixItem.selectedQuntity}</td>}
                                                                                    {isSummaryDeliveredChecked && <td>{matrixItem.deliveringQuantity}</td>}
                                                                                    <td><b>{matrixItem.dueQuantity}</b></td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}

            {(showPrintOrder == 3 || showPrintOrder == 4) && props.selectedInventorySaleOrder && props.selectedInventorySaleOrder.attributes.status == 0 && (
                <Row style={{ marginTop: "20px" }}>
                    <Col>
                        <Card>
                            <CardHeader className="text-center">
                                <h5>{franchiseId ? "Please Approve the sale order to get order overview or due." : "Order not yet accepted to generate order overview or due."}</h5>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
            )}
            {
                isLoading && <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <button className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading sale order... </button>
                        </div>
                    </Col>
                </Row>
            }
        </div>
        )
    }

    return (<React.Fragment>
        <div className="account-pages">
            <Container fluid='md'>{getUI()}</Container>
        </div>
    </React.Fragment>

    )
}

const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    const { inventoryItems } = state.InventoryItems;
    const { selectedInventorySaleOrder } = state.InventorySaleOrder;
    const { selectedInventorySaleOrderItems } = state.InventorySaleOrderItem;
    const { selectedKid } = state.Kid;
    const { selectedTeacher } = state.Teacher;
    const { franchise } = state.Franchise;
    return { selectedSchool, inventoryItems, selectedInventorySaleOrder, selectedInventorySaleOrderItems, selectedKid, selectedTeacher, schools, franchise };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, getInventoryItemForOwnerId, setSelectedSaleOrder, setSelectedSaleOrderItems, getAKid, setSelecetdTeacher, getFranchise })(PrintSaleOrder)));