import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, CardBody, Card, FormGroup, Label, Table, Button, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Moment from 'moment';
import Lightbox from "react-image-lightbox";
import Parse from 'parse';
import {
    setSelecetdSchool, getCurriculumForownerId, getTeachersForSchool, getLearningSessionForSchoolId, getAllKids, getLearningCategoriesForIds, getOnlineLearingSessionForSchoolId,
    getLearningActivitiesForIds, getAlbumsForAlbumIds, getSchoolForInstitute, clearAllKid, clearAllTeachers, clearLearningSession,updateLearningSession,getCurriculumCycleCycleIds,
} from '../../../store/actions';
import Select from "react-select";
import * as XLSX from 'xlsx/xlsx.mjs';
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import toastr from "toastr";

const LearningSession = (props) => {


    const match = matchPath(props.history.location.pathname, {
        path: "/learning-session/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;

    const [dateType, setDateType] = useState(1)
    const [learningSessionType, setLearningSessionType] = useState(1)
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());

    const [isShowGallery, setIsShowGallery] = useState(false);
    const [photoIndex, setphotoIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [allSchools, setAllSchools] = useState([]);
    const [selectedSchool, setSelctedSchool] = useState({ value: "Select School", label: 'Select School' });
    const [schoolSelectedError, setSchoolSelectedError] = useState(false)
    const [kidList, setKidList] = useState([])
    const [selectedKid, setSelectedKid] = useState({ value: "Select Student", label: 'Select Student' })
    const [teacherList, setTeacherList] = useState([])
    const [classModal, toggleClassModal] = useState(false);
    const [title, setTitle] = useState(undefined);
    const [selectedTitleForEdit, setSelectedTitleForEdit] = useState(undefined);
    const [selectedSession, setSelectedSession] = useState(undefined);
    const [selectedTeacher, setSelectedTeacher] = useState({ value: "Select Teacher", label: 'Select Teacher' })

    const toggleSessionModal = (session) => {
        setSelectedSession(session);
        toggleClassModal(!classModal);
    };

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId != "franchise") {
            props.getAllKids(schoolId);
            props.getTeachersForSchool(schoolId);
            props.getLearningSessionForSchoolId(schoolId,)
        } else {
            props.clearAllKid();
            props.clearAllTeachers();
            props.clearLearningSession();
            props.getAllKids(selectedSchool.value);
            props.getTeachersForSchool(selectedSchool.value);
            props.getLearningSessionForSchoolId(selectedSchool.value);
        }
    }, [schoolId, selectedSchool])

    useEffect(() => {
        if (props.schools) {
            let list = [{ value: "Select School", label: 'Select School' }];
            for (const school of props.schools) {
                list.push({ value: school.id, label: school.attributes.Name })
            }
            setAllSchools(list);
        }

        if (props.kids) {
            let list = [{ value: "Select Student", label: 'Select Student' }];
            for (const kid of props.kids) {
                list.push({ value: kid.id, label: kid.attributes.Name })
            }
            setKidList(list);
        }

        if (props.teachers) {
            let list = [{ value: "Select Teacher", label: 'Select Teacher' }];
            for (const teacher of props.teachers) {
                list.push({ value: teacher.id, label: teacher.attributes.Name })
            }
            setTeacherList(list);
        }

    }, [props.schools, props.kids, props.teachers]);


    useEffect(() => {
        const processLearningSessions = (sessions) => {
            let categoryIds = [];
            let activityIds = [];
            let albumIds = [];

            for (const session of sessions) {
                const attributes = session.attributes;
                categoryIds.push(attributes.categoryId);
                activityIds.push(attributes.activityId);
                albumIds.push(attributes.albumId);
            }

            props.getLearningCategoriesForIds(categoryIds);
            props.getLearningActivitiesForIds(activityIds);
            if (albumIds.length > 0) {
                props.getAlbumsForAlbumIds(albumIds);
            }
        };

        if (props.learningSession && props.learningSession.length > 0) {
            processLearningSessions(props.learningSession);
        }

        if (props.onlineLearningSession && props.onlineLearningSession.length > 0) {
            processLearningSessions(props.onlineLearningSession);
        }
    }, [props.learningSession, props.onlineLearningSession]);


    useEffect(()=>{

        if(props.learningCategories && props.learningCategories.length>0){
            let list = []
            let list2 = []

            let exList=[]
            for(const cc of props.curriculumAcademicCycles){
                exList.push(cc.id)
            }
            for(const lc of props.learningCategories){
                let cycleId = lc.attributes.cycleId
                if(cycleId && !list.includes(cycleId)){
                    list.push(cycleId)
                    if(!exList.includes(cycleId)){
                        list2.push(cycleId)
                    }
                }
            }

            if(list2.length>0){
                props.getCurriculumCycleCycleIds(list2,)
            }
        }

    },[props.learningCategories])

    const getAlbumsForAlbumId = (albumId) => {
        if (props.albums && props.albums.length > 0) {
            let albums = props.albums.filter((album) =>
                album.attributes.albumId === albumId
            )
            if (albums.length > 0) {
                return albums
            }
        }
        return undefined
    }

    const getLightBoxImagesUI = (albumId) => {
        var albums = getAlbumsForAlbumId(albumId)
        var images = []
        if (albums && albums.length > 0) {
            images = []
            for (const album of albums) {
                let image = album.attributes.image ? album.attributes.image._url : ""
                images.push(image)
            }
        }
        return (<Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={
                images[(photoIndex + images.length - 1) % images.length]
            }
            enableZoom={true}
            onCloseRequest={() => { setIsShowGallery(false) }}
            onMovePrevRequest={() => { setphotoIndex((photoIndex + images.length - 1) % images.length) }}
            onMoveNextRequest={() => { setphotoIndex((photoIndex + 1) % images.length) }}
            imageCaption={"Image " + parseFloat(photoIndex + 1)}
        />)
    }

    const getTeacherName = (teacherId) => {
        if (props.teachers && props.teachers.length > 0) {
            let temp = props.teachers.filter(
                (teacher) => teacher.id === teacherId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const getCategoryName = (categoryId) => {
        if (props.learningCategories && props.learningCategories.length > 0) {
            let temp = props.learningCategories.filter(
                (category) => category.id === categoryId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.name ?? "-")
            }
        }
    }

    const getActivityName = (activityId) => {
        if (props.learningActivities && props.learningActivities.length > 0) {
            let temp = props.learningActivities.filter(
                (activity) => activity.id === activityId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.name ?? "-")
            }
        }
    }

    const getStudentName = (kidId) => {
        if (props.kids && props.kids.length > 0) {
            let temp = props.kids.filter(
                (kid) => kid.id === kidId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name + " " ?? "-")
            }
        }
    }

    const getCycleName = (categoryId,postFx) => {
        const category = props.learningCategories.find(
            (cat) => cat.id === categoryId
        );
        return category ? getCycle(category.attributes.cycleId,postFx) : null;
    };
    

    const getCycle = (cycleId,postFx) => {
        console.log("CycleId", cycleId)
        if (props.curriculumAcademicCycles && props.curriculumAcademicCycles.length > 0) {
            let temp = props.curriculumAcademicCycles.find(
                (cycle) => cycle.id === cycleId
            );
            if (temp) {
                return (temp.attributes.schoolCycle?temp.attributes.schoolCycle+postFx: "")
            }
        }
        return ""; 
    };
    
    const handleChange = ([newStartDate, newEndDate]) => {
        setSelectedStartDate(newStartDate);
        setSelectedEndDate(newEndDate);
    };


    const downloadReport = () => {

        const fileName = `Published_LMS_Report.xlsx`;
        const sheetName = "Learning_Session_Report";
        const finalData = [];

        finalData.push([
            `Report Type: ${learningSessionType == 1 ? "Assessment Report" : "Home Learning Report"}`,
        ]);
        if (props.selectedSchool && props.selectedSchool) {
            finalData.push([
                `School: ${props.selectedSchool.attributes.Name}`,
            ]);
        }
        if (selectedSchool && selectedSchool.value != "Select School") {
            finalData.push([
                `School: ${selectedSchool.label}`,
            ]);
        }

        finalData.push([
            `Selected Date: ${dateType === 1 ? Moment(selectedStartDate).format('DD-MMM-YYYY') : Moment(selectedStartDate).format('DD-MMM-YYYY') + ' - ' + Moment(selectedEndDate).format('DD-MMM-YYYY')}`,
        ]);

        if (selectedKid && selectedKid.value != "Select Student") {
            finalData.push([
                `Student: ${selectedKid.label}`,
            ]);
        }

        if (selectedTeacher && selectedTeacher.value != "Select Teacher") {
            finalData.push([
                `Teacher: ${selectedTeacher.label}`,
            ]);
        }
        finalData.push([]);
        finalData.push([]);

        const titles = [
            "Si no.",
            "Recipients",
            "Syllabus",
            "Activity",
            "Category",
            "Created At",
            "Description",
            "Created By",
        ];
        finalData.push(titles);
        {console.log("Titles",titles)}

        let slno = 1;

        const sessions = learningSessionType == 1 ? props.learningSession : props.onlineLearningSession;

        for (const session of sessions) {
            const recipients = session.attributes.recipients;
            const cycleName = getCycleName(session.attributes.categoryId," ");
            
            for (const recipient of recipients) {
                const rowData = [
                    slno++,
                    getStudentName(recipient),
                    cycleName,
                    getActivityName(session.attributes.activityId),
                    getCategoryName(session.attributes.categoryId),
                    Moment(session.attributes.createdAt).format('DD-MMM-YYYY'),
                    session.attributes.description,
                    getTeacherName(session.attributes.createdBy),
                ];
                finalData.push(rowData);
            }
        }

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(finalData);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName);
    };



    return (
        <React.Fragment >
            <div className="page-content">
                {isShowGallery && isShowGallery.length > 0 ? (
                    getLightBoxImagesUI(isShowGallery)
                ) : null}
                <Row className='d-flex align-items-start'>
                    <Col className="d-flex align-items-start">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { window.history.back() }}>
                            <ul className=" list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className=' m-2'>
                            <h4 className="m-0">Published History</h4>
                        </div>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row className="bg-light ">
                            {schoolId === "franchise" && <Col lg={12}> <Col md="3" xs="12" xl="3" lg="3">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>Selcted school :</Label>

                                    <Select
                                        value={selectedSchool}
                                        onChange={(v) => {
                                            setSelctedSchool(v)
                                            setSchoolSelectedError(false)
                                        }}
                                        options={allSchools}
                                        className="selectPaymentType"
                                        classNamePrefix="selectPayType"

                                    />
                                    {schoolSelectedError && <p className='text-danger'>Please Select School</p>}
                                </FormGroup>
                            </Col></Col>}
                            <Col className='ml-2' md="2" xs="12" xl="2" lg="2">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>Learning Session :</Label>
                                    <select
                                        className="form-control "
                                        value={learningSessionType}
                                        onChange={(e) => {
                                            setDateType(1)
                                            setLearningSessionType(e.target.value);
                                            setSelectedKid()
                                            setSelectedTeacher()
                                            props.clearLearningSession();
                                        }}
                                    >
                                        <option value={1}>Assessment</option>
                                        <option value={2}>Home Learning</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="2" xs="12" xl="2" lg="2">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>Select Filter :</Label>
                                    <select
                                        className="form-control "
                                        value={dateType}
                                        onChange={(e) => {
                                            setDateType(e.target.value);
                                        }}
                                    >
                                        <option value={1}>Today</option>
                                        <option value={2}>Custom Date</option>
                                        <option value={3}>Students</option>
                                        <option value={4}>Teachers</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            {dateType == 3 && <Col>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label >Select Kid : </Label>
                                    <Select
                                        value={selectedKid}
                                        onChange={(v) => {
                                            setSelectedKid(v)
                                        }}
                                        options={kidList}
                                        classNamePrefix="select2-selection"
                                    />

                                </FormGroup>
                            </Col>}
                            {dateType == 4 && <Col>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label >Select Teacher : </Label>
                                    <Select
                                        value={selectedTeacher}
                                        onChange={(v) => {
                                            setSelectedTeacher(v)
                                        }}
                                        options={teacherList}
                                        classNamePrefix="select2-selection"
                                    />

                                </FormGroup>
                            </Col>}
                            {dateType != 1 && <Col>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>Start Date :</Label>
                                    <DatePicker
                                        selected={selectedStartDate}
                                        onChange={(date) => {
                                            setSelectedStartDate(date);
                                        }}
                                        className="form-control"
                                        placeholderText={"Select Date"}
                                        // startDate={fromDate}
                                        // endDate={toDate}
                                        maxDate={selectedEndDate}
                                        dateFormat="dd/MM/yyyy"
                                        showFullMonthYearPicker
                                    />
                                </FormGroup>
                            </Col>}
                            {dateType != 1 && <Col >
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>End Date :</Label>
                                    <DatePicker
                                        selected={selectedEndDate}
                                        onChange={(date) => {
                                            setSelectedEndDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={"Select Date"}
                                        // startDate={fromDate}
                                        // endDate={toDate}
                                        minDate={selectedStartDate}
                                        maxDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        showFullMonthYearPicker
                                    />
                                </FormGroup>
                            </Col>}
                            <Col md="2" xs="12" xl="2" lg="2">
                                <div className="mt-2">
                                    <div className="mt-3">
                                        <button
                                            className="btn btn-primary mt-4 mr-2"
                                            onClick={() => {
                                                setLoading(true);
                                                const filter = dateType == 1 ? undefined : {
                                                    startDate: selectedStartDate,
                                                    endDate: selectedEndDate,
                                                    type: dateType == 3 ? "kid" : dateType == 4 ? "teacher" : undefined,
                                                    kidId: dateType == 3 ? selectedKid.value : undefined,
                                                    teacherId: dateType == 4 ? selectedTeacher.value : undefined,
                                                };
                                                if (schoolId !== "franchise") {
                                                    if (learningSessionType == 1) {
                                                        props.getLearningSessionForSchoolId(schoolId, filter);
                                                    } else {
                                                        props.getOnlineLearingSessionForSchoolId(schoolId, filter);
                                                    }
                                                } else {
                                                    //selectedSchool.value when schoolId is "franchise"
                                                    let next = true;
                                                    if (selectedSchool.value == "Select School") {
                                                        setSchoolSelectedError(true);
                                                        next = false;
                                                    }

                                                    if (next) {
                                                        if (learningSessionType == 1) {
                                                            props.getLearningSessionForSchoolId(selectedSchool.value, filter);
                                                        } else {
                                                            props.getOnlineLearingSessionForSchoolId(selectedSchool.value, filter);
                                                        }
                                                    }
                                                }
                                                setLoading(false);
                                            }}
                                        >
                                            Generate
                                        </button>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {loading && (
                    <Col xs="12">
                        <div className="xtext-center my-3">
                            <Link to="#" className="text-success">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                Loading....{" "}
                            </Link>
                        </div>
                    </Col>
                )}
                <Row>
                    <Col lg="12">
                        <div className="">
                            <div className="table-responsive">
                                <Table className="project-list-table table-nowrap table-centered table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "100px" }}>#</th>
                                            <th scope="col">Activities {(props.learningSession.length > 0 || props.onlineLearningSession.length > 0) && <span className='text-primary' style={{ marginLeft: '1cm', cursor: 'pointer' }} onClick={() => { downloadReport() }}><u><i className='bx bx-download font-size-15 align-middle '></i>Download Report</u></span>}</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Recipients</th>
                                            {learningSessionType == 1 && <th scope="col">Attachments</th>}
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {learningSessionType == 1 &&
                                            props.learningSession.map((session, key) =>
                                                <tr key={key}>
                                                    <td>
                                                        <h4 className='font-size-16'>{key + 1}</h4>
                                                    </td>
                                                    <td style={{ width: "40%" }}>
                                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">{getActivityName(session.attributes.activityId)}</Link></h5>
                                                        <h6 className=''>{getCategoryName(session.attributes.categoryId)}</h6>
                                                        <h6 className='mb-0'>{getCycleName(session.attributes.categoryId," Syllbus")}</h6>
                                                        <p className="text-muted  mb-0" style={{ whiteSpace: "pre-wrap" }} >{session.attributes.description}</p>
                                                        <p className="mb-0 py-2">Created By:{getTeacherName(session.attributes.createdBy)}</p>
                                                    </td>
                                                    <td>{Moment(session.attributes.createdAt).format('DD-MMM-YYYY')}</td>
                                                    <td>
                                                        <div className="team">
                                                            <Label id={key}>
                                                                {session.attributes.recipients && session.attributes.recipients.length > 0 && (
                                                                    session.attributes.recipients.slice(0, 3).map((studentId, index) => (
                                                                        <p key={studentId} style={{ margin: '0', marginBottom: index === 2 ? '0' : '4px' }}>
                                                                            {getStudentName(studentId)}
                                                                        </p>
                                                                    ))
                                                                )}
                                                                {session.attributes.recipients.length > 3 && (
                                                                    <p id={`recipients-${key}`} style={{ margin: '0' }}>+ {session.attributes.recipients.length - 3} more</p>
                                                                )}
                                                            </Label>
                                                            {session.attributes.recipients.length > 3 && (
                                                                <UncontrolledTooltip placement="top" target={`recipients-${key}`}>
                                                                    {session.attributes.recipients.slice(3).map(studentId => (
                                                                        <p key={studentId} className="mb-0">{getStudentName(studentId)}</p>
                                                                    ))}
                                                                </UncontrolledTooltip>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className='text-center'>
                                                        {session.attributes.albumId && <i id={"msgAttachment" + key} className="fa fa-paperclip font-size-24 text-center"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setIsShowGallery(session.attributes.albumId);
                                                                setphotoIndex(0);
                                                            }}></i>}
                                                        {session.attributes.albumId && <UncontrolledTooltip placement="top" target={"msgAttachment" + key}>
                                                            {props.t("Attachments")}
                                                        </UncontrolledTooltip>}
                                                    </td>
                                                    <td>
                                                        <Link to={schoolId != "franchise" ? `/learning-session-details/${schoolId}/assesment/${session.id}` : `/learning-session-details/${selectedSchool.value}/assesment/${session.id}`}>
                                                            <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light">View Details</Button>
                                                        </Link>
                                                        <span><i
                                                            id={"edit" + key}
                                                            className="bx bx-edit-alt text-dark px-2"
                                                            onClick={() => {
                                                                toggleSessionModal(session);
                                                            }}
                                                        ></i></span>
                                                        <UncontrolledTooltip placement="top" target={"edit" + key}>
                                                            Edit
                                                        </UncontrolledTooltip>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        {learningSessionType == 2 &&
                                            props.onlineLearningSession.map((session, key) =>
                                                <tr key={key}>
                                                    <td>
                                                        <b className='font-size-16'>{key + 1}</b>
                                                    </td>
                                                    <td>
                                                        <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">{getActivityName(session.attributes.activityId)}</Link></h5>
                                                        <h6 className='mb-0'>{getCategoryName(session.attributes.categoryId)}</h6>
                                                        <p className="text-muted  mb-0">{session.attributes.description}</p>
                                                        <p className="mb-0 py-2">Created By:{getTeacherName(session.attributes.createdBy)}</p>
                                                    </td>
                                                    <td>{Moment(session.attributes.createdAt).format('DD-MMM-YYYY')}</td>
                                                    <td>
                                                        <div className="team">
                                                            <Label id={key}>
                                                                {session.attributes.recipients && session.attributes.recipients.length > 0 && (
                                                                    session.attributes.recipients.slice(0, 3).map((studentId, index) => (
                                                                        <p key={studentId} style={{ margin: '0', marginBottom: index === 2 ? '0' : '4px' }}>
                                                                            {getStudentName(studentId)}
                                                                        </p>
                                                                    ))
                                                                )}
                                                                {session.attributes.recipients.length > 3 && (
                                                                    <p id={`recipients-${key}`} style={{ margin: '0' }}>+ {session.attributes.recipients.length - 3} more</p>
                                                                )}
                                                            </Label>
                                                            {session.attributes.recipients.length > 3 && (
                                                                <UncontrolledTooltip placement="top" target={`recipients-${key}`}>
                                                                    {session.attributes.recipients.slice(3).map(studentId => (
                                                                        <p key={studentId} className="mb-0">{getStudentName(studentId)}</p>
                                                                    ))}
                                                                </UncontrolledTooltip>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Link to={schoolId != "franchise" ? `/learning-session-details/${schoolId}/homeLearning/${session.id}` : `/learning-session-details/${selectedSchool.value}/homeLearning/${session.id}`}>
                                                            <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light">View Details</Button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                    {/* {props.learningSession.length === 0 && !loading && (
                        <Col>
                            <h3 className="text-center text-info mt-3">
                                No Data Found For The Filter
                            </h3>
                        </Col>
                    )}
                    {props.onlineLearningSession.length === 0 && !loading && (
                        <Col>
                            <h3 className="text-center text-info mt-3">
                                No Data Found For The Filter
                            </h3>
                        </Col>
                    )} */}
                </Row>
            </div>
            {selectedSession && <Modal isOpen={classModal} scrollable={true}>
                <ModalHeader isOpen={classModal}>
                    <div className="modal-title mt-0">Edit Activity</div>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <AvForm >
                        <Row form>
                            <Col className="col-12">
                                <AvField
                                    name="note"
                                    label={props.t('ActivityId')}
                                    type="text"
                                    className="form-control"
                                    rows="3"
                                    placeholder={props.t('Activity')}
                                    value={getActivityName(selectedSession.attributes.activityId)}
                                    disabled={true}
                                />
                            </Col>
                            <Col className="col-12">
                                <AvField
                                    name="note"
                                    label={props.t('CategoryId')}
                                    type="text"
                                    className="form-control"
                                    rows="3"
                                    placeholder={props.t('Category')}
                                    value={getCategoryName(selectedSession.attributes.categoryId)}
                                    disabled={true}

                                />
                            </Col>
                            <Col className="col-12">
                                <AvField
                                    name="note"
                                    label={props.t('Description')}
                                    type="textarea"
                                    className="form-control"
                                    rows="3"
                                    id="session_description"
                                    placeholder={props.t('Description')}
                                    value={selectedSession.attributes.description}
                                />
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                toggleClassModal(false);
                            }}
                        >
                            {props.t("Close")}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary m-2"
                            onClick={() => {

                                let newDes=  document.getElementById("session_description").value ;

                                if(selectedSession.attributes.description != newDes){
                                    selectedSession.set("description",newDes);

                                    selectedSession.save().then((result)=>{
                                        props.updateLearningSession([result],false);
                                        toastr.success("Saved successfully")
                                        toggleSessionModal(undefined)
                                    })
                                }
                            }}
                        >
                            {props.t("Save")}
                        </button>
                    </div>
                </ModalFooter>
            </Modal>}
            <div style={{ height: "400px" }}></div>
        </React.Fragment>
    );

}
const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { curriculumAcademicCycles, loadState } = state.AcademicCycles;
    const { learningCategories } = state.LearningCategory;
    const { learningSubcategories, subcategoryState } = state.LearningSubcategory;
    const { learningActivities } = state.LearningActivity;
    const { teachers } = state.Teacher;
    const { userTeacher } = state.Login;
    const { learningCurriculumsSchoolMapForSchoolId } = state.LearningCurriculumsSchoolMap;
    const { curriculums } = state.Curriculum;
    const { learningSession, onlineLearningSession } = state.LearningSession;
    const { kids } = state.Kid;
    const { albums } = state.Message;

    return { schools, selectedSchool, curriculumAcademicCycles, loadState, learningCategories, learningSubcategories, subcategoryState, learningActivities, teachers, userTeacher, learningCurriculumsSchoolMapForSchoolId, curriculums, learningSession, onlineLearningSession, kids, albums };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getCurriculumForownerId, getTeachersForSchool, getLearningSessionForSchoolId, getAllKids, getLearningCategoriesForIds, getOnlineLearingSessionForSchoolId,
    getLearningActivitiesForIds, getAlbumsForAlbumIds, getSchoolForInstitute, clearAllKid, clearAllTeachers, clearLearningSession,updateLearningSession,getCurriculumCycleCycleIds,
})(LearningSession)));