import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getInvoiceDetailsByFunction } from "../../store/actions";
import Moment from "moment";
import Parse from "parse";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import OnlinePatment from "./onlinePayment";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Constants from "../../Constents";
import COUNTRY_JSON from '../../assets/other/countries.json'


const OnlineTransaction = (props) => {
    const match1 = matchPath(props.history.location.pathname, {
        path: "/transaction/:invoiceId/:transactionId",
        exact: true,
        strict: false,
    });
    const match2 = matchPath(props.history.location.pathname, {
        path: "/transaction/:gatewayType/:invoiceId/:transactionId/",
        exact: true,
        strict: false,
    });
    const { register, handleSubmit, formState: { errors } } = useForm();
    const match = match1 ? match1 : match2;
    const invoiceId = match.params.invoiceId;
    const transactionId = match.params.transactionId
    const [transactionObject, setTransactionObject] = useState(undefined)
    const [gatewayType, setGatewayType] = useState(match.params.gatewayType)
    const [paymentMode, setPayentMode] = useState("1");
    const [invoiceDetails, setInvoiceDetailsObject] = useState({});
    const [kid, setKidObject] = useState({});
    const [school, setSchoolObject] = useState({});
    const [parents, setParentObject] = useState([]);
    
    const [currency, setCurrency] = useState("₹");

    const [inforLoading, setinforLoading] = useState(true);
    const [showNoPG, setShowNoPG] = useState(false);

    
    

    const [invoiceDeleted, setInvoiceDeleted] = useState(false);
    const [transactionPaid, setTransactionPaid] = useState(false);

    const [razorPayRequestResponce, setRazorPayRequestResponce] = useState(false);
    const [gatewayState, setGatewayState] = useState(false);

    const [onepayRequestResponce, setOnePayRequestResponce] = useState(false);
    const [policyUrl, setPloicyURL] = useState(undefined);

    const [grayQuestResponce, setGrayQuestResponce] = useState(undefined);
    const [loadScriptStatus, setLoadScriptStatus] = useState(false);

    useEffect(() => {
    
        

        Parse.Cloud.run("function_getTransactionForId", {
            transactionId: transactionId,
        }).then((response) => {
            console.log("response", response)
            setTransactionPaid(response.attributes.deleted ? false : true);
            setTransactionObject(response)

            let mode = response && response.attributes.transactionType;
            if (mode && mode.toLowerCase() == 'razorpay') {
                loadscript('https://checkout.razorpay.com/v1/checkout.js')
            }
            props.getInvoiceDetailsByFunction(invoiceId);
    
        }, (error) => {
            console.log("error" + error.message)
        });
    
    }, [transactionId]);
    useEffect(() => {
        let invoiceBalanceTemp = 0;
        if (props.invoice) {
           
            
            if (props.invoice.Invoice) {
                let temp = {};
                temp.id = props.invoice.Invoice.id;
                temp.obj = props.invoice.Invoice;
                temp.isDaycareInvoice = props.invoice.Invoice.attributes.isDaycareInvoice;
                temp.invoiceNumber = props.invoice.Invoice.attributes
                    ? props.invoice.Invoice.attributes.invoiceNumber
                    : "--";
                temp.paidStatus = props.invoice.Invoice.attributes.paidStatus

                setInvoiceDeleted(props.invoice.Invoice.attributes.isDeleted)
                setInvoiceDetailsObject(temp);
            }
            //kid details
            if (props.invoice.Kid) {
                let temp = {};
                temp.id = props.invoice.Kid.id;
                temp.obj = props.invoice.Kid;
                temp.name = props.invoice.Kid.attributes.Name;
                temp.admissionNumber = props.invoice.Kid.attributes.admissionNumber;
                temp.email = props.invoice.Kid.attributes.email;
                temp.phoneNumber = props.invoice.Kid.attributes.phoneNumber;
                setKidObject(temp);
            }
            //school details
            if (props.invoice.School) {
                let temp = {};
                temp.id = props.invoice.School.id;
                temp.instituteId = props.invoice.School.attributes.instituteId;
                temp.obj = props.invoice.School;
                temp.name = props.invoice.School.attributes.Name;
                temp.address = props.invoice.School.attributes.Address;
                temp.primaryPhoneNumber =
                    props.invoice.School.attributes.primaryPhoneNumber;
                temp.secondaryPhoneNumber =
                    props.invoice.School.attributes.secondaryPhoneNumber;
                temp.email = props.invoice.School.attributes.EMail;
                temp.logoUrl = props.invoice.School.attributes.logo
                    ? props.invoice.School.attributes.logo._url
                    : "";
                temp.cin = props.invoice.School.attributes.cin;
                temp.gstin = props.invoice.School.attributes.GSTIN;
                temp.pan = props.invoice.School.attributes.pan;
                temp.countryCode = props.invoice.School.attributes.countryCode;
                temp.invoiceFooter = props.invoice.School.attributes.invoiceFooter;
                temp.trustName = props.invoice.School.attributes.trustName;

                temp.countryCode && getCurrencyForSchool(temp.countryCode);

                
                


                if (props.invoice.ReceiptAddress) {


                    temp.name = props.invoice.ReceiptAddress.attributes.name;
                    temp.address = props.invoice.ReceiptAddress.attributes.address;
                    temp.primaryPhoneNumber =
                        props.invoice.ReceiptAddress.attributes.phoneNumber;
                    temp.secondaryPhoneNumber = "";
                    temp.email = props.invoice.ReceiptAddress.attributes.email;
                    temp.logoUrl = props.invoice.ReceiptAddress.attributes.logo
                        ? props.invoice.ReceiptAddress.attributes.logo._url
                        : temp.logoUrl;
                    temp.cin = props.invoice.ReceiptAddress.attributes.CIN;
                    temp.gstin = props.invoice.ReceiptAddress.attributes.GSTIN;
                    temp.pan = props.invoice.ReceiptAddress.attributes.PAN;
                    temp.invoiceFooter = props.invoice.ReceiptAddress.attributes.invoiceFooter;
                    temp.trustName = undefined;

                    
                    setSchoolObject(temp);
                } else {
                    setSchoolObject(temp);
                }
            }

            setPloicyURL(props.invoice.policyURL)

            if (props.invoice.isGatewayEnabled) {
                setPayentMode("1")
            } else {
                setPayentMode("3")
            }
           
            //parents details
            if (props.invoice.Parents) {
                let temp = [];
                for (const val of props.invoice.Parents) {
                    let tempIn = {};
                    tempIn.id = val.id;
                    tempIn.obj = val;
                    tempIn.name = val.attributes.Name;
                    tempIn.relation = val.attributes.Relation;
                    tempIn.EMail = val.attributes.EMail;
                    tempIn.PhoneNumber = val.attributes.PhoneNumber;
                    temp.push(tempIn);
                }
                setParentObject(temp);
            }
            
           
            
            setinforLoading(false)
        }
    }, [props.invoice]);

    const getCurrencyForSchool = (countryCode) => {
        if (countryCode != "IN") {
            for (const cc of COUNTRY_JSON) {
                if (cc.code == countryCode) {
                    setCurrency(cc.currency.currencySymbol);
                    break;
                }
            }
        }
    };  
    
    const getPaymentObject = () => {
        let buyerEmail = "";
        let buyerPhoneNumber = "";
        let buyerFirstName = props.invoice.Kid.attributes.Name;
        let buyerLastName = " ";

        if (parents && parents.length > 0) {
            if (parents[0].EMail) {
                buyerEmail = parents[0].EMail
            } else if (parents.length > 1 && parents[1].EMail) {
                buyerEmail = parents[1].EMail
            } else {
                buyerEmail = '';
            }

            if (parents[0].PhoneNumber) {
                buyerPhoneNumber = parents[0].PhoneNumber
            } else if (parents.length > 1 && parents[1].PhoneNumber) {
                buyerPhoneNumber = parents[1].PhoneNumber
            } else {
                buyerPhoneNumber = '';
            }
        }

        if (transactionObject.attributes.userId && parents && parents.length > 0) {
            for (parent of parents) {
                if (parent.id == transactionObject.attributes.userId) {
                    if (parent.EMail) {
                        buyerEmail = parent.EMail;
                    }
                    if (parent.PhoneNumber) {
                        buyerPhoneNumber = parent.PhoneNumber;
                    }
                }
            }
        }
        
        
        let body = {
            buyerFirstName: buyerFirstName,
            buyerLastName: buyerLastName,
            buyerAddress: "bangalore",
            buyerCity: "bangalore",
            buyerState: "Karnataka",
            buyerCountry: "IN",
            buyerEmail: buyerEmail,
            buyerPhoneNumber:buyerPhoneNumber,
            amount: transactionObject.attributes.amount,
            orderid: transactionObject.id,
            popId: props.invoice.Invoice.attributes.paymentOptionId ?? props.invoice.Invoice.attributes.schoolId,
            popAttribut: props.invoice.Invoice.attributes.paymentOptionId ? "objectId" : "schoolId",
            royaltyAmount: transactionObject.attributes.royaltyAmount,
            schoolAmount: transactionObject.attributes.schoolAmount,
            invoiceId: transactionObject.attributes.invoiceId,
            kidId: kid.id,
        }

        return body;
        
    }

    const handelRazorPay = () => {
        loadscript('https://checkout.razorpay.com/v1/checkout.js')
        
        let body = getPaymentObject()
        console.log(body)
        Parse.Cloud.run("function_getRazorpayOrder", {
            body: body,
      
        }).then((response) => {
            console.log("response", response)
            if (response && response.transaction) {
                setTransactionPaid(response.transaction.attributes.deleted ? false : true);
                setTransactionObject(response.transaction);
            } else {
                // setGatewayState(false);
                setRazorPayRequestResponce(response)
                openRazorPayWindow(response)
            }
            
        }, (error) => {
            console.log("error" + error.message)
        });
    }

    const loadscript = (src, isGQ) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src
            if (isGQ) {
                script.async = true;
                document.body.appendChild(script);
            }

            script.onload = () => {
                console.log("script.onload ")
                setLoadScriptStatus(true)
                resolve(true);
            }
            script.onerror = () => {
                resolve(false);
            }

            document.body.appendChild(script)
        })
    }
  
    const openRazorPayWindow = (sResponse) => {

        let buyerEmail = ""
        let buyerPhoneNumber = ""

        if (parents && parents.length > 0) {
            buyerEmail = parents[0].obj.EMail ?? (parents.length > 1 ? parents[1].obj.EMail : "")
            buyerPhoneNumber = parents[0].PhoneNumber ?? (parents.length > 1 ? parents[1].PhoneNumber : "")
        }
        if (transactionObject.attributes.userId && parents && parents.length > 0) {
            for (parent of parents) {
                if (parent.id == transactionObject.attributes.userId) {
                    if (parent.EMail) {
                        buyerEmail = parent.EMail;
                    }
                    if (parent.PhoneNumber) {
                        buyerPhoneNumber = parent.PhoneNumber;
                    }
                }
            }
        } 

        let key = sResponse.username
        let response = JSON.parse(sResponse.response)
        var options = {
            "key": key,
            "amount": transactionObject.attributes.amount * 100,
            "currency": "INR",
            "name": school.name,
            "description": "School Fee",
            "image": school.logoUrl,
            "order_id": response.id,
            "callback_url": "https://dashboard.liltriangle.com/transcation-success/" + transactionObject.id,
            "prefill": {
                "name": props.invoice.Kid.attributes.Name,
                "email": buyerEmail,
                "contact": buyerPhoneNumber,
            },
            "notes": {
                "address": "Bengaluru"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    //*******************Onepay********************//

    const handelOnepayRequest = () => {

        let buyerEmail = ""
        let buyerPhoneNumber = ""

        if (parents && parents.length > 0) {
            if (parents[0].EMail) {
                buyerEmail = parents[0].EMail
            } else if (parents.length > 1 && parents[1].EMail) {
                buyerEmail = parents[1].EMail
            } else {
                buyerEmail = '';
            }

            if (parents[0].PhoneNumber) {
                buyerPhoneNumber = parents[0].PhoneNumber
            } else if (parents.length > 1 && parents[1].PhoneNumber) {
                buyerPhoneNumber = parents[1].PhoneNumber
            } else {
                buyerPhoneNumber = '';
            }
        } else {
          buyerEmail = '';
          buyerPhoneNumber = '';
        }

        const removedPart = (buyerPhoneNumber && buyerPhoneNumber.slice(3)) ?? "";
        handelOnePay({
            dateTime: Moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
            custMobile: removedPart.length == 10 ? removedPart : undefined,
            custMail: buyerEmail.length > 7 ? buyerEmail : undefined,
            amount: (transactionObject.attributes.amount).toFixed(2) + "",
            orderid: transactionObject.id,
            popId: props.invoice.Invoice.attributes.paymentOptionId ?? props.invoice.Invoice.attributes.schoolId,
            popAttribut: props.invoice.Invoice.attributes.paymentOptionId ? "objectId" : "schoolId",
            royaltyAmount: transactionObject.attributes.royaltyAmount,
            schoolAmount: transactionObject.attributes.schoolAmount,
            invoiceId: transactionObject.attributes.invoiceId,
            envType: "PROD",
            kidName:removeSpecialCharacters(kid.name??"no name"),
        });
    }

    const handelOnePay = (body) => {
        Parse.Cloud.run("function_getOnepayOrder", {
            body: body,
      
        }).then((response) => {
            console.log("response", response)
      
            // setGatewayState(false);
            setOnePayRequestResponce(response);
        }, (error) => {
            console.log("error" + error.message)
        });
    }
    const removeSpecialCharacters = (str) => {
        return str.replace(/[^\w\s]/gi, '');  
    }

    useEffect(() => {
        if (onepayRequestResponce) {
            setTimeout(() => {
                const pfForm = document.getElementById("payment-form");
                pfForm.submit();
            }, 1000);
        }
    }, [onepayRequestResponce]);
    

    //*******************GQ********************//
    
    const handelGQ = () =>{

        let body = getPaymentObject()
    
        Parse.Cloud.run("function_getGrayquestOrder", {
          body: body,
        }).then((response) => {
          console.log("response Grayquest", response)
          setGrayQuestResponce(response)
          if (response) {
            loadscript(response.server_url, true)
          }
        }, (error) => {
          console.log("error" + error.message)
        });
        
      }
    
      useEffect(() => {
        if (loadScriptStatus && grayQuestResponce) {
          openGQWindow(grayQuestResponce)
        }
      }, [loadScriptStatus, grayQuestResponce]);
    
      const openGQWindow = (sResponse) => {
    
        if (window.GqErpSDK) {
          window.GqErpSDK.setOptions({
            env: sResponse.env??"staging",
            auth: {
              client_id: sResponse.client_id??"GQ-b21130cf-3eed-4c73-a72c-1afad7c308f7",
              client_secret: sResponse.client_secret??"a1b55c04-cb8e-4f2a-a2a8-09f56248c07d",
              api_key: sResponse.api_key??"34d8ca1e-251a-4916-ae9e-7a10bcff9255",
            },
            student_id:sResponse.kidId,
            customer_mobile: sResponse.buyerPhone&& sResponse.buyerPhone.replace("+91", "").trim(),
            reference_id:sResponse.orderid,
            pp_config: {
              slug: sResponse.slug??"lts-mumbai-prep",
            },
            fee_headers: {
              current_payable: sResponse.amount,
            },
            notes: {
              mtId: sResponse.orderId,
              invoiceId: sResponse.invoiceId,
            },
            theme_color: "#556ee6",
            logo_url:props.invoiceDetails.School.attributes.logo && props.invoiceDetails.School.attributes.logo._url
          });
    
          window.GqErpSDK.render();
    
          
    
        document.addEventListener("onGqPopupClose", handlePopupClose);
        return () => {
          document.removeEventListener("onGqPopupClose", handlePopupClose);
        };
    
        } else {
          console.error("GqErpSDK is not available yet.");
        }
      }
    
      const handlePopupClose = (e) => {
        console.log("GQ Popup closed", e);
        window.location.reload();
      };

    const getUI = () => {
        return (<div>
            {
                (inforLoading) ? <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading....</Label>
                    </div>

                </Col> :
                    
                        
                    <Card>
                        <CardBody>
                            
                            <div className="text-center ">
                                {school && school.logoUrl && (
                                    <img
                                        src={school.logoUrl}
                                        width="100"
                                        alt=""
                                        className=""
                                    />
                                )}

                  
                                <h3>{school.name} </h3>
                  
                  
                            </div>
                            <hr />
                            
                            <div className="text-center py-2 mt-3">
                                <h3 className="font-size-15 font-weight-bold">
                                    {transactionObject&&<strong>{props.t(" Transaction Amount")}: {currency + transactionObject.attributes.amount}</strong>}
                                </h3>
                            </div>
                                 
                                    
                            <div >
                                <div className="text-center">
                     
                                    {(paymentMode == "1" || paymentMode == "3") &&  !gatewayState && (
                                        <Label
                                            onClick={() => {
                                                if (paymentMode === "3") {
                                                    setShowNoPG(true)
                                                } else {
                                                    setGatewayState(true)
                                                    let mode = transactionObject && transactionObject.attributes.transactionType;
                                                    if (mode && mode.toLowerCase() == 'razorpay') {
                                                        handelRazorPay();
                                                        return
                                                    }
                                                    if (mode && mode.toLowerCase() == 'onepay') {
                                                        handelOnepayRequest();
                                                        return
                                                    }
                                                    if (mode && mode.toLowerCase() == 'grayquest') {
                                                        handelGQ();
                                                        return
                                                    }
                                                    setShowNoPG(true)

                                                }

                                            }}
                                            className="btn btn-primary w-md waves-effect waves-light"
                                        >
                                            Pay Online
                                        </Label>
                                    )}
                                    {(paymentMode == "1" || paymentMode == "3") && gatewayState && (
                                        <button type="button" className="btn btn-primary  waves-effect waves-light  m-2">
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Preparing For Payment ')}
                                        </button>
                                    )}


                                    {policyUrl &&
                                        <div>
                                            <a href={policyUrl}  className="m-8 text-primary"><u>Terms & Conditions</u></a>
                                        </div>
                                    }
                                </div>
                            </div>
                        </CardBody>
                    </Card>
            }
        </div>)
    }

    return (
        <React.Fragment>

            {onepayRequestResponce && <div className="App row align-items-center justify-content-center full-height">
                <div className="col-md-4">
                    <form method="POST" className="d-none" id="payment-form" action={onepayRequestResponce?.postUrl}>
                        <div><input defaultValue={onepayRequestResponce?.merchantId} type="text" {...register("merchantId", { required: true })} /></div>
                        <div><input defaultValue={onepayRequestResponce?.reqData} type="text" {...register("reqData", { required: true })} /></div>
          
                        <input type="submit" />
                    </form>

                    <div className="text-center">
                        <h4>Please wait...</h4>
                        <div>Do not refresh or press any</div>
                    </div>
                </div>
            </div>}
            

            {(invoiceDeleted || transactionPaid) ? <div className="account-pages my-5 pt-5">
                <Container>
                    <Row>
                        <Col lg="12">
                            {invoiceDeleted && <div className="text-center mb-5">
                                <h1 className="display-2 font-weight-medium">4<i className="bx bx-buoy bx-spin text-primary display-3"></i>4</h1>
                                <h4 className="text-uppercase">Invoice no longer exists</h4>

                            </div>}
                            {transactionPaid && <div className="text-center mb-5">
                                {/* <h1 className="display-2 font-weight-medium">4<i className="bx bx-buoy bx-spin text-primary display-3"></i>4</h1> */}
                                <h4 className="text-uppercase"> This link no loger exists, Plase click below to  </h4>
                                <Link
                                    className='btn btn-primary'
                                    to={"/invoice/" + invoiceId}
                                >
                                    Go to recipt
                                </Link>

                            </div>}
                        </Col>
                    </Row>

                </Container>
            </div> :
                <div className= "account-pages pt-4">
                     <Container >{getUI()}</Container> 
                </div>}


            {/* {School is not accepting payments through online, please reach out to school to accept payments via online} */}
            {showNoPG && <SweetAlert
                title="Voila...!"
                danger

                confirmBtnBsStyle="danger"
                onConfirm={() => {
                    setShowNoPG(false);
                }}

            >
                {props.t(" Reach out to school to accept payments via online")}
            </SweetAlert>}
        </React.Fragment>
    );
};
const mapStatetoProps = (state) => {
  if (state.Invoice.invoice.Invoice) {
    const { invoice } = state.Invoice;
    return { invoice };
  }
};
export default connect(mapStatetoProps, { getInvoiceDetailsByFunction })(
  withRouter(withNamespaces()(OnlineTransaction))
);
