import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import Parse from "parse";

import * as InventoryItemHelper from "../../../../helpers/ParseHelpers/Inventory-managment/inventoryItem_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../../Constents';
import * as InventoryItemActions from "./actions";

function* getAInventoryItem({ payload: { id } }) {
  try {
    const response = yield call(InventoryItemHelper.getInventoryItemFromParse, id, false);
    yield put(InventoryItemActions.updateInventoryItem(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* deleteInventoryItem({ payload: { inventoryItem } }) {
  try {
    yield put(InventoryItemActions.updateDeleteInventoryItem(inventoryItem));
    const response = yield call(
      InventoryItemHelper.deleteInventoryItemFromParse,inventoryItem
    );
    if (response === "error") {
      inventoryItem.set("isDeleted", undefined);
      yield put(InventoryItemActions.updateDeleteInventoryItem(inventoryItem));
    } else
    if (response) {
      toastr.success("Item successfully removed", { timeOut: 3000 });
      response.unPin();
    }else{

    }

  } catch (error) {
   
  }
}

function* setSelectedInventoryItem({ payload: { inventoryItemId } }) {
  try {
    const response = yield call(InventoryItemHelper.getInventoryItemFromParse, inventoryItemId, true);
    yield put(InventoryItemActions.updateSelectedInventoryItem(response));
    if (response) {
      response.pin();
    } else {
      const sResponse = yield call(InventoryItemHelper.getInventoryItemFromParse, inventoryItemId, false);
      if (sResponse) {
        response.pin();
        yield put(InventoryItemActions.updateSelectedInventoryItem(sResponse));
      }

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getInventoryItemForownerId({ payload: { ownerId, ownerType, showInStore, categorySetting,schoolId } }) {
  try {
    var key =schoolId?Constants.INVENTORY_ITEM_UPDATED_TIME_FOR_OWNER_ID + "_" + ownerId+"_"+schoolId : Constants.INVENTORY_ITEM_UPDATED_TIME_FOR_OWNER_ID + "_" + ownerId;

    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(InventoryItemHelper.getInventoryItemForOwnerId, ownerId, ownerType, showInStore, categorySetting, null, true);
    yield put(InventoryItemActions.updateInventoryItems(localResponse));

    const serverResponse = yield call(InventoryItemHelper.getInventoryItemForOwnerId, ownerId, ownerType, showInStore, categorySetting, updatedTime, false);
    localStorage.setItem(key, newTime)

    if (serverResponse && serverResponse.length > 0) {
      yield put(InventoryItemActions.updateInventoryItems(serverResponse))
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

// function* getInventoryItemForOwnerIds({ payload: { ownerIds } }) {
//   try {
//     const localResponse = yield call(InventoryItemHelper.getInventoryItemForOwnerIds, ownerIds, true);
//     yield put(InventoryItemActions.updateInventoryItems(localResponse, true));

//     const serverResponse = yield call(InventoryItemHelper.getInventoryItemForOwnerIds, ownerIds, false);

//     yield put(InventoryItemActions.setInventoryItemInitialLoading(false));
//     if (serverResponse) {
//       yield put(InventoryItemActions.updateInventoryItems(serverResponse, true));


//       var nonDeleted = serverResponse.filter(
//         (enquiryquestion) =>
//           enquiryquestion.attributes.isDeleted !== true
//       );

//       var deleted = serverResponse.filter(
//         (enquiryquestion) =>
//           enquiryquestion.attributes.isDeleted
//       );

//       if (nonDeleted) {
//         Parse.Object.pinAll(nonDeleted);
//       }

//       if (deleted) {
//         Parse.Object.unPinAll(deleted);
//       }

//     }
//   } catch (error) {
//     // yield put(apiError(error));

//   }
// }

function* setInitialLoading({ payload: { state } }) {
  try {
    yield put(InventoryItemActions.updateInventoryItemInitialLoading(state));
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* addNewInventoryItem({ payload: { inventoryItem, cycles, schoolId, history } }) {
  try {
    const response = yield call(InventoryItemHelper.saveCustomObjects, inventoryItem);
    if (response) {



    } else {

    }
  } catch (error) {
   
  }
}

function* getInventoryItemForItemIds({ payload: { itemIds } }) {
  try {
    yield put(InventoryItemActions.updateInventoryItemForItemIds([]));
    const response = yield call(InventoryItemHelper.getInventoryItemForItemIds, itemIds);
    if(response){
      yield put(InventoryItemActions.updateInventoryItemForItemIds(response));
    }
    
  } catch (error) {

  }
}

function* editInventoryItem({ payload: { inventoryItem } }) {
  try {
      const response = yield call(InventoryItemHelper.editInventoryItem, inventoryItem);
      if (response === "Error") {
          toastr.error("Please try again", { timeOut: 3000 });
      } else
          if (response) {
              yield put(InventoryItemActions.updateInventoryItems(response));
              response.pin();
              toastr.success("Inventory Item successfully updated", { timeOut: 3000 });
          } else {
          }
  } catch (error) {

  }
}
function* getInventoryItemMatrixForItemId({ payload: { itemId } }) {
  try {
    const response = yield call(InventoryItemHelper.getInventoryItemMatrixForItemId, itemId);
    if (response) {
      yield put(InventoryItemActions.updateInventoryItemMatrixForItemId(response))
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getInventoryItemMatrixForItemIds({ payload: { itemIds } }) {
  try {
    const response = yield call(InventoryItemHelper.getInventoryItemMatrixForItemIds, itemIds);
    if (response) {
      yield put(InventoryItemActions.updateInventoryItemMatrixForItemIds(response))
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getInventorySubItemForItemIds({ payload: { itemIds } }) {
  try {
    const response = yield call(InventoryItemHelper.getInventorySubItemForItemIds, itemIds);
    if (response) {
      yield put(InventoryItemActions.updateInventorySubItemForItemIds(response))
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getInventoryItemCategoryPriceForItemIds({ payload: { itemIds, categoryId } }) {
  try {
    const response = yield call(InventoryItemHelper.getInventoryItemCategoryPriceForItemIds, itemIds, categoryId);
    if (response) {
      yield put(InventoryItemActions.updateInventoryItemCategoryPriceForItemIds(response))
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetInventoryItem() {
  yield takeEvery(actionTypes.GET_INVENTORY_ITEM, getAInventoryItem);
}

export function* watchDeleteInventoryItem() {
  yield takeEvery(actionTypes.DELETE_INVENTORY_ITEM, deleteInventoryItem);
}

// export function* watchGetInventoryItemForownerIds() {
//   yield takeEvery(actionTypes.GET_INVENTORY_ITEM_FOR_OWNER_ID, getInventoryItemForownerIds);
// }
export function* watchGetInventoryItemForOwnerId() {
  yield takeEvery(actionTypes.GET_INVENTORY_ITEMS, getInventoryItemForownerId);
}

export function* watchSetInitialLoading() {
  yield takeEvery(actionTypes.SET_INVENTORY_ITEM_INITIAL_LOADING, setInitialLoading);
}

export function* watchAddNewInventoryItem() {
  yield takeEvery(actionTypes.ADD_NEW_INVENTORY_ITEM, addNewInventoryItem);
}

export function* watchSetSelectedInventoryItem() {
  yield takeEvery(actionTypes.SET_SELECTED_INVENTORY_ITEM, setSelectedInventoryItem);
}

export function* watchGetInventoryItemForItemIds() {
  yield takeEvery(actionTypes.GET_INVENTORY_ITEMS_FOR_ITEM_IDS, getInventoryItemForItemIds);
}

export function* watchEditInventoryItem(){
  yield takeEvery(actionTypes.EDIT_INVENTORY_ITEM, editInventoryItem);
}

export function* watchGetInventoryItemMatrixForItemId(){
  yield takeEvery(actionTypes.GET_INVENTORY_ITEM_MATRIX_FOR_ITEM_ID, getInventoryItemMatrixForItemId);
}

export function* watchGetInventoryItemMatrixForItemIds(){
  yield takeEvery(actionTypes.GET_INVENTORY_ITEM_MATRIX_FOR_ITEM_IDS, getInventoryItemMatrixForItemIds);
}

export function* watchGetInventorySubItemForItemIds(){
  yield takeEvery(actionTypes.GET_INVENTORY_SUB_ITEM_FOR_ITEM_IDS, getInventorySubItemForItemIds);
}

export function* watchGetInventoryItemCategoryPriceForItemIds(){
  yield takeEvery(actionTypes.GET_INVENTORY_ITEM_CATEGORY_PRICE_FOR_ITEM_IDS, getInventoryItemCategoryPriceForItemIds);
}

function* InventoryItemSaga() {
  yield all([
    fork(watchGetInventoryItem),
    fork(watchDeleteInventoryItem),
    //fork(watchGetInventoryItemForownerIds),
    fork(watchGetInventoryItemForOwnerId),
    fork(watchSetInitialLoading),
    fork(watchAddNewInventoryItem),
    fork(watchSetSelectedInventoryItem),
    fork(watchGetInventoryItemForItemIds),
    fork(watchEditInventoryItem),
    fork(watchGetInventoryItemMatrixForItemId),
    fork(watchGetInventoryItemMatrixForItemIds),
    fork(watchGetInventorySubItemForItemIds),
    fork(watchGetInventoryItemCategoryPriceForItemIds),
  ]);
}
export default InventoryItemSaga;
