import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Container, Media, Table, UncontrolledTooltip, Label, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Button, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Badge } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import TopBar from './Topbar';
import {
    getFranchiseTeachers,
    sendInvite,
    addFranchiseTeacher,
    responseForWebAccess,
    requestForWebAccess,
    requestForWebAccessRemoval,
    responseForWebAccessRemoval,
    editTeacher,
    deleteTeacher,
    getUserTeacher,
} from '../../store/actions';
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import * as Constants from "../../Constents";
import SweetAlert from "react-bootstrap-sweetalert";
import Parse from "parse";
import toastr from 'toastr';
import SchoolPicker from '../Messaging/SchoolPicker';

const FranchiseAdmins = (props) => {

    useEffect(() => {
        props.getFranchiseTeachers();
    }, []);

    const [teacherList, setTeacherList] = useState([]);
    const [error, setError] = useState(props.t('No Admin found'));
    useEffect(() => {
        var tempList = [...props.franchiseTeachers];
        setTeacherList(tempList);
    }, [props.franchiseTeachers]);

    const [adminName, setAdminName] = useState(undefined);
    const [adminEmail, setAdminEmail] = useState(undefined);
    const [adminPhoneNumber, setAdminPhoneNumber] = useState(undefined);

    const [toggleId, setToggleId] = useState(undefined);
    const [selectedAdmin, setSelectedAdmin] = useState(undefined);
    const [showAddAdmin, setShowAddAdmin] = useState(false);
    const [showWebAccessRemovalAlert, setShowWebAccessRemovalAlert] = useState(false);
    const [showWebAccessRequestPopup, setShowWebAccessRequestPopup] = useState(false);
    const [currentItemForWebRequest, setCurrentItemForWebRequest] = useState(undefined);
    const [showFillEmailMessage, setShowFillEmailMessage] = useState(false);
    const [webResponse, setWebResponse] = useState(undefined);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [loadingState, setLoadingState] = useState(0);
    const [webAccesRequest, setWebAccesRequest] = useState(false);
    const [isSupervisor, setIsSupervisor] = useState(false);

    const [showSchoolPickerModal, setShowSchoolPickerModal] = useState(false);

    useEffect(() => {
        if (props.webAccessResponse) {
            setWebAccesRequest(false);
            setWebResponse(props.webAccessResponse)
        }
    }, [props.webAccessResponse])
    const handelSearch = (search) => {
        var tempList = [...props.franchiseTeachers];
        if (search === undefined || search === null || search.length === 0) {
            setTeacherList(tempList);
        } else if (tempList) {
            var filter = tempList.filter(function (item) {
                return (item.attributes.Name.toLowerCase()).includes(search.toLowerCase());
            });
            if (!filter || filter.length == 0) {
                setError(props.t('No Admin found for the search'));
            }
            setTeacherList(filter);
        }
    }
    function getDesignation(teacher) {
        if (teacher.designation) {
            return teacher.designation;
        } else if (teacher.Role === 3) {
            return props.t('Director');
        } else if (teacher.Role === 2) {
            return props.t('Center Head');
        } else {
            return props.t('Admin');
        }
    }

    const handelSendInvite = (teacherId) => {
        var schoolName = "";
        if (props.selectedSchool) {
            schoolName = props.selectedSchool.attributes.Name;
        }
        props.sendInvite("", schoolName, props.t('Invite sent successfully!'), teacherId);
    }

    useEffect(() => {
        if (props.userTeacher) {
            if (props.userTeacher.attributes.Role == Constants.FRANCHISE_STAFF_SUPERVISOR) {
                setIsSupervisor(true);
            }
        } else {
            if (Parse.User.current().attributes.franchiseId && Parse.User.current().attributes.teacherId) {
                props.getUserTeacher(Parse.User.current().attributes.teacherId)
            }
        }
    }, [props.userTeacher]);

    const handleWebRequest = () => {
        props.requestForWebAccess(
            selectedAdmin.attributes.EMail,
            selectedAdmin.attributes.Role,
            selectedAdmin.id,
            selectedAdmin.attributes.franchiseId,
            Constants.FRANCHISE_LEVEL,
            selectedAdmin,

        );
    }
    const handelAddAdmin = () => {
        setAdminEmail(undefined);
        setAdminName(undefined);
        setAdminPhoneNumber(undefined);
        setSelectedAdmin(undefined);
        setShowAddAdmin(true);
    }

    const handelAddTeacher = (adminName, phoneNumber, adminEmail, teacher) => {
        var teacherNew;


        let adminPhoneNumber = phoneNumber;
        let countryPhoneCode = "91";
        if (adminPhoneNumber && adminPhoneNumber.length > 0) {
            if (adminPhoneNumber.indexOf('+') == 0) {
            } else if (adminPhoneNumber.indexOf('0') == 0) {
                adminPhoneNumber =
                    '+' + adminPhoneNumber + adminPhoneNumber.substring(1);
            } else if (adminPhoneNumber.indexOf('+' + countryPhoneCode) == -1) {
                adminPhoneNumber = '+' + countryPhoneCode + adminPhoneNumber;
            } else if (adminPhoneNumber.indexOf(countryPhoneCode) == 0) {
                adminPhoneNumber = '+' + adminPhoneNumber;
            }

        }
        if (teacher) {

            var object = teacher;
            object.set("Name", adminName);
            object.set("EMail", adminEmail);
            object.set("PhoneNumber", adminPhoneNumber);



            teacherNew = object;

        } else {
            const Teacher = Parse.Object.extend("Teacher");
            teacherNew = new Teacher();
            teacherNew.set("Name", adminName);
            teacherNew.set("EMail", adminEmail);
            teacherNew.set("PhoneNumber", adminPhoneNumber);
            teacherNew.set("Role", 11);
            teacherNew.set("franchiseId", Parse.User.current().attributes.franchiseId);



        }

        setLoadingState(1);
        teacherNew.save().then(
            (result) => {
                result.pin();
                if (result.attributes.createdAt === result.attributes.updatedAt) {
                    setLoadingState(2);
                    setSelectedAdmin(result);
                } else {
                    setLoadingState(0);
                    setShowAddAdmin(false);
                }
                props.addFranchiseTeacher(result);
            },
            (error) => {

            });
    }

    const handelDeleteTeacher = () => {
        let admin = selectedAdmin;
        if (admin.attributes.isWebAccess) {
            handleWebAccessRemoval()
        }
        admin.set("deleted", true);
        if (Parse.User.current().attributes.teacherId) {
            admin.set("deletedBy", Parse.User.current().attributes.teacherId);
        } else {
            admin.set("deletedBy", Parse.User.current().id);
        }
        setShowDeleteAlert(false);
        props.addFranchiseTeacher(admin);
        admin.save().then(
            (result) => {
                result.unPin();
                toastr.success(selectedAdmin.attributes.Name + " deleted successfully", { timeOut: 2000 });
                props.addFranchiseTeacher(result);
            },
            (error) => {

            });
    }

    const handleWebAccessRemoval = () => {
        props.responseForWebAccessRemoval(undefined);
        props.requestForWebAccessRemoval(
            selectedAdmin.attributes.EMail,
            selectedAdmin.attributes.Role,
            selectedAdmin.id,
            selectedAdmin.attributes.franchiseId,
            Constants.FRANCHISE_LEVEL,
            selectedAdmin
        );

        setShowWebAccessRemovalAlert(false);
    }

    useEffect(() => {
        if (props.webAccessRemovalResponse) {
            if (props.webAccessRemovalResponse) {
                toastr.success("Access disabled successfully", { timeOut: 2000 });
            } else {
                toastr.warning("Please try again", { timeOut: 2000 });
            }
            props.responseForWebAccessRemoval(undefined);
        }
    }, [props.webAccessRemovalResponse])


    const cabEdit = () => {
        if (isSupervisor) {
            return false
        }
        return Parse.User.current().attributes.franchiseId && Parse.User.current().attributes.isSuperUser;
    }

    function getDropdwn(teacher) {

        var newList = [
            "View Profile",
            "Edit",
            "Enable Web Access",
            "Delete",
        ];
        if (teacher.attributes.isWebAccess) {
            newList = [
                "View Profile",
                "Edit",
                "Disable Web Access",
                "Delete",
            ];
        }
        if (cabEdit() && props.userTeacher &&( !props.userTeacher.attributes.schools ||  props.userTeacher.attributes.schools.length==0)) {
            newList.push("Branch Access")
        }
        return (
            <Dropdown
                isOpen={toggleId === teacher.id}
                toggle={() => setToggleId(toggleId ? undefined : teacher.id)}
            >
                <DropdownToggle className="bg-white border-0">
                    <i className="bx bx-dots-horizontal-rounded text-dark font-size-20"></i>
                    {/* <UncontrolledTooltip placement="top" target={"message" + teacher.id}>
                    {props.t('Action')}
                </UncontrolledTooltip> */}


                </DropdownToggle>
                <DropdownMenu>
                    {newList.map((item, key) => (
                        <DropdownItem
                            key={key}
                            onClick={() => {
                                setSelectedAdmin(teacher)
                                if (key == 0) {
                                    props.history.push(`/franchise-adminprofile/${teacher.attributes.franchiseId}/${teacher.id}`)
                                }
                                if (key == 1) {
                                    setShowAddAdmin(true)
                                    setAdminEmail(teacher.attributes.EMail);
                                    setAdminName(teacher.attributes.Name);
                                    setAdminPhoneNumber(teacher.attributes.PhoneNumber);

                                } else if (key == 2) {
                                    console.log(webResponse);
                                    if (teacher.attributes.isWebAccess) {
                                        props.responseForWebAccessRemoval(undefined);
                                        setShowWebAccessRemovalAlert(true)
                                    } else {
                                        props.responseForWebAccess(undefined);
                                        console.log(teacher.attributes.EMail);
                                        if (teacher.attributes.EMail && teacher.attributes.EMail.length > 0) {
                                            setShowWebAccessRequestPopup(true);
                                        } else {
                                            setShowWebAccessRequestPopup(true);
                                            setShowFillEmailMessage(true)
                                        }

                                    }
                                } else if (key == 3) {
                                    setShowDeleteAlert(true)
                                }else if (key == 4) {
                                    setShowSchoolPickerModal(true)
                                }


                            }}
                        >
                            {item}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );

    }

    const handelSchollAccess=(teacher, schools)=>{
        if (schools.length == props.schools.length) {
            teacher.set("schools", []);
        } else {
            let list=[]
            for (const s of schools) {
                list.push(s.id);
            }
            teacher.set("schools", list);
        }
        setShowSchoolPickerModal(false);
        teacher.save().then(
            (result) => {
                result.pin();
                props.addFranchiseTeacher(result);
                toastr.success("Branches added successfully")
            },
            (error) => {
                toastr.warning("Please try again")
            });
    }

    const getSelectedSchools = () => {
        let list = [];
        if (selectedAdmin && props.schools && props.schools.length>0) {
            if (selectedAdmin.attributes.schools && selectedAdmin.attributes.schools.length>0) {
                for (const s of props.schools) {
                    if (selectedAdmin.attributes.schools.includes(s.id)) {
                        list.push(s);
                    }
                }
            } else {
                return props.schools
            }
        }
        return list
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <TopBar title="ADMINS" onSearch={handelSearch} onClickAdd={handelAddAdmin} addButton={cabEdit()} addButtonTitle={"+ Add Admin"} />

                    <Col lg={12} >
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    {
                                        teacherList && teacherList.length > 0 ?
                                            <Table className="table-centered table-nowrap table-hover">
                                                <thead className="thead-light" style={{ height: '0' }}>
                                                    <tr>
                                                        <th scope="col" style={{ width: "70px" }}>#</th>
                                                        <th scope="col">{props.t('Name')}</th>
                                                        <th scope="col">{props.t('Email/Phone')}</th>
                                                        {/* <th scope="col" style={{ maxWidth: "200px" }}>{props.t('Classrooms')}</th> */}
                                                        {cabEdit() && <th scope="col">{props.t('Action')}</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {

                                                        teacherList.map((teacher, i) =>
                                                            <tr key={teacher.id} >
                                                                <td>
                                                                    {
                                                                        !teacher.attributes.Photo
                                                                            ? <div className="avatar-xs">
                                                                                <span className="avatar-title rounded-circle">
                                                                                    {teacher.attributes.Name ? teacher.attributes.Name.toUpperCase().charAt(0) : " "}
                                                                                </span>
                                                                            </div>
                                                                            : <div>
                                                                                <img className="rounded-circle avatar-xs" src={teacher.attributes.Photo._url} alt="" />
                                                                            </div>
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <h5 className="font-size-14 mb-1">
                                                                        {/* <Link to={teacher.attributes.Role === 3? "/staffprofile/d/" + teacher.id : "/staffprofile/"+teacher.attributes.SchoolID+"/"+teacher.id}
                                                                    className="text-dark">{teacher.attributes.Name}
                                                                </Link> */}
                                                                        <Label className="text-dark">{teacher.attributes.Name}</Label>

                                                                        {
                                                                            teacher.attributes.registered ?
                                                                                <i className=" font-size-14 text-success bx bx-check-circle ml-2"
                                                                                    id={"installed" + teacher.id}>
                                                                                    {<UncontrolledTooltip placement="top" target={"installed" + teacher.id}>
                                                                                        {props.t('Using App')}
                                                                                    </UncontrolledTooltip>}
                                                                                </i> :
                                                                                <i className="font-size-14 text-warning bx bx-navigation ml-2"
                                                                                    id={"notinstalled" + teacher.id}
                                                                                    onClick={() => {
                                                                                        if (!isSupervisor) {
                                                                                            handelSendInvite(teacher.id);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <UncontrolledTooltip placement="top" target={"notinstalled" + teacher.id}>
                                                                                        {isSupervisor ? "Not Using App" : props.t('Not Using App, click to send invite!')}
                                                                                    </UncontrolledTooltip>

                                                                                </i>
                                                                        }
                                                                    </h5>
                                                                    <p className="text-muted mb-0">{getDesignation(teacher.attributes)}</p>
                                                                </td>
                                                                <td>
                                                                    {teacher.attributes.EMail && <p className="text-muted mb-0">{teacher.attributes.EMail}</p>}
                                                                    {teacher.attributes.PhoneNumber && <p className="text-muted mb-0">{teacher.attributes.PhoneNumber}</p>}


                                                                </td>

                                                                {cabEdit() && <td>

                                                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                                                        <li className="list-inline-item px-2">

                                                                            {getDropdwn(teacher)}

                                                                        </li>

                                                                    </ul>


                                                                </td>}


                                                                {/* <td>
                                                            <ul className="list-inline font-size-20 contact-links mb-0">
                                                                <li className="list-inline-item px-2">
                                                                    <Link to="#" id={"message" + teacher.id}>
                                                                        <i className="bx bx-message-square-dots"></i>
                                                                        <UncontrolledTooltip placement="top" target={"message" + teacher.id}>
                                                                            {props.t('Message')}
                                                                        </UncontrolledTooltip>
                                                                    </Link>
                                                                </li>
                                                                <li className="list-inline-item px-2">
                                                                    <Link
                                                                        to={teacher.attributes.Role === 3? "/staffprofile/d/" + teacher.id : "/staffprofile/"+teacher.attributes.SchoolID+"/"+teacher.id}
                                                                        id={"profile" + teacher.id}>
                                                                        <i className="bx bx-user-circle"></i>
                                                                        <UncontrolledTooltip placement="top" target={"profile" + teacher.id}>
                                                                            {props.t('Profile')}
                                                                        </UncontrolledTooltip>
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </td> */}
                                                            </tr>
                                                        )

                                                    }


                                                </tbody>
                                            </Table>
                                            :
                                            <Col lg={12} >
                                                <h5 className="text-center text-info mt-4">{error}</h5>
                                            </Col>
                                    }
                                </div>

                            </CardBody>
                        </Card>
                    </Col>


                </Container>
            </div>

            <Modal
                isOpen={showAddAdmin || props.franchiseTeacherAddStatus > 0}
                toggle={() => {
                    setShowAddAdmin(false);
                }}
            >

                <ModalHeader>
                    {props.t("Add Admin")}
                </ModalHeader>
                <ModalBody>

                    <AvForm className="needs-validation"
                        onValidSubmit={(e, v) => {
                            props.responseForWebAccess(undefined);
                            setAdminName(v.name);
                            setAdminPhoneNumber(v.phonenumber);
                            setAdminEmail(v.email);
                            handelAddTeacher(v.name, v.phonenumber, v.email, selectedAdmin);

                        }}
                    >


                        <FormGroup>
                            <Label htmlFor="validationCustom01">Name</Label>
                            <AvField
                                name="name"
                                value={adminName}
                                placeholder="First name"
                                type="text"
                                errorMessage="Enter First Name"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationCustom01"
                                disabled={loadingState === 2 ? true : false}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="validationCustom02">Phone Number</Label>
                            <AvField
                                name="phonenumber"
                                placeholder="Phone Number"
                                value={adminPhoneNumber}
                                errorMessage="Enter Phone Number"
                                className="form-control"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: props.t('Enter phone number'),
                                    },
                                    pattern: {
                                        value:
                                            "^([0|+[0-9]{1,5})?([1-9][0-9]{9,14})$",
                                        errorMessage: props.t("You have entered an invalid phone no!"),
                                    },
                                    minLength: { value: 10 },
                                    maxLength: { value: 16 },

                                }}
                                disabled={loadingState === 2 ? true : false}
                                id="validationCustom02"
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="validationCustom03">Email</Label>
                            <AvField
                                name="email"
                                placeholder="Email"
                                value={adminEmail}
                                type="email"
                                errorMessage=" Enter Email."
                                className="form-control"
                                disabled={(selectedAdmin && selectedAdmin.attributes.isWebAccess && selectedAdmin.attributes.EMail || loadingState === 2) ? true : false}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: props.t('Enter Email.'),
                                    },
                                    pattern: {
                                        value:
                                            "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                        errorMessage: props.t("You have entered an invalid email address!"),
                                    },
                                }}
                                id="validationCustom03"
                            />
                            {selectedAdmin && selectedAdmin.attributes.isWebAccess && selectedAdmin.attributes.EMail && <p>The email linked with web login, can not be edited </p>}
                        </FormGroup>





                        {loadingState === 1 ? <ModalFooter>

                            <button type="button" className="btn btn-light waves-effect">
                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                {props.t(" Saving...")}
                            </button>
                        </ModalFooter> :


                            <ModalFooter>

                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        setShowAddAdmin(false);
                                        setLoadingState(0);
                                    }}
                                >
                                    {props.t("Close")}

                                </button>

                                {
                                    loadingState === 2 ? <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setShowAddAdmin(false);
                                            if (selectedAdmin.attributes.isWebAccess) {
                                                setShowWebAccessRemovalAlert(true)
                                            } else {
                                                if (selectedAdmin.attributes.EMail && selectedAdmin.attributes.EMail.length > 0) {
                                                    setShowWebAccessRequestPopup(true);
                                                } else {
                                                    setShowWebAccessRequestPopup(true);
                                                    setShowFillEmailMessage(true)
                                                }

                                            }
                                        }}
                                    >
                                        {props.t("Enable Web Access")}

                                    </button> :
                                        <button
                                            className="btn btn-primary"
                                            type="submit"
                                        >
                                            {props.t('Save')}
                                        </button>
                                }




                            </ModalFooter>}

                    </AvForm>

                </ModalBody>

            </Modal>

            <Modal isOpen={showWebAccessRequestPopup && selectedAdmin}>


                <ModalHeader>

                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t('Request for Web Access')}
                    </h5>
                </ModalHeader>
                <ModalBody >





                    {selectedAdmin && !webResponse && !showFillEmailMessage && <p className=" m-4">{props.t('Are you sure you want to give Web Access for ') + selectedAdmin.attributes.Name}</p>}
                    {selectedAdmin && !webResponse && showFillEmailMessage && <p className=" m-4">{'Please update  email id for ' + selectedAdmin.attributes.Name + ' , then try again'}</p>}

                    {
                        webResponse != undefined && <div>
                            {webResponse.UserName && webResponse.UserName.length > 0 ?
                                <div>
                                    {
                                        webResponse.Password && webResponse.Password.length > 0 ?
                                            <div>
                                                <p className=" m-4">{props.t('User Name/Email')} - {webResponse.UserName}</p>
                                                <p className=" m-4">{props.t('Password')} - {webResponse.Password}</p>
                                                <p className="m-2">{'Please ask ' + selectedAdmin.attributes.Name + ' to verify email by clicking on link which is sent to ' + webResponse.UserName + ''}</p>
                                            </div> : <div>
                                                <p className=" m-4">{props.t('User Name/Email')} - {webResponse.UserName}</p>
                                                <p className=" m-4">{"Password has been created already, Please ask " + selectedAdmin.attributes.Name + " to use existing password or reset using web portal"}</p>
                                            </div>
                                    }

                                </div>
                                : <div>
                                    {selectedAdmin && <p className=" m-4">
                                        {"The email associated with " + selectedAdmin.attributes.Name + " (" + selectedAdmin.attributes.EMail + ") already taken. Please change email and try again"}
                                    </p>}
                                </div>}
                        </div>
                    }


                </ModalBody>

                <ModalFooter>
                    {!webAccesRequest ? <Row className="d-flex justify-content-end">
                        <Col className='d-flex justify-content-end'>
                            <button
                                type="button"
                                onClick={() => {

                                    setShowWebAccessRequestPopup(false)
                                    props.responseForWebAccess(undefined);
                                    setWebResponse(undefined);
                                }}
                                className="btn btn-secondary waves-effect m-1"
                                data-dismiss="modal"
                            >
                                {props.t('Close')}
                            </button>
                            {webResponse == undefined && !showFillEmailMessage && <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light m-1"
                                onClick={() => {
                                    setWebAccesRequest(true)
                                    handleWebRequest()
                                }}
                            >
                                {props.t('Accept')}
                            </button>}
                        </Col>
                    </Row> : <button
                        type="button" className="btn btn-light waves-effect">

                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Loading..')}

                    </button>

                    }


                </ModalFooter>
            </Modal>
            {showWebAccessRemovalAlert && <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Alert!"
                onCancel={() => {
                    setShowWebAccessRemovalAlert(false);

                }}
                onConfirm={() => handleWebAccessRemoval()}
            >
                {props.t('Do you want to disable web permissions for ')}

                <b> <br /> {selectedAdmin.attributes.Name}</b>
            </SweetAlert>}

            {showDeleteAlert && <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Alert!"
                onCancel={() => {
                    setShowDeleteAlert(false);

                }}
                onConfirm={() =>
                    handelDeleteTeacher()
                }
            >
                {props.t('Ary you sure do you want to delete  ')}

                <b> <br /> {selectedAdmin.attributes.Name}</b>
            </SweetAlert>}

            <Modal isOpen={showSchoolPickerModal} className={"modal-dialog modal-xl"} scrollable={true} backdrop={true}>
            <ModalHeader toggle={() => {
                    setSelectedAdmin(undefined);
                    setShowSchoolPickerModal(false);
            }}>{selectedAdmin ?"Select Branches for "+ selectedAdmin.attributes.Name+" to access": "Select Branches"}</ModalHeader>
            <ModalBody>
                <SchoolPicker
                    franchiseId={Parse.User.current().attributes.franchiseId}
                    selectedSchools={getSelectedSchools()}
                    hideSlectAllButton={true}
                    onButtonClick={(selectedSchools, allSchools) => {
                        if (selectedSchools && selectedSchools.length > 0) {
                            handelSchollAccess(selectedAdmin,selectedSchools)
                        } else {
                            // toastr.warning("Please select atleast one student to proceed")
                        }
                }}>
              </SchoolPicker>
            </ModalBody>
          </Modal>
        </React.Fragment>
    )
}
const mapStatetoProps = state => {
    const { franchiseTeachers, webAccessResponse, webAccessRemovalResponse, } = state.Teacher;
    const { userTeacher } = state.Login;
    const { schools, schoolAnalytics } = state.School;
    return {
        franchiseTeachers, webAccessResponse, webAccessRemovalResponse,
        userTeacher,
        schools, schoolAnalytics
    };
}
export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    getFranchiseTeachers,
    sendInvite,
    addFranchiseTeacher,
    responseForWebAccess,
    requestForWebAccess,
    requestForWebAccessRemoval,
    responseForWebAccessRemoval,
    editTeacher,
    deleteTeacher,
    getUserTeacher,
})(FranchiseAdmins)));