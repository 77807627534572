import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import School from "./model/school/reducer";
import Teacher from "./model/teacher/reducer";
import Parent from "./model/parent/reducer";
import Kid from "./model/kid/reducer";
import Classroom from "./model/classroom/reducer";
import Enquiry from "./model/enquiry/reducer";
import EnquiryNotes from "./model/enquirynotes/reducer";
import EnquiryFollowup from "./model/enquiryFollowup/reducer";
import EnquiryReminder from "./model/enquiryreminder/reducer";
import ChildAdditionalDetails from "./model/childAdditionalDetails/reducer";
import EnquiryQuestions from "./model/enquiryQuestions/reducer";
import Group from "./model/group/reducer";
import GroupMap from "./model/groupMap/reducer";
import Program from "./model/program/reducer";
import Reminder from "./model/reminder/reducer";
import AdmissionTitle from "./model/admissionTitle/reducer";
import AdmissionQuestion from "./model/admissionQuestion/reducer";
import InvoiceItems from "./model/invoiceItems/reducer";
import Camera from "./model/camera/reducer";
import MoneyTransactions from "./model/moneyTransactions/reducer";
import FeeTemplate from "./model/feeTemplate/reducer";
import Invoice from "./model/invoice/reducer";
import FeeTemplateItem from "./model/feeTemplateItem/reducer";
import MoneyTransactionDetails from "./model/moneyTransactionDetails/reducer";
import LearningCategory from "./model/learningCategory/reducer";
import LearningActivity from "./model/learningActivity/reducer";
import LearningFilters from "./model/learningFilters/reducer";
import Curriculum from "./model/curriculum/reducer";
import AcademicCycles from "./model/academicCycles/reducer";
import LearningSubcategory from "./model/learningSubcategory/reducer";
import Files from "./model/files/reducer";
import LeaveModule from "./model/leaveModule/reducer";
// import LeaveTemplatesItem from "./model/leaveTemplateItem/reducer"
import Notifaction from "./model/notification/reducer";
import MessageCredits from "./model/messageCredits/reducer";
import Franchise from "./model/franchise/reducer";
import Attendance from "./model/attendance/reducer";
import Exam from "./model/exam/reducer";
import EnquiryAnalytics from "./model/enquiryAnalytics/reducer";
import Course from "./model/course/reducer";
import CourseSubscription from "./model/courseSubscription/reducer";
import CourseRequests from "./model/courseRequests/reducer";
import Expenses from "./model/expense/reducer";
import ExpenseCategory from "./model/expenseCategory/reducer";
import ExpenseSupplier from "./model/expenseSupplier/reducer";
import QuizSession from "./model/quizSession/reducer";
import CourseBatch from "./model/courseBatch/reducer";
import QuizActivityBatchMap from "./model/quizActivityBatchMap/reducer";
import LiveMeeting from "./model/liveMeeting/reducer";
import Trips from "./model/trip/reducer";
import Message from "./model/messaging/reducer";
import CorporatePartners from "./model/corporatePartners/reducer";
import InventoryVendor from "./model/inventory/InventoryVendor/reducer";
import InventoryItems from "./model/inventory/InventoryItems/reducer";
import InventoryPurchaseOrder from "./model/inventory/InventoryPurchaseOrder/reducer";
import InventoryTag from "./model/inventory/InventoryTags/reducer";
import InventorySaleOrder from "./model/inventory/InventorySaleOrder/reducer";
import InventorySaleOrderItem from "./model/inventory/InventorySaleOrderItems/reducer";
import InventoryPurchaseOrderItem from "./model/inventory/InventoryPurchaseOrderItems/reducer";
import Ticket from "./model/tickets/reducer";
import InventoryWarehouse from "./model/inventory/InventoryWarehouse/reducer";
import InventoryKits from "./model/inventory/InventoryKits/reducer";
import InventoryKitItems from "./model/inventory/InventoryKitItemMap/reducer";
import LearningCurriculumsSchoolMap from "./model/learningCurriculumSchoolMap/reducer";
import LearningSession from "./model/learningSession/reducer";
import Examination from "./model/marksCard/examination/reducer";
import Subjects from "./model/marksCard/subjects/reducer";
import ExamResults from "./model/marksCard/result/reducer";
import InventoryCategories from "./model/inventory/InventoryCategory/reducer";

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  School,
  Teacher,
  Kid,
  Parent,
  Classroom,
  Enquiry,
  EnquiryNotes,
  EnquiryFollowup,
  EnquiryReminder,
  ChildAdditionalDetails,
  EnquiryQuestions,
  Group,
  GroupMap,
  Program,
  Reminder,
  AdmissionTitle,
  AdmissionQuestion,
  InvoiceItems,
  Camera,
  MoneyTransactions,
  FeeTemplate,
  FeeTemplateItem,
  Invoice,
  MoneyTransactionDetails,
  LearningCategory,
  LearningActivity,
  LearningFilters,
  Curriculum,
  AcademicCycles,
  LearningSubcategory,
  Files,
  LeaveModule,
  // LeaveTemplatesItem,
  Notifaction,
  MessageCredits,
  Franchise,
  Attendance,
  Exam,
  EnquiryAnalytics,
  Course,
  CourseSubscription,
  CourseRequests,
  Expenses,
  ExpenseCategory,
  ExpenseSupplier,
  QuizSession,
  CourseBatch,
  QuizActivityBatchMap,
  LiveMeeting,
  Trips,
  Message,
  CorporatePartners,
  InventoryVendor,
  InventoryItems,
  InventoryPurchaseOrder,
  InventoryTag,
  InventorySaleOrder,
  InventorySaleOrderItem,
  InventoryPurchaseOrderItem,
  Ticket,
  InventoryWarehouse,
  InventoryKits,
  InventoryKitItems,
  LearningCurriculumsSchoolMap,
  LearningSession,
  Examination,
  Subjects,
  ExamResults,
  InventoryCategories,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER" || action.type === "SWITCH_SCHOOL") {
    const { routing } = state;
    state = { routing };
  }

  return appReducer(state, action);
};

export default rootReducer;
