import React from 'react';
import Parse from 'parse';
import * as Constants from '../../Constents';

export const canEdit = (curriculum, userTeacher) => {
    let returnValue = false
    let ownerId = curriculum && curriculum.attributes.ownerId;
    let type = curriculum && curriculum.attributes.type;
    if (type && type.toLowerCase() === "school") {
        if (Parse.User.current().attributes.instituteId) {
            if (Parse.User.current().attributes.teacherId) {
                if (userTeacher && userTeacher.attributes.instituteId && userTeacher.attributes.instituteId.length > 0) {
                    returnValue = true
                } if (userTeacher && userTeacher.attributes.SchoolID === ownerId) {
                    returnValue = true
                }

            } else if (Parse.User.current().attributes.isSuperUser) {
                returnValue = true;
            }
        } else {
            returnValue = false;
        }
    } else if (type && type.toLowerCase() === "institute") {
        if (Parse.User.current().attributes.teacherId) {
            if (userTeacher && userTeacher.attributes.instituteId && userTeacher.attributes.instituteId.length > 0) {
                returnValue = true
            } else if (Parse.User.current().attributes.isSuperUser) {
                returnValue = true;
            }
        }
    } else if (type && type.toLowerCase() === "franchise") {
        if (Parse.User.current().attributes.franchiseId === ownerId) {
            returnValue = true;
            if (userTeacher && userTeacher.attributes.Role == Constants.FRANCHISE_STAFF_SUPERVISOR) {
                returnValue = false;
            }

        }

    }
    return returnValue
}

export const getQuesionDataType = (dataType, questionType, databaseFieldName, props) => {
    if (databaseFieldName === "kidGender") {
        return props.t('Accepts Male or Female');
    }

    if (questionType === Constants.QUESTION_EMAIL) {
        return props.t('Accepts Email');
    }

    if (questionType === Constants.QUESTION_PHONE_NUMBER) {
        return props.t('Accepts Phone Number');
    }

    if (questionType === Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST || questionType === Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST) {
        return props.t('Accepts Choice');
    }

    if (questionType === Constants.QESTION_YES_NO_WITH_COMMENT || questionType === Constants.QESTION_YES_NO_COMMENT_ON_NO
        || questionType === Constants.QESTION_YES_NO_COMMENT_ON_YES || questionType === Constants.QESTION_YES_NO_NO_COMMENTS) {
        return props.t('Accepts Yes or No')
    }

    if (dataType === "STRING") {
        return props.t('Accepts text')
    }

    if (dataType === "DATE") {
        return props.t('Accepts date')
    }

    if (dataType === "FILE") {
        if (questionType === Constants.QESTION_UPLOAD_IMAGE) {
            return props.t('Accepts photo') + " (jpg, png etc)"
        }
        return props.t('Accepts Document') + " (pdf, doc, ppt, jpg, png, xlsx..)"
    }
    return props.t('Accepts text')
}

export const Base64 = {
    // private property
    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

    // public method for encoding
    encode: function (input) {
        let output = "";
        let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        let i = 0;

        input = Base64._utf8_encode(input);

        while (i < input.length) {

            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output +
                Base64._keyStr.charAt(enc1) + Base64._keyStr.charAt(enc2) +
                Base64._keyStr.charAt(enc3) + Base64._keyStr.charAt(enc4);

        }

        return output;
    },

    // public method for decoding
    decode: function (input) {
        let output = "";
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let i = 0;

        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

        while (i < input.length) {

            enc1 = Base64._keyStr.indexOf(input.charAt(i++));
            enc2 = Base64._keyStr.indexOf(input.charAt(i++));
            enc3 = Base64._keyStr.indexOf(input.charAt(i++));
            enc4 = Base64._keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }

        }

        output = Base64._utf8_decode(output);

        return output;

    },

    // private method for UTF-8 encoding
    _utf8_encode: function (string) {
        string = string.replace(/\r\n/g, "\n");
        let utftext = "";

        for (let n = 0; n < string.length; n++) {

            let c = string.charCodeAt(n);

            if (c < 128) {
                utftext += String.fromCharCode(c);
            }
            else if ((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }

        return utftext;
    },

    // private method for UTF-8 decoding
    _utf8_decode: function (utftext) {
        let string = "";
        let i = 0;
        let c = c1 = c2 = 0;

        while (i < utftext.length) {

            c = utftext.charCodeAt(i);

            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            }
            else if ((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            }
            else {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }

        }
        return string;
    }
}

export const Offsymbol = props => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >

        </div>
    );
};

export const OnSymbol = props => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >

        </div>
    );
};

export const isFranchise = () => {
    return Parse.User.current().attributes.franchiseId &&
        (window.location.pathname.toLowerCase().startsWith("/franchise/") ||
            window.location.pathname.toLowerCase().startsWith("/all-branches") ||
            window.location.pathname.toLowerCase().startsWith("/profile") ||
            window.location.pathname.toLowerCase() === "/admins") ||
        window.location.pathname.toLowerCase().startsWith("/lms-curriculum/franchise") ||
        window.location.pathname.toLowerCase().startsWith("/lms-syllabus/franchise") ||
        window.location.pathname.toLowerCase().startsWith("/lms-subject/franchise") ||
        window.location.pathname.toLowerCase().startsWith("/lms-chapter/franchise") ||
        window.location.pathname.toLowerCase().startsWith("/curriculum-access/") ||
        window.location.pathname.toLowerCase().startsWith("/syllabus-access/") ||
        window.location.pathname.toLowerCase().startsWith("/learning-category-access/") ||
        window.location.pathname.toLowerCase().startsWith("/lms-chapter-content/franchise") ||
        window.location.pathname.toLowerCase().startsWith("/lms-chapter-content2/franchise") ||
        window.location.pathname.toLowerCase().startsWith("/learning-session/franchise") ||
        window.location.pathname.toLowerCase().startsWith("/learning-session-details/franchise") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-reports") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-monthly-reports-view") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-monthly-reports") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-custom-reports-view") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-consolidated-reports") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-settings") ||
        window.location.pathname.toLowerCase().startsWith("/message-franchise") ||
        window.location.pathname.toLowerCase().startsWith("/corporate-partners") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-school-documents") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-all-tickets") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-new-ticket") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-ticket-details") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-inventory-management") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-inventory-items") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-add-items") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-inventory-vendor") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-add-vendor") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-edit-inventory-vendor") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-edit-inventory-items") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-purchase-order") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-new-purchase") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-edit-purchase-order") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-sale-order") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-new-sale") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-edit-sale-order") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-print-purchase-order") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-print-sale-order") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-new-items-group") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-edit-inventory-items-group") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-inventory-item-group") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-inventory-report/") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-all-cards") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-add-card") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-blog") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-blog-list") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-add-blog") ||
        window.location.pathname.toLowerCase().startsWith("/application-subscription") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-enquiry") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-add-enquiry")||
        window.location.pathname.toLowerCase().startsWith("/franchiseprofile") ||
        window.location.pathname.toLowerCase().startsWith("/franchiseposter") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-addform") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-forms") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-formview") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-printformview") ||
        window.location.pathname.toLowerCase().startsWith("/franchise-inventory-categories")||
        window.location.pathname.toLowerCase().startsWith("/franchise-adminprofile")
    }

const getToolsHost = () => {
    return (window.location.host == "tools.liltriangle.com")
}

export const isToolsHost = () => {
    return getToolsHost();
}

export const isTools = () => {
    return getToolsHost() && (window.location.pathname.toLowerCase().startsWith("/tools-school-settings") ||
        window.location.pathname.toLowerCase().startsWith("/tools-schools-subscription") ||
        window.location.pathname.toLowerCase().startsWith("/tools-institute-view") ||
        window.location.pathname.toLowerCase().startsWith("/tools-franchise-view")||
        window.location.pathname.toLowerCase().startsWith("/tools-schools-analytics") ||
        window.location.pathname.toLowerCase().startsWith("/tools-fee-analytics") || 
        window.location.pathname.toLowerCase().startsWith("/tools-whatsapp-templates") ||
        window.location.pathname.toLowerCase().startsWith("/tools-school-settings-cameraDetails"))
}

export const isMessageQueue = () => {
    return (window.location.pathname.toLowerCase().startsWith("/message-franchise") ||
        window.location.pathname.toLowerCase().startsWith("/message-school") 
       )
}
