import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Table, CardImg, Button, Label } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import QRCode from 'qrcode'
import Parse from 'parse';
import * as WhiteLable from "../../components/Common/WhiteLable";
import logo from "../../assets/images/logo-dark.png";
import checkin from "../../assets/images/checkin.png";
import checkin2 from "../../assets/images/checkin2.png"
import SaveAsPdf from '../../components/Common/SaveAsPdf';


const CheckInPoster = (props) => {
  
  const match = matchPath(props.history.location.pathname, {
    path: "/checkinposter/:id",
    exact: true,
    strict: false,
  });
  const parameter = match.params.id;

  const [loading, setLoading] = useState(true);
  const [formQRCode, setFormQRCode] = useState(undefined);

  const [school, setSchool] = useState(undefined);

  const [schoolName, setSchoolName] = useState(undefined);
  const [schoolCity, setSchoolCity] = useState(undefined);
  const [schoolLogo, setSchoolLogo] = useState(undefined);

  const [posterType, setPosterType] = useState("a4");
  const [showSaveAsPdfModal, setShowSaveAsPdfModal] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [machineType, setMachineType] = useState('');
  const SCHOOL_ID = parameter;

  useEffect(() => {
    getSchool();
    formQr();
  }, [parameter]);

  function formQr() {
    QRCode.toDataURL(SCHOOL_ID, { errorCorrectionLevel: 'H' })
      .then(url => {
        setFormQRCode(url)
      })
      .catch(err => {
        console.error(err)
      })
  }
  function getSchool() {
    var School = Parse.Object.extend("School");
    var query = new Parse.Query(School);

    query.get(parameter).then(
      (result) => {
        setLoading(false);
        setSchoolName(result.attributes.Name);
        setSchoolCity(result.attributes.city);
        if (result.attributes.logo) {
          setSchoolLogo(result.attributes.logo._url);
        }
        setSchool(result)
      },
      (error) => {
        console.log(error)
      }
    );
  }
  const getButtonSelectedClass = (type) => {

    if (type == posterType) {
      return "btn btn-primary waves-effect waves-light m-2"
    } else {
      return "btn btn-light waves-effect waves-light border-1 m-2"
    }
  }
  const printPoster = () => {
    var css = "@page { size: A4;margin:0 0 0 0; }",
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";
    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
    setTimeout(() => {
      window.print();
    }, 300);
  }

  useEffect(() => {
    const savedValue = localStorage.getItem('dontShowAgain');
    if (savedValue === 'true') {
        setDontShowAgain(true);
    }

    const userAgent = navigator.userAgent.toLowerCase();
    
    if (userAgent.includes('mac')) {
        setMachineType('Mac');
    } else if (userAgent.includes('windows')) {
        setMachineType('Windows');
    } else {
        setMachineType('Unknown');
    }
  }, []);
  

  const handleNextClick = () => {
    setShowSaveAsPdfModal(false);
    setTimeout(() => {
        printPoster();
    }, 300);
  }
  return (
    <React.Fragment>
      <Container fluid="lg" className='p-0'>



        {loading ?
          <Col xs="12">
            <div className="text-center my-3">
              <Label to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Label>
            </div>
          </Col>

          :
          // <Row className="text-center" >
          //     <Col xs="12" className="d-print-none mt-4 mb-4">
          //         <div className="text-center">
          //             <Link to="#" onClick={() => { printPoster() } } className="btn btn-success waves-effect waves-light mr-2"><i className="fa fa-print">Print Poster</i></Link>
          //         </div>
          //     </Col>


          //     <Col xs="12" >
          //         <div width= "1000px" height= "1000px"className="justify-content-center text-center">
          //             <div  className="">
          //                 <center>
          //                     <div id="CheckInPoster" style={{width: "1000px", height: "1500px"}}>

          //                         <div style={{ height: "483px", backgroundColor: "#00ace5" }}>

          //                         </div>
          //                         <div style={{height: "483px",backgroundColor: "#00ace5"}}>
          //                         </div>
          //                         <div style={{backgroundColor: "#fff",marginTop: "-128%",width: "90%",height:" 90%"}}>
          //                             <div className="row">
          //                                 <div className="col-md-12">
          //                                     <table>
          //                                         {schoolLogo ? <tr>
          //                                             <td style={{ width: "40%" }}>
          //                                                 <img src={schoolLogo} width="250px" alt="" />
          //                                             </td>
          //                                             <td style={{width:"60%"}}>
          //                                                 <center><span style={{fontSize: "30px",fontWeight: "bold"}}>{schoolName}</span></center>
          //                                                 <center><span style={{fontSize: "20px",fontWeight: "bold"}}>{schoolCity }</span></center>
          //                                             </td>
          //                                         </tr> :

          //                                         <tr>

          //                                             <td >
          //                                                 <center><span style={{fontSize: "30px",fontWeight: "bold"}}>{schoolName}</span></center>
          //                                                 <center><span style={{fontSize: "20px",fontWeight: "bold"}}>{schoolCity }</span></center>
          //                                             </td>
          //                                         </tr>
          //                                         }
          //                                     </table>

          //                                 </div>
          //                                 <div className="col-md-12">
          //                                     <span style={{fontSize:" 30px",fontWeight: "bolder",color: "#5f5f5f"}}>
          //                                         Scan here to self check-in/out using 
          //                                     </span>
          //                                 </div>
          //                                 <div className="col-md-12" style={{marginleft: "-15%",marginTop: "8%"}}>
          //                                     <img className="img-responsive" src={formQRCode} style={{width: "300px",marginTop: "1%"}}/>
          //                                 </div>
          //                                 <div className="col-md-12 mt-5">
          //                                     <p>{props.t('Powerd By')}</p>
          //                                     <img className="img-responsive" src={WhiteLable.getAppLogoWithName()} style={{width: "190px"}}/>
          //                                 </div>
          //                             </div>
          //                         </div>
          //                     </div>
          //                 </center>
          //             </div>

          //         </div>
          //     </Col>
          // </Row>
          <Row className="text-center">
            <Col xs="12" className="d-print-none m-3">

              <div className="text-center">
                <button onClick={() => { setPosterType("a4") }} className={getButtonSelectedClass("a4")}>A4</button>
                <button onClick={() => { setPosterType("card") }} className={getButtonSelectedClass("card")}>Card</button>
                <button onClick={() => { setPosterType("qrcode") }} className={getButtonSelectedClass("qrcode")}>Qr Code</button>
                <button onClick={() => {
                  if (['Windows', 'Mac'].includes(machineType)) {
                    if (!dontShowAgain) {
                      setShowSaveAsPdfModal(true); 
                    } else {
                      printPoster();
                    } 
                  }else{
                    printPoster();
                  }
                }} className="btn btn-success waves-effect waves-light m-2"><i className="fa fa-print"></i>Print/Download Poster</button>
                <SaveAsPdf
                  showModal={showSaveAsPdfModal}
                  toggleModal={() => setShowSaveAsPdfModal(false)}
                  dontShowAgain={dontShowAgain}
                  setDontShowAgain={setDontShowAgain}
                  onNextClick={handleNextClick}
                  machineType={machineType}
                /> 
              </div>
            </Col>

            {posterType == "a4" && <Col>
              <center>
                <div
                  style={{
                    height: "297mm",
                    width: "211mm",
                    backgroundColor: "#fff",
                    border: `50px solid #00ace5`,
                  }}
                  className="text-center"
                >
                  {schoolLogo ? <div>
                    <img src={schoolLogo} width="180px" alt="" />

                    <div className="mt-2">
                      <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                      <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                    </div>
                  </div> :

                    <div className="mt-5">
                      <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                      <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                    </div>
                  }


                  <div className="col-md-12 mt-5">
                    <span style={{ fontSize: " 36px", fontWeight: "bolder", color: "#00ace5", marginTop: "50px", marginLeft: "30px", marginRight: "30px" }}>
                      Scan here to self check-in/out using
                    </span>
                  </div>
                  <div className="col-md-12" >
                    <img className="img-responsive" src={formQRCode} style={{ width: "400px", marginTop: "1%" }} />
                  </div>
                  <div className="col-md-12 mt-5">
                    <p className='m-1' style={{marginLeft:"30mm"}}>{props.t('Powered By')}</p>
                    <img className="img-responsive" src={WhiteLable.getAppLogoWithName()} style={{ width: "190px" }} />
                  </div>

                </div>
              </center>
            </Col>}
            {posterType == "card" && <Col>
              <center>
                <div style={{ minHeight: "200mm", width: "150mm", backgroundColor: "#fff", }} className="text-center">
                  <div>
                  <img className="checkin" src={checkin} style={{ width: "100%", marginTop: "1%" }} />
                  </div>
                  <table className='m-1'>
                    {schoolLogo ? <tr>
                      <td style={{ width: "40%",marginTop: "1%"}}>
                        <img className="p2" src={schoolLogo} width="160" alt="" />
                      </td>
                      <td style={{ width: "60%" }}>
                        <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                        <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                      </td>
                    </tr> :

                      <tr>

                        <td >
                          <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                          <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                        </td>
                      </tr>
                    }
                  </table>
                  <div className='text-center'>
                    <div className="m-1" style={{ minHeight: "50px", width: "400px", backgroundColor: "#fff", display: "inline-block" }}>
                      <div className="col-md-12">
                        <img className="img-responsive" src={formQRCode} style={{ width: "250px", margin: "0 auto", border: `15px solid #2b388f` }} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 m-1">
                    <span style={{ fontSize: "25px", fontWeight: "bolder", display: "flex", justifyContent: "center" }}>
                      Scan here to self check-in/out using
                    </span>
                    <p className='m-1' style={{ fontWeight: 'bolder', fontSize: '40px', color: "#2B388F",display: "flex", justifyContent: "center"}}>{WhiteLable.getAppName()}</p>
                    <p className='m-1' style={{ fontSize: "30px" }}>App</p>
                    <hr style={{ border: "0.9px solid #000000", marginLeft: "6cm", marginRight: "6cm" }} />
                    <div className="col-md-12 ">
                      <p className='m-1' style={{marginLeft:"30mm"}}>{props.t('Powered By')}</p>
                      <img className="img-responsive" src={WhiteLable.getAppLogoWithName()} style={{ width: "160px" }} />
                    </div>
                  </div>
                  <div>
                  <img className="img-responsive" src={checkin2} style={{ width: "100%", marginBottom: "1%" }} />

                  </div>
                  </div>
              </center>
            </Col>}
            {posterType == "qrcode" && <Col>
              <div className="col-md-12" style={{ marginleft: "-15%", marginTop: "8%" }}>
                <img className="img-responsive" src={formQRCode} style={{ width: "400px", marginTop: "1%" }} />
                <p className='m-4'>Scan here to self check-in/out using</p>
              </div>
            </Col>}

          </Row>
        }
      </Container>
    </React.Fragment>

  );
}



export default withNamespaces()(CheckInPoster);;