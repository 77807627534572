import React, {useState, useEffect} from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter, } from "react-router-dom";
import { connect } from 'react-redux';
import StudentDataReport from './StudentDataReport'
import StudentExitReport from "./StudentExitReport"
import NewAdmissionReport from "./NewAdmissionReport"
import StudentCorporateReport from "./StudentCorporateReport"
import Parse from "parse";
import Moment from 'moment';
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle,
    Container,
    Button
} from "reactstrap";
import { getSchoolForInstitute, getFranchiseTeachers} from '../../../store/actions'
import Select from "react-select"
import * as XLSX from 'xlsx/xlsx.mjs'

const OtherReportDropdown = (props) => {

    
    const allReports = props.showCorporatePartners ?
        ["Student Data", "Student Exit Report", "New Admission Report", "Corporate Student Report"]
        : ["Staff Data", "Franchise Admin Data", "Student Data", "Student Exit Report", "New Admission Report",]
    
    const [selctedReport, setSelctedReport] = useState('Student Data'); 
    const [staffData, setStaffData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [allSchools, setAllSchools] = useState([]);
    const [selectedSchool, setSelctedSchool] = useState({ value: "allSchools", label: 'All Schools' });
    const [isReportGenerated, setIsReportGenerated] = useState(false);
    const [franchiseAdminData, setFranchiseAdminData] = useState([]);

    useEffect(() => {
        if (Parse.User.current().attributes.franchiseId) {
            
            props.getSchoolForInstitute(Parse.User.current().attributes.franchiseId, "franchiseId");
            props.getFranchiseTeachers(Parse.User.current().attributes.franchiseId, "franchiseId")
        }
    }, [Parse.User.current().attributes.franchiseId]);

    useEffect(() => {
        if (props.schools) {
            let list = [{ value: "allSchools", label: 'All Schools' }];
            for (const school of props.schools) {
                list.push({ value: school.id, label: school.attributes.Name })
            }
            setAllSchools(list);
        }
    }, [props.schools]);

    const getDateInDDMMYYYY = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };

    const handleReportSelection = (report) => {
        setSelctedReport(report);
        if (report === "Staff Data") {
            getStaffDataForSelectedFilter(); 
        }else if(report === "Franchise Admin Data") {
            getFranchiseAdminsForSelectedFilter();
        }
    };

    const handleGenerateReport = () => {
        if(selectedSchool) {
            setIsReportGenerated(true);
            getStaffDataForSelectedFilter();
        }else {
            getFranchiseAdminsForSelectedFilter();
        }
    };

    const getFranchiseAdminsForSelectedFilter = () => {
        setIsLoading(true);
        setFranchiseAdminData([]);
        let query = new Parse.Query("Teacher");
        query.equalTo("franchiseID", props.franchiseId);
        query.notEqualTo("deleted", true)
        query.containedIn("Role", [10, 11, 12, 13, 14]);
        query.ascending("Name");
        query.limit(1000);
    
        query.find().then((results) => {
            processTeachersData(results); 
            setIsLoading(false);
        }).catch((error) => {
            console.error("Error fetching franchise teachers:", error);
            setIsLoading(false); 
        });
    };
    
    const getStaffDataForSelectedFilter = () => {
        setIsLoading(true);
        setStaffData([]);
        setFranchiseAdminData([]);
        let query = new Parse.Query("Teacher");
        query.notEqualTo("deleted", true)
        if (selectedSchool.value !== "allSchools") {
            query.equalTo("SchoolID", selectedSchool.value);  
        } else {
            let schoolIds = props.schools.map(school => school.id);
            query.containedIn("SchoolID", schoolIds);
        }
        query.ascending("Name");
        query.limit(1000);
        
        query.find().then((results) => {
            processTeachersData(results); 
            setIsLoading(false);
        }).catch((error) => {
            console.error("Error fetching franchise teachers:", error);
            setIsLoading(false); 
        });
    };
    

    const processTeachersData = (teachers) => {
        let tempTeacher = [];
        for (const val of teachers) {
            let temp = {};
            temp.id = val.id;
            temp.obj = val;
            temp.name = val.attributes.Name;
            temp.designation = val.attributes.designation || (val.attributes.Role === 1 ? "Teacher" : val.attributes.Role === 2 ? "Center Head" : val.attributes.Role === 10 || val.attributes.Role === 11 || val.attributes.Role === 12 || val.attributes.Role === 13 || val.attributes.Role === 14 ? "Admin" : "Director");
            temp.status = val.attributes.deleted ? 'Archived' : 'Active';
            temp.phoneNumber = val.attributes.PhoneNumber;
            temp.email = val.attributes.EMail;
            temp.classList = val.attributes.ClassRooms ? val.attributes.ClassRooms.join(', ') : '--';
            temp.employerCode = val.attributes.empCode;
            temp.dateOfJoin = val.attributes.doj ? getDateInDDMMYYYY(val.attributes.doj) : "";
            temp.gender = val.attributes.Gender ?? "";
            temp.emergencyContact = val.attributes.emergencyContact ?? "";
            temp.emergencyContactPersonName = val.attributes.emergencyContactPersonName ?? "";

            temp.address = val.attributes.address ?? ""
            temp.communicationAddress = val.attributes.communicationAddress ?? ""
            temp.nationality = val.attributes.nationality ?? ""
            temp.religion = val.attributes.religion ?? ""
            temp.adhar = val.attributes.aadharNumber ?? ""
            temp.fatherName = val.attributes.fatherName ?? ""
            temp.maritalStatus = val.attributes.maritalStatus ?? ""
            temp.spouseName = val.attributes.spouseName ?? ""
            temp.employeeType = val.attributes.employeeType ?? ""
            temp.qualification = val.attributes.qualification ?? ""
            temp.about = val.attributes.about ?? ""
            temp.collegeDetails = val.attributes.collegeDetails ?? ""
            temp.notes = val.attributes.notes ?? ""
            temp.appointmentLetter = val.attributes.appointmentLetter === true ? "Yes" :(val.attributes.appointmentLetter === false ? "No" : "");

            temp.initialSalary = val.attributes.initialSalary ?? ""
            temp.currentSalary = val.attributes.currentSalary ?? ""
            temp.accountNumber = val.attributes.bankAccount?.accountNumber ?? ""
            temp.nameAsPerBank = val.attributes.bankAccount?.nameAsPerBank ?? ""
            temp.bankName = val.attributes.bankAccount?.bankName ?? ""
            temp.ifsc = val.attributes.bankAccount?.IFSC ?? ""
            temp.branchName = val.attributes.bankAccount?.branchName ?? ""
            temp.pan = val.attributes.pan ?? ""
            temp.uan = val.attributes.UAN ?? ""

            temp.noticePeriod = val.attributes.noticePeriod ?? ""
            temp.noticePeriodServed = (val.attributes.noticePeriodStatus === true) ? "Yes" : (val.attributes.noticePeriodStatus === false) ? "No" : "";
            temp.exitReason = val.attributes.exitReason ?? ""
            temp.dues = (val.attributes.dues === true) ? "Yes" : (val.attributes.dues === false) ? "No" : "";
            temp.experienceLetter = (val.attributes.experienceLetter === true) ? "Yes" : (val.attributes.experienceLetter === false) ? "No" : "";
            temp.appriasalDate = val.attributes.appriasalDate ? getDateInDDMMYYYY(val.attributes.appriasalDate) : "";
            tempTeacher.push(temp);
        }
        setStaffData(tempTeacher);
        setFranchiseAdminData(tempTeacher);
    };

    const downloadTheReport = () => {
        let rawData = staffData || franchiseAdminData;
        let finalData = [];
        let titles = [
            "Name",
            "Gender",
            "Designation",
            "Phone Number",
            "EMail",
            "Blood Group",
            "Employer Code",
            "Date Of Join",
            "Date Of Birth",
            "Emergency Contact Number",
            "Emergency Contact Name",

            "Permanent Address",
            "Address For Communication",
            "Nationality",
            "Religion",
            "Aadhar Number",
            "Father Name",
            "Maritual Status",
            "Spouse Name",
            "Employee Type",
            "Qualification",
            "About",
            "College Details",
            "Notes",
            "Appointment Letter Sent?",

            "Initial Salary",
            "Current Salary",
            "Account Number",
            "Name as per bank",
            "Bank Name",
            "IFSC",
            "Branch Name",
            "PAN",
            "UAN",

            "Notice Period",
            "Notice Period Served",
            "Reason for leaving Reason",
            "All School Related Materials/Resources Collected?",
            "Experience Letter Status?",
            "Last Appraisal Done On"
        ];
        
        let sheetName = "";
        let fileName = "";
        
        finalData.push([`Report Type: ${selctedReport}`]);
        if (selctedReport === "Staff Data") {
            finalData.push([`Selected: ${selectedSchool.label}`]);
            finalData.push([`Total teachers:  ${staffData.length}`])
        }
        finalData.push([]);
        finalData.push(titles);
        
        for (const val of rawData) {
            let temp = [];
            temp.push(val.name);
            temp.push(val.gender);
            temp.push(val.designation);
            temp.push(val.phoneNumber);
            temp.push(val.email);
            temp.push(val.bloodGroup)
            temp.push(val.employerCode);
            temp.push(val.dateOfJoin);
            temp.push(val.dob);
            temp.push(val.emergencyContact);
            temp.push(val.emergencyContactPersonName);

            temp.push(val.address);
            temp.push(val.communicationAddress);
            temp.push(val.nationality);
            temp.push(val.religion);
            temp.push(val.adhar)
            temp.push(val.fatherName);
            temp.push(val.maritalStatus);
            temp.push(val.spouseName);
            temp.push(val.employeeType);
            temp.push(val.qualification);
            temp.push(val.about);
            temp.push(val.collegeDetails);
            temp.push(val.notes);
            temp.push(val.appointmentLetter);

            temp.push(val.initialSalary);
            temp.push(val.currentSalary);
            temp.push(val.accountNumber);
            temp.push(val.nameAsPerBank);
            temp.push(val.bankName);
            temp.push(val.ifsc);
            temp.push(val.branchName);
            temp.push(val.pan);
            temp.push(val.uan);

            temp.push(val.noticePeriod);
            temp.push(val.noticePeriodServed);
            temp.push(val.exitReason)
            temp.push(val.dues);
            temp.push(val.experienceLetter);
            temp.push(val.appriasalDate);
            finalData.push(temp);
        }
    
        if (selctedReport === "Staff Data") {
            sheetName = "All Staff Data";
            fileName = "All Staff Data.xlsx";
        } else if (selctedReport === "Franchise Admin Data") {
            sheetName = "Franchise Data";
            fileName = "Franchise.xlsx";
        }
    
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(finalData);
        ws['!cols'] = titles.map(title => {
            return (title === 'collegeDetails' || title === 'staffNotes') ? { wpx: 300 } : { wpx: 150 };
        });
        
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName);
    };
    
     
     return (
        <React.Fragment>
            
            <CardTitle>Others Reports</CardTitle>
            <Row className="bg-light">
                <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>{props.t("Select Report")} :</Label>
                        <select
                            className="form-control "
                            value={selctedReport}
                            onChange={(e) => {
                                handleReportSelection(e.target.value);
                            }}
                        >
                            <option disabled selected value>Select Report </option>
                            {allReports.map(
                                (val, key) =>
                                    <option key={key} value={val}>{val}</option>
                                    
                            )}
                        </select>
                    </FormGroup>
                </Col>
                 {selctedReport === "Franchise Admin Data" && (
                     <Col md="3" xs="12" xl="3" lg="3">
                         <div className="mt-3">
                             <button
                                 className="btn btn-primary mt-4 mr-2"
                                 onClick={handleGenerateReport}
                                 disabled={isLoading || !selectedSchool}
                             >
                                 {props.t("Generate")}
                             </button>
                         </div>
                     </Col>
                 )}
                 <Row>
                     {(selctedReport === "Staff Data" || selctedReport === "Franchise Admin Data") && (
                         <>  {selctedReport === "Staff Data" && (
                             <Col md="3" xs="12" xl="3" lg="3">
                                 <FormGroup className="mt-2 mb-2 p-1">
                                     <Label>{props.t("Selcted school")} :</Label>

                                     <Select
                                         value={selectedSchool}
                                         onChange={(v) => {
                                             setSelctedSchool(v)
                                         }}
                                         options={allSchools}
                                         className="selectPaymentType"
                                         classNamePrefix="selectPayType"

                                     />
                                 </FormGroup>
                             </Col>)}
                             {selctedReport === "Staff Data" && (
                                 <Col md="3" xs="12" xl="3" lg="3">
                                         <div className="mt-3">
                                             <button
                                                 className="btn btn-primary mt-4 mr-2"
                                                 onClick={handleGenerateReport}
                                                 disabled={isLoading || !selectedSchool}
                                             >
                                                 {props.t("Generate")}
                                             </button>
                                     </div>
                                 </Col>
                                )}
                             {isLoading ? (
                                 <Col xs="12">
                                     <div className="text-center m-0">
                                         <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {isLoading ? "getting report data..." : "Preparing Report"}</Label>
                                     </div>
                                 </Col>
                             ) : (
                                <>
                                         <Card style={{marginLeft: '10px'}}>
                                             <CardBody>
                                                <Row>
                                                {selctedReport === "Staff Data" && (
                                                <Col lg="6">
                                                <CardTitle>{"Staff Data"}</CardTitle>
                                                 <p className='mb-0'>{"Report For: " + selectedSchool.label}</p>
                                                 <p>{"Total teachers: " + staffData.length}</p> 
                                                </Col>)}
                                                {selctedReport === "Franchise Admin Data" && (
                                                <Col lg="6">
                                                <CardTitle>{"Admin Data"}</CardTitle>
                                                 <p>{"Total Admin: " + franchiseAdminData.length}</p> 
                                                </Col>)}
                                                     <Col lg={selctedReport === "Staff Data" ? 6 : 12}>

                                                         <div className="d-flex d-flex justify-content-end">
                                                             <button
                                                                 onClick={() => {
                                                                     if (staffData && staffData.length > 0 || franchiseAdminData && franchiseAdminData.length > 0) {
                                                                        downloadTheReport();
                                                                     }

                                                                 }}
                                                                 className="w-md  btn btn-primary"
                                                             >
                                                                 <i className='bx bx-download font-size-16 align-middle m-1'></i>
                                                                 {props.t("Download Report")}
                                                             </button>
                                                         </div>
                                                     </Col>
                                                </Row>
                                                {staffData.length === 0 ? (
                                                        <h6 className='text-center text-info'>No Staff to show</h6> 
                                                    
                                                ) : (
                                                 <div className="table-responsive">
                                                     <table className="table table-centered table-nowrap">
                                                         <thead>
                                                             <tr>
                                                                <th>{props.t("Sl.No")}</th>
                                                                <th>{props.t("Name")}</th>
                                                                <th>{props.t("Designation")}</th>
                                                                <th>{props.t("Phone Number")}</th>
                                                                <th>{props.t("Email")}</th>
                                                                <th>{props.t("Blood Group")}</th>
                                                                <th>{props.t("Gender")}</th>
                                                                <th>{props.t("Employer Code")}</th>
                                                                <th>{props.t("Date Of Birth")}</th>
                                                                <th>{props.t("Date Of Join")}</th>
                                                                <th>{props.t("Status")}</th>
                                                                <th>{props.t("Emergency Contact Number")}</th>
                                                                <th>{props.t("Emergency Contact Name")}</th>

                                                                <th>{props.t("Permanent Address")}</th>
                                                                <th>{props.t("Address for Communication")}</th>
                                                                <th>{props.t("Nationality")}</th>
                                                                <th>{props.t("Religion")}</th>
                                                                <th>{props.t("Aadhar Number")}</th>
                                                                <th>{props.t("Father Name")}</th>
                                                                <th>{props.t("Maritual Status ")}</th>
                                                                <th>{props.t("Spouse Name")}</th>
                                                                <th>{props.t("Employee Type")}</th>
                                                                <th>{props.t("Qualification")}</th>
                                                                <th>{props.t("About")}</th>
                                                                <th>{props.t("College Details")}</th>
                                                                <th>{props.t("Notes/Comments about Staff")}</th>
                                                                <th>{props.t("Appointment Letter Sent?  ")}</th>

                                                                <th>{props.t("Initial Salary")}</th>
                                                                <th>{props.t("Current Salary")}</th>
                                                                <th>{props.t("Account Number")}</th>
                                                                <th>{props.t("Name as per bank")}</th>
                                                                <th>{props.t("Bank Name")}</th>
                                                                <th>{props.t("IFSC")}</th>
                                                                <th>{props.t("Branch Name")}</th>
                                                                <th>{props.t("PAN")}</th>
                                                                <th>{props.t("UAN")}</th>

                                                                <th>{props.t("Notice Period")}</th>
                                                                <th>{props.t("Notice Period Served")}</th>
                                                                <th>{props.t("School Materials provided")}</th>
                                                                <th>{props.t("Experience Letter Status? ")}</th>
                                                                <th>{props.t("Last Appraisal Done On (Month and Year)")}</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                             {staffData.map((val, key) => (
                                                                 <tr key={key}>
                                                                    <td>{key+1}</td>
                                                                     <td>{val.name}</td>
                                                                     <td>{val.designation}</td>
                                                                     <td>{val.phoneNumber}</td>
                                                                     <td>{val.email}</td>
                                                                     <td>{val.bloodGroup}</td>
                                                                     <td>{val.gender}</td>
                                                                     <td>{val.employerCode}</td>
                                                                     <td>{val.dob}</td>
                                                                     <td>{val.dateOfJoin}</td>
                                                                     <td>{val.status}</td>
                                                                     <td>{val.emergencyContact}</td>
                                                                     <td>{val.emergencyContactPersonName}</td>

                                                                     <td style={{ width: '1px', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.address}</td>
                                                                     <td style={{ width: '1px', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.communicationAddress}</td>
                                                                     <td>{val.nationality}</td>
                                                                     <td>{val.religion}</td>
                                                                     <td>{val.adhar}</td>
                                                                     <td>{val.fatherName}</td>
                                                                     <td>{val.maritalStatus}</td>
                                                                     <td>{val.spouseName}</td>
                                                                     <td>{val.employeeType}</td>
                                                                     <td style={{ width: '1px', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.qualification}</td>
                                                                     <td style={{ width: '1px', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.about}</td>
                                                                     <td style={{ width: '1px', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.collegeDetails}</td>
                                                                     <td style={{ width: '1px', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.notes}</td>
                                                                     <td>{val.appointmentLetter}</td>

                                                                     <td>{val.initialSalary}</td>
                                                                     <td>{val.currentSalary}</td>
                                                                     <td>{val.accountNumber}</td>
                                                                     <td>{val.nameAsPerBank}</td>
                                                                     <td>{val.bankName}</td>
                                                                     <td>{val.ifsc}</td>
                                                                     <td>{val.branchName}</td>
                                                                     <td>{val.pan}</td>
                                                                     <td>{val.uan}</td>

                                                                     <td>{val.noticePeriod}</td>
                                                                     <td>{val.noticePeriodServed}</td>
                                                                     <td>{val.dues}</td>
                                                                     <td>{val.experienceLetter}</td>
                                                                     <td>{(val.appriasalDate)}</td>
                                                                 </tr>
                                                             ))}
                                                         </tbody>
                                                     </table>
                                                 </div>
                                                )}
                                             </CardBody>
                                         </Card>     
                                 </>
                             )}
                            </>

                     )}

                 </Row>
                

                <Col md="12" xs="12" xl="12" lg="12">
                    {selctedReport == "Student Data" && <StudentDataReport isFranchise={true} showCorporatePartners={ props.showCorporatePartners} />}
                    {selctedReport == "Student Exit Report" && <StudentExitReport isFranchise={true} showCorporatePartners={ props.showCorporatePartners} />}
                    {selctedReport == "New Admission Report" && <NewAdmissionReport isFranchise={true} showCorporatePartners={ props.showCorporatePartners} />}
                    {selctedReport == "Corporate Student Report" && <StudentCorporateReport isFranchise={true} showCorporatePartners={ props.showCorporatePartners} />}
                </Col>
               
            </Row>

            <Row>
               
            </Row>

           
        </React.Fragment>
    );
}
            

const mapStatetoProps = state => {
    const { schools,} = state.School;
    const { franchiseTeachers,} = state.Teacher;
    return { schools, franchiseTeachers };
}
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {getSchoolForInstitute, getFranchiseTeachers})(OtherReportDropdown)
  )
);