import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Table, Col, Row, Progress, Button, UncontrolledTooltip, Label, FormGroup } from "reactstrap";
import { withNamespaces } from "react-i18next";
import Editable from "react-bootstrap-editable";
import UpdatePhoto from '../../components/Common/UpdatePhoto';
import Switch from "react-switch";
import Select from "react-select";
import Parse from 'parse';
import CreatableSelect from 'react-select/creatable';
import { editTeacher } from '../../store/actions';
import DatePicker from "react-datepicker";
import moment from "moment";
import toastr from 'toastr';

const OtherDetails = (props) => {
    
    const [attachmentAdhar, setAttachmentAdhar] = useState(null);
    const [attachmentAdharUploadProgress, setAttachmentAdharUploadProgress] = useState(0);
    const [attachmentPan, setAttachmentPan] = useState(null);
    const [attachmentPanUploadProgress, setAttachmentPanUploadProgress] = useState(0);
    const [attachmentQualofication, setAttachmentQualofication] = useState(null);
    const [attachmentQualoficationUploadProgress, setAttachmentQualoficationUploadProgress] = useState(0);


    const [isBloodGroupEditOn, setIsBloodGroupEditOn] = useState(false);
    const [bloodGroup, setBloodGroup] = useState(undefined);
    //Details
    const [address, setAddress] = useState(undefined);
    const [communicationAddress, setCommunicationAddress] = useState()
    const [nationality, setNationality] = useState();
    const [religion, setReligion] = useState();
    const [adhar, setAdhar] = useState();
    const [father, setFather] = useState();
    const [isMaritualStatusEditOn, setIsMaritualStatusEditOn] = useState(false);
    const [maritualStatus, setMaritualStatus] = useState();
    const [spouseName, setSpouseName] = useState()
    const [isEmployeeTypeEditOn, setIsEmployeeTypeEditOn] = useState(false);
    const [employeeType, setEmployeeType] = useState();
    const [collegeDetails, setCollegeDetails] = useState();
    const [qualification, setQualification] = useState();
    const [about, setAbout] = useState();
    const [notes, setNotes] = useState();
    const [isAppointmentLetterEditOn, setIsAppointmentLetterEditOn] = useState(false);
    const [appointmentLetter, setAppointmentLetter] = useState(null);
    const [workingDaysMap, setWorkingDaysMap] = useState([]);
    const [selectedDays, setSelectedDays] = useState([])
    const [checkInTime, setCheckInTime] = useState();
    const [checkOutTime, setCheckOutTime] = useState();
    

    const [initialSalary, setInitialSalary] = useState()
    const [currentSalary, setCurrentSalary] = useState();
    const [acNumber, setAcNumber] = useState()
    const [asPerName, setAsPerName] = useState()
    const [bankName, setBankName] = useState()
    const [ifsc, setIfsc] = useState()
    const [branchName, setBranchName] = useState()
    const [pan, setPan] = useState();
    const [uan, setUaNumber] = useState()
    const [bankAccount, setBankAccount] = useState({})
    
    const [noticePeriod, setNoticePeriod] = useState()
    const [isNoticePeriodEditOn, setIsNoticePeriodEdit] = useState(false);
    const [noticePeriodStatus, setNoticePeriodStatus] = useState(null);
    const [exitReason, setExitReason] = useState();
    const [isDuesEditOn, setIsDuesEdit] = useState(false);
    const [dues, setDues] = useState(null);
    const [appriasalDate, setAppriasalDate] = useState();
    const [isExperienceLetterEditOn, setIsExperienceLetterEditOn] = useState(false);
    const [experienceLetter, setExperienceLetter] = useState(null);
  
    const maritualStats = [
        { value: "Married", label: "Married" },
        { value: "Single", label: "Single" },
    ];

    const employeeTypeList = [
        { value: "Permanent", label: "Permanent" },
        { value: "Probation", label: "Probation" },
        { value: "Temporary", label: "Temporary" },
        { value: "Full-time", label: "Full-time" },
        { value: "Seasonal", label: "Seasonal" },
        { value: "Leased", label: "Leased" },
        { value: "At-will", label: "At-will" },

    ];

    const daysOptions = [
      { value: 1, label: "Sunday", isDisabled: true  },
      { value: 2, label: "Monday" },
      { value: 3, label: "Tuesday" },
      { value: 4, label: "Wednesday" },
      { value: 5, label: "Thursday" },
      { value: 6, label: "Friday" },
      { value: 7, label: "Saturday" },
    ];
  
    const allDaysOptions = [
      { value: 0, label: "@All Week/(Mon-Sun)" },
      { label : 1, label : "Monday-Saturday" },
      { label : 2, label: "Monday-Friday"},
    ];
    
    useEffect(() => {

        if (props.selectedTeacher) {
            setAddress(props.selectedTeacher.attributes.address);
            setCommunicationAddress(props.selectedTeacher.attributes.communicationAddress);
            setNationality(props.selectedTeacher.attributes.nationality);
            setReligion(props.selectedTeacher.attributes.religion);
            setAdhar(props.selectedTeacher.attributes.aadharNumber);
            setPan(props.selectedTeacher.attributes.pan);
            setFather(props.selectedTeacher.attributes.fatherName);
            {props.selectedTeacher.attributes.maritalStatus &&  setMaritualStatus({value:props.selectedTeacher.attributes.maritalStatus,label:props.selectedTeacher.attributes.maritalStatus})}
            setSpouseName(props.selectedTeacher.attributes.spouseName);
            {props.selectedTeacher.attributes.employeeType &&  setEmployeeType({value:props.selectedTeacher.attributes.employeeType,label:props.selectedTeacher.attributes.employeeType})}
            setQualification(props.selectedTeacher.attributes.qualification);
            setAbout(props.selectedTeacher.attributes.about);
            setCollegeDetails(props.selectedTeacher.attributes.collegeDetails)
            setNotes(props.selectedTeacher.attributes.notes);
            if (props.selectedTeacher.attributes.appointmentLetter !== undefined) {
              const isAppointmentLetterYes = props.selectedTeacher.attributes.appointmentLetter === true;
              setAppointmentLetter({
                  value: isAppointmentLetterYes ? "Yes" : "No",
                  label: isAppointmentLetterYes ? "Yes" : "No"
              });}

            setInitialSalary(props.selectedTeacher.attributes.initialSalary)
            setCurrentSalary(props.selectedTeacher.attributes.currentSalary)
            setUaNumber(props.selectedTeacher.attributes.UAN);
            
            setNoticePeriod(props.selectedTeacher.attributes.noticePeriod)
            setExitReason(props.selectedTeacher.attributes.exitReason);
            setAppriasalDate(props.selectedTeacher.attributes.appriasalDate)

            if (props.selectedTeacher.attributes.noticePeriodStatus !== undefined) {
              const isNoticePeriodYes = props.selectedTeacher.attributes.noticePeriodStatus === true;
              setNoticePeriodStatus({
                  value: isNoticePeriodYes ? "Yes" : "No",
                  label: isNoticePeriodYes ? "Yes" : "No"
              });}
              if (props.selectedTeacher.attributes.dues !== undefined) {
                const isDuesYes = props.selectedTeacher.attributes.dues === true;
                setDues({
                    value: isDuesYes ? "Yes" : "No",
                    label: isDuesYes ? "Yes" : "No"
                });}
          if (props.selectedTeacher.attributes.experienceLetter !== undefined) {
            const isExperienceLetterYes = props.selectedTeacher.attributes.experienceLetter === true;
            setExperienceLetter({
              value: isExperienceLetterYes ? "Yes" : "No",
              label: isExperienceLetterYes ? "Yes" : "No"
            });
          }
            if (props.selectedTeacher.attributes.bankAccount) {
                let ba = props.selectedTeacher.attributes.bankAccount;
                setBankAccount(ba)
                setAcNumber(ba.accountNumber);
                setAsPerName(ba.nameAsPerBank);
                setBankName(ba.bankName);
                setIfsc(ba.IFSC);
                setBranchName(ba.branchName)
            }
            if (props.selectedTeacher.attributes.workingDaysMap) {
              let workingDays = props.selectedTeacher.attributes.workingDaysMap;
              setWorkingDaysMap(workingDays);
              setSelectedDays(workingDays.map(day => ({ value: day.day, label: day.day })));
               if (workingDays.length > 0 && workingDays[0].checkInTime && workingDays[0].checkOutTime) {
                setCheckInTime(moment(workingDays[0].checkInTime, "h:mm A").toDate());
                setCheckOutTime(moment(workingDays[0].checkOutTime, "h:mm A").toDate());
            }
            }
            if (props.selectedTeacher.attributes.panDocument) {
                let fileExtension = props.selectedTeacher.attributes.panDocument._url.split('.').pop().toLowerCase();
                setAttachmentPan({
                    actualFileName: "pan_card."+fileExtension,
                    fileURL: props.selectedTeacher.attributes.panDocument._url
                });
            }
            if (props.selectedTeacher.attributes.aadharDocument) {

                let fileExtension = props.selectedTeacher.attributes.aadharDocument._url.split('.').pop().toLowerCase();
                setAttachmentAdhar({
                    actualFileName: "aadhar_card."+fileExtension,
                    fileURL: props.selectedTeacher.attributes.aadharDocument._url
                });
            }
            if (props.selectedTeacher.attributes.qualificationDocuments) {

              let fileExtension = props.selectedTeacher.attributes.qualificationDocuments._url.split('.').pop().toLowerCase();
              setAttachmentQualofication({
                  actualFileName: "qualification_documents."+fileExtension,
                  fileURL: props.selectedTeacher.attributes.qualificationDocuments._url
              });
          }
            console.log("Teacher", props.selectedTeacher)
          }
        
    }, [props.selectedTeacher]);


    const handleChange = (type, value) => {
        var temp = props.selectedTeacher;

        if (type === 1) {
            temp.set("address", value);
            setAddress(value);
        } else if (type === 2) {
            temp.set("nationality", value);
            setNationality(value);
        } else if (type === 3) {
            temp.set("religion", value);
            setReligion(value);
        } else if (type === 4) {
            temp.set("aadharNumber", value);
            setAdhar(value);
        }
        else if (type === 5) {
            temp.set("pan", value);
            setPan(value);
        }
        else if (type === 6) {
            temp.set("fatherName", value);
            setFather(value);
        }
        else if (type === 7) {
            temp.set("spouseName", value);
            setSpouseName(value);
        }
        else if (type === 8) {
            let obj = {...bankAccount, accountNumber: value }
            temp.set("bankAccount", obj);
            setBankAccount(obj)
            setAcNumber(value);
        }
        else if (type === 9) {
            let obj = {...bankAccount, nameAsPerBank: value }
            temp.set("bankAccount", {...bankAccount, nameAsPerBank: value });
            setBankAccount(obj)
            setAsPerName(value);
        }
        else if (type === 10) {
            let obj = {...bankAccount, bankName: value }
            temp.set("bankAccount", {...bankAccount, bankName: value });
            setBankAccount(obj)
            setBankName(value);
        }
        else if (type === 11) {
            let obj = {...bankAccount, IFSC: value }
            temp.set("bankAccount", {...bankAccount, IFSC: value });
            setBankAccount(obj)
            setIfsc(value);
        }
        else if (type === 12) {
            let obj = {...bankAccount, branchName: value }
            temp.set("bankAccount", {...bankAccount, branchName: value });
            setBankAccount(obj)
            setBranchName(value);
        }else if (type === 13) {
          temp.set("communicationAddress", value);
          setCommunicationAddress(value);
        }else if (type === 14) {
        temp.set("collegeDetails", value);
        setCollegeDetails(value);
      }else if (type === 15) {
        temp.set("initialSalary", value);
        setInitialSalary(value);
      }else if (type === 16) {
        temp.set("noticePeriod", value);
        setNoticePeriod(value);
      }else if (type === 17) {
        temp.set("exitReason", value);
        setExitReason(value);
      }else if (type === 18) {
        temp.set("notes", value);
        setNotes(value);
      }else if (type === 19) {
        temp.set("currentSalary", value);
        setCurrentSalary(value);
      }else if (type === 20) {
        temp.set("UAN", value);
        setUaNumber(value);
    }else if (type === 21) {
      temp.set("qualification", value);
      setQualification(value);
    }else if (type === 22) {
      temp.set("about", value);
      setAbout(value);
    }
        props.editTeacher(temp);
    }

    const handleMaritualStatusChange = () => {
        
        setIsMaritualStatusEditOn(false);
        var temp = props.selectedTeacher;
        temp.set("maritalStatus", maritualStatus.value);
        props.editTeacher(temp);
    }

    const handleEmployeeTypeChange = () => {
      setIsEmployeeTypeEditOn(false);
      var temp = props.selectedTeacher;
      temp.set("employeeType", employeeType.value);
      props.editTeacher(temp);
  }

  const handleAppriasalDateChange = (value) => {
    setAppriasalDate(value);
    var temp = props.selectedTeacher;
    temp.set("appriasalDate", value);
    props.editTeacher(temp);
  };

  function handleMulti(selectedMulti) {
    let finalList = [];

    if (!selectedMulti || selectedMulti.length === 0) {
      finalList = selectedMulti;
    } else {
      if (selectedMulti.some((item) => item.value === 0)) {
        finalList = [...daysOptions];
      }
      else if (selectedMulti.some((item) => item.label === "Monday-Saturday")) {
        finalList = daysOptions.filter((day) => day.value !== 1);
      }
      else if (selectedMulti.some((item) => item.label === "Monday-Friday")) {
        finalList = daysOptions.filter((day) => day.value !== 1 && day.value !== 7);
      }
      else {
        finalList = selectedMulti;
      }
    }
    setSelectedDays(finalList);
  }

  const getDayNumber = (dayLabel) => {
    const dayNames = {
      'Sunday': 0,
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6
    };
    
    return dayNames[dayLabel]
  };

  const handleWorkingDays = () => {
    const formatTime = (date) => {
      if (!date) return null;
      return moment(date).format("h:mm A");
    };

    const workingDaysData = selectedDays.map((day, index) => {
      const dayId = getDayNumber(day.label)
      const isWorking = checkInTime && checkOutTime ? true : false;
      return {
        id:dayId,
        day: day.label,
        working: isWorking,
        checkInTime: formatTime(checkInTime),
        checkOutTime: formatTime(checkOutTime),
        checkInObject: checkInTime,
        checkOutObject:checkOutTime
      };
    });
    setWorkingDaysMap(workingDaysData);
    console.log("WorkingDaysData",workingDaysData)
    var temp = props.selectedTeacher;
    temp.set("workingDaysMap", workingDaysData);
    props.editTeacher(temp);
    console.log("Teacher", temp);
    toastr.success("Successfully saved!");
  };
  
  const handleNoticePeriod = () => {
    setIsNoticePeriodEdit(false);
    var temp = props.selectedTeacher;
    const isNoticePeriodYes = noticePeriodStatus.value === "Yes";
    temp.set("noticePeriodStatus", isNoticePeriodYes);
    props.editTeacher(temp);
  }

    const handleDuesChange = () => {
      setIsDuesEdit(false);
      var temp = props.selectedTeacher;
      const isDuesYes = dues.value === "Yes";
      temp.set("dues", isDuesYes);
      props.editTeacher(temp);
    };

    const handleExperienceLetterChange = () => {
      setIsExperienceLetterEditOn(false);
      var temp = props.selectedTeacher;
      const isExperienceLetterYes = experienceLetter.value === "Yes";
      temp.set("experienceLetter", isExperienceLetterYes);
      props.editTeacher(temp);
    };

    const handleAppointmentLetterChange = () => {
      setIsAppointmentLetterEditOn(false);
      var temp = props.selectedTeacher;
      const isAppointmentLetterYes = appointmentLetter.value === "Yes";
      temp.set("appointmentLetter", isAppointmentLetterYes);
      props.editTeacher(temp);
    };
  
  // useEffect(() => {
    //     if (props.selectedSchool && props.selectedSchool.id === props.schoolId) {
    //         setAdmissionNumberFormat(props.selectedSchool.attributes.admissionNumberFormat);
    //         setAdmissionRunningNumber(props.selectedSchool.attributes.admissionRunningNumber);

    //         setEmployeeCodeFormat(props.selectedSchool.attributes.employeeCodeFormat);
    //         setEmployeeCodeRunningNumber(props.selectedSchool.attributes.employeeCodeRunningNumber);

    //         setGst(props.selectedSchool.attributes.GSTIN);
    //         setPan(props.selectedSchool.attributes.pan);
    //         setCin(props.selectedSchool.attributes.cin);
    //         setFooter(props.selectedSchool.attributes.invoiceFooter)
    //         setResetInvoiceNumberForAY(props.selectedSchool.attributes.resetInvoiceNumberForAY ? true : false)
    //         if (props.selectedSchool.attributes.letterheadLogo) {
    //             setLetterHeadLogo({ imageURL: props.selectedSchool.attributes.letterheadLogo._url, actualImageName: "logo.jpeg" })
    //         }
    //     }
    // }, [props.selectedSchool, props.schoolId]);



    function handleAdharImageUploadChange(imageFile) {
        setAttachmentAdharUploadProgress(1); 

        let fileExtension = imageFile.name.split('.').pop().toLowerCase();

        let parseFile = new Parse.File("aadhar_card."+fileExtension, imageFile);

        parseFile.save({
            progress: (progress) => {
                console.log("progress:",progress)
                setAttachmentAdharUploadProgress(progress.percent);
            }
        }).then(
            (result) => {
                if (props.selectedTeacher) {
                    props.selectedTeacher.set('aadharDocument', result);
                    props.selectedTeacher.save().then(
                        (teacherResult) => {
                            setAttachmentAdhar({
                                actualFileName: "aadhar_card."+fileExtension,
                                fileURL: result.url()
                            });
                            setAttachmentAdharUploadProgress(100);
                        },
                        (error) => {
                            console.error('Error saving teacher:', error);
                        }
                    );
                }
            },
            (error) => {
                console.error('Error saving file:', error);
                setAttachmentAdharUploadProgress(0); 
            }
        );
    }

    function handlePanImageUploadChange(imageFile) {
        setAttachmentPanUploadProgress(1);

        let fileExtension = imageFile.name.split('.').pop().toLowerCase();

        let parseFile = new Parse.File("pan_card."+fileExtension, imageFile);

        parseFile.save({
            progress: (progress) => {
                setAttachmentPanUploadProgress(progress.percent);
            }
        }).then(
            (result) => {
                if (props.selectedTeacher) {
                    props.selectedTeacher.set('panDocument', result);
                    props.selectedTeacher.save().then(
                        (teacherResult) => {
                            setAttachmentPan({
                                actualFileName: "pan_card."+fileExtension,
                                fileURL: result.url()
                            });
                            setAttachmentPanUploadProgress(100);
                        },
                        (error) => {
                            console.error('Error saving teacher:', error);
                        }
                    );
                }
            },
            (error) => {
                console.error('Error saving file:', error);
                setAttachmentPanUploadProgress(0);
            }
        );
    }

    function handleClearAdhar() {
        var temp = props.selectedTeacher;
        temp.unSet("aadharDocument")
        props.editTeacher(temp);
        setAttachmentAdhar(null);
        setAttachmentAdharUploadProgress(0);
    }

    function handleClearPan() {
        var temp = props.selectedTeacher;
        temp.unset("panDocument")
        props.editTeacher(temp);
        setAttachmentPan(null);
        setAttachmentPanUploadProgress(0);
    }

    function handleClearQualification() {
      var temp = props.selectedTeacher;
      temp.unset("qualificationDocuments")
      props.editTeacher(temp);
      setAttachmentQualofication(null);
      setAttachmentQualoficationUploadProgress(0);
  }

    function handleQualificationImageUploadChange(imageFile) {
      setAttachmentQualoficationUploadProgress(1);

      let fileExtension = imageFile.name.split('.').pop().toLowerCase();

      let parseFile = new Parse.File("qualification_documents."+fileExtension, imageFile);

      parseFile.save({
          progress: (progress) => {
            setAttachmentQualoficationUploadProgress(progress.percent);
          }
      }).then(
          (result) => {
              if (props.selectedTeacher) {
                  props.selectedTeacher.set('qualificationDocuments', result);
                  props.selectedTeacher.save().then(
                      (teacherResult) => {
                          setAttachmentQualofication({
                              actualFileName: "qualification_documents."+fileExtension,
                              fileURL: result.url()
                          });
                          setAttachmentQualoficationUploadProgress(100);
                      },
                      (error) => {
                          console.error('Error saving teacher:', error);
                      }
                  );
              }
          },
          (error) => {
              console.error('Error saving file:', error);
              setAttachmentQualoficationUploadProgress(0);
          }
      );
  }


    return (
      <React.Fragment>
        <h5 className="mt-3">Details</h5>
        <div className="table-responsive m-2">
          <Table className="table mb-0 table-bordered">
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Permanent Address
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(1, value);
                    }}
                    disabled={false}
                    editText={address ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={address}
                    showText
                    type="textarea"
                    rows="3"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Address For Communication
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(13, value);
                    }}
                    disabled={false}
                    editText={communicationAddress ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={communicationAddress}
                    showText
                    type="textarea"
                    rows="3"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Nationality
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(2, value);
                    }}
                    disabled={false}
                    editText={nationality ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={nationality}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Religion
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(3, value);
                    }}
                    disabled={false}
                    editText={religion ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={religion}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Aadhar Number
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(4, value);
                    }}
                    disabled={false}
                    editText={adhar ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={adhar}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Father Name
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(6, value);
                    }}
                    disabled={false}
                    editText={father ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={father}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row">{props.t("Maritual Status")}</th>
                {!isMaritualStatusEditOn && (
                  <td>
                    {maritualStatus && maritualStatus.value}
                    <Label
                      onClick={() => {
                        setIsMaritualStatusEditOn(true);
                      }}
                    >
                      <p className="font-size-13 text-primary cursors move">
                        {maritualStatus && maritualStatus.value
                          ? " ✎"
                          : "No value ✎"}
                      </p>
                    </Label>
                  </td>
                )}
                {isMaritualStatusEditOn && (
                  <td>
                    <Label>{props.t("Maritual Status")}:</Label>
                    <FormGroup>
                      <Select
                        value={maritualStatus}
                        onChange={(v) => {
                          setMaritualStatus(v);
                        }}
                        options={maritualStats}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                    <button
                      type="button"
                      className="btn btn-success btn-sm "
                      style={{ width: "34px" }}
                      onClick={handleMaritualStatusChange}
                    >
                      <i className="fa fa-check"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm ml-2"
                      style={{ width: "34px" }}
                      onClick={() => {
                        let teacher = props.selectedTeacher;
                        if (teacher.attributes.maritalStatus) {
                          setMaritualStatus({
                            label: teacher.attributes.maritalStatus,
                            value: teacher.attributes.maritalStatus,
                          });
                        } else {
                          setMaritualStatus(undefined);
                        }
                        setIsMaritualStatusEditOn(false);
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
            {maritualStatus &&
              maritualStatus.value &&
              maritualStatus.value == "Married" && (
                <tbody>
                  <tr>
                    <th scope="row" style={{ width: "300px" }}>
                      Spouse Name
                    </th>
                    <td>
                      <Editable
                        className={"d-flex"}
                        alwaysEditing={false}
                        onSubmit={(value) => {
                          handleChange(7, value);
                        }}
                        disabled={false}
                        editText={spouseName ? " ✎" : "No value ✎"}
                        id={null}
                        isValueClickable={false}
                        mode="inline"
                        placement="top"
                        initialValue={spouseName}
                        showText
                        type="textfield"
                      />
                    </td>
                  </tr>
                </tbody>
              )}
              <tbody>
              <tr>
                <th scope="row">{props.t("Employee Type")}</th>
                {!isEmployeeTypeEditOn && (
                  <td>
                    {employeeType ? employeeType.value : ""}
                    <Label onClick={() => {setIsEmployeeTypeEditOn(true);}}
                    >
                      <p className="font-size-13 text-primary cursors move">
                        {employeeType && employeeType.value ? " ✎" : "No value ✎"}
                      </p>
                    </Label>
                  </td>
                )}
                {isEmployeeTypeEditOn && (
                  <td>
                    <Label>{props.t("Employye Type")}:</Label>
                    <FormGroup>
                      <Select
                        value={employeeType}
                        onChange={(v) => {
                          setEmployeeType(v);
                        }}
                        options={employeeTypeList}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                    <button
                      type="button"
                      className="btn btn-success btn-sm "
                      style={{ width: "34px" }}
                      onClick={handleEmployeeTypeChange}
                    >
                      <i className="fa fa-check"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm ml-2"
                      style={{ width: "34px" }}
                      onClick={() => {
                        let teacher = props.selectedTeacher;
                        if (teacher.attributes.employeeType) {
                          setEmployeeType({
                            label: teacher.attributes.employeeType,
                            value: teacher.attributes.employeeType,
                          });
                        } else {
                          setEmployeeType(undefined);
                        }
                        setIsEmployeeTypeEditOn(false);
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Qualification/Certification
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(21, value);
                    }}
                    disabled={false}
                    editText={qualification ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={qualification}
                    showText
                    type="textarea"
                    rows="3"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  College Details
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(14, value);
                    }}
                    disabled={false}
                    editText={collegeDetails ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={collegeDetails}
                    showText
                    type="textarea"
                    rows="3"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  About
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(22, value);
                    }}
                    disabled={false}
                    editText={about ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={about}
                    showText
                    type="textarea"
                    rows="3"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Notes/Comments about Staff
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(18, value);
                    }}
                    disabled={false}
                    editText={notes ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={notes}
                    showText
                    type="textarea"
                    rows="3"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Appointment Letter Sent?
                </th>
                {!isAppointmentLetterEditOn && (
                  <td>
                    {appointmentLetter ? appointmentLetter.label : ""}
                    <Label onClick={() => setIsAppointmentLetterEditOn(true)}>
                      <p className="font-size-13 text-primary cursors move">
                        {appointmentLetter && appointmentLetter.label ? " ✎" : "No value ✎"}
                      </p>
                    </Label>
                  </td>
                )}
                {isAppointmentLetterEditOn && (
                  <td>
                    <Label>{props.t("Appointment Letter Sent")}</Label>
                    <FormGroup>
                      <Select
                        value={appointmentLetter}
                        onChange={(v) => {
                          setAppointmentLetter(v);
                        }}
                        options={[
                          { value: "Yes", label: "Yes" },
                          { value: "No", label: "No" },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                    <button
                      type="button"
                      className="btn btn-success btn-sm"
                      style={{ width: "34px" }}
                      onClick={handleAppointmentLetterChange}
                    >
                      <i className="fa fa-check"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm ml-2"
                      style={{ width: "34px" }}
                      onClick={() => {
                        let teacher = props.selectedTeacher;
                        if (teacher.attributes.appointmentLetter) {
                          setAppointmentLetter({
                            label: teacher.attributes.appointmentLetter,
                            value: teacher.attributes.appointmentLetter,
                          });
                        } else {
                          setAppointmentLetter(undefined);
                        }
                        setIsAppointmentLetterEditOn(false);
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
            <tbody>
            <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Working Days
                </th>
                <td>
                <Col xs={6}>
                      <FormGroup>
                        <Label>
                          {props.t("Select Days")} :
                        </Label>
                        <Select
                          value={selectedDays}
                          isMulti={true}
                          onChange={(v) => {
                            handleMulti(v)
                          }}
                          options={allDaysOptions}
                        />
                      </FormGroup>
                    </Col>
                </td>
            </tr>
            </tbody>
            <tbody>
            <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Check In Time
                </th>
                <td>
                <Col xs={6}>
                <DatePicker
                    selected={checkInTime}
                    onChange={(date) => setCheckInTime(date)}
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                />
                </Col>
                </td>
            </tr>
            </tbody>
            <tbody>
            <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Check Out Time
                </th>
                <td>
                <Col xs={6}>
                <DatePicker
                    selected={checkOutTime}
                    onChange={(date) => setCheckOutTime(date)}
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                />
                </Col>
                <Button color="info" className='mt-2' style={{marginLeft: '13cm'}} onClick={() => handleWorkingDays()}>Save</Button>
                </td>
            </tr>
            </tbody>
          </Table>
        </div>
        <hr />
        <h5 className="mt-3">Employee Financial Details</h5>
        <div className="table-responsive m-2">
          <Table className="table mb-0 table-bordered">
          <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Initial Salary
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(15, value);
                    }}
                    disabled={false}
                    editText={initialSalary ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={initialSalary}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Current Salary
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(19, value);
                    }}
                    disabled={false}
                    editText={currentSalary ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={currentSalary}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Account Number
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(8, value);
                    }}
                    disabled={false}
                    editText={acNumber ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={acNumber}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Name as per bank
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(9, value);
                    }}
                    disabled={false}
                    editText={asPerName ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={asPerName}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Bank Name
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(10, value);
                    }}
                    disabled={false}
                    editText={bankName ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={bankName}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  IFSC
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(11, value);
                    }}
                    disabled={false}
                    editText={ifsc ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={ifsc}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Branch Name
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(12, value);
                    }}
                    disabled={false}
                    editText={branchName ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={branchName}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  PAN
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(5, value);
                    }}
                    disabled={false}
                    editText={pan ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={pan}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  UAN Number
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(20, value);
                    }}
                    disabled={false}
                    editText={uan ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={uan}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <hr />
        <h5 className="mt-3">Documents</h5>
        <div className="table-responsive m-2">
          <Table className="table mb-0 table-bordered">
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Aadhar Card
                </th>
                <td>
                  {attachmentAdhar && (
                    <Col xl="12" md="12" sm="12" xs="12">
                      <div className="border p-3">
                                            <Row>
                                                <Col xl="2" md="2" sm="2" xs="2">
                                                    <div
                                                        className="avatar ml-3"
                                                        style={{ width: "5rem", height: "5rem" }}
                                                    >
                                                        <span
                                                        className="avatar-title bg-white ml-0 text-primary h1"
                                                        style={{ fontSize: "60px" }}
                                                        >
                                                        <i className="fa fa-file"></i>
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col xl="10" md="10" sm="10" xs="10">
                                                    <div className="float-right ml-4">
                                                        <div
                                                        className="text-muted font-weight-bold text-truncate"
                                                        style={{ width: "200px" }}
                                                        >
                                                        {attachmentAdhar.actualFileName}
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                        <Button
                                                            className="btn btn-primary float-right m-1"
                                                            color="primary"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            onClick={() => {
                                                            window.open(
                                                                attachmentAdhar.fileURL,
                                                                "_blank"
                                                            );
                                                            }}
                                                        >
                                                            Open
                                                        </Button>
                                                        <Button
                                                            className="btn btn-danger float-right m-1"
                                                            color="danger"
                                                            onClick={handleClearAdhar}
                                                        >
                                                            Clear
                                                            </Button>
                                                            <Label
                                                                htmlFor="input-file-adhar-r"
                                                                className="btn btn-primary m-1"
                                                                >
                                                                Update Adhar Card
                                                                <input
                                                                    className="btn btn-primary"
                                                                    id="input-file-adhar-r"
                                                                    style={{ display: "none" }}
                                                                    type="file"
                                                                    onChange={(e) => {
                                                                    handleAdharImageUploadChange(e.target.files[0]);
                                                                    }}
                                                                    accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                                                                />
                                                                </Label>
                                                        </div>
                                                    </div>

                                                </Col>
                         

                          
                        </Row>
                      </div>
                    </Col>
                  )}

                  {attachmentAdharUploadProgress > 0 &&
                    attachmentAdharUploadProgress < 100 && (
                      <div>Uploading... {attachmentAdharUploadProgress}%</div>
                    )}

                  {(!attachmentAdhar)&& (
                    <Label
                      htmlFor="input-file-adhar"
                      className="btn btn-primary btn-sm"
                    >
                      Upload Adhar Card
                      <input
                        className="btn btn-primary"
                        id="input-file-adhar"
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => {
                          handleAdharImageUploadChange(e.target.files[0]);
                        }}
                        accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                      />
                    </Label>
                  )}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  PAN
                </th>
                <td>
                  {attachmentPan && (
                    <Col xl="12" md="12" sm="12" xs="12">
                      <div className="border p-3">
                            <Row>
                                <Col xl="2" md="2" sm="2" xs="2">
                                    <div
                                        className="avatar ml-3"
                                        style={{ width: "5rem", height: "5rem" }}
                                    >
                                        <span
                                        className="avatar-title bg-white ml-0 text-primary h1"
                                        style={{ fontSize: "60px" }}
                                        >
                                        <i className="fa fa-file"></i>
                                        </span>
                                    </div>
                                
                                </Col>

                                <Col xl="10" md="10" sm="10" xs="10">
                                    <div className="float-right ml-4">
                                        <div
                                        className="text-muted font-weight-bold text-truncate"
                                        style={{ width: "200px" }}
                                        >
                                        {attachmentPan.actualFileName}
                                        </div>
                                        <div className="d-flex mt-3">
                                        <Button
                                            className="btn btn-primary float-right m-1"
                                            color="primary"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={() => {
                                            window.open(attachmentPan.fileURL, "_blank");
                                            }}
                                        >
                                            Open
                                        </Button>
                                        <Button
                                            className="btn btn-danger float-right m-1"
                                            color="danger"
                                            onClick={handleClearPan}
                                        >
                                            Clear
                                        </Button>
                                                            
                                        <Label
                                            htmlFor="input-file-pan-r"
                                            className="btn btn-primary m-1"
                                            >
                                            Update PAN Card
                                            <input
                                                id="input-file-pan-r"
                                                style={{ display: "none" }}
                                                type="file"
                                                onChange={(e) => {
                                                handlePanImageUploadChange(e.target.files[0]);
                                                }}
                                                accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                                            />
                                            </Label>
                                        </div>
                                    </div>
                                
                                </Col>
                            </Row>
                      </div>
                    </Col>
                  )}

                  {attachmentPanUploadProgress > 0 &&
                    attachmentPanUploadProgress < 100 && (
                      <div>Uploading... {attachmentPanUploadProgress}%</div>
                    )}

                  {(!attachmentPan ) && (
                    <Label
                      htmlFor="input-file-pan"
                      className="btn btn-primary btn-sm"
                    >
                      Upload PAN Card
                      <input
                        id="input-file-pan"
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => {
                          handlePanImageUploadChange(e.target.files[0]);
                        }}
                        accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                      />
                    </Label>
                  )}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Qualification Documents
                </th>
                <td>
                  {attachmentQualofication && (
                    <Col xl="12" md="12" sm="12" xs="12">
                      <div className="border p-3">
                            <Row>
                                <Col xl="2" md="2" sm="2" xs="2">
                                    <div
                                        className="avatar ml-3"
                                        style={{ width: "5rem", height: "5rem" }}
                                    >
                                        <span
                                        className="avatar-title bg-white ml-0 text-primary h1"
                                        style={{ fontSize: "60px" }}
                                        >
                                        <i className="fa fa-file"></i>
                                        </span>
                                    </div>
                                
                                </Col>

                                <Col xl="10" md="10" sm="10" xs="10">
                                    <div className="float-right ml-4">
                                        <div
                                        className="text-muted font-weight-bold text-truncate"
                                        style={{ width: "200px" }}
                                        >
                                        {attachmentQualofication.actualFileName}
                                        </div>
                                        <div className="d-flex mt-3">
                                        <Button
                                            className="btn btn-primary float-right m-1"
                                            color="primary"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={() => {
                                            window.open(attachmentQualofication.fileURL, "_blank");
                                            }}
                                        >
                                            Open
                                        </Button>
                                        <Button
                                            className="btn btn-danger float-right m-1"
                                            color="danger"
                                            onClick={handleClearQualification}
                                        >
                                            Clear
                                        </Button>
                                                            
                                        <Label
                                            htmlFor="input-file-qualification-r"
                                            className="btn btn-primary m-1"
                                            >
                                            Update Qualification Documents
                                            <input
                                                id="input-file-qualification-r"
                                                style={{ display: "none" }}
                                                type="file"
                                                onChange={(e) => {
                                                  handleQualificationImageUploadChange(e.target.files[0]);
                                                }}
                                                accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                                            />
                                            </Label>
                                        </div>
                                    </div>
                                
                                </Col>
                            </Row>
                      </div>
                    </Col>
                  )}

                  {attachmentQualoficationUploadProgress > 0 &&
                    attachmentQualoficationUploadProgress < 100 && (
                      <div>Uploading... {attachmentQualoficationUploadProgress}%</div>
                    )}

                  {(!attachmentQualofication ) && (
                    <Label
                      htmlFor="input-qualification-pan"
                      className="btn btn-primary btn-sm"
                    >
                      Upload Qualification Documents
                      <input
                        id="input-qualification-pan"
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => {
                          handleQualificationImageUploadChange(e.target.files[0]);
                        }}
                        accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                      />
                    </Label>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <h5 className="mt-3">Staff Exit Details</h5>
        <div className="table-responsive m-2">
          <Table className="table mb-0 table-bordered">
          <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Notice Period
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(16, value);
                    }}
                    disabled={false}
                    editText={noticePeriod ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={noticePeriod}
                    showText
                    type="textarea"
                    rows="3"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  {props.t("Notice Period Status")}
                </th>
                {!isNoticePeriodEditOn && (
                  <td>
                    {noticePeriodStatus ? noticePeriodStatus.label : ""}
                    <Label onClick={() => { setIsNoticePeriodEdit(true); }}>
                      <p className="font-size-13 text-primary cursors move">
                        {noticePeriodStatus && noticePeriodStatus.label ? " ✎" : "No value ✎"}
                      </p>
                    </Label>
                  </td>
                )}
                {isNoticePeriodEditOn && (
                  <td>
                    <Label>{props.t("Notice Period")}</Label>
                    <FormGroup>
                      <Select
                        value={noticePeriodStatus}
                        onChange={(v) => {
                          setNoticePeriodStatus(v);
                        }}
                        options={[
                          { value: "Yes", label: "Yes" },
                          { value: "No", label: "No" },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                    <button
                      type="button"
                      className="btn btn-success btn-sm"
                      style={{ width: "34px" }}
                      onClick={handleNoticePeriod}
                    >
                      <i className="fa fa-check"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm ml-2"
                      style={{ width: "34px" }}
                      onClick={() => {
                        let teacher = props.selectedTeacher;
                        if (teacher.attributes.noticePeriodStatus) {
                          setNoticePeriodStatus({
                            label: teacher.attributes.noticePeriodStatus,
                            value: teacher.attributes.noticePeriodStatus,
                          });
                        } else {
                          setNoticePeriodStatus(undefined);
                        }
                        setIsNoticePeriodEdit(false);
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Reason for leaving
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(17, value);
                    }}
                    disabled={false}
                    editText={exitReason ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={exitReason}
                    showText
                    type="textarea"
                    rows="3"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  All School Related Dues/Resources Collected?
                </th>
                {!isDuesEditOn && (
                  <td>
                    {dues ? dues.label : ""}
                    <Label onClick={() => setIsDuesEdit(true)}>
                      <p className="font-size-13 text-primary cursors move">
                        {dues && dues.label ? " ✎" : "No value ✎"}
                      </p>
                    </Label>
                  </td>
                )}
                {isDuesEditOn && (
                  <td>
                    <Label>{props.t("Dues Provided")}</Label>
                    <FormGroup>
                      <Select
                        value={dues}
                        onChange={(v) => {
                          setDues(v);
                        }}
                        options={[
                          { value: "Yes", label: "Yes" },
                          { value: "No", label: "No" },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                    <button
                      type="button"
                      className="btn btn-success btn-sm"
                      style={{ width: "34px" }}
                      onClick={handleDuesChange}
                    >
                      <i className="fa fa-check"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm ml-2"
                      style={{ width: "34px" }}
                      onClick={() => {
                        let teacher = props.selectedTeacher;
                        if (teacher.attributes.dues) {
                          setDues({
                            label: teacher.attributes.dues,
                            value: teacher.attributes.dues,
                          });
                        } else {
                          setDues(undefined);
                        }
                        setIsDuesEdit(false);
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row">
                  {props.t("Last Appraisal Done On (Month and Year)")}
                </th>

                <td>
                  <Editable
                    className={'d-flex'}
                    alwaysEditing={false}
                    onSubmit={handleAppriasalDateChange}
                    onCancel={() => {
                      props.selectedTeacher &&
                        setAppriasalDate(
                          props.selectedTeacher.attributes.appriasalDate
                        );
                    }}
                    editText={appriasalDate? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={appriasalDate ?  appriasalDate : ""}
                    showText
                    type="date"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Experience Letter Sent?
                </th>
                {!isExperienceLetterEditOn && (
                  <td>
                    {experienceLetter ? experienceLetter.label : ""}
                    <Label onClick={() => setIsExperienceLetterEditOn(true)}>
                      <p className="font-size-13 text-primary cursors move">
                        {experienceLetter && experienceLetter.label ? " ✎" : "No value ✎"}
                      </p>
                    </Label>
                  </td>
                )}
                {isExperienceLetterEditOn && (
                  <td>
                    <Label>{props.t("Experience Letter Status")}</Label>
                    <FormGroup>
                      <Select
                        value={experienceLetter}
                        onChange={(v) => {
                          setExperienceLetter(v);
                        }}
                        options={[
                          { value: "Yes", label: "Yes" },
                          { value: "No", label: "No" },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                    <button
                      type="button"
                      className="btn btn-success btn-sm"
                      style={{ width: "34px" }}
                      onClick={handleExperienceLetterChange}
                    >
                      <i className="fa fa-check"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm ml-2"
                      style={{ width: "34px" }}
                      onClick={() => {
                        let teacher = props.selectedTeacher;
                        if (teacher.attributes.experienceLetter) {
                          setExperienceLetter({
                            label: teacher.attributes.experienceLetter,
                            value: teacher.attributes.experienceLetter,
                          });
                        } else {
                          setExperienceLetter(undefined);
                        }
                        setIsExperienceLetterEditOn(false);
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
          </div>
      </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const {selectedTeacher, } = state.Teacher;
    return { selectedSchool,selectedTeacher };
}
export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    editTeacher
})(OtherDetails)));