import React, { useState, useEffect } from 'react';
import { Col, Row, Card, CardTitle, CardBody, Nav, NavItem, NavLink, Media, TabPane, TabContent, CardText, Modal, ModalBody, ModalHeader, ModalFooter, Label, FormGroup, Button, Badge, CardImg } from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Switch from "react-switch";
import Editable from "react-bootstrap-editable";
import classnames from 'classnames';
import Parse from 'parse';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import QRCode from 'qrcode'
import * as Constants from '../../Constents';
import * as CommonFunctions from "../../components/Common/CommonFunctions";
import { getEnquiryQuestionForSchoolId, deleteEnquiryQuestion, addEnquiryQuestion, editEnquiryQuestion } from "../../store/actions";
import * as WhiteLable from "../../components/Common/WhiteLable";
const EnquiryQuestions = (props) => {

    var schoolId = props.schoolId;
    const ENQUIRY_FORM = WhiteLable.getHost() + "/enquiry-form/" + schoolId;
    const ENQUIRY_APP = "https://play.google.com/store/apps/details?id=com.liltriangle.enquiry";

    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(undefined);
    const [activeTab, setactiveTab] = useState("1");
    const [switchTab, setSwitchTab] = useState(true);
    const [addQuestionPopup, showAddQuestionPopup] = useState(false);
    const [formQRCode, setFormQRCode] = useState(undefined);
    const [appQRCode, setAppQRCode] = useState(undefined);


    const [allQuestionList, setAllQuestionList] = useState([
        {
            id: "1",
            question: "Student Name",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "kidName",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 1,
        },
        {
            id: "32",
            question: "Student profile pic",
            questionType: Constants.QESTION_UPLOAD_IMAGE,
            choice: [],
            dataType: "FILE",
            databaseFieldName: "kidPhoto",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 1.1,
        },
        {
            id: "2",
            question: "Student Date of Birth",
            questionType: Constants.QESTION_DATE,
            choice: [],
            dataType: "DATE",
            databaseFieldName: "dateOfBirth",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 2,
        },
        {
            id: "3",
            question: "Gender",
            questionType: Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST,
            choice: ["Male", "Female"],
            dataType: "STRING",
            databaseFieldName: "kidGender",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 3,
        },
        {
            id: "4",
            question: "Father Name",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "Name",
            databaseTableName: "Parent",
            objectType: 2,
            order: 4,
        },
        {
            id: "33",
            question: "Father profile pic",
            questionType: Constants.QESTION_UPLOAD_IMAGE,
            choice: [],
            dataType: "FILE",
            databaseFieldName: "Photo",
            databaseTableName: "Parent",
            objectType: 2,
            order: 4.1,
        },
        {
            id: "5",
            question: "Father Phone Number",
            questionType: Constants.QUESTION_PHONE_NUMBER,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "PhoneNumber",
            databaseTableName: "Parent",
            objectType: 2,
            order: 5,
        },
        {
            id: "6",
            question: "Father Email",
            questionType: Constants.QUESTION_EMAIL,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "EMail",
            databaseTableName: "Parent",
            objectType: 2,
            order: 6,
        }, {
            id: "7",
            question: "Father Occupation",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "occupation",
            databaseTableName: "Parent",
            objectType: 2,
            order: 7,
        },
        {
            id: "71",
            question: "Father Designation ",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "designation",
            databaseTableName: "Parent",
            objectType: 2,
            order: 7.1,
        },

        {
            id: "8",
            question: "Father Qualification ",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "qualification",
            databaseTableName: "Parent",
            objectType: 2,
            order: 8,
        }

        , {
            id: "9",
            question: "Father Workplace Name",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "companyName",
            databaseTableName: "Parent",
            objectType: 2,
            order: 9,
        },
        {
            id: "10",
            question: "Mother Name",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "Name",
            databaseTableName: "Parent",
            objectType: 3,
            order: 10,
        },
        {
            id: "34",
            question: "Mother profile pic",
            questionType: Constants.QESTION_UPLOAD_IMAGE,
            choice: [],
            dataType: "FILE",
            databaseFieldName: "Photo",
            databaseTableName: "Parent",
            objectType: 3,
            order: 10.1,
        },
        {
            id: "11",
            question: "Mother Phone Number",
            questionType: Constants.QUESTION_PHONE_NUMBER,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "PhoneNumber",
            databaseTableName: "Parent",
            objectType: 3,
            order: 11,
        },
        {
            id: "12",
            question: "Mother Email",
            questionType: Constants.QUESTION_EMAIL,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "EMail",
            databaseTableName: "Parent",
            objectType: 3,
            order: 12,
        }, {
            id: "13",
            question: "Mother Occupation ",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "occupation",
            databaseTableName: "Parent",
            objectType: 3,
            order: 13,
        },
        {
            id: "131",
            question: "Mother Designation ",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "designation",
            databaseTableName: "Parent",
            objectType: 3,
            order: 13.1,
        },
        {
            id: "14",
            question: "Mother Qualification ",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "qualification",
            databaseTableName: "Parent",
            objectType: 3,
            order: 14,
        }

        , {
            id: "15",
            question: "Mother Workplace Name",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "companyName",
            databaseTableName: "Parent",
            objectType: 3,
            order: 15,
        }, {
            id: "38",
            question: "Enquired on",
            questionType: Constants.QESTION_DATE,
            choice: [],
            dataType: "DATE",
            databaseFieldName: "enquiryDate",
            databaseTableName: "Enquiry",
            objectType: 7,
            order: 15.5,
        },
        {
            id: "16",
            question: "How did you find out about us?",
            questionType: Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST,
            choice: ["Employee Referral", "Parent Referral", "Events", "Advertisement", "Cold Call", "Online Advertisement", "Email Campaign", "Facebook", "Pole Advertisement", "Reference", "Newspaper", "Banners", "Website", "Others"],
            dataType: "STRING",
            databaseFieldName: "source",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 16,
        },
        {
            id: "17",
            question: "Program You are enquiring for ",
            questionType: Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST,
            choice: ["Playgroup", "Nursery", "LKG", "UKG", "Daycare", "Summer Camp"],
            dataType: "ARRAY",
            databaseFieldName: "enquiryFor",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 17,
        },
        {
            id: "18",
            question: "Admission for Year",
            questionType: Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST,
            choice: ["2021-2022", "2022-2023", "2023-2024"],
            dataType: "STRING",
            databaseFieldName: "year",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 18,
        }, {
            id: "19",
            question: "Additional Remarks ",
            questionType: Constants.QESTION_TEXT_MULTI_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "additionalNote",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 19,
        },

        {
            id: "20",
            question: "How many Siblings",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "noofSiblings",
            databaseTableName: "ChildAdditionalDetails",
            objectType: 1,
            order: 20,
        }, {
            id: "21",
            question: "Siblings Name and Age details",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "siblingDetails",
            databaseTableName: "ChildAdditionalDetails",
            objectType: 1,
            order: 21,
        },

        {
            id: "22",
            question: "Emergency contact person name ",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "emergencyContactName",
            databaseTableName: "ChildAdditionalDetails",
            objectType: 1,
            order: 22,
        },
        {
            id: "23",
            question: "Emergency contact person Phone number",
            questionType: Constants.QUESTION_PHONE_NUMBER,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "emergencyContactNumber",
            databaseTableName: "ChildAdditionalDetails",
            objectType: 1,
            order: 23,
        },

        {
            id: "24",
            question: "Residential Address for communication",
            questionType: Constants.QESTION_TEXT_MULTI_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "residentialAddress",
            databaseTableName: "ChildAdditionalDetails",
            objectType: 1,
            order: 24,
        },
        {
            id: "25",
            question: "Shift preference",
            questionType: Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST,
            choice: ["A Shift - Morning Shift", "B Shift - Mid Morning Shift", "C Shift - Afternoon Shift"],
            dataType: "STRING",
            databaseFieldName: "batch",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 25,
        },
        {
            id: "26",
            question: "Place of birth",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "birthPlace",
            databaseTableName: "ChildAdditionalDetails",
            objectType: 1,
            order: 26,
        },


        {
            id: "27",
            question: "Do you require van facility?",
            questionType: Constants.QESTION_YES_NO_NO_COMMENTS,
            choice: [],
            dataType: "BOOLEAN",
            databaseFieldName: "transportFacility",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 27,
        },
        {
            id: "28",
            question: "Quoted Fee",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "feeQuoted",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 28,
        },
        {
            id: "29",
            question: "Daycare Timings",
            questionType: Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST,
            choice: ["5AM -8AM", "8AM -11AM", "11AM-2PM", "2PM-5PM", "5PM-8PM", "8PM-12AM", "12AM-5AM"],
            dataType: "ARRAY",
            databaseFieldName: "daycareTimePreference",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 29,
        },
        // {
        //     id: "30",
        //     question: "Has your child attended playgroup/nursery/daycare before?",
        //     questionType: 10,
        //     choice: [],
        //     dataType: "STRING",
        //     databaseFieldName: "previousSchool",
        //     databaseTableName: "Enquiry",
        //     objectType: 1,
        //     order: 30,
        // },
        {
            id: "31",
            question: "Previous school name",
            questionType: Constants.QESTION_TEXT_SINGLE_LINE,
            choice: [],
            dataType: "STRING",
            databaseFieldName: "previousSchool",
            databaseTableName: "Enquiry",
            objectType: 1,
            order: 31,
        },



        {
            id: "35",
            question: "Student Aadhar Card",
            questionType: Constants.QESTION_UPLOAD_DOCUMENT,
            choice: [],
            dataType: "FILE",
            databaseFieldName: "aadharCard",
            databaseTableName: "ChildAdditionalDetails",
            objectType: 1,
            order: 35,
        },
        {
            id: "36",
            question: "Transfer Certificate",
            questionType: Constants.QESTION_UPLOAD_DOCUMENT,
            choice: [],
            dataType: "FILE",
            databaseFieldName: "transferCertificate",
            databaseTableName: "ChildAdditionalDetails",
            objectType: 1,
            order: 36,
        },
        {
            id: "37",
            question: "Birth Certificate",
            questionType: Constants.QESTION_UPLOAD_DOCUMENT,
            choice: [],
            dataType: "FILE",
            databaseFieldName: "birthCertificate",
            databaseTableName: "ChildAdditionalDetails",
            objectType: 1,
            order: 37,
        },
    ]);

    useEffect(() => {
        if (props.showSetUp) {
            setactiveTab("1")
        } else {
            setactiveTab("3")
        }
    }, [props.showSetUp]);

    useEffect(() => {
        props.getEnquiryQuestionForSchoolId(schoolId);
        formQr();
        appQr();
    }, [schoolId]);

    function formQr() {
        QRCode.toDataURL(ENQUIRY_FORM)
            .then(url => {
                setFormQRCode(url)
            })
            .catch(err => {
                console.error(err)
            })
    }
    function appQr() {
        QRCode.toDataURL(ENQUIRY_APP)
            .then(url => {
                setAppQRCode(url)
            })
            .catch(err => {
                console.error(err)
            })
    }

    useEffect(() => {
        if (props.enquiryQuestions && props.enquiryQuestions.length > 0) {



            var list = [];
            var tempAllQuestion = [...allQuestionList];
            for (const i in props.enquiryQuestions) {

                var temp = allQuestionList.filter((question) => (
                    props.enquiryQuestions[i].attributes.databaseFieldName === question.databaseFieldName &&
                    props.enquiryQuestions[i].attributes.databaseTableName === question.databaseTableName &&
                    props.enquiryQuestions[i].attributes.objectType === question.objectType

                ));


                if (temp && temp.length > 0) {
                    tempAllQuestion[allQuestionList.indexOf(temp[0])].questionObject = props.enquiryQuestions[i];
                    list.push(temp[0].id);
                }

            }
            setAllQuestionList(tempAllQuestion);
            setSelectedQuestions(list);
            if (switchTab) {
                setactiveTab("1");
            }

        } else {
            setactiveTab("2");
        }
    }, [props.enquiryQuestions]);



    function onSelectQustion(question) {

        if (selectedQuestions.length > 0) {

            if (selectedQuestions.includes(question.id) > 0) {
                setSelectedQuestion(question.questionObject)
            }
            // else if (question.questionObject) {
            //     setSelectedQuestion(question.questionObject)
            // }
            else {

                // showAddQuestionPopup(true);
                // setNewQuestion(question)
                handelAddQuestion(question);
            }

        } else {
            // showAddQuestionPopup(true);
            // setNewQuestion(question)
            handelAddQuestion(question);
        }

    }


    function handelAddQuestion(newQuestion) {

        var list = [...selectedQuestions];
        list.push(newQuestion.id);
        setSelectedQuestions(list);


        if (newQuestion && newQuestion.questionObject) {

            var object = newQuestion.questionObject;
            object.set("isDeleted", false);
            props.addEnquiryQuestion(object)
        } else {
            const EnquiryQuestion = Parse.Object.extend("EnquiryQuestions");
            var enquiryQuestion = new EnquiryQuestion();
            enquiryQuestion.set("databaseFieldName", newQuestion.databaseFieldName);
            enquiryQuestion.set("databaseTableName", newQuestion.databaseTableName);
            enquiryQuestion.set("objectType", newQuestion.objectType);
            enquiryQuestion.set("question", newQuestion.question);
            enquiryQuestion.set("order", newQuestion.order);
            enquiryQuestion.set("questionType", newQuestion.questionType);

            if (newQuestion.databaseFieldName === "kidName") {
                enquiryQuestion.set("isMandatory", true);
            }

            enquiryQuestion.set("dataType", newQuestion.dataType);
            enquiryQuestion.set("choice", newQuestion.choice);

            enquiryQuestion.set("schoolId", schoolId);

            props.addEnquiryQuestion(enquiryQuestion)
        }
        setSwitchTab(false);

        showAddQuestionPopup(false);

    }




    function handelRemoveQuestion() {

        var temp = allQuestionList.filter((question) => (
            question.databaseFieldName === selectedQuestion.attributes.databaseFieldName &&
            question.databaseTableName === selectedQuestion.attributes.databaseTableName &&
            question.objectType === selectedQuestion.attributes.objectType

        ));

        if (temp && temp.length > 0) {
            var others = selectedQuestions.filter(
                (enquiryquestion) =>
                    enquiryquestion !== temp[0].id

            );

            setSelectedQuestions(others);
        }
        props.deleteEnquiryQuestion(selectedQuestion);
        setSelectedQuestion(undefined);
    }
    function getSelectedQuestions() {
        var temp = allQuestionList.filter(
            (enquiryquestion) => (selectedQuestions.includes(enquiryquestion.id)));

        return temp;
    }
    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            > No
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            > Yes
            </div>
        );
    };


    function handelEditQuestion() {
        var questionObject = editQuestionObject;
        questionObject.set("question", editQuestion);
        questionObject.set("isMandatory", isMandatory);
        if ((editQuestionObject.attributes.questionType === 5 || editQuestionObject.attributes.questionType === 6) && (editQuestionObject.attributes.databaseFieldName !== "kidGender")) {
            questionObject.set("choice", tags);
        }
        props.editEnquiryQuestion(questionObject);

        setEditQuestionObject(undefined);
        setEditQuestion(false);
        setIsMandatory(undefined);
        setTags([]);
        setAddNewTags(false);
        setNewTag("");
        setQuestionError(false);
        setTagError(false);
    }

    const [editQuestionObject, setEditQuestionObject] = useState(undefined)
    const [editQuestion, setEditQuestion] = useState("")
    const [isMandatory, setIsMandatory] = useState(undefined)
    const [tags, setTags] = useState([])
    const [addNewTags, setAddNewTags] = useState(false);
    const [newTag, setNewTag] = useState("");

    const [questionError, setQuestionError] = useState(false);
    const [tagError, setTagError] = useState(false);

    function getQuestionEditView(question) {
        return (
            <div>

                <AvForm className="outer-repeater" >
                    <div data-repeater-item className="outer">
                        <FormGroup >
                            <Label htmlFor="formname">{props.t('Question')}: </Label>
                            <AvField
                                type="text"
                                name="name"
                                placeholder={props.t('Enter Question...')}
                                className="form-control"
                                value={editQuestion}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: props.t('Enter Question...'),
                                    },
                                }}
                                onChange={(e) => {
                                    if (e.target.value.length > 0) {
                                        setQuestionError(false);
                                    }
                                    setEditQuestion(e.target.value);
                                }}
                            />
                            {questionError && <p className="text-danger">{props.t('Question can not be empty')}</p>}
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="formname">{props.t('Mandatory')}: </Label>
                            <Switch
                                className="ml-3"
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                onColor="#626ed4"
                                onChange={() => { setIsMandatory(!isMandatory) }}
                                checked={isMandatory}
                            />
                        </FormGroup>

                        <p>{CommonFunctions.getQuesionDataType(question.attributes.dataType, question.attributes.questionType, question.attributes.databaseFieldName, props)}</p>

                        {(question.attributes.questionType === 5 || question.attributes.questionType === 6) && (question.attributes.databaseFieldName !== "kidGender") && <FormGroup>
                            <Label htmlFor="formname">{props.t('Tags')}: </Label>
                            {tags.map((option, key) =>

                                <Badge key={key} className={"badge badge-soft-primary font-size-12 m-2"}>{option.length > 20 ? option.slice(0, 19) + "..." : option}

                                    <i className="mdi mdi-window-close ml-1" id={option}
                                        onClick={(e) => {
                                            var others = tags.filter((tag) => (tag !== e.target.id))
                                            setTags(others);
                                        }}
                                    > </i>

                                </Badge>

                            )}
                            {!addNewTags && <Badge className={"badge badge-soft-success font-size-12 m-2"}
                                onClick={() => {
                                    setNewTag("");
                                    setAddNewTags(true);

                                }}>{props.t('Add')} <i className="bx bx-plus ml-1"  > </i>
                            </Badge>}

                            {addNewTags && <Badge
                                className={"badge badge-soft-danger font-size-12 m-2"}
                                onClick={() => {
                                    setNewTag("");
                                    setAddNewTags(false);
                                }}
                            >
                                {props.t("Close")} <i className="mdi mdi-window-close ml-1"> </i>
                            </Badge>
                            }

                            {tagError && <p className="text-danger">{props.t('Tags can not be empty')}</p>}

                        </FormGroup>}

                        {addNewTags && <FormGroup> <Editable
                            className="me-5 ml-5"
                            alwaysEditing={true}
                            onValidated={(value) => {
                                var temp = [...tags];
                                var res = value.split(",");
                                var newList = temp.concat(res);
                                if (newList.length > 0) {
                                    setTagError(false);
                                }
                                setTags(newList);
                                setNewTag("");
                                setAddNewTags(false);
                            }}
                            onCancel={() => {
                                setAddNewTags(false);
                            }}
                            disabled={false}
                            editText={newTag ? " ✎" : "No value ✎"}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            initialValue={newTag}
                            showText
                            type="textfield"
                            placeholder="Type here"
                            validate={(value) => {
                                if (!(value && value.length > 0)) {
                                    return props.t("Tag Can not be empty ")
                                } else if (tags.includes(value)) {
                                    return props.t("This Tag is already added")
                                }
                            }}
                        />
                        </FormGroup>
                        }
                    </div>

                </AvForm>

            </div>

        );
    }




    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="checkout-tabs">
                        <Row>
                            <Col lg="2">
                                <Nav className="flex-column" pills>
                                    {props.showSetUp && <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { setactiveTab("1") }}
                                        >
                                            <i className="bx bx-clipboard d-block check-nav-icon mt-4 mb-2"></i>
                                            <p className="font-weight-bold mb-4">{props.t('School Enquiry Questions')}</p>
                                        </NavLink>
                                    </NavItem>}
                                    {props.showSetUp && <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { setactiveTab("2") }}
                                        >
                                            <i className="bx bx-check-shield d-block check-nav-icon mt-4 mb-2"></i>
                                            <p className="font-weight-bold mb-4">{props.t('General Enquiry Questions')}</p>
                                        </NavLink>
                                    </NavItem>}
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => { setactiveTab("3") }}
                                        >
                                            <i className="mdi-qrcode-scan d-block check-nav-icon mt-4 mb-2"></i>
                                            <p className="font-weight-bold mb-4">{props.t(' Enquiry Poster')}</p>
                                        </NavLink>
                                    </NavItem>

                                </Nav>
                            </Col>
                            <Col lg="10">
                                <Card>
                                    <CardBody>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">
                                                <CardTitle className="mb-2">{props.t('School Enquiry Questions')}</CardTitle>
                                                {
                                                    getSelectedQuestions().length > 0 &&

                                                    <CardText className="mb-2">{props.t('Please un select the question which you want to remove from your school enquiry form')}</CardText>
                                                }

                                                {/* <table className="table table-nowrap table-centered mb-0">
                                                    <tbody className="justify-content-center"> */}
                                                {
                                                    props.enquiryQuestions && props.enquiryQuestions.map((question) => (
                                                        <Row key={question.id} className="border-bottom justify-content-center" >
                                                            <Col lg={1} className="mt-3 mb-2">

                                                                <div className="custom-control custom-checkbox mb-2 ml-3" >
                                                                    <input type="checkbox" className="custom-control-input" id={question.id} onChange={() => { setSelectedQuestion(question) }} checked={true} />
                                                                    <label className="custom-control-label" onClick={() => { setSelectedQuestion(question); }} ></label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={10} className="mt-3 mb-2">
                                                                {editQuestionObject && question.id === editQuestionObject.id ?
                                                                    <div>{getQuestionEditView(question)}</div> :
                                                                    <div>
                                                                        <h5 className="text-truncate font-size-14 m-0"><Link to="#" className="text-dark">{question.attributes.question}</Link></h5>
                                                                        <p className="m-0"> {props.t('Mandatory Question') + ": "}{question.attributes.isMandatory ? props.t('Yes') : props.t('NO')}</p>
                                                                        {(question.attributes.questionType === 5 || question.attributes.questionType === 6) && <FormGroup>
                                                                            <Label >{props.t('Tags')}: </Label>
                                                                            {question.attributes.choice && question.attributes.choice.map((option, key) =>

                                                                                <Badge key={key} className={"badge badge-soft-primary font-size-12 m-1"}>{option.length > 20 ? option.slice(0, 19) + "..." : option} </Badge>

                                                                            )}
                                                                        </FormGroup>}
                                                                        <p className="text-muted">{CommonFunctions.getQuesionDataType(question.attributes.dataType, question.attributes.questionType, question.attributes.databaseFieldName, props)}</p>
                                                                    </div>
                                                                }
                                                            </Col>

                                                            <Col lg={1} className="mt-2 mb-2" >
                                                                <Button onClick={() => {

                                                                    if (editQuestionObject && question.id === editQuestionObject.id) {
                                                                        if (editQuestion === undefined && editQuestion === null && editQuestion.length === 0) {
                                                                            setQuestionError(true);
                                                                        } else if ((question.attributes.questionType === 5 || question.attributes.questionType === 6) && tags.length === 0) {
                                                                            setTagError(true);
                                                                        } else {
                                                                            handelEditQuestion()
                                                                        }

                                                                    } else {
                                                                        setEditQuestionObject(question);
                                                                        setEditQuestion(question.attributes.question);
                                                                        setIsMandatory(question.attributes.isMandatory);
                                                                        setTags(question.attributes.choice);
                                                                        setQuestionError(false);
                                                                        setTagError(false);
                                                                        setAddNewTags(false);
                                                                    }


                                                                }}
                                                                    type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light">
                                                                    {editQuestionObject && question.id === editQuestionObject.id ? props.t('Save') : props.t('Edit')}
                                                                </Button>

                                                                {editQuestionObject && question.id === editQuestionObject.id ?
                                                                    <Button
                                                                        type="button"
                                                                        color="danger"
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            setEditQuestionObject(undefined);
                                                                            setEditQuestion(false);
                                                                            setIsMandatory(undefined);
                                                                            setTags([]);
                                                                            setAddNewTags(false);
                                                                            setNewTag("");
                                                                            setQuestionError(false);
                                                                            setTagError(false);
                                                                        }}
                                                                        className="btn-rounded waves-effect waves-light mt-2">{props.t('Close')}


                                                                    </Button> : null}
                                                            </Col>

                                                        </Row>
                                                    ))
                                                }
                                                {/* </tbody>
                                            
                                                    </table> */}

                                            </TabPane>
                                            <TabPane tabId="2">
                                                <CardTitle className="mb-2">{props.t('General Enquiry Questions')}</CardTitle>
                                                <CardText className="mb-2">{props.t('Please select the question which you want in your school Enquiry form')}</CardText>
                                                <div className="table-responsive">

                                                    <table className="table table-nowrap table-centered mb-0">
                                                        <tbody>
                                                            {

                                                                allQuestionList.map((question) => (
                                                                    <tr key={question.id}>
                                                                        <td style={{ width: "60px" }}>
                                                                            <div className="custom-control custom-checkbox mb-2 ml-3" >
                                                                                <input type="checkbox" className="custom-control-input" id={question.id} onChange={() => { onSelectQustion(question) }} checked={selectedQuestions.includes(question.id)} />
                                                                                <label className="custom-control-label" onClick={() => { onSelectQustion(question); }} ></label>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <h5 className="text-truncate font-size-14 m-0"><Label className="text-dark">{question.question}</Label></h5>
                                                                            <p className="text-muted">{CommonFunctions.getQuesionDataType(question.dataType, question.questionType, question.databaseFieldName, props)}</p>
                                                                        </td>

                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>

                                                    </table>

                                                </div>
                                            </TabPane>
                                            <TabPane tabId="3">


                                                <Col lg={12} className="text-center">

                                                    {(WhiteLable.getAppName() == "lilTriangle") && (<CardTitle>{props.t('Download Enquiry App By Scanning QR Code')}</CardTitle>)}
                                                    {(WhiteLable.getAppName() == "lilTriangle") && (<img src={appQRCode}
                                                        height="200"
                                                        width="200"
                                                    >
                                                    </img>)}

                                                    <CardTitle className="mt-4">{props.t('Access To Web-Link By Scanning Bellow QR Code (Web & iPad)')}</CardTitle>

                                                    <Link to={"/enquiryposter/" + schoolId} target="_blank">
                                                        <CardText ><i className=" bx bx-cloud-download" />{props.t('Download Poster')}</CardText>
                                                    </Link>

                                                    <Link to={"/enquiry-form/" + schoolId} target="_blank">
                                                        <img
                                                            src={formQRCode}
                                                            height="200"
                                                            width="200"
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </Link>
                                                </Col>

                                            </TabPane>

                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>

                </CardBody>
            </Card>

            {selectedQuestion && <SweetAlert
                danger
                showCancel
                confirmBtnText="Remove"
                cancelBtnText="Cancel"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Alert!"
                onCancel={() => {
                    setSelectedQuestion(undefined);
                }}
                onConfirm={() => handelRemoveQuestion()}
            >
                {props.t('Are you sure you want to remove the question')} <br></br>
            </SweetAlert>}


            {/* <Modal isOpen={addQuestionPopup || props.loding}>
                      
                  
                      <ModalHeader>
                            
                          <h5 className="modal-title mt-0" id="myModalLabel">
                              {props.t('Add Question to Form')}
                          </h5>   
                      </ModalHeader>
                      <ModalBody >
                          <p className=" m-4">{props.t('Are you sure you want Add this Question')}</p>
                      </ModalBody>

                      <ModalFooter>
                      { !props.loding ?<Row className="text-center">
                          <button
                              type="button"
                              onClick={() => {
                                showAddQuestionPopup(false);
                                  setNewQuestion(undefined);
                              }}
                              className="btn btn-secondary waves-effect"
                              data-dismiss="modal"
                            >
                              {props.t('Cancel')}
                          </button>
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light ml-2"
                              onClick={() => {
                                handelAddQuestion();
                                 
                             }}
                            >
                              {props.t('Add Question')}
                          </button>

                          </Row>:<button
                              type="button" className="btn btn-light waves-effect">
                       
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Adding Question')}
                                            
                            </button>
                          
                        }

                          
                      </ModalFooter>
                  </Modal> */}
        </React.Fragment>

    );

}
const mapStatetoProps = state => {

    const { enquiryQuestions, loding } = state.EnquiryQuestions;
    return { enquiryQuestions, loding };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { getEnquiryQuestionForSchoolId, deleteEnquiryQuestion, addEnquiryQuestion, editEnquiryQuestion })(EnquiryQuestions)));