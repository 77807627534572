import React, { useEffect, useState } from 'react';
import { Card, CardBody, Table, Button, Row,Col } from 'reactstrap';
import Parse from 'parse';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import { setSelecetdSchool, getInventorySubItemForItemIds, getInventoryItemMatrixForItemIds, getInventoryItemForOwnerId,  getInventoryStockForWarehouse, getInventoryWarehouseForOwnerId, setFetchStatus } from '../../store/actions';
import * as XLSX from 'xlsx/xlsx.mjs';

const InventoryItemsReport = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/inventory-report/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-inventory-report/:franchiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;
    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

    const ownerId = schoolId??franchiseId


    const [allItems, setAllItems] = useState([]);
    const [displayTitles, setDisplayTitles] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [analytics,setAnalytics]=useState(undefined)
    const [gotReport, setGotReport] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (ownerId) {
            getInventoryItems(0);
        }
    }, [ownerId]);

    

    const getInventoryItems = (skip) => {

        var InventoryItems = Parse.Object.extend("InventoryItems");
        var query = new Parse.Query(InventoryItems);

        query.notEqualTo("isDeleted", true);
        query.equalTo("ownerId",ownerId)
        query.limit(1000)
        query.skip(skip)


        query.addAscending("order");
        query.find().then(
            (result) => {
                let list = [...allItems,...result]
                setAllItems(list)
                if(result && result.length==1000){
                    getInventoryItems(skip+1000)
                }else{
                    getMatrixObjects(list)
                }
            },
            (error) => {
            }
        );
    }

    const getMatrixObjects=(items)=>{

        let matrixIds=[]
        let allIds =[]
        for(const item of items){

            allIds.push(item.id)

            if(!matrixIds.includes(item.attributes.matrixColorId)){
                matrixIds.push(item.attributes.matrixColorId)
            }
            if(!matrixIds.includes(item.attributes.matrixSizeId)){
                matrixIds.push(item.attributes.matrixSizeId)
            }
        }

        if(matrixIds && matrixIds.length>0){
            var InventoryItemMatrix = Parse.Object.extend("InventoryItemMatrix");
            var query = new Parse.Query(InventoryItemMatrix);

            query.notEqualTo("isDeleted", true);
            query.containedIn("objectId",matrixIds)
            query.limit(matrixIds.length+100)
            query.addAscending("order");
            query.find().then(
                (result) => {
                    getStockForItems(items,result??[],allIds)
                },
                (error) => {
                }
            );
        }else{
            getStockForItems(items,[],allIds)
        }

    }

    const getStockForItems=(items,matrixObjects,allIds)=>{
        var InventoryItemStockMap = Parse.Object.extend("InventoryItemStockMap");
        var query = new Parse.Query(InventoryItemStockMap);

        query.notEqualTo("isDeleted", true);
        query.containedIn("itemId",allIds)
        query.limit(allIds.length+100)
        query.addAscending("order");
        query.find().then(
            (result) => {
                prepareItemsData(items,matrixObjects,result)
            },
            (error) => {
            }
        );
    }

    const prepareItemsData = (items,matrixObjects,stockObjects) => {
        let finalData = [];
        let preTaxTotal= 0;
        let sellingPriceTotal = 0;
        let totalStock=0;
        let title = [];
        title.push("Sl.No");
        title.push("Name");
        title.push("Pretax Price");
        title.push("Selling Price");
        title.push("Tax Rate");
        title.push("Stock");
        title.push("Total Pretax Price");
        title.push("Total Selling Price");
        setDisplayTitles(title); 
        const mainItems = items.filter(
            (item) => !item.attributes.itemId
        );

        for (const item of mainItems) {
            if(item.attributes.isMatrix){

                const subItems = items.filter(
                    (subItem) => subItem.attributes.itemId == item.id
                );
                for (const subItem of subItems) {

                    const matrixColor = matrixObjects.find(
                        (mx) => subItem.attributes.matrixColorId == mx.id
                    );
                    const matrixSize = matrixObjects.find(
                        (mx) => subItem.attributes.matrixSizeId == mx.id
                    );

                    let name = item.attributes.name
                    if(matrixColor){
                        name=name+" "+matrixColor.attributes.value
                    }
                    if(matrixSize){
                        name=name+" "+matrixSize.attributes.value
                    }
                    let row =getRowDataForItem(subItem,stockObjects)

                    if(row){
                        row.name=name
                        preTaxTotal=preTaxTotal+row.totalPretaxPrice;
                        sellingPriceTotal=sellingPriceTotal+row.totalSellingPrice;
                        totalStock=totalStock+row.stock;
                        finalData.push(row);
                    }
                }
            }else{
                let row =getRowDataForItem(item,stockObjects)
                preTaxTotal=preTaxTotal+row.totalPretaxPrice;
                sellingPriceTotal=sellingPriceTotal+row.totalSellingPrice;
                totalStock=totalStock+row.stock;
                finalData.push(row)
            }
        }
        const analytics = {
            preTaxTotal: preTaxTotal,
            sellingPriceTotal: sellingPriceTotal,
            mainItems: mainItems.length,
            allItems: finalData.length,
            totalStock: totalStock
        };
    
        setAnalytics(analytics);
        setDisplayData(finalData);
        setGotReport(true);
        setLoading(false);
    };

    const getRowDataForItem=(item,stockObjects)=>{
        const stockObject = stockObjects.find(
            (stock) => stock.attributes.itemId==item.id
        );
        let stock = stockObject?stockObject.attributes.stock:0
        let row ={
            name:item.attributes.name,
            sellingPrice:item.attributes.sellingPrice,
            pretaxPrice:item.attributes.pretaxPrice,
            taxRate:item.attributes.taxRate,
            stock:stock,
            totalPretaxPrice:item.attributes.pretaxPrice * stock,
            totalSellingPrice:item.attributes.sellingPrice * stock
        }
        return row;
    }

    const downloadSchoolItemReport = () => {
        let finalData = [];
        let title = [];
        let fileName = `Items_Report.xlsx`;
        let sheetName = "Items_Report";
        finalData.push([`Report: Items Report`]);
        finalData.push([]);
    
        finalData.push(["Total Items", analytics?.allItems || 0]);
        finalData.push(["Inventory Items", analytics?.mainItems || 0]);
        finalData.push(["PreTax Total", analytics?.preTaxTotal || 0]);
        finalData.push(["Selling Price Total", analytics?.sellingPriceTotal || 0]);
        finalData.push(["Total Stock", analytics?.totalStock || 0]);
        finalData.push([])
    
        title.push("Sl.No");
        title.push("Name");
        title.push("Pretax Price");
        title.push("Selling Price");
        title.push("Tax Rate");
        title.push("Stock");
        title.push("Total Pretax Price");
        title.push("Total Selling Price");
    
        finalData.push(title);
    
        let slNo = 1;
        for (const item of displayData) {
            finalData.push([
                slNo++,
                item.name,
                item.pretaxPrice,
                item.sellingPrice,
                item.taxRate,
                item.stock,
                item.totalPretaxPrice,
                item.totalSellingPrice,
            ]);
        }
    
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(finalData);
        ws['!cols'] = Array(title.length).fill({ wpx: 100 }); 
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName);
    };
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Table className="table mt-0 mb-3 table-bordered table-sm" style={{ width: '500px' }}>
                        <tbody>
                            <tr>
                                <th scope="row" style={{ width: "300px" }}>Total Items</th>
                                <td style={{ width: "300px" }}>{analytics?.allItems || 0}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "300px" }}>Inventory Items</th>
                                <td style={{ width: "300px" }}>{analytics?.mainItems || 0}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "300px" }}>PreTax Total</th>
                                <td style={{ width: "300px" }}>{analytics?.preTaxTotal || 0}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "300px" }}>Selling Price Total</th>
                                <td style={{ width: "300px" }}>{analytics?.sellingPriceTotal || 0}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "300px" }}>Total Stock</th>
                                <td style={{ width: "300px" }}>{analytics?.totalStock || 0}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col className="text-end">
                    <button
                        className="btn btn-info"
                        onClick={() => {
                            downloadSchoolItemReport(displayData)
                        }}
                    >
                        <i className="bx bxs-download m-1" />
                        Download
                    </button>
                </Col>
            </Row>
            

            <div className="table-responsive mb-0" data-pattern="priority-columns">
                <Table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            {displayTitles.map((title, index) => (
                                <th key={index}>{title}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {displayData.map((data, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{data.name}</td>
                                <td>{data.pretaxPrice}</td>
                                <td>{data.sellingPrice}</td>
                                <td>{data.taxRate}</td>
                                <td>{data.stock}</td>
                                <td>{data.totalPretaxPrice}</td>
                                <td>{data.totalSellingPrice}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colSpan="6" style={{ textAlign: "right" }}>Total:</th>
                        <td>{displayData.reduce((acc, item) => acc + item.totalPretaxPrice, 0)}</td>
                        <td>{displayData.reduce((acc, item) => acc + item.totalSellingPrice, 0)}</td>
                    </tr>
                </tfoot>
                </Table>
                {displayData.length == 0 && !loading && gotReport && (
                    <h3 className="text-center text-info mt-3">
                        No Report Found For This Filter
                    </h3>
                )}
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    const { inventorySaleOrders } = state.InventorySaleOrder;
    const { inventorySaleOrderItems } = state.InventorySaleOrderItem;
    const { inventoryItems, inventorySubItems, inventoryMatrixs } = state.InventoryItems;
    const {  inventoryWarehouses, inventoryItemStockMap } = state.InventoryWarehouse;

    return { inventoryItems, inventoryItemStockMap, inventorySubItems, inventoryMatrixs};
};

export default withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getInventoryItemForOwnerId, 
    getInventoryWarehouseForOwnerId, getInventoryStockForWarehouse, 
    getInventorySubItemForItemIds, getInventoryItemMatrixForItemIds
})(InventoryItemsReport));
