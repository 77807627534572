import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label, FormGroup, Input } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelecetdSchool, getTeachersForSchool, getLeavePolicy, updateLeaveModule, getLeavePolicyItemsForPolicyId, getHolidayList } from '../../store/actions';
import Moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Parse from 'parse';
import toastr from 'toastr';

const ApplyLeave = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/apply-leave/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;

    const [teachersList, setTeachersList] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState();
    const [selectedTeacherError, setSelectedTeacherError] = useState('');
    const [leaveModes, setLeaveModes] = useState('');
    const [leaveModesError, setleaveModesError] = useState('');
    const [selectedDate, setSelectedDate] = useState();
    const [selectedDateError, setSelectedDateError] = useState('');
    const [startDate, setStartDate] = useState();
    const [startDateError, setStartDateError] = useState('');
    const [endDate, setEndDate] = useState();
    const [endDateError, setEndDateError] = useState('');
    const [halfDayOption, setHalfDayOption] = useState('first half');
    const [halfDayOptionError, setHalfDayOptionError] = useState('');
    const [startDayOption, setStartDayOption] = useState('full day');
    const [endDayOption, setEndDayOption] = useState('full day');

    const [reason, setReason] = useState('');
    const [leaveConflictError, setLeaveConflictError] = useState('');
    const [loading, setLoading] = useState(false);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateItems, setTemplateItems] = useState([]);
    const [selectedTemplateItem, setSelectedTemplateItem] = useState(null);

    const [holidayList,setHolidayList]=useState([]);
    const [weekOffs,setWeekOff]=useState([]);
    const [weekOffDays,setWeekOffDays]=useState([]);
    const [leaveInterval,setLeaveInterval]=useState({
        start:new Date(),
        end:new Date()
    });

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.getTeachersForSchool(schoolId);
        props.getLeavePolicy(schoolId);
        props.getHolidayList(schoolId, 'school');
    }, [schoolId]);

    useEffect(() => {
        if (selectedTemplate) {
            props.getLeavePolicyItemsForPolicyId(selectedTemplate.value);
        }else {
            setTemplateItems([]);
        }
    }, [selectedTemplate]);

    useEffect(() => {
        if (selectedTeacher) {
            setSelectedTemplate(null);
            setSelectedTemplateItem(null);
            setLeaveModes("disabled");
            setHalfDayOption(null);
            setStartDate(null);
            setEndDate(null);
            setSelectedDate(null);
        }
    }, [selectedTeacher]);
    
    useEffect(() => {
        if (props.leavesPoliciesItems) {
            setTemplateItems(props.leavesPoliciesItems);
        }
    }, [props.leavesPoliciesItems]);    

    useEffect(() => {
        if (props.teachers) {
            let teacherList = [];
            for (const teacher of props.teachers) {
                teacherList.push({ value: teacher.id, label: teacher.attributes.Name,teacher: teacher});
            }
            setTeachersList(teacherList);
        }
    }, [props.teachers]);

    useEffect(() => {
        let list =[]
        for(const h of props.holidayList){
            list.push(h.attributes.holidayDate)
        }
        setHolidayList(list);
    }, [props.holidayList]);

    useEffect(() => {
        let nonWorkingDays = []
        if(selectedTeacher && selectedTeacher.teacher && 
            selectedTeacher.teacher.attributes.workingDaysMap &&
             selectedTeacher.teacher.attributes.workingDaysMap.length >0 ){

            let workingDaysMap = selectedTeacher.teacher.attributes.workingDaysMap;
            nonWorkingDays =[0,1,2,3,4,5,6]
            for(const day of workingDaysMap){
                if(day.working){
                    nonWorkingDays = nonWorkingDays.filter(item => item !== day.id);
                }
            }
            
        }else if(props.selectedSchool  && props.selectedSchool.attributes.workingDays){
            let workingDays = props.selectedSchool.attributes.workingDays
            let allDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            for(const day in allDays){
                if(!workingDays.includes(allDays[day])){
                    nonWorkingDays.push(parseInt(day))
                }
            }
        }else{
            nonWorkingDays.push(0)
        }
        let today = new Date();
        let lastDay= new Date();
        lastDay.setMonth(today.getMonth() + 3);



        let months = today.getFullYear() == lastDay.getFullYear() ?lastDay.getMonth():11
        let day = today.getFullYear() == lastDay.getFullYear() ?lastDay.getDate():31
        

        const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(),0,0,0);
        const endDate = new Date(today.getFullYear(), months, day,23,59,59); 

        setLeaveInterval({
            start:startDate,
            end:endDate
        })

        let nonWorkingDates=[]

        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
            if(nonWorkingDays.includes(d.getDay())){
                let nd = new Date(d)
                nonWorkingDates.push(nd)
            }
        }
        setWeekOffDays(nonWorkingDays)
        // console.log("NonworkingDays3", nonWorkingDays)
        setWeekOff(nonWorkingDates);
        
    }, [props.selectedSchool,selectedTeacher]);
    
    useEffect(() => {
        if (selectedTeacher) {
            const query = new Parse.Query("Teacher");
            query.get(selectedTeacher.value)
                .then(teacher => {
                    const teacherLeavePolicyId = teacher.attributes.leavePolicyId;
                    if (!teacherLeavePolicyId) {
                        setSelectedTemplate(null);
                        setTemplateOptions([]);
                        return;
                    }
                    let templates = [];
                    for (const template of props.leavesPolicies) {
                        if (template.id === teacherLeavePolicyId) {
                            templates.push({ value: template.id, label: template.attributes.policyTitle });
                        }
                    }
                    setTemplateOptions(templates);
                    setSelectedTemplate(templates.length > 0 ? templates[0] : null);
                })
                .catch(error => {
                    console.error("Error fetching teacher:", error);
                });
        }
    }, [selectedTeacher, props.leavesPolicies, schoolId]);
    
    const leavesModeList = [
        { value: 'half day', label: 'Half day' },
        { value: 'full day', label: 'Full day' },
        { value: 'multiple days', label: 'Multiple Days' },
    ];

    const calculateLeavePeriod = (leaveMode) => {
        if (leaveMode == 1 || leaveMode == 2) {
            return 0.5;
        } else if (leaveMode === 3) {
            return 1;
        } else if (leaveMode > 3) {
            return calculateLeaveDays(startDate,endDate,leaveMode)
        }
        return 0;
    };
    
    function calculateLeaveDays(fromDate, toDate, leaveMode) {
        const start = new Date(fromDate);
        const end = new Date(toDate);
        let totalDays = 0;

        let holidays =[]

        for(const h of holidayList){
            holidays.push(h.toISOString().split("T")[0])
        }
    
        for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
            const dayOfWeek = d.getDay(); // 0 = Sunday, 6 = Saturday
    
            if (weekOffDays.includes(dayOfWeek)) {
                continue;
            }
    
            const formattedDate = d.toISOString().split("T")[0]; // Format as YYYY-MM-DD
            if (holidays.includes(formattedDate)) {
                continue; 
            }
    
            totalDays++;
        }

        if(leaveMode == 4 ||leaveMode == 7 ){
            totalDays = totalDays - 0.5
        }else if(leaveMode == 6){
            totalDays = totalDays - 1
        }
    
        return totalDays;
    }

    // console.log("PolicyItems", props.leavesPoliciesItems);

    const getLeaveMode=()=>{
        if (leaveModes === 'half day') {
            return halfDayOption === 'first half' ? 1 : 2;
        } else if (leaveModes === 'full day') {
            return 3;
        } else if (leaveModes === 'multiple days') {
            if (startDayOption === 'full day' && endDayOption === 'first half') {
                return 4;
            } else if (startDayOption === 'full day' && endDayOption === 'full day') {
                return 5;
            } else if (startDayOption === 'second half' && endDayOption === 'first half') {
                return 6;
            } else if (startDayOption === 'second half' && endDayOption === 'full day') {
                return 7;
            }
        }
        return -1;
    }

    const remainingDays = (policyItemId, selectedStartDate, selectedEndDate, leaveMode) => {
        if (!selectedTeacher || !props.leavesApplied) return 0;
    
        const policyItem = templateItems.find(item => item.id === policyItemId);
        if (!policyItem) return 0;
    
        const totalDays = policyItem.attributes.days;
        // console.log("TotalDays", totalDays)
        let usedDays = 0;
    
        for (let leave of props.leavesApplied) {
            if (
                leave.attributes.userId === selectedTeacher?.value &&
                leave.attributes.status === 1 &&
                leave.attributes.leavePolicyItemId === policyItemId
            ) {
                let leaveStartDate = new Date(leave.attributes.startDate);
                let leaveEndDate = new Date(leave.attributes.endDate);
                let existingLeaveMode = leave.attributes.leaveMode;
    
                let leavePeriod = existingLeaveMode == 1 || existingLeaveMode == 2 
                ? 0.5 
                : calculateLeaveDays(leaveStartDate, leaveEndDate, existingLeaveMode);
                usedDays += leavePeriod;
                // console.log("UsedDays: ", usedDays);

            }
        }
    
        let newLeaveDays = 0;
        if (selectedStartDate && selectedEndDate && usedDays > 0) {
            newLeaveDays = calculateLeaveDays(new Date(selectedStartDate), new Date(selectedEndDate), leaveMode) || 0;
        }

        // console.log("New Leave Days: ", newLeaveDays);
        let remaining = Math.max(totalDays - usedDays - newLeaveDays, 0);
        // console.log("Remainining", remaining)
        return remaining;
    };

    useEffect(() => {
        if (props.leavesPoliciesItems) {
            let items = [...props.leavesPoliciesItems];
    
            const lopExists = items.some(item => item.attributes.lop);
    
            if (!lopExists) {
                items.push({
                    id:"LOP",
                    attributes: {
                        title: "LOP",
                        days: Infinity,
                        lop: true,
                    }
                });
            }
            setTemplateItems(items);
        }
    }, [props.leavesPoliciesItems]);
    
    const saveLeaves = () => {
        let isValid = true;
        setLeaveConflictError('');

        if (!selectedTeacher) {
            setSelectedTeacherError(true);
            isValid = false;
        }

        if (!leaveModes) {
            setleaveModesError(true);
            isValid = false;
        }

        if (leaveModes === 'half day' && !halfDayOption) {
            setHalfDayOptionError(true);
            isValid = false;
        }

        if (leaveModes === 'multiple days') {
            if (!startDate) {
                setStartDateError(true);
                isValid = false;
            }
            if (!endDate) {
                setEndDateError(true);
                isValid = false;
            }
        } else {
            if ((leaveModes === 'full day' || (leaveModes === 'half day' && halfDayOption)) && !selectedDate) {
                setSelectedDateError(true);
                isValid = false;
            }
        }

        const leavesExist = props.leavesApplied.find(leave => {
            const leaveStartDate = Moment(leave.attributes.startDate);
            const leaveEndDate = Moment(leave.attributes.endDate);

            const newStartDate = Moment(leaveModes === 'multiple days' ? startDate : selectedDate);
            const newEndDate = Moment(leaveModes === 'multiple days' ? endDate : selectedDate);

            return leave.attributes.userId === selectedTeacher.value &&
                !(newEndDate.isBefore(leaveStartDate) || newStartDate.isAfter(leaveEndDate));
        });

        if (leavesExist) {
            setLeaveConflictError(`${selectedTeacher.label} has already applied for leave between these dates.`);
            setLoading(false);
            return;
        }

        if (isValid) {
            setLoading(true);
            
            const Leaves = Parse.Object.extend("Leaves");
            const leaves = new Leaves();

            let leaveMode = getLeaveMode();
            const leavePeriod = calculateLeavePeriod(leaveMode);

            if (leavePeriod === 0) {
                toastr.error("No working days available between the selected dates. Please choose different dates.", { timeOut: 3000 });
                return;
            }
            if (!selectedTemplateItem || !selectedTemplateItem.value) {
                toastr.error("Please select a leave policy before applying.", { timeOut: 3000 });
                setLoading(false);
                return;
            }
            
            let availableDays = remainingDays(selectedTemplateItem.value);
            // console.log("selectedTemplateItem",selectedTemplateItem)
            const policyName = selectedTemplateItem.label.split(" (")[0] || "Selected Policy"
            if (leavePeriod > availableDays) {
                toastr.error(`You are applying for ${leavePeriod} days, but only ${availableDays} days are available for ${policyName}.`, { timeOut: 3000 });
                setLoading(false);
                return;
            }
        
            if (leaveModes === 'multiple days') {
                leaves.set("startDate", startDate);
                leaves.set("endDate", endDate);
            } else {
                leaves.set("startDate", selectedDate);
                leaves.set("endDate", selectedDate);
            }

            leaves.set("reason", reason);
            leaves.set("ownerId", schoolId);
            leaves.set("userId", selectedTeacher.value);
            leaves.set("createdBy", props.userTeacher.id);
            leaves.set("leavePolicyItemId", selectedTemplateItem.value);
            leaves.set("leavePolicyId", selectedTemplate.value);
            leaves.set("leaveMode", leaveMode);
            leaves.set("ownerType", "School");
            leaves.set("leavePeriod",leavePeriod)
            leaves.set("lop", selectedTemplateItem?.value === "LOP");
            leaves.set("policyItemTitle", selectedTemplateItem.label.split(" (")[0]);
            
            leaves.save().then(
                (result) => {
                    props.updateLeaveModule([result]);
                    console.log("Result", result)
                    toastr.success("Leave Applied successfully", { timeOut: 3000 });

                    const LeaveLog = Parse.Object.extend("LeaveLog");
                    const leaveLog = new LeaveLog();
                    leaveLog.set("createdBy", props.userTeacher.id);
                    leaveLog.set("leaveId",result.id)
                    leaveLog.set("fromState",0)
                    leaveLog.set("toState",0)
                    leaveLog.set("operationNote","Created")
                    leaveLog.save();

                    window.history.back();
                    setLoading(false);
                },
                (error) => {
                    console.error('Error Applying Leave:', error);
                    setLoading(false);
                }
            );
        } else {
            console.log("Error");
        }
    };

    return (
        <React.Fragment>
            <div className='page-content'>
                <Row className="d-flex justify-content-between align-items-start">
                    <Col lg={6} className="d-flex align-items-start">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { window.history.back() }}>
                            <ul className="list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className='m-2'>
                            <h4 className="font-size-18">Apply Leaves</h4>
                        </div>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row lg={12}>
                            <Col lg='6'>
                                <FormGroup>
                                    <Label>Select User</Label>
                                    <Select
                                        className="react-select"
                                        classNamePrefix="select"
                                        options={teachersList}
                                        value={selectedTeacher}
                                        onChange={option => {
                                            setSelectedTeacher(option);
                                            setSelectedTeacherError(false);
                                        }}
                                        placeholder="Select a teacher"
                                        isClearable
                                    />
                                    {selectedTeacherError && <div className="text-danger m-0">Select</div>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row lg={12}>
                            <Col lg='6'>
                                <FormGroup>
                                    <Label>Leave Policy</Label>
                                    {selectedTemplate ? (
                                        <Input 
                                            type="text"
                                            value = {selectedTemplate.label}
                                            readOnly
                                        />
                                    ) : selectedTeacher ? (
                                        <div className="text-danger">
                                            Not Assigned Leave Policies, to Apply Leave, Assign Policies <a href={`/leave-policies/${schoolId}`} style={{ textDecoration: "underline" }}>Click</a>
                                        </div>
                                    ) : (
                                        <Input 
                                            type="text"
                                            value="" 
                                            readOnly
                                        />
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row lg={12}>
                            <Col lg='6'>
                                <FormGroup>
                                    <Label>Select Leave Policy Items</Label>
                                    <Select
                                        className="react-select"
                                        classNamePrefix="select"
                                        options={selectedTemplate ? templateItems.map(item => {
                                            if (item.attributes.lop) {
                                                return { value: item.id, label: `${item.attributes.title}` };
                                            }
                                            const totalDays = item.attributes.days;
                                            const remaining = remainingDays(item.id,startDate,endDate,getLeaveMode());
                                            return {
                                                value: item.id,
                                                label: `${item.attributes.title} (${remaining}/${totalDays})`,
                                            };
                                        }) : []}
                                        value={selectedTemplateItem}
                                        onChange={option => {
                                            setSelectedTemplateItem(option);
                                        }}
                                        placeholder="Select a Policy item"
                                        isClearable
                                    />
                                    {selectedTemplateItem && remainingDays(selectedTemplateItem.value) <= 0 && (
                                    <div className="text-danger">
                                        Policy Item remaining days are {remainingDays(selectedTemplateItem.value)}, but you are trying to apply leave. Please check.
                                    </div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row lg={12}>
                            <Col lg='6'>
                                <FormGroup>
                                    <Label>Leave Mode ({calculateLeavePeriod(getLeaveMode())} days)</Label>
                                    {selectedTeacher ? ( 
                                    <select
                                        className="form-control"
                                        value={leaveModes}
                                        onChange={e => {
                                            setLeaveModes(e.target.value);
                                            setleaveModesError(false);
                                            if (e.target.value !== 'half day') {
                                                setHalfDayOption('');
                                            }
                                        }}
                                    >
                                        <option value="disabled">Select Leave Mode</option>
                                        {leavesModeList.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    ) : (
                                        <Input type="text" value="" readOnly />
                                    )}
                                    {leaveModesError && <div className="text-danger m-0">Select Leave Mode</div>}
                                </FormGroup>
                            </Col>
                            {leaveModes === 'half day' && (
                                <Col lg='6'>
                                    <FormGroup>
                                        <Label>Select Half Day Option</Label>
                                        <select
                                            className="form-control"
                                            value={halfDayOption}
                                            onChange={e => {
                                                setHalfDayOption(e.target.value);
                                                setHalfDayOptionError(false);
                                            }}
                                        >
                                            <option value="first half">First Half</option>
                                            <option value="second half">Second Half</option>
                                        </select>
                                        {halfDayOptionError && <div className="text-danger m-0">Select</div>}
                                    </FormGroup>
                                </Col>
                            )}
                        </Row>
                        {(leaveModes === 'full day' || (leaveModes === 'half day' && halfDayOption)) && (
                            <Row lg={12}>
                                <Col lg='6'>
                                    <FormGroup>
                                        <Label>Select Date</Label>
                                        <DatePicker
                                            selected={selectedDate}
                                            className="form-control"
                                            onChange={(date) => {
                                                setSelectedDate(date);
                                                setSelectedDateError(false);
                                            }}
                                            minDate={new Date()}
                                            maxDate={leaveInterval.end}
                                            excludeDates={[...weekOffs,...holidayList]}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        {selectedDateError && <div className="text-danger m-0">Select</div>}
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                        {leaveModes === 'multiple days' && (
                            <Row lg={12}>
                                <Col lg='6'>
                                    <FormGroup>
                                        <Label>Start Date</Label>
                                        <DatePicker
                                            selected={startDate}
                                            className="form-control"
                                            onChange={(date) => {
                                                setStartDate(date);
                                                setStartDateError(false);
                                                setLeaveConflictError('');
                                            }}
                                            excludeDates={[...weekOffs,...holidayList]}
                                            minDate={new Date()}
                                            maxDate={leaveInterval.end}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        {startDateError && <div className="m-0 text-danger">Select</div>}
                                    </FormGroup>
                                </Col>
                                <Col lg='6'>
                                    <FormGroup>
                                        <Label>Select Start Day Option</Label>
                                        <select
                                            className="form-control"
                                            value={startDayOption}
                                            onChange={e => {
                                                setStartDayOption(e.target.value);
                                            }}
                                        >
                                            <option value="full day">Full Day</option>
                                            <option value="second half">Second Half</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col lg='6'>
                                    <FormGroup>
                                        <Label>End Date</Label>
                                        <DatePicker
                                            selected={endDate }
                                            className="form-control"
                                            onChange={(date) => {
                                                setEndDate(date);
                                                setEndDateError(false);
                                                setLeaveConflictError('');
                                            }}
                                            
                                            minDate={startDate && new Date(startDate.getTime() + 86400000)}
                                            maxDate={leaveInterval.end}
                                            excludeDates={[...weekOffs,...holidayList]}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        {endDateError && <div className="text-danger m-0">Select</div>}
                                    </FormGroup>
                                </Col>
                                <Col lg='6'>
                                    <FormGroup>
                                        <Label>Select End Day Option</Label>
                                        <select
                                            className="form-control"
                                            value={endDayOption}
                                            onChange={e => {
                                                setEndDayOption(e.target.value);
                                            }}
                                        >
                                            <option value="full day">Full day</option>
                                            <option value="first half">First Half</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                        {leaveConflictError && <div className="text-danger m-0">{leaveConflictError}</div>}
                        <Row lg={12}>
                            <Col lg='6'>
                                <FormGroup>
                                    <Label>Reason</Label>
                                    <Input
                                        type="textarea"
                                        value={reason}
                                        rows="3"
                                        placeholder='Enter Reason'
                                        onChange={(e) => {
                                            setReason(e.target.value);
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='10' className='text-center'>
                                <Button color="primary" onClick={() => saveLeaves()}>
                                    {loading ? (
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    ) : null}
                                    {loading ? props.t('Applying...') : props.t('Apply')}
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { leavesApplied, leavesPolicies, leavesPoliciesItems,holidayList } = state.LeaveModule;
    const { teachers } = state.Teacher;
    const { userTeacher } = state.Login;
    return { leavesApplied, selectedSchool, teachers, userTeacher, leavesPolicies, leavesPoliciesItems, holidayList };
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool, getTeachersForSchool, getLeavePolicy, updateLeaveModule, getLeavePolicyItemsForPolicyId, getHolidayList })(ApplyLeave)));
