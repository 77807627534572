import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Card, CardBody, Button, Input, Label, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from 'react-select';
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelecetdSchool } from '../../store/actions';

const PayRollTemplate = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/payroll-templates/:schoolId",
    exact: true,
    strict: false,
  });

  const schoolId = match && match.params.schoolId;

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);


  return (
    <React.Fragment>
      <div className='page-content'>
        <Row>
          <Col className="d-flex align-items-start">
            <div style={{ cursor: "pointer" }} onClick={() => { window.history.back() }}>
              <ul className="list-unstyled">
                <div className=''>
                  <i className="bx bx-chevron-left h1 text-primary"></i>
                </div>
              </ul>
            </div>
            <div className='m-2'>
              <h4 className="font-size-18">PayRoll Templates</h4>
            </div>
          </Col>
          <Col className='d-flex justify-content-end'>
            <div className="mt-4 mt-sm-0 float-sm-right form-inline">
              <Link to={"/payroll-new-template/" + schoolId} className="btn btn-primary float-right">New Template</Link>
            </div>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Row>
              <Col className="table-responsive">
                <Table className="table table-nowrap m-0">
                  <thead>
                    <tr>
                      <th>{props.t("#")}</th>
                      <th>{props.t("Category Type")}</th>
                      <th>{props.t("Title")}</th>
                      <th>{props.t("Description")}</th>
                    </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  return { selectedSchool };
};

export default connect(mapStatetoProps, { setSelecetdSchool })(withRouter(withNamespaces()(PayRollTemplate)));
