import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import toastr from "toastr";
import TopBar from "./Topbar";
import Moment from 'moment';

import Parse from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import avatar2 from "../../assets/images/users/user.png";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Constants from "../../Constents";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import {
  setSelecetdSchool,
  getTeachersForSchool,
  getAllKidsForGroup,
  getClassroomsForSchoolId,
  getFranchise,
} from "../../store/actions";

const FormView = (props) => {
  const schoolMatch = matchPath(props.history.location.pathname, {
    path: "/formview/:schoolId/:formId",
    exact: true,
    strict: false,
  });

  const franchiseMatch = matchPath(props.history.location.pathname, {
    path: "/franchise-formview/:franchiseId/:formId",
    exact: true,
    strict: false,
  });

  const match = schoolMatch ?? franchiseMatch
  const formId = match && match.params.formId;
  const schoolId = schoolMatch && schoolMatch.params.schoolId;
  const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

  const [formName, setFormName] = useState("");
  const [formObject, setFormObject] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");

  const [selectedSessionObject, setSelectedSessionObject] = useState(undefined);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [displayList, setDisplayList] = useState([]);
  const [showMappingModal, setShowMappingModal] = useState(false);
  const [classroomList, setClassroomList] = useState([]);
  const [selectedClassroomFilter, setSelectedClassroomFilter] = useState(
    undefined
  );
  const [activeTab, setactiveTab] = useState("1");

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [formToDelete, setFormToDelete] = useState(undefined);
  const [loading, setLoading] = useState(false)

  const [searchBarString, setSearchBarString] = useState("");
  const [isMappingInProgress, setIsMappingInProgress] = useState(false);
  const handleSearchBarTextChange = (value) => {
    setSearchBarString(value);
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  useEffect(() => {
    if (props.classrooms && props.classrooms.length > 0) {
      var tempArray = [...props.classrooms];
      const ClassroomObject = Parse.Object.extend("ClassRoom");
      var tempClassroomObject = new ClassroomObject();
      tempClassroomObject.id = "allClassrooms";
      tempClassroomObject.set("Name", "All Classrooms");
      tempArray.unshift(tempClassroomObject);
      setClassroomList(tempArray);
      setSelectedClassroomFilter(tempClassroomObject);
    }
  }, [props.classrooms]);

  const getAllKidsFilteredList = () => {
    var listToReturn = [];
    if (selectedClassroomFilter != undefined) {
      if (selectedClassroomFilter.id === "allClassrooms") {
        listToReturn = [...props.kids];
      } else {
        var i = 0;
        for (i = 0; i < props.kids.length; i++) {
          if (
            props.kids[i].attributes.ClassRoomID === selectedClassroomFilter.id
          ) {
            listToReturn.push(props.kids[i]);
          }
        }
      }
    }

    if (searchBarString === "") {
    } else {
      listToReturn = listToReturn.filter((object) =>
        object.attributes.Name.toLowerCase().includes(
          searchBarString.toLowerCase()
        )
      );
    }



    return listToReturn;
  };
  const handleCheckboxChange = (tempObject, userType) => {
    setSelectedUserType(userType);
    setSelectedUserId(tempObject.id);
    setSelectedUserName(tempObject.attributes.Name);
  };

  const getKidPhoto = (kObject) => {
    var kidPhoto = "";

    kidPhoto =
      kObject.attributes.Photo != undefined
        ? kObject.attributes.Photo._url
        : avatar2;

    return kidPhoto;
  };

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
    if (props.selectedSchool && props.selectedSchool) {
      props.getFranchise(props.selectedSchool.attributes.franchiseId);
    }
    props.getTeachersForSchool(schoolId);
    props.getAllKidsForGroup(schoolId);
    props.getClassroomsForSchoolId(schoolId);
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {
    getFormObject();
  }, [formId]);


  const getFormObject = () => {
    if (formId) {
      var query = new Parse.Query("Forms");
      query.equalTo("objectId", formId);
      query.notEqualTo("isDeleted", true);
      query.limit(1000);
      query.first().then((result) => {
        if (result) {
          setFormObject(result)
          setFormName(result.attributes.name)
        }
      });
    }
  }

  useEffect(() => {
    if (true) {
      let query = new Parse.Query("FormSessions");
      if (franchiseMatch) {
        query.equalTo("franchiseId", franchiseId);
      } else {
        query.equalTo("schoolId", schoolId);
      }
      query.equalTo("formId", formId);
      query.notEqualTo("isDeleted", true);
      query.limit(1000);
      query.find().then((results) => {
        setIsLoading(false);
        var tempList = [];

        if (results != null) {
          if (results.length > 0) {
            let tempList = [];
            for (var i = 0; i < results.length; i++) {
              let tempObj = {
                mappedTo: "-",
                mappedToType: "",
                applicantName: results[i].attributes.fillingFor,
                sessionParseObject: results[i],
              };

              let tempMappedTo = "-";
              let tempMappedToType = "";
              if (
                results[i].attributes.userId != undefined &&
                results[i].attributes.userId.length > 0
              ) {
                if (results[i].attributes.userType === "teacher") {
                  if (props.teachers && props.teachers.length > 0) {
                    let tempUser = props.teachers.filter((teacher) => {
                      return teacher.id === results[i].attributes.userId;
                    });
                    if (tempUser && tempUser.length > 0) {
                      tempMappedTo = tempUser[0].attributes.Name;
                      tempMappedToType = "teacher"
                    }
                  }
                } else if (results[i].attributes.userType === "student") {
                  if (props.kids && props.kids.length > 0) {
                    let tempUser = props.kids.filter((kid) => {
                      return kid.id === results[i].attributes.userId;
                    });
                    if (tempUser && tempUser.length > 0) {
                      tempMappedTo = tempUser[0].attributes.Name;
                      tempMappedToType = "student"
                    }
                  }
                }
              }

              tempObj.mappedToType = tempMappedToType;
              tempObj.mappedTo = tempMappedTo;

              tempList.push(tempObj);
            }
            setDisplayList(tempList);
          } else {
            //setIsQuestionsAvailable(false);
          }
        }
      });
    }
  }, [props.selectedSchool, formId, props.kids, props.teachers]);


  function strReplace(stringValue) {
    var newStr = stringValue.replace(/%20/g, " ");

    return newStr;
  }

  const handleClassroomFilterChange = (value) => {
    var classrooms = classroomList.filter(
      (classroom) => classroom.id === value
    );

    if (classrooms.length > 0) {
      setSelectedClassroomFilter(classrooms[0]);
    }
  };

  const getSortedTeachersList = () => {
    let sortedTeachers = [];
    if (props.teachers && props.teachers.length > 0) {
      sortedTeachers = props.teachers.sort(function (a, b) {
        var nameA = a.attributes.Name.toLowerCase(),
          nameB = b.attributes.Name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
    }
    return sortedTeachers;
  };

  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  const getFormSection = () => {
    setLoading(true)
    var FormSection = Parse.Object.extend("FormSections");
    var query = new Parse.Query(FormSection);

    query.equalTo("formId", formId);
    query.ascending("order");

    query.notEqualTo("isDeleted", true);
    query.find().then((formSection) => {
      if (formSection) {
        getFormQuestions(formSection)
      }
    });
  }

  const getFormQuestions = (formSection) => {

    var FormQuestions = Parse.Object.extend("FormQuestions");
    var query = new Parse.Query(FormQuestions);

    query.equalTo("formId", formId);
    query.ascending("order");

    query.notEqualTo("isDeleted", true);
    query.find().then((formQuestion) => {
      if (formQuestion) {
        getFormData(formQuestion, formSection)
      }
    });
  }

  const getFormData = (formQuestion, formSection) => {

    var FormData = Parse.Object.extend("FormData");
    var query = new Parse.Query(FormData);

    query.equalTo("formId", formId);
    query.limit(formQuestion.length * displayList.length + 100)
    query.notEqualTo("isDeleted", true);
    query.find().then((formData) => {
      if (formData) {
        //processData(formObject, formSection, formQuestion, displayList, formData)
        processData2(formObject, formSection, formQuestion, displayList, formData)
      }
    });
  }


  const processData = (form, sections, questions, sessions, formData) => {

    let horizontalTitles = []

    let questionTitles = [];



    for (const sec of sections) {

      for (const qus of questions) {
        if (qus.attributes.sectionId == sec.id) {

          questionTitles.push({

            section: sec,
            question: qus,
            sectionId: sec.id,
            questionId: qus.id,
            sectionName: sec.attributes.sectionName,
            questionName: qus.attributes.question,
          })
        }
      }

    }

    horizontalTitles.push({
      name: "Section Name"
    });
    horizontalTitles.push({
      name: "Question"
    });
    for (const secObj of sessions) {
      let sec = secObj.sessionParseObject
      horizontalTitles.push({
        sessionId: sec.id,
        session: sec,
        name: sec.attributes.fillingFor,
        kidId: sec.attributes.kidId,
      })
    }





    let reportData = [];


    reportData.push([`Form Name: ${formName}`]);
    reportData.push([`${franchiseMatch ? "Franchise Name" : "School Name"}: ${`${franchiseMatch ? props.franchise && props.franchise.attributes.name : props.selectedSchool && props.selectedSchool.attributes.Name}`}`]);
    reportData.push([``]);
    reportData.push([``]);


    let tTitles = [];
    for (const t of horizontalTitles) {
      tTitles.push(t.name)
    }

    reportData.push(tTitles);



    for (const qt of questionTitles) {

      let list = [qt.sectionName, qt.questionName,]

      let ansQuestionData = formData.filter((fd) => fd.attributes.questionId == qt.questionId)

      for (const secObj of sessions) {
        let sec = secObj.sessionParseObject
        let ans = ansQuestionData.filter((fd) => fd.attributes.sessionId == sec.id)


        if (ans && ans.length > 0) {
          if (qt.question.attributes.questionType == Constants.QESTION_DATE) {
            list.push(Moment(ans[0].attributes.answer[0]).format('DD-MM-YYYY'))

          } else if (qt.question.attributes.questionType == Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST) {
            let multiChoice = ""
            for (const a of ans[0].attributes.answer) {

              if (multiChoice) {
                multiChoice = multiChoice + a + ", ";

              } else {
                multiChoice = a + ", "
              }
            }
            list.push(multiChoice)

          } else if (qt.question.attributes.questionType == Constants.QESTION_YES_NO_COMMENT_ON_YES) {

            if (ans[0].attributes.answer[0]) {
              list.push("Yes, " + ans[0].attributes.answer[1])
            } else {
              list.push("No")
            }

          } else if (qt.question.attributes.questionType == Constants.QESTION_YES_NO_COMMENT_ON_NO) {

            if (ans[0].attributes.answer[0] == false) {
              list.push("No, " + ans[0].attributes.answer[1])
            } else {
              list.push("Yes")
            }
          }

          else if (qt.question.attributes.questionType == Constants.QESTION_YES_NO_NO_COMMENTS) {
            if (ans[0].attributes.answer[0] == true) {
              list.push("Yes")
            } else {
              list.push("No")
            }
          }

          else if (qt.question.attributes.questionType == Constants.QESTION_CHECKBOX) {
            if (ans[0].attributes.answer[0] == true) {
              list.push("Checked")
            } else {
              list.push("Unchecked")
            }
          }

          else if (qt.question.attributes.questionType == Constants.QESTION_UPLOAD_IMAGE) {
            list.push(ans[0].attributes.answer[0] ? ans[0].attributes.answer[0]._url : '')
          }

          else if (qt.question.attributes.questionType == Constants.QESTION_UPLOAD_DOCUMENT) {
            list.push(ans[0].attributes.answer[0] ? ans[0].attributes.answer[0]._url : "")
          }

          else {
            list.push(ans[0].attributes.answer[0])
          }
        } else {
          list.push("")
        }
      }

      reportData.push(list)
      setLoading(false)
    }


    //exel sheet logic

    let fileName = "";
    let sheetName = "";

    fileName = `${formName}_Report.xlsx`;
    sheetName = "Form_Data_Report";

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(reportData);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, fileName);

  }

  const processData2 = (form, sections, questions, sessions, formData) => {

    let horizontalTitles = []
    let questionTitles = [];

    let sectionRow = []
    let questionRow=[]

    for (const sec of sections) {

      for (const qus of questions) {
        if (qus.attributes.sectionId == sec.id) {

          questionTitles.push({

            section: sec,
            question: qus,
            sectionId: sec.id,
            questionId: qus.id,
            sectionName: sec.attributes.sectionName,
            questionName: qus.attributes.question,
          })

          sectionRow.push(sec.attributes.sectionName)
          questionRow.push(qus.attributes.question)
        }
      }

    }

    horizontalTitles.push({
      name: "Section Name"
    });
    horizontalTitles.push({
      name: "Question"
    });
    for (const secObj of sessions) {
      let sec = secObj.sessionParseObject
      horizontalTitles.push({
        sessionId: sec.id,
        session: sec,
        name: sec.attributes.fillingFor,
        kidId: sec.attributes.kidId,
      })
    }

    let reportData = [];


    reportData.push([`Form Name: ${formName}`]);
    reportData.push([`${franchiseMatch ? "Franchise Name" : "School Name"}: ${`${franchiseMatch ? props.franchise && props.franchise.attributes.name : props.selectedSchool && props.selectedSchool.attributes.Name}`}`]);
    reportData.push([``]);
    reportData.push([``]);


    reportData.push(sectionRow);
    reportData.push(questionRow);


    for (const secObj of sessions) { 
      let list = []
      let ansQuestionData = formData.filter((fd) => fd.attributes.sessionId == secObj.sessionParseObject.id)
      for (const qt of questionTitles) { 
        let ans = ansQuestionData.filter((fd) => fd.attributes.questionId == qt.questionId);
        if (ans && ans.length > 0) {
          if (qt.question.attributes.questionType == Constants.QESTION_DATE) {
            list.push(Moment(ans[0].attributes.answer[0]).format('DD-MM-YYYY'))
          } else if (qt.question.attributes.questionType == Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST) {
            let multiChoice = ""
            for (const a of ans[0].attributes.answer) {

              if (multiChoice) {
                multiChoice = multiChoice + a + ", ";

              } else {
                multiChoice = a + ", "
              }
            }
            list.push(multiChoice)

          } else if (qt.question.attributes.questionType == Constants.QESTION_YES_NO_COMMENT_ON_YES) {

            if (ans[0].attributes.answer[0]) {
              list.push("Yes, " + ans[0].attributes.answer[1])
            } else {
              list.push("No")
            }

          } else if (qt.question.attributes.questionType == Constants.QESTION_YES_NO_COMMENT_ON_NO) {

            if (ans[0].attributes.answer[0] == false) {
              list.push("No, " + ans[0].attributes.answer[1])
            } else {
              list.push("Yes")
            }
          }

          else if (qt.question.attributes.questionType == Constants.QESTION_YES_NO_NO_COMMENTS) {
            if (ans[0].attributes.answer[0] == true) {
              list.push("Yes")
            } else {
              list.push("No")
            }
          }

          else if (qt.question.attributes.questionType == Constants.QESTION_CHECKBOX) {
            if (ans[0].attributes.answer[0] == true) {
              list.push("Checked")
            } else {
              list.push("Unchecked")
            }
          }

          else if (qt.question.attributes.questionType == Constants.QESTION_UPLOAD_IMAGE) {
            list.push(ans[0].attributes.answer[0] ? ans[0].attributes.answer[0]._url : '')
          }

          else if (qt.question.attributes.questionType == Constants.QESTION_UPLOAD_DOCUMENT) {
            list.push(ans[0].attributes.answer[0] ? ans[0].attributes.answer[0]._url : "")
          }

          else {
            list.push(ans[0].attributes.answer[0])
          }
        } else {
          list.push("")
        }

      }

      reportData.push(list);
    }

    


    //exel sheet logic

    let fileName = "";
    let sheetName = "";

    fileName = `${formName}_Report.xlsx`;
    sheetName = "Form_Data_Report";

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(reportData);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, fileName);

  }

  return (
    <React.Fragment>
      <div className="page-content">
        {showDeleteAlert && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Delete"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="success"
            title="Alert!"
            onCancel={() => {
              setShowDeleteAlert(false);
            }}
            onConfirm={() => {
              setShowDeleteAlert(false);
              formToDelete.set("isDeleted", true);
              formToDelete.save().then(
                (result) => {
                  let tempList = [...displayList];
                  let newList = tempList.filter((object) => object.sessionParseObject.id != result.id)
                  setDisplayList(newList);
                  toastr.success("Form deleted successfully !!", {
                    timeOut: 2000,
                  });
                },
                (error) => {
                  toastr.warning(
                    "Something went wrong, please try again !!",
                    { timeOut: 2000 }
                  );
                }
              );
            }}
          >
            {"Are you sure you want to delete"}
            <br></br>
          </SweetAlert>
        )}
        <Modal
          isOpen={showMappingModal}
          fade={false}
          className="modal-dialog modal-xl"
        >
          <ModalHeader
            toggle={() => {
              if (showMappingModal === true) {
                setSelectedSessionObject(undefined);
                setSelectedUserId("");
                setSelectedUserType("");
              }
              setShowMappingModal(!showMappingModal);
            }}
          >
            Select student/teacher to map with this form entry
          </ModalHeader>
          <ModalBody
            style={{ maxHeight: `calc(100vh - 200px)`, overflowY: "auto" }}
          >
            <div>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Nav pills className="navtab-bg nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          Students
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          Teachers
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1" className="p-3">
                        <Col lg={12}>
                          <Row>
                            <Col lg={6}>
                              <FormGroup>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    handleClassroomFilterChange(e.target.value);
                                  }}
                                >
                                  {classroomList.map((classroomObject, key) => (
                                    <option key={key} value={classroomObject.id}>
                                      {classroomObject.attributes.Name}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg={6}>
                              <Input
                                type="text"
                                name="searchAG"
                                className="inner form-control mb-3"
                                placeholder="Search"
                                onChange={(e) => {
                                  handleSearchBarTextChange(e.target.value);
                                }}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <div className="table-responsive ml-3 mr-2">
                              <table className="table table-nowrap table-centered mb-0">
                                <tbody>
                                  {getAllKidsFilteredList().length === 0 && (
                                    <div className="ml-3 mt-3">
                                      <p>No students found</p>
                                      <hr />
                                    </div>
                                  )}
                                  {getAllKidsFilteredList().map(
                                    (kObject, keyInside) => (
                                      <tr key={keyInside}>
                                        <td style={{ width: "60px" }}>
                                          <input
                                            type="checkbox"
                                            id={"checkbox" + keyInside}
                                            onChange={() => {
                                              handleCheckboxChange(
                                                kObject,
                                                "student"
                                              );
                                            }}
                                            checked={
                                              kObject.id === selectedUserId
                                            }
                                          />
                                        </td>

                                        <td>
                                          <Label className="text-truncate font-size-14 m-0">
                                            {kObject.attributes.Name !==
                                              "Pick All" && (
                                                <img
                                                  src={getKidPhoto(kObject)}
                                                  className="rounded-circle avatar-xs m-1"
                                                  alt=""
                                                />
                                              )}
                                            <Link
                                              to="#"
                                              className="text-dark ml-2"
                                            >
                                              {kObject.attributes.Name}
                                            </Link>
                                          </Label>
                                        </td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Row>
                        </Col>
                      </TabPane>
                      <TabPane tabId="2" className="p-3">
                        <Col lg={12}>
                          <Row>
                            <div className="table-responsive ml-3 mr-2">
                              <table className="table table-nowrap table-centered mb-0">
                                <tbody>
                                  {(props.teachers == undefined ||
                                    props.teachers.length === 0) && (
                                      <div className="ml-3 mt-3">
                                        <p>No Teachers found</p>
                                        <hr />
                                      </div>
                                    )}
                                  {props.teachers &&
                                    getSortedTeachersList().map(
                                      (kObject, keyInside) => (
                                        <tr key={keyInside}>
                                          <td style={{ width: "60px" }}>
                                            <input
                                              type="checkbox"
                                              id={
                                                "checkboxTeachers" + keyInside
                                              }
                                              onChange={() => {
                                                handleCheckboxChange(
                                                  kObject,
                                                  "teacher"
                                                );
                                              }}
                                              checked={
                                                kObject.id === selectedUserId
                                              }
                                            />
                                          </td>

                                          <td>
                                            <Label className="text-truncate font-size-14 m-0">
                                              {kObject.attributes.Name !==
                                                "Pick All" && (
                                                  <img
                                                    src={getKidPhoto(kObject)}
                                                    className="rounded-circle avatar-xs m-1"
                                                    alt=""
                                                  />
                                                )}
                                              <Link
                                                to="#"
                                                className="text-dark ml-2"
                                              >
                                                {kObject.attributes.Name}
                                              </Link>
                                            </Label>
                                          </td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </Row>
                        </Col>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </ModalBody>
          <ModalFooter>
            {
              <Button
                color="primary"
                disabled={isMappingInProgress}
                onClick={() => {
                  setIsMappingInProgress(true);
                  let sessionObjectToSave =
                    selectedSessionObject.sessionParseObject;
                  sessionObjectToSave.set("userId", selectedUserId);
                  sessionObjectToSave.set("userType", selectedUserType);
                  sessionObjectToSave.set("userType", schoolId);
                  sessionObjectToSave.save().then(
                    (result) => {
                      let index = displayList.indexOf(selectedSessionObject);
                      let tempList = [...displayList];
                      let tempObj = selectedSessionObject;
                      tempObj.mappedTo = selectedUserName;
                      tempObj.mappedToType = selectedUserType

                      tempList[index] = tempObj;
                      setDisplayList(tempList);

                      setIsMappingInProgress(false);
                      setSelectedUserId("");
                      setSelectedUserType("");
                      setShowMappingModal(false);
                    },
                    (error) => {
                      setIsMappingInProgress(false);
                      toastr.warning("Please try again !!", { timeOut: 1000 });
                    }
                  );
                }}
              >
                {isMappingInProgress && (
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                )}
                Done
              </Button>
            }{" "}
            <Button
              color="secondary"
              onClick={() => {
                setSelectedSessionObject(undefined);
                setSelectedUserId("");
                setSelectedUserType("");
                setShowMappingModal(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {!isLoading && (
          <Container fluid>
            <Row className='d-flex justify-content-start'>
              <Col lg={8} className="d-flex align-items-start">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => { window.history.back() }}>
                  <ul className=" list-unstyled">
                    <div className=''>
                      <i className="bx bx-chevron-left h1 text-primary"></i>
                    </div>
                  </ul>
                </div>
                <div className=' m-2'>
                  <h4>{formName}</h4>
                </div>
              </Col>
              <Col lg={4} className="d-flex justify-content-end">
                {displayList.length > 0 && <div>
                  {
                    loading ?
                      <Button type="button" color="info" className=" waves-effect float-right" >
                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>Downloading...
                      </Button> :
                      <Button type="submit" className='float-right'
                        color="info"
                        onClick={() => {
                          getFormSection()
                        }}>
                        Download Report
                      </Button>

                  }
                </div>}
              </Col>
            </Row>
            <Card>
              <CardBody>
                {displayList.length > 0 && (
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th>{props.t("Name")}</th>
                          <th style={{ width: "40px", textAlign: "center" }}>
                            {props.t("Submitted On")}
                          </th>
                          {schoolMatch && <th style={{ width: "40px", textAlign: "center" }}>
                            {props.t("Mapped to")}
                          </th>}
                          {schoolMatch && <th style={{ width: "20px", textAlign: "center" }}>
                            {props.t("Map")}
                          </th>}
                          <th style={{ width: "20px" }}>
                            {props.t("View Details")}
                          </th>
                          <th style={{ width: "20px" }}>
                            {props.t("Delete")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayList.map((object, key) => (
                          <tr key={key}>
                            <td>
                              <Label className="font-size-12 mb-1">
                                {object.applicantName
                                  ? object.applicantName
                                  : "Entry " + (key + 1)}
                              </Label>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Label className="font-size-12 mb-1">
                                {object.sessionParseObject && Moment(object.sessionParseObject.createdAt).format('DD-MM-YYYY, h:mm a')}
                              </Label>
                            </td>
                            {schoolMatch && <td style={{ textAlign: "center" }}>
                              <Label className="font-size-12 mb-1">
                                {object.mappedTo !== "-" ? (`${object.mappedTo} (${object.mappedToType})`) : ""}
                                {object.mappedTo === "-" && object.mappedTo}
                              </Label>
                            </td>}
                            {schoolMatch && <td>
                              <Button
                                type="button"
                                color="primary"
                                size="sm"
                                className="btn-rounded waves-effect waves-light"
                                onClick={() => {
                                  setSelectedSessionObject(object);
                                  if (object.sessionParseObject && object.sessionParseObject.attributes.userId && object.sessionParseObject.attributes.userId.length > 0) {
                                    setSelectedUserId(object.sessionParseObject.attributes.userId);
                                    setSelectedUserType(object.sessionParseObject.attributes.userType);

                                    if (object.mappedTo && object.mappedTo.length > 0) {
                                      setSelectedUserName(object.mappedTo)
                                    } else {
                                      setSelectedUserName("")
                                    }


                                  } else {
                                    setSelectedUserName("")
                                    setSelectedUserId("");
                                    setSelectedUserType("");
                                  }

                                  setShowMappingModal(true);
                                }}
                              >
                                {props.t("Map")}
                              </Button>
                            </td>}
                            <td>
                              <Link
                                to={
                                  franchiseMatch
                                    ? `/franchise-printformview/${franchiseId}/${object.sessionParseObject.attributes.formId}/${formName && formName}/${object.sessionParseObject.id}`
                                    : `/printformview/${schoolId}/${object.sessionParseObject.attributes.formId}/${formName && formName}/${object.sessionParseObject.id}`
                                }
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  size="sm"
                                  className="btn-rounded waves-effect waves-light"
                                >
                                  {props.t("View Details")}
                                </Button>
                              </Link>
                            </td>
                            <td>
                              <div
                                className="flex-fill text-center"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setFormToDelete(object.sessionParseObject);
                                  setShowDeleteAlert(true);
                                }}
                              >
                                <i className="bx bxs-trash text-danger"></i>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {displayList.length === 0 && (
                  <p className="text-center">No form entries to display !!</p>
                )}
              </CardBody>
            </Card>
          </Container>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading Forms...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  const { teachers } = state.Teacher;
  const { classrooms } = state.Classroom;
  const { kids } = state.Kid;
  const { franchise } = state.Franchise;

  return { selectedSchool, teachers, kids, classrooms, franchise };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      getTeachersForSchool,
      getAllKidsForGroup,
      getClassroomsForSchoolId,
      getFranchise,
    })(FormView)
  )
);
