export const UPDATE_ACADEMIC_CYCLE = "UPDATE_ACADEMIC_CYCLE";
export const DELETE_ACADEMIC_CYCLE = "DELETE_ACADEMIC_CYCLE";

export const UPDATE_ACADEMIC_CYCLES = "UPDATE_ACADEMIC_CYCLES";   
export const GET_ACADEMIC_CYCLES = "GET_ACADEMIC_CYCLES";
export const GET_ACADEMIC_CYCLE = "GET_ACADEMIC_CYCLE";


export const UPDATE_CURRICULUM_ACADEMIC_CYCLE = "UPDATE_CURRICULUM_ACADEMIC_CYCLE";
export const DELETE_CURRICULUM_ACADEMIC_CYCLE = "DELETE_CURRICULUM_ACADEMIC_CYCLE";

export const UPDATE_CURRICULUM_ACADEMIC_CYCLES = "UPDATE_CURRICULUM_ACADEMIC_CYCLES";   
export const GET_CURRICULUM_ACADEMIC_CYCLES = "GET_CURRICULUM_ACADEMIC_CYCLES";
export const GET_CURRICULUM_ACADEMIC_CYCLE = "GET_CURRICULUM_ACADEMIC_CYCLE";
export const GET_CURRICULUM_CYCLES_CYCLE_ID = "GET_CURRICULUM_CYCLES_CYCLE_ID";


export const SET_CYCLE_LOADING_STATE = "SET_CYCLE_LOADING_STATE";
export const CLEAR_ACADEMIC_CYCLES = "CLEAR_ACADEMIC_CYCLES";

export const ADD_CURRICULUM_ACADEMIC_CYCLE = "ADD_CURRICULUM_ACADEMIC_CYCLE";

export const ACADEMIC_CYCLE_REORDER = "ACADEMIC_CYCLE_REORDER";

export const SET_SELECTED_ACADEMIC_CYCLE = "SET_SELECTED_ACADEMIC_CYCLE";

export const UPDATE_SELECTED_ACADEMIC_CYCLE = "UPDATE_SELECTED_ACADEMIC_CYCLE";