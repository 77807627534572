import React, { useEffect, useState } from 'react';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { Container, Row, Col, Card, CardBody, Table, CardImg } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { getFranchise, } from '../../../store/actions';
import logo from "../../../assets/images/lilLogo.png"
import * as WhiteLable from "../../../components/Common/WhiteLable"



import QRCode from 'qrcode'
import Parse from 'parse';

const FranchisePoster = (props) => {


    const match = matchPath(props.history.location.pathname, {
        path: "/franchiseposter/:franchiseid",
        exact: true,
        strict: false,
    });
    const franchiseId = match.params.franchiseid;

    const [loading, setLoading] = useState(true);
    const [formQRCode, setFormQRCode] = useState(undefined);
    const [posterType, setPosterType] = useState("a4");


    const [school, setSchool] = useState(undefined);

    const [schoolName, setSchoolName] = useState(undefined);
    const [schoolCity, setSchoolCity] = useState(undefined);
    const [schoolLogo, setSchoolLogo] = useState(undefined);
    const [showSaveAsPdfModal, setShowSaveAsPdfModal] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [machineType, setMachineType] = useState('');

    const HOST_NAME =  WhiteLable.getHost();
    const FRANCHISE_FORM = HOST_NAME + "/franchise-branch-enquiry/" + franchiseId;

    useEffect(() => {
        if (Parse.User && Parse.User.current() && Parse.User.current().attributes.franchiseId) {
            props.getFranchise(Parse.User.current().attributes.franchiseId);
        } else {
            props.history.push("/pages-404");
        }

        formQr();
    }, [franchiseId]);


    const getButtonSelectedClass = (type) => {

        if (type == posterType) {
            return "btn btn-primary waves-effect waves-light m-2"
        } else {
            return "btn btn-light waves-effect waves-light border-1 m-2"
        }
    }


    // function printPoster() {

    //     var css = "@page {size: A4,margin: 0; }",
    //         head = document.head || document.getElementsByTagName("head")[0],
    //         style = document.createElement("style");

    //     style.type = "text/css";
    //     style.media = "print";

    //     if (style.styleSheet) {
    //         style.styleSheet.cssText = css;
    //     } else {
    //         style.appendChild(document.createTextNode(css));
    //     }

    //     head.appendChild(style);

    //     window.print();
    // }

    function printPoster() {
        var css = "@page { size: A4;margin:0 0 0 0; }",
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

        style.type = "text/css";
        style.media = "print";
        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
        head.appendChild(style);
        setTimeout(() => {
           window.print();
        }, 300);
    }

    useEffect(() => {
        const savedValue = localStorage.getItem('dontShowAgain');
        if (savedValue === 'true') {
            setDontShowAgain(true);
        }
        const userAgent = navigator.userAgent.toLowerCase();
        
        if (userAgent.includes('mac')) {
            setMachineType('Mac');
        } else if (userAgent.includes('windows')) {
            setMachineType('Windows');
        } else {
            setMachineType('Unknown');
        }
      }, []);
    
      const handleNextClick = () => {
        setShowSaveAsPdfModal(false);
        setTimeout(() => {
            printPoster();
        }, 300);
      }


    function formQr() {
        console.log("FRANCHISE_FORM", FRANCHISE_FORM);

        QRCode.toDataURL(FRANCHISE_FORM, { errorCorrectionLevel: 'H' })
            .then(url => {
                setFormQRCode(url);
            })
            .catch(err => {
                console.error(err);
            });
    }


    return (
        <React.Fragment>
            <Container fluid="lg" className='p-0'>

                <Row className="text-center">
                    <Col xs="12" className="d-print-none m-3">
                        <div className="text-center">
                            <button onClick={() => { setPosterType("a4") }} className={getButtonSelectedClass("a4")}>A4</button>
                            <button onClick={() => { setPosterType("card") }} className={getButtonSelectedClass("card")}>Card</button>
                            <button onClick={() => { setPosterType("qrCode") }} className={getButtonSelectedClass("qrCode")}>QR Code</button>
                            <button onClick={() => {
                                    if (['Windows', 'Mac'].includes(machineType)) {
                                            if (!dontShowAgain) {
                                                setShowSaveAsPdfModal(true); 
                                            } else {
                                                printPoster();
                                            }
                                    } else{
                                        printPoster()
                                    }
                                    }} className="btn btn-success waves-effect waves-light m-2"><i className="fa fa-print"></i>Print Poster</button>
                                    <SaveAsPdf
                                        showModal={showSaveAsPdfModal}
                                        toggleModal={() => setShowSaveAsPdfModal(false)}
                                        dontShowAgain={dontShowAgain}
                                        setDontShowAgain={setDontShowAgain}
                                        onNextClick={handleNextClick}
                                        machineType={machineType}
                                    /> 
                        </div>
                    </Col>
                </Row>

                {posterType == "a4" && <Col xs="12">
                    <center>
                        <div style={{ width: "210mm", height: "296mm" }}>
                            <div style={{ height: "148mm", backgroundColor: "#fe1386" }}></div>
                            <div style={{ height: "148mm", backgroundColor: "#ffbd59" }}></div>

                            <div style={{ backgroundColor: "#fff", marginTop: "-135%", marginBottom: "-150%", width: "90%", height: "90%" }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "40%" }}>
                                                <img
                                                    src={(props.franchise && props.franchise.attributes.logo) ? props.franchise.attributes.logo._url : WhiteLable.getAppLogo()}
                                                    alt=""
                                                    className="rounded avatar-lg border mt-3"
                                                />
                                            </td>
                                            <td style={{ width: "60%" }}>
                                                <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{props.franchise?.attributes?.name}</span></center>
                                                {/* <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{props.franchise?.attributes?.city}</span></center> */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="col-md-12 m-5">
                                    <span style={{ fontSize: "30px", fontWeight: "bolder", color: "#5f5f5f" }}>
                                        PLEASE SCAN BELOW QR CODE <br />
                                        TO SUBMIT YOUR ENQUIRY
                                    </span>
                                </div>

                                <div className="col-md-12 mt-5 mb-5 text-center" style={{ marginLeft: "-15%", marginRight: "-15%" }}>
                                    <img className="img-responsive" src={formQRCode} style={{ width: "280px", marginTop: "1%" }} alt="QR Code" />
                                </div>

                                <div className="col-md-12 mt-5 mb-5">
                                    <p>{props.t('Powered By')}</p>
                                    <img className="img-responsive" src={WhiteLable.getAppLogoWithName()} style={{ width: "160px" }} />
                                </div>
                            </div>
                        </div>
                    </center>
                </Col>}
                {posterType == "card" && <Col>
                    <center>
                        <div style={{ minHeight: "200mm", width: "150mm", backgroundColor: "#fff", border: `15px solid #00ace5` }} className="text-center">

                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "40%" }} className="text-center">
                                            <img
                                                src={(props.franchise && props.franchise.attributes.logo) ? props.franchise.attributes.logo._url : logo}
                                                alt=""
                                                className="rounded avatar-lg border mt-3"
                                            />
                                        </td>
                                        <td style={{ width: "60%" }}>
                                            <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{props.franchise?.attributes?.name}</span></center>
                                            {/* <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{props.franchise?.attributes?.city}</span></center> */}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <span style={{ fontSize: "25px", fontWeight: "bolder", display: "flex", justifyContent: "center" }} className='m-4'>
                                {/* ARE YOU INTRESTED TO SETUP FRANCHISE WITH LILTRIANGLE ? */}
                                INTRESTED TO SETUP FRANCHISE WITH LILTRIANGLE ?

                            </span>
                            <div className="col-md-12 m-1">
                                <span style={{ fontSize: "20px", fontWeight: "bolder", display: "flex", justifyContent: "center" }}>
                                    PLEASE SCAN BELOW QR CODE <br />
                                    TO SUBMIT YOUR ENQUIRY
                                </span>
                                <div className='text-center'>
                                    <div className="m-1" style={{ minHeight: "50px", width: "400px", backgroundColor: "#fff", display: "inline-block" }}>
                                        <div className="col-md-12">
                                            <img className="img-responsive" src={formQRCode} style={{ width: "250px", margin: "0 auto", }} />
                                        </div>
                                    </div>
                                </div>
                                {/* <p className='m-1' style={{ fontWeight: 'bolder', fontSize: '40px', color: "000000", display: "flex", justifyContent: "center" }}>lilTriangle</p> */}
                                <hr style={{ border: "0.9px solid #000000", marginLeft: "6cm", marginRight: "6cm" }} />
                                <div className="col-md-12 ">
                                    <p className='m-1' style={{ marginLeft: "30mm" }}>{props.t('Powered By')}</p>
                                    <img className="img-responsive" src={WhiteLable.getAppLogoWithName()} style={{ width: "160px" }} />
                                </div>
                            </div>

                        </div>
                    </center>
                </Col>}
                {posterType == "qrCode" && (
                    <Col>
                        <div className="col-md-12 d-flex justify-content-center align-items-center m-5" style={{ marginLeft: "-10%", marginTop: "4%", marginRight: "-10%" }}>
                            <img className="img-responsive" src={formQRCode} style={{ width: "280px", marginTop: "1%" }} alt="QR Code" />

                        </div>
                    </Col>
                )}



            </Container>
        </React.Fragment>


    );
}

const mapStatetoProps = state => {
    const { franchise } = state.Franchise;

    return { franchise, };
};



export default withNamespaces()(withRouter(connect(mapStatetoProps, { getFranchise, })(FranchisePoster)));
