import React, { useEffect,useState} from 'react';
import { withNamespaces } from 'react-i18next';
import { getSchoolForInstitute,setSelecetdSchool,getCorporatePartners} from '../../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Select from "react-select";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle,
    Container
  
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';



const CorporatePartnerReport = (props) => {



    const [allKids, setAllKids] = useState([]);
    const [selctedCorporateObject, setSelctedCorporateObject] = useState(undefined);
    const [parentObjects, setParentObjects] = useState([]);

    const [classroomObjects, setClassroomObjects] = useState([]);

    const [selectedDate, setSelectedDate] = useState(undefined);
    const [selectedEndDate, setSelectedEndDate] = useState(undefined);

    const [loadingState, setloadingState] = useState(undefined);

    const [schoolData, setSchoolData] = useState([]);
    const [kidData, setKidData] = useState([]);


    

    const [totalInTime, setTotalInTime] = useState();
    const [displayTitles, setDisplayTitles] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [totalCheckins, setTotalCheckins] = useState([]);
    const [allSchools, setAllSchools] = useState([]);
    const [selectedSchool, setSelctedSchool] = useState({ value: "allSchools", label: 'All Schools' });
    const [activeKids, setActiveKids] = useState();

    useEffect(() => {
        if (props.schools) {
            let list = [{ value: "allSchools", label: 'All Schools' }];
            for (const school of props.schools) {
                list.push({ value: school.id, label: school.attributes.Name })
            }
            setAllSchools(list);
        }
    }, [props.schools]);
    

    useEffect(() => {
        if (Parse.User.current().attributes.franchiseId) {
            
            props.getSchoolForInstitute(Parse.User.current().attributes.franchiseId, "franchiseId");
            if(props.showCorporatePartners){
                props.getCorporatePartners(Parse.User.current().attributes.franchiseId, "franchiseId")
            }
        }
    }, [Parse.User.current().attributes.franchiseId]);

   
    

    const getStudentReport = () => {
                
        setloadingState(0);
        let schoolIds = [];
        let query = new Parse.Query("Kid");
        query.notEqualTo('Deleted', true);

        if (props.isFranchise) {
            if (selectedSchool && selectedSchool.value != 'allSchools') {
                schoolIds.push(selectedSchool.value)
            } else {
                for (const school of props.schools) {
                    schoolIds.push(school.id)
                }
            }

            query.containedIn('SchoolID', schoolIds)
            query.limit(schoolIds.length * 500);
            
            
        } else {
            query.equalTo('SchoolID', props.schoolId)
            query.limit(1000);
        }
        
        query.ascending('Name')
        query.find().then((kids) => {
            // console.log('kids',kids)
            setAllKids(kids);

            let familyIds = [];
            for (const k of kids) {
                if (!familyIds.includes(k.attributes.FamilyID)) {
                    familyIds.push(k.attributes.FamilyID)
                }
            }
            getParentsInLoop(schoolIds, kids,familyIds, 0, [])
        });
    }

    const getParentsInLoop = (schoolIds, kids, familyIds, skip, exList) => {

        let nextSkip = skip + 475;
        let newFamilyIds = [];

        if (familyIds.length>=nextSkip) {
            newFamilyIds = familyIds.slice(skip, nextSkip);
        } else {
            newFamilyIds = familyIds.slice(skip, familyIds.length);
        }

        // console.log("nextSkip", nextSkip);

        if (newFamilyIds.length > 0) {
            let query2 = new Parse.Query("Parent");
            query2.notEqualTo('isDeleted', true);
            query2.containedIn('FamilyID', newFamilyIds);
            query2.limit(1000)
            query2.find().then((aObjects) => {
                let oldList = [...exList];
                if (aObjects) {
                    oldList = oldList.concat(aObjects)
                }
                getParentsInLoop(schoolIds, kids, familyIds, nextSkip, oldList)
                setParentObjects(oldList);
            });
        } else {
            getClassroomData(schoolIds, kids, exList, 0, []);
        }
        
        
        
    }
    const getParentData = (schoolIds, kids,familyIds, skip,  exList) => {
        let query2 = new Parse.Query("Parent");
        query2.notEqualTo('isDeleted', true);
        query2.containedIn('FamilyID', familyIds);
        query2.limit(1000)
        query2.skip(skip);
        

        query2.find().then((aObjects) => {
            let oldList = [...exList];
            if (aObjects) {
                oldList = oldList.concat(aObjects)
            }
            if (aObjects && aObjects.length == 1000) {
                getParentData(schoolIds, kids,familyIds, skip + 1000, oldList)
            } else {
                setParentObjects(oldList);
                getClassroomData(schoolIds, kids, oldList, 0, []);
               
            }
        });
    }

    const getClassroomData = (schoolIds, kids,parents,skip, exList) => {
        let query2 = new Parse.Query("ClassRoom");
        query2.containedIn('SchoolID', schoolIds);
        query2.limit(1000)
        query2.skip(skip);
        query2.notEqualTo('isDeleted', true);

        query2.find().then((aObjects) => {
            let dList = [...exList];
            if (aObjects) {
                dList = dList.concat(aObjects)
            }
            if (aObjects && aObjects.length == 1000) {
                getClassroomData(schoolIds, kids, parents,skip + 1000, dList)
            } else {
                setClassroomObjects(dList);
                setloadingState(2);
                prepareData(kids,parents,dList);
            }
        });
    }

   

    const getDateInDDMMYYYY = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };

    

    const prepareData = (kids, parents, classromms) => {

    
        let list = [];
        let list2 = [];
        


        let finalData = [];
        let title = [];
        
        title.push("#");
        title.push("Student Name");
        title.push("School Name");
        title.push("State")
        title.push("Class Name");
        {props.showCorporatePartners&&title.push("Corporate Name");}
        title.push("Gender");
        title.push("Date of Birth");
        title.push("Admission Number");
        title.push("Date Of Admission");
        title.push("Father Name");
        title.push("Father Email");
        title.push("Father Phone");
        {props.showCorporatePartners&&title.push("Father Corporate EmailId");}
        {props.showCorporatePartners&&title.push("Father EmployeeId");}
        title.push("Mother Name");
        title.push("Mother Email");
        title.push("Mother Phone");
        {props.showCorporatePartners&&title.push("Mother Corporate EmailId");}
        {props.showCorporatePartners&&title.push("Mother EmployeeId");}
        title.push("Address");

        setDisplayTitles(title);
        
        let schoolObjects = props.schools;
        if (props.isFranchise && selectedSchool && selectedSchool.value != 'allSchools') {
            schoolObjects = props.schools.filter((s) => s.id == selectedSchool.value);
        }

        let count = 0;
        let lKids = [...kids];
        let lParents = [...parents];
        for (const school of schoolObjects) {

            let kidList = [];
            let nlKids=[]
            let sKids = [];
            for (const k of lKids) {
                if (k.attributes.SchoolID == school.id) {
                    sKids.push(k);
                } else {
                    nlKids.push(k);
                }
            }

            lKids = [...nlKids];

           
            let sClass = classromms.filter(
                (ao) => ao.attributes.SchoolID == school.id
            );

            
            for (const kid of sKids) {
                count++
                // console.log(count);
                let tempKid = {};
                tempKid.id = kid.id;
                tempKid.name = kid.attributes.Name;
            
                let kClass = sClass.filter(
                    (ao) => ao.id == kid.attributes.ClassRoomID
                );

                let corporate = kid.attributes.corporateId && props.showCorporatePartners && props.corporatePartners.filter(
                    (p) => p.id == kid.attributes.corporateId
                );

                let kParents = [];
                // let kParents = parents.filter(
                //     (p) => p.attributes.FamilyID == kid.attributes.FamilyID
                // );

                let nlParents = [];
                for (const p of lParents) {
                    if (p.attributes.FamilyID == kid.attributes.FamilyID) {
                        kParents.push(p);
                    } else {
                        nlParents.push(p);
                    }
                }

                lParents = [...nlParents];

                for (const pa of kParents) {
                    if (pa.attributes.Relation == 'Father') {
                        tempKid.fatherName = pa.attributes.Name;
                        tempKid.fatherEmail = pa.attributes.EMail;
                        tempKid.fatherPhone = pa.attributes.PhoneNumber;
                        tempKid.fathercorporateEmployeeId = pa.attributes.corporateEmployeeId;
                        tempKid.fatherCorporateEmailId = pa.attributes.corporateEmailId;
                    } else if (pa.attributes.Relation == 'Mother') {
                        tempKid.motherName = pa.attributes.Name;
                        tempKid.motherEmail = pa.attributes.EMail;
                        tempKid.motherPhone = pa.attributes.PhoneNumber;
                        tempKid.mothercorporateEmployeeId = pa.attributes.corporateEmployeeId;
                        tempKid.motherCorporateEmailId = pa.attributes.corporateEmailId;
                    }
                }

                
                tempKid.schoolName = school.attributes.Name;
                tempKid.state = school.attributes.state;
                tempKid.className = kClass.length > 0 ? kClass[0].attributes.Name : "";
                tempKid.corporateName = (corporate && corporate.length > 0) ? corporate[0].attributes.name : "";

                tempKid.gender = kid.attributes.Gender;
                tempKid.dob = getDateInDDMMYYYY(kid.attributes.DateOfBirth);
                tempKid.doa = getDateInDDMMYYYY(kid.attributes.createdAt);
                tempKid.admissionNumber = kid.attributes.admissionNumber;
                tempKid.address = kid.attributes.address;
                

                let temp = [];
                temp.push(count);
                temp.push(tempKid.name);
                temp.push(tempKid.schoolName);
                temp.push(tempKid.state)
                temp.push(tempKid.className);
                { props.showCorporatePartners && temp.push(tempKid.corporateName); }
                temp.push(tempKid.gender);
                temp.push(tempKid.dob);
                temp.push(tempKid.admissionNumber);
                temp.push(tempKid.doa);
                temp.push(tempKid.fatherName);
                temp.push(tempKid.fatherEmail);
                temp.push(tempKid.fatherPhone);
                { props.showCorporatePartners && temp.push(tempKid.fatherCorporateEmailId); }
                { props.showCorporatePartners && temp.push(tempKid.fathercorporateEmployeeId); }
                temp.push(tempKid.motherName);
                temp.push(tempKid.motherEmail);
                temp.push(tempKid.motherPhone );
                { props.showCorporatePartners && temp.push(tempKid.motherCorporateEmailId); }
                { props.showCorporatePartners && temp.push(tempKid.mothercorporateEmployeeId); }
                temp.push(tempKid.address);
                
                
                finalData.push(temp);

                let tKid = {
                    id: kid.id,
                    school: school,
                    kid: kid,
                    kidData: tempKid
                }

                kidList.push(tKid);
                list.push(tKid);
            
            }
                       
            let schoolObject = {
                id: school.id,
                school: school,
                kidList: kidList,
            }

            list2.push(schoolObject)
            
        }

        setDisplayData(finalData);
        setloadingState(3);
        setKidData(list);
        setSchoolData(list2);
            
    }


    const onClickDownload = () => {
       
        let finalData3 = []
        let fileName = `student_report.xlsx`;

        finalData3.push(["Student Report"]);
        finalData3.push(["Report For: " + selectedSchool.label]);
        finalData3.push(["Total Students:" + displayData.length])
        finalData3.push([]);
        finalData3.push(displayTitles);
        finalData3 = finalData3.concat(displayData)

        if (props.isFranchise) {
            let wb = XLSX.utils.book_new();
            let ws3 = XLSX.utils.aoa_to_sheet(finalData3);
            ws3['!cols'] = Array(displayTitles.length).fill({ wpx: 100 });
            XLSX.utils.book_append_sheet(wb, ws3, "Student Report");
            XLSX.writeFile(wb, fileName);
        }
    }
    

    return (
        <React.Fragment>
            
            <Row className="bg-light ">
               
                <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>{props.t("Selcted school")} :</Label>
                        
                         <Select
                            value={selectedSchool}
                            onChange={(v) => {
                                setSelctedSchool(v)
                                setloadingState(undefined);
                            }}
                            options={allSchools}
                            className="selectPaymentType"
                            classNamePrefix="selectPayType"
                            
                        />
                    </FormGroup>
                </Col>
                
                <Col md="2" xs="12" xl="2" lg="2">
                    <div className="mt-2">
                        <div className="mt-3">
                            <button
                                className="btn btn-primary mt-4 mr-2"
                                onClick={getStudentReport}
                            >
                                {props.t("Generate")}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>

            {(loadingState === 0 || loadingState === 2) &&
                <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState === 0 ? "getting report data..." : "Preparing Report"}</Label>
                    </div>
                </Col>
            }
            

            {loadingState == 3 && schoolData && displayData && <Card>
                <CardBody>
                   
                    <CardTitle>{"Student Data"}</CardTitle>
                    <p className='mb-0'>{"Report For: " + selectedSchool.label}</p>
                  
                    <p>{"Total Students:" + displayData.length}</p>

                     <Col lg={12}>
                        <div className="mt-2 mb-2 p-1 d-flex d-flex justify-content-end">
                            <button
                                onClick={() => {
                                    if (displayData && displayData.length > 0) {
                                        onClickDownload();
                                    }
                                                            
                                }}
                                className="w-md  btn btn-primary"
                            >
                                <i className='bx bx-download font-size-16 align-middle m-1'></i>
                                {props.t("Download Report")}
                            </button>
                        </div>
                    </Col>
                        
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table className="table table-striped table-bordered">

                            <Thead>
                                <Tr>
                                    {displayTitles.map((val2, key) => (
                                        <Th key={key}>{val2}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {displayData && displayData.map((cData, key) => (
                                    <Tr key={key}>
                                        {cData.map((val2, key2) => (
                                            <Td key={key2}>{val2}</Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </div>
                   
                </CardBody>
            </Card>}
        </React.Fragment>
    );
}
            

const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { franchise } = state.Franchise;
    const { corporatePartners, corporatePartnerAddLoading } = state.CorporatePartners;
    return {
        schools, selectedSchool,
        franchise,
        corporatePartners, corporatePartnerAddLoading,
    };
}
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
     getSchoolForInstitute,setSelecetdSchool,getCorporatePartners
    })(CorporatePartnerReport)
  )
);