import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import Parse from "parse";

const MedicalDetails = (props) => {
  const bloodGroups = [
    { value: "A+", label: "A+" },
    { value: "B+", label: "B+" },
    { value: "O+", label: "O+" },
    { value: "AB+", label: "AB+" },
    { value: "A-", label: "A-" },
    { value: "B-", label: "B-" },
    { value: "O-", label: "O-" },
    { value: "AB-", label: "AB-" },
    { value: "Not Found", label: "Not Found?"}
  ];

  const nonFoundBloodGroups = [
    { value: "A1+", label: "A1+" },
    { value: "A1-", label: "A1-" },
    { value: "A2+", label: "A2+" },
    { value: "A2-", label: "A2-" },
    { value: "B1+", label: "B1+" },
    { value: "B1-", label: "B1-" },
    { value: "B2+", label: "B2+" },
    { value: "B2-", label: "B2-" },
    { value: "B3+", label: "B3+" },
    { value: "B3-", label: "B3-" },
    { value: "A3+", label: "A3+" },
    { value: "A3-", label: "A3-" },
    { value: "Ax+", label: "Ax+" },
    { value: "Ax-", label: "Ax-" },
    { value: "Weak D+", label: "Weak D" },
    { value: "Partial D", label: "Partial D" },
  ];

  const [bloodGroup, setBloodGroup] = useState("");
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [allergies, setAllergies] = useState();
  const [medicalNotes, setMedicalNotes] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [bloodGroupOptions, setBloodGroupOptions] = useState(bloodGroups);
  
  useEffect(() => {
    if (props.kidMedicalObj && props.kidMedicalObj.attributes.KidID==props.kid.id) {
      const savedBloodGroup = props.kidMedicalObj.attributes.BloodGroup;
      setBloodGroup(savedBloodGroup);
      const isNonFoundGroup = nonFoundBloodGroups.some(group => group.value === savedBloodGroup);
      if (isNonFoundGroup) {
        setBloodGroupOptions(nonFoundBloodGroups);
      } else {
        setBloodGroupOptions(bloodGroups);
      }
      setHeight(props.kidMedicalObj.attributes.Height);
      setWeight(props.kidMedicalObj.attributes.Weight);
      setAllergies(props.kidMedicalObj.attributes.AllergiesAndReactions);
      setMedicalNotes(props.kidMedicalObj.attributes.MedicalNotes);
    }
  }, [props.kidMedicalObj]);

  function handleSubmit() {
    // console.log(height);
    // console.log(weight);
    // console.log(bloodGroup);
    // console.log(allergies);
    // console.log(medicalNotes);

    const MedicalDetails = Parse.Object.extend("MedicalDetails");
    let medicaldetails = new MedicalDetails();
    if (props.kidMedicalObj && props.kidMedicalObj.attributes.KidID==props.kid.id) {
      medicaldetails = props.kidMedicalObj;
    } else {
      medicaldetails.set("KidID", props.kid.id)
    }

    medicaldetails.set("Height", height);
    medicaldetails.set("Weight", weight);
    medicaldetails.set("BloodGroup", bloodGroup);
    medicaldetails.set("AllergiesAndReactions", allergies);
    medicaldetails.set("MedicalNotes", medicalNotes);
    props.handleSaveMedicalDetails(medicaldetails);
    setShowPopup(false);
  }

  const reset = () => {
    if (props.kidMedicalObj && props.kidMedicalObj.attributes.KidID==props.kid.id) {
      setBloodGroup(props.kidMedicalObj.attributes.BloodGroup);
      setHeight(props.kidMedicalObj.attributes.Height);
      setWeight(props.kidMedicalObj.attributes.Weight);
      setAllergies(props.kidMedicalObj.attributes.AllergiesAndReactions);
      setMedicalNotes(props.kidMedicalObj.attributes.MedicalNotes);
    } else {
      setBloodGroup(undefined);
      setHeight(undefined);
      setWeight(undefined);
      setAllergies(undefined);
      setMedicalNotes(undefined);
    }
    setShowPopup(false)
  }

  return (
    <React.Fragment>
      <div className=" d-flex align-items-center justify-content-between">
        <h5 className="center">{"Medical Details"}</h5>
       {!props.kid.attributes.Deleted && <div className="page-title-right mr-3 contact-links d-flex font-size-20">
          <div className="flex-fill mr-2 ">
            <Button
              color="primary"
              className="btn btn-sm mb-1"
              onClick={() => {
                setShowPopup(true);
              }}
              id="medical-edit"
            >
              <i className="bx bx-edit-alt"></i>
              <span>Edit</span>
            </Button>
            <UncontrolledTooltip placement="top" target={"medical-edit"}>
              {props.t("Edit")}
            </UncontrolledTooltip>
          </div>
        </div>}
      </div>

      <Table className="table mb-0 table-bordered">
        <tbody>
          <tr style={{ "white-space": "pre-wrap" }}>
               <td width="33%">
              <b>
                <i
                  className="bx bxs-ruler"
                  style={{ transform: "rotate(90deg)" }}
                ></i>
                <span> {props.t("Height (in cm)")}</span>
              </b>
              <span> : {height}</span>{" "}
            </td>
            <td>
              <b>
                <i className="bx bx-street-view"></i>
                <span>{props.t("Weight (in Kg)")}</span>
              </b>{" "}
              : {weight}
            </td>
            <td>
              <b>
                <i className="bx bx-donate-blood"></i>
                <span> {props.t("Blood Group")}</span>
              </b>{" "}
              : {bloodGroup}
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <b>
                <i className="bx bx-capsule " />
                <span> {props.t("Allergies And Reactions")}</span>
              </b>{" "}
              : {allergies}
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <b>
                {" "}
                <i className="bx bx-note " />
                <span> {props.t("Medical Notes")}</span>
              </b>{" "}
              : {medicalNotes}
            </td>
          </tr>
        </tbody>
      </Table>

      <b></b>
      <b></b>
      <h5 className="mt-4">
        {props.t("Immunization")}
      </h5>

      <Table className="table mb-0 table-bordered">
        <thead>
          <tr>
            <th>{props.t("Vaccine Name")}</th>
            <th>{props.t("Administered Status")}</th>
            <th>{props.t("Administered Date")}</th>
          </tr>
        </thead>
        <tbody>
          {props.vaccines.map((vaccine, key) => (
            <tr key={key}>
              <td>
                {vaccine.name} (<b>{vaccine.month}</b> Months)
              </td>
              <td>{vaccine.isChecked ? "Yes" : "No"}</td>
              <td>{vaccine.administeredDate}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        size="lg"
        isOpen={showPopup}
        toggle={() => {
          reset();
        }}
      >
        <ModalHeader
          toggle={() => {
            reset();
          }}
        >
          {" "}
          {props.t("Medical Details")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="height">
                    <b>Height</b>
                  </Label>
                  <Input
                    type="number"
                    name="height"
                    placeholder="Height in cm"
                    value={height}
                    onChange={(e) => {
                      setHeight(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="weight">
                    <b>Weight</b>
                  </Label>
                  <Input
                    type="number"
                    name="weight"
                    placeholder="Weight in (kg)"
                    value={weight}
                    onChange={(e) => {
                      setWeight(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="blood group">
                    <b>Blood Group</b>
                  </Label>
                  <select
                    className="form-control "
                    style={{ width: "14rem" }}
                    value={bloodGroup}
                    onChange={(e) => {
                      const selectedVal = e.target.value;

                      if (selectedVal === "Not Found") {
                        setBloodGroupOptions(nonFoundBloodGroups);
                        setBloodGroup("");
                      } else {
                        setBloodGroup(selectedVal);
                      }
                    }}
                  >
                    <option disabled value="">Pick Blood Group</option>
                    {bloodGroupOptions.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="allergies">
                    <b>Allergies And Reactions</b>
                  </Label>
                  <Input
                    type="textarea"
                    rows="8"
                    name="allergies"
                    placeholder="Write here..."
                    value={allergies}
                    onChange={(e) => {
                      setAllergies(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="medical notes">
                    <b>Medical Notes</b>
                  </Label>
                  <Input
                    type="textarea"
                    rows="8"
                    name="notes"
                    placeholder="Write here..."
                    value={medicalNotes}
                    onChange={(e) => {
                      setMedicalNotes(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            <i className="align-middle"></i>
            Save
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default withNamespaces()(MedicalDetails);
