import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withNamespaces } from 'react-i18next';
import Parse from 'parse';
import {
  registerUser,
  apiError,
  registerUserFailed,
} from "../../store/actions";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/lilLogo.png";

import * as WhiteLable from "../../components/Common/WhiteLable";

const Register = (props) => {

  function handleValidSubmit(event, values) {
    props.registerUser(values, WhiteLable.getAppName());
  }

  const [userEmail, setUserEmail] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState("123456");
  const [reRequest, setReRquest] = useState(0);

  useEffect(() => {
    if (props.registrationError == "taken") {
      setErrorMsg("Email address  already taken");
    } else if (props.registrationError === "wrong") {
      setErrorMsg(props.t('Oops! Something went wrong'));
    } else {
      setErrorMsg(props.registrationError);
    }
  }, [props.registrationError]);

  useEffect(() => {
    if (props.user) {
      props.history.push("email-verification/" + userEmail)
    }
  }, [props.user]);


  function requestVerificationEmail() {

    try {

      setReRquest(1);
      let user = Parse.User.current();
      var email = user.attributes.email;
      var dummyEmail = email + ".unverified"
      user.set("email", dummyEmail);
      user.save().then(
        (result) => {
          let newuser = Parse.User.current();
          newuser.set("email", email);
          newuser.save().then(
            (result) => {
              setReRquest(2);
            },
            (error) => {


            }
          );
        },
        (error) => {
          console.log(error);
          let newuser = Parse.User.current();
          newuser.set("email", email);
          newuser.save().then(
            (result) => {
              setReRquest(2);
            },
            (error) => {


            }
          );
        }
      );

    } catch (e) {

    }

  };

  function getTitle() {

    return 'Get your '+WhiteLable.getAppName() +' account now.'

  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{props.t('Free Register')}</h5>
                        <p>{getTitle()}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    {WhiteLable.isSanfortApp() ? (
                      <div className="avatar-lg">
                        <span className="avatar-title bg-white">
                          <img
                            src={WhiteLable.getAppLogo()}
                            alt=""
                            className=""
                            height="60"
                            style={{ marginTop: '-2.5cm', marginLeft: '1cm' }}
                          />
                        </span>
                      </div>
                    ) : WhiteLable.isJumpStartApp() ? (
                      <div className="avatar-lg">
                        <span className="avatar-title bg-white">
                          <img
                            src={WhiteLable.getAppLogo()}
                            alt=""
                            className="bg-white p-1"
                            height="60"
                            style={{ marginTop: '-2.5cm', marginLeft: '2cm', border: '2px solid #556ee62e' }}
                          />
                        </span>
                      </div>
                    ) : WhiteLable.isArtklubApp() ? (
                      <div className="avatar-lg">
                        <span className="avatar-title bg-white">
                          <img
                            src={WhiteLable.getAppLogo()}
                            alt=""
                            className="bg-white p-1"
                            height="60"
                            style={{
                              marginTop: '-2.5cm', marginLeft: '2cm', border: '2px solid #556ee62e'
                            }}
                          />
                        </span>
                      </div>

                    ) : WhiteLable.isMakoonsApp() ? (
                      <div className="avatar-lg">
                        <span className="avatar-title bg-white">
                          <img
                            src={WhiteLable.getAppLogo()}
                            alt=""
                            className="bg-white p-1"
                            height="60"
                            style={{
                              marginTop: '-2.5cm', marginLeft: '2cm', border: '2px solid #556ee62e'
                            }}
                          />
                        </span>
                      </div>

                    ) : WhiteLable.isCuddlingBeeApp() ? (
                      <div className="avatar-lg">
                        <span className="avatar-title bg-white">
                          <img
                            src={WhiteLable.getAppLogo()}
                            alt=""
                            className="bg-white"
                            height="60"
                            style={{
                              marginTop: '-2.2cm', marginLeft: '2cm', border: '2px solid #556ee62e'
                            }}
                          />
                        </span>
                      </div>) : (
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={WhiteLable.getAppLogo()}
                            alt=""
                            className="rounded-circle"
                            height="50"
                          />
                        </span>
                      </div>
                    )}
                  </div>



                  {props.user && props.user && false ?
                    <div>
                      <p className="text-muted mb-0"> {props.t('Dear User')}.</p>
                      <br />
                      <p className="text-muted mb-0">{props.t('We have sent an email to')}</p>
                      <p className="text-dark mb-0">{userEmail}</p>
                      <br />
                      <p className="text-muted mb-0">{props.t('You need to verify your email to Login.')}</p>
                      <p className="text-muted mb-0">{props.t('If you have not received the verification email, please check your “spam” or "Bulk Email" folder. You can also click the resend Button below to have another email sent to you')}.</p>
                      <br />

                      {reRequest === 0 || reRequest === 2 ?
                        <div>
                          <button
                            className="btn btn-sm btn-secondary"
                            onClick={() => {
                              requestVerificationEmail();
                            }}
                          >
                            {props.t('Resend verification Email')}
                          </button>
                          {reRequest === 2 && <p className="text-infp mb-0 ml-1"> {props.t("Resend successfull..!")}</p>}
                        </div>
                        :
                        <div >
                          <i className="bx bx-loader bx-spin font-size-20 align-middle mr-2"></i>
                        </div>
                      }



                      <h5 className="mt-3 ">{props.t('OR')}</h5>
                      <div className="mt-3">

                        <Link to="/login">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            {props.t('Login')}
                          </button>
                        </Link>
                      </div>
                    </div>
                    :

                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v);
                        }}
                      >

                        {props.registrationError && props.registrationError ? (
                          <Alert color="danger">{errorMsg}</Alert>
                        ) : null}

                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                            value={userEmail}
                            onChange={(e) => { setUserEmail(e.target.value) }}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t('Please enter your email'),
                              },
                              pattern: {
                                value:
                                  "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                errorMessage: props.t('E-Mail is not valid!'),
                              },
                            }}

                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            name="password"
                            label={props.t('Password')}
                            type="password"
                            // value={password}
                            // onChange={(e)=>{setPassword(e.target.value)}}
                            // required
                            placeholder="Enter Password"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t('Please enter Password'),
                              },
                              minLength: {
                                value: 6,
                                errorMessage:
                                  props.t('Your password must be between 6 and 8 characters'),
                              },
                              maxLength: {
                                value: 16,
                                errorMessage:
                                  props.t('Your password must be between 6 and 8 characters'),
                              },
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <AvField
                            name="confirmpassword"
                            label={props.t('Confirm password')}
                            type="password"
                            // value={passwordMatch}
                            // onChange={(e)=>{setPasswordMatch(e.target.value)}}
                            placeholder={props.t('Confirm password')}
                            errorMessage="Enter Re-password"
                            validate={{
                              required: { value: true },
                              match: { value: "password" }
                            }}
                          />
                        </div>

                        {
                          props.loading ?
                            <div className="mt-3">
                              <button className="btn btn-primary btn-block waves-effect waves-light" style={{ width: '100%' }}>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                              </button>
                            </div>
                            :
                            <div className="mt-4">
                              <button
                                style={{ width: '100%' }}
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                              >
                                {props.t('Register')}
                              </button>
                            </div>}

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            {props.t('By registering you agree to the') + " " + WhiteLable.getAppName()}
                            <Link
                              style={{ cursor: "pointer" }}
                              to="/termsofUse"
                              target="_blank"
                              className="text-primary">
                              {" "}{props.t('Terms of Use')}
                            </Link>
                          </p>
                        </div>
                      </AvForm>
                    </div>}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {props.t('Already have an account ?')}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    {props.t('Login')}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} {WhiteLable.getFooterName()}. {WhiteLable.getDesignTag()}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default withNamespaces()(connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register));
