import * as actions from "./actionTypes";

export const updateAcademicCycle = (academicCycle) => {
  return {
    type: actions.UPDATE_ACADEMIC_CYCLE,
    payload: { academicCycle },
  };
};

export const deleteAcademicCycle = (academicCycle) => {
  return {
    type: actions.DELETE_ACADEMIC_CYCLE,
    payload: {academicCycle},
  };
};

export const updateAcademicCycles = (academicCycles,type) => {
  return {
    type: actions.UPDATE_ACADEMIC_CYCLES,
    payload: { academicCycles,type },
  };
};

export const getAcademicCycleForSchoolId = (schoolId) => {
    return {
      type: actions.GET_ACADEMIC_CYCLES,
      payload: { schoolId },
    };
};

export const clearAcademicCycles = () => {
  return {
    type: actions.CLEAR_ACADEMIC_CYCLES,
    payload: {  },
  };
};

export const getAcademicCycleForCurriculumId = (curriculumId) => {
  return {
    type: actions.GET_CURRICULUM_ACADEMIC_CYCLES,
    payload: { curriculumId },
  };
};

export const updateCurriculumAcademicCycles = (curriculumAcademicCycles, type) => {
  return {
    type: actions.UPDATE_CURRICULUM_ACADEMIC_CYCLES,
    payload: { curriculumAcademicCycles,type },
  };
};
export const setAcademicCycleLodingState = (state) => {
  return {
    type: actions.SET_CYCLE_LOADING_STATE,
    payload: { state },
  };
};

export const addAcademicCycle = (academicCycle,message) => {
  return {
    type: actions.ADD_CURRICULUM_ACADEMIC_CYCLE,
    payload: {academicCycle,message},
  };
};

export const reorderCycles = (cycles,type,message) => {
  return {
    type: actions.ACADEMIC_CYCLE_REORDER,
    payload: { cycles,type,message },
  };
};

export const setSelectedAcademicCycle = (academicCycleId) => {
  return {
    type: actions.SET_SELECTED_ACADEMIC_CYCLE,
    payload: { academicCycleId },
  };
};

export const updateSelectedAcademicCycle = (academicCycle) => {
  return {
    type: actions.UPDATE_SELECTED_ACADEMIC_CYCLE,
    payload: { academicCycle },
  };
};

export const getCurriculumCycleCycleIds = (cycleIds) => {
  return {
    type: actions.GET_CURRICULUM_CYCLES_CYCLE_ID,
    payload: { cycleIds },
  };
};
