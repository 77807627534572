import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import Parse from "parse";
import { setSelecetdSchool } from '../../store/actions';
import { matchPath } from "react-router";
import { Label, Button, Row, Col, Card, CardBody, Input, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Container } from 'reactstrap';

const PaySlip = (props) => {
    const match1 = matchPath(props.history.location.pathname, {
        path: "/payslip/:schoolId/:teacherId/:payslipId",
        exact: true,
        strict: false,
    });

    const match2 = matchPath(props.history.location.pathname, {
        path: "/download-payslip/:schoolId/:teacherId/:payslipId",
        exact: true,
        strict: false,
    })

    const match = match1 ?? match2
    const schoolId = match.params.schoolId;
    const teacherId = match.params.teacherId;
    const payslipId = match.params.payslipId;

    const [paySlip, setPaySlip] = useState(null);

    const getDesignation = (role) => {
        switch (role) {
            case 3:
                return props.t("Director");
            case 2:
                return props.t("Center Head");
            default:
                return props.t("Teacher");
        }
    };

    const payments = paySlip?.payslipItems.filter(item => item.attributes.categoryType === 1) || [];
    const deductions = paySlip?.payslipItems.filter(item => item.attributes.categoryType === 2) || [];

    useEffect(() => {
        if (schoolId && teacherId && payslipId) {
            Parse.Cloud.run("function_getPayslip", {
                schoolId: schoolId,
                teacherId: teacherId,
                payslipId: payslipId
            }).then(
                (result) => {
                    setPaySlip(result);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, [schoolId, teacherId, payslipId]);

    const totalPayments = payments.reduce((total, item) => total + item.attributes.amount, 0).toFixed(2);
    const totalDeductions = deductions.reduce((total, item) => total + item.attributes.amount, 0).toFixed(2);
    const netAmountPayable = (totalPayments - totalDeductions).toFixed(2);

    const getDaysInMonth = (monthString) => {
        const [month, year] = monthString.split('-');
        const date = new Date(year, new Date(Date.parse(month + " 1, " + year)).getMonth() + 1, 0);

        return date.getDate();
    };

    return (
        <div className={`${match1 ? 'page-content p-8' : 'account-pages'}`}>
            {match1 && <Row className='d-flex align-items-start d-print-none'>
                <Col className="d-flex align-items-start">
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            window.history.back();
                        }}>
                        <ul className="list-unstyled">
                            <div>
                                <i className="bx bx-chevron-left h1 text-primary"></i>
                            </div>
                        </ul>
                    </div>
                    <div className='m-2'>
                        <h4>{"Payslip"}</h4>
                    </div>
                </Col>
            </Row>}
            {paySlip?.payslip ? (
                <Card>
                    <CardBody>
                        <div className="max-w-4xl">
                            <Row className="d-flex justify-content-between">
                                {paySlip.school?.attributes.logo && (
                                    <Col className="d-flex justify-content-start">
                                        <img
                                            src={paySlip.school.attributes.logo._url}
                                            style={{ height: "4rem" }}
                                            alt="School Logo"
                                        />
                                    </Col>
                                )}
                                <Col className="d-flex justify-content-end">
                                    <address className="p-0 m-0">
                                        {paySlip.school?.attributes.Name && (
                                            <strong>
                                                {paySlip.school.attributes.Name}
                                                <br />
                                            </strong>
                                        )}
                                        {paySlip.school?.attributes.Address}
                                        {paySlip.school?.attributes.primaryPhoneNumber && (
                                            <span>{paySlip.school.attributes.primaryPhoneNumber}</span>
                                        )}
                                        {", "}
                                        {paySlip.school?.attributes.secondaryPhoneNumber && (
                                            <span>{paySlip.school.attributes.secondaryPhoneNumber}</span>
                                        )}
                                        {(paySlip.school?.attributes.primaryPhoneNumber || paySlip.school?.attributes.secondaryPhoneNumber) && <br />}
                                        {paySlip.school?.attributes.primaryPhoneNumber?.EMail} {paySlip.school?.attributes.primaryPhoneNumber?.EMail && <br />}
                                    </address>
                                </Col>
                            </Row>

                            <hr style={{ border: 'none', borderTop: '1px solid black', margin: '10px 0' }} />
                            <table className="mb-4" style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td className="border px-4 py-2"><strong>Employee Name</strong></td>
                                        <td className="border px-4 py-2">{paySlip.teacher?.attributes.Name}</td>
                                        <td className="border px-4 py-2"><strong>Designation</strong></td>
                                        <td className="border px-4 py-2">{paySlip.teacher?.attributes.designation || getDesignation(paySlip.teacher?.attributes.Role)}</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2"><strong>E-Mail ID</strong></td>
                                        <td className="border px-4 py-2">{paySlip.teacher?.attributes.EMail}</td>
                                        <td className="border px-4 py-2"><strong>Salary (Monthly)</strong></td>
                                        <td className="border px-4 py-2">{paySlip.teacher?.attributes.currentSalary ? (paySlip.teacher.attributes.currentSalary / 12).toFixed(2) : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2"><strong>Bank Name</strong></td>
                                        <td className="border px-4 py-2">{paySlip.teacher?.attributes.bankAccount?.bankName ?? "-"}</td>
                                        <td className="border px-4 py-2"><strong>Bank Account No.</strong></td>
                                        <td className="border px-4 py-2">{paySlip.teacher?.attributes.bankAccount?.accountNumber ?? "-"}</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2"><strong>Paid days</strong></td>
                                        <td className="border px-4 py-2">{getDaysInMonth(paySlip.payslip?.attributes.payMonth)}</td>
                                        <td className="border px-4 py-2"><strong>UAN</strong></td>
                                        <td className="border px-4 py-2">{paySlip.teacher?.attributes.bankAccount?.UAN ?? "-"}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr style={{ border: 'none', borderTop: '1px solid black', margin: '10px 0' }} />
                            <Row>
                                <Col>
                                    <table className="mb-4" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th className="border px-4 py-2 text-center" colSpan="2"><strong>PAYMENTS</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payments.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="border px-4 py-2">{item.attributes.categoryName}</td>
                                                    <td className="border px-4 py-2">{item.attributes.amount.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className="border px-4 py-2"><strong>Total Payments</strong></td>
                                                <td className="border px-4 py-2">
                                                    <strong>{totalPayments}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col>
                                    <table className="mb-4" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th className="border px-4 py-2 text-center" colSpan="2"><strong>DEDUCTIONS</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {deductions.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="border px-4 py-2">{item.attributes.categoryName}</td>
                                                    <td className="border px-4 py-2">{item.attributes.amount.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className="border px-4 py-2"><strong>Total Deductions</strong></td>
                                                <td className="border px-4 py-2">
                                                    <strong>{totalDeductions}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            <hr style={{ border: 'none', borderTop: '1px solid black', margin: '10px 0' }} />
                            <Row>
                                <Col>
                                    <div className="flex justify-between float-end mb-4">
                                        <tr>
                                            <td className="border px-4 py-2"><strong>Net Amount Payable</strong></td>
                                            <td className="border px-4 py-2"><strong>{netAmountPayable}</strong></td>
                                        </tr>
                                    </div>
                                </Col>
                            </Row>
                            <hr style={{ border: 'none', borderTop: '1px solid black', margin: '0px 0' }} />
                            <div>
                                <p>This is a computer generated statement, No signature required</p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            ) : (
                <Card>
                    <CardBody>
                        <div className="text-info text-center">
                            <Alert color='info'> Payslip not yet generated.</Alert>
                        </div>
                    </CardBody>
                </Card>
            )}
        </div>
    );
};

const mapStatetoProps = (state) => {
    return {};
};

export default connect(mapStatetoProps, {
    setSelecetdSchool
})(withRouter(withNamespaces()(PaySlip)));