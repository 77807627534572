import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Container,
} from "reactstrap";
import Parse from "parse";
import toastr from "toastr";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { setSelecetdSchool, getUserTeacher} from "../../store/actions";
import * as WhiteLable from "../../components/Common/WhiteLable";

const SupportForm = (props) => {

    

    const [isLoading, setIsLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [name, setName] = useState(undefined);
    const [phoneNumber, setPhoneNumber] = useState(undefined);
    const [instituteName, setinstituteName] = useState(undefined);
  
    const [cityName, setCityName] = useState(undefined);
    const [cityError, setCityError] = useState(undefined);
    
    const [emailId, setEmailId] = useState(undefined);
    const [emailError, setEmailError] = useState(undefined);
    const [invalidEmailError, setInvalidEmailError] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [invalidPhoneError, setInvalidPhoneError] = useState(false);
    const [instituteNameError, setinstituteNameError] = useState(false);
    const [submittingForm, setSubmittingForm] = useState(false);
    const [alldetails, setAlldetails] = useState([]);

    const [file, setFile] = useState(undefined)
    const [uploadedFileError, setUploadedFileError] = useState();


    useEffect(() => {
        if (Parse.User.current()) {
            props.getUserTeacher(Parse.User.current().attributes.teacherId)
        }
    }, [])

    useEffect(() => {
        if (Parse.User.current()) {
            setEmailId(Parse.User.current().attributes.username)
        }
        if (props.userTeacher) {
            setName(props.userTeacher.attributes.Name)
            setPhoneNumber(props.userTeacher.attributes.PhoneNumber)
        }
    }, [props.userTeacher])

    

    useEffect(() => {
        if (isFormSubmitted) {
            setAlldetails([
                { left: "Name", right: name },
                { left: "Phone Number", right: phoneNumber },
                { left: "Email", right: emailId },
                { left: "Query/Issue: ", right: instituteName },
               
            ]);
        }
    }, [isFormSubmitted])

    const isPhoneInvalid = (b) => {
        var re = new RegExp("^([0|+[0-9]{1,5})?([1-9][0-9]{9,14})$");
        if (re.test(b)) {
            return false
        }
        else {
            return true
        }
    }

    const isValidateEmail = (value) => {
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (value && value.match(mailformat)) {
            return true;
        } else {
            return false;
        }
    }

    const isFormValid = () => {

        let canProceedToSave = true

        if (name == undefined || name.trim().length === 0) {
            canProceedToSave = false
            setNameError(true)
        } else {
            setNameError(false)
        }

        // if (cityName == undefined || cityName.length === 0) {
        //     canProceedToSave = false
        //     setCityError(true)
        // } else {
        //     setCityError(false)
        // }

        if (instituteName == undefined || instituteName.length === 0) {
            canProceedToSave = false
            setinstituteNameError(true)
        } else {
            setinstituteNameError(false)
        }

        if (phoneNumber == undefined || phoneNumber.trim().length === 0) {
            canProceedToSave = false
            setPhoneNumberError(true)
        } else {
            setPhoneNumberError(false)
        }

        if (isPhoneInvalid(phoneNumber)) {
            canProceedToSave = false
            setInvalidPhoneError(true)
        } else {
            setInvalidPhoneError(false)
        }

        if (isValidateEmail(emailId)) {
            setInvalidEmailError(false)
        } else {
            canProceedToSave = false
            setInvalidEmailError(true)         
        }


        

        if (instituteName == undefined || instituteName.trim().length === 0) {
            canProceedToSave = false
            setinstituteNameError(true)
        } else {
            setinstituteNameError(false)
        }

        


        if (canProceedToSave) {
            saveForm()
        }
    }

    const saveForm = () => {
        setSubmittingForm(true)
        const Visitor = Parse.Object.extend('lilEnquiries');
        var visitor = new Visitor();

        visitor.set("name", name)
        visitor.set("schoolDetails", instituteName)
        visitor.set("city", cityName)
        visitor.set("phone", phoneNumber)
        visitor.set("email", emailId)
        visitor.set("clientType", "Support Console")
        visitor.set("hostName", window.location.host)
        if (file) {
            let uniqueFileName = file.name.replace(/[^a-zA-Z0-9._]/g, '_');
            const parseFile = new Parse.File(uniqueFileName, file);
            parseFile.save().then(
                (result) => {
                    visitor.set("file", result);
                    visitor.save().then(
                        (result) => {
                            setSubmittingForm(false)
                            setIsFormSubmitted(true)
                        },
                        (error) => {
                            console.error('Error saving file:', error);
                            setSubmittingForm(false)
                        }
                    );

                },
                (error) => {
                    console.error('Error saving file:', error);
                }
            );
        } else {
            visitor.save().then(
                (result) => {
                    setSubmittingForm(false)
                    setIsFormSubmitted(true)
                },
                (error) => {
                    setSubmittingForm(false)
                }
            );
        }
        
    }

    const updateFile = () => {
        document.getElementById('upload-files').click();
    };
    function validateSize(input, limit) {
        const fileSize = input.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > limit) {
          toastr.warning(`File size exceeds ${limit} MB, Please use a smaller document`)
          return false
        }
        return true
    };
    

    const borderStyle = {
        border: "2px solid lightGray",
        borderRadius: "50px 50px",
        minHeight: `calc(100vh - 170px)`,
        position: "relative"
    }

    const getTextUI = (leftLabel, rightLabel, key) => {
        return (<React.Fragment key={key}><Row lg={5} md={8} sm={12} xs={12} className="justify-content-center mt-1"><div className="text-left col-6 "> {leftLabel} < Label className="float-right" >:</Label ></div > <div className="text-left col-6">{rightLabel}</div></Row></React.Fragment>)
    }


    return (
        <React.Fragment>
            <Container
                fluid={true}
                // className={"page-content"}
            >
                {!isLoading && !isFormSubmitted && (
                    <Row>
                        <Col lg={8} className="mx-auto mt-4">
                            
                                <div className="text-center">
                                    <img
                                        src={WhiteLable.getAppLogoWithName()}
                                        width="200"
                                        alt=""
                                        className="img-thumbnail "
                                        />
                                    <h3>
                                        {props.t(`Support Desk`)}
                                    </h3>

                                    <h6 className="mt-3">
                                        {props.t("Please provide the details")}
                                    </h6>
                                </div>
                            
                            <Card>
                                <CardBody>
                                    <AvForm
                                        className="outer-repeater"
                                        style={{ border: "1px" }}
                                        onValidSubmit={isFormValid}
                                    >
                                        <div data-repeater-list="outer-group" className="outer">
                                            <div data-repeater-item className="outer">
                                              
                                                <FormGroup>
                                                    <Label htmlFor="vf-name">
                                                        {"Name"}{" "}
                                                    </Label>
                                                    <Label htmlFor="vf-name" className="text-danger">
                                                        *
                                                    </Label>
                                                    {nameError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <AvField
                                                        type="text"
                                                        name={"vf-name-f"}
                                                        placeholder={"Type here"}
                                                        className="form-control"
                                                        value={name}
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setNameError(true)
                                                            } else {
                                                                setNameError(false)
                                                            }
                                                            setName(e.target.value)
                                                        }}
                                                    />
                                                </FormGroup>

                                               


                                               

                                                <FormGroup>
                                                    <Label htmlFor="vf-phone"> {"Phone number"}</Label>
                                                    <Label htmlFor="vf-phone" className="text-danger">*</Label>
                                                    {phoneNumberError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    {
                                                        invalidPhoneError && <Label className="label-xsmall text-danger ml-2">
                                                            (Please enter a valid phone number)
                                                        </Label>
                                                    }
                                                    <AvField
                                                        name={"vf-phone-f"}
                                                        className="inner form-control noscroll"
                                                        placeholder={props.t("Enter phone no")}
                                                        value={phoneNumber}
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setPhoneNumberError(true)
                                                            } else {
                                                                setPhoneNumberError(false)
                                                            }
                                                            setPhoneNumber(e.target.value);
                                                        }}
                                                    />
                                                </FormGroup>
                                               <FormGroup>
                                                    <Label htmlFor="vf-phone"> {"Email Id"}</Label>
                                                    <Label htmlFor="vf-phone" className="text-danger">*</Label>
                                                    {emailError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    {
                                                        invalidEmailError && <Label className="label-xsmall text-danger ml-2">
                                                            (Please enter a valid email)
                                                        </Label>
                                                    }
                                                    <AvField
                                                        name={"vf-email-f"}
                                                        className="inner form-control noscroll"
                                                        placeholder={props.t("Enter email id")}
                                                        value={emailId}
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setEmailError(true)
                                                            } else {
                                                                setEmailError(false)
                                                            }
                                                            setEmailId(e.target.value);
                                                        }}
                                                    />
                                                </FormGroup>

                                                 <FormGroup>
                                                    <Label htmlFor="vf-instituteName">
                                                        {"Query/Issue"}{" "}
                                                    </Label>
                                                    <Label htmlFor="vf-instituteName" className="text-danger">
                                                        *
                                                    </Label>
                                                    {instituteNameError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}


                                                    <textarea
                                                        type="text"
                                                        name={"vf-instituteName-f"}
                                                        placeholder={"Type here"}
                                                        className="form-control"
                                                        value={instituteName}
                                                        rows="6"
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setinstituteNameError(true)
                                                            } else {
                                                                setinstituteNameError(false)
                                                            }
                                                            setinstituteName(e.target.value)
                                                        }}
                                                    />
                                                </FormGroup>

                                                <FormGroup>
                                                <Label className="col-form-label">Attachment(PDF, Doc, PPT, Excel, Image - Max size: 2MB)</Label>
                                                    {!file && (<div>
                                                        <Row lg={12}>
                                                            <Col lg={6}>
                                                                <label className='btn btn-info m-2' htmlFor='upload-files'>
                                                                    Upload
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        {uploadedFileError && <small className='text-danger'>{uploadedFileError}</small>}
                                                    </div>
                                                    )}
                                                    <input type="file"
                                                        className="sr-only"
                                                        name="upload-files"
                                                        id="upload-files"
                                                        accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*,/*"
                                                        onChange={(e) => {
                                                            if (validateSize(e.target, 2)) {
                                                                setUploadedFileError(false)
                                                                setFile(e.target.files[0])
                                                            } else {
                                                                setUploadedFileError(`File size exceeds ${limit} MB, Please use a smaller document`)
                                                            }
                                                            
                                                        }} />
                                                    {file && (
                                                         <Col lg={6}>
                                                            <div className='mt-2 p-2' style={{ border: '1px solid #808080', }}>
                                                                <p>{file.name}</p>
                                                                <Button color="info" size="sm" className="m-1" onClick={updateFile}>Change File</Button>
                                                                <Button color="danger" size="sm" className="m-1"onClick={()=>{setFile(undefined)}}>Remove</Button>
                                                            </div>
                                                        </Col>
                                                    )}
                                                </FormGroup>


                                               


                                                <div style={{ display: "flex" }}>
                                                    <Button
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            width: "180px",
                                                        }}
                                                        type="submit"
                                                        color="primary"
                                                        disabled={submittingForm}
                                                    >
                                                        {submittingForm && < i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>}
                                                        {props.t("SUBMIT")}
                                                    </Button>
                                                </div>

                                            </div>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}

                {isFormSubmitted && (

                    <Card style={{ height: `calc(100vh)` }}>
                        <CardBody>
                            <center>
                                <div style={borderStyle} className="mt-4">
                                    <p className="mt-1 pt-1">
                                        <i style={{ fontSize: "50px" }}
                                            className="fa fa-check-circle align-middle bx-flashing text-success ml-2 pb-2"
                                            aria-hidden="true"
                                        ></i>
                                    </p>

                                    {/* <div>
                                        <h1 className="mt-1 pt-1">
                                            Dear User,your query/issue submitted successfully  !
                                        </h1>
                                    </div> */}
                                    <p className="text-success  text-size-18 mt-4" style={{ fontSize: "30px" }} >Dear User! <br/> Your query/issue submitted successfully  !</p>

                                    <div className="p-1 pl-4">
                                        {alldetails.map((item, key) => (
                                            getTextUI(item.left, item.right, key)
                                        ))}

                                    </div>
                                    
                                    <p className="text-success">{WhiteLable.getAppName()+" "} support executive will reach you shortly .</p>
                                        
                                    <div>
                                        <button className="btn btn-success mb-2" onClick={() => {
                                            // window.location.reload()
                                            setEmailId(undefined)
                                            setPhoneNumber(undefined)
                                            setCityName(undefined)
                                            setName(undefined)
                                            setinstituteName(undefined)
                                            setIsFormSubmitted(false);
                                            setFile(false);
                                            setUploadedFileError(false);
                                        }}>
                                            Done
                                        </button>
                                    </div>
                                        
                                    
                                       
                                   
                                </div>
                            </center>
                        </CardBody>
                    </Card>
                )}



                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Visitor Form...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </React.Fragment >
    );
};

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { userTeacher } = state.Login;
    return { selectedSchool,userTeacher };
};

export default withRouter(connect(mapStatetoProps, { setSelecetdSchool, getUserTeacher })(withNamespaces()(SupportForm)));

