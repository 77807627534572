import React, { useState, useEffect } from 'react';;
import { Col, Row, Card, CardBody, Form, FormGroup, Label, Button, Input, Alert } from "reactstrap";
// import { AvForm, AvField } from 'availity-reactstrap-validation';
// import classnames from "classnames";
// import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Parse from 'parse';
// import DatePicker from "react-datepicker";
// import moment from 'moment';
// import Post1 from '../../../assets/images/Posts/Post1.jpg'
// import Post2 from '../../../assets/images/Posts/Post2.jpg';
// import Post3 from '../../../assets/images/Posts/Post3.jpg';
// import Post4 from '../../../assets/images/Posts/Post4.jpg';
// import Sankranthi1 from '../../../assets/images/Posts/sankranti1.png';
// import Sankranthi3 from '../../../assets/images/Posts/sankranti3.png';
// import Sankranthi4 from '../../../assets/images/Posts/sankranti4.png';
// import Sankranthi5 from '../../../assets/images/Posts/sankranti5.png';
// import Sankranthi6 from '../../../assets/images/Posts/sankranti6.png';
// import Sankranthi7 from '../../../assets/images/Posts/sankranti7.png';
// import Sankranthi8 from '../../../assets/images/Posts/sankranti8.png';
// import RepublicDay1 from '../../../assets/images/Posts/R1.png';
// import RepublicDay2 from '../../../assets/images/Posts/R2.png';
// import RepublicDay3 from '../../../assets/images/Posts/R3.png';
// import RepublicDay4 from '../../../assets/images/Posts/R4.png';

// import Holi1 from '../../../assets/images/Posts/Holi1.png'
// import Holi2 from '../../../assets/images/Posts/Holi2.png';
// import Holi3 from '../../../assets/images/Posts/Holi3.png';
// import Holi4 from '../../../assets/images/Posts/Holi4.png';
// import Holi5 from '../../../assets/images/Posts/Holi5.png';
import Ugadi1 from '../../../assets/images/Posts/ugadi1.png';
import Ugadi2 from '../../../assets/images/Posts/ugadi2.png';
import Ugadi3 from '../../../assets/images/Posts/ugadi3.png';
import Ugadi4 from '../../../assets/images/Posts/ugadi4.png';
import Ugadi5 from '../../../assets/images/Posts/ugadi5.png';
import Ramadhan1 from '../../../assets/images/Posts/ramadhan1.png';
import Ramadhan2 from '../../../assets/images/Posts/ramadhan2.png';
import Ramadhan3 from '../../../assets/images/Posts/ramadhan3.png';
// import Independence1 from '../../../assets/images/Posts/independence1.png';
// import Independence2 from '../../../assets/images/Posts/independence2.png';
// import Independence3 from '../../../assets/images/Posts/independence3.png';
// import Independence4 from '../../../assets/images/Posts/independence4.png';
// import Raksha1 from '../../../assets/images/Posts/rakshabandhan1.png';
// import Raksha2 from '../../../assets/images/Posts/rakshabandhan2.png';
// import Raksha3 from '../../../assets/images/Posts/rakshabandhan3.png';
// import Teacher_Day1 from '../../../assets/images/Posts/teachers_day1.png';
// import Teacher_Day2 from '../../../assets/images/Posts/teachers_day2.png';
// import Teacher_Day3 from '../../../assets/images/Posts/teachers_day3.png';
// import Teacher_Day4 from '../../../assets/images/Posts/teachers_day4.png';
// import Ganesh_Chaturthi1 from '../../../assets/images/Posts/ganesh_chaturthi1.png';
// import Ganesh_Chaturthi2 from '../../../assets/images/Posts/ganesh_chaturthi2.png';
// import Ganesh_Chaturthi3 from '../../../assets/images/Posts/ganesh_chaturthi3.png';
// import Ganesh_Chaturthi4 from '../../../assets/images/Posts/ganesh_chaturthi4.png';
// import Gandhi1 from '../../../assets/images/Posts/gandhi1.png';
// import Gandhi2 from '../../../assets/images/Posts/gandhi2.png';
// import Gandhi3 from '../../../assets/images/Posts/gandhi3.png';

// import Kannada1 from '../../../assets/images/Posts/Kannada1.png';
// import Kannada2 from '../../../assets/images/Posts/Kannada2.png';
// import Kannada3 from '../../../assets/images/Posts/Kannada3.png';

// import Diwali1 from '../../../assets/images/Posts/Diwali1.png';
// import Diwali2 from '../../../assets/images/Posts/Diwali2.png';
// import Diwali3 from '../../../assets/images/Posts/Diwali3.png';

// import Children1 from '../../../assets/images/Posts/Children1.jpeg';
// import Children2 from '../../../assets/images/Posts/Children2.jpeg';
// import Children3 from '../../../assets/images/Posts/Children3.jpeg';
// import Children4 from '../../../assets/images/Posts/Children4.jpeg';

// import Christmas1 from '../../../assets/images/Posts/Christmas 1.png';
// import Christmas2 from '../../../assets/images/Posts/Christmas 2.png';
// import Christmas3 from '../../../assets/images/Posts/Christmas 3.png';
// import Christmas4 from '../../../assets/images/Posts/Christmas 4.png';
// import Christmas5 from '../../../assets/images/Posts/Christmas 5.png';

// import New_2025_1 from '../../../assets/images/Posts/2025_1.png';
// import New_2025_2 from '../../../assets/images/Posts/2025_2.png';
// import New_2025_3 from '../../../assets/images/Posts/2025_3.png';
// import New_2025_4 from '../../../assets/images/Posts/2025_4.png';

import html2canvas from 'html2canvas';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import * as WhiteLable from "../../../components/Common/WhiteLable";
import * as Analytics from "../../../components/Common/Analytics"
import {
    getTeachersForSchool,
} from "../../../store/actions";
import { withNamespaces } from "react-i18next";

const post = (props) => {

    console.log("postType", props.postType)

    let sankranti = 'May this Sankranti be as bright as the sun and the kites fly high as the sky.'
    let newYear = 'May the coming year bring you joy, success, and fulfillment in all your endeavors.'
    let republicDay = '26th January'
    let holi = "May the vibrant colors of Holi fill your life with happiness, prosperity, and success."
    let schoolLogo = props.selectedSchool && props.selectedSchool.attributes.logo._url;
    let ugadi = "May the Lord bless you with good health, wealth, peace and prosperity."
    let independence = "Let's keep the memories of all the people who sacrificed their lives for our country alive."
    let raksha = "Celebrating  a bond of love, respect and all good things."
    let Ganesh_Chaturthi = "As you celebrate Ganesh Chaturthi, may your faith in Lord Ganesha deepen, and may his blessings be with you always. Happy Ganesh Chaturthi!"
    let GandhiJayanthi = "Let's all embrace truth and wisdom, and honor the Father of the Nation on this special day."
    let diwali = "May this Diwali fill your life with the radiant colors of joy and the soothing shades of peace."
    let kannada = "Celebrating the heritage and rich diversity of Karnataka"
    let Cristamas = "May your holiday season be merry and bright, and the New Year bring you happiness and success"

    const [styleType, setStyleType] = useState(props.postType == 'ramadan' ? 5 : 0);
    const [showAlert, setShowAlert] = useState(false);
    // const [logo, setLogo] = useState('https://parsefiles.back4app.com/n1BIxpDhrdEUUwxqMla37DBZoMlFIWLRVLVKXK0a/d486a3d8df79a13dbcf1c4bd6e9595f9_click_school_logo.png')
    const [logo, setLogo] = useState(WhiteLable.getAppLogoWithName())
    const [wishText, setWishText] = useState(ugadi);

    useEffect(() => {

        const timeoutId = setTimeout(() => {
            setShowAlert(true);
        }, 10000);

        return () => clearTimeout(timeoutId);
    }, []);

    const getBackGroundStyle = (image) => {
        return {
            backgroundImage: image,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            // height: '80vh',
            height: '14cm',
            width: '16cm',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '20px',
        };
    }

    const getFontStyle = (marginTop, paddingLeft, paddingRight, color) => {
        return {
            fontFamily: 'Arial, sans-serif',
            // fontSize: '15px',
            fontStyle: 'italic',
            fontVariant: 'small-caps',
            fontWeight: 'bold',
            fontSize: '40px',
            lineHeight: '1.5',
            // fontFamily: 'cursive',
            color: color ?? '#000000',

            marginBottom: marginTop ?? '63vh',
            paddingRight: paddingRight ?? '2.5cm',
            paddingLeft: paddingLeft ?? '2.5cm'
        };
    }

    const getLogoStyle = (marginTop, marginLeft, logoWdth) => {
        return {
            marginTop: marginTop ?? '3hv',
            marginLeft: marginLeft ?? '0.1cm',
            width: logoWdth ?? "2.5cm"
        };
    }

    const posts2= [{
        styleType: 0,
        styleName: "Ugadi Design 1",
        backgroundStyle: getBackGroundStyle(`url('${Ugadi1}')`),
        fontStyle: getFontStyle('0.1cm', '2.5cm', '2.5cm', "#000000"),
        logoStyle: getLogoStyle('-0.2cm', '6.2cm', '2.2cm'),
        hideWish:false
    },
    {
        styleType: 1,
        styleName: "Ugadi Design 2",
        backgroundStyle: getBackGroundStyle(`url('${Ugadi2}')`),
        fontStyle: getFontStyle('0.5cm', '3.5cm', '3.5cm', "#000000"),
        logoStyle: getLogoStyle('-0.2cm', '6.2cm', '2.2cm'),
        hideWish:true
    },
    {
        styleType: 2,
        styleName: "Ugadi Design 3",
        backgroundStyle: getBackGroundStyle(`url('${Ugadi3}')`),
        fontStyle: getFontStyle("3.5cm", "2.5cm", "2.5cm", "#000000"),
        logoStyle: getLogoStyle('-0.2cm', '6.2cm', '2.2cm'),
        hideWish:false
    },
    {
        styleType: 3,
        styleName: "Gudi Padwa Design 4",
        backgroundStyle: getBackGroundStyle(`url('${Ugadi4}')`),
        fontStyle: getFontStyle("0cm", "2.5cm", "2.5cm", "#000000"),
        logoStyle: getLogoStyle('-0.2cm', '6.2cm', '2.2cm'),
        hideWish:true
    },
    {
        styleType: 4,
        styleName: "Gudi Padwa Design 5",
        backgroundStyle: getBackGroundStyle(`url('${Ugadi5}')`),
        fontStyle: getFontStyle("4.5cm", "2.5cm", "2.5cm", "#000000"),
        logoStyle: getLogoStyle('-0.2cm', '6.2cm', '2.2cm'),
        hideWish:true
    },
    ]
    const posts1 = [
        {
            styleType: 5,
            styleName: "Ramadan Design 1",
            backgroundStyle: getBackGroundStyle(`url('${Ramadhan1}')`),
            fontStyle: getFontStyle('1.5cm', '2.5cm', '2.5cm', '#796F46'),
            logoStyle: getLogoStyle('1cm', '6.2cm', '2.2cm'),
            hideWish:true
        },
        {
            styleType: 6,
            styleName: "Ramadan Design 2 ",
            backgroundStyle: getBackGroundStyle(`url('${Ramadhan2}')`),
            fontStyle: getFontStyle('0.2cm', '2.5cm', '2.5cm', '#000000'),
            logoStyle: getLogoStyle('2.6cm', '6.2cm', '2.2cm'),
            hideWish:true
        },
        {
            styleType: 7,
            styleName: "Ramadan Design 3 ",
            backgroundStyle: getBackGroundStyle(`url('${Ramadhan3}')`),
            fontStyle: getFontStyle('0.4cm', '2.5cm', '2.5cm', '#55207A'),
            logoStyle: getLogoStyle('0.5cm', '6.2cm', '2.2cm'),
            hideWish:true
        },
        
       
    ]

    const postsList =  [...posts2,...posts1];

    // console.log(postsList);
    const printDocument = () => {
        const input = document.getElementById('divToPrint');
        html2canvas(input, { allowTaint: true, useCORS: true })
            .then((canvas) => {

                // Convert canvas to data URL
                const imgData = canvas.toDataURL('image/png');

                // Create a link element and trigger a download
                const downloadLink = document.createElement('a');
                downloadLink.href = imgData;
                downloadLink.download = 'download.png';
                downloadLink.click();
            });
    };
    const myTextStyles = {
        fontFamily: 'Arial, sans-serif',
        fontSize: '15px',
        // fontStyle: 'italic',
        // color: '#333',
        // lineHeight: '1.5',
        // letterSpacing: '1px',
        // textTransform: 'uppercase',

        // marginTop: '30vh',
        marginBottom: '5vh',
        paddingRight: '2.5cm',
        paddingLeft: '2.5cm'
    };


    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Use FileReader to convert the selected file to a data URL
            const reader = new FileReader();
            reader.onload = () => {
                setLogo(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handelEnquireNow = () => {

        if (Parse.User.current() && props.selectedSchool) {
            // setShowLoading(title);
            const lilPackageLeads = Parse.Object.extend("lilPackageLeads");
            const packageLeads = new lilPackageLeads();
            packageLeads.set("schoolName", props.selectedSchool.attributes.Name);
            packageLeads.set("email", props.selectedSchool.attributes.EMail);
            packageLeads.set("phoneNumber", props.selectedSchool.attributes.primaryPhoneNumber);
            packageLeads.set("location", props.selectedSchool.attributes.city);
            packageLeads.set("schoolId", props.selectedSchool.id);

            packageLeads.set("packageSelected", 'Digital Marketing (New year Post)');


            if (Parse.User.current().attributes.teacherId) {
                packageLeads.set("userId", Parse.User.current().attributes.teacherId);
            } else {
                packageLeads.set("userId", Parse.User.current().id);
            }

            packageLeads.save().then(
                (result) => {
                    toastr.success(("Enquire Submitted successfully"), { timeOut: 1000 });
                    setShowAlert(false)
                },
                (error) => {

                }
            );

        } else {

        }
    }

    return (
        <React.Fragment>
            <div>
                {showAlert && <Row>
                    <Col xs={12}>
                        <Card className="mini-stats-wid">
                            <CardBody>
                                <Alert color="info" className="" >
                                    <Row>
                                        <Col xs={10}>
                                            <p>To create more custom creatives and effectively manage your digital marketing, connect with our digital marketing expert</p>
                                        </Col>
                                        <Col xs={2}>
                                            <div className=" float-end">
                                                <Link to='#'
                                                    className="btn btn-primary "
                                                    onClick={() => {
                                                        handelEnquireNow()
                                                    }}
                                                >
                                                    Enquire Now
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Alert>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>}
                <Row>
                    <Col xs={8}>
                        <Row>
                            <Col xs={1}>
                            </Col>
                            <Col xs={10}>
                                <div className="certificate-card">

                                    {/* {console.log("styleType",postsList[styleType].fontStyle)} */}
                                    <div style={postsList[styleType].backgroundStyle} id="divToPrint"
                                        className="divToPrint">
                                        <Col className=''>
                                            <label htmlFor="fileInput">
                                                <img
                                                    className='logo'
                                                    id='logo'
                                                    src={logo}
                                                    alt=""
                                                    style={postsList[styleType].logoStyle}
                                                />
                                            </label>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                style={{ display: 'none' }}
                                            />
                                        </Col>


                                        {!postsList[styleType].hideWish &&<div className='text-center' >
                                            <Row className=' '>
                                                <Col className=''>
                                                    <p style={postsList[styleType].fontStyle} className='font-size-15'>
                                                        <b>{wishText ? wishText : ""}</b>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={1}>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={4}>
                        <div className=''>
                            <Card className='d-print-none '>
                                <CardBody>
                                    <Row >
                                        <Col xs="12">
                                            <FormGroup className="mt-2 mb-2 p-1">
                                                <Label>Choose your School Logo:</Label>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12">
                                            <FormGroup className="mt-2 mb-2 p-1">
                                                <Label>Select style :</Label>
                                                <select
                                                    className="form-control "
                                                    value={styleType}
                                                    onChange={(e) => {
                                                        setStyleType(e.target.value);
                                                    }}
                                                >
                                                    {
                                                        postsList.map((post) =>
                                                            <option
                                                                value={post.styleType}
                                                                key={post.styleType}>
                                                                {post.styleName}
                                                            </option>)
                                                    }

                                                </select>
                                            </FormGroup>
                                        </Col>
                                       { (!postsList[styleType].hideWish )&&  <Col xs="12">
                                            <FormGroup className="mt-2 mb-2 p-1">
                                                <Label>Wish Text:</Label>
                                                <textarea
                                                    className="form-control"
                                                    id="displayName"
                                                    placeholder="Wish text"
                                                    required
                                                    value={wishText}
                                                    onChange={(e) => {
                                                        setWishText(e.target.value);
                                                    }}
                                                    rows={4}
                                                />
                                            </FormGroup>
                                        </Col>}
                                        <Col>
                                            <Button
                                                color='success'
                                                className='btn float-end d-print-none'
                                                onClick={() => {
                                                    printDocument()
                                                    let user = Parse.User.current();
                                                    Analytics.festival_Poster_Click(user.id, user.attributes.email, props.userTeacher.attributes.Name, '_', "Download Poster", props.selectedSchool.id, props.selectedSchool.attributes.Name)
                                                }}>
                                                Download Post
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>


        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {

    const { userTeacher } = state.Login;
    return {
        userTeacher,
    };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            getTeachersForSchool,
        })(post)
    )
);
