import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label, Form, FormGroup, Input, Table, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
    setSelecetdSchool, setSelecetdTeacher, getPayrollCategoriesForOwnerId, getMasterPayslipForTeacherId,
    getMasterPayslipItemsForMasterPayslipId, getPayslipForMonth, getPayslipItemsForPayslipId, setSelectedMonth,
} from '../../store/actions';
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import Parse from 'parse';
import PayslipWithAttendanceModal from './PayslipWithAttendanceModal'

const SetupPayroll = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/setup-payroll/:schoolId/:teacherId/:date",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.schoolId;
    const teacherId = match.params.teacherId;
    const selectedMonth = match.params.date;


    const [categoryList, setCategoryList] = useState([]);
    const [saveLoding, setSaveLoding] = useState(false);
    const [selectedCategoryList, setSelectedCategoryList] = useState([]);
    const [salaryComponents, setSalaryComponents] = useState([])
    const [disabled, setDisabled] = useState(false);
    const [openPayslipWithAttendanceModal, setOpenPayslipWithAttendanceModal] = useState(false);
    const [attendanceObjectForMOnth, setAttendanceObjectForMOnth] = useState();
    const [compensationPerDay, setCompensationPerDay] = useState();
    const [newCategories, setNewCategories] = useState([])

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool]);

    useEffect(() => {
        if (teacherId) {
            props.setSelecetdTeacher(teacherId);
            props.getMasterPayslipForTeacherId(teacherId)
        }
    }, [teacherId])

    useEffect(() => {
        if (schoolId) {
            props.getPayrollCategoriesForOwnerId(schoolId, "School");
        }
    }, [schoolId]);

    useEffect(() => {
        if (props.masterPayslip) {
            props.getMasterPayslipItemsForMasterPayslipId(props.masterPayslip.id);
        }
    }, [props.masterPayslip]);

    useEffect(() => {
        if (teacherId && selectedMonth) {
            props.getPayslipForMonth(teacherId, selectedMonth)
        }
        if (selectedMonth && !props.selectedMonth) {
            props.setSelectedMonth(selectedMonth)
        }
    }, [teacherId])

    useEffect(() => {
        if (props.payslip) {
            props.getPayslipItemsForPayslipId(props.payslip.id);
        }
    }, [props.payslip]);


    useEffect(() => {
        if (props.payrollCategories && props.payrollCategories.length > 0) {
            let tempSelectList = []
            for (const i of props.payrollCategories) {
                tempSelectList.push(getSelectionObject(i))
            }
            setCategoryList(tempSelectList)
        }
    }, [props.payrollCategories])

    const getSelectionObject = (payrollCategory) => {
        let tempSelectObj = {}
        let obj = payrollCategory.attributes
        tempSelectObj.id = payrollCategory.id
        tempSelectObj.categoryName = obj.title ?? ""
        tempSelectObj.value = payrollCategory.id
        tempSelectObj.label = obj.title ?? ""
        tempSelectObj.parseObject = payrollCategory
        return tempSelectObj
    }

    const getSelectionCategoryFromId = (id) => {
        for (const item of props.payrollCategories) {
            if (item.id == id) {
                return getSelectionObject(item)
            }
        }
    }

    useEffect(() => {
        const selectedCategories = newCategories.map(salry => salry.categoryId);
        setSelectedCategoryList(selectedCategories);
    }, [newCategories]);

    useEffect(() => {
        if (!props.payslip && props.masterPayslip && props.masterPayslipItems && props.masterPayslipItems.length > 0) {
            let items = [];

            for (const mpi of props.masterPayslipItems) {
                if (mpi.attributes.masterPayslipId === props.masterPayslip.id) {
                    let category = getSelectionCategoryFromId(mpi.attributes.categoryId)
                    let newItem = {
                        itemId: uuidv4(),
                        category: category,
                        categoryId: mpi.attributes.categoryId,
                        masterPayslipItemId: mpi.id,
                        amount: mpi.attributes.amount,
                        categoryName: category.categoryName,
                        categoryType: mpi.attributes.categoryType,
                        parseObject: undefined
                    };
                    items.push(newItem);
                }
            }
            setDisabled(true)
            setSalaryComponents(items);
        }
    }, [props.masterPayslipItems, props.masterPayslip]);

    useEffect(() => {
        setNewCategories([]);
        if (props.payslip && props.payslipItems && props.payslipItems.length > 0) {
            let items = [];
            let newCategoryItems = [];

            for (const pi of props.payslipItems) {
                if (pi.attributes.payslipId === props.payslip.id) {
                    let category = getSelectionCategoryFromId(pi.attributes.categoryId);
                    let newItem = {
                        itemId: uuidv4(),
                        category: category ?? { value: pi.attributes.categoryId, label: pi.attributes.categoryName },
                        categoryId: pi.attributes.categoryId,
                        masterPayslipItemId: pi.attributes.masterPayslipItemId,
                        payslipItemId: pi.id,
                        amount: pi.attributes.amount,
                        categoryName: pi.attributes.categoryName,
                        categoryType: pi.attributes.categoryType,
                        parseObject: pi
                    };

                    if (pi.attributes.masterPayslipItemId) {
                        items.push(newItem);
                    } else {
                        newCategoryItems.push(newItem);
                    }
                }
            }

            setDisabled(true);
            setSalaryComponents(items);
            setNewCategories(newCategoryItems);
        }
    }, [props.payslipItems, props.payslip]);

    const handleNewCategory = () => {
        const newCategory = {
            itemId: uuidv4(),
            category: null,
            categoryType: 1,
            categoryName: "New Category",
            categoryId: "",
            amount: 0,
        };
        setNewCategories([...newCategories, newCategory]);
    };

    const handleRemove = (categoryId) => {
        const newItems = newCategories.filter((item) => item.categoryId !== categoryId);
        setNewCategories(newItems);
    };

    const handleAmountChange = (index, value) => {
        const updatedCategories = [...newCategories];
        updatedCategories[index].amount = parseFloat(value) || 0;
        setNewCategories(updatedCategories);
    };

    const filteredCategoryList = () => {
        return categoryList.filter(category =>
            !selectedCategoryList.includes(category.value)
        );
    };

    const getPayslipItemParseObject = (categoryId) => {
        if (newCategories && newCategories.length > 0) {
            for (const sc of newCategories) {
                if (sc.categoryId == categoryId) {
                    return sc.parseObject
                }
            }
        }
    }

    const addCategoryToList = (attendanceObject, compensation) => {

        let newSalaryComponent = []

        let parseObject1 = getPayslipItemParseObject("xOpA80fiCN")
        let parseObject2 = getPayslipItemParseObject("Hu55N31xoI")

        let lop = {
            category: getSelectionCategoryFromId("xOpA80fiCN"),
            categoryType: 2,
            categoryName: "LOP",
            categoryId: "xOpA80fiCN",
            amount: attendanceObject.totalLOP * compensation,
            parseObject: parseObject1
        }

        let ot = {
            category: getSelectionCategoryFromId("Hu55N31xoI"),
            categoryType: 1,
            categoryName: "Over Time",
            categoryId: "Hu55N31xoI",
            amount: attendanceObject.totalExtraDays * compensation,
            parseObject: parseObject2
        }

        for (const c of newCategories) {
            if (c.category.id == "xOpA80fiCN") {
                lop.added = true
                newSalaryComponent.push(lop)
            } else if (c.category.id == "Hu55N31xoI") {
                ot.added = true
                newSalaryComponent.push(ot)
            } else {
                newSalaryComponent.push(c)
            }
        }

        if (!lop.added) {
            newSalaryComponent.push(lop)
        }
        if (!ot.added) {
            newSalaryComponent.push(ot)
        }

        setNewCategories(newSalaryComponent);
        handelSave(newSalaryComponent, attendanceObject, compensation)
    };

    const handelSave = (newSalaryComponent, attendanceObject) => {

        const mergedComponents = [
            ...salaryComponents,
            ...(newSalaryComponent || newCategories)
        ];

        let mSalaryComponents = mergedComponents

        let canSave = true;

        // let newList = []
        // for (let i of mSalaryComponents) {

        //     if (!i.amount || i.amount < 0) {
        //         i.amountError = true;
        //         canSave = false
        //     }
        //     newList.push(i)
        // }
        // setSalaryComponents(newList);

        // if (!canSave) {
        //     return
        // }

        setSaveLoding(true);

        const Payslip = Parse.Object.extend("Payslip");
        let payslip = new Payslip();

        if (props.payslip && props.payslip) {
            payslip.id = props.payslip && props.payslip.id
        } else {
            payslip.set("teacherId", teacherId);
            payslip.set("ownerId", schoolId);
            payslip.set("ownerType", "School");
            payslip.set("masterPayslipId", props.masterPayslip && props.masterPayslip.id);
            payslip.set("payMonth", selectedMonth);
            payslip.set("salary", parseInt(props.masterPayslip && props.masterPayslip.attributes?.salaryPerMonth));
        }

        let netPayableSalary = 0;

        const masterPayslipItems = mSalaryComponents.filter(component => component.masterPayslipItemId);

        masterPayslipItems.forEach(component => {
            if (component.categoryType === 1 || component.categoryType === 3) {
                netPayableSalary += component.amount;
            }
        });
    
        mSalaryComponents.forEach(component => {
            if (!component.masterPayslipItemId) {
                if (component.categoryType === 1 || component.categoryType === 3) {
                    netPayableSalary += component.amount;
                } else if (component.categoryType === 2) {
                    netPayableSalary -= component.amount;
                }
            }
        });
    
        payslip.set("netPayableSalary", parseFloat(netPayableSalary.toFixed(2)));
    
        if (attendanceObject) {
            payslip.set("attendanceObject", attendanceObject);
            payslip.set("attendanceStatus", true);
        }

        savePayslipObject(payslip, mSalaryComponents);

    }

    const savePayslipObject = (payslip, salaryComponents) => {
        payslip.save().then(
            (result) => {
                result.pin();
                if ((props.payslip && props.payslip)) {
                    let salaryObjects = [];
                    let exItems = [];

                    for (let i = 0; i < salaryComponents.length; i++) {
                        const PayslipItems = Parse.Object.extend("PayslipItems");
                        let payslipItem = new PayslipItems();

                        if (salaryComponents[i].parseObject) {
                            payslipItem.id = salaryComponents[i].parseObject.id;
                            exItems.push(salaryComponents[i].parseObject.id);
                        } else {
                            payslipItem.set("payslipId", result.id);
                            if (salaryComponents[i].masterPayslipItemId) {
                                payslipItem.set("masterPayslipItemId", salaryComponents[i].masterPayslipItemId);
                                payslipItem.set("categoryId", salaryComponents[i].categoryId ?? salaryComponents[i].category.id);
                            } else {
                                payslipItem.set("categoryId", salaryComponents[i].categoryId);
                            }
                            payslipItem.set("categoryName", salaryComponents[i].categoryName);
                            payslipItem.set("categoryType", salaryComponents[i].categoryType);
                        }

                        payslipItem.set("amount", salaryComponents[i].amount);
                        salaryObjects.push(payslipItem);
                    }

                    let deletedItems = [];
                    for (const si of props.payslipItems) {
                        if (!exItems.includes(si.id)) {
                            si.set("isDeleted", true);
                            si.set("deletedBy", Parse.User.current().attributes.teacherId);
                            deletedItems.push(si);
                        }
                    }

                    Parse.Object.saveAll(salaryObjects).then((results) => {
                        Parse.Object.pinAll(results);
                        if (deletedItems.length > 0) {
                            Parse.Object.saveAll(deletedItems).then((dResults) => {
                                Parse.Object.pinAll(dResults);
                                setSaveLoding(false);
                                if (openPayslipWithAttendanceModal) {
                                    setOpenPayslipWithAttendanceModal(!openPayslipWithAttendanceModal);
                                } else {
                                    window.history.back();
                                }
                            }).catch((error) => {
                                console.error("Error saving deleted items:", error);
                                setSaveLoding(false);
                            });
                        } else {
                            if (openPayslipWithAttendanceModal) {
                                setOpenPayslipWithAttendanceModal(!openPayslipWithAttendanceModal);
                            } else {
                                window.history.back();
                            }
                        }
                    })

                } else {
                    let salaryObjects = [];
                    for (let i = 0; i < salaryComponents.length; i++) {
                        const PayslipItems = Parse.Object.extend("PayslipItems");
                        let payslipItem = new PayslipItems();

                        payslipItem.set("payslipId", result.id);
                        if (salaryComponents[i].masterPayslipItemId) {
                            payslipItem.set("masterPayslipItemId", salaryComponents[i].masterPayslipItemId);
                            payslipItem.set("categoryId", salaryComponents[i].categoryId ?? salaryComponents[i].category.id);
                        } else {
                            payslipItem.set("categoryId", salaryComponents[i].categoryId ?? salaryComponents[i].category.id);
                        }
                        payslipItem.set("categoryName", salaryComponents[i].categoryName);
                        payslipItem.set("categoryType", salaryComponents[i].categoryType);
                        payslipItem.set("amount", salaryComponents[i].amount);

                        salaryObjects.push(payslipItem);
                    }
                    Parse.Object.saveAll(salaryObjects).then((results) => {
                        Parse.Object.pinAll(results);
                        setSaveLoding(false)
                        // console.log(results);
                        if (openPayslipWithAttendanceModal) {
                            setOpenPayslipWithAttendanceModal(!openPayslipWithAttendanceModal)
                        } else {
                            window.history.back();
                        }
                    });
                }
                (error) => {
                    console.error("Error saving payslip:", error);
                }
            }
        );
    };

    const calculateTotalAmountOfEarnings = () => {
        return salaryComponents
            .filter(component => (component.categoryType == 1 || component.categoryType == 3))
            .reduce((total, component) => total + component.amount, 0)
            .toFixed(2);
    };

    const calculateTotalAmountOfDeductions = () => {
        return salaryComponents
            .filter(component => component.categoryType == 2)
            .reduce((total, component) => total + component.amount, 0)
            .toFixed(2);
    };

    const calculateTotalAmountOfNetPayble = () => {
        const totalEarningsFromComponents = salaryComponents
            .filter(component => (component.categoryType === 1 || component.categoryType === 3))
            .reduce((total, component) => total + component.amount, 0);

        const totalEarningsFromNewCategories = newCategories
            .filter(category => (category.categoryType === 1 || category.categoryType === 3))
            .reduce((total, category) => total + category.amount, 0);

        const totalDeductionsFromNewCategories = newCategories
            .filter(category => category.categoryType === 2)
            .reduce((total, category) => total + category.amount, 0);

        const totalEarnings = totalEarningsFromComponents + totalEarningsFromNewCategories - totalDeductionsFromNewCategories;

        return totalEarnings.toFixed(2);
    };

    return (
        <React.Fragment>
            <div className='page-content'>
                <Modal isOpen={openPayslipWithAttendanceModal} className={"modal-dialog modal-xl"} scrollable={true} backdrop={true}>
                    <ModalHeader toggle={() => {
                        setOpenPayslipWithAttendanceModal(!openPayslipWithAttendanceModal)
                    }}>{`Salary Calculation Based on Attendance for ${props.selectedTeacher?.attributes?.Name}`}</ModalHeader>
                    <ModalBody>
                        {(props.selectedTeacher && props.selectedTeacher) &&
                            <PayslipWithAttendanceModal
                                selectedSchool={props.selectedSchool}
                                selectedTeacher={props.selectedTeacher}
                                selectedMonth={selectedMonth}
                                masterPayslip={props.masterPayslip}
                                onDoneButtonClick={(attendanceObject, compensation) => {
                                    if (attendanceObject) {
                                        setAttendanceObjectForMOnth(attendanceObject)
                                        setCompensationPerDay(compensation)
                                        addCategoryToList(attendanceObject, compensation)
                                    }
                                }}>
                            </PayslipWithAttendanceModal>}
                    </ModalBody>
                </Modal>
                <Row className="d-flex justify-content-between align-items-start">
                    <Col lg={6} className="d-flex align-items-start">
                        <div style={{ cursor: "pointer" }} onClick={() => {
                            window.history.back()
                        }}>
                            <ul className="list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className='m-2'>
                            <h4 className="font-size-18">{`Payslip for ${props.selectedTeacher?.attributes?.Name} (${selectedMonth})`}</h4>
                        </div>
                    </Col>
                </Row>

                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={1} className="text-center  ">
                                <div className="border p-2">
                                    {props.selectedTeacher && props.selectedTeacher.attributes.Photo ? (
                                        <div>
                                            <img
                                                style={{ width: "3.5rem", height: "3.5rem" }}
                                                className="rounded-circle avatar mb-2"
                                                src={props.selectedTeacher && props.selectedTeacher.attributes.Photo._url}
                                                alt=""
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className="avatar-lg mx-auto mb-2"
                                            style={{ width: "3.5rem", height: "3.5rem" }}
                                        >
                                            <span
                                                className={
                                                    "avatar-title rounded-circle bg-soft-primary text-primary"
                                                }
                                                style={{ fontSize: "30px" }}
                                            >
                                                {props.selectedTeacher && props.selectedTeacher.attributes.Name.charAt(0).toUpperCase()}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col lg={7}>
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="table mb-0 table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">
                                                        Name :<br />
                                                        Designation :<br />
                                                        Employee Code :<br />
                                                    </th>
                                                    <td colSpan="6">
                                                        {props.selectedTeacher?.attributes?.Name}<br />
                                                        {props.selectedTeacher?.attributes?.designation}<br />
                                                        {props.selectedTeacher?.attributes?.empCode}<br />
                                                    </td>
                                                    <th scope="row">
                                                        Current Salary (per year) :<br />
                                                        Current Salary (per Month) :<br />
                                                        Compensation (Per Day) :<br />
                                                    </th>
                                                    <td colSpan="6">
                                                        {props.masterPayslip?.attributes?.salaryPerYear ?? "-"}<br />
                                                        {props.masterPayslip?.attributes?.salaryPerMonth ?? "-"}<br />
                                                        {props.masterPayslip?.attributes?.perDayCompensation ?? "-"}<br />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <Button
                                    className='btn float-end'
                                    onClick={() => {
                                        setOpenPayslipWithAttendanceModal(true);
                                    }}
                                    color='primary'>
                                    {props.payslip && props.payslip.attributes.attendanceStatus ? "Update Salary With Attendance" : "Salary With Attendance"}
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {props.masterPayslip ? (<Card>
                    <CardBody>
                        {salaryComponents.length > 0 && (
                            <Row>
                                <Col>
                                    <Table className="table table-nowrap mb-0 bordered">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "4%" }}>Sl.no</th>
                                                <th style={{ width: "30%" }}>Category</th>
                                                <th style={{ width: "20%" }}>Type</th>
                                                <th style={{ width: "15%" }}>Amount</th>
                                                <th style={{ width: "15%" }}></th>
                                            </tr>
                                        </thead>
                                        {console.log("salaryComponents", salaryComponents)}
                                        {console.log("newCategories", newCategories)}
                                        <tbody>
                                            {salaryComponents.map((salry, key) => (
                                                <tr key={salry.categoryId}>
                                                    <td>{(key + 1)}</td>
                                                    <td>{salry.categoryName}</td>
                                                    <td>{salry.categoryType == 1 ? "Earnings" : salry.categoryType == 2 ? "Deductions" : salry.categoryType == 3 ? "Reimbursement" : ""}</td>
                                                    <td>
                                                        <div className="d-flex m-0 col-md-10">
                                                            {(salry.categoryType == 1 || salry.categoryType == 3) && <Label
                                                                className=" text-success"
                                                                id="add-time-camera"
                                                                style={{ fontSize: "20px", cursor: "pointer" }}
                                                            >
                                                                <i className="bx bx-plus-circle"></i>
                                                            </Label>}
                                                            {salry.categoryType == 2 && <Label
                                                                className=" text-danger"
                                                                id="add-time-camera"
                                                                style={{ fontSize: "20px", cursor: "pointer" }}
                                                            >
                                                                <i className="bx bx-minus-circle"></i>
                                                            </Label>}
                                                            <div className="m-1">{salry.amount}</div>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: "15%" }}></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Row className='m-2'>
                                        <Col lg={1}></Col>
                                        <Col >
                                            <Label>
                                                Total Current Salary :
                                            </Label>
                                            <Input
                                                name="Total"
                                                placeholder="0.00"
                                                type="number"
                                                className={`form-control float-end m-2 text-success`}
                                                value={(props.masterPayslip?.attributes?.salaryPerMonth).toFixed(2)}
                                                id="Total"
                                                readOnly
                                            />
                                        </Col>
                                        <Col >
                                            <Label>
                                                Earnings:
                                            </Label>
                                            <Input
                                                name="Total"
                                                placeholder="0.00"
                                                type="number"
                                                className={`form-control float-end m-2 text-info`}
                                                value={calculateTotalAmountOfEarnings()}
                                                id="Total"
                                                readOnly
                                            />
                                        </Col>
                                        <Col>
                                            <Label>
                                                Deductions:
                                            </Label>
                                            <Input
                                                name="Total"
                                                placeholder="0.00"
                                                type="number"
                                                className={`form-control float-end m-2 text-danger`}
                                                value={calculateTotalAmountOfDeductions()}
                                                id="Total"
                                                readOnly
                                            />
                                        </Col>
                                        <Col >
                                            <Label>
                                                {newCategories.length > 0 ? "Payable" : "Net Payable Amount"}
                                            </Label>
                                            <Input
                                                name="Total"
                                                placeholder="0.00"
                                                type="number"
                                                className={`form-control float-end m-2 text-success`}
                                                value={((props.masterPayslip?.attributes?.salaryPerMonth) - (calculateTotalAmountOfDeductions())).toFixed(2)}
                                                id="Total"
                                                readOnly
                                            />
                                        </Col>
                                        <Col>
                                            <Button color="success"
                                                className="btn waves-effect waves-light float-end m-2"
                                                onClick={handleNewCategory}
                                            >
                                                Add Category<i className="mdi mdi-plus mr-1" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Table className="table table-nowrap mb-0">
                                        <tbody>
                                            {newCategories.map((salry, key) => (
                                                <tr key={salry.itemId}>
                                                    <td style={{ width: "4%" }}><h5 className="m-2">{(key + 1)}</h5></td>
                                                    <td style={{ width: "30%" }}>
                                                        <FormGroup className="position-relative">
                                                            <Select
                                                                style={{ width: "14rem" }}
                                                                onChange={(selectedOption) => {
                                                                    let tempCategory = salry;
                                                                    tempCategory.amount = (selectedOption.parseObject.attributes.amount);
                                                                    tempCategory.category = selectedOption.item ?? selectedOption;
                                                                    tempCategory.categoryId = selectedOption.id;
                                                                    tempCategory.categoryName = selectedOption.categoryName;
                                                                    tempCategory.categoryType = selectedOption.parseObject.attributes.type;

                                                                    let newList = []
                                                                    for (let i of newCategories) {
                                                                        if (i.itemId == salry.itemId) {
                                                                            newList.push(tempCategory)
                                                                        } else {
                                                                            newList.push(i)
                                                                        }
                                                                    }

                                                                    setNewCategories(newList);
                                                                }}
                                                                value={salry.category}
                                                                isDisabled={salry.parseObject}
                                                                id="categoryList"
                                                                options={salry.parseObject ? [salry.category] : filteredCategoryList()}
                                                                className="selectItems"
                                                                classNamePrefix="SaleItem"
                                                            />
                                                        </FormGroup>
                                                    </td>
                                                    <td style={{ width: "20%" }}>
                                                        <FormGroup>
                                                            <div className="col-md-10">
                                                                <Input
                                                                    name="units"
                                                                    placeholder="Units"
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={salry.categoryType == 1 ? "Earnings" : salry.categoryType == 2 ? "Deductions" : salry.categoryType == 3 ? "Reimbursement" : ""}
                                                                    id="units"
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </td>
                                                    <td style={{ width: "15%" }}>
                                                        <FormGroup>
                                                            <div className="d-flex col-md-10">
                                                                {(salry.categoryType == 1 || salry.categoryType == 3) && <Label
                                                                    className="m-2 text-success"
                                                                    id="add-time-camera"
                                                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                                                >
                                                                    <i className="bx bx-plus-circle"></i>
                                                                </Label>}
                                                                {salry.categoryType == 2 && <Label
                                                                    className="m-2 text-danger"
                                                                    id="add-time-camera"
                                                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                                                >
                                                                    <i className="bx bx-minus-circle"></i>
                                                                </Label>}
                                                                <Input
                                                                    name="cost"
                                                                    placeholder="0"
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={salry.amount}
                                                                    id="cost"
                                                                    onChange={(e) => {
                                                                        handleAmountChange(key, e.target.value)
                                                                    }}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </td>
                                                    <td style={{ width: "15%" }}>
                                                        {!salry.masterPayslipItemId && <i
                                                            onClick={() =>
                                                                handleRemove(salry.categoryId)
                                                            }
                                                            className="bx bx-message-square-x text-danger h3 mt-1"
                                                            style={{ cursor: "pointer" }}
                                                        ></i>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    {newCategories.length > 0 && <Row className='m-2'>
                                        <Col lg={3}></Col>
                                        <Col ></Col>
                                        <Col></Col>
                                        <Col >
                                            <Label>
                                                {"Net Payable Amount:"}
                                            </Label>
                                            <Input
                                                name="Total"
                                                placeholder="0.00"
                                                type="number"
                                                className={`form-control float-end m-2 text-success`}
                                                value={(calculateTotalAmountOfNetPayble())}
                                                id="Total"
                                                readOnly
                                            />
                                        </Col>
                                        <Col lg={2}></Col>
                                    </Row>}
                                </Col>
                            </Row>
                        )}
                        {newCategories.length > 0 && <hr />}
                        <div className="float-end ">
                            <Button
                                color="primary"
                                className="btn waves-effect waves-light float-right m-2"
                                disabled={saveLoding}
                                onClick={(e) => {
                                    handelSave()
                                }}
                            >
                                {saveLoding ? "Saving..." : "Save"}
                            </Button>
                        </div>
                    </CardBody>
                </Card>) : (<Card>
                    <CardBody>
                        <div className="text-info text-center">
                            <Alert color="info">Payroll setup is pending. To set it up, click the Setup button below.</Alert>
                        </div>
                        <div className="text-center">
                            <Link
                                to={`/setup-master-payroll/${schoolId}/${teacherId}`}
                                className="btn btn-primary text-center"
                            >
                                Setup <i className="bx bx-right-arrow-alt"></i>
                            </Link>
                        </div>
                    </CardBody>
                </Card>)}
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { selectedTeacher } = state.Teacher;
    const { payrollCategories, masterPayslip, masterPayslipItems, payslip, payslipItems } = state.LeaveModule;

    return { selectedSchool, selectedTeacher, payrollCategories, masterPayslip, masterPayslipItems, payslip, payslipItems };
};

export default connect(mapStatetoProps, {
    setSelecetdSchool, setSelecetdTeacher, getPayrollCategoriesForOwnerId, getMasterPayslipForTeacherId,
    getMasterPayslipItemsForMasterPayslipId, getPayslipForMonth, getPayslipItemsForPayslipId, setSelectedMonth,
})(withRouter(withNamespaces()(SetupPayroll)));
