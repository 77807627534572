import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil";

const initialState = {
  leavesApplied: [],
  leavesPolicies: [],
  leavesPoliciesItems: [],
  payrollCategories: [],
  masterPayslipItems: [],
  masterPayslip: undefined,
  payslipItems: [],
  payslip: undefined,
  selectedPayslip: undefined,
  payslips: [],
  masterPayslips: [],
  holidayList: [],
  allPayslipItems: [],
  selectedMonth: undefined,

};

const LeaveModule = (state = initialState, action) => {
  switch (action.type) {

    case actions.UPDATE_LEAVE_MODULE:
      var leavesApplied = [...state.leavesApplied]
      if (leavesApplied.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.leavesApplied], action.payload.leaves, "isDeleted", false)
        state = { ...state, leavesApplied: updatedList };
      } else {
        var others = action.payload.leaves.filter(
          (f) => f.attributes.isDeleted !== true
        );
        state = { ...state, leavesApplied: others };
        // console.log("New leavesApplied list:", others);
      }
      break;

    case actions.UPDATE_LEAVE_POLICY:
      var leavesPolicies = state.leavesPolicies ? [...state.leavesPolicies] : []
      if (leavesPolicies.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.leavesPolicies], action.payload.leavesPolicies, "isDeleted", false)
        state = { ...state, leavesPolicies: updatedList };
      } else {
        var others = action.payload.leavesPolicies.filter(
          (f) => f.attributes.isDeleted !== true
        );
        state = { ...state, leavesPolicies: others };
        // console.log("New leavesPolicies list:", others);
      }
      break;

    case actions.GET_LEAVE_POLICY_ITEMS_FOR_POLICY_ID:
      state = { ...state, leavesPoliciesItems: undefined };
      break;

    case actions.UPDATE_LEAVE_POLICY_ITEMS_FOR_POLICY_ID:
      state = { ...state, leavesPoliciesItems: action.payload.leavesPoliciesItems };
      break;

    case actions.UPDATE_PAYROLL_CATEGORIES:
      var payrollCategories = state.payrollCategories ? [...state.payrollCategories] : []
      if (payrollCategories.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.payrollCategories], action.payload.payrollCategories, "isDeleted", false)
        state = { ...state, payrollCategories: updatedList };
      } else {
        var others = action.payload.payrollCategories.filter(
          (f) => f.attributes.isDeleted !== true
        );
        state = { ...state, payrollCategories: others };
      }
      break;
    case actions.UPDATE_MASTER_PAYSLIP_FOR_TEACHER_ID:
      state = { ...state, masterPayslip: action.payload.masterPayslip };
      break;
    case actions.UPDATE_MASTER_PAYSLIP_ITEMS_FOR_MASTER_PAYSLIP_ID:
      state = { ...state, masterPayslipItems: action.payload.masterPayslipItems };
      break;
    case actions.UPDATE_PAYSLIP_FOR_MONTH:
      state = { ...state, payslip: action.payload.payslip };
      break;
    case actions.UPDATE_PAYSLIP_FOR_ID:
      state = { ...state, selectedPayslip: action.payload.selectedPayslip };
      break;
    case actions.UPDATE_PAYSLIP_ITEMS_FOR_PAYSLIP_ID:
      state = { ...state, payslipItems: action.payload.payslipItems };
      break;
    case actions.UPDATE_PAYSLIPS_FOR_SCHOOL_ID_AND_MONTH:
      state = { ...state, payslips: action.payload.payslips };
      break;

    case actions.UPDATE_PAYSLIPS_FOR_OWNER_ID_AND_OWNER_TYPE:
      state = { ...state, masterPayslips: action.payload.masterPayslips };
      break;
    case actions.UPDATE_PAYSLIP_ITEMS_FOR_PAYSLIP_IDS:
      state = { ...state, allPayslipItems: action.payload.allPayslipItems };
      break;
    case actions.SET_SELECTED_MONTH:
      state = { ...state, selectedMonth: action.payload.selectedMonth };
      break;
    case actions.UPDATE_PAYROLL_CATEGORIES:
      var payRollCategories = state.payRollCategories ? [...state.payRollCategories] : []
      if (payRollCategories.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.payRollCategories], action.payload.payRollCategories, "isDeleted", false)
        state = { ...state, payRollCategories: updatedList };
      } else {
        var others = action.payload.payRollCategories.filter(
          (f) => f.attributes.isDeleted !== true
        );
        state = { ...state, payRollCategories: others };
        console.log("New payRollCategories list:", others);
      }
      break;

    case actions.UPDATE_HOLIDAY_LIST:
      var holidayList = [...state.holidayList];
      if (holidayList.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.holidayList], action.payload.holidayList, "isDeleted", false);
        state = { ...state, holidayList: updatedList };
      } else {
        var others = action.payload.holidayList.filter(
          (f) => f.attributes.isDeleted !== true
        );
        state = { ...state, holidayList: others };
      }
      break;


    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LeaveModule;
