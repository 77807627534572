import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, TabContent, TabPane, Card, Form, Button, CardText, Nav, Table, Input, CardBody, FormGroup } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import classnames from 'classnames';
import SweetAlert from "react-bootstrap-sweetalert";
import Parse from 'parse';
import Moment from 'moment';
import { AvForm, AvField } from "availity-reactstrap-validation";
import Switch from "react-switch";
import OnOffSymbol from "../../components/Common/SwitchOnOff";
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from "uuid";

import {
    setSelecetdSchool, getSubjectsForExamId, getExamForExamId
} from '../../store/actions';
import moment from 'moment';

const NewExam = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/new-exam/:schoolId",
        exact: true,
        strict: false,
    });

    const matchEdit = matchPath(props.history.location.pathname, {
        path: "/edit-exam/:schoolId/:examId",
        exact: true,
        strict: false,
    });

    const schoolId = (match || matchEdit).params.schoolId;
    const examId = matchEdit && matchEdit.params.examId;

    const [examName, setExamName] = useState();
    const [examStartDate, setExamStartDate] = useState(undefined);
    const [examEndDate, setExamEndDate] = useState(undefined);
    const [certificateIssueDate, setCertificateIssueDate] = useState(undefined);
    const [batchName, setBatchName] = useState();
    const [examNameError, setExamNameError] = useState();
    const [examStartDateError, setExamStartDateError] = useState(undefined);
    const [examEndDateError, setExamEndDateError] = useState(undefined);
    const [certificateIssueDateError, setCertificateIssueDateError] = useState(undefined);
    const [saveLoading, setSaveLoading] = useState(false)
    const [resultType, setResultType] = useState(1)

    const getSubjectObject = () => {
        return {
            subjectId: uuidv4(),
            subjectName: undefined,
            examStartDate: undefined,
            duration: 0,
            theory: undefined,
            passing: undefined,
            totalMarks: undefined,
            subjectNameError: false,
            examStartDateError: false,
            theoryMarksError: false,
            passingMarksError: false,

            practicalData: [{
                practicalId: uuidv4(),
                practicalName: undefined,
                practicalStartDate: undefined,
                duration: undefined,
                practicalMarks: undefined,
                passingMarks: undefined,
                totalMarks: undefined,
                practicalNameError: false,
                practicalStartDateError: false,
                practicalMarksError: false,
                passingMarksError: false,
            }
            ]
        }
    }

    const [tableData, setTableData] = useState([getSubjectObject()]);

    const currentYear = new Date().getFullYear();
    const currentAcademicYear = `${currentYear}-${(currentYear + 1).toString().slice(-2)}`;

    const [examYear, setExamYear] = useState(currentAcademicYear);

    const options = [];
    for (let i = currentYear - 10; i <= currentYear + 5; i++) {
        const academicYearOption = `${i}-${(i + 1).toString().slice(-2)}`;
        options.push(academicYearOption);
    }


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (examId) {
            props.getSubjectsForExamId(examId);
            props.getExamForExamId(examId);
        }
    }, [examId]);

    useEffect(() => {

        if ((matchEdit) && props.selectedExam && props.selectedExam.id == examId) {
            setExamName(props.selectedExam.attributes.name);
            setExamStartDate(new Date(props.selectedExam.attributes.startDate));
            setExamEndDate(new Date(props.selectedExam.attributes.endDate));
            setExamYear(props.selectedExam.attributes.tag && props.selectedExam.attributes.tag.length > 0 && props.selectedExam.attributes.tag[0]);
            setBatchName(props.selectedExam.attributes.batch)
            setCertificateIssueDate(props.selectedExam.attributes.certificateDate ? new Date(props.selectedExam && props.selectedExam.attributes.certificateDate) : "");
        }
    }, [props.selectedExam]);

    // console.log(props.subjects);
    // console.log(props.selectedExam);
    // console.log(tableData);

    useEffect(() => {

        if ((matchEdit) && props.subjects && props.subjects.length > 0) {
            let theorySubjects = []
            let practicalSubjects = []
            let subjects = [];
            console.log(practicalSubjects);
            console.log(theorySubjects);
            console.log(props.subjects)
            for (const sub of props.subjects) {
                if (sub.attributes.examId == examId) {
                    if (sub.attributes.theoryMarks) {
                        theorySubjects.push(sub)
                    } else {
                        practicalSubjects.push(sub)
                    }
                }
            }
            for (const s of theorySubjects) {

                let newSubject = {
                    subjectId: uuidv4(),
                    parseObject: s,
                    subjectName: s.attributes.name,
                    examStartDate: new Date(s.attributes.examDate),
                    duration: s.attributes.duration,
                    theory: s.attributes.theoryMarks,
                    passing: s.attributes.passMarks,
                    totalMarks: s.attributes.maxMarks,
                    subjectNameError: false,
                    examStartDateError: false,
                    theoryMarksError: false,
                    passingMarksError: false,

                    practicalData: [
                    ]
                }


                let pList = []
                for (const p of practicalSubjects) {

                    if (p.attributes.subjectId == s.id) {
                        let pObj = {
                            practicalId: uuidv4(),
                            parseObject: p,
                            practicalName: p.attributes.name,
                            practicalStartDate: new Date(p.attributes.examDate),
                            duration: p.attributes.duration,
                            practicalMarks: p.attributes.practicalMarks,
                            passingMarks: p.attributes.passMarks,
                            practicalNameError: false,
                            practicalStartDateError: false,
                            practicalMarksError: false,
                            passingMarksError: false,
                        }
                        pList.push(pObj)
                    }
                }

                newSubject.practicalData = pList;
                subjects.push(newSubject)


            }
            setTableData(subjects)
        }

    }, [props.subjects])

    function handleNewSubject() {
        let items = [...tableData]

        items.push(getSubjectObject())
        setTableData(items)
    }

    const handleRemoveSubject = (item) => {
        let newItems = tableData.filter((k) => k.subjectId !== item.subjectId);
        setTableData(newItems);
    }


    function handleNewPractical(subjectIndex) {
        const updatedTableData = [...tableData];
        const newItem = {
            practicalId: uuidv4(),
            practicalName: undefined,
            practicalStartDate: undefined,
            practicalMarks: undefined,
            passingMarks: undefined,
            totalMarks: undefined,
        };

        updatedTableData[subjectIndex].practicalData.push(newItem);
        setTableData(updatedTableData);
    }


    function handleRemovePractical(practicalIndex, subjectIndex) {

        const updatedTableData = [...tableData];

        const updatedSubject = subjectIndex

        updatedSubject.practicalData = updatedSubject.practicalData.filter(
            (practicalItem) => practicalItem.practicalId !== practicalIndex.practicalId
        );

        updatedTableData[subjectIndex] = updatedSubject;
        setTableData(updatedTableData);
    }

    const handleSave = () => {

        let canSave = true;

        if (tableData.length == 0) {
            canSave = false;
        }

        let allData = [];

        for (let subject of tableData) {
            if (!subject.subjectName) {
                subject.subjectNameError = true;
                canSave = false;
            }

            if (!subject.examStartDate) {
                subject.examStartDateError = true;
                canSave = false;
            }

            if (!subject.theory) {
                subject.theoryMarksError = true;
                canSave = false;
            }

            if (!subject.passing) {
                subject.passingMarksError = true;
                canSave = false;
            }

            let practicalData = [];

            for (let practical of subject.practicalData) {
                if (!practical.practicalName) {
                    practical.practicalNameError = true;
                    canSave = false;
                }

                if (!practical.practicalStartDate) {
                    practical.practicalStartDateError = true;
                    canSave = false;
                }

                if (!practical.practicalMarks) {
                    practical.practicalMarksError = true;
                    canSave = false;
                }

                if (!practical.passingMarks) {
                    practical.passingMarksError = true;
                    canSave = false;
                }

                practicalData.push(practical);
            }

            subject.practicalData = practicalData;
            allData.push(subject);
        }

        setTableData(allData);

        if (!examName) {
            setExamNameError(true);
            canSave = false;
        }
        if (!examStartDate) {
            setExamStartDateError(true);
            canSave = false;
        }
        if (!examEndDate) {
            setExamEndDateError(true);
            canSave = false;
        }
        if (!certificateIssueDate) {
            setCertificateIssueDateError(true);
            canSave = false;
        }

        if (!canSave) {
            return
        }

        setSaveLoading(true);
        const Group = Parse.Object.extend("Group");
        const group = new Group();
        if (matchEdit) {
            group.id = props.selectedExam.attributes.groupId
            group.set('name', examName);
        } else {
            group.set('name', examName);
            group.set('ownerId', Parse.User.current().attributes.teacherId);
            group.set('schoolId', schoolId);
            group.set('type', 3);
        }


        group.save().then((groupResult) => {
            const Examination = Parse.Object.extend("Examination");
            const examination = new Examination();

            if (matchEdit) {
                examination.id = props.selectedExam.id
            } else {
                examination.set("groupId", groupResult.id);
                examination.set("schoolId", schoolId);
            }
            examination.set("name", examName);
            examination.set("startDate", examStartDate);
            examination.set("endDate", examEndDate);
            examination.set("tag", [examYear]);
            examination.set("batch", batchName);
            examination.set("certificateDate", certificateIssueDate);
            examination.set("resultType", parseInt(resultType));

            examination.save().then((examResults) => {
                // console.log(examResults);
                saveSubject(examResults, 0);
                window.history.back()
            });
        })
            .catch((error) => {
                console.error("Error saving group:", error);
            });
    };



    const saveSubject = (examResults, i) => {
        if (i < tableData.length) {
            const subject = tableData[i];

            const ExamSubjects = Parse.Object.extend("ExamSubjects");
            const examSubject = new ExamSubjects();

            if (matchEdit && subject.parseObject) {
                examSubject.id = subject.parseObject.id
            } else {
                examSubject.set("examId", examResults.id);
            }

            examSubject.set("theoryMarks", parseInt(subject.theory));
            examSubject.set("maxMarks", parseInt(subject.totalMarks));
            examSubject.set("passMarks", parseInt(subject.passing));
            examSubject.set("name", subject.subjectName);
            examSubject.set("examDate", subject.examStartDate);
            examSubject.set("duration", parseInt(subject.duration));
            examSubject.set("orderNumber", parseInt(i + ""));
            examSubject.set("order", i + "");

            examSubject.save().then((examSubjectResults) => {
                console.log(examSubjectResults);

                let allSubjects = []

                let practicalData = subject.practicalData;
                for (let j = 0; j < practicalData.length; j++) {
                    const practicalItem = practicalData[j];

                    const practicalSubject = new ExamSubjects();

                    if (matchEdit && practicalItem.parseObject) {
                        practicalSubject.id = practicalItem.parseObject.id
                    } else {
                        practicalSubject.set("examId", examResults.id);
                        practicalSubject.set("subjectId", examSubject.id);
                    }

                    practicalSubject.set("practicalMarks", parseInt(practicalItem.practicalMarks));
                    practicalSubject.set("maxMarks", parseInt(practicalItem.practicalMarks));
                    practicalSubject.set("passMarks", parseInt(practicalItem.passingMarks));
                    practicalSubject.set("name", practicalItem.practicalName);
                    practicalSubject.set("examDate", practicalItem.practicalStartDate);
                    practicalSubject.set("duration", parseInt(practicalItem.duration));
                    practicalSubject.set("orderNumber", parseInt(j + ""));
                    practicalSubject.set("order", j + "");

                    allSubjects.push(practicalSubject);
                }

                Parse.Object.saveAll(allSubjects)
                    .then((savedSubjects) => {
                        // console.log(" subjects:", savedSubjects);
                        saveSubject(examResults, i + 1)
                        setSaveLoading(false);
                    })
                    .catch((error) => {
                        console.error("Error saving subjects:", error);
                    });


            });
        } else {
            // successfully saved

        }
    };



    const getTotalMarksForSubject = (subject) => {
        let total = 0;

        if (subject.theory) {
            total = parseInt(subject.theory)
        }

        for (const p of subject.practicalData) {
            if (p.practicalMarks) {
                total += parseInt(p.practicalMarks)
            }
        }
        return total

    }
    return (

        <React.Fragment>
            <div className="page-content">
                <Row>
                    <Col>
                        <Row className='d-flex align-items-start'>
                            <Col className="d-flex align-items-start">
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { window.history.back() }}>
                                    <ul className=" list-unstyled">
                                        <div className=''>
                                            <i className="bx bx-chevron-left h1 text-primary"></i>
                                        </div>
                                    </ul>
                                </div>
                                <div className=' m-2'>
                                    <h4 className="mb-0">{match ? "New Exam" : examName}</h4>
                                </div>
                            </Col>
                            <Col className='d-flex justify-content-end'>
                                <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                                    {
                                        saveLoading ?
                                            <Button type="button" color="primary" className=" waves-effect float-right m-1" disabled={saveLoading}>
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle m-1"></i>Saving...
                                            </Button> :
                                            <Button type="submit" className='m-1' disabled={saveLoading}
                                                color="primary" onClick={(e) => {
                                                    handleSave(e.target.value);
                                                }}>
                                                Save
                                            </Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <Row lg={12}>
                                    <Col lg={4}>
                                        <Label>Exam Name</Label>
                                        <Input
                                            type="text"
                                            name="singleLineAG"
                                            id="singleLineAG3"
                                            placeholder="Exam Name"
                                            className="form-control"
                                            value={examName}
                                            onChange={(e) => {
                                                setExamName(e.target.value);
                                                setExamNameError(false);
                                            }}
                                        />
                                        {examNameError && <small className='text-danger'>Enter Exam Name</small>}
                                    </Col>
                                    <Col lg={4}>
                                        <Label className="mt-1 ml-1">Start Date</Label>
                                        <DatePicker
                                            selected={examStartDate}
                                            onChange={(date) => {
                                                setExamStartDate(new Date(date))
                                                setExamStartDateError(false)
                                            }}
                                            className="form-control"
                                            placeholderText={props.t("Select Start Date")}
                                            startDate={examStartDate}
                                            endDate={examEndDate}
                                            //maxDate={endDate}
                                            dateFormat="dd/MM/yyyy"
                                            showDayMonthYearPicker
                                        />
                                        {examStartDateError && <small className='text-danger'>Enter Exam Start Date</small>}
                                    </Col>
                                    <Col lg={4}>
                                        <Label className="mt-1 ml-1">End Date</Label>
                                        <DatePicker
                                            selected={examEndDate}
                                            onChange={(date) => {
                                                setExamEndDate(new Date(date))
                                                setExamEndDateError(false)
                                            }}
                                            className="form-control"
                                            placeholderText={props.t("Select Start Date")}
                                            startDate={examEndDate}
                                            // endDate={examEndDate}
                                            minDate={examStartDate}
                                            dateFormat="dd/MM/yyyy"
                                            showDayMonthYearPicker
                                        />
                                        {examEndDateError && <small className='text-danger'>Enter Exam End Date</small>}
                                    </Col>
                                </Row>
                                <Row lg={12}>
                                    <Col lg={4}>
                                        <Label className="mt-1 ml-1">Year</Label>
                                        <Input
                                            className="form-control"
                                            type="select"
                                            id="academicYear"
                                            value={examYear}
                                            onChange={(e) => {
                                                setExamYear(e.target.value);
                                            }}
                                        >
                                            {options.map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <Col lg={4}>
                                        <Label>Batch Name</Label>
                                        <Input
                                            type="text"
                                            name="singleLineAG"
                                            id="singleLineAG3"
                                            placeholder="Batch Name"
                                            className="form-control"
                                            value={batchName}
                                            onChange={(e) => {
                                                setBatchName(e.target.value);
                                            }}
                                        />
                                    </Col>
                                    <Col lg={4}>
                                        <Label className="mt-1 ml-1">Date of Certificate Issue</Label>
                                        <DatePicker
                                            selected={certificateIssueDate}
                                            onChange={(date) => {
                                                setCertificateIssueDate(new Date(date))
                                                setCertificateIssueDateError(false)
                                            }}
                                            className="form-control"
                                            placeholderText={props.t("Select Start Date")}
                                            startDate={certificateIssueDate}
                                            // endDate={examEndDate}
                                            //maxDate={endDate}
                                            minDate={examEndDate}
                                            dateFormat="dd/MM/yyyy"
                                            showDayMonthYearPicker
                                        />
                                        {certificateIssueDateError && <small className='text-danger'>Enter Certificate issue Date</small>}
                                    </Col>
                                </Row>
                                        <FormGroup className='float-end mt-4 ml-4'>
                                            <div className=" d-flex align-items-center">
                                                <Label htmlFor="example-radio-input" className=" col-form-label">Select Result Type: </Label>
                                                <div className="custom-control custom-radio custom-control-inline m-2">
                                                    <Input
                                                        type="radio"
                                                        id="customRadioInline1"
                                                        name="customRadioInline1"
                                                        checked={resultType == 1}
                                                        className="custom-control-input m-1"
                                                        onChange={(e) => {
                                                            setResultType(1);
                                                        }}
                                                        required
                                                    />
                                                    <Label
                                                        className="custom-control-label"
                                                        htmlFor="customRadioInline1"
                                                    >
                                                        Class
                                                    </Label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline m-2" style={{ marginLeft: "30px" }}>
                                                    <Input
                                                        type="radio"
                                                        id="customRadioInline2"
                                                        name="customRadioInline1"
                                                        checked={resultType == 2}
                                                        className="custom-control-input m-1"
                                                        onChange={(e) => {
                                                            setResultType(2);
                                                        }}
                                                    />
                                                    <Label
                                                        className="custom-control-label"
                                                        htmlFor="customRadioInline2"
                                                    >
                                                        Grade
                                                    </Label>
                                                </div>
                                            </div>
                                        </FormGroup>
                            </CardBody>
                        </Card>


                        <Card>
                            <CardBody>
                                {/* <div className=''>
                                        <h4>Add Subjects</h4>
                                    </div> */}
                                <div className={"border rounded p-3 mb-1"}>
                                    <Row style={{ height: "8px", paddingBottom: "20px" }}>
                                        <Col lg={1}>
                                            <div>
                                                <div className=" ml-5">
                                                    <Label className="mb-1 font-size-14">
                                                        Sl.no
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div>
                                                <div className="">
                                                    <Label className="mb-1 font-size-14">
                                                        All Subject
                                                    </Label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="float-right">
                                                <Label className="mb-1 font-size-14">
                                                    Exam date
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="float-right">
                                                <Label className="mb-1 font-size-14">
                                                    Duration
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="float-right">
                                                <Label className="mb-1 font-size-14">
                                                    Max Marks
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="float-right">
                                                <Label className="mb-1 font-size-14">
                                                    Passing Marks
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col >
                                            <div className="float-right">
                                                <Label className="mb-1 font-size-14">
                                                    Total
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col lg={1}>
                                            <div>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>

                        <div>
                            <div className="flex-fill mr-2 ml-0 mt-2 mb-0">
                                <Row>
                                    <Col>

                                        {tableData.map((subject, subjectIndex) => (
                                            <Card>
                                                <CardBody>
                                                    <div className="border rounded p-3 mb-1" key={subjectIndex}>
                                                        <Row>
                                                            <Col lg={1}>
                                                                <div>
                                                                    <Label className="mb-1 font-size-14">
                                                                        <div className=" ml-5">
                                                                            <h5 className="m-2">{subjectIndex + 1}</h5>
                                                                        </div>
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col >
                                                                <div>
                                                                    <FormGroup className="position-relative">
                                                                        <div className="col-md-10">
                                                                            <Input
                                                                                name="Subject Name"
                                                                                placeholder="Subject Name"
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={subject.subjectName}
                                                                                id="units"
                                                                                onChange={(e) => {
                                                                                    const updatedTableData = [...tableData];
                                                                                    subject.subjectNameError = false;
                                                                                    updatedTableData[subjectIndex].subjectName = e.target.value;
                                                                                    setTableData(updatedTableData);
                                                                                }}
                                                                            />
                                                                            {subject.subjectNameError && <small className='text-danger'>Enter Subject Name</small>}
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                            </Col>
                                                            <Col >
                                                                <div className="float-right">
                                                                    <FormGroup className="position-relative">
                                                                        <div className="col-md-10">
                                                                            <DatePicker
                                                                                selected={subject.examStartDate}
                                                                                onChange={(date) => {
                                                                                    const updatedTableData = [...tableData];
                                                                                    subject.examStartDateError = false;
                                                                                    updatedTableData[subjectIndex].examStartDate = date;
                                                                                    setTableData(updatedTableData);
                                                                                }}
                                                                                className="form-control"
                                                                                placeholderText={props.t("Exam Date")}
                                                                                dateFormat="dd/MM/yyyy"
                                                                                showDayMonthYearPicker
                                                                            />
                                                                            {subject.examStartDateError && <small className='text-danger'>Enter Exam Date</small>}
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                            </Col>
                                                            <Col >
                                                                <div className="float-right">
                                                                    <FormGroup className="position-relative">
                                                                        <div className="col-md-10">
                                                                            <Input
                                                                                name="Duration"
                                                                                placeholder="In minutes"
                                                                                type="number"
                                                                                className="form-control"
                                                                                value={subject.duration}
                                                                                id="duration"
                                                                                onChange={(e) => {
                                                                                    const updatedTableData = [...tableData];
                                                                                    updatedTableData[subjectIndex].duration = e.target.value;
                                                                                    setTableData(updatedTableData);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                            </Col>
                                                            <Col >
                                                                <div className="float-right">
                                                                    <FormGroup className="position-relative">
                                                                        <div className="col-md-10">
                                                                            <Input
                                                                                name="units"
                                                                                placeholder="Theory Marks"
                                                                                type="number"
                                                                                className="form-control"
                                                                                value={subject.theory}
                                                                                id="units"
                                                                                onChange={(e) => {
                                                                                    const updatedTableData = [...tableData];
                                                                                    subject.theoryMarksError = false;
                                                                                    updatedTableData[subjectIndex].theory = e.target.value;
                                                                                    updatedTableData[subjectIndex].totalMarks = getTotalMarksForSubject(updatedTableData[subjectIndex])
                                                                                    setTableData(updatedTableData);
                                                                                }}
                                                                            />
                                                                            {subject.theoryMarksError && <small className='text-danger'>Enter Max Marks</small>}
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                            </Col>
                                                            <Col >
                                                                <div className="float-right">
                                                                    <FormGroup>
                                                                        <div className="col-md-10">
                                                                            <Input
                                                                                name="units"
                                                                                placeholder="Passing Marks"
                                                                                type="number"
                                                                                className="form-control"
                                                                                value={subject.passing}
                                                                                id="units"
                                                                                onChange={(e) => {
                                                                                    const updatedTableData = [...tableData];
                                                                                    subject.passingMarksError = false;
                                                                                    updatedTableData[subjectIndex].passing = e.target.value;
                                                                                    setTableData(updatedTableData);
                                                                                }}
                                                                            />
                                                                            {subject.passingMarksError && <small className='text-danger'>Enter Passing Marks</small>}
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                            </Col>
                                                            <Col >
                                                                <div className="float-right">
                                                                    <FormGroup>
                                                                        <div className="col-md-10">
                                                                            <Input
                                                                                name="amount"
                                                                                placeholder="Total marks"
                                                                                type="number"
                                                                                className="form-control"
                                                                                id="quantity"
                                                                                value={subject.totalMarks}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                            </Col>
                                                            <Col lg={1}>
                                                                <div>
                                                                    <Button
                                                                        color='danger'
                                                                        className='btn btn-sm'
                                                                        onClick={() => {
                                                                            handleRemoveSubject(subject)
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {subject.practicalData.map((item, key) => (
                                                            <Row key={key}>
                                                                <Col lg={1}>
                                                                    <div>
                                                                        <Label className="mb-1 font-size-14">
                                                                            <div className=" ml-5">
                                                                                <Row>
                                                                                    <Col>
                                                                                        {/* <h5 className="m-2">{key + 1}</h5> */}
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <b>
                                                                                            <i
                                                                                                className='bx bx-plus btn-success'
                                                                                                style={{ color: 'green', marginTop: '8px', fontSize: '24px' }}
                                                                                                onClick={() => {
                                                                                                    handleNewPractical(subjectIndex, key);
                                                                                                }}
                                                                                            ></i>
                                                                                        </b>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Label>
                                                                    </div>

                                                                </Col>

                                                                <Col>
                                                                    <div>
                                                                        <FormGroup className="position-relative">
                                                                            <div className="col-md-10">
                                                                                <Input
                                                                                    name="Practical Name"
                                                                                    placeholder="Practical Name"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={item.practicalName}
                                                                                    id="units"
                                                                                    onChange={(e) => {
                                                                                        const updatedTableData = [...tableData];
                                                                                        item.practicalNameError = false
                                                                                        updatedTableData[subjectIndex].practicalData[key].practicalName = e.target.value;
                                                                                        setTableData(updatedTableData);
                                                                                    }}
                                                                                />
                                                                                {item.practicalNameError && <small className='text-danger'>Enter Subject Name</small>}
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                                <Col >
                                                                    <div className="float-right">
                                                                        <FormGroup className="position-relative">
                                                                            <div className="col-md-10">
                                                                                <DatePicker
                                                                                    selected={item.practicalStartDate}
                                                                                    onChange={(date) => {
                                                                                        const updatedTableData = [...tableData];
                                                                                        item.practicalStartDateError = false;
                                                                                        updatedTableData[subjectIndex].practicalData[key].practicalStartDate = date;
                                                                                        setTableData(updatedTableData);
                                                                                    }}
                                                                                    className="form-control"
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    placeholderText={props.t("Practical Date")}
                                                                                />
                                                                                {item.practicalStartDateError && <small className='text-danger'>Enter Exam Date</small>}
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                                <Col >
                                                                    <div className="float-right">
                                                                        <FormGroup className="position-relative">
                                                                            <div className="col-md-10">
                                                                                <Input
                                                                                    name="Duration"
                                                                                    placeholder="In minutes"
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    value={item.duration}
                                                                                    id="duration"
                                                                                    onChange={(e) => {
                                                                                        const updatedTableData = [...tableData];
                                                                                        updatedTableData[subjectIndex].practicalData[key].duration = e.target.value;
                                                                                        setTableData(updatedTableData);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                                <Col >
                                                                    <div className="float-right">
                                                                        <FormGroup className="position-relative">
                                                                            <div className="col-md-10">
                                                                                <Input
                                                                                    name="units"
                                                                                    placeholder="Practical Marks"
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    value={item.practicalMarks}
                                                                                    id="units"
                                                                                    onChange={(e) => {
                                                                                        const updatedTableData = [...tableData];
                                                                                        item.practicalMarksError = false
                                                                                        updatedTableData[subjectIndex].practicalData[key].practicalMarks = e.target.value;
                                                                                        updatedTableData[subjectIndex].totalMarks = getTotalMarksForSubject(updatedTableData[subjectIndex])
                                                                                        setTableData(updatedTableData);
                                                                                    }}
                                                                                />
                                                                                {item.practicalMarksError && <small className='text-danger'>Enter Max Marks</small>}
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div className="float-right">
                                                                        <FormGroup>
                                                                            <div className="col-md-10">
                                                                                <Input
                                                                                    name="units"
                                                                                    placeholder="Passing Marks"
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    value={item.passingMarks}
                                                                                    id="units"
                                                                                    onChange={(e) => {
                                                                                        const updatedTableData = [...tableData];
                                                                                        item.passingMarksError = false;
                                                                                        updatedTableData[subjectIndex].practicalData[key].passingMarks = e.target.value;
                                                                                        setTableData(updatedTableData);
                                                                                    }}
                                                                                />
                                                                                {item.passingMarksError && <small className='text-danger'>Enter Passing Marks</small>}
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                                <Col >
                                                                    <div className="float-right">
                                                                        <FormGroup className="position-relative">
                                                                            <div className="col-md-10">

                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={1}>
                                                                    <div className='text-center'>
                                                                        <b>
                                                                            <i
                                                                                className='bx bx-x btn-success'
                                                                                style={{ color: 'red', marginTop: '8px', fontSize: '24px' }}
                                                                                onClick={() => {
                                                                                    handleRemovePractical(item, subject)
                                                                                }}
                                                                            ></i>
                                                                        </b>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Row>
                            <div className="d-flex justify-content-end">
                                {
                                    saveLoading ?
                                        <Button type="button" color="primary" className=" waves-effect float-right m-1" disabled={saveLoading}>
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle m-1"></i>Saving...
                                        </Button> :
                                        <Button type="submit" className='m-1' disabled={saveLoading}
                                            color="primary" onClick={(e) => {
                                                handleSave(e.target.value);
                                            }}>
                                            Save
                                        </Button>
                                }
                                {<Button color="success" className="btn waves-effect waves-light float-right m-1" onClick={() => {
                                    handleNewSubject()
                                }}>
                                    Add Subject<i className="mdi mdi-plus mr-1" />
                                </Button>}
                            </div>
                        </Row>
                    </Col>
                </Row>

            </div >
        </React.Fragment >

    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { subjects } = state.Subjects;
    const { selectedExam } = state.Examination;

    return {
        selectedSchool,
        subjects,
        selectedExam
    }
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getSubjectsForExamId, getExamForExamId
})(NewExam)));
