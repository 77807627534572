import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label, Form, FormGroup, Input, Table, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
    setSelecetdSchool, setSelecetdTeacher, getPayrollCategoriesForOwnerId, getMasterPayslipForTeacherId,
    getMasterPayslipItemsForMasterPayslipId, editTeacher, updateMasterPayslipItemsForMasterPayslipId
} from '../../store/actions';
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import Editable from "react-bootstrap-editable";
import Parse from 'parse';

const SetupMasterPayroll = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/setup-master-payroll/:schoolId/:teacherId",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.schoolId;
    const teacherId = match.params.teacherId;

    const [categoryList, setCategoryList] = useState([]);
    const [saveLoding, setSaveLoding] = useState(false);
    const [selectedCategoryList, setSelectedCategoryList] = useState([]);
    const [employeeCurrentSalary, setEmployeeCurrentSalary] = useState()
    const [amountTotalMatch, setAmountTotalMatch] = useState(false)
    const [showSalaryDifferencePopup, setShowSalaryDifferencePopup] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool]);

    useEffect(() => {
        if (teacherId) {
            props.setSelecetdTeacher(teacherId);
            props.getMasterPayslipForTeacherId(teacherId)
        }
    }, [teacherId])

    useEffect(() => {
        if (schoolId) {
            props.getPayrollCategoriesForOwnerId(schoolId, "School");
        }
    }, [schoolId]);


    useEffect(() => {
        if (props.masterPayslip) {
            props.getMasterPayslipItemsForMasterPayslipId(props.masterPayslip.id);
        }
    }, [props.masterPayslip]);

    useEffect(() => {
        if (props.selectedTeacher && props.selectedTeacher) {
            setEmployeeCurrentSalary(props.selectedTeacher.attributes.currentSalary)
        }
    }, [props.selectedTeacher])

    useEffect(() => {
        if (props.payrollCategories && props.payrollCategories.length > 0) {
            let tempSelectList = []
            for (const i of props.payrollCategories) {
                tempSelectList.push(getSelectionObject(i))
            }
            setCategoryList(tempSelectList)
        }
    }, [props.inventoryItems])

    const getSelectionObject = (payrollCategory) => {
        console.log("payrollCategory get ", payrollCategory)
        let tempSelectObj = {}
        let obj = payrollCategory.attributes
        tempSelectObj.id = payrollCategory.id
        tempSelectObj.categoryName = obj.title ?? ""
        tempSelectObj.value = payrollCategory.id
        tempSelectObj.label = obj.title ?? ""
        tempSelectObj.parseObject = payrollCategory
        return tempSelectObj
    }

    const getSelectionCategoryFromId = (id) => {
        for (const item of props.payrollCategories) {
            if (item.id == id) {
                return getSelectionObject(item)
            }
        }
    }


    const [salaryComponents, setSalaryComponents] = useState([]);

    const setBasicItem = () => {
        const basicItems = [
            {
                itemId: uuidv4(),
                category: getSelectionCategoryFromId("mWtWp7jJwe"),
                categoryType: 1,
                categoryName: "Basic Pay",
                categoryId: "mWtWp7jJwe",
                amount: 0,
            },
            {
                itemId: uuidv4(),
                category: getSelectionCategoryFromId("rEBdOiTQgT"),
                categoryType: 1,
                categoryName: "House Rent Allowance",
                categoryId: "rEBdOiTQgT",
                amount: 0,
            },
            {
                itemId: uuidv4(),
                category: getSelectionCategoryFromId("noHlDtHDbI"),
                categoryType: 1,
                categoryName: "Flexible Pay",
                categoryId: "noHlDtHDbI",
                amount: 0,
            },
            {
                itemId: uuidv4(),
                category: getSelectionCategoryFromId("Hp0yag3Y8W"),
                categoryType: 2,
                categoryName: "Employee PF",
                categoryId: "Hp0yag3Y8W",
                amount: 0,
            },
            {
                itemId: uuidv4(),
                category: getSelectionCategoryFromId("rOOxiWNrlk"),
                categoryType: 2,
                categoryName: "Income Tax",
                categoryId: "rOOxiWNrlk",
                amount: 0,
            },
            {
                itemId: uuidv4(),
                category: getSelectionCategoryFromId("8vA4hohNrZ"),
                categoryType: 2,
                categoryName: "Professional Tax",
                categoryId: "8vA4hohNrZ",
                amount: 0,
            },
        ];

        setSalaryComponents(basicItems); 
    };



    useEffect(() => {
        const selectedCategories = salaryComponents.map(salry => salry.categoryId);
        setSelectedCategoryList(selectedCategories);
        totalMatch()
    }, [salaryComponents]);

    useEffect(() => {
        if (props.masterPayslip && props.masterPayslipItems && props.masterPayslipItems.length > 0) {
            console.log("props.masterPayslip", props.masterPayslip)
            console.log("props.masterPayslipItems", props.masterPayslipItems)
            let items = [];
            let selectedCategories = [];

            for (const sc of props.masterPayslipItems) {
                if (sc.attributes.masterPayslipId === props.masterPayslip.id) {
                    console.log("sc.attributes.categoryId", sc.attributes.categoryId)
                    console.log("getSelectionCategoryFromId(sc.attributes.categoryId)", getSelectionCategoryFromId(sc.attributes.categoryId))
                    let newItem = {
                        itemId: uuidv4(),
                        category: getSelectionCategoryFromId(sc.attributes.categoryId),
                        categoryId: sc.attributes.categoryId,
                        amount: sc.attributes.amount,
                        categoryName: sc.attributes.categoryName,
                        categoryType: sc.attributes.categoryType,
                        parseObject: sc
                    };
                    items.push(newItem);
                    selectedCategories.push(sc.attributes.categoryId);
                }
            }

            setSelectedCategoryList(selectedCategories);
            setSalaryComponents(items);
        }  else {
            setBasicItem(); 
        }
    }, [props.masterPayslipItems, props.masterPayslip,props.payrollCategories]);

    const handleNewCategory = () => {
        const newSalaryComponent = {
            itemId: uuidv4(),
            categoryType: undefined,
            categoryName: undefined,
            categoryId: undefined,
            category: undefined,
            amount: 0,
        };
        setSalaryComponents([...salaryComponents, newSalaryComponent]);
    };

    const handleRemove = (categoryId) => {
        const newItems = salaryComponents.filter((item) => item.categoryId !== categoryId);
        setSalaryComponents(newItems);
    };

    const handleAmountChange = (index, value) => {
        const updatedComponents = [...salaryComponents];
        updatedComponents[index].amount = parseFloat(value) || 0;
        setSalaryComponents(updatedComponents);
    };

    const createNewMasterPayslip = () => {
        let temp = props.selectedTeacher;
        temp.set("currentSalary", employeeCurrentSalary);
        props.editTeacher(temp);
        if (props.masterPayslip && props.masterPayslip) {
            diableMasterPayslip()
        } else {
            handelSave()
        }
    };

    const diableMasterPayslip = () => {
        let tempMasterPaySlip = props.masterPayslip;

        tempMasterPaySlip.set("isDisabled", true);
        tempMasterPaySlip.set("disabledBy", Parse.User.current().attributes.teacherId);
        tempMasterPaySlip.set("disabledOn", new Date());

        tempMasterPaySlip.save().then(
            (result) => {
                // console.log(result)
                handelSave()
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const handelSave = () => {

        let canSave = true;

        let newList = []
        for (let i of salaryComponents) {

            if (!i.amount || i.amount < 0) {
                i.amountError = true;
                canSave = false
            }
            newList.push(i)
        }
        setSalaryComponents(newList);

        if (!canSave) {
            return
        }

        setSaveLoding(true);

        let temp = props.selectedTeacher;
        temp.set("currentSalary", employeeCurrentSalary);
        props.editTeacher(temp);


        const MasterPayslip = Parse.Object.extend("MasterPayslip");
        let masterPayslip = new MasterPayslip();
        if (props.masterPayslip && props.masterPayslip && !showSalaryDifferencePopup) {
            masterPayslip.id = props.masterPayslip && props.masterPayslip.id
        }
        if (props.masterPayslip && props.masterPayslip && !showSalaryDifferencePopup) {
            masterPayslip.id = props.masterPayslip && props.masterPayslip.id
        } else {
            masterPayslip.set("teacherId", teacherId);
            masterPayslip.set("ownerId", schoolId);
            masterPayslip.set("ownerType", "School");
        }
        const salaryPerYear = parseFloat(props.selectedTeacher?.attributes?.currentSalary) || parseFloat(employeeCurrentSalary) || 0;
        const salaryPerMonth = salaryPerYear / 12;

        masterPayslip.set("salaryPerYear", Math.round(salaryPerYear * 100) / 100);
        masterPayslip.set("salaryPerMonth", Math.round(salaryPerMonth * 100) / 100);
        saveMasterPayslipObject(masterPayslip);

    }


    const saveMasterPayslipObject = (masterPayslip) => {
        masterPayslip.save().then(
            (result) => {
                result.pin()
                // console.log(result)
                if ((props.masterPayslip && props.masterPayslip && !showSalaryDifferencePopup)) {
                    let salaryObjects = [];
                    for (let i = 0; i < salaryComponents.length; i++) {
                        const MasterPayslipItems = Parse.Object.extend("MasterPayslipItems");
                        let masterPayslipItem = new MasterPayslipItems();

                        if (salaryComponents[i].parseObject) {
                            masterPayslipItem.id = salaryComponents[i].parseObject.id
                        } else {
                            masterPayslipItem.set("masterPayslipId", result.id);
                            masterPayslipItem.set("categoryId", salaryComponents[i].categoryId);
                            masterPayslipItem.set("categoryName", salaryComponents[i].categoryName);
                            masterPayslipItem.set("categoryType", salaryComponents[i].categoryType);
                        }

                        masterPayslipItem.set("amount", salaryComponents[i].amount);

                        salaryObjects.push(masterPayslipItem);
                    }

                    Parse.Object.saveAll(salaryObjects).then((results) => {
                        Parse.Object.pinAll(results);
                        setSaveLoding(false);
                        window.history.back();
                    }, (error) => {
                        console.log(error)
                    });
                } else {
                    let salaryObjects = [];
                    for (let i = 0; i < salaryComponents.length; i++) {
                        const MasterPayslipItems = Parse.Object.extend("MasterPayslipItems");
                        let masterPayslipItem = new MasterPayslipItems();

                        masterPayslipItem.set("masterPayslipId", result.id);
                        masterPayslipItem.set("categoryId", salaryComponents[i].categoryId ?? salaryComponents[i].category.id);
                        masterPayslipItem.set("categoryName", salaryComponents[i].categoryName);
                        masterPayslipItem.set("categoryType", salaryComponents[i].categoryType);
                        masterPayslipItem.set("amount", salaryComponents[i].amount);

                        salaryObjects.push(masterPayslipItem);
                    }
                    Parse.Object.saveAll(salaryObjects).then((results) => {
                        Parse.Object.pinAll(results);
                        setSaveLoding(false)
                        // console.log(results);
                        window.history.back();
                    });
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    const calculateTotalAmount = () => {
        return salaryComponents.reduce((total, component) => total + component.amount, 0).toFixed(2);
    };

    const totalMatch = (value) => {
        const totalAmount = parseFloat(calculateTotalAmount());
        const currentSalary = value ? (value / 12).toFixed(2) : (parseFloat(employeeCurrentSalary) / 12).toFixed(2) ?? (parseFloat(props.selectedTeacher?.attributes?.currentSalary) / 12).toFixed(2);

        setAmountTotalMatch(totalAmount == currentSalary);
    };

    const filteredCategoryList = () => {
        const selectedCategoryIds = salaryComponents.map(item => item.categoryId);
        return categoryList.filter(category => !selectedCategoryIds.includes(category.value));
    };

    return (
        <React.Fragment>
            <Modal size='lg' isOpen={showSalaryDifferencePopup}>
                <ModalHeader toggle={() => {
                    setShowSalaryDifferencePopup(false)
                }}>
                    Salary Slab Update
                </ModalHeader>
                <ModalBody>
                    <div>
                        <p>{`Are you sure you want to change the salary slab for ${props.selectedTeacher?.attributes?.Name}?`}</p>
                        <Row>
                            <Col>
                                <p><b>{`Previous Salary(per year): ${props.selectedTeacher?.attributes?.currentSalary}`}</b></p>
                                <p><b>{`New Salary(per year): ${employeeCurrentSalary}`}</b></p>
                            </Col>
                            <Col>
                                <p><b>{`Previous Salary(per month): ${props.selectedTeacher?.attributes?.currentSalary / 12}`}</b></p>
                                <p><b>{`New Salary(per month): ${(employeeCurrentSalary / 12).toFixed(2)}`}</b></p>
                            </Col>
                        </Row>
                        <p>{`This change will take effect from the next salary cycle and may impact payroll calculations. Do you want to proceed?`}</p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color='secondary'
                        className='btn'
                        onClick={() => {
                            setShowSalaryDifferencePopup(false)
                        }}>
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        className='btn'
                        onClick={() => {
                            if (showSalaryDifferencePopup) {
                                createNewMasterPayslip()
                            }
                        }}>
                        Create
                    </Button>
                </ModalFooter>
            </Modal>
            <div className='page-content'>
                <Row className="d-flex justify-content-between align-items-start">
                    <Col lg={6} className="d-flex align-items-start">
                        <div style={{ cursor: "pointer" }} onClick={() => {
                            props.updateMasterPayslipItemsForMasterPayslipId([])
                            window.history.back()
                        }}>
                            <ul className="list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className='m-2'>
                            <h4 className="font-size-18">{`Master Payroll for ${props.selectedTeacher?.attributes?.Name}`}</h4>
                        </div>
                    </Col>
                </Row>

                <Card>
                    <CardBody>
                        <Row>
                            <Col lg={1} className="text-center  ">
                                <div className="border p-2">
                                    {props.selectedTeacher && props.selectedTeacher.attributes.Photo ? (
                                        <div>
                                            <img
                                                style={{ width: "3.5rem", height: "3.5rem" }}
                                                className="rounded-circle avatar mb-2"
                                                src={props.selectedTeacher && props.selectedTeacher.attributes.Photo._url}
                                                alt=""
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className="avatar-lg mx-auto mb-2"
                                            style={{ width: "3.5rem", height: "3.5rem" }}
                                        >
                                            <span
                                                className={
                                                    "avatar-title rounded-circle bg-soft-primary text-primary"
                                                }
                                                style={{ fontSize: "30px" }}
                                            >
                                                {props.selectedTeacher && props.selectedTeacher.attributes.Name.charAt(0).toUpperCase()}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <Col lg={7}>
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="table mb-0 table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">
                                                        Name :<br />
                                                        Designation :<br />
                                                        Employee Code :<br />
                                                    </th>
                                                    <td colSpan="6">
                                                        {props.selectedTeacher?.attributes?.Name}<br />
                                                        {props.selectedTeacher?.attributes?.designation}<br />
                                                        {props.selectedTeacher?.attributes?.empCode}<br />
                                                    </td>
                                                    <th scope="row">
                                                        Current Salary (per year) :<br />
                                                        Current Salary (per Month) :<br />
                                                    </th>
                                                    <td colSpan="6">
                                                        <Editable
                                                            className={'d-flex'}
                                                            alwaysEditing={false}
                                                            onSubmit={(value) => {
                                                                let res = value.replace(/\D/g, "")
                                                                setEmployeeCurrentSalary(res);
                                                                totalMatch(res)
                                                            }}
                                                            onCancel={() => {
                                                                props.selectedTeacher && setEmployeeCurrentSalary(props.selectedTeacher.attributes.currentSalary);
                                                                totalMatch()
                                                            }}
                                                            editText={(props.selectedTeacher?.attributes?.currentSalary || employeeCurrentSalary) ? " ✎" : "No value ✎"}
                                                            id={null}
                                                            isValueClickable={false}
                                                            mode="inline"
                                                            placement="top"
                                                            initialValue={props.selectedTeacher?.attributes?.currentSalary ?? ""}
                                                            showText
                                                            type="textfield"
                                                        />
                                                        {employeeCurrentSalary ? (employeeCurrentSalary / 12).toFixed(2) :
                                                            props.selectedTeacher?.attributes?.currentSalary ? (props.selectedTeacher.attributes.currentSalary / 12).toFixed(2) : "-"}                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {employeeCurrentSalary ? (<Card>
                    <CardBody>
                        {salaryComponents && salaryComponents.length > 0 && (
                            <Row>
                                <Col>
                                    <Table className="table table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "4%" }}>Sl.no</th>
                                                <th style={{ width: "30%" }}>Category</th>
                                                <th style={{ width: "20%" }}>Type</th>
                                                <th style={{ width: "15%" }}>Amount</th>
                                                <th style={{ width: "15%" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {salaryComponents.map((salry, key) => (
                                                <tr key={salry.categoryId}>
                                                    <td><h5 className="m-2">{(key + 1)}</h5></td>
                                                    <td>
                                                        <FormGroup className="position-relative">
                                                            <Select
                                                                style={{ width: "14rem" }}
                                                                onChange={(selectedOption) => {
                                                                    let tempCategory = salry;
                                                                    tempCategory.amount = (selectedOption.parseObject.attributes.amount);
                                                                    tempCategory.category = selectedOption.item ?? selectedOption;
                                                                    tempCategory.categoryId = selectedOption.id;
                                                                    tempCategory.categoryName = selectedOption.categoryName;
                                                                    tempCategory.categoryType = selectedOption.parseObject.attributes.type;

                                                                    let newList = []
                                                                    for (let i of salaryComponents) {
                                                                        if (i.itemId == salry.itemId) {
                                                                            newList.push(tempCategory)
                                                                        } else {
                                                                            newList.push(i)
                                                                        }
                                                                    }
                                                                    const updatedCategoryList = categoryList.filter(
                                                                        item => item.value !== selectedOption.value
                                                                    );

                                                                    setCategoryList(updatedCategoryList);
                                                                    setSalaryComponents(newList);
                                                                }}
                                                                value={salry.category}
                                                                id="categoryList"
                                                                isDisabled={salry.parseObject}
                                                                options={filteredCategoryList()}
                                                                className="selectItems"
                                                                classNamePrefix="SaleItem"
                                                            />
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <FormGroup>
                                                            <div className="col-md-10">
                                                                <Input
                                                                    name="units"
                                                                    placeholder="Units"
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={salry.categoryType == 1 ? "Earnings" : salry.categoryType == 2 ? "Deductions" : salry.categoryType == 3 ? "Reimbursement" : ""}
                                                                    id="units"
                                                                    onChange={(e) => handleTypeChange(key, e.target.value)}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <FormGroup>
                                                            <div className="col-md-10">
                                                                <Input
                                                                    name="cost"
                                                                    placeholder="0"
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={salry.amount}
                                                                    id="cost"
                                                                    onChange={(e) => {
                                                                        handleAmountChange(key, e.target.value)
                                                                        totalMatch()
                                                                    }}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    </td>
                                                    <td>
                                                        <i
                                                            onClick={() => handleRemove(salry.categoryId)}
                                                            className="bx bx-message-square-x text-danger h3 mt-1"
                                                            style={{ cursor: "pointer" }}
                                                        ></i>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Row >
                                        <Col lg={8}></Col>
                                        <Col className='d-flex'>
                                            <Input
                                                name="Total"
                                                placeholder="0.00"
                                                type="number"
                                                className={`form-control float-end m-2 ${amountTotalMatch ? 'text-success' : 'text-danger'}`}
                                                value={calculateTotalAmount()}
                                                id="Total"
                                                readOnly
                                            />
                                        </Col>
                                        <Col>
                                            <Button color="success"
                                                className="btn waves-effect waves-light float-end m-2"
                                                onClick={handleNewCategory}
                                            >
                                                Add Category<i className="mdi mdi-plus mr-1" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        <hr />
                        <div className="float-end ">
                            <Button
                                color="primary"
                                className="btn waves-effect waves-light float-right m-2"
                                disabled={!amountTotalMatch || saveLoding}
                                onClick={(e) => {
                                    if (props.selectedTeacher?.attributes?.currentSalary) {
                                        if (employeeCurrentSalary == props.selectedTeacher?.attributes?.currentSalary) {
                                            handelSave()
                                        } else {
                                            setShowSalaryDifferencePopup(true)
                                        }
                                    } else {
                                        handelSave()
                                    }
                                }}
                            >
                                {saveLoding ? "Saving..." : "Save"}
                            </Button>
                        </div>
                    </CardBody>
                </Card>) : (<Card>
                    <CardBody>
                        <div className="text-info text-center">
                            <Alert color='info'> Please Add the current Salary of Employee to setup Payslip</Alert>
                        </div>
                    </CardBody>
                </Card>)}
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { selectedTeacher } = state.Teacher;
    const { payrollCategories, masterPayslip, masterPayslipItems } = state.LeaveModule;

    return { selectedSchool, selectedTeacher, payrollCategories, masterPayslip, masterPayslipItems };
};

export default connect(mapStatetoProps, {
    setSelecetdSchool, setSelecetdTeacher, getPayrollCategoriesForOwnerId, getMasterPayslipForTeacherId,
    getMasterPayslipItemsForMasterPayslipId, editTeacher, updateMasterPayslipItemsForMasterPayslipId,
})(withRouter(withNamespaces()(SetupMasterPayroll)));
