import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import {
    setSelecetdSchool, getFranchise, getCorporatePartners
} from '../../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Select from "react-select";
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import Image from "../../../assets/images/growing_chart.svg";

import * as Constants from "../../../Constents";


const RawDataReport = (props) => {

    const schoolId = props.schoolId


    const [reportData, setReportData] = useState(undefined);
    const [invoiceReportData, setInvoiceReportData] = useState(undefined);

    const [monetTransactions, setMonetTransactions] = useState(undefined);
    const [invoiceList, setInvoiceList] = useState(undefined);
    const [kidList, setKidList] = useState(undefined);

    const [invoice, setInvoice] = useState(undefined);
    const [kid, setKid] = useState(undefined);

    const [fromDate, setFromDate] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);

    const [toDateError, setToDateError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);

    const [selectedMonths, setSelectedMonths] = useState([]);
    const [loadingState, setLoadingState] = useState(1);

    const [allSchools, setAllSchools] = useState([]);
    const [selectedSchool, setSelctedSchool] = useState({ value: "allSchools", label: 'All Schools' });

    const [showCorporatePartners, setShowCorporatePartners] = useState(false)

    const [analyticsData, setAnalyticsData] = useState([])
    const [showRoyalty, setShowRoyalty] = useState(false)
    const [reportType, setReportType] = useState("Transaction");
    const [selectedCorporate, setSelectedCorporate] = useState(undefined);
    const [corporatePartnerOptions, setCorporatePartnerOptions] = useState([]);
    const [noReportsFound, setNoReportsFound] = useState(false);
    
    const transactionTitles = ["School Name", "Kid Name", "Fee Type", "Program Type", showCorporatePartners && "Corporate Name", "Invoice No", "Transaction Date", "Title", "Status", "Type", "Amount", "Transaction Id", "Gateway Ref Id",];

    const allInvoiceTitles = ["School Name", "Kid Name", "Invoice Number", "Date", "Title", "Amount", "Paid Amount", "Balance", "Status"];
    const [invoiceTitles,setInvoiceTitles] = useState(allInvoiceTitles);

    const [reportTypeList,setReportTypeList] = useState(["Transaction", "Invoice"]);

    const transactionData = ["School Name", "Collected Amount", showRoyalty && "School Amount", showRoyalty && "Royalty Amount", "Cash", "Online", "Cheque", "AirPay", "RazorPay", "OnePay","GrayQuest"];

    useEffect(() => {
        setFromDate(props.fromDate)
    }, [props.fromDate]);

    useEffect(() => {
        setToDate(props.toDate);
    }, [props.toDate]);

    useEffect(() => {
        if (props.onClickApply) {
            handelDateChange();
        }
    }, [props.onClickApply]);

    useEffect(() => {
        if (props.schools) {
            let list = [{ value: "allSchools", label: 'All Schools' }];
            for (const school of props.schools) {
                list.push({ value: school.id, label: school.attributes.Name })
            }
            setAllSchools(list);
        }
    }, [props.schools]);

    useEffect(() => {

        if (props.franchise && props.franchise.attributes.features) {

            if (props.franchise.attributes.features.length > Constants.FRANCHISE_CORPORATE_PARTNERS_INDEX &&
                props.franchise.attributes.features[Constants.FRANCHISE_CORPORATE_PARTNERS_INDEX] > 0) {
                setShowCorporatePartners(true)
                setReportTypeList(["Transaction", "Invoice","Corporate"])
                setInvoiceTitles([... allInvoiceTitles, "Corporate Name"])
            }
            else if (showCorporatePartners) {
                setShowCorporatePartners(false)
            }
            if (props.franchise.attributes.features.length > Constants.FRANCHISE_ROYALTY_SETTING_INDEX &&
                props.franchise.attributes.features[Constants.FRANCHISE_ROYALTY_SETTING_INDEX] > 0) {
                setShowRoyalty(true)
            }
        }
    }, [props.franchise]);

    useEffect(() => {
        props.getCorporatePartners(props.franchise.id, "franchise")
    }, [props.franchise]);

    useEffect(() => {
        if (props.corporatePartners && props.corporatePartners.length > 0) {
          const options = props.corporatePartners.map(partner => ({
            value: partner.id,
            label: partner.attributes.name,
          }));
          const allOption = { value: "all", label: "All" };
          setCorporatePartnerOptions([allOption,...options]);
        }
      }, [props.corporatePartners]);

    const handelDateChange = () => {
        setLoadingState(2);
        if (reportType === "Transaction") {
            getReportFromParse();
        } else if (reportType === "Invoice" || reportType === "Corporate") {
            getReportForInvoice(); 
        }
    }

    const getKidName = (kidId) => {
        if (props.kids && props.kids.length > 0) {
            let temp = props.kids.filter(
                (student) => student.id === kidId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const getCorporateName = (corporateId) => {
        if (props.corporatePartners && props.corporatePartners.length > 0) {
            let temp = props.corporatePartners.filter(
                (corporate) => corporate.id === corporateId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.name ?? "-")
            }
        }
    }

    const getPaidStatus = (status) => {
        if (status === 1) {
            return "Outstanding";
        } else if (status === 2) {
            return "Verified";
        } else {
            return "Success";
        }
    };

    const getInvoicePaidStatus = (status) => {
        if (status === 1) {
            return "Paid";
        } else if (status === 3) {
            return "Partially Paid";
        } else {
            return "Pending";
        }
    };

    const getDayBetweenDates = (d1, d2) => {
        var diff = d2 - d1;
        return Math.floor(diff / 86400000);

    }


    const getReportFromParse = () => {

        if (!fromDate || !toDate) {
            return;
        }


        var startDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
        var endDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59, 999);

        let loop = true
        let newDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
        var newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0, 23, 59);
        let dateList = [];
        while (loop) {
            if (newDate < newEndDate) {

                newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
                let dateString = Moment(newDate).format('DD-MM-YYYY')
                dateList.push({ date: newDate, dateString: dateString })
            } else {
                loop = false;
            }
        }
        setSelectedMonths(dateList);

        let days = getDayBetweenDates(startDate, endDate);

        let query = new Parse.Query("MoneyTransactions");

        query.greaterThanOrEqualTo('createdAt', startDate);
        query.lessThanOrEqualTo('createdAt', endDate);
        query.notEqualTo('deleted', true);

        query.limit(10000)

        let schoolIds = [];
        if (props.isFranchise) {

            if (selectedSchool && selectedSchool.value != 'allSchools') {
                schoolIds.push(selectedSchool.value)
            } else {
                for (const school of props.schools) {
                    schoolIds.push(school.id)
                }
            }

            query.containedIn('schoolId', schoolIds)
        } else {
            query.equalTo('schoolId', schoolId)
        }
        query.ascending("createdAt");
        query.find().then((mts) => {
            //console.log(mts)
            if (!mts || mts.length === 0) {
                setLoadingState(5)
            } else {
                setMonetTransactions(mts);

                let invoiceIds = [];
                for (const mt of mts) {
                    if (!invoiceIds.includes(mt.attributes.invoiceId)) {
                        invoiceIds.push(mt.attributes.invoiceId);
                    }
                }

                let queryI = new Parse.Query("Invoice");
                queryI.containedIn("objectId", invoiceIds);
                queryI.limit(10000);

                queryI.find().then((invoiceList) => {
                    //console.log(invoiceList);
                    setInvoiceList(invoiceList);

                    let kidIds = [];
                    for (const kid of invoiceList) {
                        if (!kidIds.includes(kid.attributes.recipientId)) {
                            kidIds.push(kid.attributes.recipientId);
                        }
                    }

                    let queryKid = new Parse.Query("Kid");
                    queryKid.containedIn("objectId", kidIds);
                    queryKid.limit(10000);

                    queryKid.find().then((kidList) => {
                        //console.log(kidList);
                        setKidList(kidList);

                        prepareDate(mts, invoiceList, kidList);
                    });
                });
            }
        });
    }

    const prepareDate = (mts, invoiceList, kidList) => {
        let dataList = [];


        for (const school of props.schools) {

            let schoolsMts = mts.filter(
                (mt) => mt.attributes.schoolId === school.id
            );

            for (const mt of schoolsMts) {

                let invoice = invoiceList.filter(
                    (i) => i.id === mt.attributes.invoiceId);

                let kid = kidList.filter(
                    (i) => i.id === invoice[0].attributes.recipientId);

                dataList.push({
                    id: mt.id,
                    moneyTransaction: mt,
                    invoice: invoice[0],
                    kid: kid[0],

                    transactionData: [
                        school.attributes.Name,
                        kid[0].attributes.Name,
                        kid[0].attributes.feeType,
                        kid[0].attributes.programType,
                        showCorporatePartners && getCorporateName(kid[0].attributes.corporateId),
                        invoice[0].attributes.invoiceNumber,

                        Moment(mt.attributes.createdAt).format('DD-MM-YYYY hh:mm a'),
                        invoice[0].attributes.title,
                        getPaidStatus(mt.attributes.status),
                        mt.attributes.transactionType,
                        mt.attributes.amount,
                        mt.attributes.transactionId,
                        mt.attributes.transactionType == (("AirPay")||("OnePay")||("GrayQuest")) ? mt.id : mt.attributes.transactionType == "RazorPay" ? mt.attributes.razorpayId : "",

                    ]
                });
            }
        }
        console.log(dataList)
        setReportData(dataList);
        setLoadingState(3)
        getTransactionAnalytics(dataList)
    }

    const getTransactionAnalytics = (transactionData) => {

        let transcationDataObject = [];

        for (const school of props.schools) {

            let tAmount = 0;
            let successAmount = 0;
            let outStandingAmount = 0;
            let cAmount = 0;
            let oAmount = 0;
            let chAmount = 0;
            let apAmount = 0;
            let gpAmount = 0;
            let ccAmount = 0;
            let puAmount = 0;
            let rpAmount = 0;
            let opAmount = 0;
            let gqAmount = 0;
            let cancelAmount = 0;
            let schoolAmount = 0;
            let royaltyAmount = 0;

            for (const td of transactionData) {

                const moneyTransaction = td.moneyTransaction;;
                const transactionData = td.transactionData;


                if (moneyTransaction.attributes.schoolId == school.id) {

                    let status = undefined;

                    if (moneyTransaction.attributes.deleted) {
                        console.log(moneyTransaction.attributes.deleted)
                        status = "Cancelled";
                    }
                    let type = moneyTransaction.attributes.transactionType;
                    let transactionSchoolAmount = moneyTransaction.attributes.schoolAmount ?? 0;
                    let transactionRoyaltyAmount = moneyTransaction.attributes.royaltyAmount ?? 0
                    let transactionAmount = moneyTransaction.attributes.amount ?? 0;


                    if (status != "Cancelled") {

                        tAmount += transactionAmount;
                        schoolAmount += transactionSchoolAmount;
                        royaltyAmount += transactionRoyaltyAmount;


                        if (moneyTransaction.attributes.status == 1) {
                            outStandingAmount += transactionAmount;
                        } else {
                            successAmount += transactionAmount;
                        }

                        if (type == "AirPay") {
                            apAmount += transactionAmount;
                        } else if (type == "RazorPay") {
                            rpAmount += transactionAmount;
                        } else if (type == "OnePay") {
                            opAmount += transactionAmount;
                        } else if (type == "PayU") {
                            puAmount += transactionAmount;
                        } else if (type == "CCAvenue") {
                            ccAmount += transactionAmount;
                        } else if (type == "GooglePay") {
                            gpAmount += transactionAmount;
                        } else if (type == "Online") {
                            oAmount += transactionAmount;
                        } else if (type == "Cheque") {
                            chAmount += transactionAmount;
                        }else if (type == "GrayQuest") {
                            gqAmount += transactionAmount;
                        } else {
                            cAmount += transactionAmount;
                        }
                    } else {
                        cancelAmount += transactionAmount;
                    }

                }

            }
            let schoolData = {
                school: school,
                schoolName: school.attributes.Name,
                tAmount: tAmount,
                outStandingAmount: outStandingAmount,
                successAmount: successAmount,
                schoolAmount: schoolAmount,
                royaltyAmount: royaltyAmount,
                cAmount: cAmount,
                oAmount: oAmount,
                chAmount: chAmount,
                apAmount: apAmount,
                gpAmount: gpAmount,
                ccAmount: ccAmount,
                puAmount: puAmount,
                rpAmount: rpAmount,
                opAmount: opAmount,
                gqAmount:gqAmount,
                cancelAmount: cancelAmount,
            }
            transcationDataObject.push(schoolData)
        }
        setAnalyticsData(transcationDataObject);
        console.log(transcationDataObject)
    };


    const getReportForInvoice = () => {

        if (!fromDate || !toDate) {
            return;
        }


        var startDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
        var endDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59, 999);

        let loop = true
        let newDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
        var newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0, 23, 59);
        let dateList = [];
        while (loop) {
            if (newDate < newEndDate) {

                newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
                let dateString = Moment(newDate).format('DD-MM-YYYY')
                dateList.push({ date: newDate, dateString: dateString })
            } else {
                loop = false;
            }
        }
        setSelectedMonths(dateList);

        let days = getDayBetweenDates(startDate, endDate,);

        let query = new Parse.Query("Invoice");
        query.greaterThanOrEqualTo('createdAt', startDate);
        query.lessThanOrEqualTo('createdAt', endDate);
        query.notEqualTo('deleted', true);

        query.limit(10000)
        let schoolIds = [];
        if (props.isFranchise) {

            if (selectedSchool && selectedSchool.value != 'allSchools') {
                schoolIds.push(selectedSchool.value)
            } else {
                for (const school of props.schools) {
                    schoolIds.push(school.id)
                }
            }

            query.containedIn('schoolId', schoolIds)
        } else {
            query.equalTo('schoolId', schoolId)
        }
        if (reportType === "Corporate") {
            if (selectedCorporate && selectedCorporate.value === "all") {
                query.exists('corporateId');
            } else if (selectedCorporate) {
                query.equalTo('corporateId', selectedCorporate.value);
            }
        }
        query.ascending("createdAt");
        query.find().then((invoiceList) => {
            if (!invoiceList || invoiceList.length === 0) {
                setLoadingState(5)
            } else {
                setInvoice(invoiceList);

                let kidIds = [];
                for (const kid of invoiceList) {
                    if (!kidIds.includes(kid.attributes.recipientId)) {
                        kidIds.push(kid.attributes.recipientId);
                    }
                }

                let queryKid = new Parse.Query("Kid");
                queryKid.containedIn("objectId", kidIds);
                queryKid.limit(10000);

                queryKid.find().then((kids) => {
                    console.log(kids);
                    setKid(kids);

                    prepareInvoiceData(invoiceList, kids);
                });
            }
        });
    }


    const prepareInvoiceData = (invoiceList, kids) => {
        let dataList = [];

        for (const school of props.schools) {

            let schoolsInvoice = invoiceList.filter(
                (invoice) => invoice.attributes.schoolId === school.id
            );

            for (const invoice of schoolsInvoice) {

                let kid = kids.filter((i) => i.id === invoice.attributes.recipientId);


                let invoiceData = [
                    school.attributes.Name,
                    kid[0].attributes.Name,
                    invoice.attributes.invoiceNumber,

                    Moment(invoice.attributes.createdAt).format('DD-MM-YYYY hh:mm a'),
                    invoice.attributes.title,
                    (invoice.attributes.amount ? invoice.attributes.amount : 0),
                    (invoice.attributes.paidAmount ? invoice.attributes.paidAmount : 0),
                    (invoice.attributes.paidAmount ? invoice.attributes.amount - invoice.attributes.paidAmount : invoice.attributes.amount),
                    getInvoicePaidStatus(invoice.attributes.paidStatus),
                ]

                if (reportType === "Corporate") {
                    invoiceData.push(getCorporateName(invoice.attributes.corporateId))
                }

                dataList.push({
                    invoice: invoice,
                    kid: kid[0],
                    invoiceData: invoiceData
                });
            }
        }
        setInvoiceReportData(dataList);
        setLoadingState(3)
        if (dataList.length === 0) {
            setNoReportsFound(true); 
        } else {
            setNoReportsFound(false);
        }
    }

    const getStringFromList = (list) => {
        let str = "";
        if (list) {
            for (const s of list) {
                str = s + ",";
            }
        }
        return str;

    }



    const onClickDownload = () => {

        let sDate = Moment(fromDate).format('DD-MM-YYYY');
        let eDate = Moment(toDate).format('DD-MM-YYYY');

        let finalData = [];
        let fileName = `Transcation_report_${sDate}_${eDate}.xlsx`;
        let sheetName = sDate + "-" + eDate;

        finalData.push([`Transcation report for ${selectedSchool.label}`]);
        finalData.push([`Selected Dates : ${sDate} to ${eDate}`]);

        let title1 = []
        finalData.push([]);

        title1.push(["School Name"])
        title1.push(["Kid Name"])
        title1.push(["Fee Type"])
        title1.push(["Program Type"])
        { showCorporatePartners && title1.push(["Corporate Name"]) }
        title1.push(["Invoice No"])
        title1.push(["Transaction Date"])
        title1.push(["Title"])
        title1.push(["Status"])
        title1.push(["Type"])
        title1.push(["Amount"])
        title1.push(["Transaction Id"])
        title1.push(["Gateway Ref Id"])

        finalData.push(title1)

        for (const rd of reportData) {
            let row = [];
            let temp = rd.transactionData;
            row.push(temp[0]);
            row.push(temp[1]);
            row.push(temp[2]);
            row.push(temp[3]);
            { showCorporatePartners && row.push(temp[4]); }
            row.push(temp[5]);
            row.push(temp[6]);
            row.push(temp[7]);
            row.push(temp[8]);
            row.push(temp[9]);
            row.push(temp[10]);
            row.push(temp[11]);
            row.push(temp[12]);
            finalData.push(row);
        }
        finalData.push([])
        finalData.push([])

        let title = [];
        let finalData2 = [];
        finalData2.push(["Transcation Summary"]);
        finalData2.push([`Transcation Summary for ${selectedSchool.label}`]);
        finalData2.push([`Selected Dates : ${sDate} to ${eDate}`]);
        finalData2.push([]);

        title.push(["School Name"]);
        title.push(["Collected Amount"]);
        if (showRoyalty) {
            title.push(["School"]);
            title.push(["Royalty"]);
        }
        title.push(["Cash"]);
        title.push(["Online"]);
        title.push(["Cheque"]);
        title.push(["AirPay"]);
        title.push(["RazorPay"]);
        title.push(["OnePay"]);
        finalData2.push(title)


        for (const analytic of analyticsData) {
            let row = [];
            row.push(analytic.schoolName);
            row.push(analytic.tAmount.toFixed(2));
            if (showRoyalty) {
                row.push(analytic.schoolAmount.toFixed(2));
                row.push(analytic.royaltyAmount.toFixed(2));
            }
            row.push(analytic.cAmount);
            row.push(analytic.oAmount);
            row.push(analytic.chAmount);
            row.push(analytic.apAmount);
            row.push(analytic.rpAmount);
            row.push(analytic.opAmount);
            finalData2.push(row);
        }
        finalData2.push([]);
        finalData2.push([]);

        if (props.isFranchise) {
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);
            let ws2 = XLSX.utils.aoa_to_sheet(finalData2);

            XLSX.utils.book_append_sheet(wb, ws, "Transaction Report");
            XLSX.utils.book_append_sheet(wb, ws2, "Transaction summary");

            XLSX.writeFile(wb, fileName);
        } else {
            let wb = XLSX.utils.book_new();
            let schoolData = [];
            schoolData = finalData;
            schoolData = schoolData.concat(finalData2);

            let ws = XLSX.utils.aoa_to_sheet(schoolData);
            XLSX.utils.book_append_sheet(wb, ws, "all");
            XLSX.writeFile(wb, fileName);
        }
    };


    const invoiceReportDownload = () => {
        let sDate = Moment(fromDate).format('DD-MM-YYYY')
        let eDate = Moment(toDate).format('DD-MM-YYYY')

        let finalData = [];
        let fileName = `Invoice_report_${sDate}_${eDate}.xlsx`;
        let sheetName = sDate + "-" + eDate;
        
        if (reportType === "Invoice") {
            fileName = `Invoice_report_${sDate}_${eDate}.xlsx`;
            sheetName = `${sDate}-${eDate}`;
            finalData.push([`Invoice report for ${selectedSchool.label}`]);
        } else if (reportType === "Corporate") {
            fileName = `Corporate Report ${sDate}_${eDate}.xlsx`;
            sheetName = "Corporate Report";
            finalData.push([`Corporate report for ${selectedSchool.label}`]);
        }
        finalData.push([`Selected Dates : ${sDate} to ${eDate}`,]);

        finalData.push([]);
        finalData.push(invoiceTitles);

        for (const rd of invoiceReportData) {
            let temp = rd.invoiceData;
            finalData.push(temp)
        }
        if (props.isFranchise) {
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);
            ws['!cols'] = Array(invoiceTitles.length).fill({ wpx: 100 });
            XLSX.utils.book_append_sheet(wb, ws, "all");

            XLSX.writeFile(wb, fileName);
        } else {
            let wb = XLSX.utils.book_new();
            let schoolData = [];
            schoolData = finalData;
            let ws = XLSX.utils.aoa_to_sheet(schoolData);
            ws['!cols'] = Array(invoiceTitles.length).fill({ wpx: 100 });

            XLSX.utils.book_append_sheet(wb, ws, "all");
            XLSX.writeFile(wb, fileName);
        }
    }

    const getIndexList = () => {
        let list = [];
        for (const i in reportData) {
            list.push(i);
        }
        return list;
    }

    const getIndexForInvoiceList = () => {
        let list = [];
        for (const i in invoiceReportData) {
            list.push(i);
        }
        return list;
    }

    function getDateTimeStringForDate(date) {
        return Moment(date).format('DD-MM-YYYY');
    }

    return (
        <React.Fragment>
            {props.showTitle && <CardTitle>Enquiry Analytics Report</CardTitle>}
            {loadingState > 0 && props.showDatePickers &&
                <Row className="bg-light">
                    <Col lg="3">
                        <FormGroup>
                            <Label>{props.t("School")} :</Label>

                            <Select
                                value={selectedSchool}
                                onChange={(v) => {
                                    setSelctedSchool(v)
                                }}
                                options={allSchools}
                                className="selectPaymentType"
                                classNamePrefix="selectPayType"

                            />
                        </FormGroup>
                    </Col>
                    <Col lg="3">
                        <FormGroup>
                            <Label>Report Type :</Label>
                            <select
                                className="form-control "
                                value={reportType}
                                onChange={(e) => {
                                    setReportType(e.target.value)
                                }}
                            >
                                {reportTypeList.map((val) => (
                                    <option value={val} key={val}>
                                        {val}
                                    </option>
                                ))}
                            </select>
                        </FormGroup>
                    </Col>
                    {(reportType === "Corporate") && (
                        <Col md="3" xs="12" xl="5" lg="3" style={{marginLeft: '10px'}}>
                            <FormGroup>
                                <Label>{props.t("Corporate Partner")}:</Label>
                                <select
                                    className="form-control"
                                    value={selectedCorporate ? selectedCorporate.value : ""}
                                    onChange={event => {
                                        const selectedValue = event.target.value;
                                        const selectedOption = corporatePartnerOptions.find(option => option.value === selectedValue);
                                        setSelectedCorporate(selectedOption);
                                    }}
                                >
                                    {corporatePartnerOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>

                            </FormGroup>
                        </Col>
                    )}
                    <Col lg={2}>
                        <FormGroup>
                            <Label >
                                {props.t("From")} :
                            </Label>
                            <DatePicker
                                selected={fromDate}
                                onChange={(date) => {
                                    setFromDateError(false)
                                    setFromDate(date)
                                }}
                                className="form-control"
                                placeholderText={props.t("Select Date")}
                                startDate={fromDate}
                                endDate={toDate}
                                maxDate={toDate}
                                dateFormat="dd/MM/yyyy"
                                showFullMonthYearPicker
                                required
                                errorMessage="Please select"

                            />
                        </FormGroup>
                        {fromDateError && <p className="text-danger ml-2">Please select</p>}
                    </Col>
                    <Col lg={2}>
                        <FormGroup>
                            <Label>
                                {props.t("To")} :
                            </Label>
                            <DatePicker
                                selected={toDate}
                                onChange={(date) => {
                                    setToDateError(false);
                                    setToDate(date)
                                }}
                                className="form-control"
                                placeholderText={props.t("Select Date")}
                                startDate={fromDate}
                                endDate={toDate}
                                minDate={fromDate}
                                dateFormat="dd/MM/yyyy"
                                showFullMonthYearPicker
                                required
                                errorMessage="Please select"

                            />
                        </FormGroup>
                        {toDateError && <p className="text-danger ml-2">Please select</p>}
                    </Col>
                    <Col lg={2} >
                        <div className="mt-2 p-3">
                            <button
                                disabled={loadingState === 2}
                                onClick={(e, v) => {
                                    let next = true;
                                    if (!toDate) {
                                        setToDateError(true);
                                        next = false;
                                    }
                                    if (!fromDate) {
                                        setFromDateError(true);
                                        next = false;
                                    }
                                    if (next) {
                                        handelDateChange();
                                    }

                                }}
                                className="w-md  btn btn-primary"
                            >
                                {props.t("Apply")}
                            </button>

                        </div>
                    </Col>
                </Row>
            }
            {(loadingState === 0 || loadingState === 2) &&
                <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState === 0 ? "Setting up..." : "Preparing report, it may take more time than anticipated"}</Label>
                    </div>
                </Col>
            }


            <Element name={"scrollPossition"} >
                <div></div>
            </Element>


            {loadingState === 5 && <Card>
                <CardBody className="text-center" >
                    <Row className="justify-content-center">
                        <Col sm={4}>
                            <div className="maintenance-img mt-8">
                                <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>
                    </Row>
                    <h3 className="mt-5">{'No data avilable between ' + getDateTimeStringForDate(fromDate) + " and " + getDateTimeStringForDate(toDate)}</h3>
                    <p>{'Plese select other dates '}.</p>


                </CardBody>
            </Card>}

            {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && 
                <Card>
                    <CardBody>
                    {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && reportType === "Transaction" &&<CardHeader>  <CardTitle> Transaction Summary </CardTitle> </CardHeader>}
                    {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && reportType === "Invoice" &&<CardHeader>  <CardTitle> Invoice Report </CardTitle> </CardHeader>}
                    {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && reportType === "Corporate" &&<CardHeader>  <CardTitle> Corporate Report </CardTitle> </CardHeader>}
                        <Row className='ml-1'>
                            <Col lg={9}>
                                {reportType === "Transaction" &&<p>{`Transcation Summary for ${selectedSchool.label}`}</p>}
                                {reportType === "Invoice" &&<p>{`Invoices for ${selectedSchool.label}`}</p>}
                                {reportType === "Corporate" &&<p>{`Invoices for ${selectedSchool.label}`}</p>}
                                <p>{`Selected Dates : ${Moment(fromDate).format('DD-MM-YYYY')} to ${Moment(toDate).format('DD-MM-YYYY')}`}</p>
                            </Col>
                            <Col lg={3}>
                                <div className="mt-2 mb-2 p-1 text-center d-flex justify-content-end ">
                                    <button
                                        onClick={() => {
                                            if (selectedMonths && selectedMonths.length > 0 && reportType === "Transaction") {
                                                onClickDownload();
                                            } else {
                                                invoiceReportDownload();
                                            }

                                        }}
                                        className="w-md  btn btn-primary"
                                    >
                                        <i className='bx bx-download font-size-16 align-middle m-1'></i>
                                        {props.t("Download Report")}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>}

            {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && reportType === "Transaction" &&
                <Col xs="10">
                    <div className="table-rep-plugin py-2">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <Table id="tech-companies-1" className="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>School Name</th>
                                        <th>Collected Amount</th>
                                        {showRoyalty && <th>School Amount</th>}
                                        {showRoyalty && <th>Royalty Amount</th>}
                                        <th>Cash</th>
                                        <th>Online</th>
                                        <th>Cheque</th>
                                        <th>AirPay</th>
                                        <th>RazorPay</th>
                                        <th>OnePay</th>
                                        <th>GrayQuest</th>
                                        {/* <th>Cancelled Amount</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {analyticsData && analyticsData.map((item, key) => (
                                        <tr key={key}>
                                            <th>{parseInt(key) + 1}</th>
                                            <td>{item.schoolName}</td>
                                            <td>{item.tAmount}</td>
                                            {showRoyalty && <td>{item.schoolAmount.toFixed(2)}</td>}
                                            {showRoyalty && <td>{item.royaltyAmount.toFixed(2)}</td>}
                                            <td>{item.cAmount}</td>
                                            <td>{item.oAmount}</td>
                                            <td>{item.chAmount}</td>
                                            <td>{item.apAmount}</td>
                                            <td>{item.rpAmount}</td>
                                            <td>{item.opAmount}</td>
                                            <td>{item.gqAmount}</td>
                                            {/* <td>{item.cancelAmount}</td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                </Col>}


             {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && reportType === "Transaction" && <CardHeader>  <CardTitle> Transaction Report </CardTitle> </CardHeader>}
            {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && reportType === "Transaction" &&<Col xs="12">
                <div className="table-rep-plugin py-2">
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table id="tech-companies-1" className="table table-striped table-bordered table-sm">
                            <Thead>
                                <Tr>


                                    <Th>#</Th>
                                    {transactionTitles.map((t, k) => (
                                        <Th key={k} data-priority={k}>{t}</Th>
                                    ))}

                                </Tr>
                            </Thead>
                            <Tbody>

                                {
                                    reportData && getIndexList().map((i, key) => (
                                        <Tr key={key}>
                                            <Th >{parseInt(i) + 1}</Th>
                                            {reportData[i].transactionData.map((e, key2) => (
                                                <Td key={key2}>{e}</Td>
                                            ))}

                                        </Tr>
                                    ))
                                }

                            </Tbody>
                        </Table>
                    </div>
                </div>
            </Col>}


            {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && (reportType === "Invoice" || reportType === "Corporate")&& <Col xs="12">
                <div className="table-rep-plugin">
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table id="tech-companies-1" className="table table-striped table-bordered table-sm">
                            <Thead>
                                <Tr>


                                    <Th>#</Th>
                                    {invoiceTitles.map((t, k) => (
                                        <Th key={k} data-priority={k}>{t}</Th>
                                    ))}

                                </Tr>
                            </Thead>
                            <Tbody>

                                {
                                    invoiceReportData && getIndexForInvoiceList().map((i, key) => (
                                        <Tr key={key}>
                                            <Th >{parseInt(i) + 1}</Th>
                                            {invoiceReportData[i].invoiceData.map((e, key2) => (
                                                <Td key={key2}>{e}</Td>
                                            ))}

                                        </Tr>
                                    ))
                                }

                            </Tbody>
                        </Table>
                    </div>
                </div>

            </Col>
            }


            <div style={{ height: "400px" }}></div>
        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { franchise } = state.Franchise;
    const { corporatePartners } = state.CorporatePartners;
    return { schools, selectedSchool, franchise, corporatePartners };
}
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool, getFranchise, getCorporatePartners
        })(RawDataReport)
    )
);
