export const USER_PROFILE_NAME = "USER_PROFILE_NAME";
export const USER_PROFILE_EMAIL = "USER_PROFILE_EMAIL";
export const USER_PROFILE_PHOTO = "USER_PROFILE_PHOTO";
export const SCREEN_LOCK = "SCREEN_LOCK";

export const DISPLAY_TYPE_LIST = "DISPLAY_TYPE_LIST";
export const DISPLAY_TYPE_GRID = "DISPLAY_TYPE_GRID";

//Expenses
export const EXPENSE_LIST_UPDATED_TIME = "EXPENSE_LIST_UPDATED_TIME";
export const EXPENSE_CATEGORY_LIST_UPDATED_TIME = "EXPENSE_CATEGORY_LIST_UPDATED_TIME"
export const EXPENSE_SUPPLIER_LIST_UPDATED_TIME = "EXPENSE_SUPPLIER_LIST_UPDATED_TIME"
//Teacher
export const TEACHER_DISPLAY_TYPE = "TEACHER_DISPLAY_TYPE";
export const STUDENT_DISPLAY_TYPE = "STUDENT_DISPLAY_TYPE";

//Programs Display List
export const PROGRAM_DISPLAY_TYPE_LIST = "PROGRAM_DISPLAY_TYPE_LIST";
export const PROGRAM_DISPLAY_TYPE_GRID = "PROGRAM_DISPLAY_TYPE_GRID";
export const PROGRAM_DISPLAY_TYPE = "PROGRAM_DISPLAY_TYPE";


//UPDATED_TIME
export const REMINDER_UPDATED_TIME = "REMINDER_UPDATED_TIME";
export const TEACHER_UPDATED_TIME = "TEACHER_UPDATED_TIME";
export const CLASSROOM_UPDATED_TIME = "CLASSROOM_UPDATED_TIME";
export const ENQUIRY_UPDATED_TIME = "ENQUIRY_UPDATED_TIME";
export const SCHOOL_RATING_UPDATED_TIME = "SCHOOL_RATING_UPDATED_TIME";
export const PARENTS_UPDATED_TIME = "PARENTS_UPDATED_TIME";
export const KIDS_UPDATED_TIME = "KIDS_UPDATED_TIME";
export const ENQUIRYNOTE_UPDATED_TIME = "ENQUIRYNOTE_UPDATED_TIME";
export const ADMISSIONS_UPDATED_TIME = "ADMISSIONS_UPDATED_TIME";
export const ENQUIRY_QUESTIONS_UPDATED_TIME = "ENQUIRY_QUESTIONS_UPDATED_TIME";
export const GROUP_UPDATED_TIME = "GROUP_UPDATED_TIME";
export const ARCHIVE_GROUP_UPDATED_TIME = "ARCHIVE_GROUP_UPDATED_TIME";
export const KIDS_UPDATED_TIME_FOR_GROUP = "KIDS_UPDATED_TIME_FOR_GROUP";
export const SELECTED_SCHOOL_ID = "SELECTED_SCHOOL_ID";
export const PROGRAM_UPDATED_TIME = "PROGRAM_UPDATED_TIME";
export const MESSAGE_UPDATED_TIME = "MESSAGE_UPDATED_TIME";
export const ADMISSION_TITLE_UPDATED_TIME = "ADMISSION_TITLE_UPDATED_TIME";
export const ADMISSION_QUESTIONS_UPDATED_TIME = "ADMISSION_QUESTIONS_UPDATED_TIME";
export const MONEY_TRANSACTION_UPDATED_TIME = "MONEY_TRANSACTION_UPDATED_TIME";
export const CAMERA_UPDATED_TIME = "CAMERA_UPDATED_TIME";
export const CAMERA_LIVE_STREAM_UPDATED_TIME = "CAMERA_LIVE_STREAM_UPDATED_TIME";
export const FEE_TEMPLATE_UPDATED_TIME = "FEE_TEMPLATE_UPDATED_TIME";
export const KIDS_UPDATED_TIME_FOR_SCHOOL_ID = "KIDS_UPDATED_TIME_FOR_SCHOOL_ID";
export const LEARNING_CATEGORY_UPDATED_TIME_FOR_SCHOOL_ID = "LEARNING_CATEGORY_UPDATED_TIME_FOR_SCHOOL_ID";
export const LEARNING_CATEGORY_UPDATED_TIME_FOR_CURRICULUM_ID = "LEARNING_CATEGORY_UPDATED_TIME_FOR_CURRICULUM_ID";
export const LEARNING_ACTIVITY_UPDATED_TIME_FOR_CURRICULUM_ID = "LEARNING_ACTIVITY_UPDATED_TIME_FOR_CURRICULUM_ID";
export const LEARNING_FILTER_UPDATED_TIME_FOR_CURRICULUM_ID = "LEARNING_FILTER_UPDATED_TIME_FOR_CURRICULUM_ID";
export const CURRICULUM_UPDATED_TIME_FOR_OWNER_ID = "CURRICULUM_UPDATED_TIME_FOR_OWNER_ID";
export const ACADEMIC_CYCLE_UPDATED_TIME = "ACADEMIC_CYCLE_UPDATED_TIME";
export const LEARNING_SUBCATEGORY_UPDATED_TIME_FOR_CATEGORY_ID = "LEARNING_SUBCATEGORY_UPDATED_TIME_FOR_CATEGORY_ID";
export const DEFAULT_ADMISSION_QUESTION_UPDATED_TIME = "DEFAULT_ADMISSION_QUESTION_UPDATED_TIME";
export const DELETED_ENQUIRY_UPDATED_TIME = "DELETED_ENQUIRY_UPDATED_TIME";
export const DELETED_KID_UPDATED_TIME = "DELETED_KID_UPDATED_TIME";
export const COURSE_UPDATED_TIME_FOR_OWNER_ID = "COURSE_UPDATED_TIME_FOR_OWNER_ID";
export const COURSE_REQUESTS_UPDATED_TIME_FOR_OWNER_ID = "COURSE_REQUESTS_UPDATED_TIME_FOR_OWNER_ID";
export const COURSE_UPDATED_TIME_FOR_SCHOOL_ID = "COURSE_UPDATED_TIME_FOR_SCHOOL_ID"
export const COURSE_BATCH_UPDATED_TIME_FOR_SCHOOL_ID = "COURSE_BATCH_UPDATED_TIME_FOR_SCHOOL_ID"
export const LEARNING_CATEGORY_UPDATED_TIME_FOR_COURSE_ID = "LEARNING_CATEGORY_UPDATED_TIME_FOR_COURSE_ID";
export const LEARNING_ACTIVITY_UPDATED_TIME_FOR_COURSE_ID = "LEARNING_ACTIVITY_UPDATED_TIME_FOR_COURSE_ID";
export const FILE_UPDATED_TIME = "FILE_UPDATED_TIME";

export const INVENTORY_VENDOR_UPDATED_TIME = 'INVENTORY_VENDOR_UPDATED_TIME';
export const INVENTORY_ITEM_UPDATED_TIME_FOR_OWNER_ID = "INVENTORY_ITEM_UPDATED_TIME_FOR_OWNER_ID";
export const INVENTORY_PURCHASE_ORDER_UPDATED_TIME = 'INVENTORY_PURCHASE_ORDER_UPDATED_TIME';
export const INVENTORY_TAGS_UPDATED_TIME = "INVENTORY_TAGS_UPDATED_TIME";
export const INVENTORY_SALE_ORDER_UPDATED_TIME = 'INVENTORY_SALE_ORDER_UPDATED_TIME';
export const INVENTORY_SALE_ORDER_ITEMS_UPDATED_TIME = "INVENTORY_SALE_ORDER_ITEMS_UPDATED_TIME";
export const INVENTORY_PURCHASE_ORDER_ITEMS_UPDATED_TIME = "INVENTORY_PURCHASE_ORDER_ITEMS_UPDATED_TIME";
export const TICKET_UPDATED_TIME_FOR_RECIPIENT_ID = 'TICKET_UPDATED_TIME_FOR_RECIPIENT_ID';
export const TICKET_UPDATED_TIME_FOR_ASSIGNED_FOR = 'TICKET_UPDATED_TIME_FOR_ASSIGNED_FOR';
export const LEAVE_UPDATED_TIME_FOR_USER_ID = 'LEAVE_UPDATED_TIME_FOR_USER_ID';
export const INVENTORY_KIT_UPDATED_TIME = 'INVENTORY_KIT_UPDATED_TIME';
export const INVENTORY_KIT_ITEMS_UPDATED_TIME = "INVENTORY_KIT_ITEMS_UPDATED_TIME";
export const INVENTORY_CART_UPDATED_TIME = 'INVENTORY_CART_UPDATED_TIME';
export const ENQUIRY_TEMPLATE_UPDATED_TIME = "ENQUIRY_TEMPLATE_UPDATED_TIME";
export const FRANCHISE_ENQUIRY_UPDATED_TIME = "FRANCHISE_ENQUIRY_UPDATED_TIME";
export const INVENTORY_CATEGORIES_UPDATED_TIME = 'INVENTORY_CATEGORIES_UPDATED_TIME';

//Teacher Roles School level

export const STAFF_ROLE_TEACHER = 1;
export const STAFF_ROLE_CCENTER_HEAD = 2;
export const STAFF_ROLE_DIRECTOR = 3;
export const STAFF_ROLE_ACADEMIC_COORDINATOR = 2;
export const STAFF_ROLE_ADDMISSION_COORDINATOR = 2;
export const STAFF_ROLE_PRINCIPAL = 2;
export const STAFF_ROLE_NANY = 1;
export const STAFF_ROLE_DRIVER = 5;
export const STAFF_ROLE_HELPER = 6;
export const STAFF_ROLE_SECURIRY = 7;
export const STAFF_ROLE_OTHERS = 1;

//Teacher role asci

export const ASCI_STAFF_COURSE_DIRECTOR = 2;
export const ASCI_STAFF_COURSE_COORDINATOR = 2;
export const ASCI_STAFF_SPEAKER = 2;
export const ASCI_STAFF_ADMIN = 3;
export const ASCI_STAFF_OTHERS = 2;

//Teacher at franchise level

export const FRANCHISE_STAFF_ADMIN = 10;
export const FRANCHISE_STAFF_ADMIN2 = 11;
export const FRANCHISE_STAFF_SUPERVISOR = 12;

//School Features Index
export const FEATURE_ACTIVITY_TRACKING_INDEX = 0;
export const FEATURE_ATTENDANCE_INDEX = 1;
export const FEATURE_DIARY_INDEX = 2;
export const FEATURE_PAYMENTS_INDEX = 3;
export const FEATURE_PICKNDROP_INDEX = 4;
export const FEATURE_MEDICAL_INDEX = 5;
export const FEATURE_TRANSPORT_INDEX = 6;
export const FEATURE_CUSTOM_DIARY_INDEX = 7;
export const FEATURE_PARENT_TRANSPORT_INDEX = 8;
export const FEATURE_NO_ATTENDANCE_MESSAGE_INDEX = 9;
export const FEATURE_ACTIVITY_TRACKING_PARENT_INDEX = 10;
export const FEATURE_OBSERVATION_REPORT_INDEX = 11;
export const FEATURE_WEB_MESSAGE_INDEX = 12;
export const FEATURE_EXPENSE_INDEX = 13;
export const FEATURE_CCTV_INDEX = 14;
export const FEATURE_VISITOR_INDEX = 15;
export const FEATURE_SMS_ON_ALERT_INDEX = 16;
export const FEATURE_PROGRAM_INDEX = 17;
export const FEATURE_MARKETPLACE_INDEX = 18;
export const FEATURE_E_LEARNING = 19;
export const FEATURE_GO_LIVE = 20;
export const FEATURE_ATTENDANCE_WITH_TEMPERATURE = 21;
export const FEATURE_COURSE = 22;
export const FEATURE_MARKS_CARD = 23;
export const FEATURE_SERVICE_DESK = 24;
export const FEATURE_TICKETS = 24;
export const FEATURE_LEAVES = 25;
export const FEATURE_ENQUIRY_FOLLOWUP = 26;
export const FEATURE_WHATSAPP_INTEGRATION = 27;
export const FEATURE_LEAVE_MANAGEMNET = 28;
export const FEATURE_PAYROLL_MANAGEMNET = 29;

//Features Index Values
export const FEATURE_ATTENDANCE_INDEX_VALUE_BASIC = 1;
export const FEATURE_ATTENDANCE_INDEX_VALUE_QR_SCAN = 2;
export const FEATURE_ATTENDANCE_INDEX_VALUE_NFC = 3;
export const FEATURE_ATTENDANCE_INDEX_VALUE_SELF = 4;

export const FEATURE_PARENT_TRANSPORT_INDEX_COMPANY = 1;
export const FEATURE_PARENT_TRANSPORT_INDEX_NO_QUERY = 2; //no messaging button for parent

//visitor Index Values
export const FEATURE_VISITOR_INDEX_VALUE_BASIC = 1;
export const FEATURE_VISITOR_INDEX_VALUE_WITH_OTP_AUTH = 2;
export const FEATURE_VISITOR_INDEX_VALUE_PHOTO_WITH_OTP_AUTH = 3;

//cctv Index Values
export const FEATURE_CCTV_INDEX_VALUE_WITH_ATTENDANCE = 1;
export const FEATURE_CCTV_INDEX_VALUE_BASIC = 2;


//payment gateway options
export const GATEWAY_CC_AVENUE_INDEX = 0;
export const GATEWAY_PAY_U_INDEX = 1;
export const GATEWAY_AIR_PAY_INDEX = 2;
export const GATEWAY_RAZOR_PAY_INDEX = 4;
export const GATEWAY_ONE_PAY_INDEX = 5;
export const GATEWAY_GRAY_QUEST = 6;


export const GATEWAY_PAY_U_PRODUCTION = 1;
export const GATEWAY_PAY_U_DEVELOPMENT = 2;

//SCHOOL

export const APP_SUBSCRIPTION_MODE_PARENT = 2;
export const APP_SUBSCRIPTION_MODE_SCHOOL = 1;

//TEACHER PERMISSIONS
export const TEACHER_CHILD_DEVELOPMENT_INDEX = 0;
export const TEACHER_ATTENDANCE_INDEX = 1;
export const TEACHER_MESSAGING_INDEX = 2;
export const TEACHER_PAYMENTS_INDEX = 3;
export const TEACHER_PICK_DROP_INDEX = 4;
export const TEACHER_MEDICAL_DETAILS_INDEX = 5;
export const TEACHER_TRANSPORT_INDEX = 6;
export const TEACHER_DAYCARE_INDEX = 7;
export const TEACHER_CHILD_DETAILS_INDEX = 8;
export const TEACHER_EXPENSE_INDEX = 9;
export const TEACHER_CCTV_INDEX = 10;
export const TEACHER_PAYMENT_ADMINISTRATOR = 11;
export const TEACHER_GROUPS = 12;
export const TEACHER_ENQUIRY = 13;
export const TEACHER_COURSE = 14;
export const TEACHER_EXAM = 15;
export const TEACHER_FORMS = 16;
export const TEACHER_PROGRAMS = 17;
export const TEACHER_PARENT_CONTACT = 18;
export const TEACHER_LMS_CONTENT_ACCESS = 19;

//ADDITIONAL SETTINGS FOR SCHOOL

export const ADDITIONAL_SETTING_CUSTOM_HELP_SCREEN = 0; //app
export const ADDITIONAL_SETTING_DAYCARE_FULL_HOUR_CHARGES = 1;
export const ADDITIONAL_SETTING_CHILD_DEV_MSG_DISABLED = 2; //server
export const ADDITIONAL_SETTING_PAYMENT_MSG_DISABLED = 3; //server
export const ADDITIONAL_SETTING_VOICE_MESSAGE_DISABLED_FOR_START_STOP = 4;
export const ADDITIONAL_SETTING_PENALTY = 5;
export const ADDITIONAL_SETTING_DIARY_APPROVAL = 6;
export const ADDITIONAL_SETTING_MARKETPLACE_VOICE = 7;
export const ADDITIONAL_SETTING_lilTriangle_CONTENT_SHARE_PERMISSIM = 8;
export const ADDITIONAL_SETTING_lilTriangle_EXTRA_PROTECTION = 9; //zoom call water mark and disbling emulator
export const ADDITIONAL_SETTING_WATER_MARK = 10;
export const ADDITIONAL_SETTING_PAYMENT_EDIT_DISABLED = 11;
export const ADDITIONAL_SETTING_PAYMENT_DATE = 12;
export const ADDITIONAL_SETTING_RECEIPT_WITHOUT_TRANSACTION= 13;
export const ADDITIONAL_SETTING_DISABLE_SELF_SCAN_ANDROID= 14;
export const ADDITIONAL_SETTING_DISABLE_SELF_CHECK_IN = 15;


//PARENT PERMISSIONS

export const PARENT_PERMISSIONS_EDIT_PARENT_DETAILS_INDEX = 0; //disable
export const PARENT_PERMISSIONS_EDIT_MEDICAL_DETAILS_INDEX = 1; //disable
export const PARENT_PERMISSIONS_EDIT_PICKUP_DETAILS_INDEX = 2; //disable
export const PARENT_PERMISSIONS_FAVORITE_COLOR_INDEX = 3; //disable
export const PARENT_PERMISSIONS_REFER_CHILD_INDEX = 4; //disable
export const PARENT_PERMISSIONS_SHARE_IMAGE_INDEX = 5; //disable
export const PARENT_PERMISSIONS_HELP_VIDEO_INDEX = 6; //disable
export const PARENT_PERMISSIONS_EDIT_PROFILE_PICK_INDEX = 7; //disable
export const PARENT_PERMISSIONS_PARENT_FEEDBACK_INDEX = 8; //disable
export const PARENT_PERMISSIONS_PARENT_MARKETPLACE_INDEX = 9; //disable

// Enquiry Question types
export const QESTION_TEXT_SINGLE_LINE = 1;
export const QESTION_TEXT_MULTI_LINE = 2;
export const QESTION_NUMBER = 3;
export const QESTION_YES_NO_NO_COMMENTS = 4;
export const QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST = 5;
export const QESTION_CHOICE_MULTI_SELECTION_FROM_LIST = 6;
export const QESTION_DATE = 7;
export const QESTION_RANGE_TIME = 8;
export const QESTION_GEO_POINT = 9;
export const QESTION_YES_NO_COMMENT_ON_YES = 10;
export const QESTION_YES_NO_COMMENT_ON_NO = 11;
export const QESTION_YES_NO_WITH_COMMENT = 12;
export const QUESTION_EMAIL = 13;
export const QUESTION_PHONE_NUMBER = 14;
export const QESTION_CHECKBOX = 15;
export const QESTION_UPLOAD_IMAGE = 16;
export const QESTION_UPLOAD_DOCUMENT = 17;
export const QESTION_CLASS_SELECTION = 18;
export const QESTION_MONTH_SELECTION = 19;
export const QESTION_SUPERVISION_REPORT = 20;




//Exam Question types
export const EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST = 1;
export const EXAM_FILL_IN_THE_BLANKS = 2;
export const EXAM_ARRANGE_THE_FOLLOWING = 3;
export const EXAM_MATCH_THE_FOLLOWING = 4;
export const EXAM_CHOICE_MULTI_SELECTION_FROM_LIST = 5;
export const EXAM_LONG_ANSWER = 6;

//School Level
export const FRANCHISE_LEVEL = 1;
export const INSTITUTE_LEVEL = 2;
export const SCHOOL_LEVEL = 3;

//Franchise Features Index
export const FRANCHISE_CUSTOM_REPORTS_INDEX = 0;
export const FRANCHISE_ADMISSION_FORM_INDEX = 1;
export const FRANCHISE_ROYALTY_SETTING_INDEX = 2;
export const FRANCHISE_ID_CARD_DISABLE_INDEX = 3;
export const FRANCHISE_CORPORATE_PARTNERS_INDEX = 4;
export const FRANCHISE_DOCUMENT_INDEX = 5;
export const FRANCHISE_SCHOOL_ADDING_DISABLE_INDEX = 6;
export const FRANCHISE_TICKETS_INDEX = 7;
export const FRANCHISE_INVENTORY_INDEX = 8;
export const FRANCHISE_LOCK_FEATURE_EDIT = 9;
export const FRANCHISE_NEW_BRANCH_ENQUIRY = 10;
export const FRANCHISE_MANAGE_BRANCH_SUBSCRIPTION = 11

//Monthly Reports Section Type
export const MR_SECTION_TYPE_NON_REPEATER_BY_4_COL = 0;
export const MR_SECTION_TYPE_REPEATER_BY_4_COL = 1;//4 
export const MR_SECTION_TYPE_FULL_ROW = 2;
export const MR_SECTION_TYPE_DIMENSIONS = 3;// dimentions y axis defined x axis is question(table data)
export const MR_SECTION_TYPE_STAFF_LEFT_THIS_MONTH = 4;
export const MR_SECTION_TYPE_SUPERVISION = 5;// parmeter and grade


//Monthly Reports Session Status
export const MR_SESSION_STATUS_SAVED_ONLY = 0;
export const MR_SESSION_STATUS_SUBMITTED = 1;
export const MR_SESSION_STATUS_RESUBMITTED = 2;
export const MR_SESSION_STATUS_REJECTED = 3;
export const MR_SESSION_STATUS_APPROVED = 4;

//CourseSubscriptionRequests Status
export const CSR_SUBMITTED = 1;
export const CSR_RESUBMITTED = 2;
export const CSR_REJECTED = 3;
export const CSR_APPROVED = 4;


//GroupingType for learning Analytics graphs
export const CATEGORY_GRAPHS = 1
export const INDIVIDUAL_GRAPHS = 2


//Report Type For Custom Report Template
export const CR_MONTHLY_REPORTS = 1
export const CR_SUPERVISION = 2

//School Types

export const SCHOOL_TYPE_PRESCHOOL = 1;
export const SCHOOL_TYPE_ASCI = 10;
export const SCHOOL_TYPE_ACADEMY = 11;

//classroom Types
export const DAYCARE_CLASS_TYPE = 2;


//FRANCHISE ADMIN PERMISSIONS
export const FRANCHISE_ADMIN_BRANCH_INDEX = 0;
export const FRANCHISE_ADMIN_ADMINS_INDEX = 1;
export const FRANCHISE_ADMIN_MESSAGING_INDEX = 2;
export const FRANCHISE_ADMIN_FEE_INDEX = 3;
export const FRANCHISE_ADMIN_LMS_INDEX = 4;
export const FRANCHISE_ADMIN_TICKETS_INDEX = 5;
export const FRANCHISE_ADMIN_INVENTORY_INDEX = 6;
export const FRANCHISE_ADMIN_CARDS_INDEX = 7;
export const FRANCHISE_ADMIN_CORPORATE_INDEX = 8;
export const FRANCHISE_ADMIN_BRANCH_ENQUIRY_INDEX = 9;
export const FRANCHISE_ADMIN_FORMS_INDEX = 10;
export const FRANCHISE_ADMIN_REPORTS_INDEX = 11;
export const FRANCHISE_ADMIN_COSTOM_REPORTS_INDEX = 12;

export const stateName = [
  { value: "AP", label: "Andhra Pradesh" },
  { value: "AS", label: "Assam" },
  { value: "AR", label: "Arunachal Pradesh" },
  { value: "AN", label: "Andaman and Nicobar Islands" },
  { value: "BR", label: "Bihar" },
  { value: "CH", label: "Chandigarh" },
  { value: "CG", label: "Chhattisgarh" },
  { value: "DN", label: "Dadra and nagar Haveli" },
  { value: "DD", label: "Daman and Diu" },
  { value: "DL", label: "Delhi" },
  { value: "GJ", label: "Gujarat" },
  { value: "GA", label: "Goa" },
  { value: "HR", label: "Haryana" },
  { value: "HP", label: "Himachal Pradesh" },
  { value: "JH", label: "Jharkhand" },
  { value: "JK", label: "Jammu and Kashmir" },
  { value: "KA", label: "Karnataka" },
  { value: "KL", label: "Kerala" },
  { value: "LA", label: "Ladakh" },
  { value: "LD", label: "Lakshadweep" },
  { value: "MH", label: "Maharashtra" },
  { value: "MP", label: "Madhya Pradesh" },
  { value: "MN", label: "Manipur" },
  { value: "ML", label: "Meghalaya" },
  { value: "MZ", label: "Mizoram" },
  { value: "NL", label: "Nagaland" },
  { value: "OD", label: "Odisha" },
  { value: "PY", label: "Puducherry" },
  { value: "PB", label: "Punjab" },
  { value: "RJ", label: "Rajasthan" },
  { value: "SK", label: "Sikkim" },
  { value: "TN", label: "Tamil Nadu" },
  { value: "TS", label: "Telangana" },
  { value: "TR", label: "Tripura" },
  { value: "UP", label: "Uttar Pradesh" },
  { value: "UK", label: "Uttarakhand" },
  { value: "WB", label: "West Bengal" },
];

export const stateNameCode = {
  "AP": "Andhra Pradesh",
  "AS": "Assam",
  "AR": "Arunachal Pradesh",
  "AN": "Andaman and Nicobar Islands",
  "BR": "Bihar",
  "CH": "Chandigarh",
  "CG": "Chhattisgarh",
  "DN": "Dadra and nagar Haveli",
  "DD": "Daman and Diu",
  "DL": "Delhi",
  "GJ": "Gujarat",
  "GA": "Goa",
  "HR": "Haryana",
  "HP": "Himachal Pradesh",
  "JH": "Jharkhand",
  "JK": "Jammu and Kashmir",
  "KA": "Karnataka",
  "KL": "Kerala",
  "LA": "Ladakh",
  "LD": "Lakshadweep",
  "MH": "Maharashtra",
  "MP": "Madhya Pradesh",
  "MN": "Manipur",
  "ML": "Meghalaya",
  "MZ": "Mizoram",
  "NL": "Nagaland",
  "OD": "Odisha",
  "PY": "Puducherry",
  "PB": "Punjab",
  "RJ": "Rajasthan",
  "SK": "Sikkim",
  "TN": "Tamil Nadu",
  "TS": "Telangana",
  "TR": "Tripura",
  "UP": "Uttar Pradesh",
  "UK": "Uttarakhand",
  "WB": "West Bengal",
};


export const MESSAGE = 1;
export const ALERT = 2;
export const ASSIGNMENT = 3;
export const NOTICE = 4;
export const REMINDER = 5;
export const QUERY = 10;
export const REPORT = 15;
export const LIVEMEETING = 53;
export const PAYMENT = 61;
export const PAYMENT_MESSAGE = 62;
export const TRANSPORT = 71;
export const TRANSPORT_FINISH = 72;
export const TRANSPORT_UPDATE = 73;
export const MEAL_IDEA = 6;
export const SWEET_TIMES = 7;
export const ATTENDANCE = 8;
export const DIARY = 9;
export const DIARY_FOOD = 11;
export const DIARY_DIAPER = 12;
export const DIARY_NAP = 13;
export const DIARY_MEDICAL_NOTE = 14;
export const ATTITUDE = 18;
export const INTELLIGENCE = 16;
export const LEARNING_STYLE = 17;
export const TEACHER = 20;
export const TEACHER_REPLACEMENT = 21;
export const TRIANGLEBOT = 100;
export const BIRTHDAY = 101;
export const MEDICAL_NOTE_UPDATE = 102;
export const ACTIVITY_CREATED = 51;
export const NEW_ACTIVITY_CREATED = 52;
export const E_LEARNING = 54;
export const UNKNOWN = -1;
export const POLLS = 25;
export const FRANCHISE_BROADCAST_MESSAGE = 110;
export const AIR_QUALITY = 30;
export const TOYS = 40;
export const ENERGY_LEVEL = 39;
export const FEELINGS = 38;

export const IMAGE_COMPRESSION_RATE = 0.6;


//GraphType - LearningMeasures
//SES - Socio Emotional Skills.
export const GRAPHTYPE_SES = 'SES';
export const GRAPHTYPE_PIE = 'pie';
export const GRAPHTYPE_RADAR = 'radar';
