import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as EnquiryHelper from "../../../helpers/ParseHelpers/enquiry_helper";
import * as actionTypes from "./actionTypes";
import * as EnquiryActions from "./actions";
import * as Constants from "../../../Constents";
import { v4 as uuidv4 } from "uuid";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import KidSaga from "../kid/saga";

function* getAEnquiry({ payload: { id } }) {
  try {
    const response = yield call(EnquiryHelper.getEnquiryFromParse, id, false);
    yield put(EnquiryActions.updateEnquiry(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAEnquiry({ payload: { enquiryId } }) {
  try {
    const response = yield call(
      EnquiryHelper.deleteEnquiryFromParse,
      enquiryId
    );
    if (response) {
      yield put(EnquiryActions.deleteEnquiry(response));
      response.pin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* setSelectedEnquiry({ payload: { id } }) {
  try {
    const localResponse = yield call(
      EnquiryHelper.getEnquiryFromParse,
      id,
      true
    );
    if (localResponse) {
      yield put(EnquiryActions.updateSelecetdEnquiry(localResponse));
    }

    const serverResponse = yield call(
      EnquiryHelper.getEnquiryFromParse,
      id,
      false
    );
    if (serverResponse) {
      yield put(EnquiryActions.updateSelecetdEnquiry(serverResponse));
      serverResponse.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getEnquirisForSchoolId({ payload: { schoolId } }) {
  try {
    var key = Constants.ENQUIRY_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      EnquiryHelper.getEnquirysForSchoolId,
      schoolId,
      null,
      true
    );
    yield put(EnquiryActions.updateEnquiries(localResponse));

    const serverResponse = yield call(
      EnquiryHelper.getEnquirysForSchoolId,
      schoolId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(EnquiryActions.updateEnquiries(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getDeletedEnquirisForSchoolId({ payload: { schoolId } }) {
  try {
    var key = Constants.DELETED_ENQUIRY_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      EnquiryHelper.getDeletedEnquirysForSchoolId,
      schoolId,
      null,
      true
    );
    if (localResponse && localResponse.length > 0) {
      yield put(EnquiryActions.updateDeletedEnquiries(localResponse));
    }


    const serverResponse = yield call(
      EnquiryHelper.getDeletedEnquirysForSchoolId,
      schoolId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(EnquiryActions.updateDeletedEnquiries(serverResponse));
      Parse.Object.pinAll(serverResponse);
    } else if (!localResponse || localResponse.length === 0) {
      yield put(EnquiryActions.updateDeletedEnquiries([]));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getEnquirisForGivenDates({
  payload: { schoolId, startDate, endDate },
}) {
  yield put(EnquiryActions.clearEnquiry());
  const localResponse = yield call(
    EnquiryHelper.getEnquirisForGivenDates,
    schoolId,
    startDate,
    endDate,
    true
  );
  yield put(EnquiryActions.updateEnquiries(localResponse));

  const serverResponse = yield call(
    EnquiryHelper.getEnquirisForGivenDates,
    schoolId,
    startDate,
    endDate,
    false
  );
  if (serverResponse && serverResponse.length > 0) {
    yield put(EnquiryActions.updateEnquiries(serverResponse));
    Parse.Object.pinAll(serverResponse);
  }
}

function* editEnquiry({ payload: { enquiry } }) {
  try {
    const response = yield call(EnquiryHelper.editEnquiry, enquiry);
    if (response) {
      yield put(EnquiryActions.updateEnquiry(response));
      response.pin();
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getAdQuestions({ payload: { schoolId, instituteId, franchiseId } }) {
  try {
    const response = yield call(
      EnquiryHelper.getAdduQuestionParse,
      schoolId,
      instituteId,
      franchiseId
    );
    yield put(EnquiryActions.setAdmissionQuestions(response));
  } catch (error) { }
}

function* moveKidToAdmissions({ payload: { enquiry } }) {
  yield put(EnquiryActions.setEnquiryLoading(true));
  var familyId;
  if (enquiry.attributes.familyId && enquiry.attributes.familyId.length > 0) {
    familyId = enquiry.attributes.familyId;
  } else {
    familyId = uuidv4();
    enquiry.set("familyId", familyId);
  }
  enquiry.set("status", 1);

  const response = yield call(EnquiryHelper.editEnquiry, enquiry);
  if (response) {
    yield put(EnquiryActions.updateEnquiry(response));
    response.pin();
  }

  var groupACL = new Parse.ACL();

  groupACL.setRoleWriteAccess(enquiry.attributes.schoolId + "_Admin", true);
  groupACL.setRoleReadAccess(enquiry.attributes.schoolId + "_Admin", true);

  groupACL.setRoleWriteAccess(enquiry.attributes.schoolId + "_Teacher", false);
  groupACL.setRoleReadAccess(enquiry.attributes.schoolId + "_Teacher", true);

  groupACL.setRoleWriteAccess(familyId + "_Role", true);
  groupACL.setRoleReadAccess(familyId + "_Role", true);

  var publicACL = new Parse.ACL();
  publicACL.setPublicReadAccess(true);
  publicACL.setPublicWriteAccess(true);
  enquiry.setACL(publicACL);

  const tempKid = yield call(EnquiryHelper.getKidForEnquiryId, enquiry.id);
  const Kid = Parse.Object.extend("Kid");
  var kid = new Kid();
  if (tempKid && tempKid.length > 0) {
    kid = tempKid[0];
    if (kid.attributes.Deleted) {
      kid.set("Deleted", false);
    }
  }


  kid.set("Name", enquiry.attributes.kidName);
  kid.set("schoolAdmitted", enquiry.attributes.schoolId);
  kid.set("DateOfBirth", enquiry.attributes.dateOfBirth);
  kid.set(
    "Gender",
    enquiry.attributes.kidGender ? enquiry.attributes.kidGender : "Male"
  );
  kid.set("FamilyID", enquiry.attributes.familyId);
  kid.set("enquiryId", enquiry.id);

  if (enquiry.attributes.kidPhoto) {
    kid.set("Photo", enquiry.attributes.kidPhoto);
  }
  if (enquiry.attributes.enquiryFor) {
    kid.set("enquiryFor", enquiry.attributes.enquiryFor);
  }
  if (enquiry.attributes.year) {
    kid.set("admissionYear", enquiry.attributes.year);
  }
  if (enquiry.attributes.occupation) {
    kid.set("occupation", enquiry.attributes.occupation);
  }
  if (enquiry.attributes.email) {
    kid.set("email", enquiry.attributes.kidPhoto);
  }
  if (enquiry.attributes.phoneNumber) {
    kid.set("phoneNumber", enquiry.attributes.phoneNumber);
  }
  if (enquiry.attributes.feeType) {
    kid.set("feeType", enquiry.attributes.feeType);
  }
  if (enquiry.attributes.programType) {
    kid.set("programType", enquiry.attributes.programType);
  }
  kid.set("admissionDate", new Date());
  kid.setACL(groupACL);

  const kidResponse = yield call(EnquiryHelper.saveParseObject, kid);
  if (kidResponse) {
    kidResponse.pin();
    const CDA = yield call(EnquiryHelper.getCADForEnquiryId, enquiry.id);
    if (CDA && CDA.length > 0) {
      CDA[0].set("kidId", kidResponse.id);
      yield call(EnquiryHelper.saveParseObject, CDA[0]);
    }
  }

  const FatherObject = Parse.Object.extend("Parent");
  var fatherObject = new FatherObject();

  const MotherObject = Parse.Object.extend("Parent");
  var motherObject = new MotherObject();

  if (enquiry.attributes.familyId && enquiry.attributes.familyId.length > 0) {
    const parebtObjects = yield call(
      EnquiryHelper.getParentsForFamilyId,
      enquiry.attributes.familyId
    );

    if (parebtObjects && parebtObjects.length > 0) {
      for (const i in parebtObjects) {
        if (parebtObjects[i].attributes.Relation === "Father") {
          fatherObject = parebtObjects[i];
        } else if (parebtObjects[i].attributes.Relation === "Mother") {
          motherObject = parebtObjects[i];
        }
      }
    }
  }

  fatherObject.set("Relation", "Father");
  fatherObject.set("FamilyID", familyId);
  fatherObject.set("NotificationChoice", 16127);
  fatherObject.setACL(publicACL);
  if (
    enquiry.attributes.fatherName &&
    enquiry.attributes.fatherName.length > 0
  ) {
    fatherObject.set("Name", enquiry.attributes.fatherName);
  } else {
    fatherObject.set("Name", "");
  }
  if (enquiry.attributes.fatherMobile != null) {
    fatherObject.set("PhoneNumber", enquiry.attributes.fatherMobile);
  }
  if (enquiry.attributes.fatherEmail) {
    fatherObject.set("EMail", enquiry.attributes.fatherEmail);
  }
  const fatherResponse = yield call(
    EnquiryHelper.saveParseObject,
    fatherObject
  );
  if (fatherResponse) {
    fatherResponse.pin();
  }
  motherObject.set("Relation", "Mother");
  motherObject.set("FamilyID", familyId);
  motherObject.set("NotificationChoice", 16127);
  motherObject.setACL(publicACL);
  if (
    enquiry.attributes.motherName &&
    enquiry.attributes.motherName.length > 0
  ) {
    motherObject.set("Name", enquiry.attributes.motherName);
  } else {
    motherObject.set("Name", "");
  }
  if (enquiry.attributes.motherMobile != null) {
    motherObject.set("PhoneNumber", enquiry.attributes.motherMobile);
  }
  if (enquiry.attributes.motherEmail) {
    motherObject.set("EMail", enquiry.attributes.motherEmail);
  }
  const motherResponse = yield call(
    EnquiryHelper.saveParseObject,
    motherObject
  );
  if (motherResponse) {
    motherResponse.pin();
  }
  yield call(EnquiryHelper.saveParseObject, enquiry);
  yield put(EnquiryActions.setEnquiryLoading(false));
}

function* saveEnquiryObjects({
  payload: { enquiryObjects, schoolId, parentObjects, successMessage },
}) {
  try {
    yield put(EnquiryActions.setEnquiryBulkUploadLoading(true));
    const response = yield call(EnquiryHelper.saveParseObjects, enquiryObjects);
    if (response) {
      Parse.Object.pinAll(response);

      yield put(EnquiryActions.clearEnquiry());

      // yield put(EnquiryActions.updateEnquiries(response));
      const localResponse = yield call(
        EnquiryHelper.getEnquirysForSchoolId,
        schoolId,
        null,
        true
      );
      yield put(EnquiryActions.updateEnquiries(localResponse));

      const response2 = yield call(
        EnquiryHelper.saveParseObjects,
        parentObjects
      );

      if (response2) {
        toastr.success(successMessage, { timeOut: 2000 });
        Parse.Object.pinAll(response2);
      }
    } else {
    }

    yield put(EnquiryActions.setEnquiryBulkUploadLoading(false));
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* bulkDelete({ payload: { enquiries } }) {
  try {
    yield put(EnquiryActions.setEnquiryBulkDeleteLoading(true));
    const response = yield call(
      EnquiryHelper.deleteEnquiriesFromParse,
      enquiries
    );
    if (response) {
      yield put(EnquiryActions.updateEnquiries(response));
      Parse.Object.pinAll(response);
    } else {
    }
    yield put(EnquiryActions.setEnquiryBulkDeleteLoading(false));
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* enquieybulkRestore({ payload: { enquiries } }) {
  try {
    yield put(EnquiryActions.setEnquiryBulkRestoreLoading(true));
    const response = yield call(
      EnquiryHelper.restoreEnquiriesFromParse,
      enquiries
    );
    if (response) {
      yield put(EnquiryActions.updateDeletedEnquiries(response));
      Parse.Object.pinAll(response);
      yield put(EnquiryActions.updateEnquiries(response));
    } else {
    }
    yield put(EnquiryActions.setEnquiryBulkRestoreLoading(false));
  } catch (error) {
    // yield put(apiError(error));
    console.log(error);
  }
}


function* getKidObjFromEnquiry({ payload: { enqId } }) {
  try {
    const response = yield call(
      EnquiryHelper.getKidObjFromEnquiry,
      enqId,
      true
    );
    if (response) {
      yield put(EnquiryActions.setKidObjForEnquiry(response));
    } else {
      response = yield call(EnquiryHelper.getKidObjFromEnquiry, enqId, false);
      yield put(EnquiryActions.setKidObjForEnquiry(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* getAEnquiryTemplate({ payload: { teacherId, schoolId, instituteId } }) {
  try {
    var key = Constants.ENQUIRY_TEMPLATE_UPDATED_TIME + "_" + teacherId + "_" + schoolId + "_" + instituteId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      EnquiryHelper.getEnquiryTemplate,
      teacherId,
      schoolId,
      instituteId,
      null,
      true
    );
    yield put(EnquiryActions.updateEnquiryTemplate(localResponse));

    const serverResponse = yield call(
      EnquiryHelper.getEnquiryTemplate,
      teacherId,
      schoolId,
      instituteId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(EnquiryActions.updateEnquiryTemplate(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

//FRANCHISE ENQUIRY
function* getFranchiseEnquiryForFranchiseId({ payload: { franchiseId,} }) {
  try {
    
    
    var key = Constants.FRANCHISE_ENQUIRY_UPDATED_TIME + "_" + franchiseId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      EnquiryHelper.getFranchiseEnquiryForFranchiseId,franchiseId,null,true
    );
    if (localResponse && localResponse.length > 0) {
      yield put(EnquiryActions.updateFranchiseEnquiry(localResponse));
    }
  
    const serverResponse = yield call(
      EnquiryHelper.getFranchiseEnquiryForFranchiseId,franchiseId,updatedTime,false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(EnquiryActions.updateFranchiseEnquiry(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* saveFranchiseEnquiry({ payload: { franchiseEnquiry } }) {
  try {
    const response = yield call(EnquiryHelper.saveFranchiseEnquiry, franchiseEnquiry);
    if (response === "Error") {
      toastr.error("Please try again", { timeOut: 3000 });
    } else
      if (response ) {
        yield put(EnquiryActions.updateFranchiseEnquiry([response]));
        response.pin();
        toastr.success("Enquiry successfully updated", { timeOut: 3000 });

        if(isSelcted){
          yield put(EnquiryActions.updateSelectedFranchiseEnquiry(response));
        }
      } else {
      }
  } catch (error) {

  }
}

function* setSelectedFranchiseEnquiry({ payload: { id } }) {
  try {
    const response = yield call(EnquiryHelper.getFranchiseEnquiryFromParse, id, false);
    yield put(EnquiryActions.updateSelectedFranchiseEnquiry(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}



export function* watchGetEnquiry() {
  yield takeEvery(actionTypes.GET_ENQUIRY, getAEnquiry);
}

export function* watchDeleteEnquiry() {
  yield takeEvery(actionTypes.DELETE_ENQUIRY, deleteAEnquiry);
}

export function* watchSetSelectedEnquiry() {
  yield takeEvery(actionTypes.SET_SELECTED_ENQUIRY, setSelectedEnquiry);
}
export function* watchGetEnquiriesForSchoolId() {
  yield takeEvery(actionTypes.GET_ENQUIRIES, getEnquirisForSchoolId);
}
export function* watchEditEquiry() {
  yield takeEvery(actionTypes.EDIT_ENQUIRY, editEnquiry);
}
export function* watchGetEnquirisForGivenDates() {
  yield takeEvery(
    actionTypes.ENQUIRIES_BETWEEN_DATES,
    getEnquirisForGivenDates
  );
}

export function* watchMoveToAdmissions() {
  yield takeEvery(actionTypes.MOVE_TO_ADMISSIONS, moveKidToAdmissions);
}
export function* watchGetAdmissionQuestions() {
  yield takeEvery(actionTypes.GET_ADMISSION_QUESTIONS, getAdQuestions);
}

export function* watchSaveEnquiryObjects() {
  yield takeEvery(actionTypes.SAVE_ENQUIRY_OBJECTS, saveEnquiryObjects);
}
export function* watchBulkDelete() {
  yield takeEvery(actionTypes.BULK_DELETE_REQUEST, bulkDelete);
}
export function* watchgetKidObjFromEnquiry() {
  yield takeEvery(actionTypes.GET_KIDOBJ_FROM_ENQUIRY, getKidObjFromEnquiry);
}
export function* watchGetDeletedEnquiriesForSchoolId() {
  yield takeEvery(actionTypes.GET_DELETED_ENQUIRIES, getDeletedEnquirisForSchoolId);
}
export function* watchEnquiryBulkRestore() {
  yield takeEvery(actionTypes.ENQUIRY_BULK_RESTORE_REQUEST, enquieybulkRestore);
}

export function* watchGetAEnquiryTemplate() {
  yield takeEvery(actionTypes.GET_ENQUIRY_TEMPLATE, getAEnquiryTemplate)
}

export function* watchGetFranchiseEnquiryForFranchiseId() {
  yield takeEvery(actionTypes.GET_FRANCHISE_ENQUIRY_FOR_FRANCHISE_ID, getFranchiseEnquiryForFranchiseId);
}

export function* watchsaveFranchiseEnquiry() {
  yield takeEvery(actionTypes.SAVE_FRANCHISE_ENQUIRY, saveFranchiseEnquiry);
}

export function* watchsetSelectedFranchiseEnquiry() {
  yield takeEvery(actionTypes.SET_SELECTED_FRANCHISE_ENQUIRY, setSelectedFranchiseEnquiry);
}



function* enquirySaga() {
  yield all([
    fork(watchGetEnquiry),
    fork(watchDeleteEnquiry),
    fork(watchSetSelectedEnquiry),
    fork(watchGetEnquiriesForSchoolId),
    fork(watchEditEquiry),
    fork(watchGetEnquirisForGivenDates),
    fork(watchMoveToAdmissions),
    fork(watchGetAdmissionQuestions),
    fork(watchSaveEnquiryObjects),
    fork(watchBulkDelete),
    fork(watchgetKidObjFromEnquiry),
    fork(watchGetDeletedEnquiriesForSchoolId),
    fork(watchEnquiryBulkRestore),
    fork(watchGetAEnquiryTemplate),
    fork(watchGetFranchiseEnquiryForFranchiseId),
    fork(watchsaveFranchiseEnquiry),
    fork(watchsetSelectedFranchiseEnquiry),

  ]);
}
export default enquirySaga;
