import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Input, Table, Badge } from "reactstrap";
import Select from 'react-select';
import { withNamespaces, } from "react-i18next";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    setSelecetdSchool, getTeachersForSchool, updateMasterPayslipForTeacherId, updateMasterPayslipItemsForMasterPayslipId,
    getMasterPayslipsForOwnerIdAndOwnertype
} from '../../store/actions';

const MasterPayRoll = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/master-payroll/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool]);

    useEffect(() => {
        if (schoolId) {
            props.getTeachersForSchool(schoolId)
        }
    }, [schoolId])

    useEffect(() => {
        if (schoolId) {
            props.getMasterPayslipsForOwnerIdAndOwnertype(schoolId, "School")
        }
    }, [schoolId])

    const getTeacherName = (teacherId) => {
        if (props.teachers && props.teachers.length > 0) {
            let temp = props.teachers.filter(
                (teacher) => teacher.id === teacherId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const getDesignation = (role) => {
        if (role === 3) {
            return props.t("Director");
        } else if (role === 2) {
            return props.t("Center Head");
        } else {
            return props.t("Teacher");
        }
    };

    return (
        <React.Fragment>
            <div className='page-content'>
                <Row className="d-flex justify-content-between align-items-start">
                    <Col lg={6} className="d-flex align-items-start">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { window.history.back() }}>
                            <ul className="list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className='m-2'>
                            <h4 className="font-size-18">Payroll Setup</h4>
                        </div>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <div>
                            <div className="table-responsive mt-3">
                                <Table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Teacher Name</th>
                                            <th>Designation</th>
                                            <th>Setup</th>
                                            <th>Current Salary (per year)</th>
                                            <th>Setup</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.teachers && props.teachers.map((teacher, index) => {
                                            const masterPayslip = props.masterPayslips && props.masterPayslips.find(p => p.attributes.teacherId === teacher.id);

                                            return (
                                                <tr key={teacher.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{getTeacherName(teacher.id)}</td>
                                                    <td>{getDesignation(teacher.attributes.Role)}</td>
                                                    <td>
                                                        <Badge
                                                            className={`badge ${masterPayslip ? 'badge-soft-success' : 'badge-soft-danger'} font-size-12 m-2`}
                                                        >
                                                            {masterPayslip ? "Done" : "Not Done"}
                                                        </Badge>
                                                    </td>
                                                    <td>{teacher.attributes.currentSalary ?? "-"}</td>
                                                    <td>
                                                        <Link
                                                            to={`/setup-master-payroll/${schoolId}/${teacher.id}`}
                                                            onClick={() => {
                                                                updateMasterPayslipForTeacherId(undefined);
                                                                updateMasterPayslipItemsForMasterPayslipId([]);
                                                            }}
                                                        >
                                                            <i className="bx bx-right-arrow-alt bx-sm"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { teachers } = state.Teacher;
    const { masterPayslips } = state.LeaveModule;

    return { selectedSchool, teachers, masterPayslips };
};

export default connect(mapStatetoProps, {
    setSelecetdSchool, getTeachersForSchool, updateMasterPayslipForTeacherId, updateMasterPayslipItemsForMasterPayslipId,
    getMasterPayslipsForOwnerIdAndOwnertype
})(withRouter(withNamespaces()(MasterPayRoll)));