import React, { useEffect, useState } from 'react';
import Topbar from './Topbar';
import Parse from 'parse';
import { Card, CardBody, Label, Button, Badge, Row, Col, Form, FormGroup } from 'reactstrap';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { setSelecetdSchool, getInventorySubItemForItemIds, getInventoryItemMatrixForItemIds, getInventoryItemForOwnerId,  getInventoryStockForWarehouse, getInventoryWarehouseForOwnerId} from '../../store/actions';
import Moment from 'moment';
import OrderStatus from "./Sales/OrderStatus"
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
// import XLSX from "xlsx";
import DatePicker from "react-datepicker";
import * as XLSX from 'xlsx/xlsx.mjs';
import InventoryItemsReport from './InventoryItemsReport';

const saleOrder = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/inventory-report/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-inventory-report/:franchiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;
    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

    const [showOrderStatus, setShowOrderStatus] = useState(false);
    const [orderStatusObject, setOrderStatusObject] = useState(undefined);

    const [allSchools, setAllSchools] = useState([]);
    const [selectedSchool, setSelctedSchool] = useState();
    const [reportType, setReportType] = useState(1);
    const [statusType, setStatusType] = useState(0);
    const [saleOrders, setSaleOrders] = useState([]);
    const [saleOrderItems, setSaleOrderItems] = useState([]);
    const [displayTitles, setDisplayTitles] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedSchoolError, setSelctedSchoolError] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();
    const [gotReport, setGotReport] = useState(false);
    const [showInventoryItemsReport, setShowInventoryItemsReport] = useState(false);
    
    useEffect(() => {
        if (schoolId && (!props.selectedSchool || props.selectedSchool.id !== schoolId)) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);


    useEffect(() => {
        if (props.schools) {
            let list = [];
            for (const school of props.schools) {
                list.push({ value: school.id, label: school.attributes.Name })
            }
            setAllSchools(list);
        }
    }, [props.schools]);

    const getSchoolName = (schoolId) => {
        if (props.schools && props.schools.length > 0) {
            let temp = props.schools.filter(
                (school) => school.id === schoolId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const getInventorySaleOrderReport = () => {
        setLoading(true);
        let InventorySaleOrder = Parse.Object.extend("InventorySaleOrders");
        let query = new Parse.Query(InventorySaleOrder);

        if (reportType == 1) {

            if (franchiseId) {
                query.equalTo('buyerId', selectedSchool.value)
            } else {
                query.equalTo('buyerId', schoolId)
                query.limit(1000);
            }
        }

        if (reportType == 2 || reportType == 3) {

            let startDate = new Date(
                selectedStartDate.getFullYear(),
                selectedStartDate.getMonth(),
                selectedStartDate.getDate()
            );
            let endDate = new Date(
                selectedEndDate.getFullYear(),
                selectedEndDate.getMonth(),
                selectedEndDate.getDate(),
                23,
                59,
                59,
                999
            );
            if(franchiseMatch){
                query.equalTo("ownerId", franchiseId)
            }else{
                query.equalTo('buyerId', schoolId)
            }
            query.greaterThanOrEqualTo("createdAt", startDate);
            query.lessThanOrEqualTo("createdAt", endDate);
        }
        if (statusType != 0) {
            if (statusType == 1) {
                query.equalTo("status", 0)
            } else if (statusType == 2) {
                query.equalTo("status", 2)
            } else if (statusType == 3) {
                query.equalTo("status", 3)
            } else if (statusType == 4) {
                query.equalTo("status", 1)
            }
        }else{
            query.notEqualTo("status",-1)
        }
        query.limit(1000)
        query.notEqualTo("isDeleted", true);

        query.descending('createdAt')
        query.find().then(
            (result) => {
                // console.log(result)
                setSaleOrders(result)

                let saleOrderIds = [];
                for (const saleOrder of result) {
                    saleOrderIds.push(saleOrder.id)
                }
                let transactionIds = [];
                for (const saleOrder of result) {
                    transactionIds.push(saleOrder.attributes.transactionId)
                }
                if (reportType == 3) {
                    getMoneyTransactions(transactionIds, result)
                } else {
                    getSaleOrderItems(saleOrderIds, result)
                }
            },
            (error) => {
                console.log(error)
            }
        );
    }

    const getMoneyTransactions = (transactionIds, saleOrders) => {

        var InventoryMoneyTransactions = Parse.Object.extend("MoneyTransactions");
        var query = new Parse.Query(InventoryMoneyTransactions);
        query.containedIn('objectId', transactionIds);

        query.notEqualTo("isDeleted", true);

        query.limit(transactionIds.length + 100)

        query.addAscending("order");
        query.find().then(
            (result) => {
                // console.log(result);
                prepareTransactionReportData(saleOrders, result)

            },
            (error) => {
                console.log(error);
            }
        );
    }

    const prepareTransactionReportData = (saleOrders, moneyTransactions) => {
        let finalData = [];
        let title = [];

        title.push("Sl.No")
        title.push("Sale order");
        title.push("Sale Order Number");
        title.push("Sale Order date");
        title.push("Transaction Id");
        title.push("Amount");
        title.push("Transaction Type");
        title.push("Transaction Status");

        setDisplayTitles(title);

        let count = 1

        for (const mt of moneyTransactions) {
            count++
            let saleOrderTransaction = saleOrders.filter((so) => so.attributes.transactionId === mt.id)
            // console.log(saleOrderTransaction)
            for (const so of saleOrderTransaction) {
                finalData.push(
                    {
                        slNo: count,
                        saleOrder: getSchoolName(so.attributes.buyerId),
                        saleOrderNumber: so.attributes.saleOrderNumber,
                        date: Moment(so.attributes.createdAt).format('DD/MM/YYYY'),
                        transcationId: mt.attributes.transactionType == "RazorPay" ? mt.attributes.razorpayId : mt.id,
                        amount: mt.attributes.amount,
                        transcationType: mt.attributes.transactionType,
                        transactionStatus: mt.attributes.transactionStatus,
                    }
                )
            }
        }
        // console.log(finalData)
        setDisplayData(finalData);
        setGotReport(true)
        setLoading(false)
    }


    const getSaleOrderItems = (saleOrderIds, saleOrders) => {

        var InventorySaleOrderItem = Parse.Object.extend("InventorySaleOrderItems");
        var query = new Parse.Query(InventorySaleOrderItem);
        query.containedIn('saleOrderId', saleOrderIds);

        query.notEqualTo("isDeleted", true);

        query.limit(saleOrderIds.length * 100)

        query.addAscending("order");
        query.find().then(
            (result) => {
                // console.log(result);
                setSaleOrderItems(result)

                let inventoryItemIds = [];
                for (const saleOrderItem of result) {
                    inventoryItemIds.push(saleOrderItem.attributes.itemId)
                }
                getInventoryItems(inventoryItemIds, saleOrders, result)
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const getInventoryItems = (inventoryItemIds, saleOrders, saleOrderItems) => {

        var InventoryItems = Parse.Object.extend("InventoryItems");
        var query = new Parse.Query(InventoryItems);
        query.containedIn("objectId", inventoryItemIds);

        // query.notEqualTo("isDeleted", true);

        query.limit(inventoryItemIds.length * 100)

        query.addAscending("order");
        query.find().then(
            (result) => {
                // console.log(result);
                if (reportType == 1) {
                    prepareSchoolReportData(saleOrders, saleOrderItems, result)
                }
                if (reportType == 2) {
                    prepareItemReportData(saleOrders, saleOrderItems, result)
                }

            },
            (error) => {
                console.log(error);
            }
        );
    }

    const prepareSchoolReportData = (saleOrders, saleOrderItems, inventoryItems) => {
        let finalData = [];
        let title = [];

        title.push("#");
        title.push("Item Name");
        title.push("Number of orders");
        title.push("Total Item count");

        setDisplayTitles(title);


        for (const item of inventoryItems) {
            let saleItems = saleOrderItems.filter((si) => si.attributes.itemId === item.id)
            let totalCost = 0;
            let totalQuantity = 0;
            for (const si of saleItems) {
                totalQuantity = totalQuantity + si.attributes.quantity;
            }
            finalData.push(
                {
                    itemName: item.attributes.name,
                    orderCount: saleItems.length,
                    totalQuantity: totalQuantity,
                }
            )
        }
        setDisplayData(finalData)
        setGotReport(true)
        setLoading(false)
    }

    const prepareItemReportData = (saleOrders, saleOrderItems, inventoryItems) => {
        let finalData = [];
        let title = [];

        title.push("Sl.No")
        title.push("School Name");

        for (const item of inventoryItems) {
            title.push(item.attributes.name)
        }


        setDisplayTitles(title);

        let count = 1

        for (const school of props.schools) {

            let schoolData = [];

            schoolData.push(count);
            count++
            schoolData.push(school.attributes.Name);

            let schoolOrders = []
            let schoolOrderIds = []
            for (const so of saleOrders) {
                if (so.attributes.buyerId == school.id) {
                    schoolOrders.push(so)
                    schoolOrderIds.push(so.id);
                }
            }

            let schoolItems = []
            for (const si of saleOrderItems) {
                if (schoolOrderIds.includes(si.attributes.saleOrderId)) {
                    schoolItems.push(si);
                }
            }


            for (const item of inventoryItems) {
                let count = 0
                for (const si of schoolItems) {

                    if (si.attributes.itemId == item.id) {
                        count = count + si.attributes.quantity
                    }
                }

                schoolData.push(count)

            }

            finalData.push(schoolData)
        }

        setDisplayData(finalData)
        setGotReport(true)
        setLoading(false)
    }
    
    const downloadSchoolItemReport = (data) => {

        let finalData = [];
        let title = [];
        let fileName = "";
        let sheetName = "";
        if (reportType == 1) {


            fileName = `Sale_Order_Item_Report.xlsx`;
            sheetName = "Sale_Order_Item_Report";
            finalData.push([
                `Report : Sale Order Item Report`,
            ]);
           {franchiseMatch && finalData.push([`School Name: ${selectedSchool.label}`])}
           {match && finalData.push([`School Name: ${props.selectedSchool && props.selectedSchool.attributes.Name}`])}
            finalData.push([`Order Status: ${getStatusName(statusType)}`])
            finalData.push([`Number of orders: ${saleOrders.length}`]);

            title.push("Sl.No");
            title.push("Item Name");
            title.push("Number of orders");
            title.push("Total Item count");

            finalData.push(title);

            let slNo = 1;
            for (const item of data) {
                finalData.push([
                    slNo,
                    item.itemName,
                    item.orderCount,
                    item.totalQuantity,
                ]);
                slNo++;
            }
        }

        if (reportType == 2) {

            fileName = `Sale_Order_School_Item_Report.xlsx`;
            sheetName = "Sale_Order_Item_Report";

            finalData.push([`School wise item report`]);
            finalData.push(["Intervel " + Moment(selectedStartDate).format('DD/MM/YYYY') + " to " + Moment(selectedEndDate).format('DD/MM/YYYY')]);
            finalData.push([`Order Status: ${getStatusName(statusType)}`])
            finalData.push([`Number of orders: ${saleOrders.length}`]);

            finalData.push(displayTitles);
            finalData = finalData.concat(data)
        }
        if (reportType == 3) {

            fileName = `Sale_Order_Transcation_Report.xlsx`;
            sheetName = "Sale_Order_Transcation_Report";
            finalData.push([`Report : Sale Order Transcation Report`,]);
            finalData.push([`Order Status: ${getStatusName(statusType)}`])
            finalData.push([`Number of orders: ${saleOrders.length}`]);

            title.push("Sl.No")
            title.push("Sale order");
            title.push("Sale Order Number");
            title.push("Sale Order date");
            title.push("Transaction Id");
            title.push("Amount");
            title.push("Transaction Type");
            title.push("Transaction Status");

            finalData.push(title);

            let slNo = 1;
            for (const item of data) {
                slNo++;
                finalData.push([
                    slNo,
                    item.saleOrder,
                    item.saleOrderNumber,
                    item.date,
                    item.transcationId,
                    item.amount,
                    item.transcationType,
                    item.transactionStatus,
                ]);
            }
        }
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(finalData);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName);
    }
    
    const getStatusName = (statusType) => {
        if (statusType == 0) {
            return "All"
        } else if (statusType == 1) {
            return "Order Placed"
        } else if (statusType == 2) {
            return "Order Accepted"
        } else if (statusType == 3) {
            return "Order Shipped"
        } else if (statusType == 4) {
            return "Order Delivered"
        }
    }


    return (
        <React.Fragment>
            <div className='page-content'>
                <Topbar
                    title={"Inventory Report"}
                    buttonName={false}
                    hideButton={true} />
                <OrderStatus
                    showOrderStatus={showOrderStatus}
                    orderStatusObject={orderStatusObject}
                    saleOrderId={orderStatusObject && orderStatusObject.id}
                    onCloseOrderStatus={() => { setShowOrderStatus(false); setOrderStatusObject(undefined) }}
                />


                <div>
                    {<Card>
                        <CardBody>
                            <Row className="bg-light ">
                                <Col md="3" xs="12" xl="3" lg="3">
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Report Type :</Label>
                                        <select
                                            className="form-control "
                                            value={reportType}
                                            onChange={(e) => {
                                                setReportType(e.target.value);
                                                setDisplayData([])
                                                // gotReport(false)
                                            }}
                                        >
                                            <option value={1}>School Report</option>
                                           {franchiseMatch && <option value={2}>School-Item wise Report</option>}
                                            <option value={3}>Transaction Report</option>
                                            <option value={4}>Items Report</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                {franchiseMatch && reportType == 1 && <Col >
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Select School:</Label>

                                        <Select
                                            value={selectedSchool}
                                            onChange={(v) => {
                                                setSelctedSchool(v)
                                                setDisplayData([])
                                                // gotReport(false)
                                                setSelctedSchoolError(false)
                                            }}
                                            options={allSchools}
                                            className="selectPaymentType"
                                            classNamePrefix="selectPayType"

                                        />
                                    </FormGroup>
                                    {selectedSchoolError && <small className="text-danger ml-2">Please select school</small>}
                                </Col>}
                                {reportType != 4 && <Col md="3" xs="12" xl="3" lg="3">
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Order status :</Label>
                                        <select
                                            className="form-control "
                                            value={statusType}
                                            onChange={(e) => {
                                                setStatusType(e.target.value);
                                                setDisplayData([])
                                                gotReport(false)
                                            }}
                                        >
                                            <option value={0}>All</option>
                                            <option value={1}>Order placed</option>
                                            <option value={2}>Order Accepted</option>
                                            <option value={3}>Order Shipped</option>
                                            <option value={4}>Order Delivered</option>
                                        </select>
                                    </FormGroup>
                                </Col>}
                                {(reportType == 2 || reportType == 3) && <Col>
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>Start Date :</Label>
                                        <DatePicker
                                            selected={selectedStartDate}
                                            onChange={(date) => {
                                                date ? setSelectedStartDate(date) : setSelectedStartDate(new Date());
                                            }}
                                            className="form-control"
                                            placeholderText={"Select Date"}
                                            // startDate={fromDate}
                                            // endDate={toDate}
                                            maxDate={selectedEndDate}
                                            dateFormat="dd/MM/yyyy"
                                            showFullMonthYearPicker
                                        />
                                    </FormGroup>
                                </Col>}
                                {(reportType == 2 || reportType == 3) && <Col >
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label>End Date :</Label>
                                        <DatePicker
                                            selected={selectedEndDate}
                                            onChange={(date) => {
                                                date
                                                    ? setSelectedEndDate(date)
                                                    : setSelectedEndDate(new Date());
                                            }}
                                            className="form-control"
                                            placeholderText={"Select Date"}
                                            // startDate={fromDate}
                                            // endDate={toDate}
                                            minDate={selectedStartDate}
                                            maxDate={new Date()}
                                            dateFormat="dd/MM/yyyy"
                                            showFullMonthYearPicker
                                        />
                                    </FormGroup>
                                </Col>}
                                {<Col md="2" xs="12" xl="2" lg="2">
                                    <div className="mt-2">
                                        <div className="mt-3">
                                            <button
                                                className="btn btn-primary mt-4 mr-2"
                                                onClick={() => {
                                                    if (reportType == 1) {
                                                        let next = true;
                                                        if (!selectedSchool) {
                                                            setSelctedSchoolError(true);
                                                            next = false;
                                                        }
                                                        setLoading(true);
                                                        getInventorySaleOrderReport()
                                                    }  else if(reportType == 4) {
                                                        setShowInventoryItemsReport(true); 
                                                        setLoading(false);
                                                    }  else {
                                                        setLoading(true);
                                                        getInventorySaleOrderReport()
                                                    }
                                                }}
                                            >
                                                Generate
                                            </button>
                                        </div>
                                    </div>
                                </Col>}
                            </Row>
                            <Row className='d-flex justify-content-between m-2'>
                                {displayData.length > 0 && <Col className=' justify-content-start'>
                                    {reportType == 1 && <p className="align-middle mt-2 ml-2 row gy-1 mb-0">{franchiseMatch ?(`School Name : ${selectedSchool ? selectedSchool.label : ""}`) : (`School Name : ${props.selectedSchool && props.selectedSchool.attributes.Name}`)}</p>}
                                    {reportType!=4 && <p className="align-middle mt-2 ml-2 row gy-1 mb-0">{`Order status : ${getStatusName(statusType)}`}</p>}
                                    {reportType!=4 &&<p className="align-middle mt-2 ml-2 row gy-1 mb-0">{`Number of orders: : ${saleOrders.length}`}</p>}
                                </Col>}
                                <Col>
                                    {displayData.length > 0 && <div className="d-flex justify-content-end" >
                                        <button
                                            className="btn btn-info"
                                            onClick={() => {
                                                downloadSchoolItemReport(displayData)
                                            }}
                                        >
                                            <i className="bx bxs-download m-1" />
                                            Download
                                        </button>
                                    </div>}
                                </Col>
                            </Row>
                            {loading && (
                                <Col xs="12">
                                    <div className="text-center py-4">
                                        <Link to="#" className="text-success">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                            Loading....{" "}
                                        </Link>
                                    </div>
                                </Col>
                            )}
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <Table className="table table-striped table-bordered">
                                    {displayData.length > 0 && <Thead>
                                        <Tr>
                                            {displayTitles.map((val2, key) => (
                                                <Th key={key}>{val2}</Th>
                                            ))}
                                        </Tr>
                                    </Thead>}
                                    {reportType == 1 && displayData.length > 0 && <Tbody>
                                        {displayData &&
                                            displayData.map((data, key) => (
                                                <Tr key={key}>
                                                    <Td>{key + 1}</Td>
                                                    <Td>{data.itemName}</Td>
                                                    <Td>{data.orderCount}</Td>
                                                    <Td>{data.totalQuantity}</Td>
                                                </Tr>
                                            ))}
                                    </Tbody>}

                                    {reportType == 2 && displayData.length > 0 && <Tbody>
                                        {displayData &&
                                            displayData.map((rdata, key) => (
                                                <Tr key={key}>
                                                    {rdata.map((item, k2) => (
                                                        <Td key={"2" + k2}>{item}</Td>
                                                    ))}

                                                </Tr>
                                            ))}
                                    </Tbody>}
                                    {reportType == 3 && displayData.length > 0 && <Tbody>
                                        {displayData &&
                                            displayData.map((data, key) => (
                                                <Tr key={key}>
                                                    <Td>{key + 1}</Td>
                                                    <Td>{data.saleOrder}</Td>
                                                    <Td>{data.saleOrderNumber}</Td>
                                                    <Td>{data.date}</Td>
                                                    <Td>{data.transcationId}</Td>
                                                    <Td>{data.amount}</Td>
                                                    <Td>{data.transcationType}</Td>
                                                    <Td>{data.transactionStatus}</Td>
                                                </Tr>
                                            ))}
                                    </Tbody>}
                                </Table>
                            </div>
                            {displayData.length == 0 && !loading && gotReport && reportType !== "4" &&(
                                <h3 className="text-center text-info mt-3">
                                    No Report Found For This Filter
                                </h3>
                            )}
                            {showInventoryItemsReport && reportType == "4" && <InventoryItemsReport inventoryItems={props.inventoryItems} />}
                        </CardBody>
                    </Card>}
                </div>

            </div>
            <div style={{ height: "400px" }}></div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    const { inventorySaleOrders } = state.InventorySaleOrder;
    const { inventorySaleOrderItems } = state.InventorySaleOrderItem;
    const { inventoryItems, inventorySubItems, inventoryMatrixs } = state.InventoryItems;
    const {  inventoryWarehouses, inventoryItemStockMap } = state.InventoryWarehouse;
    return { selectedSchool, inventorySaleOrders, inventorySaleOrderItems, schools, inventoryItems, inventorySubItems, inventoryMatrixs,inventoryItemStockMap,inventoryWarehouses };
}

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getInventoryItemForOwnerId, 
    getInventoryWarehouseForOwnerId, getInventoryStockForWarehouse, 
    getInventorySubItemForItemIds, getInventoryItemMatrixForItemIds
})(saleOrder)));