import React from "react";
import { Redirect } from "react-router-dom";
import Parse from "parse";

//All Apps
import Apps from "../pages/Apps/allApps"
import AppLogin from "../components/CommonForBoth/AppLogin.js";

// // Pages Component
import Chat from "../pages/Chat/Chat";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

import CalendarNew from "../pages/CalendarNew/index";

// //Tasks
import TasksList from "../pages/Tasks/tasks-list";
import TasksKanban from "../pages/Tasks/tasks-kanban";
import TasksCreate from "../pages/Tasks/tasks-create";

// //Projects
import ProjectsGrid from "../pages/Projects/projects-grid";
import ProjectsList from "../pages/Projects/projects-list";
import ProjectsOverview from "../pages/Projects/projects-overview";
import ProjectsCreate from "../pages/Projects/projects-create";

// //Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProductDetail";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";

//Cowin
import Cowin from "../pages/Cowin/index";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list";
import InvoiceDetail from "../pages/Invoices/invoices-detail";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import EmailVerificationPage from "../pages/Authentication/EmailVerificationPage"
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ForgetPwdNew from "../pages/Authentication/ForgetPasswordNew";


import TermsofUse from "../pages/Authentication/TermsofUse";
import RefundPolicy from "../pages/Authentication/RefundPolicy";
import PrivacyPolicy from "../pages/Authentication/PrivacyPolicy";
import Support from "../pages/Authentication/SupportTicket";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Dashboard
import Dashboard from "../pages/DashboardNew/index";
import DashboardSaas from "../pages/Dashboard-saas/index";
import DashboardCrypto from "../pages/Dashboard-crypto/index";

//Crypto
import CryptoWallet from "../pages/Crypto/crypto-wallet";
import CryptoBuySell from "../pages/Crypto/crypto-buy-sell";
import CryptoExchange from "../pages/Crypto/crypto-exchange";
import CryptoLending from "../pages/Crypto/crypto-lending";
import CryptoOrders from "../pages/Crypto/crypto-orders";
import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application";
import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ToastUIChart from "../pages/Charts/ToastUIChart";
import ChartsKnob from "../pages/Charts/charts-knob";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";
import VisitorForm from "../pages/VisitorForm/VisitorForm";
import VisitorForm2 from "../pages/VisitorForm/VisitorForm2";
import StallVisitorForm from "../pages/VisitorForm/StallVisitorForm";
import StallVisitorFormV2 from "../pages/VisitorForm/StallVisitorFormV2";
//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiImageCropper from "../pages/Ui/ui-image-cropper";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesDelay from "../pages/Utility/pages-delay";

import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/contacts-list";
import ContactsProfile from "../pages/Contacts/contacts-profile";

//Staf
import Staff from "../pages/Staff/index";
import TeacherProfilePage from "../pages/Staff/TeacherProfilePage";
import AddTeacher from "../pages/Staff/AddTeacher";
//classes
import AllClasses from "../pages/classes/index";
import Attendence from "../pages/classes/Attendence.js";

//Inventory
import Inventory from "../pages/Inventory/index";
import AllVendor from "../pages/Inventory/Vendor/AllVendor";
import AddVendor from "../pages/Inventory/Vendor/AddVendor"
import AllItems from "../pages/Inventory/Items/AllItems";
import AddItems from "../pages/Inventory/Items/AddItems";
import PurchaseOrder from "../pages/Inventory/Purchase/PurchaseOrder";
import NewPurchaseOrder from "../pages/Inventory/Purchase/NewPurchaseOrder";
import SaleOrder from "../pages/Inventory/Sales/SaleOrder";
import NewSaleOrder from "../pages/Inventory/Sales/NewSaleOrder";
import PrintPurchaseOrder from "../pages/Inventory/Purchase/PrintPurchaseOrder";
import PrintSaleOrder from "../pages/Inventory/Sales/PrintSaleOrder";
import ItemGroups from "../pages/Inventory/ItemGroups/AllItemGroups";
import NewItemGroup from "../pages/Inventory/ItemGroups/NewItemGroup";
import InventoryProducts from "../pages/Inventory/E-commerce/Products";
import InventoryProductDetails from "../pages/Inventory/E-commerce/ProductDetails";
import InventoryCart from "../pages/Inventory/cart";
import InventoryReports from "../pages/Inventory/InventoryReports";
import SaleOrderSummary from "../pages/Inventory/Sales/saleOrderSummary.js";
import AllCategories from '../pages/Inventory/InventoryCategories/AllCategories.js'

//Help-desk ticket
import NewTicket from "../pages/Help-Desk/NewTicket";
import AllTickets from "../pages/Help-Desk/AllTickets";
import TicketDetails from "../pages/Help-Desk/TicketDetails";


//Student
import Student from "../pages/Student/index";
import AddNewKid from "../pages/Student/addNewKid";
import StudentIdCard from "../pages/Student/StudentIDCard";
import StudentImport from "../pages/StudentImport/index";
import AcademyStudentImport from "../pages/StudentImport/AcademyStudentImport";
import EscortIdCard from "../pages/Student/EscortIdCard";
import BonafideCertificate from "../pages/Certificates/bonafideCertificate";
import Certificate from "../pages/Certificates/certificate";

//Certificates
import Certificates from "../pages/Certificates/index";
import NewCertificate from "../pages/Certificates/NewCertificates/index"

//Student View
import StudentView from "../pages/studentView/index";
import SchoolDocumentsView from "../pages/SchoolDocuments/SchoolDocuments";
import StudentEditView from "../pages/studentView/editIndex";

//School
import School from "../pages/SchoolDetails/index";
import EditSchool from "../pages/SchoolDetails/EditSchool";
import EditEnquiryForm from "../pages/Enquiry/EditEnquiryForm";
import EditAdmissionForm from "../pages/Admissions/EditAdmissionForm";
import AdmissionFormViewOnly from "../pages/Admissions/AdmissionFormViewOnly";
import PaymentGateway from "../pages/PaymentGateway/PaymentGateway";
import ExpenseIndex from "../pages/ExpenseManagement/ExpenseIndex"
import SubscriptionExpiry from "../pages/SchoolDetails/SubscriptionExpiry";

//Birthday
import BirthdayWish from "../pages/BirthdayWish/Wish"

//Programs
import ProgramDetailPage from "../pages/ProgramDetails/ProgramDetailPage";
import AddPrograms from "../pages/ProgramDetails/AddPrograms";
import ProgramView from "../pages/ProgramDetails/ProgramView";
import ProgramAnalytics from "../pages/ProgramDetails/ProgramAnalytics"
import TripView from "../pages/Trips/TripView";


import SchoolRating from "../pages/SchoolRating/index";
import SchoolRatingCertificate from "../pages/SchoolRating/SchoolRatingCertificate";

//Group
import AddGroups from "../pages/GroupDetails/AddGroups";
import GroupDetailPage from "../pages/GroupDetails/GroupDetailPage";
import GroupDetails from "../pages/GroupDetails/GroupDetails";

//Archive
import ArchiveDetailPage from "../pages/Archive/ArchiveDetailPage";
import Archives from "../pages/Archive/index.js";
import ExitFeedBackForm from "../pages/Archive/KidExitFeedbackForm";
//CCTV
import AddCamera from "../pages/CCTVDetails/AddCamera";
import CameraDetailPage from "../pages/CCTVDetails/CameraDetailPage";

import CameraView from "../pages/CCTVDetails/CameraView";

//Forms
import FormDetailPage from "../pages/FormsNew/FormDetailPage";
import AddForm from "../pages/FormsNew/AddForm";
import FormView from "../pages/FormsNew/FormView";
import PrintFormView from "../pages/FormsNew/PrintFormView";
import EditCustomForm from "../pages/FormsNew/EditCustomForm";

//Exams
import AddExam from "../pages/Exams/AddExam";
import ExamDetailPage from "../pages/Exams/ExamDetailPage";
import EditCustomExam from "../pages/Exams/EditCustomExam";
import ExamView from "../pages/Exams/ExamView";
import PrintExamView from "../pages/Exams/PrintExamView";

//Help HelpVideo
import HelpVideo from "../pages/HelpVidio/index";

//Gallery
import Gallery from "../pages/Gallery/index";

//Notice Board
import NoticeBoard from "../pages/NoticeBoard/index.js";
import AddNotice from "../pages/NoticeBoard/AddNotice.js";

//Leave Module
import LeaveModule from "../pages/LeaveModule/index.js";
import LeaveDetails from "../pages/LeaveModule/LeaveDetails.js";
import LeaveTemplate from "../pages/LeaveModule/LeaveTemplate.js";
import leavesList from "../pages/LeaveModule/leavesList.js";
import ApplyLeave from "../pages/LeaveModule/ApplyLeave.js";
import PayrollTemplate from "../pages/LeaveModule/PayrollTemplate.js";
import MasterPayRoll from "../pages/LeaveModule/MasterPayRoll.js"
import PayRollCategories from "../pages/LeaveModule/PayRollCategories.js";
import LeavesAssigning from "../pages/LeaveModule/LeavesAssigning.js";
import NewPayrollTemplate from "../pages/LeaveModule/NewPayrollTemplate.js";
import SetupMasterPayroll from "../pages/LeaveModule/SetUpMasterPayroll.js";
import PayRoll from "../pages/LeaveModule/PayRoll.js"
import SetUpPayroll from "../pages/LeaveModule/SetUpPayroll.js";
import PaySlip from "../pages/LeaveModule/PaySlip.js";
import holidayList from "../pages/LeaveModule/holidayList.js";


//Transfer Certificate
import TransferCertificateForm from "../pages/TransferCertificate/TransferCertificateForm";
import TransferCertificatePrint from "../pages/TransferCertificate/TransferCertificatePrint";


//institute and school creation

import Registration from "../pages/Registration/index";

//Enquiry
import Enquiry from "../pages/Enquiry/index";
import EnquiryProfile from "../pages/Enquiry/EnquiryProfile";
import EnquiryTasks from "../pages/Enquiry/EnquiryTasks.js";
import EnquiryPoster from "../pages/Enquiry/EnquiryPoster";
import VisitorPoster from "../pages/VisitorForm/VisitorPoster";
import CheckInPoster from "../pages/SchoolDetails/CheckInPoster";

import VisitorManagement from "../pages/VisitorForm/VisitorManagement";

//Admissions
import Admissions from "../pages/Admissions/index";

import Messaging from "../pages/Messaging/Messaging";

//payment
import Payment from "../pages/payment/index";
import PrintInvoice from "../pages/payment/printInvoice";
import OnlineTransaction from "../pages/payment/onlineTransaction";
import DownloadReceipt from "../pages/payment/DownloadReceipt";
import Gpay from "../pages/payment/GPay";

//addAddress
import AddAddress from "../pages/payment/AddAddress";

//LMS
import LMS from "../pages/LMS/index";

import CycleYears from "../pages/LMS/CycleYears";
import CategoryList from "../pages/LMS/CategoryList";
import SubcategoryList from "../pages/LMS/SubcategoryList";

import ActivityList from "../pages/LMS/ActivityList";
import ActivityContentList from "../pages/LMS/ActivityContentList";
import ActivityContentList2 from "../pages/LMS/ActivityContentList2";
import CurriculumAccess from "../pages/LMS/Access/CurriculumAccess";
import SyllabusAccess from "../pages/LMS/Access/SyllabusAccess";
import LearningCategoryAccess from "../pages/LMS/Access/LearningCategoryAccess";
import LearningSession from "../pages/LMS/Publish/LearningSession"
import LearningSessionDetails from "../pages/LMS/Publish/LearningSessionDetails"

//Franchise
import FranchiseDashboard from "../pages/Franchise/FranchiseDashboard"
import FranchiseBranches from "../pages/Franchise/FranchiseBranches";
import FranchiseAdmins from "../pages/Franchise/FranchiseAdmins";
import FranchiseEnquiry from "../pages/Franchise/enquiry";

//reports
import ReportList from "../pages/reports/reportList";
import ReportListFranchise from "../pages/reports/franchise/reportListFranchise";
import MonthlyReports from "../pages/MonthlyReports/MonthlyReport";
import MonthlyReportView from "../pages/MonthlyReports/MonthlyReportSession";

//LearningAnalytics
import LearningAnalytics from "../pages/LearningAnalytics/LearningAnalytics";
import LearningMonthlyReport from "../pages/LearningAnalytics/LearningMonthlyReport";

import ConsolidatedMonthlyReport from "../pages/MonthlyReports/ConsolidatedMonthlyReport";

//Job Notification
import NewJob from "../pages/JobNotifications/newJob"
import JobList from "../pages/JobNotifications/jobList"
import JobApplications from "../pages/JobNotifications/jobApplications"


//allApps
import allApps from "../components/allApps";
import CustomReportsView from "../pages/MonthlyReports/CustomReportsView";
import FranchiseSettings from "../pages/Franchise/FranchiseSettings";

import DiaryView from "../pages/DaycareDiary/DiaryView";
import CovidVaccine from "../pages/CovidVaccine/index";
import CovidDashboard from "../pages/CovidVaccine/CovidDashboard";
import CovidSOP from "../pages/CovidVaccine/CovidSOP";
import TemperatureView from "../pages/TemperatureView/TemperatureView";




import lilTrianglePricing from "../pages/lilTrianglePricing/index";
import CourseAdmin from "../pages/Course/index";
import CreateCourse from "../pages/Course/CreateCourse/CreateCourse";
import CreateSubscribers from "../pages/Course/CourseSubscribers";
import CourseRequests from "../pages/Course/CourseRequests";
import CourseAssessments from "../pages/Course/CourseAssessments";
import FeedbackForms from "../pages/Course/FeedbackForms/FeedbackForms";
import CourseFormSession from "../pages/Course/FeedbackForms/CourseFormSession";
import BatchList from "../pages/Course/Batches/BatchList";
import BatchDetails from "../pages/Course/Batches/BatchDetails";
import CourseReports from "../pages/Course/Reports/CourseReports";
import ZoomAuth from "../pages/Zoom/ZoomAuth";
import DocView from "../pages/Course/DocView";

import AllAnnouncements from "../pages/Course/Announcements/AllAnnouncements";
import CreateAnnouncement from "../pages/Course/Announcements/CreateAnnouncement";

import StaffIDCard from "../pages/Staff/StaffIDCard";
import TripIndex from "../pages/Trips/TripIndex";
import TripAdd from "../pages/Trips/TripAdd";

import MessageQueueList from "../pages/Messaging/MessageQueueList";
import CorporatePartners from "../pages/CorporatePartners/index";
import CorporatePartnersDetails from "../pages/CorporatePartners/corporatePartnerDetails";
import Wish from "../pages/BirthdayWish/Wish";

import schoolSettings from "../pages/Tools/schoolSettings";

import schoolList from "../pages/Tools/instituteList"
import FranchiseList from "../pages/Tools/franchiseList"
import SchoolSubscription from "../pages/Tools/schoolSubscription";
import SchoolAnalytics from "../pages/Tools/schoolAnalyticsList";
import SchoolCameraDetails from "../pages/Tools/schoolCameraDetails";
import ToolsFeeAnalytics from "../pages/Tools/FeeManagment/ToolsFeeIndex.js";
import whatsAppTemplates from "../pages/Tools/whatsAppTemplates.js";

import AddCard from '../pages/Cards/addCard';
import AllCards from "../pages/Cards/allcards";
import Blog from '../pages/Cards/blog'
import BlogList from '../pages/Cards/blogList';
import AddBlog from '../pages/Cards/addBlog';
import AllExams from '../pages/MarksCard/AllExams';
import NewExam from '../pages/MarksCard/NewExams';
import MarksView from '../pages/MarksCard/MarksView.js';
import MarksCard from '../pages/MarksCard/MarkCard.js';
import ExamCertificate from '../pages/MarksCard/ExamCertificate.js';
import ExamHallTicket from '../pages/MarksCard/HallTicket.js';


import TransactionSuccess from "../pages/payment/transcationSuccess"
import { components } from "react-select/dist/react-select.cjs.prod";
import ExamResult from "../pages/MarksCard/Result.js";
import AppPoster from "../pages/DashboardNew/AppPoster.js";
import ProfileDisabledPage from "../pages/Registration/ProfileDisabledPage.js";
import FranchiseBranchEnquiryList from "../pages/Franchise/BranchEnquiry/FranchiseBranchEnquiryList.js"
import FranchiseAddEnquiry from "../pages/Franchise/BranchEnquiry/FranchiseAddEnquiry.js"
import FranchiseProfile from "../pages/Franchise/BranchEnquiry/FranchiseProfile.js"
import FranchisePoster from "../pages/Franchise/BranchEnquiry/FranchisePoster.js"

const userRoutes = [
  { path: "/message-franchise/:id", component: MessageQueueList },
  { path: "/message-school/:id", component: MessageQueueList },
  { path: "/dashboard", component: Dashboard },
  { path: "/dashboard/:id", component: Dashboard },
  { path: "/dashboard-saas", component: DashboardSaas },
  { path: "/dashboard-crypto", component: DashboardCrypto },

  //All Apps
  { path: "/all-apps/:id", component: Apps },

  //Crypto
  { path: "/crypto-wallet", component: CryptoWallet },
  { path: "/crypto-buy-sell", component: CryptoBuySell },
  { path: "/crypto-exchange", component: CryptoExchange },
  { path: "/crypto-lending", component: CryptoLending },
  { path: "/crypto-orders", component: CryptoOrders },
  { path: "/crypto-kyc-application", component: CryptoKYCApplication },

  //chat
  { path: "/chat", component: Chat },

  // //calendar
  { path: "/calendarDemo", component: Calendar },

  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/profile/:id", component: UserProfile },

  //Ecommerce
  { path: "/ecommerce-products", component: EcommerceProducts },
  { path: "/ecommerce-product-detail", component: EcommerceProductDetail },
  { path: "/ecommerce-orders", component: EcommerceOrders },
  { path: "/ecommerce-customers", component: EcommerceCustomers },
  { path: "/ecommerce-cart", component: EcommerceCart },
  { path: "/ecommerce-checkout", component: EcommerceCheckout },
  { path: "/ecommerce-shops", component: EcommerceShops },
  { path: "/ecommerce-add-product", component: EcommerceAddProduct },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },

  //Invoices
  { path: "/invoices-list", component: InvoicesList },
  { path: "/invoices-detail", component: InvoiceDetail },

  // Tasks
  { path: "/tasks-list", component: TasksList },
  { path: "/tasks-kanban", component: TasksKanban },
  { path: "/tasks-create", component: TasksCreate },

  //Projects
  { path: "/projects-grid", component: ProjectsGrid },
  { path: "/projects-list", component: ProjectsList },
  { path: "/projects-overview", component: ProjectsOverview },
  { path: "/projects-create", component: ProjectsCreate },

  // Contacts
  { path: "/contacts-grid", component: ContactsGrid },
  { path: "/contacts-list", component: ContactsList },
  { path: "/contacts-profile", component: ContactsProfile },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/tui-charts", component: ToastUIChart },
  { path: "/charts-knob", component: ChartsKnob },

  // Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-image-cropper", component: UiImageCropper },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  //Covid Vaccine
  { path: "/covidvaccine/:schoolId/:id", component: CovidVaccine },
  { path: "/coviddashboard/:id", component: CovidDashboard },



  //Staf
  { path: "/staff/:id", component: Staff },
  { path: "/staffprofile/:schoolId/:id", component: TeacherProfilePage },
  { path: "/editstaff/:id", component: AddTeacher },
  { path: "/editstaff/:schoolId/:id", component: AddTeacher },
  { path: "/addstaff/:id", component: AddTeacher },

  { path: "/coordinators/:id", component: Staff },
  { path: "/coordinators-profile/:schoolId/:id", component: TeacherProfilePage },
  { path: "/franchise-adminprofile/:franchiseId/:id", component: TeacherProfilePage },
  { path: "/editCoordinator/:id", component: AddTeacher },
  { path: "/editCoordinator/:schoolId/:id", component: AddTeacher },
  { path: "/addCoordinator/:id", component: AddTeacher },

  //Inventory
  { path: "/inventory-management/:id", component: Inventory },
  { path: "/inventory-vendor/:id", component: AllVendor },
  { path: "/add-inventory-vendor/:schoolId", component: AddVendor },
  { path: "/edit-inventory-vendor/:schoolId/:vendorId", component: AddVendor },
  { path: "/inventory-items/:id", component: AllItems },
  { path: "/add-inventory-items/:schoolId", component: AddItems },
  { path: "/edit-inventory-items/:schoolId/:itemId", component: AddItems },
  { path: "/purchase-order/:id", component: PurchaseOrder },
  { path: "/new-purchase/:schoolId", component: NewPurchaseOrder },
  { path: "/sale-order/:id", component: SaleOrder },
  { path: "/new-sale/:schoolId", component: NewSaleOrder },
  { path: "/inventory-sale-order-details/:schoolId/:saleOrderId", component: NewSaleOrder },
  { path: "/sale-order-summary/:schoolId/:saleOrderId", component: SaleOrderSummary },
  { path: "/edit-inventory-purchase-order/:schoolId/:purchaseOrderId", component: NewPurchaseOrder },
  { path: "/print-purchase-order/:schoolId/:purchaseOrderId", component: PrintPurchaseOrder },
  { path: "/print-sale-order/:schoolId/:saleOrderId", component: PrintSaleOrder },
  { path: "/inventory-item-group/:id", component: ItemGroups },
  { path: "/franchise-inventory-item-group/:franchiseId", component: ItemGroups },
  { path: "/new-items-group/:schoolId", component: NewItemGroup },
  { path: "/edit-inventory-items-group/:schoolId/:kitId", component: NewItemGroup },
  { path: "/franchise-new-items-group/:franchiseId", component: NewItemGroup },
  { path: "/franchise-edit-inventory-items-group/:franchiseId/:kitId", component: NewItemGroup },
  { path: "/franchise-inventory-management/:franchiseId", component: Inventory },
  { path: "/franchise-inventory-items/:franchiseId", component: AllItems },
  { path: "/franchise-add-items/:franchiseId", component: AddItems },
  { path: "/franchise-inventory-vendor/:franchiseId", component: AllVendor },
  { path: "/franchise-add-vendor/:franchiseId", component: AddVendor },
  { path: "/franchise-edit-inventory-vendor/:franchiseId/:vendorId", component: AddVendor },
  { path: "/franchise-edit-inventory-items/:franchiseId/:itemId", component: AddItems },
  { path: "/franchise-purchase-order/:franchiseId", component: PurchaseOrder },
  { path: "/franchise-new-purchase/:franchiseId", component: NewPurchaseOrder },
  { path: "/franchise-edit-purchase-order/:franchiseId/:purchaseOrderId", component: NewPurchaseOrder },
  { path: "/franchise-new-sale/:franchiseId", component: NewSaleOrder },
  { path: "/franchise-sale-order-details/:franchiseId/:saleOrderId", component: NewSaleOrder },
  { path: "/franchise-sale-order-summary/:franchiseId/:saleOrderId", component: SaleOrderSummary },
  { path: "/franchise-sale-order/:franchiseId", component: SaleOrder },
  { path: "/franchise-print-purchase-order/:franchiseId/:purchaseOrderId", component: PrintPurchaseOrder },
  { path: "/franchise-print-sale-order/:franchiseId/:saleOrderId", component: PrintSaleOrder },
  { path: "/inventory-products/:schoolId", component: InventoryProducts },
  { path: "/inventory-product-details/:type/:schoolId/:id", component: InventoryProductDetails },
  { path: "/school-cart/:schoolId/", component: InventoryCart },
  { path: "/franchise-inventory-report/:franchiseId", component: InventoryReports },
  { path: "/inventory-report/:schoolId", component: InventoryReports },
  { path: "/inventory-categories/:id", component: AllCategories },
  { path: "/franchise-inventory-categories/:franchiseId", component: AllCategories },

  // Help-desk tickets
  { path: "/all-tickets/:id", component: AllTickets },
  { path: "/new-ticket/:schoolId", component: NewTicket },
  { path: "/ticket-details/:schoolId/:ticketId", component: TicketDetails },
  { path: "/franchise-all-tickets/:franchiseId", component: AllTickets },
  { path: "/franchise-new-ticket/:franchiseId", component: NewTicket },
  { path: "/franchise-ticket-details/:schoolId/:ticketId", component: TicketDetails },

  //Calendar
  { path: "/calendar/:id", component: CalendarNew },
  //classlist
  { path: "/allClasses/:id", component: AllClasses },
  { path: "/attendence/:type/:schoolId/:classId", component: Attendence },


  //Student
  { path: "/student/:id", component: Student },
  { path: "/classroom/:id/:classId", component: Student },
  { path: "/studentProfile/:schoolId/:id", component: StudentView },
  { path: "/franchise-school-documents/:schoolId/:id", component: SchoolDocumentsView },
  { path: "/studentEdit/:schoolId/:id", component: StudentEditView },
  { path: "/addNewKid/:id", component: AddNewKid },
  { path: "/addNewKid/:id/:classroomId", component: AddNewKid },
  { path: "/student-import/:schoolId", component: StudentImport },

  //School
  { path: "/school/:id", component: School },
  { path: "/school/:id/:cycles", component: School },
  { path: "/editschool/:id", component: EditSchool },
  { path: "/school-subscription/:id", component: SubscriptionExpiry },
  { path: "/application-subscription/:franchiseId", component: SubscriptionExpiry },

  { path: "/schoolratinginside/:id", component: SchoolRating },

  //Groups
  { path: "/addgroup/:id", component: AddGroups },
  { path: "/editgroup/:schoolId/:id", component: AddGroups },
  { path: "/group-details/:schoolId/:id", component: GroupDetails },
  { path: "/groupdetail/:id", component: GroupDetailPage },

  //Programs
  { path: "/programdetail/:id", component: ProgramDetailPage },
  { path: "/addprogram/:id", component: AddPrograms },
  { path: "/editprogram/:schoolId/:groupId/:id", component: AddPrograms },
  { path: "/editprogram/:schoolId/:programId/:groupId/:id", component: AddPrograms },
  { path: "/editprogram/:schoolId/:programId/:id", component: AddPrograms },
  { path: "/programview/:schoolId/:programId/:id", component: ProgramView },
  { path: "/programAnalytics/:schoolId/:programId/:id", component: ProgramAnalytics },

  { path: "/trip-view/:schoolId/:tripId/:id", component: TripView },

  //Archive

  { path: "/archivedetailstudent/:id", component: ArchiveDetailPage },
  { path: "/archivedetailstaff/:id", component: ArchiveDetailPage },
  {
    path: "/archivedetail/:id/:groupId/:groupName",
    component: ArchiveDetailPage,
  },
  { path: "/archives/:id", component: Archives },

  //CCTV
  { path: "/addcamera/:id", component: AddCamera },
  { path: "/cameradetail/:id", component: CameraDetailPage },
  { path: "/triplist/:id", component: TripIndex },
  { path: "/tripAdd/:id", component: TripAdd },
  { path: "/editcamera/:schoolId/:groupId", component: AddCamera },
  { path: "/cameraview", component: CameraView },


  //Custom Forms
  { path: "/formview/:schoolId/:formId", component: FormView },
  { path: "/franchise-formview/:franchiseId/:formId", component: FormView },

  { path: "/printformview/:schoolId/:formId/:formName/:id", component: PrintFormView, },
  { path: "/franchise-printformview/:franchiseId/:formId/:formName/:id", component: PrintFormView, },

  { path: "/forms/:schoolId", component: FormDetailPage },
  { path: "/franchise-forms/:franchiseId", component: FormDetailPage },

  { path: "/addform/:schoolId/:id", component: AddForm },
  { path: "/franchise-addform/:franchiseId/:id", component: AddForm },

  { path: "/editcustomform/:schoolId/:formId", component: EditCustomForm },
  { path: "/fill-form/:type/:ownerId/:formId", component: EditCustomForm },


  //Exams
  { path: "/examview/:schoolId/:id", component: ExamView },
  { path: "/printexamview/:schoolId/:examId/:id", component: PrintExamView },

  //HelpHelpVideo
  { path: "/help-videos/:id", component: HelpVideo },

  //Gallery
  { path: "/gallery/:id", component: Gallery },
  { path: "/gallery/:id/:classroomId", component: Gallery },

  //Notice Board
  { path: "/noticeboard/:id", component: NoticeBoard },
  { path: "/add-notice/:id", component: AddNotice },
  { path: "/add-notice/:id/:classId", component: AddNotice },

  //Leave Module 
  { path: "/payroll-leave-dashboard/:id", component: LeaveModule },
  { path: "/leave-Details/:schoolId", component: LeaveDetails },
  { path: "/leave-Details/:franchiseId", component: LeaveDetails },
  { path: "/leave-policies/:schoolId", component: LeaveTemplate },
  { path: "/franchise-leave-policies/:franchiseId", component: LeaveTemplate },
  { path: "/leaves/:schoolId", component: leavesList },
  { path: "/leaves/:franchiseId", component: leavesList },
  { path: "/apply-leave/:schoolId", component: ApplyLeave },
  { path: "/payroll-templates/:schoolId", component: PayrollTemplate },
  { path: "/master-payroll/:schoolId", component: MasterPayRoll },
  { path: "/payroll-categories/:schoolId", component: PayRollCategories },
  { path: "/policies-assigning/:schoolId", component: LeavesAssigning },
  { path: "/payroll-new-template/:schoolId", component: NewPayrollTemplate },
  { path: "/setup-master-payroll/:schoolId/:teacherId", component: SetupMasterPayroll },
  { path: "/payroll/:schoolId", component: PayRoll },
  { path: "/setup-payroll/:schoolId/:teacherId/:date", component: SetUpPayroll },
  { path: "/payslip/:schoolId/:teacherId/:payslipId", component: PaySlip },
  { path: "/policies-assigning/:schoolId", component: LeavesAssigning},
  { path: "/payroll-new-template/:schoolId", component: NewPayrollTemplate},
  { path: "/setup-payroll/:schoolId/:teacherId", component: SetUpPayroll},
  { path: "/holiday-list/:schoolId", component: holidayList},

  //Enquiry
  { path: "/enquiry/:id", component: Enquiry },
  { path: "/enquiryprofile/:schoolId/:id", component: EnquiryProfile },
  { path: "/editadmissioninside/:schoolId/:id", component: EditAdmissionForm },
  { path: "/editenquiryinside/:id", component: EditEnquiryForm },
  { path: "/enquiry-tasks/:id", component: EnquiryTasks },
  { path: "/franchise-enquiry/:franchiseId", component: FranchiseBranchEnquiryList },
  { path: "/franchise-add-enquiry/:franchiseId", component: FranchiseAddEnquiry },
  { path: "/franchiseprofile/:franchiseId/:id", component: FranchiseProfile },

  { path: "/paymentgatewaykyc/:id", component: PaymentGateway },

  { path: "/exams/:id", component: ExamDetailPage },
  { path: "/addExam/:schoolId/:id", component: AddExam },

  //Admissions
  { path: "/admissions/:id", component: Admissions },

  //payment
  { path: "/payments/:id", component: Payment },
  // { path: '/invoice/:invoiceId', component: PrintInvoice },

  //Expense Management
  { path: "/expenses/:id", component: ExpenseIndex },

  { path: "/Gpay", component: Gpay },

  //LMS(CHILD DEVELOPMENT)

  { path: "/lms-curriculum/:id", component: LMS },
  { path: "/lms-syllabus/:schoolId/:curriculumId", component: CycleYears },
  {
    path: "/lms-subject/:schoolId/:curriculumId/:cycleId",
    component: CategoryList,
  },
  {
    path: "/lms-chapter/:schoolId/:curriculumId/:cycleId/:subjectId",
    component: SubcategoryList,
  },

  { path: "/activities/:id", component: ActivityList },
  { path: "/lms-chapter-content/:school/:id", component: ActivityContentList },
  {
    path: "/lms-chapter-content/:school/:curriculumId/:cycleId/:id",
    component: ActivityContentList,
  },
  {
    path: "/lms-chapter-content2/:school/:curriculumId/:cycleId/:id",
    component: ActivityContentList2,
  },
  { path: "/curriculum-access/:franchiseId/:curriculumId", component: CurriculumAccess },
  { path: "/syllabus-access/:franchiseId/:curriculumId/:scycleId", component: SyllabusAccess },
  { path: "/learning-category-access/:franchiseId/:curriculumId/:cycleId/:subjectId", component: LearningCategoryAccess },
  { path: "/learning-session/:schoolId", component: LearningSession },
  { path: "/learning-session-details/:schoolId/:assesment/:sessionId", component: LearningSessionDetails },
  { path: "/learning-session-details/:schoolId/:homeLearning/:sessionId", component: LearningSessionDetails },

  //Franchise
  { path: "/franchise/:id", component: FranchiseDashboard },
  { path: "/all-branches/:id", component: FranchiseBranches },
  { path: "/admins", component: FranchiseAdmins },

  //reports
  { path: "/reports/:id", component: ReportList },
  { path: "/franchise-reports", component: ReportListFranchise },
  { path: "/franchise-reports/:franchiseId", component: ReportListFranchise },
  { path: "/reports-staff/:id", component: ReportList },
  { path: "/reports-daycare/:id", component: ReportList },
  { path: "/reports-class/:id", component: ReportList },
  { path: "/reports-classwise/:id", component: ReportList },

  //Transfer Certificate
  { path: "/transfer-certificate/:schoolId/:id", component: TransferCertificateForm },
  { path: "/transfer-certificate-print/:id", component: TransferCertificatePrint },

  //Monthly Reports
  { path: "/monthly-reports/:schoolId/:id", component: MonthlyReports },
  { path: "/monthly-reports/:schoolId/:reportId/:id", component: MonthlyReports },
  { path: "/monthly-reports-view/:schoolId/:id", component: MonthlyReportView },
  { path: "/custom-reports-view/:id", component: CustomReportsView },

  { path: "/franchise-monthly-reports/:id", component: MonthlyReports },
  { path: "/franchise-monthly-reports/:reportId/:id", component: MonthlyReports },
  { path: "/franchise-monthly-reports-view/:id", component: MonthlyReportView },
  { path: "/franchise-custom-reports-view", component: CustomReportsView },
  { path: "/franchise-settings", component: FranchiseSettings },
  { path: "/franchise-consolidated-reports/:id", component: ConsolidatedMonthlyReport },
  { path: "/monthly-consolidated-reports/:schoolId/:id", component: ConsolidatedMonthlyReport },

  { path: "/diaryview/:id", component: DiaryView },

  { path: "/temperatureView/:id", component: TemperatureView },

  //Certificates
  { path: "/certificates/:schoolId", component: Certificates },

  //CourseAdmin

  { path: "/allcourses ", component: CourseAdmin },
  { path: "/allcourses/:id", component: CourseAdmin },

  { path: "/course-content/:schoolId/:id", component: CreateCourse },
  { path: "/course-participants/:schoolId/:id", component: CreateSubscribers },
  { path: "/course-participants/:schoolId/:courseId/:batchId", component: CreateSubscribers },

  { path: "/course-requests/:schoolId/:id", component: CourseRequests },

  { path: "/course-assessments/:schoolId/:courseId/:kidId", component: CourseAssessments },
  { path: "/course-assessments/:schoolId/:courseId/:subId/:batchId/:kidId", component: CourseAssessments },

  { path: '/course-feedback-forms/:schoolId/:courseId', component: FeedbackForms },
  { path: '/course-form-session/:schoolId/:courseId/:formId', component: CourseFormSession },
  { path: '/course-batch/:schoolId/:courseId', component: BatchList },
  { path: '/course-batch-details/:schoolId/:courseId/:batchId', component: BatchDetails },
  { path: '/course-reports/:schoolId', component: CourseReports },

  { path: '/announcements/:schoolId', component: AllAnnouncements },
  { path: '/create-announcements/:schoolId', component: CreateAnnouncement },
  { path: '/announcement-view/:schoolId/:id', component: CreateAnnouncement },

  //addAddress
  { path: "/addAddress", component: AddAddress },

  { path: "/visitor-management/:schoolId", component: VisitorManagement },

  { path: "/add-import/:schoolId", component: AcademyStudentImport },
  { path: "/messaging/:schoolId", component: Messaging },

  //
  { path: "/corporate-partners/:id", component: CorporatePartners },
  { path: "/corporate-partners-details/:id", component: CorporatePartnersDetails },

  { path: "/tools-school-settings/:id", component: schoolSettings },
  { path: "/tools-institute-view/", component: schoolList },
  { path: "/tools-franchise-view/", component: FranchiseList },
  { path: "/tools-schools-subscription/", component: SchoolSubscription },
  { path: "/tools-schools-subscription/:franchiseId", component: SchoolSubscription },
  { path: "/tools-schools-analytics/", component: SchoolAnalytics },
  { path: "/tools-whatsapp-templates/:schoolId", component: whatsAppTemplates },
  { path: "/tools-school-settings-cameraDetails/:id", component: SchoolCameraDetails },
  { path: "/tools-fee-analytics", component: ToolsFeeAnalytics },
  { path: "/new-certificates/:schoolId", component: NewCertificate },
  { path: '/new-certificates/:schoolId/:type', component: NewCertificate },
  { path: "/add-card/:schoolId", component: AddCard },
  { path: "/all-cards/:schoolId", component: AllCards },
  { path: "/blog/:schoolId/:id", component: Blog },
  { path: "/blog-list/:schoolId/:id", component: BlogList },
  { path: "/add-blog/:schoolId/:id", component: AddBlog },

  { path: "/franchise-all-cards/:franchiseId", component: AllCards },
  { path: "/franchise-add-card/:franchiseId", component: AddCard },
  { path: "/franchise-blog/:franchiseId/:id", component: Blog },
  { path: "/franchise-blog-list/:franchiseId/:id", component: BlogList },
  { path: "/franchise-add-blog/:franchiseId/:id", component: AddBlog },

  // Marks Card
  { path: "/all-exams/:schoolId", component: AllExams },
  { path: "/new-exam/:schoolId", component: NewExam },
  { path: "/edit-exam/:schoolId/:examId", component: NewExam },
  { path: "/marks-view/:schoolId/:examId/:groupId", component: MarksView },
  { path: "/edit-marks-view/:schoolId/:examId/:groupId", component: MarksView },

  //Job NOtification
  { path: "/new-job/:schoolId", component: NewJob },
  { path: "/edit-job/:schoolId/:jobId", component: NewJob },
  { path: "/job-list/:schoolId", component: JobList },
  { path: "/job-applications/:schoolId/:jobId", component: JobApplications },
  { path: "/job-applications-all/:schoolId", component: JobApplications },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => (
      <Redirect
        to={Parse.User.current()
          && Parse.User.current().attributes.franchiseId
          && Parse.User.current().attributes.franchiseId.length > 0 ?
          "franchise/" + Parse.User.current().attributes.franchiseId :
          "/dashboard/" + localStorage.getItem("SELECTED_SCHOOL_ID")
        }
      />
    ),
  },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/email-verification/:id", component: EmailVerificationPage },
  { path: "/email-verification", component: EmailVerificationPage },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/forgot-password-verification", component: ForgetPwdNew },
  { path: "/forgot-password-verification/:id", component: ForgetPwdNew },
  { path: "/reset-password-verification/:emailId", component: ForgetPwdNew },
  { path: "/register", component: Register },
  { path: "/termsofUse", component: TermsofUse },
  { path: "/refundPolicy", component: RefundPolicy },
  { path: "/privacyPolicy", component: PrivacyPolicy },
  { path: "/support", component: Support },
  { path: "/apps", component: AppLogin },
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },

  { path: "/enquiry-form/:id", component: EditEnquiryForm },
  { path: "/enquiry-refill/:type/:typeId/:id", component: EditEnquiryForm },

  { path: "/admission-form/:schoolId/:id", component: EditAdmissionForm },
  { path: "/admission-form/:schoolId/:kidId/:id", component: EditAdmissionForm },
  { path: "/admission-form-view/:schoolId/:id", component: AdmissionFormViewOnly },

  { path: "/visitor-form/:schoolId", component: VisitorForm2 },
  { path: "/visitor-form-au/:schoolId", component: VisitorForm2 },
  { path: "/exit-feedback-form/:kidId", component: ExitFeedBackForm },
  { path: "/lilTriangleStall/:event", component: StallVisitorForm },
  { path: "/lilTriangleStallV2/:event", component: StallVisitorFormV2 },
  {
    path: "/franchise-admission-form/:franchiseId/:schoolId/:id",
    component: EditAdmissionForm,
  },
  { path: "/franchise-admission-form/:franchiseId/:schoolId/:kidId/:id", component: EditAdmissionForm },

  {
    path: "/editforminiframe/:schoolId/:formId",
    component: EditCustomForm,
  },


  {
    path: "/editcustomformstudent/:schoolId/:formId/:id",
    component: EditCustomForm,
  },
  {
    path: "/editcoursefeedbackform/:schoolId/:formId/:courseId/:id",
    component: EditCustomForm,
  },

  { path: "/fill-form/:type/:ownerId/:formId", component: EditCustomForm },

  {
    path: "/printformviewoutside/:schoolId/:formId/:formName/:id",
    component: PrintFormView,
  },
  { path: "/editcustomexam/:schoolId/:examId", component: EditCustomExam },
  {
    path: "/editcustomexam/:schoolId/:examId/:userId/:userType/:courseId/:activityId",
    component: EditCustomExam,
  },
  {
    path: "/editcustomexam/:schoolId/:examId/:userId/:userType/:courseId/:activityId/:subscriptionId/:batchId",
    component: EditCustomExam,
  },
  { path: "/letsfighttogether/app", component: Cowin },
  { path: "/letsfighttogether", component: Cowin },
  { path: "/schoolrating/:id", component: SchoolRating },
  { path: "/switching-school/:id", component: PagesDelay },
  { path: "/checkinposter/:id", component: CheckInPoster },
  { path: "/enquiryposter/:id", component: EnquiryPoster },
  { path: "/visitor-poster/:id", component: VisitorPoster },
  { path: "/franchise/enquiry/:id", component: FranchiseEnquiry },
  { path: "/institute/enquiry/:id", component: FranchiseEnquiry },
  {
    path: "/franchise-enquiry-form/:id/:franchiseId",
    component: EditEnquiryForm,
  },
  {
    path: "/enq-form/:client/:schoolId/:id",
    component: EditEnquiryForm,
  },
  {
    path: "/institute-enquiry-form/:id/:instituteId",
    component: EditEnquiryForm,
  },

  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/crypto-ico-landing", component: CryptoIcoLanding },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-forget-pwd", component: ForgetPwd1 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/schoolratingcertificate/:id", component: SchoolRatingCertificate },

  //Institute and school Creation
  { path: "/registration", component: Registration },
  { path: "/new-branch", component: Registration },
  { path: "/invoice/:invoiceId", component: PrintInvoice },
  { path: "/invoice/:invoiceId/:mode", component: PrintInvoice },
  { path: "/download-receipt/:invoiceId", component: DownloadReceipt },
  { path: "/transaction/:invoiceId/:transactonId", component: OnlineTransaction },
  { path: "/transaction/:gateway/:invoiceId/:transactonId", component: OnlineTransaction },

  //allApps
  { path: "/applications", component: allApps },

  //LearningAnalytics
  { path: "/learning-analytics/:schoolId/:id", component: LearningAnalytics },
  { path: "/lms-monthly-progress-report/:schoolId/:kidId", component: LearningMonthlyReport },
  //PRICING
  { path: "/liltriangle-packages", component: lilTrianglePricing },


  { path: "/covidsops/:id", component: CovidSOP },

  { path: "/staffIdcard/:schoolId/:id", component: StaffIDCard },
  { path: "/staffIdcard/:schoolId", component: StaffIDCard },

  { path: "/studentIdcard/:schoolId/:id", component: StudentIdCard },
  { path: "/studentIdcard/:schoolId", component: StudentIdCard },

  { path: "/BirthdayWish", component: Wish },

  { path: "/escortIdcard/:schoolId/:type/:kidId/:id", component: EscortIdCard },

  { path: "/escortIdcard/:schoolId", component: EscortIdCard },

  { path: "/zooom-authentication", component: ZoomAuth },

  { path: "/docview/:id/:name", component: DocView },

  { path: "/bonafide-certificate/:schoolId/:id", component: BonafideCertificate },

  { path: "/bonafide-certificate/:schoolId/:id/:year", component: BonafideCertificate },

  { path: "/custom-certificate/:schoolId", component: Certificate },

  { path: "/transcation-success", component: TransactionSuccess },
  { path: "/transcation-success/:transcationId", component: TransactionSuccess },

  { path: "/marks-card/:schoolId/:examId/:kidId", component: MarksCard },

  { path: "/marks-cards/:schoolId/:examId/:groupId", component: MarksCard },

  { path: "/exam-certificate/:schoolId/:examId/:kidId", component: ExamCertificate },

  { path: "/exam-certificates/:schoolId/:examId/:groupId", component: ExamCertificate },

  { path: "/exam-hall-ticket/:schoolId/:examId/:kidId", component: ExamHallTicket },

  { path: "/exam-hall-tickets/:schoolId/:examId/:groupId", component: ExamHallTicket },

  { path: "/profile-access-warning/:type", component: ProfileDisabledPage },

  { path: "/franchise-branch-enquiry/:franchiseId", component: FranchiseAddEnquiry },

  { path: "/exam-result", component: ExamResult },
  { path: "/app-poster/:id", component: AppPoster },
  { path: "/franchiseposter/:franchiseId", component: FranchisePoster },
  { path: "/download-payslip/:schoolId/:teacherId/:payslipId", component: PaySlip },


];

export { userRoutes, authRoutes };
