import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import logoDark from "../../assets/images/logo-dark.png";
import * as WhiteLable from "../../components/Common/WhiteLable";
const TermsofUse = (props) => {

    const APP_NAME= WhiteLable.getAppName()+" "

    return (
        <React.Fragment>
            
                       
            <div className="text-center m-4">
                <div className=" mb-4">
                    <img src={WhiteLable.getAppLogoWithName()} alt="" height="36" />
                </div>
                                                   
                <h3 className="text-dark"> END USER LICENSE AGREEMENT </h3>
                <CardTitle className="mb-4">
                    PUBLISHED 25 JAN 2016  5 MINUTE READ
                </CardTitle>

                <h5 className="text-dark">IMPORTANT : <span className="text-muted font-14">PLEASE READ THIS LICENSE CAREFULLY BEFORE USING THIS SOFTWARE.</span> </h5>

                {/* <p className="text-muted">Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus tincidunt.</p> */}
                           
                <Card className="justify-content-center">

                    <CardBody>
                        <div>
                            <CardTitle className="text-left">1. LICENSE</CardTitle>
                            <CardSubtitle className="mb-3 text-left">By installing and/or using using {APP_NAME} ("Software") containing this software,
                            you agree that this End User License Agreement (EULA) is a legally binding and valid contract and agree to be bound by it.
                            You agree to abide by the intellectual property laws and all of the terms and conditions of this Agreement.
                            </CardSubtitle>

                            <CardSubtitle className="mb-3 text-left">
                                Your use of {APP_NAME} indicates your acceptance of this license agreement and warranty.
                            </CardSubtitle>

                            <CardSubtitle className="mb-3 text-left">
                                Subject to the terms of this Agreement, LittleTriangle Pvt.Ltd grants to you a limited, non-exclusive, non-transferable license,
                                without right to sub-license, to use {APP_NAME} in accordance with this Agreement and any other written agreement with LittleTriangle Pvt.Ltd.
                                Oyoo Design Studio does not transfer the title of {APP_NAME} to you; the license granted to you is not a sale. This agreement is a binding legal
                                agreement between LittleTriangle Pvt.Ltd and the purchasers or users of {APP_NAME}.
                            </CardSubtitle>

                            <CardSubtitle className="mb-3 text-left">
                                If you do not agree to be bound by this agreement, {APP_NAME} users from your school
                                will be unsubscribe from system and all users need to remove {APP_NAME} app from their devices.
                            </CardSubtitle>
                        </div>

                        <div>
                            <CardTitle className="text-left">2. DISTRIBUTION</CardTitle>
                            <CardSubtitle className="mb-3 text-left">{APP_NAME} and the license herein granted shall not be copied,
                            shared, distributed, re-sold, offered for re-sale, transferred or sub-licensed in whole or in part except
                            that you may make one copy for archive purposes only.
                            </CardSubtitle>
                        </div>

                        <div>
                            <CardTitle className="text-left">3. USER AGREEMENT</CardTitle>
                            <CardSubtitle className="mb-2 text-left"> <strong>3.1 Use </strong>
                            </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">Your license to use {APP_NAME} is limited to the number of licenses
                            purchased by you. You shall not allow others to use, copy or evaluate copies of {APP_NAME}.
                            </CardSubtitle>
                            <CardSubtitle className="mb-2 text-left"> <strong>3.2 Use Restrictions </strong>
                            </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">You shall use {APP_NAME} in compliance with all applicable laws and
                            not for any unlawful purpose.
                            </CardSubtitle>
                            <CardSubtitle className="mb-2 text-left"> <strong>3.3 Copyright Restriction </strong>
                            </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">This Software contains copyrighted material, trade secrets and other
                            proprietary material. You shall not, and shall not attempt to, modify, reverse engineer, disassemble or decompile
                            {APP_NAME} <strong>[APP VERSION].</strong> Nor can you create any derivative works or other works that are based upon or derived
                            from {APP_NAME} in whole or in part.
                            </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">LittleTriangle Pvt.Ltd's name, logo and graphics file that represents {APP_NAME} shall not
                            be used in any way to promote products developed with {APP_NAME}. LittleTriangle Pvt.Ltd retains sole and exclusive ownership of all
                            right, title and interest in and to {APP_NAME} and all Intellectual Property rights relating thereto.
                            </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">Copyright law and international copyright treaty provisions protect all parts of {APP_NAME},
                            products and services. No program, code, part, image, audio sample, or text may be copied or used in any way by the user except as intended
                            within the bounds of the single user program. All rights not expressly granted hereunder are reserved for Oyoo Design Studio.
                            </CardSubtitle>
                            <CardSubtitle className="mb-2 text-left"> <strong>3.4 Limitation of Responsibility</strong>
                            </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">You will indemnify, hold harmless, and defend LittleTriangle Pvt.Ltd, its employees, agents and distributors
                            against any and all claims, proceedings, demand and costs resulting from or in any way connected with your use of LittleTriangle Pvt.Ltd's Software.
                            </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">In no event (including, without limitation, in the event of negligence) will LittleTriangle Pvt.Ltd , its employees,
                            agents or distributors be liable for any consequential, incidental, indirect, special or punitive damages whatsoever (including, without limitation, damages
                            for loss of profits, loss of use, business interruption, loss of information or data, or pecuniary loss), in connection with or arising out of or related to
                            this Agreement, {APP_NAME} or the use or inability to use {APP_NAME} or the furnishing, performance or use of any other matters hereunder whether based upon
                            contract, tort or any other theory including negligence.
                            </CardSubtitle>
                            <CardSubtitle className="mb-2 text-left"> <strong>3.5 Warranties</strong>
                            </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">THIS SOFTWARE AND THE ACCOMPANYING FILES ARE SOLD "AS IS" AND WITHOUT WARRANTIES AS TO PERFORMANCE OR MERCHANTABILITY
                            OR ANY OTHER WARRANTIES WHETHER EXPRESSED OR IMPLIED. THIS DISCLAIMER CONCERNS ALL FILES GENERATED AND EDITED BY {APP_NAME} AS WELL.
                            </CardSubtitle>
                            <CardSubtitle className="mb-2 text-left"> <strong>3.6 Governing Law</strong>
                            </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">This Agreement shall be governed by the law of the <strong> Indian Government </strong> applicable therein. You hereby irrevocably attorn
                            and submit to the non-exclusive jurisdiction of the courts of <strong> Indian Government </strong> therefrom. If any provision shall be considered unlawful, void or otherwise unenforceable,
                             then that provision shall be deemed severable from this License and not affect the validity and enforceability of any other provisions.
                            </CardSubtitle>
                            <CardSubtitle className="mb-2 text-left"> <strong>3.7 Termination</strong>
                            </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">Any failure to comply with the terms and conditions of this Agreement will result in automatic and immediate termination of this license. Upon
                            termination of this license granted herein for any reason, you agree to immediately cease use of {APP_NAME} and destroy all copies of {APP_NAME} supplied under this Agreement.
                            </CardSubtitle>
                            <CardSubtitle className="mb-2 text-left"> <strong>3.8. CONSENT OF DATA SECURITY AND USE OF DATA</strong>
                            </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">{APP_NAME} supports data security of data generated using app. Data privacy is ensured and school specific data will not be shared to any third-party.
                            You agree that LittleTriangle Pvt.Ltd may collect and use information gathered in any manner as part of the product support services provided to you, if any, related to {APP_NAME}. Oyoo Design
                            Studio may also use this information to provide notices, reports to you which may be of use or interest to you.
                            </CardSubtitle>
                            <CardSubtitle className="mb-2 text-left"> <strong>3.9. Maintenance and Support</strong>
                            </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">{APP_NAME} does provide minimal maintenance or support for it but not to the extent that any maintenance or support is required by applicable law.
                            <strong> {APP_NAME} assures support and updates from the date of purchase based on plan subscribed.</strong>
                            </CardSubtitle>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
}

export default TermsofUse;