import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label, FormGroup, Input, Table, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelecetdSchool, getHolidayList,updateHolidayList } from '../../store/actions';
import Moment from "moment";
import DatePicker from "react-datepicker";
import Parse from 'parse';
import toastr from 'toastr';
import SweetAlert from "react-bootstrap-sweetalert";
import * as XLSX from 'xlsx/xlsx.mjs';
import HOLIDAY_JSON from '../../assets/other/holidayList.json'
import SimpleBar from "simplebar-react";


const HolidayList = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/holiday-list/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedYear, setSelectedYear] = useState(2025);
    const [title, setTitle] = useState('');
    const [titleError,setTitleError] = useState(false)
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedHolidayForEdit, setSelectedHolidayForEdit] = useState(null);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [selectedHoliday, setSelectedHoliday] = useState(null);
    const [suggestedHolidays, setSuggestedHolidays] = useState([]);
    const [suggestedModal, setSuggestedModal] = useState(false);
    const [selectedHolidays, setSelectedHolidays] = useState([]);
    
    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            props.getHolidayList(schoolId, 'school');
        }
    }, [schoolId]);


    const saveHolidays = () => {
        setLoading(true);
        
        if (selectedHolidayForEdit) {
            const holidayToUpdate = selectedHolidayForEdit;
            const newHolidayDate = selectedDate;
            const newTitle = title;
    
            const holidayExists = props.holidayList.some(holiday => 
                holiday.id !== holidayToUpdate.id && Moment(holiday.attributes.holidayDate).isSame(newHolidayDate, 'day') && 
                holiday.attributes.title === newTitle
            );
    
            if (holidayExists) {
                toastr.error("Holiday already exists!");
                setLoading(false);
                return;
            }
    
            holidayToUpdate.set("holidayDate", newHolidayDate);
            holidayToUpdate.set("title", newTitle);
            
            holidayToUpdate.save().then((saved) => {
                const updatedHolidayList = props.holidayList.map(holiday => 
                    holiday.id === saved.id ? saved : holiday
                );
                props.updateHolidayList(updatedHolidayList);
                toastr.success("Holiday updated successfully");
                setLoading(false);
                setModalOpen(false);
                setSelectedHolidayForEdit(null);
            }).catch((error) => {
                toastr.error("Error: " + error.message);
                setLoading(false);
            });
        } else {
            let holidaysToSave = selectedHolidays.length ? selectedHolidays : [{ holidayDate: selectedDate, title }];
        
            holidaysToSave = holidaysToSave.filter(h => 
                !props.holidayList.some(holiday => 
                    Moment(holiday.attributes.holidayDate).isSame(h.holidayDate, 'day') && holiday.attributes.title === h.title
                )
            );
        
            if (!holidaysToSave.length) {
                toastr.error("Holiday already exists!");
                setLoading(false);
                return;
            }
        
            const Holiday = Parse.Object.extend("HolidayList");
            let holidayObjects = holidaysToSave.map(h => {
                let holiday = new Holiday();
                holiday.set("holidayDate", h.holidayDate);
                holiday.set("title", h.title);
                holiday.set("ownerId", schoolId);
                holiday.set("ownerType", "school");
                return holiday;
            });
        
            Parse.Object.saveAll(holidayObjects).then((saved) => {
                props.updateHolidayList([...props.holidayList, ...saved]);
                toastr.success("Holidays Added!");
                setLoading(false);
                setModalOpen(false);
                setSuggestedModal(false);
                setSelectedHolidays([]);
            }).catch((error) => toastr.error("Error: " + error.message));
        }
    };
    
    const handleEdit = (holiday) => {
        setSelectedHolidayForEdit(holiday);
        setSelectedDate(new Date(holiday.attributes.holidayDate)); 
        setTitle(holiday.attributes.title);
        setModalOpen(true);
    };

    const handleDeleteHoliday = () => {
        if (!selectedHoliday) return;

        setLoading(true);
        setShowDeleteAlert(false);
        selectedHoliday.set("isDeleted", true);
        selectedHoliday.save().then(
            (result) => {
                // console.log("Result", result)
                props.updateHolidayList([result]);
                setLoading(false);
                setShowDeleteAlert(false);
                setSelectedHoliday(null);
                toastr.success("Holiday deleted successfully");
            },
            (error) => {
                toastr.error('Error deleting holiday: ' + error.message);
                setLoading(false);
            }
        );
    };
    

    // const onClickDownload = () => {
    //     let finalData3 = [];
    //     let fileName = `HolidayListReport_${selectedYear}.xlsx`;

    //     finalData3.push(["HolidayList Report"]);
    //     finalData3.push([`Report for: ${props.isFranchise ? props.selectedSchool.label : props.selectedSchool && props.selectedSchool.attributes.Name}`]);
    //     finalData3.push([`Total Holidays: ${props.holidayList.length}`]);
    //     finalData3.push([]);
        
    //     finalData3.push(["#", "Date", "Title"]);
    
    //     const holidayData = props.holidayList.map((holiday, index) => [
    //         index + 1,
    //         Moment(holiday.attributes.holidayDate).format("DD-MMM-YYYY"),
    //         holiday.attributes.title
    //     ]);
        
    //     finalData3 = finalData3.concat(holidayData);
    //     let wb = XLSX.utils.book_new();
    //     let ws = XLSX.utils.aoa_to_sheet(finalData3);
    //     ws['!cols'] = [
    //         { wch: 10 },
    //         { wch: 15 },
    //         { wch: 25 }
    //     ];
    //     XLSX.utils.book_append_sheet(wb, ws, "Holiday Report");
    //     XLSX.writeFile(wb, fileName);
    // };

    const filteredHolidays = props.holidayList
        .filter(holiday => Moment(holiday.attributes.holidayDate).year() === selectedYear)
        .sort((a, b) => new Date(a.attributes.holidayDate) - new Date(b.attributes.holidayDate)

    );
    
    const handleSuggestedButtonClick = () => {

        let jsonHolidayList=[]
        if(selectedYear=="2025"){
            jsonHolidayList=HOLIDAY_JSON.publicHolidays2025;
        }
        if(selectedYear=="2026"){
            jsonHolidayList=HOLIDAY_JSON.publicHolidays2026;
        }
        const suggested = jsonHolidayList.map(holiday => ({
            attributes: {
                holidayDate: holiday.date,
                title: holiday.name,

            }
        }));
        setSuggestedHolidays(suggested);
    };
    
    return (
        <React.Fragment>
            <div className='page-content'>
                <Row>
                    <Col className="d-flex align-items-start">
                        <div style={{ cursor: "pointer" }} onClick={() => window.history.back()}>
                            <i className="bx bx-chevron-left h1 text-primary"></i>
                        </div>
                        <div className='m-2'>
                            <h4 className="font-size-18">Holiday List</h4>
                        </div>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                    <FormGroup style={{marginRight:'5px'}}>
                            <Input
                                type="select"
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                            >
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                            </Input>
                    </FormGroup>
                    <div className="mt-4 mt-sm-0 float-sm-right form-inline">
                    {/* <button className="btn btn-primary float-right" style={{marginRight:'5px'}} onClick={() => {
                            setSuggestedModal(true);
                            handleSuggestedButtonClick(); 
                    }}>{`Suggested Holidays`}</button> */}

                    </div>
                    <div className="mt-4 mt-sm-0 float-sm-right form-inline" style={{marginRight:'10px'}}>
                        <button className="btn btn-success float-right" onClick={() => {
                            setSelectedHolidayForEdit(null);
                            let today = new Date();
                            let minDate = new Date(selectedYear,0,1,0,0,0)
                            if(selectedYear== today.getFullYear()){
                                setSelectedDate(new Date());
                            }else {
                                setSelectedDate(undefined)
                            }
                            
                            setTitle('');
                            handleSuggestedButtonClick();
                            setSuggestedModal(true);
                        }}>
                            Add Holiday
                        </button>
                    </div>
                    {/* <div className="mt-4 mt-sm-0 float-sm-right form-inline">
                        <button className="btn btn-success float-right" onClick={() => {
                            onClickDownload()
                        }}>
                            Download Report
                        </button>
                    </div> */}
                    </Col>
                </Row>

                <Card>
                    <CardBody>
                        <div className="table-responsive">
                            <Table className="table-centered table-nowrap table-hover">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">{props.t('Date')}</th>
                                        <th scope="col">{props.t('Title')}</th>
                                        <th>{props.t("Edit")}</th>
                                        <th>{props.t("Delete")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredHolidays.map((holiday, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{Moment(holiday.attributes.holidayDate).format("DD-MMM-YYYY")}</td>
                                            <td>{holiday.attributes.title}</td>
                                            <td>
                                                <div className="flex-fill text-primary">
                                                <i 
                                                    className="bx bx-edit-alt text-primary" 
                                                    style={{ cursor: 'pointer', fontSize: '18px' }} 
                                                    onClick={() => handleEdit(holiday)}
                                                ></i>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="flex-fill text-primary">
                                                <i 
                                                    className="bx bxs-trash text-danger ml-3" 
                                                    style={{ cursor: 'pointer', fontSize: '18px' }} 
                                                    onClick={() => {
                                                        setSelectedHoliday(holiday)
                                                        setShowDeleteAlert(true);
                                                    }}
                                                ></i>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </div>

            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
                    {selectedHolidayForEdit ? (`Edit Holiday for ${selectedYear}`) : (`Create Holiday for ${selectedYear}`)}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg='12'>
                            <FormGroup>
                                <Label>Date</Label>
                                <DatePicker
                                    selected={selectedDate}
                                    className="form-control"
                                    placeholder="DD/MM/YYYY"
                                    onChange={(date) => setSelectedDate(date)}
                                    minDate={new Date(selectedYear,0,1,0,0,0)}
                                    maxDate={new Date(selectedYear,11,31,23,59,59)}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg='12'>
                            <FormGroup>
                                <Label>Title</Label>
                                <Input
                                    type="text"
                                    value={title}
                                    placeholder='Enter Title'
                                    onChange={(e) =>  {
                                        setTitle(e.target.value)
                                        setTitleError(false);
                                    }}
                                />
                                {titleError && <small className='text-danger'>Enter Title</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={saveHolidays} disabled ={selectedDate && title ? false : true}>
                        {loading ? <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i> : null}
                        {loading ? props.t('Saving...') : props.t('Save')}
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={suggestedModal} toggle={() => setSuggestedModal(false)}>
                <ModalHeader toggle={() => setSuggestedModal(false)}>
                   {`Add Holiday(s)  for ${selectedYear}`}
                </ModalHeader>
                <ModalBody>
                    {suggestedHolidays.length > 0 ? (
                        <Col lg="12" className="mt-3">
                            <button className='btn btn-success btn-sm' onClick={()=>{setModalOpen(true);
                                setSuggestedModal(false);}}>Add Custom Holiday</button>
                            <h4 className='m-2'>"OR"</h4>
                            <p>Select holidays from the list to add it to your holiday list (optional).</p>
                            <SimpleBar style={{ maxHeight: '400px' }} autoHide={false}>

                                {suggestedHolidays.map((holiday, index) => (
                                    <div key={index}>
                                        <Input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={(e) => {
                                                const isChecked = e.target.checked;
                                                const holidayData = {
                                                    holidayDate: new Date(holiday.attributes.holidayDate),
                                                    title: holiday.attributes.title,
                                                };

                                                if (isChecked) {
                                                    setSelectedHolidays([...selectedHolidays, holidayData]);
                                                } else {
                                                    setSelectedHolidays(selectedHolidays.filter(h => h.title !== holidayData.title));
                                                }
                                            }}
                                        />
                                        <label htmlFor={`holiday-checkbox-${index}`} style={{ marginLeft: '8px' }}>
                                            {holiday.attributes.title} ({Moment(holiday.attributes.holidayDate).format('DD-MMM-YYYY')})
                                        </label>
                                    </div>
                                ))}
                        </SimpleBar>
                        </Col>
                    ):<Col lg="12" className="mt-3">
                        <p>No Suggestions yet</p>
                    </Col>}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => saveHolidays()}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
            {showDeleteAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowDeleteAlert(false);
                    }}
                    onConfirm={() => handleDeleteHoliday()}
                >
                    {"Are you sure you want to delete?"}
                    <br />
                </SweetAlert>
            )}
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School
    const { holidayList } = state.LeaveModule;
    return { selectedSchool, holidayList};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool,getHolidayList, updateHolidayList})(HolidayList)));
