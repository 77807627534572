import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, FormGroup, TabPane, Card, Form, Button, CardText, Nav, NavItem, NavLink, CardBody, Table, UncontrolledTooltip, Badge } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import classnames from 'classnames';
import SweetAlert from "react-bootstrap-sweetalert";
import Parse from 'parse';
import Moment from 'moment';
import Switch from "react-switch";

import {
    setSelecetdSchool, getExamsForSchoolId, deleteExam, saveMarksCard,
} from '../../store/actions';
import { color } from 'echarts';
import school from '../../store/model/school/reducer';

const AllExams = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/all-exams/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;

    const [selectedFilter, setSelectedFilter] = useState("All");
    const filterList = ["All", "Upcoming", "In Progress", "Completed"];
    const [deleteObject, setDeleteObject] = useState(undefined);
    const [showDelete, setShowDelete] = useState(false);
    const [displayExam, setDisplayExams] = useState([])
    const [showInPublish, setShowInPublish] = useState([])

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            props.getExamsForSchoolId(schoolId)
        }
    }, [schoolId]);

    const handleDeleteExam = () => {
        props.deleteExam(deleteObject)
        setShowDelete(false);
    };

    const getBadge = (startDate, endDate) => {
        const todayDate = new Date();
        let badgeColor, badgeText;

        if (startDate < todayDate && endDate > todayDate) {
            badgeColor = "warning";
            badgeText = "In Progress";
        } else if (startDate > todayDate && endDate > todayDate) {
            badgeColor = "primary";
            badgeText = "Upcoming";
        } else {
            badgeColor = "success";
            badgeText = "Completed";
        }

        return <Badge color={badgeColor}>{badgeText}</Badge>;
    };


    useEffect(() => {

        if (props.exams && props.exams) {

            let tempAll = props.exams

            let filteredExams = [];

            let todayDate = new Date();

            for (const exam of tempAll) {

                if (selectedFilter === 'All') {
                    filteredExams.push(exam)
                } else if (selectedFilter == 'In Progress') {
                    if (exam.attributes.startDate < todayDate && exam.attributes.endDate > todayDate) {
                        filteredExams.push(exam)
                    }
                } else if (selectedFilter == 'Upcoming') {
                    if (exam.attributes.startDate > todayDate && exam.attributes.endDate > todayDate) {
                        filteredExams.push(exam)
                    }
                } else if (selectedFilter == 'Completed') {
                    if (exam.attributes.startDate < todayDate && exam.attributes.endDate < todayDate) {
                        filteredExams.push(exam);
                    }
                }
            }

            setDisplayExams(filteredExams)
        }

    }, [props.exams, selectedFilter])

    const Offsymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
            </div>
        );
    };

    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >

            </div>
        );
    };

    useEffect(() => {
        const initialStatus = props.exams.map(item => item.attributes.publishResults);
        setShowInPublish(initialStatus);
    }, [props.exams]);

    const showExamResult = (isChecked, index, item) => {
        setShowInPublish(prevState => {
            const newState = [...prevState];
            newState[index] = isChecked;
            return newState;
        });
        item.set("publishResults", isChecked);
        props.saveMarksCard(item);
    };


    return (

        <React.Fragment>
            <div className="page-content">
                {showDelete && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Delete"
                        cancelBtnText="Cancel"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="success"
                        title="Alert!"
                        onCancel={() => {
                            setShowDelete(false);
                            setDeleteObject(undefined)
                        }}
                        onConfirm={() => {
                            handleDeleteExam();

                        }}
                    >
                        {"Are you sure you want to delete"}
                        <br></br>
                    </SweetAlert>
                )}
                <Row>
                    <Col>
                        <Row className="justify-content-between">
                            <Col lg={3} sm={6}>
                                <div className='m-2'>
                                    <h4 className="font-size-18" >All Exams</h4>
                                </div>
                            </Col>
                            <Col lg={9} sm={6}>
                                <Form className="d-flex justify-content-end mb-3">
                                    <div className="m-1">
                                        <FormGroup>
                                            <select
                                                className="form-control"
                                                onChange={(e) => {
                                                    setSelectedFilter(e.target.value);
                                                }}
                                                value={selectedFilter}
                                            >
                                                {filterList.map((filter, key) => (
                                                    <option key={key}>{filter}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </div>
                                    <div className="m-1">
                                        <Form className="form-inline">
                                            <Link
                                                className="btn btn-primary waves-effect waves-light"
                                                to={"/new-exam/" + schoolId}
                                                role="button"
                                            >
                                                Create Exam
                                            </Link>
                                        </Form>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <Table className="table-centered table-nowrap table-hover">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">{('Name')}</th>
                                                <th scope="col">{'Start Date'}</th>
                                                <th scope="col">{'End Date'}</th>
                                                <th scope="col">{'Status'}</th>
                                                <th scope='col'>{'Publish Result'}</th>
                                                <th scope="col" style={{ textAlign: "center" }}>{'Actions'}</th>
                                            </tr>
                                        </thead>
                                        <tbody>{
                                            displayExam.map((exam, key) =>
                                                <tr key={"_program_" + key}>

                                                    <td>
                                                        <Link to={`/marks-view/${schoolId}/${exam.id}/${exam.attributes.groupId}`}>
                                                            < p className="text-truncate font-size-15" > {exam.attributes.name}</p></Link>
                                                    </td>

                                                    <td>
                                                        <p className="text-muted mb-2">{Moment(exam.attributes.startDate).format("DD-MM-YYYY")}</p>
                                                    </td>
                                                    <td>
                                                        <p className="text-muted mb-2">{Moment(exam.attributes.endDate).format("DD-MM-YYYY")}</p>
                                                    </td>
                                                    <td>
                                                        <p className="text-muted mb-2">{getBadge(exam.attributes.startDate, exam.attributes.endDate)}</p>
                                                    </td>
                                                    <td>
                                                        <Switch
                                                            uncheckedIcon={<Offsymbol />}
                                                            checkedIcon={<OnSymbol />}
                                                            height={15}
                                                            width={30}
                                                            onColor="#626ed4"
                                                            onChange={(e) => {
                                                                showExamResult(!showInPublish[key], key, exam);
                                                            }}
                                                            checked={showInPublish[key]}
                                                        />
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <Row>
                                                            <Col className='d-flex justify-content-center'>
                                                                <ul className="list-inline font-size-18 contact-links mb-0 float-right">
                                                                    <Link to={`/edit-exam/${schoolId}/${exam.id}`}>
                                                                        <li className="list-inline-item px-2" style={{ cursor: "pointer" }} id={"exam-edit" + key}>
                                                                            <i className="bx bx-edit-alt"></i>
                                                                            <UncontrolledTooltip placement="top" target={"exam-edit" + key}>
                                                                                {props.t('Edit')}
                                                                            </UncontrolledTooltip>
                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                                <ul className="list-inline font-size-18 contact-links mb-0 float-right">
                                                                    <li className="list-inline-item px-2" style={{ cursor: "pointer" }} id={"exam-delete" + key}
                                                                        onClick={() => {
                                                                            setDeleteObject(exam)
                                                                            setShowDelete(true);
                                                                        }}>
                                                                        <i className="bx bxs-trash text-danger"></i>
                                                                        <UncontrolledTooltip placement="top" target={"exam-delete" + key}>
                                                                            {props.t('Delete')}
                                                                        </UncontrolledTooltip>
                                                                    </li>
                                                                </ul>
                                                                <ul className="list-inline font-size-18 contact-links mb-0 float-right">
                                                                    <Link to={`/marks-view/${schoolId}/${exam.id}/${exam.attributes.groupId}`}>
                                                                        <li className="list-inline-item px-2"
                                                                            style={{ cursor: "pointer" }}
                                                                            id={"viewDetailPage" + key}
                                                                        >
                                                                            <i className="bx bx-right-arrow-circle"></i>
                                                                            <UncontrolledTooltip placement="top" target={"viewDetailPage" + key}>
                                                                                {'Details'}
                                                                            </UncontrolledTooltip>
                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody >
                                    </Table >
                                    {displayExam.length == 0 && (
                                        <Col>
                                            <h3 className="text-center text-info mt-3">
                                                No Data Found For The Filter
                                            </h3>
                                        </Col>
                                    )}
                                </div >

                            </CardBody >
                        </Card >
                    </Col>
                </Row>

            </div >
        </React.Fragment >

    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { exams } = state.Examination;

    return {
        selectedSchool, exams
    }
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getExamsForSchoolId, deleteExam, saveMarksCard
})(AllExams)));
