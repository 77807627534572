import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, Label, Badge, Input, Table, ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";
import moment from 'moment';
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import Switch from "react-switch";
import Parse from "parse";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    setSelecetdSchool, getTeachersForSchool, updateMasterPayslipForTeacherId, updateMasterPayslipItemsForMasterPayslipId,
    getPayslipsForMonthAndSchoolId, updatePayslipsForMonthAndSchoolId, getPayrollCategoriesForOwnerId, getPayslipItemsForPayslipIds,
    getMasterPayslipsForOwnerIdAndOwnertype, editPayslip, setSelectedMonth,
} from '../../store/actions';
import * as XLSX from 'xlsx/xlsx.mjs';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap/lib';

const PayRoll = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/payroll/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;

    const [selectedMonth, setSelectedMonth] = useState();
    const [allTeachers, setAllTeachers] = useState([]);
    const [isMonthSelected, setIsMonthSelected] = useState(false);
    const [openModalForReport, setOpenModalForReport] = useState(false)
    const [isBankDetailsChecked, setIsBankDetailsChecked] = useState(false);
    const [isCategoryBreakupChecked, setIsCategoryBreakupChecked] = useState(false);
    const [payoutStatusCheck, setPayoutStatusCheck] = useState([]);
    const [showNotifyAlert, setShowNotifyAlert] = useState(false);
    const [selectedPaySlipId, setSelectedPaySlipId] = useState();

    const [allDataPayroll, setAllDataPayroll] = useState({
        totalNetPayableSalary: 0,
        totalSalaryPerMonth: 0,
        totalTeachers: 0,
        totalPayslips: 0,
        totalMasterPayslips: 0,
    });
    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            props.getMasterPayslipsForOwnerIdAndOwnertype(schoolId, "School")
        }
    }, [schoolId])

    const getDesignation = (role) => {
        if (role === 3) {
            return props.t("Director");
        } else if (role === 2) {
            return props.t("Center Head");
        } else {
            return props.t("Teacher");
        }
    };

    useEffect(() => {
        if (props.selectedMonth && !selectedMonth) {
            setIsMonthSelected(true)
            setSelectedMonth(props.selectedMonth)
            getTeachersAndPayslipsForMonth()
        }
    }, [props.selectedMonth])

    const getTeachersAndPayslipsForMonth = () => {
        if (schoolId) {
            setAllTeachers([]);
            props.updatePayslipsForMonthAndSchoolId([]);
            props.getTeachersForSchool(schoolId);
            props.getPayslipsForMonthAndSchoolId(schoolId, moment(selectedMonth ?? props.selectedMonth).format('MMM-YYYY').toLowerCase());
        }
    };

    const Offsymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
            </div>
        );
    };

    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
            </div>
        );
    };

    useEffect(() => {
        if (props.teachers) {
            setAllTeachers(props.teachers);
        }
    }, [props.teachers]);

    useEffect(() => {
        if (schoolId) {
            props.getPayrollCategoriesForOwnerId(schoolId, "School");
        }
    }, [schoolId]);

    useEffect(() => {
        if (props.payslips && props.payslips.length > 0) {
            let paySlipIds = []
            for (const paySlip of props.payslips) {
                paySlipIds.push(paySlip.id)
            }
            props.getPayslipItemsForPayslipIds(paySlipIds)
        }
    }, [props.payslips])

    useEffect(() => {
        const initialStatus = props.payslips.map(item => item.attributes.payoutStatus == 1);
        setPayoutStatusCheck(initialStatus);
    }, [props.payslips]);

    const DownloadPayrollReport = () => {
        let finalData = [];
        let title = [];
        let fileName = "Payroll Report.xlsx";
        let sheetName = "Payroll Report";

        finalData.push([`Report: Payroll Report`]);

        title.push("Sl.No");
        title.push("Teacher Name");
        title.push("Designation");

        if (isBankDetailsChecked) {
            title.push("Account Number");
            title.push("Name as per Bank");
            title.push("Bank Name");
            title.push("IFSC");
            title.push("Branch Name");
        }

        if (isCategoryBreakupChecked) {
            for (const category of props.payrollCategories) {
                title.push(category.attributes.title);
            }
        }

        title.push("Gross Salary");
        title.push("Net Payable Salary");

        finalData.push(title);

        let slNo = 1;
        let totalGrossSalary = 0;
        let totalNetPayableSalary = 0;
        let categoryTotals = Array(isCategoryBreakupChecked ? props.payrollCategories.length : 0).fill(0);

        for (const teacher of props.teachers) {
            const payslip = props.payslips.find(p => p.attributes.teacherId === teacher.id);
            const masterPayslip = props.masterPayslips.find(p => p.attributes.teacherId === teacher.id);

            const rowData = [
                slNo,
                teacher.attributes.Name,
                teacher.attributes.designation ? teacher.attributes.designation : getDesignation(teacher.attributes.Role),
            ];

            if (isBankDetailsChecked) {
                const bankAccount = teacher.attributes.bankAccount || {};
                rowData.push(
                    bankAccount.accountNumber ?? "-",
                    bankAccount.nameAsPerBank ?? "-",
                    bankAccount.bankName ?? "-",
                    bankAccount.IFSC ?? "-",
                    bankAccount.branchName ?? "-"
                );
            }

            if (isCategoryBreakupChecked) {
                for (let i = 0; i < props.payrollCategories.length; i++) {
                    const categoryId = props.payrollCategories[i].id;
                    const categoryItem = props.allPayslipItems.find(item =>
                        item.attributes.categoryId === categoryId &&
                        payslip && item.attributes.payslipId === payslip.id
                    );
                    const amount = categoryItem ? categoryItem.attributes.amount : 0;
                    rowData.push(amount);
                    categoryTotals[i] += amount;
                }
            }

            const grossSalary = masterPayslip?.attributes?.salaryPerMonth ?? 0;
            const netPayableSalary = payslip?.attributes?.netPayableSalary ?? 0;

            rowData.push(grossSalary);
            rowData.push(netPayableSalary);

            totalGrossSalary += grossSalary;
            totalNetPayableSalary += netPayableSalary;

            finalData.push(rowData);
            slNo++;
        }

        const totalsRow = ["Total", "", ""];
        if (isBankDetailsChecked) {
            totalsRow.push("", "", "", "", "");
        }

        if (isCategoryBreakupChecked) {
            totalsRow.push(...categoryTotals);
        }

        totalsRow.push(totalGrossSalary);
        totalsRow.push(totalNetPayableSalary);

        finalData.push(totalsRow);

        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(finalData);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName);
        setOpenModalForReport(!openModalForReport);
    };

    const getPayoutStatus = (isChecked, index, payslip) => {
        setPayoutStatusCheck(prevState => {
            const newState = [...prevState];
            newState[index] = isChecked;
            return newState;
        });

        let selectPayslip = payslip
        selectPayslip.set("payoutStatus", isChecked ? 1 : 2);
        selectPayslip.save().then(
            (result) => {
                // console.log("result", result)
            },
            (error) => {

            }
        );
        // props.editPayslip(payslip);
    }

    useEffect(() => {
        setAllDataPayroll([])
        if (props.teachers && props.teachers.length > 0) {
            let totalNetPayableSalary = 0;
            let totalSalaryPerMonth = 0;

            console.log("selectedMonth", selectedMonth)
            if (props.payslips) {
                for (const payslip of props.payslips) {
                    if (payslip.attributes?.netPayableSalary && moment(selectedMonth).format('MMM-YYYY').toLowerCase() == payslip.attributes.payMonth) {
                        totalNetPayableSalary += payslip.attributes.netPayableSalary;
                    }
                }
            }

            if (props.masterPayslips) {
                for (const masterPayslip of props.masterPayslips) {
                    if (masterPayslip.attributes?.salaryPerMonth) {
                        totalSalaryPerMonth += masterPayslip.attributes.salaryPerMonth;
                    }
                }
            }

            setAllDataPayroll({
                totalNetPayableSalary,
                totalSalaryPerMonth,
                totalTeachers: props.teachers.length || 0,
                totalPayslips: props.payslips?.length || 0,
                totalMasterPayslips: props.masterPayslips?.length || 0,
            });
        }

    }, [props.payslips, props.masterPayslips])

    const sendPayslipToTeacher = (teacherId, payslipId) => {
        if (schoolId && teacherId && payslipId) {
            Parse.Cloud.run("function_sendPayslipEmail", {
                schoolId: schoolId,
                teacherId: teacherId,
                payslipId: payslipId
            }).then(
                (result) => {
                    setShowNotifyAlert(false)
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }

    return (
        <React.Fragment>
            <div className='page-content'>
                {showNotifyAlert && (<SweetAlert
                    title={props.t('Are you sure?')}
                    warning
                    showCancel
                    confirmBtnText="Send"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        sendPayslipToTeacher(showNotifyAlert, selectedPaySlipId)
                    }}
                    onCancel={() => {
                        setShowNotifyAlert(false)
                    }}>
                    {props.t("Send an email to the employee with their payslip")}

                </SweetAlert>)}
                <Modal isOpen={openModalForReport}>
                    <ModalHeader toggle={() => setOpenModalForReport(!openModalForReport)}>
                        Payroll Report
                    </ModalHeader>
                    <ModalBody>
                        <div className="custom-control custom-checkbox mb-2 ml-3">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="salaryReport"
                                checked={true}
                            />
                            <label
                                className="custom-control-label m-1"
                                htmlFor="salaryReport"
                            >
                                Salary Report
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox mb-2 ml-3">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="bankDetails"
                                onChange={() => setIsBankDetailsChecked(!isBankDetailsChecked)}
                                checked={isBankDetailsChecked}
                            />
                            <label
                                className="custom-control-label m-1"
                                htmlFor="bankDetails"
                            >
                                Include Employee Bank Details
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox mb-2 ml-3">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="categoryBreakup"
                                onChange={() => setIsCategoryBreakupChecked(!isCategoryBreakupChecked)}
                                checked={isCategoryBreakupChecked}
                            />
                            <label
                                className="custom-control-label m-1"
                                htmlFor="categoryBreakup"
                            >
                                Include category breakup
                            </label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className="btn"
                            color='secondary'
                            onClick={() => setOpenModalForReport(!openModalForReport)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="btn"
                            color='primary'
                            onClick={() => {
                                DownloadPayrollReport()
                            }}
                        >
                            Apply
                        </Button>
                    </ModalFooter>
                </Modal>
                <Row className="d-flex justify-content-between">
                    <Col lg={6} className="d-flex justify-content-start">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { window.history.back() }}>
                            <ul className="list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className='m-2'>
                            <h4 className="font-size-18">Payroll</h4>
                        </div>
                    </Col>
                    <Col lg={6} className="d-flex justify-content-end">
                        <div className="">
                            <Button
                                className="btn "
                                color='success'
                                onClick={() => {
                                    setOpenModalForReport(!openModalForReport)
                                }}
                            >
                                Download Report
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Card style={{ height: isMonthSelected ? 'auto' : '300px' }}>
                    <CardBody>
                        <div className="pr-4">
                            <Row className="d-flex justify-content-start">
                                <Col lg={2}>
                                    <Label>Select Month :</Label>
                                    <DatePicker
                                        selected={selectedMonth}
                                        onChange={(date) => {
                                            setSelectedMonth(date);
                                            props.setSelectedMonth(date);
                                            setAllTeachers([]);
                                            props.updatePayslipsForMonthAndSchoolId([]);
                                            setIsMonthSelected(true);
                                        }}
                                        className="form-control"
                                        placeholderText="Select Month"
                                        dateFormat="MMM-yyyy"
                                        showMonthYearPicker
                                    />
                                    {!isMonthSelected && <p className='text-danger'>Please Select Month</p>}
                                </Col>

                                <Col lg={2}>
                                    <Button
                                        color="primary"
                                        className="mt-4"
                                        onClick={() => {
                                            getTeachersAndPayslipsForMonth();
                                        }}
                                    >
                                        Apply
                                    </Button>
                                </Col>
                                <Col>
                                    {selectedMonth && (
                                        <div className="table-responsive ">
                                            <Table className="table mb-0 table-bordered table-sm">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Total Employees</th>
                                                        <td>{allDataPayroll.totalTeachers}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Total Payslip Setup</th>
                                                        <td>{allDataPayroll.totalMasterPayslips}</td>
                                                        <th scope="row">Total Salary Per Month</th>
                                                        <td>{allDataPayroll.totalSalaryPerMonth}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Total Payslips</th>
                                                        <td>{allDataPayroll.totalPayslips}</td>
                                                        <th scope="row">Total Net Payable Salary</th>
                                                        <td>{allDataPayroll.totalNetPayableSalary ? allDataPayroll.totalNetPayableSalary.toFixed(2) : allDataPayroll.totalNetPayableSalary}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
                {isMonthSelected && allTeachers && allTeachers.length > 0 && <Card>
                    <CardBody>
                        <div>
                            <div className="table-responsive mt-3">
                                <Table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Teacher Name</th>
                                            <th>Status</th>
                                            <th>Gross Salary</th>
                                            <th>Net Payable Salary</th>
                                            <th>Payout</th>
                                            <th>Setup</th>
                                            <th>Payslip</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allTeachers.map((teacher, index) => {
                                            const payslip = props.payslips && props.payslips.find(p => p.attributes.teacherId === teacher.id);
                                            const masterPayslip = props.masterPayslips && props.masterPayslips.find(p => p.attributes.teacherId === teacher.id);

                                            return (
                                                <tr key={teacher.id}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <p className='mb-0'>{teacher.attributes.Name}</p>
                                                        <small className='mb-0'>{teacher.attributes.designation ? teacher.attributes.designation : getDesignation(teacher.attributes.Role)}</small>
                                                    </td>
                                                    <td>
                                                        <Badge
                                                            className={`badge ${payslip ? 'badge-soft-success' : 'badge-soft-danger'} font-size-12 m-2`}
                                                        >
                                                            {payslip ? props.t("Generated") : props.t("Not Generated")}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <p>{masterPayslip ? masterPayslip.attributes.salaryPerMonth : '-'}</p>
                                                    </td>
                                                    <td>
                                                        <p>{payslip ? payslip.attributes.netPayableSalary : '-'}</p>
                                                    </td>
                                                    <td>
                                                        <Switch
                                                            uncheckedIcon={<Offsymbol />}
                                                            checkedIcon={<OnSymbol />}
                                                            height={15}
                                                            width={30}
                                                            onColor="#626ed4"
                                                            onChange={() => {
                                                                getPayoutStatus(!payoutStatusCheck[index], index, payslip);
                                                            }}
                                                            checked={payoutStatusCheck[index]}
                                                            disabled={!payslip}
                                                        />
                                                        {payoutStatusCheck[index] && <div style={{ cursor: 'pointer' }}>
                                                            <u className='text-primary'><p
                                                                className='text-primary'
                                                                onClick={() => {
                                                                    setSelectedPaySlipId(payslip.id)
                                                                    setShowNotifyAlert(teacher.id)
                                                                }}>
                                                                Notify via Email
                                                            </p></u>
                                                        </div>}
                                                    </td>
                                                    <td>
                                                        <Link
                                                            to={`/setup-payroll/${schoolId}/${teacher.id}/${moment(selectedMonth).format('MMM-YYYY').toLowerCase()}`}
                                                            onClick={() => {
                                                                props.updateMasterPayslipForTeacherId(undefined);
                                                                props.updateMasterPayslipItemsForMasterPayslipId([]);
                                                            }}
                                                            className='m-2'
                                                        >
                                                            <i className="bx bx-right-arrow-alt bx-sm"></i>
                                                        </Link><br />
                                                        <Badge
                                                            className={`badge ${masterPayslip ? 'badge-soft-success' : 'badge-soft-danger'} font-size-12`}
                                                        >
                                                            {masterPayslip ? "Done" : "Not Done"}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <Link to={`/payslip/${schoolId}/${teacher.id}/${payslip && payslip.id}`}>
                                                            <i className="bx bxs-file-doc bx-sm"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </CardBody>
                </Card>}
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { teachers } = state.Teacher;
    const { payslips, payrollCategories, allPayslipItems, masterPayslips, selectedMonth } = state.LeaveModule;

    return { selectedSchool, teachers, payslips, payrollCategories, allPayslipItems, masterPayslips, selectedMonth };
};

export default connect(mapStatetoProps, {
    setSelecetdSchool, getTeachersForSchool, updateMasterPayslipForTeacherId, updateMasterPayslipItemsForMasterPayslipId,
    getPayslipsForMonthAndSchoolId, updatePayslipsForMonthAndSchoolId, getPayrollCategoriesForOwnerId, getPayslipItemsForPayslipIds,
    getMasterPayslipsForOwnerIdAndOwnertype, editPayslip, setSelectedMonth
})(withRouter(withNamespaces()(PayRoll)));