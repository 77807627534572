import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Input, Button, Table, Modal, NavItem, Nav, NavLink, Card, CardBody, CardTitle, InputGroup, InputGroupText, ModalBody, ButtonGroup, ModalHeader, ModalFooter } from "reactstrap";
import Topbar from './Topbar';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ItemImage from "../../assets/images/book_lover.svg";
import Parse from 'parse';
import SweetAlert from 'react-bootstrap-sweetalert';
import Moment from "moment";
import { useForm } from "react-hook-form";
import {
    setSelecetdSchool,
    getCartForForOwnerId, addInventoryCart,
    getInventoryItemForItemIds,
    getInventoryKitsForOwnerId,
    getFranchise,
    getInventoryWarehouseForOwnerId,
    getInventoryItemForOwnerId,
    getInventoryStockForWarehouse,
    getInventorySubItemForItemIds,
    getInventoryItemCategoryPriceForItemIds,
} from '../../store/actions';
import toastr from 'toastr'
import * as Constants from "../../Constents";
import * as WhiteLable from "../../components/Common/WhiteLable";

const InventoryCart = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/school-cart/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [productList, setProductList] = useState([]);
    const [itemIds, setItemIds] = useState([]);
    const [kitIds, setKitIds] = useState([]);
    const [franchiseId, setFranchiseId] = useState(undefined);
    const [totalCost, setTotalCost] = useState(0);
    const [unitCount, setUnitCount] = useState(0);
    const [onClickCheckOut, setOnClickCheckOut] = useState(false);
    const [cartInvoice, setcartInvoice] = useState(undefined);
    const [pgResponse, setPgResponse] = useState(undefined);
    const [showNotHavePaymentOptionAlert, setShowNotHavePaymentOptionAlert] = useState(false);

    const [gatewayState, setGatewayState] = useState(false);
    const [razorPayRequestResponce, setRazorPayRequestResponce] = useState(false);
    const [onepayRequestResponce, setOnePayRequestResponce] = useState(undefined)

    const [openCheckOutModal, setOpenCheckOutModal] = useState(false);
    const [categories, setCategories] = useState(undefined);
    const [categorySetting, setCategoriesSetting] = useState(0);
    const [productOutOfStockEroor, setProductOutOfStockEroor] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const [outOfStockItems, setOutOfStockItems] = useState([]);
    const [updateItemId, setUpdateItemId] = useState(null);
    const [showKitDetailPopup, setShowKitDetailPopup] = useState(false)
    const [kitPriceCategory, setKitPriceCategory] = useState([])

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        if (!franchiseId) {
            setFranchiseId(props.selectedSchool && props.selectedSchool.attributes.franchiseId)
        }
        if (props.selectedSchool && props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length > 0) {
            props.getFranchise(props.selectedSchool.attributes.franchiseId);
            props.getInventoryWarehouseForOwnerId(props.selectedSchool.attributes.franchiseId, 'franchise');
        }
        setCategories(props.selectedSchool && props.selectedSchool.attributes.inventoryCategories[0])

    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (props.selectedSchool && props.selectedSchool.attributes.franchiseId) {
            props.getInventoryItemForOwnerId(props.selectedSchool && props.selectedSchool.attributes.franchiseId, 'franchise')
            props.getInventoryKitsForOwnerId(props.selectedSchool && props.selectedSchool.attributes.franchiseId, 'franchise', true);
        }

    }, [franchiseId, categories, categorySetting]);

    useEffect(() => {
        if (props.inventoryWarehouses && props.inventoryWarehouses.length > 0) {
            props.getInventoryStockForWarehouse(props.inventoryWarehouses[0].id);
        }
    }, [props.inventoryWarehouses]);

    useEffect(() => {
        if (schoolId) {
            props.getCartForForOwnerId(schoolId, 'school');
        }
    }, [schoolId]);

    useEffect(() => {
        if (props.inventoryItems && props.inventoryItems.length > 0) {
            let itemIds = []
            let subItemIds = []
            for (const item of props.inventoryItems) {
                itemIds.push(item.id)
            }
            if (itemIds.length > 0) {
                props.getInventorySubItemForItemIds(itemIds)
            }

            if (props.inventorySubItems && props.inventorySubItems.length > 0) {
                for (const subItem of props.inventorySubItems) {
                    subItemIds.push(subItem.id)
                }
            }

            const allItemIds = [...subItemIds, ...itemIds];
            if (itemIds.length > 0 && subItemIds.length > 0) {
                props.getInventoryItemCategoryPriceForItemIds(allItemIds, categories)
            } else if (itemIds.length > 0) {
                props.getInventoryItemCategoryPriceForItemIds(itemIds, categories)
            }
        }
    }, [props.inventoryItems, props.inventorySubItems])

    useEffect(() => {
        if (props.inventoryKits && props.inventoryKits.length > 0) {
            let kitIds = []
            for (const kit of props.inventoryKits) {
                kitIds.push(kit.id)
            }
            if (kitIds.length > 0) {
                getKitPricesForCategory(kitIds)
            }
        }
    }, [props.inventoryKits])

    const getKitPricesForCategory = (kitIds) => {
        Parse.Cloud.run("function_getKitPricesForCategory", {
            kitIds: kitIds,
            categoryId: categories
        }).then((result) => {
            setKitPriceCategory(result.categoryKitPriceMap)
        },
            (error) => {
                console.log(error);
            }
        );
    }

    const getKitPriceForCategory = (kitId) =>{
        if(kitPriceCategory && kitPriceCategory.length > 0){
            for(const kitPrice of kitPriceCategory){
                if(kitPrice.id == kitId){
                    return kitPrice.sellingPrice
                }
            }
        }
        return undefined

    }

    useEffect(() => {
        let items = [];
        let kits = [];
        if (props.inventoryCart && props.inventoryCart.length > 0) {
            for (const cart of props.inventoryCart) {
                if (cart.attributes.productType == 'inventoryItem') {
                    items.push(cart.attributes.productId)
                } else if (cart.attributes.productType == 'inventoryKit') {
                    kits.push(cart.attributes.productId)
                }
            }
        }
        setItemIds(items);
        setKitIds(kits)

        let next = false;

        for (let it of items) {


            let is = props.inventoryItemsOfItemIds && props.inventoryItemsOfItemIds.filter(
                (i) => it == i.id
            );
            if (is.length == 0) {
                next = true;
            }

        }

        if (next || !props.inventoryItemsOfItemIds || props.inventoryItemsOfItemIds.length == 0) {
            props.getInventoryItemForItemIds(items);
        }

        //props.getInventoryItemForItemIds(items);


    }, [props.inventoryCart]);

    useEffect(() => {
        let list = [];

        for (const cart of props.inventoryCart) {

            let item = {
                id: cart.id,
                cartObject: cart,
                quantity: cart.attributes.quantity,
                totalFinalCost: 0,
            };

            if (cart.attributes.productType === 'inventoryItem') {
                item.itemId = cart.attributes.productId;

                let is = props.inventoryItemsOfItemIds && props.inventoryItemsOfItemIds.filter(
                    (i) => item.itemId === i.id
                );

                if (is && is.length > 0) {
                    item.itemObject = is[0];
                    item.productId = item.itemObject.id;
                    item.image = item.itemObject.attributes.displayImage
                        ? item.itemObject.attributes.displayImage._url
                        : ItemImage;
                    item.name = item.itemObject.attributes.name;
                    item.type = "item";
                    item.unitCost = getInventoryItemCategoryPrice(item.itemObject.id) !== undefined ? getInventoryItemCategoryPrice(item.itemObject.id) : item.itemObject.attributes.sellingPrice;
                    item.cost = item.quantity * item.unitCost;

                    let totalCost = 0;
                    if (cart.attributes.kitItems && cart.attributes.kitItems.length > 0) {
                        if (cart.attributes.kitItems.length == 1) {
                            totalCost = cart.attributes.kitItems[0].finalPrice || 0;
                        } else {
                            cart.attributes.kitItems.forEach((kitItem) => {
                                totalCost += kitItem.finalPrice || 0;
                            });
                        }
                    } else {
                        totalCost = item.unitCost * item.quantity
                    }
                    item.totalFinalCost = totalCost;

                    list.push(item);
                }

            } else if (cart.attributes.productType === 'inventoryKit') {
                item.kitId = cart.attributes.productId;

                let tempList = props.inventoryKits && props.inventoryKits.filter(
                    (p) => p.id === item.kitId
                );

                if (tempList && tempList.length > 0) {
                    item.kitObject = tempList[0];
                    item.productId = item.kitObject.id;
                    item.image = item.kitObject.attributes.displayImage
                        ? item.kitObject.attributes.displayImage._url
                        : ItemImage;
                    item.name = item.kitObject.attributes.name;
                    item.type = "kit";
                    item.unitCost = getKitPriceForCategory(item.kitObject.id) !== undefined ? getKitPriceForCategory(item.kitObject.id) : item.kitObject.attributes.sellingPrice;

                    item.cost = item.quantity * item.unitCost;

                    let totalCost = 0;
                    if (cart.attributes.kitItems && cart.attributes.kitItems.length > 0) {
                        if (cart.attributes.kitItems.length == 1) {
                            totalCost = cart.attributes.kitItems[0].finalPrice || 0;
                        } else {
                            cart.attributes.kitItems.forEach((kitItem) => {
                                totalCost += kitItem.finalPrice || 0;
                            });
                        }
                    }
                    item.totalFinalCost = totalCost;

                    list.push(item);
                }
            }
        }

        setProductList(list);

    }, [props.inventoryCart, props.inventoryKits, props.inventoryItemsOfItemIds]);

    const getInventoryItemCategoryPrice = (itemId) => {
        if (props.inventoryItemCategoryPrices) {
            for (const price of props.inventoryItemCategoryPrices) {
                if (price.attributes.itemId == itemId && price.attributes.categoryId == categories) {
                    return price.attributes.sellingPrice;
                }
            }
        }
        return undefined;
    }

    const removeCartItem = (product) => {
        let list = productList.filter(
            (p) => p.id != product.id
        );
        let cart = product.cartObject;
        cart.set('isDeleted', true);
        cart.set('deletedBy', Parse.User.current().attributes.teacherId);
        props.addInventoryCart(cart, "removed");
        setProductList(list);
    }

    function countUP(id, quantity, product) {
        setProductList(productList.map(p => (p.id === id ? { ...p, quantity: quantity + 1, cost: p.unitCost * (quantity + 1) } : p)));
        let cart = product.cartObject;
        cart.set('quantity', quantity + 1);
        props.addInventoryCart(cart, "inc");
    }

    function countDown(id, quantity, product) {
        setProductList(productList.map(p => (p.id === id ? { ...p, quantity: quantity - 1, cost: p.unitCost * (quantity - 1) } : p)));
        let cart = product.cartObject;
        cart.set('quantity', quantity - 1);
        props.addInventoryCart(cart, "dec");
    }

    useEffect(() => {

        let sum = 0;
        let uc = 0
        for (const pl of productList) {
            sum = sum + pl.totalFinalCost;
            uc = uc + pl.quantity;
        }
        setTotalCost(sum);
        setUnitCount(uc)

    }, [productList]);




    const createInvoice = (cod) => {

        if (!cod) {
            setOnClickCheckOut(true);
        }
        const Cart = Parse.Object.extend("Invoice");
        const invoice = new Cart();

        invoice.set("recipientId", schoolId);
        invoice.set("amount", totalCost + "");
        invoice.set("hasItems", true);
        invoice.set("title", "Inventory Order");
        invoice.set("franchiseId", franchiseId);
        invoice.set("tags", ["2023", "INVENTORY"]);

        invoice.save().then(
            (result) => {

                var invoiceItems = [];

                for (var i = 0; i < productList.length; i++) {
                    let product = productList[i];
                    const InvoiceItem = Parse.Object.extend("InvoiceItems");
                    const invoiceItem = new InvoiceItem();

                    invoiceItem.set("invoiceId", result.id);
                    invoiceItem.set("totalAmount", product.totalFinalCost);
                    invoiceItem.set("amount", product.totalFinalCost);
                    invoiceItem.set("productId", product.productId);
                    invoiceItem.set("title", product.name);
                    invoiceItem.set("cartId", product.id);

                    invoiceItems.push(invoiceItem);
                }

                if (invoiceItems.length > 0) {
                    // console.log(invoiceItems);

                    Parse.Object.saveAll(invoiceItems).then(
                        (list) => {
                            // console.log("SUCCESS", list);
                            // setOnClickCheckOut(false)
                            let object = { invoice: result, invoiceItems: invoiceItems };
                            setcartInvoice(object);
                            payNow(object, cod);
                        },
                        (error) => {
                            console.log("ERROR", error);
                        }
                    );
                }
            },
            (error) => {
                console.log("ERROR", error);
                // this.setState({ onClickCheckOut: false });
            }
        );
    }

    const payNow = (invoiceObject, cod) => {
        let invoiceItem = [];
        for (const val of invoiceObject.invoiceItems) {
            let temp = {};
            temp.amount = val.attributes.amount;
            temp.discount = val.attributes.discount;
            temp.discountType = val.attributes.discountType;
            temp.id = val.id;
            temp.itemDescription = val.attributes.itemDescription;
            temp.payableAmout = val.attributes.totalAmount;
            temp.tax = val.attributes.tax;
            temp.totalAmount = val.attributes.totalAmount;
            invoiceItem.push(temp);
        }

        const amount = "" + totalCost.toFixed(2);
        const submitAmount = parseFloat(amount);

        let fpop = props.franchise && props.franchise && props.franchise.attributes.inventoryPaymentOptionId;
        let popId = fpop;

        if (cod) {
            // create sale order for franchise  
            const InventorySaleOrders = Parse.Object.extend("InventorySaleOrders");
            let saleOrder = new InventorySaleOrders();

            saleOrder.set("ownerType", 'franchise');
            saleOrder.set("ownerId", invoiceObject.invoice.attributes.franchiseId);
            saleOrder.set("createdOn", new Date());
            saleOrder.set('createdBy', Parse.User.current().attributes.teacherId);
            saleOrder.set('status', 0);
            saleOrder.set("buyerType", 'School');
            saleOrder.set("buyerId", schoolId);
            saleOrder.set('invoiceId', invoiceObject.invoice.id);
            saleOrder.set('orderValue', totalCost);
            if (props.inventoryWarehouses) {
                saleOrder.set("warehouseId", props.inventoryWarehouses[0].id);
            }

            saleOrder.save().then((soResult) => {
                let orderValue = 0;
                let itemObjects = [];
                let updateCart = [];

                for (let i = 0; i < productList.length; i++) {
                    const InventorySaleOrderItems = Parse.Object.extend("InventorySaleOrderItems");
                    let saleOrderItem = new InventorySaleOrderItems();

                    let co = productList[i].cartObject;
                    co.set('isDeleted', true);
                    co.set('success', true);
                    updateCart.push(co);

                    saleOrderItem.set("saleOrderId", soResult.id);
                    saleOrderItem.set("ownerType", 'school');
                    saleOrderItem.set("ownerId", schoolId);
                    saleOrderItem.set("itemId", productList[i].itemId);
                    saleOrderItem.set("quantity", productList[i].cartObject.attributes.quantity);

                    if (productList[i].type == "item") {
                        let items = props.inventoryItems.filter((item) => item.id == productList[i].itemId);
                        if (items && items.length > 0) {
                            let unitCost = 0;
                            if (productList[i].cartObject.attributes.quantity > 0) {
                                unitCost = productList[i].totalFinalCost / productList[i].cartObject.attributes.quantity;
                            }
                            let totalcost = unitCost * productList[i].cartObject.attributes.quantity;
                            orderValue = orderValue + totalcost;
                            saleOrderItem.set("unitCost", unitCost);
                            saleOrderItem.set("totalCost", productList[i].totalFinalCost);
                            saleOrderItem.set("itemName", items[0].attributes.name);
                            saleOrderItem.set("unit", items[0].attributes.unit);
                            saleOrderItem.set("itemId", productList[i].itemId);
                            saleOrderItem.set("productType", "inventoryItem");
                            if (productList[i].cartObject.attributes.kitItems) {
                                saleOrderItem.set("kitItemMatrix", productList[i].cartObject.attributes.kitItems);
                            }
                        }
                    } else {
                        let kits = props.inventoryKits.filter((kit) => kit.id == productList[i].kitId);
                        if (kits && kits.length > 0) {
                            let unitCost = 0;
                            if (productList[i].cartObject.attributes.quantity > 0) {
                                unitCost = productList[i].totalFinalCost / productList[i].cartObject.attributes.quantity;
                            }
                            let totalcost = unitCost * productList[i].cartObject.attributes.quantity;
                            orderValue = orderValue + totalcost;
                            saleOrderItem.set("unitCost", unitCost);
                            saleOrderItem.set("totalCost", productList[i].totalFinalCost);
                            saleOrderItem.set("itemName", kits[0].attributes.name);
                            saleOrderItem.set("itemId", productList[i].kitId);
                            saleOrderItem.set("unit", "kit");
                            saleOrderItem.set("productType", "inventoryKit");
                            if (productList[i].cartObject.attributes.kitItems) {
                                saleOrderItem.set("kitItemMatrix", productList[i].cartObject.attributes.kitItems);
                            }
                        }
                    }

                    itemObjects.push(saleOrderItem);
                }

                Parse.Object.saveAll(itemObjects).then((siResult) => {
                    Parse.Object.saveAll(updateCart).then((cResult) => {
                        Parse.Object.pinAll(cResult);
                        Parse.Object.pinAll(siResult)
                        soResult.pin()
                        toastr.success("Order Placed successfully", { timeOut: 3000 });
                        props.history.push(`/sale-order/${schoolId}`);
                        // Parse.Cloud.run("function_AproveSaleOrder", {
                        //     saleOrderId: soResult.id,
                        // }).then((response) => {
                        //     Parse.Object.pinAll(cResult);
                        //     Parse.Object.pinAll(siResult);
                        //     soResult.pin();
                        //     toastr.success("Order Placed successfully", { timeOut: 3000 });
                        //     window.history.back();
                        // }, (error) => {
                        //     console.log("error" + error.message);
                        // });
                    });
                });
            }, (error) => {
                console.log(error);
            });
        } else if (!popId) {
            setShowNotHavePaymentOptionAlert(true)
            setGatewayState(false);
            return
        } else {
            let PaymentOptions = Parse.Object.extend("PaymentOptions");
            var query = new Parse.Query(PaymentOptions);
            query.get(popId).then(
                (result) => {
                    if (result && result.attributes.paymentGateway) {
                        let airPay = (result.attributes.enabledOptions && result.attributes.enabledOptions.length >= 3 && result.attributes.enabledOptions[2] == 1) ? true : false;
                        let razorPay = (result.attributes.enabledOptions && result.attributes.enabledOptions.length >= 5 && result.attributes.enabledOptions[4] == 1) ? true : false;
                        let onePay = (result.attributes.enabledOptions && result.attributes.enabledOptions.length >= 6 && result.attributes.enabledOptions[5] == 1) ? true : false;
                        let grayQuest = (result.attributes.enabledOptions && result.attributes.enabledOptions.length >= 6 && result.attributes.enabledOptions[6] == 1) ? true : false;

                        if (airPay || razorPay || onePay || grayQuest || cod) {
                            const MT = Parse.Object.extend("MoneyTransactions");
                            let mt = new MT();
                            mt.set("invoiceId", invoiceObject.invoice.id);
                            mt.set("amount", submitAmount);
                            mt.set("transactionType", cod ? "cod" : (onePay ? "OnePay" : (airPay ? "AirPay" : (razorPay ? "RazorPay":(grayQuest&&"GrayQuest")))));
                            mt.set("deleted", true);
                            mt.set("invoiceItems", invoiceItem);
                            mt.set("transactionFrom", "INVENTORY");
                            mt.set("franchiseId", franchiseId);
                            mt.set('inventoryBuyerId', invoiceObject.invoice.attributes.recipientId)
                            mt.save().then((mtResult) => {
                                let buyerEmail = Parse.User.current().attributes.email;
                                let buyerPhoneNumber = "";
                                let buyerFirstName = props.selectedSchool.attributes.Name;
                                let buyerLastName = "lastNameInfo" ?? " ";

                                const orderId = mtResult.id;
                                let popAttribut = "objectId";

                                if (onePay) {
                                    handelOnePay({
                                        dateTime: Moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
                                        custMobile: undefined,
                                        custMail: buyerEmail.length > 7 ? buyerEmail : undefined,
                                        amount: parseFloat(submitAmount).toFixed(2) + "",
                                        orderid: orderId,
                                        popId: popId,
                                        popAttribut: popAttribut,
                                        royaltyAmount: 0,
                                        invoiceId: invoiceObject.invoice.id,
                                        kidName: removeSpecialCharacters(buyerFirstName),
                                    });

                                    return
                                } else if (airPay) {
                                    fetch("https://liltriangle.b4a.app/airpay/sendtoairpay", {
                                        method: "POST",
                                        body: JSON.stringify({
                                            buyerFirstName: buyerFirstName,
                                            buyerLastName: buyerLastName,
                                            buyerAddress: "bangalore",
                                            buyerCity: "bangalore",
                                            buyerState: "Karnataka",
                                            buyerCountry: "IN",
                                            buyerEmail: buyerEmail,
                                            buyerPhone: buyerPhoneNumber,
                                            amount: amount,
                                            orderid: orderId,
                                            popId: popId,
                                            popAttribut: popAttribut,
                                        }),
                                        headers: {
                                            "Content-type": "application/json; charset=UTF-8",
                                        },
                                    }).then((response) => {
                                        if (response.status !== 200) {
                                            return "error";
                                        }
                                        return response.text();
                                    }, (error) => {
                                        console.log("error" + error.message)
                                    }).then((text) => {
                                        let response = text;
                                        // console.log(response);
                                        setPgResponse(response);

                                    }, (error) => {
                                        console.log("error" + error.message)
                                    });
                                } else if (razorPay) {
                                    loadscript('https://checkout.razorpay.com/v1/checkout.js')
                                    handelRazorPay({
                                        royaltyAmount: 0,
                                        invoiceId: invoiceObject.invoice.id,
                                    })
                                    return;
                                } else if(grayQuest) {
                                    //yet to impliment
                                }
                            });
                        } else {
                            setShowNotHavePaymentOptionAlert(true)
                            setGatewayState(false);
                        }
                    }
                },
                (error) => {
                    console.log("error" + error.message)
                }
            );
        }
    }

    const handelRazorPay = (body) => {
        Parse.Cloud.run("function_getRazorpayOrder", {
            body: body,

        }).then((response) => {
            // console.log("response" + response)
            setGatewayState(false);
            setRazorPayRequestResponce(response)
            openRazorPayWindow(response)
        }, (error) => {
            console.log("error" + error.message)
        });
    }

    const loadscript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src
            script.onload = () => {
                resolve(true);
            }
            script.onerror = () => {
                resolve(false);
            }

            document.body.appendChild(script)
        })
    }



    const openRazorPayWindow = (sResponse) => {
        let key = sResponse.username;
        let response = JSON.parse(sResponse.response);

        var options = {
            "key": key,
            "amount": parseFloat(totalCost).toFixed(2) * 100,
            "currency": "INR",
            "name": props.franchise.attributes.name,
            "description": "School Fee",
            "image": props.franchise && props.franchise.attributes.logo ? props.franchise.attributes.logo._url : '',
            "order_id": response.id,
            "callback_url": WhiteLable.getHost()+"/sale-order/" + schoolId,
            "prefill": {
                "name": props.selectedSchool.attributes.Name,
                "email": Parse.User.current().attributes.email,
                "contact": "",
            },
            "notes": {
                "address": ""
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    const handelOnePay = (body) => {
        Parse.Cloud.run("function_getOnepayOrder", {
            body: body,

        }).then((response) => {
            // console.log("response", response)

            // setGatewayState(false);
            setOnePayRequestResponce(response);
        }, (error) => {
            console.log("error" + error.message)
        });
    }

    const removeSpecialCharacters = (str) => {
        return str.replace(/[^\w\s]/gi, '');
    }

    useEffect(() => {
        if (onepayRequestResponce) {
            setTimeout(() => {
                const pfForm = document.getElementById("payment-form");
                pfForm.submit();
            }, 1000);
        }
    }, [onepayRequestResponce]);

    const getStock = (itemId) => {
        if (props.inventoryItemStockMap && props.inventoryItemStockMap.length > 0) {
            let temp = props.inventoryItemStockMap.filter(
                (stock) => stock.attributes.itemId == itemId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.stock ?? 0)
            }
        }

        return 0
    }



    useEffect(() => {
        let countdownInterval;

        if (openCheckOutModal) {
            countdownInterval = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }

        return () => {
            clearInterval(countdownInterval);
        };
    }, [openCheckOutModal]);

    useEffect(() => {
        if (countdown == 0) {
            setOpenCheckOutModal(false);
            setCountdown(60);
        }
    }, [countdown]);

    const handleCheckout = () => {
        setOpenCheckOutModal(true);
        // const outOfStockItemsList = [];
        // for (const product of productList) {
        //     if (product.type == "kit") {
        //         return setOpenCheckOutModal(true);
        //     }
        //     if (getStock(product.itemId) < product.quantity) {
        //         outOfStockItemsList.push(product);
        //     }
        // }
        // setOutOfStockItems(outOfStockItemsList);

        // if (outOfStockItemsList.length === 0) {
        //     setOpenCheckOutModal(true);
        // }
    };

    useEffect(() => {
        if (updateItemId !== null) {
            const updatedProductList = productList.map((p) => {
                if (p.id == updateItemId) {
                    const availableStock = getStock(p.itemId);
                    const updatedQuantity = availableStock < p.quantity ? availableStock : p.quantity;
                    const updatedCost = p.unitCost * updatedQuantity;
                    return { ...p, quantity: updatedQuantity, cost: updatedCost };
                }
                return p;
            });
            setProductList(updatedProductList);
            setUpdateItemId(null);
        }
    }, [updateItemId, productList]);

    function countUPForOutOfStock(id, quantity, product) {
        setProductList(productList.map(p => (p.id === id ? { ...p, quantity: quantity, cost: p.unitCost * (quantity) } : p)));
        let cart = product.cartObject;
        cart.set('quantity', quantity);
        props.addInventoryCart(cart, "inc");
    }

    return (
        <div className="page-content">
            <Modal isOpen={openCheckOutModal}>
                <ModalHeader
                    toggle={() => {
                        setCountdown(0)
                        setOpenCheckOutModal(!openCheckOutModal)
                    }}>
                    Place Order
                </ModalHeader>
                <ModalBody>
                    <p>Place your order using</p>
                    {countdown > 0 && (
                        <p className='text-warning'>Time remaining: {countdown} seconds</p>
                    )}
                </ModalBody>
                <ModalFooter>
                    {false && <div className='d-flex justify-content-end'>
                        {onClickCheckOut ?
                            <button type="button" className="btn btn-light waves-effect">
                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                {" Checkout"}
                            </button> :
                            <button
                                onClick={() => {
                                    createInvoice(false)
                                    setOpenCheckOutModal(false)
                                }}
                                className="btn btn-primary m-1">
                                ₹ Pay Online
                            </button>

                        }
                        <button
                            onClick={() => {
                                createInvoice(true)
                                setOpenCheckOutModal(false)
                            }}
                            className="btn btn-primary m-1">
                            ₹ Cash On Delivary
                        </button>
                    </div>}

                    <div className='d-flex justify-content-end'>

                        <button
                            onClick={() => {
                                createInvoice(true)
                                setOpenCheckOutModal(false)
                            }}
                            className="btn btn-primary m-1">
                            Place Order
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
            {showKitDetailPopup && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Details Page"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    title="Alert!"
                    onCancel={() => {
                        setShowKitDetailPopup(false);
                    }}
                    onConfirm={() => {
                        props.history.push("/inventory-product-details/" + schoolId + "/" + showKitDetailPopup.type + "/" + showKitDetailPopup.productId)
                    }}
                >
                    {" To modify Item/kit details navigate to details page "}
                    <br></br>
                </SweetAlert>
            )}
            {onepayRequestResponce && <div className="App row align-items-center justify-content-center full-height">
                <div className="col-md-4">
                    <form method="POST" className="d-none" id="payment-form" action={onepayRequestResponce?.postUrl}>
                        <div><input defaultValue={onepayRequestResponce?.merchantId} type="text" {...register("merchantId", { required: true })} /></div>
                        <div><input defaultValue={onepayRequestResponce?.reqData} type="text" {...register("reqData", { required: true })} /></div>

                        <input type="submit" />
                    </form>

                    <div className="text-center">
                        <h4>Please wait...</h4>
                        <div>Do not refresh or press any</div>
                    </div>
                </div>
            </div>}

            <Topbar title="CART"></Topbar>
            <section >
                <div className="py-3 ">
                    <Container>
                        {showNotHavePaymentOptionAlert && (
                            <SweetAlert
                                warning
                                confirmBtnText="Close"
                                confirmBtnBsStyle="primary"
                                title="Alert!"
                                onConfirm={() => setShowNotHavePaymentOptionAlert(false)}
                            >
                                <div>
                                    {" "}
                                    The head office is currently not accepting online orders. Please contact the head office directly for further assistance.                        </div>
                            </SweetAlert>
                        )}

                        <Row>
                            <Col lx="8">
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive">
                                            {productList && productList.length > 0 ? <Table className="table table-centered mb-0 table-nowrap">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Product Desc</th>
                                                        {/* <th>Price</th> */}
                                                        <th>Quantity</th>
                                                        <th colSpan="2">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        productList.map((product) =>
                                                            <tr key={product.id}>
                                                                <td>
                                                                    <img src={product.image} alt="product-img" title="product-img" className="avatar-md" />
                                                                </td>
                                                                <td>
                                                                    <h5 className="font-size-14 text-truncate"><Link to={"/inventory-product-details/" + schoolId + "/" + product.type + "/" + product.productId} className="text-dark">{product.name}</Link></h5>
                                                                </td>
                                                                {/* <td>
                                                                    ₹ {product.unitCost}
                                                                </td> */}
                                                                <td>
                                                                    <div style={{ width: "60%" }}>
                                                                        <ButtonGroup>
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    if (product.type == "item") {
                                                                                        if (product.cartObject.attributes.kitItems) {
                                                                                            setShowKitDetailPopup(product)
                                                                                        } else {
                                                                                            countDown(product.id, product.quantity, product);
                                                                                        }
                                                                                    } else {
                                                                                        setShowKitDetailPopup(product)
                                                                                    }
                                                                                }}
                                                                                disabled={product.quantity <= 1}
                                                                            >
                                                                                -
                                                                            </Button>
                                                                            <Input
                                                                                type="text"
                                                                                value={product.quantity}
                                                                                name="demo_vertical"
                                                                                readOnly
                                                                                className='text-center'
                                                                            />
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    if (product.type == "item") {
                                                                                        if (product.cartObject.attributes.kitItems) {
                                                                                            setShowKitDetailPopup(product)
                                                                                        } else {
                                                                                            countUP(product.id, product.quantity, product);
                                                                                        }
                                                                                    } else {
                                                                                        setShowKitDetailPopup(product)
                                                                                    }

                                                                                }}
                                                                            // disabled={getStock(product.itemId) <= product.quantity}
                                                                            >
                                                                                +
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                        {false && <div>
                                                                            {product.type == "item" && getStock(product.itemId) <= product.quantity && <p className='text-danger text-center'>Maximum stock reached</p>}
                                                                        </div>}
                                                                        {outOfStockItems.find((item) => item.id == product.id) && (
                                                                            <div className="text-danger">
                                                                                {getStock(product.itemId) == 0 ? `${getStock(product.itemId)} item left.` : `Only ${getStock(product.itemId)} items left`} <p style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                                                    onClick={() => {
                                                                                        if (getStock(product.itemId) == 0) {
                                                                                            removeCartItem(product)
                                                                                        } else {
                                                                                            setUpdateItemId(product.id);
                                                                                            countUPForOutOfStock(product.id, getStock(product.itemId), product)
                                                                                            setOutOfStockItems((prevItems) => prevItems.filter((item) => item.id !== product.id));
                                                                                        }
                                                                                    }}>{getStock(product.itemId) == 0 ? "Remove" : "Update"}</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    ₹ {product.totalFinalCost}
                                                                </td>
                                                                <td>
                                                                    <Link to="#" onClick={() => removeCartItem(product)} className="action-icon text-danger"> <i className="mdi mdi-trash-can font-size-18"></i></Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }

                                                    <tr key={"Cost"}>

                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><strong>Total:</strong></td>
                                                        <td>
                                                            <Input
                                                                type="text"
                                                                name="cost"
                                                                value={"₹" + totalCost}
                                                                title="Total:"
                                                                readOnly
                                                            />

                                                        </td>
                                                        <td></td>
                                                    </tr>


                                                </tbody>
                                            </Table> :

                                                <div className='text-center'>
                                                    <p className='text-success'>Empty</p>
                                                    <Link to={"/inventory-products/" + schoolId} className="btn btn-secondary">
                                                        <i className="mdi mdi-arrow-left mr-1"></i> Continue Shopping </Link>
                                                </div>

                                            }
                                        </div>
                                        {(productList && productList.length > 0) && <Row className="d-flex justify-content-between m-2">
                                            <Col className='d-flex justify-content-start'>
                                                <Link to={"/inventory-products/" + schoolId} className="btn btn-secondary">
                                                    <i className="mdi mdi-arrow-left mr-1"></i> Continue Shopping </Link>
                                            </Col>
                                            <Col className='d-flex justify-content-end'>
                                                <div className="text-sm-right mt-2 mt-sm-0">
                                                    <button
                                                        onClick={() => {
                                                            handleCheckout()
                                                        }}
                                                        className="btn btn-success">
                                                        <i className="mdi mdi-cart-arrow-right mr-1"></i> Checkout
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>}
                                        {false && productOutOfStockEroor && <div className='float-end m-2'>
                                            <p className='text-danger'>Item is out of stock please Remove</p>
                                        </div>}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="4">

                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-3">Order Summary</CardTitle>

                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td>Total Products :</td>
                                                        <td>{productList.length}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total units : </td>
                                                        <td>{unitCount}</td>
                                                    </tr>

                                                    <tr>
                                                        <th>Total :</th>
                                                        <th>₹{totalCost}</th>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>

                                        {productList.length > 0 && <div className="d-flex justify-content-end mt-2 mt-sm-0 m-1">

                                            <button
                                                onClick={() => {
                                                    for (const product of productList) {
                                                        if (getStock(product.itemId) >= product.quantity) {
                                                            setOpenCheckOutModal(true)
                                                            setProductOutOfStockEroor(true)
                                                        }
                                                    }
                                                }}
                                                className="btn btn-success">
                                                <i className="mdi mdi-cart-arrow-right mr-1"></i> Checkout
                                            </button>

                                        </div>}
                                        {false && productOutOfStockEroor && <div className='float-end m-1'>
                                            <p className='text-danger'>Item is out of stock please Remove</p>
                                        </div>}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>


                    </Container>
                </div>
            </section>

            <Modal isOpen={onClickCheckOut} centered={true}>
                <ModalBody>
                    <div className="modal-body text-center mb-4 mt-4">
                        <h5 className="modal-title mt-0">
                            {pgResponse ? "Ready to Pay" : "Preparing For Payment "}
                        </h5>
                        {!pgResponse && <h1 className="display-2 font-weight-medium">
                            <i className="bx bx-loader bx-spin text-primary display-3"></i>
                        </h1>}
                        {pgResponse ? <h5>{"Please click below to make paymet"}</h5> : <h5>{"Please wait..."}</h5>}

                        {
                            pgResponse &&
                            <button
                                className='btn-primary btn-sm btn'
                                onClick={() => {
                                    window.open().document.write(pgResponse);
                                }}
                            >
                                Proceed to Payment
                            </button>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { inventoryCart } = state.InventorySaleOrder;
    const { inventoryKits } = state.InventoryKits;
    const { franchise } = state.Franchise;
    const { inventoryWarehouses, inventoryItemStockMap } = state.InventoryWarehouse;
    const { inventoryItems, inventoryItemsOfItemIds, inventorySubItems, inventoryItemCategoryPrices } = state.InventoryItems;

    return {
        selectedSchool, inventoryCart, inventoryKits, inventoryItemsOfItemIds, franchise, inventoryWarehouses, inventoryItems,
        inventoryItemStockMap, inventorySubItems, inventoryItemCategoryPrices
    };
}

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool,
    getCartForForOwnerId, addInventoryCart,
    getInventoryItemForItemIds, getInventoryKitsForOwnerId,
    getFranchise,
    getInventoryWarehouseForOwnerId,
    getInventoryItemForOwnerId,
    getInventoryStockForWarehouse,
    getInventorySubItemForItemIds,
    getInventoryItemCategoryPriceForItemIds,
})(InventoryCart)));