export const GET_LEAVE_MODULE_FOR_OWNER_ID = "GET_LEAVE_MODULE_FOR_OWNER_ID";
export const UPDATE_LEAVE_MODULE = "UPDATE_LEAVE_MODULE";
export const EDIT_LEAVE_DETAILS = "EDIT_LEAVE_DETAILS";
export const GET_LEAVE_POLICY = "GET_LEAVE_POLICY";
export const UPDATE_LEAVE_POLICY ="UPDATE_LEAVE_POLICY";
export const DELETE_LEAVE_POLICY = "DELETE_LEAVE_POLICY";
export const GET_LEAVE_POLICY_ITEMS_FOR_POLICY_ID = "GET_LEAVE_POLICY_ITEMS_FOR_POLICY_ID";
export const UPDATE_LEAVE_POLICY_ITEMS_FOR_POLICY_ID = "UPDATE_LEAVE_POLICY_ITEMS_FOR_POLICY_ID";
export const GET_LEAVE_CATEGORIES_FOR_OWNER_ID = "GET_LEAVE_CATEGORIES_FOR_OWNER_ID";
export const UPDATE_PAYROLL_CATEGORIES = "UPDATE_PAYROLL_CATEGORIES";
export const DELETE_PAYROLL_CATEGORIES = "DELETE_PAYROLL_CATEGORIES";

export const GET_MASTER_PAYSLIP_FOR_TEACHER_ID = "GET_MASTER_PAYSLIP_FOR_TEACHER_ID";
export const UPDATE_MASTER_PAYSLIP_FOR_TEACHER_ID = "UPDATE_MASTER_PAYSLIP_FOR_TEACHER_ID";
export const GET_MASTER_PAYSLIP_ITEMS_FOR_MASTER_PAYSLIP_ID = "GET_MASTER_PAYSLIP_ITEMS_FOR_MASTER_PAYSLIP_ID";
export const UPDATE_MASTER_PAYSLIP_ITEMS_FOR_MASTER_PAYSLIP_ID = "UPDATE_MASTER_PAYSLIP_ITEMS_FOR_MASTER_PAYSLIP_ID";

export const GET_PAYSLIP_FOR_MONTH = "GET_PAYSLIP_FOR_MONTH";
export const UPDATE_PAYSLIP_FOR_MONTH = "UPDATE_PAYSLIP_FOR_MONTH";
export const GET_PAYSLIP_ITEMS_FOR_PAYSLIP_ID = "GET_PAYSLIP_ITEMS_FOR_PAYSLIP_ID";
export const UPDATE_PAYSLIP_ITEMS_FOR_PAYSLIP_ID = "UPDATE_PAYSLIP_ITEMS_FOR_PAYSLIP_ID";

export const GET_PAYSLIPS_FOR_SCHOOL_ID_AND_MONTH = "GET_PAYSLIPS_FOR_SCHOOL_ID_AND_MONTH";
export const UPDATE_PAYSLIPS_FOR_SCHOOL_ID_AND_MONTH = "UPDATE_PAYSLIPS_FOR_SCHOOL_ID_AND_MONTH";

export const GET_PAYSLIP_FOR_ID = "GET_PAYSLIP_FOR_ID";
export const UPDATE_PAYSLIP_FOR_ID = "UPDATE_PAYSLIP_FOR_ID";

export const GET_PAYSLIPS_FOR_OWNER_ID_AND_OWNER_TYPE = "GET_PAYSLIPS_FOR_OWNER_ID_AND_OWNER_TYPE";
export const UPDATE_PAYSLIPS_FOR_OWNER_ID_AND_OWNER_TYPE = "UPDATE_PAYSLIPS_FOR_OWNER_ID_AND_OWNER_TYPE";
export const GET_HOLIDAY_LIST = "GET_HOLIDAY_LIST";
export const UPDATE_HOLIDAY_LIST = "UPDATE_HOLIDAY_LIST";
export const GET_PAYSLIP_ITEMS_FOR_PAYSLIP_IDS = "GET_PAYSLIP_ITEMS_FOR_PAYSLIP_IDS";
export const UPDATE_PAYSLIP_ITEMS_FOR_PAYSLIP_IDS = "UPDATE_PAYSLIP_ITEMS_FOR_PAYSLIP_IDS";
export const EDIT_PAYSLIP = "EDIT_PAYSLIP";
export const SET_SELECTED_MONTH = "SET_SELECTED_MONTH";