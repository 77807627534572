import React, { useEffect, useState } from 'react';
import { Container, FormGroup, Label, Button, Row, Col, Card, CardBody, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool, getTeachersForSchool, getLeavePolicy } from '../../store/actions';
import { matchPath } from "react-router";
import toastr from 'toastr';
import Parse from 'parse';

const LeavesAssigning = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/policies-assigning/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.schoolId;

    const [teachersList, setTeachersList] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [selectedTeacherError, setSelectedTeacherError] = useState('');
    const [policyOptions, setPolicyOptions] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState(null); 
    const [selectedPolicyError, setSelectedPolicyError] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            props.getTeachersForSchool(schoolId);
            props.getLeavePolicy(schoolId, 'school');
        }
    }, [schoolId]);

    useEffect(() => {
        if (props.teachers) {
            let teacherList = [];
            for (const teacher of props.teachers) {
                teacherList.push({ value: teacher.id, label: `${teacher.attributes.Name}` });
            }
            setTeachersList(teacherList);
        }
    }, [props.teachers]);

    useEffect(() => {
        if (props.leavesPolicies) {
            let policys = [];
            for (const policy of props.leavesPolicies) {
                policys.push({ value: policy.id, label: policy.attributes.policyTitle });
            }
            setPolicyOptions(policys);
        }
    }, [props.leavesPolicies]);

    const getTeacherName = (teacherId) => {
        if (props.teachers && props.teachers.length > 0) {
            let temp = props.teachers.filter(
                (teacher) => teacher.id === teacherId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const getDesignation = (role) => {
        if (role === 3) {
            return props.t("Director");
        } else if (role === 2) {
            return props.t("Center Head");
        } else {
            return props.t("Teacher");
        }
    };

    const assignPolicys = () => {
        let isValid = true;

        if (!selectedTeacher) {
            setSelectedTeacherError('Please select a teacher');
            isValid = false;
        }
        if (!selectedPolicy) {
            setSelectedPolicyError('Please select a policy');
            isValid = false;
        }

        if (isValid) {
            setLoading(true);

            const Teacher = Parse.Object.extend("Teacher");
            const query = new Parse.Query(Teacher);

            query.get(selectedTeacher.id).then((teacher) => {
                teacher.set("leavePolicyId", selectedPolicy);
                return teacher.save();
            }).then((savedTeacher) => {
                toastr.success("Teacher Policy Assigned", { timeOut: 3000 });
                setLoading(false);
                setModalOpen(false);
            }).catch((error) => {
                console.error('Error assigning teacher policy:', error);
                toastr.error("Error assigning teacher policy", { timeOut: 3000 });
                setLoading(false);
            });
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex justify-content-between align-items-start">
                    <Col lg={6} className="d-flex align-items-start">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { window.history.back() }}>
                            <ul className="list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className='m-2'>
                            <h4 className="font-size-18">Teachers Mapping Policies</h4>
                        </div>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <div>
                            <div className="table-responsive mt-3">
                                <Table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Teacher Name</th>
                                            <th>Designation</th>
                                            <th>Leave Policy</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.teachers && props.teachers.map((teacher, index) => (
                                            <tr key={teacher.id}>
                                                <td>{index+1}</td>
                                                <td>{getTeacherName(teacher.id)}</td>
                                                <td>{getDesignation(teacher.attributes.Role)}</td>
                                                <td>
                                                    {teacher.attributes.leavePolicyId ? (
                                                        <span onClick={() => {
                                                                setModalOpen(true);
                                                                setSelectedTeacher(teacher);
                                                                setSelectedPolicy(teacher.attributes.leavePolicyId);  
                                                        }}>
                                                            {props.leavesPolicies.find(policy => policy.id === teacher.attributes.leavePolicyId)?.attributes.policyTitle || ''}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ cursor: "pointer" }}
                                                            className="badge font-size-11 m-1 badge-soft-primary"
                                                            onClick={() => {
                                                                setModalOpen(true);
                                                                setSelectedTeacher(teacher);
                                                                setSelectedPolicy(null);
                                                            }}
                                                        >
                                                            {props.t("Assign")}
                                                            <i className="mdi mdi-chevron-down"></i>
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </CardBody>
                </Card>

                <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                    <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Assign Leave Policies</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="policys">Select Policys</Label>
                            {policyOptions.map((policy) => (
                                <div key={policy.value} className="form-check">
                                    <Input
                                        type="checkbox"
                                        id={`policy-${policy.value}`}
                                        checked={selectedPolicy === policy.value} 
                                        onChange={() => setSelectedPolicy(policy.value)}
                                        className="form-check-input"
                                    />
                                    <Label
                                        check
                                        htmlFor={`policy-${policy.value}`}
                                        className="form-check-label"
                                    >
                                        {policy.label}
                                    </Label>
                                </div>
                            ))}
                            {selectedPolicyError && <span className="text-danger">{selectedPolicyError}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setModalOpen(false)}>Cancel</Button>
                        <Button color="primary" onClick={assignPolicys} disabled={loading}>
                            {loading ? 'Assigning...' : 'Assign Leave Policies'}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { teachers } = state.Teacher;
    const { leavesPolicies } = state.LeaveModule;
    return { selectedSchool, teachers, leavesPolicies };
};

export default connect(mapStatetoProps, {
    setSelecetdSchool, getTeachersForSchool, getLeavePolicy,
})(withRouter(withNamespaces()(LeavesAssigning)));
