import React, { useEffect, useState } from "react";
import {
  Modal,
  FormGroup,
  Label,
  Button,
} from "reactstrap";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import toastr from "toastr";

import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import {
  createClassForASchool,
  getAcademicCycleForSchoolId,
} from "../../store/actions";

const AddClass = (props) => {

  const [editClassDetailsName, editClassDetailsNameOps] = useState("");
  const [editClassDetailstype, editClassDetailstypeOps] = useState("");
  const [editClassType, editClassTypeOps] = useState(0);

  let match = matchPath(props.history.location.pathname, {
    path: "/allClasses/:id",
    exact: false,
    strict: false,
  });
  if (match === null) {
    match = matchPath(props.history.location.pathname, {
      path: "/student/:id",
      exact: false,
      strict: false,
    });
  }
  if (match === null) {
    match = matchPath(props.history.location.pathname, {
      path: "/staffprofile/:id/:teacherId",
      exact: false,
      strict: false,
    });
  }
  if (match === null) {
    match = matchPath(props.history.location.pathname, {
      path: "/coordinators-profile/:id/:teacherId",
      exact: false,
      strict: false,
    });
  }
  if (match === null) {
    match = matchPath(props.history.location.pathname, {
      path: "/franchise-adminprofile/:franchiseId/:teacherId",
      exact: false,
      strict: false,
    });
  }
  if (match === null) {
    match = matchPath(props.history.location.pathname, {
      path: "/classroom/:id/:classId",
      exact: false,
      strict: false,
    });
  }
  const tempschoolId = match.params.id
    ? match.params.id : "";
    const franchiseId = match && match.params.franchiseId;
    
    function getSchoolId(tempschoolId) {

    if (tempschoolId && tempschoolId.startsWith("d-")) {
      var res = tempschoolId.split("-");
      return res[1];
    }
    return "";
  }
  const schoolId = (!tempschoolId || tempschoolId.startsWith("d-")) ? getSchoolId(tempschoolId) : tempschoolId;


  useEffect(() => {
    if (schoolId && schoolId.length > 0) {
      props.getAcademicCycleForSchoolId(schoolId);
    }
  }, [schoolId]);

  const submitForcreatingclass = () => {
    let classDetails = {};
    classDetails.name = editClassDetailsName;
    classDetails.level = editClassDetailstype;
    classDetails.schoolId = schoolId;
    classDetails.classroomType = editClassType;
    props.createClassForASchool(classDetails);
    props.tog_standard();
    toastr.success("Class added successfully !!", { timeOut: 1000 });

    editClassDetailsNameOps(undefined);
    editClassDetailstypeOps(undefined);

  };
  return (
    <Modal
      isOpen={props.modal_standard}
      toggle={() => {
        props.tog_standard();
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {props.t("Add Class")}
        </h5>
        <button
          type="button"
          onClick={() => {
            props.tog_standard();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <AvForm className="outer-repeater" onValidSubmit={submitForcreatingclass}>
        <div className="modal-body">
          <FormGroup>
            <Label htmlFor="formname">{props.t("Class Name")}:</Label>
            <AvField
              type="text"
              name="name"
              placeholder={props.t("Enter Class Name...")}
              className="form-control"
              value={editClassDetailsName}
              validate={{
                required: {
                  value: true,
                  errorMessage: props.t("Please enter class name..."),
                },
              }}
              onChange={(e) => {
                editClassDetailsNameOps(e.target.value);
              }}
            />
          </FormGroup>
          <Label htmlFor="formname">{props.t("Academic cycle")}: </Label>
          <FormGroup>
            <select
              className="form-control"
              onChange={(e) => {
                if (e.target.value === "addOrEdit") {
                  props.history.push("/school/" + schoolId + "/cycles");
                } else {

                  let tempCyc = props.academicCycles.filter(cyc => cyc.attributes.schoolCycle === e.target.value || cyc.attributes.defaultCycle === e.target.value);
                  if (tempCyc && tempCyc.length > 0) {
                    editClassDetailstypeOps(tempCyc[0].attributes.schoolCycle ? tempCyc[0].attributes.schoolCycle : tempCyc[0].attributes.defaultCycle);
                    if (tempCyc[0].attributes.level === "Daycare") {
                      editClassTypeOps(2)
                    } else {
                      editClassTypeOps(0)
                    }
                  } else {
                    editClassDetailstypeOps(e.target.value);
                  }

                }

              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: props.t("Please enter class name..."),
                },
              }}
              value={editClassDetailstype}
            >
              {!editClassDetailstype && <option key={0} value=""></option>}

              {props.academicCycles && props.academicCycles.length > 0 &&

                props.academicCycles.map((cycle) => (
                  <option key={cycle.id} value={cycle.attributes.schoolCycle ? cycle.attributes.schoolCycle : cycle.attributes.defaultCycle}>
                    {cycle.attributes.schoolCycle ? cycle.attributes.schoolCycle : cycle.attributes.defaultCycle}
                  </option>

                ))

              }

              {(!props.academicCycles || props.academicCycles.length === 0) &&
                <div>

                  <option key={1} value="SrKG">
                    SrKG
                  </option>
                  <option key={2} value="JrKG">
                    JrKG
                  </option>
                  <option key={3} value="Nursery">
                    Nursery
                  </option>

                </div>
              }

              <option key={""} value="addOrEdit" >
                *Add or Edit Academic cycle *
              </option>


            </select>
          </FormGroup>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              props.tog_standard();
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
          <Button type="submit" color="primary">
            {props.t("Save Class")}
          </Button>
        </div>
      </AvForm>
    </Modal>
  );
};
const mapStatetoProps = (state) => {
  const { academicCycles, } = state.AcademicCycles;
  return { academicCycles, };
};
export default connect(mapStatetoProps, {
  createClassForASchool,
  getAcademicCycleForSchoolId,

})(withRouter(withNamespaces()(AddClass)));
