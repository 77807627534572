import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Table, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import { withNamespaces } from 'react-i18next';
import Switch from "react-switch";
import * as Constants from '../../Constents';
import SweetAlert from "react-bootstrap-sweetalert";
import Parse from "parse";



const TeacherPermissions = (props) => {
    const [readSwith, setReadSwith] = useState(true);
    const [writeSwitch, setWriteSwith] = useState(true);

    const [enableSwitch, setEnableSwith] = useState(true);
    const [showWebAccessRemovalAlert, setShowWebAccessRemovalAlert] = useState(false);

    const [showWebAccessRequestPopup, setShowWebAccessRequestPopup] = useState(false);
    const [currentItemForWebRequest, setCurrentItemForWebRequest] = useState(undefined);

    const [showFillEmailMessage, setShowFillEmailMessage] = useState(false);



    const [teacherFeatureList, setTeacherFeatureList] = useState([]);
    const [adminFeaturesList, setAdminFeaturesList] = useState([]);

    const isSuperUser = Parse.User.current() && Parse.User.current().attributes.isSuperUser && props.canEdit;
    
    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            > No
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            > Yes
            </div>
        );
    };



    const featureList = ["Messaging", "Learning management system", "Attendance", "Daycare",
        "Pick And Drop", "Medical Details", "Student Details","Groups", "Enquiry","Fee Management","Programs",
        "Expenses", "Transport",]
    const accdemyFeatureList = ["Course",]

    const adminFeatures = ["CCTV View Access", "Finance Admin", "Web Portal Login","LMS Content"];
    const [webResponse, setWebResponse] = useState(undefined);

    useEffect(() => {
        if (props.selectedSchool && props.selectedTeacher) {
            if (props.selectedSchool.attributes.schoolType === 10) {
                prepareAccdemyFeatureList()
            } else {
                prepareFeatureList();
            }
        }
        
        
    }, [props.selectedTeacher,props.selectedSchool ])


    useEffect(() => {
        if (props.franchiseId && props.isFranchiser) {
            prepareFeatureList();
        }
    }, [props.franchiseId ])

    useEffect(() => {
        if (props.webAccessResponse) {
            setWebResponse(props.webAccessResponse)
        }
    }, [props.webAccessResponse])

    function prepareAccdemyFeatureList() {
        var teacherFeatureList = [];
        for (const i in accdemyFeatureList) {
            var featureObject = null;
            if (accdemyFeatureList[i] === "Course") {
                if (checkSchoolForFeatur(Constants.FEATURE_COURSE)) {
                    featureObject = checkTeacherForFeatur(Constants.TEACHER_COURSE);
                    featureObject.index = Constants.TEACHER_COURSE;
                    featureObject.title = props.t('Course');
                    featureObject.type = 'Course';
                    teacherFeatureList.push(featureObject);
                }
            }
        }
        setTeacherFeatureList(teacherFeatureList);

        setAdminList();
    }


    function prepareFeatureList() {
        var teacherFeatureList = [];
        for (const i in featureList) {
            var featureObject = null;
            if (featureList[i] === "Messaging") {
                featureObject = checkTeacherForFeatur(Constants.TEACHER_MESSAGING_INDEX);
                featureObject.index = Constants.TEACHER_MESSAGING_INDEX;
                featureObject.title = props.t('Messaging');
                featureObject.type = 'Messaging';
                teacherFeatureList.push(featureObject);
            } else if (featureList[i] === "Learning management system") {
                if (checkSchoolForFeatur(Constants.FEATURE_ACTIVITY_TRACKING_INDEX) || checkSchoolForFeatur(Constants.FEATURE_E_LEARNING)) {
                    featureObject = checkTeacherForFeatur(Constants.TEACHER_CHILD_DEVELOPMENT_INDEX);
                    featureObject.index = Constants.TEACHER_CHILD_DEVELOPMENT_INDEX;
                    featureObject.title = props.t('Learning management system');
                    featureObject.type = 'Learning management system';
                    teacherFeatureList.push(featureObject);
                }
            } else if (featureList[i] === "Attendance") {
                if (checkSchoolForFeatur(Constants.FEATURE_ATTENDANCE_INDEX)) {
                    featureObject = checkTeacherForFeatur(Constants.TEACHER_ATTENDANCE_INDEX);
                    featureObject.index = Constants.TEACHER_ATTENDANCE_INDEX;
                    featureObject.title = props.t('Attendance');
                    featureObject.type = 'Attendance';
                    teacherFeatureList.push(featureObject);
                }
            } else if (featureList[i] === "Daycare") {
                if (checkSchoolForFeatur(Constants.FEATURE_CUSTOM_DIARY_INDEX)) {
                    featureObject = checkTeacherForFeatur(Constants.TEACHER_DAYCARE_INDEX);
                    featureObject.index = Constants.TEACHER_DAYCARE_INDEX;
                    featureObject.title = props.t('Daycare');
                    featureObject.type = 'Daycare';
                    teacherFeatureList.push(featureObject);
                }
            } else if (featureList[i] === "Pick And Drop") {
                if (checkSchoolForFeatur(Constants.FEATURE_PICKNDROP_INDEX)) {
                    featureObject = checkTeacherForFeatur(Constants.TEACHER_PICK_DROP_INDEX);
                    featureObject.index = Constants.TEACHER_PICK_DROP_INDEX;
                    featureObject.title = props.t('Pick And Drop');
                    featureObject.type = 'Pick And Drop';
                    teacherFeatureList.push(featureObject);
                }
            } else if (featureList[i] === "Medical Details") {
                if (checkSchoolForFeatur(Constants.FEATURE_MEDICAL_INDEX)) {
                    featureObject = checkTeacherForFeatur(Constants.TEACHER_MEDICAL_DETAILS_INDEX);
                    featureObject.index = Constants.TEACHER_MEDICAL_DETAILS_INDEX;
                    featureObject.title = props.t('Medical Details');
                    featureObject.type = 'Medical Details';
                    teacherFeatureList.push(featureObject);
                }
            } else if (featureList[i] === "Student Details") {
                featureObject = checkTeacherForFeatur(Constants.TEACHER_CHILD_DETAILS_INDEX);
                featureObject.index = Constants.TEACHER_CHILD_DETAILS_INDEX;
                featureObject.title = props.t('Student Details');
                featureObject.type = 'Student Details';
                teacherFeatureList.push(featureObject);
            } else if (featureList[i] === "Groups") {
                featureObject = checkTeacherForFeatur(Constants.TEACHER_GROUPS);
                featureObject.index = Constants.TEACHER_GROUPS;
                featureObject.title = props.t('Groups');
                featureObject.type = 'Groups';
                teacherFeatureList.push(featureObject);
            }else if (featureList[i] === "Enquiry") {
                featureObject = checkTeacherForFeatur(Constants.TEACHER_ENQUIRY);
                featureObject.index = Constants.TEACHER_ENQUIRY;
                featureObject.title = props.t('Enquiry');
                featureObject.type = 'Enquiry';
                teacherFeatureList.push(featureObject);
            }else if (featureList[i] === "Fee Management" && props.selectedTeacher
                && (props.selectedTeacher.attributes.Role == 3 || props.selectedTeacher.attributes.Role == 2 || props.selectedTeacher.attributes.Role == 10 || props.selectedTeacher.attributes.Role == 11 || props.selectedTeacher.attributes.Role == 12 || props.selectedTeacher.attributes.Role == 13 || props.selectedTeacher.attributes.Role == 14)) {
                if (checkSchoolForFeatur(Constants.FEATURE_PAYMENTS_INDEX)) {
                    featureObject = checkTeacherForFeatur(Constants.TEACHER_PAYMENTS_INDEX);
                    featureObject.index = Constants.TEACHER_PAYMENTS_INDEX;
                    featureObject.title = props.t('Fee Management');
                    featureObject.type = 'Fee Management';
                    teacherFeatureList.push(featureObject);
                }
            } else if (featureList[i] === "Programs" && props.selectedTeacher
                && (props.selectedTeacher.attributes.Role == 3 || props.selectedTeacher.attributes.Role == 2 || props.selectedTeacher.attributes.Role == 10 || props.selectedTeacher.attributes.Role == 11 || props.selectedTeacher.attributes.Role == 12 || props.selectedTeacher.attributes.Role == 13 || props.selectedTeacher.attributes.Role == 14 )) {
                if (checkSchoolForFeatur(Constants.FEATURE_PROGRAM_INDEX)) {
                    featureObject = checkTeacherForFeatur(Constants.TEACHER_PROGRAMS);
                    featureObject.index = Constants.TEACHER_PROGRAMS;
                    featureObject.title = props.t('Programs');
                    featureObject.type = 'Programs';
                    teacherFeatureList.push(featureObject);
                }
            }else if (featureList[i] === "Expenses") {
                if (checkSchoolForFeatur(Constants.FEATURE_EXPENSE_INDEX)) {
                    featureObject = checkTeacherForFeatur(Constants.TEACHER_EXPENSE_INDEX);
                    featureObject.index = Constants.TEACHER_EXPENSE_INDEX;
                    featureObject.title = props.t('Expenses');
                    featureObject.type = 'Expenses';
                    teacherFeatureList.push(featureObject);
                }
            }
            else if (featureList[i] === "Transport") {
                if (checkSchoolForFeatur(Constants.FEATURE_TRANSPORT_INDEX)) {
                    featureObject = checkTeacherForFeatur(Constants.TEACHER_TRANSPORT_INDEX);
                    featureObject.index = Constants.TEACHER_TRANSPORT_INDEX;
                    featureObject.title = props.t('Transport');
                    featureObject.type = 'Transport';
                    teacherFeatureList.push(featureObject);
                }
            }
        }
        setTeacherFeatureList(teacherFeatureList);

        setAdminList();
    }

    function setAdminList() {
        var adminFeaturesList = [];
        for (const i in adminFeatures) {
            var featureObject = null;;
            if (adminFeatures[i] === "CCTV View Access" && props.selectedTeacher && (props.selectedTeacher.attributes.Role == 3 || props.selectedTeacher.attributes.Role == 2|| props.selectedTeacher.attributes.Role == 10 || props.selectedTeacher.attributes.Role == 11 || props.selectedTeacher.attributes.Role == 12 || props.selectedTeacher.attributes.Role == 13 || props.selectedTeacher.attributes.Role == 14 )) {
                if (checkSchoolForFeatur(Constants.FEATURE_CCTV_INDEX)) {
                    featureObject = checkTeacherForFeatur(Constants.TEACHER_CCTV_INDEX);
                    featureObject.index = Constants.TEACHER_CCTV_INDEX
                    featureObject.title = props.t('CCTV View Access');
                    featureObject.type = 'CCTV View Access';
                    featureObject.enabled = featureObject.write || featureObject.read;
                    adminFeaturesList.push(featureObject);
                }
            } else if (adminFeatures[i] === "Finance Admin" && props.selectedTeacher
                && (props.selectedTeacher.attributes.Role == 3 || props.selectedTeacher.attributes.Role == 2 || props.selectedTeacher.attributes.Role == 10 || props.selectedTeacher.attributes.Role == 11 || props.selectedTeacher.attributes.Role == 12 || props.selectedTeacher.attributes.Role == 13 || props.selectedTeacher.attributes.Role == 14 )) {

                if (checkSchoolForFeatur(Constants.FEATURE_PAYMENTS_INDEX)) {
                    let temp=checkTeacherForFeatur(Constants.TEACHER_PAYMENTS_INDEX) 
                    if (temp.write) {
                        featureObject = checkTeacherForFeatur(Constants.TEACHER_PAYMENT_ADMINISTRATOR);
                        if (featureObject) {
                            featureObject.index = Constants.TEACHER_PAYMENT_ADMINISTRATOR
                            featureObject.title = props.t('Finance Admin');
                            featureObject.type = 'Finance Admin';
                            featureObject.enabled = featureObject.write || featureObject.read;
                            adminFeaturesList.push(featureObject);
                        }
                    }
                    
                }
            } else if (adminFeatures[i] === "Web Portal Login" && props.selectedTeacher
                && (props.selectedTeacher.attributes.Role == 3 || props.selectedTeacher.attributes.Role == 2 || props.selectedTeacher.attributes.Role == 10 || props.selectedTeacher.attributes.Role == 11 || props.selectedTeacher.attributes.Role == 12 || props.selectedTeacher.attributes.Role == 13 || props.selectedTeacher.attributes.Role == 14 )) {


                featureObject = checkTeacherForFeatur(-1);
                if (featureObject) {
                    featureObject.title = props.t('Web Portal Login');
                    featureObject.type = 'Web Portal Login';
                    featureObject.enabled = props.selectedTeacher.attributes.isWebAccess;
                    adminFeaturesList.push(featureObject);
                }
            }
            else if (adminFeatures[i] === "LMS Content" && props.selectedSchool && props.selectedSchool.attributes.franchiseId === "NKFdJul9rK" && props.selectedTeacher ) {
                if( checkSchoolForFeatur(Constants.FEATURE_ACTIVITY_TRACKING_INDEX)){
                    featureObject = checkTeacherForFeatur(Constants.TEACHER_LMS_CONTENT_ACCESS);
                    if (featureObject ) {
                        featureObject.index = Constants.TEACHER_LMS_CONTENT_ACCESS;
                        featureObject.title = props.t("LMS Content");
                        featureObject.type = "LMS Content";
                        featureObject.enabled = featureObject.write || featureObject.read; 
                        adminFeaturesList.push(featureObject);
                    }
                }
            }

        }

        setAdminFeaturesList(adminFeaturesList);
    }




    function checkSchoolForFeatur(schoolIndex) {
        if(props.franchiseId && props.isFranchiser){
            return true;
        }
        if (props.selectedSchool && props.selectedSchool.attributes) {
            const schollfeature = props.selectedSchool.attributes.featuresList;
            if (schollfeature && schollfeature.length > 0) {
                if (schollfeature[schoolIndex] > 0) {
                    return true;
                }
            }
        }
        return false;
    }
    function checkTeacherForFeatur(teacherIndex) {

        var featureObject = { title: "", type: "", read: false, write: false, enabled: false, schoolIndex: -1, teacherIndex: teacherIndex }

        if (props.selectedTeacher && props.selectedTeacher.attributes) {
            const teacherPermissions = props.selectedTeacher && props.selectedTeacher.attributes.featurePermissions;
            if (teacherPermissions && teacherPermissions.length > 0 && teacherIndex >= 0) {
                if (teacherPermissions[teacherIndex] == 0) {
                    featureObject.read = false;
                    featureObject.write = false;
                } else if (teacherPermissions[teacherIndex] == 1) {
                    featureObject.read = true;
                    featureObject.write = false;
                } else if (teacherPermissions[teacherIndex] == 2) {
                    featureObject.read = true;
                    featureObject.write = true;
                }
            } else if (teacherIndex === Constants.TEACHER_PAYMENT_ADMINISTRATOR) {
                featureObject.read = false;
                featureObject.write = false;
            }
        }

        return featureObject;
    }

    const handelReadSwitch = (item, index) => {

        var list = [...teacherFeatureList];
        var tempObject = item;
        tempObject.read = !item.read;
        tempObject.write = false;
        list[index] = tempObject;
        setTeacherFeatureList(list);
        if (item.index >= 0) {
            prepareList();
        }
    }
    const handelWriteSwitch = (item, index) => {
        var list = [...teacherFeatureList];
        var tempObject = item;
        tempObject.write = !item.write;
        if (tempObject.write) {
            tempObject.read = true;
        }
        list[index] = tempObject;
        setTeacherFeatureList(list);

        if (item.index >= 0) {
            prepareList();
        }

    }

    const turnOffSwitchForWebAccess = () => {

        if (webResponse == undefined || !webResponse.UserName ) {
            var list = [...adminFeaturesList];
            var tempObject = currentItemForWebRequest.item;
            tempObject.enabled = false;
            list[currentItemForWebRequest.index] = tempObject;
            setAdminFeaturesList(list);

        }

    }

    const turnOnSwitchForWebAccess = () => {

        var list = [...adminFeaturesList];
        var tempObject = currentItemForWebRequest.item;
        tempObject.enabled = true;
        list[currentItemForWebRequest.index] = tempObject;
        setAdminFeaturesList(list);

    }

    const handleWebRequest = () => {
        //Check for email here
        if (props.selectedTeacher.attributes.EMail != null && props.selectedTeacher.attributes.EMail !== "") {
            props.onRequestForWebAccess()
        } else {
            setShowFillEmailMessage(true)
        }
    }

    const handelEnableSwitch = (item, index) => {
        var list = [...adminFeaturesList];
        var tempObject = item;
        tempObject.enabled = !item.enabled;
        list[index] = tempObject;
        setAdminFeaturesList(list);
        if (item.index >= 0) {
            prepareList();
        } else if (item.type === "Web Portal Login") {
            //web portal function call
            //switch off do u want to disable web permissions for this...
            //isWebAccess = false
            //featureObject.enabled = props.selectedTeacher.attributes.isWebAccess;
            // enable email id is there or not
            //request for web access.


            if (item.enabled) {
                setCurrentItemForWebRequest({ item: item, index: index });
                setShowWebAccessRequestPopup(true);
            } else {
                console.log("testing off")
                setCurrentItemForWebRequest({ item: item, index: index })
                setShowWebAccessRemovalAlert(true)
            }
        }
    }
    const handleWebAccessRemoval = () => {
        setShowWebAccessRemovalAlert(false);
        props.onChangeWebAccess(false)
    }


    function prepareList() {
        var teacherList = [...teacherFeatureList];
        var adminList = [...adminFeaturesList];
        var newFeatureList = [];


        for (var i = 0; i <= Constants.TEACHER_LMS_CONTENT_ACCESS; i++) {
            if ((i === Constants.TEACHER_LMS_CONTENT_ACCESS || i === Constants.TEACHER_CCTV_INDEX  || i === Constants.TEACHER_PAYMENT_ADMINISTRATOR)&& adminList.length > 0) {
                var current = adminList.filter(item => item.index === i);
                if (current.length > 0) {
                    if (current[0].enabled) {
                        newFeatureList.push(1);
                    } else {
                        newFeatureList.push(0);
                    }

                } else {
                    newFeatureList.push(0)
                }

            } else {
                var current = teacherList.filter(item => item.index === i);
                if (current.length > 0) {
                    if (current[0].write) {
                        newFeatureList.push(2);
                    } else if (current[0].read) {
                        newFeatureList.push(1);
                    } else {
                        newFeatureList.push(0);
                    }

                } else {
                    newFeatureList.push(0)
                }
            }

        }

        props.onChangeFeatureList(newFeatureList);
    }

    return (
        <React.Fragment>
            {showWebAccessRemovalAlert && <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Alert!"
                onCancel={() => {
                    setShowWebAccessRemovalAlert(false);
                    turnOnSwitchForWebAccess()
                }}
                onConfirm={() => handleWebAccessRemoval()}
            >
                {props.t('Do you want to disable web permissions for this staff?')}<br></br>
            </SweetAlert>}
            {(props.selectedSchool && props.selectedSchool.attributes.schoolType!==10)&&  <p className="card-title-desc">{props.t('Based on your selection features are shown to your staff in mobile application')}</p>}
            {<Table className="table-centered table-nowrap table-hover">
                <thead className="thead-light" >
                    <tr>
                        <th scope="col" style={{ width: "70px" }}>#</th>
                        <th scope="col" style={{ width: "450px" }} >{props.t('Feature Name')}</th>
                        <th scope="col">{props.t('Read')}</th>
                        <th scope="col">{props.t('Write')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        teacherFeatureList.map((item, i) =>
                            <tr key={"_user_" + i} >
                                <td>{i + 1} </td>
                                <td> {item.title}</td>
                                <td>
                                    <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        onColor="#626ed4"
                                        onChange={() => { handelReadSwitch(item, i) }}
                                        checked={item.read??false}
                                        disabled={!isSuperUser}

                                    />
                                </td>
                                <td>
                                    <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        onColor="#626ed4"
                                        onChange={() => { handelWriteSwitch(item, i) }}
                                        checked={item.write??false}
                                        disabled={!isSuperUser}
                                    />
                                </td>


                            </tr>
                        )
                    }

                </tbody>
            </Table>}

            {adminFeaturesList && adminFeaturesList.length > 0 &&
                <Table className="table-centered table-nowrap table-hover">
                    <thead className="thead-light" style={{ height: '0' }}>
                        <tr>
                            <th scope="col" style={{ width: "70px" }}>#</th>
                            <th scope="col" style={{ width: "450px" }}>{props.t('Admin Features')}</th>
                            <th scope="col">{props.t('Enabled')}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            adminFeaturesList.map((item, i) =>
                                <tr key={"_user_" + i} >
                                    <td>{i + 1}</td>
                                    <td> {item.title}</td>
                                    <td>
                                        <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            onColor="#626ed4"
                                            onChange={() => { handelEnableSwitch(item, i) }}
                                            checked={item.enabled??false}
                                            disabled={!isSuperUser}
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                            )
                        }

                    </tbody>
                </Table>}
            <Modal isOpen={showWebAccessRequestPopup}>


                <ModalHeader>

                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t('Request for Web Access')}
                    </h5>
                </ModalHeader>
                <ModalBody >





                    {props.selectedTeacher && webResponse == undefined && !showFillEmailMessage && <p className=" m-4">{props.t('Are you sure you want to give Web Access for ')+props.selectedTeacher.attributes.Name}</p>}
                    {props.selectedTeacher && webResponse == undefined && showFillEmailMessage && <p className=" m-4">{'Please update  email id for '+props.selectedTeacher.attributes.Name+' , then try again'}</p>}

                    {
                        webResponse != undefined && <div>
                            {webResponse.UserName && webResponse.UserName.length > 0  ?
                                <div>
                                    {
                                        webResponse.Password && webResponse.Password.length > 0 ?
                                            <div>
                                                <p className=" m-4">{props.t('User Name/Email')} - {webResponse.UserName}</p>
                                                <p className=" m-4">{props.t('Password')} - {webResponse.Password}</p>
                                            </div> : <div>
                                                <p className=" m-4">{props.t('User Name/Email')} - {webResponse.UserName}</p>
                                                <p className=" m-4">{"Password has been created already, Please ask "+props.selectedTeacher.attributes.Name+" to use existing password or reset using web portal"}</p>
                                            </div>
                                    }
                                    
                                </div>
                                : <div>
                                    {props.selectedTeacher && <p className=" m-4">
                                        {"The email associated with " + props.selectedTeacher.attributes.Name + " (" + props.selectedTeacher.attributes.EMail + ") already taken. Please change email and try again"}
                                    </p>}
                                </div>}
                        </div>
                    }


                </ModalBody>

                <ModalFooter>
                    {true ? <Row className="text-center">
                        <button
                            type="button"
                            onClick={() => {
                                turnOffSwitchForWebAccess()
                                setShowWebAccessRequestPopup(false)
                                props.clearWebAccessResponce();
                            }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                        >
                            {showFillEmailMessage ?props.t('Close'):props.t('Cancel')}
                        </button>
                        {webResponse == undefined && !showFillEmailMessage && <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light ml-2"
                            onClick={() => {
                                handleWebRequest()
                            }}
                        >
                            {props.t('Accept')}
                        </button>}

                    </Row> : <button
                        type="button" className="btn btn-light waves-effect">

                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Moving to Admissions')}

                        </button>

                    }


                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};



export default withNamespaces()(TeacherPermissions);