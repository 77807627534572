import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import {
  CardSubtitle,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import Moment from "moment";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';
import {
  getTeachersForSchool,
  getClassroomsForSchoolId,
  getParentsForFamilyIds,
} from "../../store/actions";
import StudentExitReport from "../reports/franchise/StudentExitReport"
import DaywiseSummary from './DaywiseSummaryReport'
import NewAdmissionReport from "../reports/franchise/NewAdmissionReport"
import CctvReport from "./CctvReport"
import TripsReport from "./TripsReport";
import DairyReport from "./DairyReport";
import Select from "react-select";
import Parse from "parse";

const SchoolReport = (props) => {
  const [reportType, setReportType] = useState("1");
  const [gotReport, setGotReport] = useState(false);
  const [allData, setAllData] = useState([]);
  const [requested, setRequested] = useState(false);
  const [academicYear, setAcademicYear] = useState({ value: "all", label: "All" });
  const [status, setStatus] = useState({ value: "active", label: "Active"});
  const [isLoading, setIsLoading] = useState(false);
  
  //dec
  const [allClassRooms, allClassRoomsOps] = useState([]);
  useEffect(() => {
    if (props.schoolId && requested) {
      props.getClassroomsForSchoolId(props.schoolId);
    }
  }, [props.schoolId,requested]);
  const generateReport = () => {
    setGotReport(false);
    setRequested(true);
    setIsLoading(true);
    if (reportType === "1") {
      getTeachersForSelectedFilter();
    } else if (reportType === "2") {
      getStudentsForSelctedFilter();
    }
  };
  useEffect(() => {
    if ((props.teacherReport || props.kidReport) && requested) {
      setGotReport(true);
    } else {
      setGotReport(false);
    }
  }, [props.teacherReport, props.kidReport]);
  const academicYearList = [
    { value: "all", label: "All" },
    { value: "2025-26",year:"26",years:["25","26","2025","2026"], label: "2025-26" },
    { value: "2024-25",year:"25",years:["24","25"], label: "2024-25" },
    { value: "2023-24",year:"24",years:["23","24"], label: "2023-24" },
    { value: "2022-23",year:"23",years:["22","23"], label: "2022-23" },
    { value: "2021-22",year:"22",years:["21","22"] , label: "2021-22" },

  ];

  const statusList = [
    { value: "all", label: "All" },
    { value: "active", label: "Active"},
    { value: "archives", label: "Archived(DeActive)"},
  ]
  useEffect(() => {
    let tempClass = [];
    for (const val of props.classrooms) {
      let temp = {};
      temp.id = val.id;
      temp.name = val.attributes.Name;
      temp.type = val.attributes.classroomType; //type 2 daycare type 1/undefine class
      temp.obj = val;
      tempClass.push(temp);
    }
    allClassRoomsOps(tempClass);
  }, [props.classrooms,]);

  const getTeachersForSelectedFilter = () => {
    let query = new Parse.Query("Teacher");
    query.equalTo("SchoolID", props.schoolId);
    query.ascending("Name");
    query.limit(100000);
    if (status && status.value !== "all") {
      if (status.value === "active") {
        query.notEqualTo("deleted", true);
      } else if (status.value === "archives") {
        query.equalTo("deleted", true);
      }
    }
    query.find().then((results) => {
      processTeachersData(results);
      setIsLoading(false);
    });
  }
  
  const getStudentsForSelctedFilter=()=>{
    let query = new Parse.Query("Kid");
         
    if (academicYear && academicYear.value != "all") {
      let query1 = new Parse.Query("Kid");
      query1.endsWith("admissionYear", academicYear.year)
      
      let query2 = new Parse.Query("Kid");
      query2.contains("admissionNumber", academicYear.value)

      query = Parse.Query.or(query1, query2);
    } 

    query.equalTo("SchoolID", props.schoolId);
    query.ascending("Name");
    query.limit(10000);

    if (status && status.value != "all") {
      if (status.value == "active") {
        query.notEqualTo("Deleted", true);
      } else if (status.value === "archives") {
        query.equalTo("Deleted", true);
      }
    }
    
    query.find().then((results) => {
      processKidsData(results);
      setIsLoading(false);
    });
  }

  const processTeachersData = (teachers) => {
    let tempTeacher = [];
    for (const val of teachers) {
      let temp = {};
      temp.id = val.id;
      temp.obj = val;
      temp.name = val.attributes.Name;
      temp.dob = val.attributes.dob
        ? getDateInDDMMYYYY(val.attributes.dob)
        : "";
      if (val.attributes.designation) {
        temp.designation = val.attributes.designation;
      } else {
        if (val.attributes.Role == 1) {
          temp.designation = "Teacher";
        } else if (val.attributes.Role == 2) {
          temp.designation = "Center Head";
        } else if (val.attributes.Role == 3) {
          temp.designation = "Director";
        }
      }
      if (val.attributes.deleted) {
        temp.status = 'Archived'; 
      } else {
        temp.status = 'Active';  
      }
      temp.phoneNumber = val.attributes.PhoneNumber;
      temp.email = val.attributes.EMail;
      temp.classList = "";
      if (val.attributes.Role === 3 || val.attributes.Role === 2) {
        temp.classList = "All Classroom";
      } else if (
        val.attributes.ClassRooms &&
        val.attributes.ClassRooms.length > 0
      ) {
        for (const list of val.attributes.ClassRooms) {
          for (const aList of allClassRooms) {
            if (aList.id === list) {
              temp.classList += aList.name + ", ";
              break;
            }
          }
        }
      } else {
        temp.classList = "--";
      }
      temp.employerCode = val.attributes.empCode;
      temp.dateOfJoin = val.attributes.doj
        ? getDateInDDMMYYYY(val.attributes.doj)
        : "";
      temp.gender = val.attributes.Gender ?? ""
      temp.emergencyContact = val.attributes.emergencyContact ?? ""
      temp.emergencyContactPersonName = val.attributes.emergencyContactPersonName ?? ""
      temp.bloodGroup = val.attributes.bloodGroup ?? ""
      
      temp.address = val.attributes.address ?? ""
      temp.communicationAddress = val.attributes.communicationAddress ?? ""
      temp.nationality = val.attributes.nationality ?? ""
      temp.religion = val.attributes.religion ?? ""
      temp.adhar = val.attributes.aadharNumber ?? ""
      temp.fatherName = val.attributes.fatherName ?? ""
      temp.maritalStatus = val.attributes.maritalStatus ?? ""
      temp.spouseName = val.attributes.spouseName ?? ""
      temp.employeeType = val.attributes.employeeType ?? ""
      temp.qualification = val.attributes.qualification ?? ""
      temp.about = val.attributes.about ?? ""
      temp.collegeDetails = val.attributes.collegeDetails ?? ""
      temp.notes = val.attributes.notes ?? ""
      temp.appointmentLetter = val.attributes.appointmentLetter === true ? "Yes" :( val.attributes.appointmentLetter === false ? "No" :  "");
      
      temp.initialSalary = val.attributes.initialSalary ?? ""
      temp.currentSalary = val.attributes.currentSalary ?? ""
      temp.accountNumber = val.attributes.bankAccount?.accountNumber ?? ""
      temp.nameAsPerBank = val.attributes.bankAccount?.nameAsPerBank ?? ""
      temp.bankName = val.attributes.bankAccount?.bankName ?? ""
      temp.ifsc = val.attributes.bankAccount?.IFSC ?? ""
      temp.branchName = val.attributes.bankAccount?.branchName ?? ""
      temp.pan = val.attributes.pan ?? ""
      temp.uan = val.attributes.UAN ?? ""
      
      temp.noticePeriod = val.attributes.noticePeriod ?? ""
      temp.noticePeriodServed = (val.attributes.noticePeriodStatus === true) ? "Yes" : (val.attributes.noticePeriodStatus === false) ? "No" :  "";
      temp.exitReason = val.attributes.exitReason ?? ""
      temp.dues = (val.attributes.dues === true) ? "Yes" : (val.attributes.dues === false) ? "No" :  "";
      temp.experienceLetter = (val.attributes.experienceLetter === true) ? "Yes" : (val.attributes.experienceLetter === false) ? "No" :  "";
      temp.appriasalDate = val.attributes.appriasalDate? getDateInDDMMYYYY(val.attributes.appriasalDate) : "";
      tempTeacher.push(temp);
    }
    setAllData(tempTeacher);
    setGotReport(true);
  }

  const processKidsData = (kids) => {
    let tempList = [];
    let familyIds = [];
    for (const val of kids) {
      let temp = {};
      temp.id = val.id;
      temp.obj = val;
      temp.name = val.attributes.Name;
      temp.gender = val.attributes.Gender;
      temp.address = val.attributes.address;
      temp.dob = val.attributes.DateOfBirth
        ? getDateInDDMMYYYY(val.attributes.DateOfBirth)
        : "";
      temp.admissionNumber = val.attributes.admissionNumber;
      temp.academicYear = val.attributes.admissionYear;
      if (val.attributes.Deleted) {
        temp.status = 'Archived'; 
      } else {
        temp.status = 'Active';  
      }
      if (val.attributes.ClassRoomID) {
        for (const classRoom of allClassRooms) {
          if (classRoom.id === val.attributes.ClassRoomID) {
            temp.className = classRoom.name;
          }
        }
      } else {
        temp.className = "Admission";
      }
      temp.doa = getDateInDDMMYYYY(val.attributes.createdAt);
      temp.familyId = val.attributes.FamilyID;
      familyIds.push(val.attributes.FamilyID);

      if (props.parents) {
        for (const parent of props.parents) {
          if (parent.attributes.FamilyID === val.attributes.FamilyID)
            if (parent.attributes.Relation === "Mother") {
              temp.motherName = parent.attributes.Name;
              temp.motherPhoneNumber = parent.attributes.PhoneNumber;
              temp.motherEmail = parent.attributes.EMail;
              temp.motherObj = parent;
            } else if (parent.attributes.Relation === "Father") {
              temp.fatherName = parent.attributes.Name;
              temp.fatherPhoneNummber = parent.attributes.PhoneNumber;
              temp.fatherEmail = parent.attributes.EMail;
              temp.fatherObj = parent;
            }
        }
      } else {
        temp.motherName = "";
        temp.motherPhoneNumber = "";
        temp.motherEmail = "";
        temp.fatherName = "";
        temp.fatherPhoneNummber = "";
        temp.fatherEmail = "";
        // console.log("no paretns");
      }
      tempList.push(temp);
    }
    setAllData(tempList);
    setGotReport(true);
    if (familyIds && familyIds.length > 0 && requested) {
      props.getParentsForFamilyIds(familyIds);
    }
    // setGotReport(true);
  };
  useEffect(() => {
    let tempData = Object.assign([], allData);
    if (tempData && tempData.length>0 && props.parents && props.parents.length > 0) {
      
      for (const parent of props.parents) {
        for (const kid of tempData) {
          if (kid.familyId === parent.attributes.FamilyID) {
            if (parent.attributes.Relation === "Mother") {
              kid.motherName = parent.attributes.Name;
              kid.motherPhoneNumber = parent.attributes.PhoneNumber;
              kid.motherEmail = parent.attributes.EMail;
              kid.motherObj = parent;
            } else {
              kid.fatherName = parent.attributes.Name;
              kid.fatherPhoneNummber = parent.attributes.PhoneNumber;
              kid.fatherEmail = parent.attributes.EMail;
              kid.fatherObj = parent;
            }
          }
        }
      }
      setAllData(tempData);
    }
  }, [props.parents]);
  const getDateInDDMMYYYY = (date) => {
    return Moment(date).format("DD/MM/YYYY");
  };
  const downloadTheReport = () => {
    let rawData = allData;
    let finalData = [];
    let titles = [];
    let fileName = "";
    let sheetName = "";
    if (reportType === "1") {
      sheetName = "All Staff";
      fileName = "All Staff.xlsx";
      finalData.push([`Report Type: All Staff`]);
      finalData.push([]);
      titles = [
        "Name",
        "Gender",
        "Designation",
        "Phone Number",
        "EMail",
        "Blood Group",
        "Classes",
        "Employer Code",
        "Date Of Join",
        "Date Of Birth",
        "Emergency Contact Number",
        "Emergency Contact Name",

        "Permanent Address",
        "Address For Communication",
        "Nationality",
        "Religion",
        "Aadhar Number",
        "Father Name",  
        "Maritual Status",
        "Spouse Name",
        "Employee Type",
        "Qualification",
        "About",
        "College Details",
        "Notes",
        "Appointment Letter Sent?",

        "Initial Salary",
        "Current Salary",
        "Account Number",
        "Name as per bank",
        "Bank Name",
        "IFSC",
        "Branch Name",
        "PAN",
        "UAN",

        "Notice Period",
        "Notice Period Served",
        "Reason for leaving Reason",
        "All School Related Materials/Resources Collected?",
        "Experience Letter Status?",
        "Last Appraisal Done On"
      ];
      finalData.push(titles);
      for (const val of rawData) {
        let temp = [];
        temp.push(val.name);
        temp.push(val.gender);
        temp.push(val.designation);
        temp.push(val.phoneNumber);
        temp.push(val.email);
        temp.push(val.bloodGroup)
        temp.push(val.classList);
        temp.push(val.employerCode);
        temp.push(val.dateOfJoin);
        temp.push(val.dob);
        temp.push(val.emergencyContact);
        temp.push(val.emergencyContactPersonName);

        temp.push(val.address);
        temp.push(val.communicationAddress);
        temp.push(val.nationality);
        temp.push(val.religion);
        temp.push(val.adhar)
        temp.push(val.fatherName);
        temp.push(val.maritalStatus);
        temp.push(val.spouseName);
        temp.push(val.employeeType);
        temp.push(val.qualification);
        temp.push(val.about);
        temp.push(val.collegeDetails);
        temp.push(val.notes);
        temp.push(val.appointmentLetter);

        temp.push(val.initialSalary);
        temp.push(val.currentSalary);
        temp.push(val.accountNumber);
        temp.push(val.nameAsPerBank);
        temp.push(val.bankName);
        temp.push(val.ifsc);
        temp.push(val.branchName);
        temp.push(val.pan);
        temp.push(val.uan);

        temp.push(val.noticePeriod);
        temp.push(val.noticePeriodServed);
        temp.push(val.exitReason)
        temp.push(val.dues);
        temp.push(val.appriasalDate);
        temp.push(val.experienceLetter);
        finalData.push(temp);
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(finalData);
      ws['!cols'] = titles.map(title => {
        return (title === 'collegeDetails' || title === 'notes') ? { wpx: 300 } : { wpx: 150 };
      });
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, fileName);
    } else if (reportType === "2") {
      sheetName = "All Students";
      fileName = "All Students.xlsx";
      finalData.push([`Report Type: All Students`]);
      finalData.push([]);
      titles = [
        "Name",
        "Class",
        "Gender",
        "Academic Year",
        "Status",
        "DOB",
        "Admission Number",
        "Date Of Admission",
        "Father Name",
        "Father Email",
        "Father PhoneNumber",
        "Mother Name",
        "Mother Email",
        "Mother PhoneNumber",
        "Address"
      ];
      finalData.push(titles);
      for (const val of rawData) {
        let temp = [];
        temp.push(val.name);
        temp.push(val.className);
        temp.push(val.gender);
        temp.push(val.academicYear);
        temp.push(val.status);
        temp.push(val.dob);
        temp.push(val.admissionNumber);
        temp.push(val.doa);
        temp.push(val.fatherName);
        temp.push(val.fatherEmail);
        temp.push(val.fatherPhoneNummber);
        temp.push(val.motherName);
        temp.push(val.motherEmail);
        temp.push(val.motherPhoneNumber);
        temp.push(val.address);
        finalData.push(temp);
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(finalData);
      ws['!cols'] = Array(titles.length).fill({ wpx: 100 });
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, fileName);
    }
  };
  return (
    <React.Fragment>
      <CardTitle>{props.t("School Report")}</CardTitle>
      <CardSubtitle className="mb-3">
        {props.t("Select filter to generate the report")}
      </CardSubtitle>
      <Row className="bg-light">
        <Col md="3" xs="12" xl="3" lg="3">
          <FormGroup className="mt-2 mb-2 p-1">
            <Label>{props.t("Report Type")} :</Label>
            <select
              className="form-control "
              value={reportType}
              onChange={(e) => {
                setReportType(e.target.value);
                setGotReport(false);
              }}
            >
              <option value={1}>{props.t("Staff")}</option>
              <option value={2}>{props.t("Student")}</option>
              <option value={3}>{props.t("New Admission Report")}</option>
              <option value={4}>{props.t("Student Exit Report")}</option>
              <option value={5}>{props.t("CCTV Report")}</option>
              <option value={6}>{props.t("Trips Report")}</option>
              <option value={7}>{props.t("DayCare Diary Report")}</option>
              <option value={8}>{props.t("Daywise Summary Report")}</option>
            </select>
          </FormGroup>
        </Col>
        {reportType === "2" && <Col md="3" xs="12" xl="3" lg="3">
          <FormGroup className="mt-2">
            <Label>{props.t("Select Academic Year")}:</Label>
            <Select
              value={academicYear}
              onChange={(option) => setAcademicYear(option)}
              options={academicYearList}
            />
          </FormGroup>
        </Col>}
        {(reportType === "1" || reportType === "2") && ( <Col md="3" xs="12" xl="3" lg="3">
          <FormGroup className="mt-2">
            <Label>{props.t("Select Status")}:</Label>
            <Select
              value={status}
              onChange={(option) => setStatus(option)}
              options={statusList}
            />
          </FormGroup>
        </Col>)}
          {reportType != "3" && reportType != "4" &&  reportType != "5" && reportType != "6" && <Col md="2" xs="12" xl="2" lg="2">
          <div className="mt-2">
            <div className="mt-3">
              <button
                className="btn btn-primary mt-4 mr-2"
                onClick={generateReport}
              >
                {props.t("Generate")}
              </button>
            </div>
          </div>
        </Col>}
      </Row>
      {reportType == "2" && status.value !== "all" && <h6 className="mt-3">Note: If any student is marked as missing for the selected academic year, kindly review and update their admission details in the student profile.</h6>}
      {gotReport && allData.length > 0 && (
        <Row>
          <Col md="12" xs="12" xl="12" lg="12" className="d-flex justify-content-end mt-1 mb-1">
            <button
              className="btn btn-info float-right"
              onClick={downloadTheReport}
            >
              <i className="bx bxs-download mr-1" />
              {props.t("Download")}
            </button>
          </Col>
        </Row>
      )}
      {isLoading && reportType == "2" && allData.length === 0 &&(
        <Label className="d-flex justify-content-center align-items-center text-success">
          <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
          {"Generating Report"}
        </Label>
      )}
      {gotReport && reportType != "3" && reportType != "4" && (
        <Row>
          {allData.length > 0 && reportType === "1" && (
            <div className="table-responsive">
              <table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>{props.t("Sl.no")}</th>
                    <th>{props.t("Name")}</th>
                    <th>{props.t("Designation")}</th>
                    <th>{props.t("Phone Number")}</th>
                    <th>{props.t("Email")}</th>
                    <th>{props.t("Class")}</th>
                    <th>{props.t("Blood Group")}</th>
                    <th>{props.t("Gender")}</th>
                    <th>{props.t("Employer Code")}</th>
                    <th>{props.t("Date Of Birth")}</th>
                    <th>{props.t("Date Of Join")}</th>
                    <th>{props.t("Status")}</th>
                    <th>{props.t("Emergency Contact Number")}</th>
                    <th>{props.t("Emergency Contact Name")}</th>

                    <th>{props.t("Permanent Address")}</th>
                    <th>{props.t("Address for Communication")}</th>
                    <th>{props.t("Nationality")}</th>
                    <th>{props.t("Religion")}</th>
                    <th>{props.t("Aadhar Number")}</th>
                    <th>{props.t("Father Name")}</th>
                    <th>{props.t("Maritual Status ")}</th>
                    <th>{props.t("Spouse Name")}</th>
                    <th>{props.t("Employee Type")}</th>
                    <th>{props.t("Qualification")}</th>
                    <th>{props.t("About")}</th>
                    <th>{props.t("College Details")}</th>
                    <th>{props.t("Notes/Comments about Staff")}</th>
                    <th>{props.t("Appointment Letter Sent?  ")}</th>

                    <th>{props.t("Initial Salary")}</th>
                    <th>{props.t("Current Salary")}</th>
                    <th>{props.t("Account Number")}</th>
                    <th>{props.t("Name as per bank")}</th>
                    <th>{props.t("Bank Name")}</th>
                    <th>{props.t("IFSC")}</th>
                    <th>{props.t("Branch Name")}</th>
                    <th>{props.t("PAN")}</th>
                    <th>{props.t("UAN")}</th>

                    <th>{props.t("Notice Period")}</th>
                    <th>{props.t("Notice Period Served")}</th>
                    <th>{props.t("School Materials provided")}</th>
                    <th>{props.t("Experience Letter Status? ")}</th>
                    <th>{props.t("Last Appraisal Done On (Month and Year)")}</th>
                  </tr>
                </thead>
                <tbody>
                  {allData.map((val, key) => (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>{val.name}</td>
                      <td>{val.designation}</td>
                      <td>{val.phoneNumber}</td>
                      <td>{val.email}</td>
                      <td style={{width: '1px', whiteSpace: 'normal',overflow: 'hidden', textOverflow: 'ellipsis'}}>{val.classList}</td>
                      <td>{val.bloodGroup}</td>
                      <td>{val.gender}</td>
                      <td>{val.employerCode}</td>
                      <td>{val.dob}</td>
                      <td>{val.dateOfJoin}</td>
                      <td>{val.status}</td>
                      <td>{val.emergencyContact}</td>
                      <td>{val.emergencyContactPersonName}</td>

                      <td style={{width: '1px', whiteSpace: 'normal',overflow: 'hidden', textOverflow: 'ellipsis'}}>{val.address}</td>
                      <td style={{width: '1px', whiteSpace: 'normal',overflow: 'hidden', textOverflow: 'ellipsis'}}>{val.communicationAddress}</td>
                      <td>{val.nationality}</td>
                      <td>{val.religion}</td>
                      <td>{val.adhar}</td>
                      <td>{val.fatherName}</td>
                      <td>{val.maritalStatus}</td>
                      <td>{val.spouseName}</td>
                      <td>{val.employeeType}</td>
                      <td style={{width: '1px', whiteSpace: 'normal',overflow: 'hidden', textOverflow: 'ellipsis'}}>{val.qualification}</td>
                      <td style={{width: '1px', whiteSpace: 'normal',overflow: 'hidden', textOverflow: 'ellipsis'}}>{val.about}</td>
                      <td style={{width: '1px', whiteSpace: 'normal',overflow: 'hidden', textOverflow: 'ellipsis'}}>{val.collegeDetails}</td>
                      <td style={{width: '1px', whiteSpace: 'normal',overflow: 'hidden', textOverflow: 'ellipsis'}}>{val.notes}</td>
                      <td>{val.appointmentLetter}</td>

                      <td>{val.initialSalary}</td>
                      <td>{val.currentSalary}</td>
                      <td>{val.accountNumber}</td>
                      <td>{val.nameAsPerBank}</td>
                      <td>{val.bankName}</td>
                      <td>{val.ifsc}</td>
                      <td>{val.branchName}</td>
                      <td>{val.pan}</td>
                      <td>{val.uan}</td>

                      <td>{val.noticePeriod}</td>
                      <td>{val.noticePeriodServed}</td>
                      <td>{val.dues}</td>
                      <td>{val.experienceLetter}</td>
                      <td>{(val.appriasalDate)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {gotReport && allData.length > 0 && reportType === "2" && (
            <div className="table-responsive">
              <table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>{props.t("Sl.No")}</th>
                    <th>{props.t("Name")}</th>
                    <th>{props.t("Class")}</th>
                    <th>{props.t("Gender")}</th>
                    <th>{props.t("Academic Year")}</th>
                    <th>{props.t("Status")}</th>
                    <th>{props.t("Date Of Birth")}</th>
                    <th>{props.t("Admission Number")}</th>
                    <th>{props.t("Date Of Admission")}</th>
                    <th>{props.t("Father Name")}</th>
                    <th>{props.t("Father Email")}</th>
                    <th>{props.t("Father Phone Number")}</th>
                    <th>{props.t("Mother Name")}</th>
                    <th>{props.t("Mother Email")}</th>
                    <th>{props.t("Mother Phone Number")}</th>
                  </tr>
                </thead>
                <tbody>
                  {allData.map((val, key) => {
                    return (
                      <tr key={key}>
                      <td>{key+1}</td>
                      <td>{val.name}</td>
                      <td>{val.className}</td>
                      <td>{val.gender}</td>
                      <td>{val.academicYear}</td>
                      <td>{val.status}</td>
                      <td>{val.dob}</td>
                      <td>{val.admissionNumber}</td>
                      <td>{val.doa}</td>
                      <td>{val.fatherName}</td>
                      <td>{val.fatherEmail}</td>
                      <td>{val.fatherPhoneNummber}</td>
                      <td>{val.motherName}</td>
                      <td>{val.motherEmail}</td>
                      <td>{val.motherPhoneNumber}</td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          )}
          {allData.length === 0 && (
            <Col>
              <h3 className="text-center text-info mt-3">
                {props.t("No Report Found For The Filter")}
              </h3>
            </Col>
          )}
        </Row>
      )}
      {reportType == "3" && <NewAdmissionReport
        isFranchise={false}
        showCorporatePartners={false}
        schoolId={props.schoolId}
      />}
      {reportType == "4" && <StudentExitReport
        isFranchise={false}
        showCorporatePartners={false}
        schoolId={props.schoolId}
      />}
      {reportType == "5" && <CctvReport
        isFranchise={false}
        showCorporatePartners={false}
        schoolId={props.schoolId}
      />}
      {reportType == "6" && <TripsReport
        isFranchise={false}
        showCorporatePartners={false}
        schoolId={props.schoolId}
      />}
      {reportType == "7" && <DairyReport
        isFranchise={false}
        showCorporatePartners={false}
        schoolId={props.schoolId}
      />}
      {reportType == "8" && <DaywiseSummary
        isFranchise={false}
        showCorporatePartners={false}
        schoolId={props.schoolId}
      />}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { teachers, teacherReport } = state.Teacher;
  const { classrooms } = state.Classroom;
  const { kids, kidReport } = state.Kid;
  const { parents } = state.Parent;
  return { teachers, classrooms, kids, parents, teacherReport, kidReport };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getTeachersForSchool,
      getClassroomsForSchoolId,
      getParentsForFamilyIds,
    })(SchoolReport)
  )
);

