import Parse from "parse";
import { printLog } from "./loghelper";

export const getLeaveModuleForSchoolId = (filter) => {
  printLog("HL: leaves_helper : getLeaveModuleForSchoolId");
  try {
    return new Promise((resolve, reject) => {
      const Leaves = Parse.Object.extend("Leaves");
      const query = new Parse.Query(Leaves);

      if (filter.userId) {
        query.equalTo("userId", filter.userId);
      }

      if (filter.ownerId) {
        query.equalTo("ownerId", filter.ownerId);
      }
      // if (ownerType === "School") {
      //   query.equalTo("ownerType", "School");
      // }

      if (filter.updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(filter.updatedDate));
      }
      query.notEqualTo("isDeleted", true);

      if (filter.isLocal) {
        query.fromLocalDatastore();
      }


      if (filter.startDate) {
        query.greaterThan("createdAt", filter.startDate);
      }
      if (filter.endDate) {
        query.lessThanOrEqualTo("createdAt", filter.endDate);
      }

      query.descending("createdAt");
      query.limit(1000);

      query.find().then(
        (result) => {
          resolve(result);
          // console.log("Filtered Results:", result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  } catch (error) {
    console.error("Error in getLeaveModuleForSchoolId:", error);
  }
};

export const editLeaveDetails = (leaves) => {
  try {
    return new Promise((resolve, reject) => {
      leaves.save().then(
        (result) => {
          resolve(result);
          // console.log("Results", result)
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};

export const getLeavePoliciesForSchoolId = (ownerId, ownerType) => {
  printLog("HL: leaves_helper : getLeavePoliciesForSchoolId");
  try {
    return new Promise((resolve, reject) => {
      // const LeavePolicys = Parse.Object.extend("LeavePolicy");
      // const query = new Parse.Query(LeavePolicys);

      // query.equalTo("ownerId", ownerId);
      // query.equalTo("ownerType", ownerType)
      // query.notEqualTo("isDeleted", true);
      // query.descending("createdAt");
      // query.limit(1000);

      // query.find().then(
      //   (result) => {
      //     resolve(result);
      //     console.log("HELPERResults:", result);

      //   },
      //   (error) => {
      //     reject(error);
      //   }
      // );

      Parse.Cloud.run("function_getLeavePlocies", {
        ownerId: ownerId,
        ownerType: ownerType,
      }).then(
        (result) => {
          resolve(result.policyObjects);
          console.log("Result", result)
        },
        (error) => {
          console.log(error);
        }
      );
    });
  } catch (error) {
    console.error("Error in getLeaveModuleForSchoolId:", error);
  }
};

export const getLeavePolicyItems = (policyId) => {
  printLog("HL: leaves_helper : getLeavePolicyItems")

  try {
    return new Promise((resolve, reject) => {
      var leavePolicyItems = Parse.Object.extend("LeavePolicyItems");
      var query = new Parse.Query(leavePolicyItems);
      query.equalTo("policyId", policyId);
      query.notEqualTo("isDeleted",true)
      query.find()
        .then((results) => {
          resolve(results);
        })
        .catch((error) => {
          console.error("Error fetching leave policy items:", error);
          return error;
        });
    });
  } catch (error) {
    console.error("Unexpected error in Saga:", error);
    return error;
  }
}

export const getPayrollCategoriesForOwnerId = (ownerId, ownerType) => {
  return new Promise((resolve, reject) => {
    try {
      const PaySlipCategories = Parse.Object.extend("PaySlipCategories");

      const query1 = new Parse.Query(PaySlipCategories);
      query1.equalTo("ownerId", ownerId);
      query1.equalTo("ownerType", ownerType);

      const query2 = new Parse.Query(PaySlipCategories);
      query2.equalTo("ownerId", "default");
      query2.equalTo("ownerType", "default");

      const combinedQuery = Parse.Query.or(query1, query2);
      combinedQuery.notEqualTo("isDeleted", true);
      combinedQuery.descending("createdAt");
      combinedQuery.limit(1000);

      combinedQuery.find()
        .then((result) => {
          // console.log("Result:", result);
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};


export const deletePayrollCategory = (payRollCategories) => {

  printLog("HL: leaves_helper : deletePayrollCategory")
  try {
    return new Promise((resolve, reject) => {

      payRollCategories.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        payRollCategories.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        payRollCategories.set("deletedBy", Parse.User.current().id);
      }

      payRollCategories.save().then(
        (result) => {
          resolve(result);
          // console.log("DeleteResults", result)
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getMasterPayslipForTeacherId = (teacherId) => {
  try {
    return new Promise((resolve, reject) => {
      var MasterPayslip = Parse.Object.extend("MasterPayslip");
      var query = new Parse.Query(MasterPayslip);
      query.equalTo("teacherId", teacherId);
      query.notEqualTo("isDisabled", true);

      query.first().then((result) => {
        resolve(result);
      },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getMasterPayslipItemsForMasterPayslipId = (masterPayslipId) => {
  try {
    return new Promise((resolve, reject) => {
      var MasterPayslipItems = Parse.Object.extend("MasterPayslipItems");
      var query = new Parse.Query(MasterPayslipItems);
      query.equalTo("masterPayslipId", masterPayslipId);

      query.find().then(
        (result) => {
          // console.log("masterPayslipId", result)
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getPayslipForMonth = (teacherId, month) => {
  try {
    return new Promise((resolve, reject) => {
      var Payslip = Parse.Object.extend("Payslip");
      var query = new Parse.Query(Payslip);
      query.equalTo("teacherId", teacherId);
      query.equalTo("payMonth", month);

      query.first().then((result) => {
        resolve(result);
      },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getPayslipForId = (teacherId, payslipId) => {
  try {
    return new Promise((resolve, reject) => {
      var Payslip = Parse.Object.extend("Payslip");
      var query = new Parse.Query(Payslip);
      query.equalTo("teacherId", teacherId);
      query.equalTo("objectId", payslipId);

      query.first().then((result) => {
        resolve(result);
      },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getPayslipItemsForPayslipId = (payslipId) => {
  try {
    return new Promise((resolve, reject) => {
      var PayslipItems = Parse.Object.extend("PayslipItems");
      var query = new Parse.Query(PayslipItems);
      query.equalTo("payslipId", payslipId);
      query.notEqualTo("isDeleted", true);

      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getPayslipsForMonthAndSchoolId = (schoolId, month) => {
  try {
    return new Promise((resolve, reject) => {
      var Payslip = Parse.Object.extend("Payslip");
      var query = new Parse.Query(Payslip);
      query.equalTo("ownerId", schoolId);
      query.equalTo("payMonth", month);

      query.find().then((result) => {
        resolve(result);
      },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getMasterPayslipsForOwnerIdAndOwnertype = (ownerId, ownerType) => {
  try {
    return new Promise((resolve, reject) => {
      var MasterPayslip = Parse.Object.extend("MasterPayslip");
      var query = new Parse.Query(MasterPayslip);
      query.equalTo("ownerId", ownerId);
      query.equalTo("ownerType", ownerType);

      query.find().then((result) => {
        resolve(result);
      },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};


export const getHolidayList = (ownerId, ownerType) => {
  printLog("HL: leaves_helper : getHolidayList");
  try {
    return new Promise((resolve, reject) => {
      const Holiday = Parse.Object.extend("HolidayList");
      const query = new Parse.Query(Holiday);

      query.equalTo("ownerId", ownerId);
      query.equalTo("ownerType", ownerType)
      query.notEqualTo("isDeleted", true);
      query.descending("createdAt");
      query.limit(1000);

      query.find().then(
        (result) => {
          resolve(result);
          // console.log("Filtered Results:", result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  } catch (error) {
    console.error("Error in getHolidayList:", error);
    reject(error);
  }
}

export const getPayslipItemsForPayslipIds = (payslipIds) => {
  try {
    return new Promise((resolve, reject) => {
      var PayslipItems = Parse.Object.extend("PayslipItems");
      var query = new Parse.Query(PayslipItems);
      query.containedIn("payslipId", payslipIds);
      query.notEqualTo("isDeleted", true);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const editPayslip = (payslip) => {
  try {
    return new Promise((resolve, reject) => {
      payslip.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};