import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import Parse from "parse";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setSelecetdSchool } from "../../store/actions";
import { v4 as uuidv4 } from "uuid";

const VisitorForm = (props) => {
    const match1 = matchPath(props.history.location.pathname, {
        path: "/visitor-form/:schoolId",
        exact: true,
        strict: false,
    });
    const match2 = matchPath(props.history.location.pathname, {
        path: "/visitor-form-au/:schoolId",
        exact: true,
        strict: false,
    });

    const match = match1 ?? match2;
    const schoolId = match && match.params.schoolId;

    const [schoolObject, setSchoolObject] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [name, setName] = useState("");
    const [visitingPurpose, setVisitingPurpose] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [meetingPerson, setMeetingPerson] = useState("");
    const [visitingFrom, setVisitingFrom] = useState("");
    const [visitorType, setVisitorType] = useState("");

    const [nameError, setNameError] = useState(false);
    const [visitingPurposeError, setVisitingPurposeError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [invalidPhoneError, setInvalidPhoneError] = useState(false);
    const [meetingPersonError, setMeetingPersonError] = useState(false);
    const [visitingFromError, setVisitingFromError] = useState(false);
    const [visitorTypeError, setVisitorTypeError] = useState(false);
    const [submittingForm, setSubmittingForm] = useState(false);
    const [alldetails, setAlldetails] = useState([]);

    const [otprequestResponce, setOtprequestResponce] = useState(undefined);
    const [syncId, setSyncId] = useState(uuidv4());
    const [cameraState, setCameraState] = useState(false);
    const [otpPopup, setOtpPopup] = useState(false);
    const [photo, setPhoto] = useState(null);
    const [photoError, setPhotoError] = useState(false);
    const videoRef = useRef(null);
    const length = 4;
    const [otp, setOtp] = useState(new Array(length).fill(''));
    const inputRefs = useRef([]);

    const typesOfVisitor = ["Parent", "Visitor"];
    const purposeOfVisit = ["Official", "Enquiry", "Others"];

    useEffect(() => {
        if (schoolId) {
            fetchSchoolFromServer(schoolId);
        }
    }, [schoolId]);

    useEffect(() => {
        if (match && (!props.selectedSchool || props.selectedSchool.id !== schoolId)) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const fetchSchoolFromServer = (schoolId) => {
        Parse.Cloud.run("function_getSchoolDetailsForSchoolId", { schoolId })
            .then((result) => {
                if (result) {
                    setSchoolObject(result);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const startCamera = async () => {
        try {
            setCameraState(true);
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = mediaStream;
            }
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };

    const takePicture = () => {
        if (videoRef.current) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
            const capturedPhoto = canvas.toDataURL('image/jpeg');
            setPhoto(capturedPhoto);
            setCameraState(false);
            stopCamera();
        }
    };

    const stopCamera = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach(track => track.stop());
        }
    };

    const handleChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && inputRefs.current[index - 1]) {
            inputRefs.current[index - 1].focus();
        }
    };

    const isPhoneInvalid = (phone) => {
        const re = new RegExp("^([0|+[0-9]{1,5})?([1-9][0-9]{9,14})$");
        return !re.test(phone);
    };

    const isFormValid = () => {
        let canProceedToSave = true;

        if (!name.trim()) {
            canProceedToSave = false;
            setNameError(true);
        } else {
            setNameError(false);
        }

        if (visitingPurpose.length === 0) {
            canProceedToSave = false;
            setVisitingPurposeError(true);
        } else {
            setVisitingPurposeError(false);
        }

        if (!phoneNumber.trim()) {
            canProceedToSave = false;
            setPhoneNumberError(true);
        } else {
            setPhoneNumberError(false);
        }

        if (isPhoneInvalid(phoneNumber)) {
            canProceedToSave = false;
            setInvalidPhoneError(true);
        } else {
            setInvalidPhoneError(false);
        }

        if (!meetingPerson.trim()) {
            canProceedToSave = false;
            setMeetingPersonError(true);
        } else {
            setMeetingPersonError(false);
        }

        if (!visitingFrom.trim()) {
            canProceedToSave = false;
            setVisitingFromError(true);
        } else {
            setVisitingFromError(false);
        }

        if (!visitorType.trim()) {
            canProceedToSave = false;
            setVisitorTypeError(true);
        } else {
            setVisitorTypeError(false);
        }

        // Check permission based on featuresList[15]
        if (schoolObject) {
            const featureValue = schoolObject.attributes.featuresList[15];

            if (featureValue === 3) {
                if (!photo) {
                    canProceedToSave = false;
                    setPhotoError(true);
                } else {
                    setPhotoError(false);
                }
                if (canProceedToSave) {
                    requestOTP();
                }
            } else if (featureValue === 2) {
                if (canProceedToSave) {
                    requestOTP();
                }
            } else if (featureValue === 1) {
                if (canProceedToSave) {
                    saveForm();
                }
            } else {
                if (canProceedToSave) {
                    saveForm();
                }
            }
        }
    };

    const requestOTP = () => {
        Parse.Cloud.run("function_RequestVisitorOTP", {
            userPhoneNumber: phoneNumber,
            syncId,
            schoolId,
        })
            .then((response) => {
                setOtprequestResponce(response);
                setOtpPopup(true);
            })
            .catch((error) => {
                console.error("Error requesting OTP:", error.message);
            });
    };

    const verifyOtp = (otp) => {
        Parse.Cloud.run("function_VerifyVisitorOTP", {
            userPhoneNumber: otprequestResponce.userPhoneNumber,
            syncId,
            schoolId,
            otp,
        })
            .then((response) => {
                if (response === 1) {
                    setOtpPopup(false);
                    saveForm();
                } else {
                    // Handle invalid OTP case
                    console.error("Invalid OTP");
                }
            })
            .catch((error) => {
                console.error("Error verifying OTP:", error.message);
            });
    };

    const saveForm = () => {
        setSubmittingForm(true);
        const Visitor = Parse.Object.extend('Visitor');
        const visitor = new Visitor();

        visitor.set("name", name);
        visitor.set("visitingPurpose", visitingPurpose);
        visitor.set("phoneNumber", phoneNumber);
        visitor.set("meetingPerson", meetingPerson);
        visitor.set("visitingFrom", visitingFrom);
        visitor.set("visitorType", visitorType);
        visitor.set("schoolId", schoolId);

        if (schoolObject && schoolObject.attributes.featuresList[15] === 2) {
            visitor.set("isOTPVerified", true);
        }

        if (photo) {
            const parseFile = new Parse.File("visitorPhoto.jpg", { base64: photo });
            parseFile.save()
                .then((result) => {
                    visitor.set("visitorPhoto", result);
                    return visitor.save();
                })
                .then((result) => {
                    console.log(result);
                    setSubmittingForm(false);
                    setIsFormSubmitted(true);
                })
                .catch((error) => {
                    setSubmittingForm(false);
                    console.error('Error saving visitor:', error);
                });
        } else {
            visitor.save()
                .then((result) => {
                    console.log(result);
                    setSubmittingForm(false);
                    setIsFormSubmitted(true);
                })
                .catch((error) => {
                    setSubmittingForm(false);
                    console.error('Error saving visitor:', error);
                });
        }
    };

    const changeVisitingPurpose = (choice) => {
        const tempArray = [...visitingPurpose];
        if (visitingPurpose.includes(choice)) {
            // Remove
            const index = tempArray.indexOf(choice);
            if (index > -1) {
                tempArray.splice(index, 1);
            }
        } else {
            tempArray.push(choice);
        }
        setVisitingPurpose(tempArray);
        setVisitingPurposeError(tempArray.length === 0);
    };

    const getTextUI = (leftLabel, rightLabel, key) => (
        <React.Fragment key={key}>
            <Row lg={5} md={8} sm={12} xs={12} className="justify-content-center mt-1">
                <div className="text-left col-6">{leftLabel}</div>
                <div className="text-left col-6">
                    <Label className="float-right">:</Label>
                    {rightLabel}
                </div>
            </Row>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Container fluid={true} className={match ? "" : "page-content"}>
                {!isLoading && !isFormSubmitted && (
                    <Row>
                        <Col lg={!match ? 12 : 8} className="mx-auto">
                            {match && (
                                <div className="text-center mt-3">
                                    {schoolObject && schoolObject.attributes.logo && (
                                        <img
                                            src={schoolObject.attributes.logo._url}
                                            width="100"
                                            alt=""
                                            className="img-thumbnail rounded-circle"
                                        />
                                    )}
                                    <h3>
                                        {schoolObject
                                            ? props.t(`Welcome to ${schoolObject.attributes.Name}`)
                                            : props.t("Welcome")}{" "}
                                    </h3>
                                    <h6 className="mt-3">
                                        {(schoolObject && schoolObject.attributes.featuresList[15] === 2)
                                            && props.t("Please provide the details")}
                                    </h6>
                                </div>
                            )}
                            {(schoolObject && schoolObject.attributes.featuresList[15] > 0) ? (
                                <Card>
                                    <CardBody>
                                        <AvForm
                                            className="outer-repeater"
                                            onValidSubmit={isFormValid}
                                        >
                                            <div data-repeater-list="outer-group" className="outer">
                                                <div data-repeater-item className="outer">
                                                    <h5>{props.t("Visitor Form")}: </h5>
                                                    <hr />
                                                    {/* Name Field */}
                                                    <FormGroup>
                                                        <Label htmlFor="vf-name">{"Name of the person"}{" "}</Label>
                                                        <Label htmlFor="vf-name" className="text-danger">*</Label>
                                                        {nameError && (
                                                            <Label className="label-xsmall text-danger ml-2">
                                                                (this field is mandatory)
                                                            </Label>
                                                        )}
                                                        <AvField
                                                            type="text"
                                                            name={"vf-name-f"}
                                                            placeholder={"Type here"}
                                                            className="form-control"
                                                            value={name}
                                                            onChange={(e) => {
                                                                setName(e.target.value);
                                                                setNameError(!e.target.value.trim());
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    {/* Purpose of Visit */}
                                                    <div className="widget mb-2 pb-2">
                                                        <Label className="">{"Purpose of visit"}</Label>
                                                        <Label className="text-danger">*</Label>
                                                        {visitingPurposeError && (
                                                            <Label className="label-xsmall text-danger ml-2">
                                                                (this field is mandatory)
                                                            </Label>
                                                        )}
                                                        <div>
                                                            {purposeOfVisit.map((choice, keyInside) => (
                                                                <div
                                                                    className="form-check ml-3"
                                                                    key={"purposeOfVisit" + keyInside}
                                                                >
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id={"checkbox" + "purposeOfVisit" + keyInside}
                                                                        value={choice}
                                                                        checked={visitingPurpose.includes(choice)}
                                                                        onChange={() => changeVisitingPurpose(choice)}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor={"checkbox" + "purposeOfVisit" + keyInside}
                                                                    >
                                                                        {choice}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>

                                                    {/* Whom to Meet */}
                                                    <FormGroup>
                                                        <Label htmlFor="vf-meetingPerson">{"Whom to meet"}{" "}</Label>
                                                        <Label htmlFor="vf-meetingPerson" className="text-danger">*</Label>
                                                        {meetingPersonError && (
                                                            <Label className="label-xsmall text-danger ml-2">
                                                                (this field is mandatory)
                                                            </Label>
                                                        )}
                                                        <AvField
                                                            type="text"
                                                            name={"vf-meetingPerson-f"}
                                                            placeholder={"Type here"}
                                                            className="form-control"
                                                            value={meetingPerson}
                                                            onChange={(e) => {
                                                                setMeetingPerson(e.target.value);
                                                                setMeetingPersonError(!e.target.value.trim());
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    {/* Phone Number */}
                                                    <FormGroup>
                                                        <Label htmlFor="vf-phone">{"Phone number"}</Label>
                                                        <Label htmlFor="vf-phone" className="text-danger">*</Label>
                                                        {phoneNumberError && (
                                                            <Label className="label-xsmall text-danger ml-2">
                                                                (this field is mandatory)
                                                            </Label>
                                                        )}
                                                        {invalidPhoneError && (
                                                            <Label className="label-xsmall text-danger ml-2">
                                                                (Please enter a valid phone number)
                                                            </Label>
                                                        )}
                                                        <AvField
                                                            name={"vf-phone-f"}
                                                            className="inner form-control noscroll"
                                                            placeholder={props.t("Enter phone no")}
                                                            value={phoneNumber}
                                                            onChange={(e) => {
                                                                setPhoneNumber(e.target.value);
                                                                setPhoneNumberError(!e.target.value.trim());
                                                                setInvalidPhoneError(isPhoneInvalid(e.target.value));
                                                            }}
                                                            type="number"
                                                        />
                                                    </FormGroup>

                                                    {/* Coming From */}
                                                    <FormGroup>
                                                        <Label htmlFor="vf-comingFrom">{"Coming from"}{" "}</Label>
                                                        <Label htmlFor="vf-comingFrom" className="text-danger">*</Label>
                                                        {visitingFromError && (
                                                            <Label className="label-xsmall text-danger ml-2">
                                                                (this field is mandatory)
                                                            </Label>
                                                        )}
                                                        <AvField
                                                            type="text"
                                                            name={"vf-comingFrom-f"}
                                                            placeholder={"Type here"}
                                                            className="form-control"
                                                            value={visitingFrom}
                                                            onChange={(e) => {
                                                                setVisitingFrom(e.target.value);
                                                                setvisitingFromError(!e.target.value.trim());
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    {/* Visitor Type */}
                                                    <div className="widget mb-2 pb-2">
                                                        <Label className="">{"Visiting the school as"}</Label>
                                                        <Label className="text-danger">*</Label>
                                                        {visitorTypeError && (
                                                            <Label className="label-xsmall text-danger ml-2">
                                                                (this field is mandatory)
                                                            </Label>
                                                        )}
                                                        <div>
                                                            {typesOfVisitor.map((choice, keyInside) => (
                                                                <div
                                                                    className="form-check ml-3"
                                                                    key={"typesOfVisitor" + keyInside}
                                                                >
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        id={"radio" + "typesOfVisitor" + keyInside}
                                                                        value={choice}
                                                                        checked={visitorType === choice}
                                                                        onChange={() => {
                                                                            setVisitorType(choice);
                                                                            setVisitorTypeError(false);
                                                                        }}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor={"radio" + "typesOfVisitor" + keyInside}
                                                                    >
                                                                        {choice}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>

                                                    {/* Conditional Rendering for Photo Capture */}
                                                    {schoolObject && schoolObject.attributes.featuresList[15] === 3 && (
                                                        <FormGroup>
                                                            <Label className="">{"Visitor Photo"}</Label>
                                                            <Label className="text-danger">*</Label>
                                                            {photoError && (
                                                                <Label className="label-xsmall text-danger ml-2">
                                                                    (photo is mandatory)
                                                                </Label>
                                                            )}
                                                            <Row>
                                                                <Col className="text-center">
                                                                    {photo && !cameraState && (
                                                                        <img
                                                                            src={photo}
                                                                            alt="Captured"
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "auto",
                                                                                objectFit: "contain",
                                                                                display: "block",
                                                                                margin: "0 auto",
                                                                                maxWidth: "400px",
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {cameraState && (
                                                                        <video
                                                                            ref={videoRef}
                                                                            autoPlay
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "auto",
                                                                                objectFit: "contain",
                                                                                display: "block",
                                                                                margin: "0 auto",
                                                                                maxWidth: "400px",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="text-center">
                                                                    {!photo && !cameraState && (
                                                                        <button className="btn btn-primary" onClick={startCamera}>
                                                                            Click Photo
                                                                        </button>
                                                                    )}
                                                                    {cameraState && (
                                                                        <button className="btn btn-primary m-1" onClick={takePicture}>
                                                                            CLICK
                                                                        </button>
                                                                    )}
                                                                    {photo && !cameraState && (
                                                                        <button className="btn btn-primary m-1" onClick={startCamera}>
                                                                            Try Again
                                                                        </button>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                    )}

                                                    {/* Submit Button */}
                                                    <div style={{ display: "flex" }}>
                                                        <Button
                                                            style={{
                                                                marginLeft: "auto",
                                                                marginRight: "auto",
                                                                width: "180px",
                                                            }}
                                                            type="submit"
                                                            color="primary"
                                                            disabled={submittingForm}
                                                        >
                                                            {submittingForm && <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>}
                                                            {props.t("SUBMIT")}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            ) : (
                                <Card>
                                    <CardBody>
                                        <p className="text-center">Visitor form is not available at this moment please contact school!</p>
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                )}

                {isFormSubmitted && (
                    <Card>
                        <CardBody>
                            <center>
                                <div style={{ border: "2px solid lightGray", borderRadius: "50px", minHeight: `calc(100vh - 170px)`, position: "relative" }} className="mt-5 pt-5 mb-5 pb-5">
                                    <div className="p-1 pl-4">
                                        <h1 className="mt-1 pt-1">
                                            Form submitted successfully<i style={{ fontSize: "50px" }}
                                                className="fa fa-check-circle align-middle bx-flashing text-success ml-2 pb-2"
                                                aria-hidden="true"
                                            ></i>
                                        </h1>
                                    </div>

                                    <div className="p-1 pl-5" style={{ marginTop: "18%" }}>
                                        {alldetails.map((item, key) => (
                                            getTextUI(item.left, item.right, key)
                                        ))}
                                    </div>

                                    <div style={{ marginTop: "18%" }}>
                                        <div>
                                            <button className="btn btn-success mb-2" onClick={() => window.location.reload()}>
                                                Done
                                            </button>
                                        </div>
                                        <Label>(Please show this to security)</Label>
                                    </div>
                                </div>
                            </center>
                        </CardBody>
                    </Card>
                )}

                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Visitor Form...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}

                {/* OTP Modal */}
                <Modal isOpen={otpPopup}>
                    <ModalHeader toggle={() => setOtpPopup(!otpPopup)}>
                        Enter OTP Here
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-center">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    value={digit}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={(ref) => (inputRefs.current[index] = ref)}
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        marginRight: '10px',
                                        textAlign: 'center',
                                        fontSize: '16px',
                                    }}
                                />
                            ))}
                        </div>
                    </ModalBody>
                    <Row className="p-3">
                        <Col className="text-center">
                            <button
                                onClick={() => setOtpPopup(!otpPopup)}
                                className="btn btn-secondary btn-sm m-1">
                                Cancel
                            </button>

                            <button
                                className="btn btn-primary btn-sm m-1"
                                onClick={() => {
                                    const otpStr = otp.join('');
                                    verifyOtp(otpStr);
                                }}>
                                Submit
                            </button>
                        </Col>
                    </Row>
                </Modal>
            </Container>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
        })(VisitorForm)
    )
);