import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  Badge,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  InputGroup,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap";
import DatePicker from "react-datepicker";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "react-switch";
import Editable from "react-bootstrap-editable";
import { Offsymbol, OnSymbol } from '../../components/Common/CommonFunctions';
import {
  getInvoiceItemForInvoiceId,
  updateInvoiceAndItems,
  getMTDForItemIds,
  successUpdateInvoiceValue,
} from "../../store/actions";

//import
const ViewInvoice = (props) => {
  const [togUp, togUpOps] = useState(false);
  const [activeYear, activeYearOps] = useState("2020-21");
  const [toggleId, toggleIdOps] = useState();
  const [invoiceTotal, invoiceTotalOps] = useState(0);
  const [invoiceTitle, invoiceTitleOps] = useState("");
  const [invoiceItems, invoiceItemsOps] = useState([]);
  const [showLoader, showLoaderOps] = useState(false);
  const [successRes, successResOps] = useState(false);
  const [paymentOption, paymentOptionOps] = useState();
  const [toggleId1, toggleId1Ops] = useState();

  const [receiptAddress, setReceiptAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [addressButton, setAddressButton] = useState(false);

  const [taxable, setTaxable] = useState(false);
  const [invoiceTax, setInvoiceTax] = useState(0);

  const [removeItemAlert, setRemoveItemAlert] = useState(undefined);
  const [removeItemList, setRemoveItemList] = useState([]);

  const [showRoyalty, setShowRoyalty] = useState(props.showRoyalty);
  const [royaltyAmount, setRoyaltyAmount] = useState(0);

  const [isDaycareInvoice, setIsDaycareInvoice] = useState(false);
  const [classRoomName, setClassRoomName] = useState()

  const getYearDropDown = () => {
    let currentState = activeYear;

    let newList = props.yearList.filter((item) => item !== currentState);

    return (
      <Dropdown
        isOpen={toggleId === currentState}
        toggle={() => toggleIdOps(toggleId ? undefined : currentState)}
      >
        <DropdownToggle className="bg-white border-0">
          <Badge
            className="font-size-12 badge-soft-success"
            color="success"
            pill
          >
            {currentState}
            <i className="mdi mdi-chevron-down"></i>
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          {newList.map((item, key) => (
            <DropdownItem key={key} onClick={() => activeYearOps(item)}>
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  };
  useEffect(() => {
    togUpOps(props.togInvoice);
  }, [props.togInvoice]);
  useEffect(() => {
    if (props.invoice.id) {
      invoiceTitleOps(props.invoice.invoiceTitle);
      setIsDaycareInvoice(props.invoice.Obj.attributes.isDaycareInvoice)
      if (props.invoice.paymentOptionId && props.paymentOptions) {
        let temp = props.paymentOptions.filter(
          (item) => item.id === props.invoice.paymentOptionId
        );
        if (temp.length > 0) {
          paymentOptionOps(
            temp[0].id
          );
        }
      }
      if (props.invoice.tag) {
        activeYearOps(props.invoice.tag);
      }
      props.getInvoiceItemForInvoiceId(props.invoice.id);
    }
  }, [props.invoice]);
  useEffect(() => {
    let tempItems = [];
    let invoiceTotalAmount = 0;
    let itemIds = [];
    let totalTax = 0;
    let totalRoyalty = 0;
    let taxed = false
    for (const val of props.invoiceItems) {
      let temp = {};
      temp.id = val.id;
      itemIds.push(temp.id);
      temp.Obj = val;
      temp.itemDescription = val.attributes.itemDescription;
      temp.duedate = val.attributes.dueDate;
      temp.amount = val.attributes.amount;
      temp.discount = val.attributes.discount + "";
      temp.discountType = val.attributes.discountType;
      temp.discountRemark = val.attributes.discountRemark;
      temp.discountDisabled = val.attributes.discount > 0;
      temp.totalAmount = val.attributes.totalAmount;
      temp.tax = val.attributes.tax;
      temp.paidAmount = val.attributes.paidAmount;

      temp.afterDiscount =
        (temp.discountType === "1" || temp.discountType === 1)
          ? temp.amount - temp.discount
          : temp.amount -
          (temp.amount * temp.discount) / 100;

      if (temp.tax > 0) {
        let tTax = roundToTwo(temp.afterDiscount * 0.01 * temp.tax);
        temp.taxAmount = tTax
        totalTax = totalTax + tTax
        taxed = true;
      } else {
        temp.taxAmount = 0;
      }

      if (!taxable) {
        setTaxable(taxed);
      }

      temp.royalty = val.attributes.royalty;
      if (temp.royalty > 0) {
        let royaltyAmount = (temp.totalAmount * temp.royalty) / 100;
        temp.royaltyAmount = royaltyAmount;
        totalRoyalty += royaltyAmount;
      } else {
        temp.royaltyAmount = 0;
      }

      temp.paidStatus = val.attributes.paidStatus;
      temp.minAmount = val.attributes.paidAmount ? val.attributes.paidAmount : 0;
      invoiceTotalAmount += temp.totalAmount;
      tempItems.push(temp);
    }
    setRoyaltyAmount(totalRoyalty);
    setInvoiceTax(totalTax);
    invoiceItemsOps(tempItems);
    invoiceTotalOps(invoiceTotalAmount);
    props.getMTDForItemIds(itemIds);
  }, [props.invoiceItems]);
  useEffect(() => {
    let tempItems = Object.assign([], invoiceItems);
    for (const val1 of tempItems) {
      val1.minAmount = 0;
    }
    for (const val of props.triansactionsItems) {
      for (const val1 of tempItems) {
        if (val.attributes.itemId === val1.id) {
          if (val.attributes.isDeleted) {
            break;
          }
          val1.minAmount += val.attributes.amount;
          break;
        }
      }
    }
    invoiceItemsOps(tempItems);
  }, [props.triansactionsItems]);
  useEffect(() => {
    if (props.successUpdateInvoice) {
      toastr.success("Invoice Updated Successfully", { timeOut: 2000 });
      if (togUp) {
        let id;
        props.tog_invoice(id, true);
      }
      props.getAllInvoices();
      props.successUpdateInvoiceValue();
    }
  }, [props.successUpdateInvoice]);
  useEffect(() => {
    showLoaderOps(props.showLoader);
    // if(!props.showLoader){
    //     props.tog_invoice()
    // }
  }, [props.showLoader]);
  useEffect(() => {
    if (props.successRes) {
      successResOps(props.successRes);
      props.tog_invoice();
      toastr.success("Invoice Updated Successfully", { timeOut: 2000 });
    }
  }, [props.successRes]);
  const addAnItemToInvoice = () => {
    let tempItems = Object.assign([], invoiceItems);
    let temp = {};
    temp.itemDescription = `Item ${tempItems.length + 1}`;
    temp.duedate = new Date();
    temp.amount = 0;
    temp.discount = 0 + "";
    temp.discountType = "1";
    temp.discountRemark = "";
    temp.discountDisabled = true;
    temp.totalAmount = 0;
    tempItems.push(temp);
    invoiceItemsOps(tempItems);
  };
  const checkIsAmountValid = (key, value) => {
    let tempItems = Object.assign([], invoiceItems);
    let updatedValue = value ? value : 0;
    if (updatedValue < tempItems[key]["minAmount"]) {
      updateInvoiceData(key, "amount", tempItems[key].minAmount);
      // tempItems[key].amount = tempItems[key].minAmount;
      // invoiceItemsOps(tempItems);
    }
  };
  const updateInvoiceData = (key, val, updatedValue) => {
    let tempItems = Object.assign([], invoiceItems);
    let totalAmount = 0;
    let totalTax = 0;
    let totalRoyalty = 0;
    tempItems[key][val] = updatedValue;
    // if (val === "amount") {
    //   if (updatedValue < tempItems[key]["minAmount"]) {
    //     tempItems[key].amount = tempItems[key].minAmount;
    //   }
    // }
    for (let i in tempItems) {
      tempItems[i].totalAmount =
        (tempItems[i].discountType == "1" || tempItems[i].discountType == 1)
          ? tempItems[i].amount - +tempItems[i].discount
          : tempItems[i].amount -
          (tempItems[i].amount * +tempItems[i].discount) / 100;
      tempItems[i].totalAmount = roundToTwo(tempItems[i].totalAmount);
      if (tempItems[i].tax > 0) {
        let tTax = roundToTwo(tempItems[i].totalAmount * 0.01 * tempItems[i].tax);
        tempItems[i].taxAmount = tTax
        totalTax = totalTax + tTax
        tempItems[i].totalAmount = tempItems[i].totalAmount + tTax
      } else {
        tempItems[i].taxAmount = 0;
      }
      totalAmount += tempItems[i].totalAmount;
      tempItems[i].discountDisabled = +tempItems[i].discount > 0 ? true : false;
      tempItems[i].discountRemark = tempItems[i].discountDisabled
        ? tempItems[i].discountRemark
        : "";


      if (tempItems[i].royalty > 0) {
        let royaltyAmount = (tempItems[i].totalAmount * tempItems[i].royalty) / 100
        tempItems[i].royaltyAmount = royaltyAmount;
        totalRoyalty += royaltyAmount;
      } else {
        tempItems[i].royaltyAmount = 0;
      }
    }
    setRoyaltyAmount(totalRoyalty);
    setInvoiceTax(totalTax)
    invoiceTotalOps(totalAmount);
    invoiceItemsOps(tempItems);
  };
  const removeItemFromNewInvoice = (key) => {
    let tempItems = Object.assign([], invoiceItems);
    let totalAmount = 0;
    let totalTax = 0;
    let totalRoyalty = 0;
    tempItems.splice(key, 1);
    for (let i in tempItems) {
      tempItems[i].totalAmount =
        (tempItems[i].discountType == "1" || tempItems[i].discountType == 1)
          ? tempItems[i].amount - +tempItems[i].discount
          : tempItems[i].amount -
          (tempItems[i].amount * +tempItems[i].discount) / 100;
      tempItems[i].totalAmount = roundToTwo(tempItems[i].totalAmount);

      if (tempItems[i].tax > 0) {
        let tTax = roundToTwo(tempItems[i].totalAmount * 0.01 * tempItems[i].tax);
        tempItems[i].taxAmount = tTax;
        totalTax = totalTax + tTax
        tempItems[i].totalAmount = tempItems[i].totalAmount + tTax
      }
      totalAmount += tempItems[i].totalAmount;

      let royalty = tempItems[i].royalty;
      if (royalty > 0) {
        let royaltyAmount = (tempItems[i].totalAmount * royalty) / 100
        tempItems[i].royaltyAmount = royaltyAmount;
        totalRoyalty += royaltyAmount;
      } else {
        tempItems[i].royaltyAmount = 0;
      }
    }
    setRoyaltyAmount(totalRoyalty)
    setInvoiceTax(totalTax);
    invoiceTotalOps(totalAmount);
    invoiceItemsOps(tempItems);
  };
  const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
  }
  const updateInvoice = () => {
    let invoiceNItem = {};
    invoiceNItem.id = props.invoice.id;
    invoiceNItem.title = invoiceTitle;
    if (props.invoice.Obj.attributes.isDaycareInvoice == undefined && isDaycareInvoice == false) {
      invoiceNItem.isDaycareInvoiceEdit = false;
    } else {
      invoiceNItem.isDaycareInvoiceEdit = true;
      invoiceNItem.isDaycareInvoice = isDaycareInvoice;
    }

    invoiceNItem.activeYear = activeYear;
    if (paymentOption) {
      invoiceNItem.paymentOption = props.paymentOptions.filter(
        (item) => item.id === paymentOption
      )[0].id;
    }
    if (selectedAddress) {
      if (selectedAddress.id === "schoolId") {
        invoiceNItem.receiptAddressId = undefined;
      } else {
        invoiceNItem.receiptAddressId = selectedAddress.id;
      }
    }
    invoiceNItem.invoiceTotalAmount = invoiceTotal;
    invoiceNItem.items = invoiceItems;
    if (royaltyAmount) {
      invoiceNItem.royaltyAmount = royaltyAmount;
      invoiceNItem.isRoyaltyInvoice = true;
    }

    if (removeItemList && removeItemList.length > 0) {
      let nl = [];
      nl = [...invoiceItems];
      for (const ri of removeItemList) {
        ri.isDeleted = true;
        nl.push(ri)
      }
      invoiceNItem.items = nl;
    }
    props.updateInvoiceAndItems(invoiceNItem);
  };

  const handleAddClassRoomname = async (value) => {
    try {
      let temp = props.invoice.Obj;
      temp.set("classRoomname", value);

      const savedObject = await temp.save();
      props.invoice.classRoomname = value;
      // console.log("Saved object:", savedObject);
    } catch (error) {
      console.error("Error updating classroom name", error);
    }
  };

  const getAccountDropDown = () => {
    let currentState;
    let newList;
    if (paymentOption) {
      currentState = paymentOption;
      newList = props.paymentOptions.filter(
        (item) => item.id !== currentState
      );
    }
    return (
      <Dropdown
        isOpen={toggleId1 === currentState}
        toggle={() => toggleId1Ops(toggleId ? undefined : currentState)}
      >
        <DropdownToggle className="bg-white border-0">
          <Badge
            className="font-size-12 badge-soft-success"
            color="success"
            pill
          >
            {props.paymentOptions.filter((item) => item.id === currentState)[0] ? props.paymentOptions.filter((item) => item.id === currentState)[0].accName : ""}
            ({
              props.paymentOptions.filter((item) => item.id === currentState)[0] ? props.paymentOptions.filter((item) => item.id === currentState)[0].showAccNo : ""
            })
            <i className="mdi mdi-chevron-down"></i>
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          {newList &&
            newList.map((item, key) => (
              <DropdownItem
                key={key}
                onClick={() => paymentOptionOps(item.id)}
              >
                {item.accName}({item.showAccNo})
              </DropdownItem>
            ))}
        </DropdownMenu>
      </Dropdown>
    );
  };

  useEffect(() => {
    if (props.receiptAddres.length > 0) {
      let temp = [];
      let schoolAddress = {
        id: "schoolId",
        name: "School Address"
      }
      temp.push(schoolAddress)
      for (const val of props.receiptAddres) {
        let tempIn = {};
        tempIn.id = val.id;
        tempIn.object = val;
        tempIn.name = val.attributes.name;
        tempIn.taxable = val.attributes.taxable;
        temp.push(tempIn);
      }
      if (props.invoice.receiptAddressId) {

        let newList = temp.filter(
          (item) => item.id === props.invoice.receiptAddressId
        );
        if (newList && newList.length > 0) {
          if (newList[0].taxable) {
            setTaxable(newList[0].taxable)
          }
          setSelectedAddress(newList[0]);
        } else {
          setSelectedAddress(temp[0]);
        }

      } else {
        setSelectedAddress(temp[0]);
      }

      setReceiptAddress(temp);

    }
  }, [props.receiptAddres, props.invoice]);

  const getReceiptAddressDropDown = () => {
    let newList = receiptAddress.filter(
      (item) => item.id !== selectedAddress.id
    );
    return (
      <div>

        <Dropdown
          className=""
          isOpen={addressButton}
          toggle={() => setAddressButton(!addressButton)}
        >
          <DropdownToggle className="bg-white border-0">
            <Badge
              className="font-size-12 badge-soft-success"
              color="success"
              pill
            >
              {
                newList.filter(
                  (item) => item.id === selectedAddress.id
                )
              }
              {selectedAddress.name} <i className="mdi mdi-chevron-down"></i>
            </Badge>
          </DropdownToggle>
          <DropdownMenu>
            {newList.map((item, key) => (
              <DropdownItem
                key={key}
                onClick={() => {
                  setSelectedAddress(item)
                  if (!taxable) {
                    setTaxable(item.taxable)
                  }
                }}
              >
                {item.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
  return (
    <Modal
      isOpen={togUp}
      toggle={() => {
        props.tog_invoice();
      }}
      size="xl"
    >
      <AvForm className="outer-repeater">
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            <span>{props.t("Invoice Number") + ": "}</span>
            <span>{props.invoice.invoiceNumber}</span>
          </h5>
          <button
            type="button"
            onClick={() => {
              props.tog_invoice();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            disabled={showLoader}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row className="mb-2">
            <Col md="3" xs="3" xl="3">
              <h6>{props.t("Invoice Title")} :</h6>
              {/* </Col>
                        <Col md="3" xs="3" xl="3"> */}
              <input
                type="text"
                value={invoiceTitle}
                onChange={(e) => {
                  invoiceTitleOps(e.target.value);
                }}
                className="form-control"
              />
            </Col>
            {props.showClass && (<Col>
              <h6>{props.t("Class Name")} :</h6>
              <Editable
                className={'d-flex'}
                alwaysEditing={false}
                onSubmit={(val) => {
                  handleAddClassRoomname(val)
                }}
                onCancel={() => {
                  setClassRoomName()
                }}
                disabled={""}
                editText={props.invoice.classRoomname ? " ✎" : "No value ✎"}
                id={null}
                isValueClickable={false}
                mode="inline"
                placement="top"
                initialValue={
                  props.invoice.classRoomname
                    ? props.invoice.classRoomname
                    : ""
                }
                showText
                type="textfield"
              />
            </Col>)}
            {props.invoice.paymentOptionId && props.paymentOptions.length > 1 && (
              <Col>
                <h6 className="ml-3">{props.t("Account Selected")}</h6>
                {getAccountDropDown()}
              </Col>
            )}

            {props.receiptAddres && props.receiptAddres.length > 0 && (
              <Col>
                <h6 className="ml-3">{props.t("Receipt Address")}</h6>
                {getReceiptAddressDropDown()}
              </Col>
            )}

            <Col >
              <h6 className="ml-3">{props.t("Year")} :</h6>
              {/* </Col> */}
              {/* <Col md="3" xs="3" xl="3"> */}
              {getYearDropDown()}
            </Col>

            <Col>
              <h6 className="ml-3">{props.t("Is daycare invoice")} :</h6>
              <div className="text-center">
                <Switch
                  height={15}
                  width={30}
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  onColor={"#14A44D"}
                  checked={isDaycareInvoice}
                  onChange={() => {
                    const updatedValue = !isDaycareInvoice;
                    setIsDaycareInvoice(updatedValue);

                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {/* <Col className="table-responsive"> */}
            <Table className="table table-nowrap mb-0">
              <thead>
                <tr>
                  <th>{props.t("Slno")}</th>
                  <th>{props.t("Particular")}</th>
                  <th>{props.t("Due Date")}</th>
                  <th>{props.t("Amount")}</th>
                  <th>{props.t("Discount")}</th>
                  <th>{props.t("Discount Remark")}</th>
                  {taxable && <th>{props.t("GST")}</th>}
                  {showRoyalty && <th>{props.t("Royalty")}</th>}
                  <th>{props.t("Item Total")}</th>
                </tr>
              </thead>
              <tbody>
                {invoiceItems.map((val, key) => (
                  <tr key={key}>
                    <td className="pb-0">{key + 1}</td>
                    <td className="pb-0">
                      <FormGroup className="mb-0">
                        <AvField
                          type="text"
                          name="name"
                          placeholder={props.t("Enter title...")}
                          className="form-control mb-0"
                          value={val.itemDescription}
                          disabled={val.paidStatus === 1 ? true : (props.completeDisable ?? false)}
                          onChange={(e) => {
                            updateInvoiceData(
                              key,
                              "itemDescription",
                              e.target.value
                            );
                          }}
                        />
                        <p className="text-warning">
                          {val.paidStatus === 1 &&
                            props.t("Item is paid.")}
                        </p>

                        {val.paidStatus === 3 &&
                          <p className="text-warning">
                            {"Partially Paid, "} <span className="text-success">{"Paid Amount: " + val.paidAmount}</span>
                          </p>
                        }
                      </FormGroup>
                    </td>
                    <td className="pb-0">
                      <FormGroup>
                        <DatePicker
                          selected={val.duedate}
                          onChange={(date) => {
                            updateInvoiceData(key, "duedate", date);
                          }}
                          className="form-control"
                          placeholderText={props.t("Selecte Date")}
                          startDate={val.duedate}
                          disabled={val.paidStatus === 1 ? true : (props.completeDisable ?? false)}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                        />
                      </FormGroup>
                    </td>
                    <td className="pb-0">
                      <FormGroup>
                        <AvField
                          type="number"
                          name="name"
                          placeholder={props.t("Item amount...")}
                          className="form-control"
                          value={val.amount}
                          min={val.minAmount}
                          // validate={{ required: {
                          //     value: true,
                          //     errorMessage: props.t('Please enter father email...'),
                          //   },}}
                          disabled={val.paidStatus === 1 ? true : (props.completeDisable ?? false)}
                          onChange={(e) => {
                            updateInvoiceData(key, "amount", e.target.value);
                          }}
                          onBlur={(e) => {
                            checkIsAmountValid(key, e.target.value);
                          }}
                        />
                      </FormGroup>
                    </td>
                    <td style={{ minWidth: "7rem" }} className="pb-0">
                      <InputGroup>
                        <input
                          type="number"
                          className="form-control"
                          value={val.discount}
                          placeholder="number"
                          min={0}
                          disabled={val.paidStatus === 1 ? true : false}
                          onChange={(e) => {
                            updateInvoiceData(key, "discount", e.target.value);
                          }}
                        />
                        <div className="input-group-append">
                          <select
                            value={val.discountType}
                            onChange={(e) => {
                              updateInvoiceData(key, "discountType", e.target.value);
                            }}
                            className="form-select" // Use the Bootstrap 5 class for select
                            style={{ fontFamily: "Arial, sans-serif" }}
                            disabled={val.paidStatus === 1 ? true : false}
                          >
                            <option value="1">{props.currency}</option>
                            <option value="2">%</option>
                          </select>
                        </div>
                      </InputGroup>

                    </td>
                    <td className="pb-0">
                      <FormGroup>
                        <AvField
                          type="text"
                          name="name"
                          placeholder={props.t("Remark...")}
                          className="form-control"
                          value={val.discountRemark}
                          disabled={val.paidStatus === 1 ? true : false}

                          onChange={(e) => {
                            updateInvoiceData(
                              key,
                              "discountRemark",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </td>
                    {taxable && <td style={{ minWidth: "5rem" }}>
                      <InputGroup>

                        <div className="input-group-append">
                          {/* <span className="input-group-text">%</span> */}
                          <select
                            value={val.tax}
                            onChange={(e) => {
                              updateInvoiceData(
                                key,
                                "tax",
                                e.target.value === "0" ? undefined : parseInt(e.target.value)
                              );
                            }}
                            style={{ fontFamily: "Arial, sans-serif" }}
                            disabled={val.paidStatus === 1 ? true : false}
                          >
                            <option value="0">0%</option>
                            <option value="5">5%</option>
                            <option value="12">12%</option>
                            <option value="18">18%</option>
                            <option value="28">28%</option>

                          </select>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          value={val.taxAmount}
                          placeholder="00"
                          min={0}
                          disabled
                        />
                      </InputGroup>
                    </td>}
                    {showRoyalty && (
                      <td style={{ minWidth: "5rem" }}>
                        <InputGroup>
                          <input
                            type="number"
                            className="form-control"
                            value={val.royalty}
                            placeholder="Royalty"
                            min={0}
                            onChange={(e) => {
                              updateInvoiceData(key, "royalty", e.target.value);
                            }}
                            disabled={true}
                          // disabled={val.paidStatus === 1 ? true : (props.completeDisable ?? false)}
                          />
                          <div className="input-group-append">
                            <select
                              value="2"
                              disabled
                              className="form-select"
                              style={{ fontFamily: "Arial, sans-serif" }}
                            >
                              <option value="2">%</option>
                            </select>
                          </div>
                        </InputGroup>
                        {val.royalty > 0 && (
                          <p className="text-center text-size-12">
                            {props.currency}
                            {val.royaltyAmount ?? 0}
                          </p>
                        )}
                      </td>
                    )}
                    <td className="pb-0">
                      <FormGroup>
                        <AvField
                          type="number"
                          name="name"
                          placeholder={props.t("Total...")}
                          className="form-control"
                          disabled={true}
                          value={parseFloat(val.totalAmount).toFixed(2)}
                          min={val.minAmount}
                        // validate={{ required: {
                        //     value: true,
                        //     errorMessage: props.t('Please enter father email...'),
                        //   },}}
                        // onChange={(e) => {
                        //     fatherEmailOps(e.target.value);
                        // }}
                        />
                      </FormGroup>
                    </td>
                    {!showRoyalty &&
                      <td className="pb-0">
                        {!val.id && (
                          <i
                            onClick={() => removeItemFromNewInvoice(key)}
                            className="bx bx-message-square-x text-danger h3 mt-1"
                            style={{ cursor: "pointer" }}
                          ></i>
                        )}
                        {(!(val.paidStatus === 3 || val.paidStatus === 1)) && val.id && (
                          <i
                            onClick={() => setRemoveItemAlert({ object: val, key: key })}
                            className="bx bx-message-square-x text-danger h3 mt-1"
                            style={{ cursor: "pointer" }}
                          ></i>
                        )}
                      </td>}
                  </tr>
                ))}
                {invoiceItems.length === 0 && (
                  <tr>
                    {props.itemsLoading ? <td
                      className="text-center text-Secondary h5 pt-5"
                      colSpan="7"
                    >
                      <Label className="text-success">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading
                      </Label>
                    </td> :
                      <td
                        className="text-center text-Secondary h5 pt-5"
                        colSpan="7"
                      >
                        {props.t("To create invoice start")}{" "}
                        <span
                          className="text-info"
                          onClick={addAnItemToInvoice}
                          style={{ cursor: "pointer" }}
                        >
                          <button
                            className="btn btn-info"
                            disabled={!props.teacherPaymenrWrite}
                          >
                            {" "}
                            {props.t("Adding items")}{" "}
                          </button>
                        </span>
                      </td>}
                  </tr>
                )}
                {invoiceItems.length > 0 && !showRoyalty && (
                  <tr>
                    <td colSpan={taxable && showRoyalty ? "8" : (taxable || showRoyalty) ? "7" : "6"}></td>
                    <td>
                      <button
                        className="btn btn-info"
                        onClick={addAnItemToInvoice}
                        style={{ width: "100%" }}
                        disabled={!props.teacherPaymenrWrite}
                      >
                        + Add Item
                      </button>
                    </td>
                  </tr>
                )}

                {invoiceItems.length > 0 && taxable && (
                  <tr>
                    <td colSpan="6"></td>
                    <td className="pb-0">
                      <FormGroup className="mb-0">
                        <Label className="mb-0">
                          {props.t("GST")} :
                        </Label>
                        <input
                          type="number"
                          className="form-control mb-0"
                          value={parseFloat(invoiceTax).toFixed(2)}
                          disabled={true}
                        ></input>
                      </FormGroup>
                    </td>
                    {showRoyalty && <td className="pb-0">
                      <FormGroup className="mb-0">
                        <Label className="mb-0">
                          {props.t("Royalty Amount")} :
                        </Label>
                        <input
                          type="number"
                          className="form-control mb-0"
                          value={parseFloat(royaltyAmount).toFixed(2)}
                          disabled={true}
                        ></input>

                      </FormGroup>
                    </td>}

                    <td className="pb-0">
                      <FormGroup className="mb-0">
                        <Label className="mb-0">
                          {props.t("Total Amount")} :
                        </Label>
                        <input
                          type="number"
                          className="form-control mb-0"
                          value={parseFloat(invoiceTotal).toFixed(2)}
                          disabled={true}
                        ></input>
                      </FormGroup>
                    </td>
                  </tr>
                )}
                {invoiceItems.length > 0 && !taxable && (
                  <tr>
                    <td colSpan={showRoyalty ? "6" : "6"}></td>
                    {showRoyalty && <td className="pb-0">
                      <FormGroup className="mb-0">
                        <Label className="mb-0">
                          {props.t("Royalty Amount")} :
                        </Label>
                        <input
                          type="number"
                          className="form-control mb-0"
                          value={parseFloat(royaltyAmount).toFixed(2)}
                          disabled={true}
                        ></input>

                      </FormGroup>
                    </td>}
                    <td className="pb-0">
                      <FormGroup className="mb-0">
                        <Label className="mb-0">
                          {props.t("Total Amount")} :
                        </Label>
                        <input
                          type="number"
                          className="form-control mb-0"
                          value={parseFloat(invoiceTotal).toFixed(2)}
                          disabled={true}
                        ></input>
                      </FormGroup>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {/* </Col> */}
          </Row>
        </div>
        <div className="modal-footer table-responsive">
          <Table className="table table-nowrap table-borderless mb-0">
            <thead>
              <tr>
                <td style={{ minWidth: "7rem" }}></td>
                <td style={{ minWidth: "8rem" }}></td>
                <td style={{ minWidth: "8rem" }}></td>
                <td style={{ minWidth: "8rem" }}></td>
                <td style={{ minWidth: "8rem" }}>
                  {!props.teacherPaymenrWrite && (
                    <p className="text-danger mt-2">
                      You don't have permission
                    </p>
                  )}
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => {
                      props.tog_invoice();
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                    style={{ width: "100%" }}
                    disabled={showLoader}
                  >
                    {props.t("Close")}
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={updateInvoice}
                    disabled={
                      props.teacherPaymenrWrite
                        ? invoiceItems.length === 0
                        : true
                    }
                    style={{ width: "90%" }}
                  >
                    {showLoader && (
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                    )}
                    {props.t("Update Invoice")}
                  </button>
                </td>
                <td></td>
              </tr>
            </thead>
          </Table>
        </div>
      </AvForm>
      {removeItemAlert && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Remove"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            setRemoveItemAlert(undefined);
          }}
          onConfirm={() => {
            removeItemFromNewInvoice(removeItemAlert.key);
            let tl = [...removeItemList];
            tl.push(removeItemAlert.object);
            setRemoveItemList(tl);
            setRemoveItemAlert(undefined);
          }}
        >
          {props.t("Are you sure you want to remove item")} <br></br>
        </SweetAlert>
      )}
    </Modal>
  );
};
const mapStatetoProps = (state) => {
  const { invoiceItems, itemsLoading } = state.InvoiceItems;
  const { showLoader, successRes, successUpdateInvoice } = state.Invoice;
  const { triansactionsItems } = state.MoneyTransactionDetails;
  const { receiptAddres } = state.School;

  return {
    invoiceItems,
    showLoader,
    successRes,
    successUpdateInvoice,
    triansactionsItems,
    receiptAddres,
    itemsLoading,
  };
};
export default connect(mapStatetoProps, {
  getInvoiceItemForInvoiceId,
  updateInvoiceAndItems,
  getMTDForItemIds,
  successUpdateInvoiceValue,
})(withRouter(withNamespaces()(ViewInvoice)));
