import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Container, Media, Modal, ModalBody, ModalFooter, ModalHeader, Button, Label, FormGroup } from "reactstrap";
import { withRouter, Link,useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import Parse from 'parse';
import TopBar from './Topbar';
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { getSchoolAnalytics, editSchool, getFranchise, getInventoryCategoriesForOwnerId } from '../../store/actions';
import logo from "../../assets/images/lilLogo.png";
import * as Constants from "../../Constents";
import Moment from "moment";
import Editable from "react-bootstrap-editable";
import DatePicker from "react-datepicker";
import COUNTRY_JSON from '../../assets/other/countries.json'
import Select from "react-select";

const SchoolList = (props) => {

  const [schoolList, setSchoolList] = useState([]);
  const [selectedState, setSelectedState] = useState(undefined);
  const [selectedCity, setSelectedCity] = useState(undefined);
  const [selectedArea, setSelectedArea] = useState(undefined);
  const [allCityList, setAllCityList] = useState([]);
  const [allAreaList, setAllAreaList] = useState([]);
  const [error, setError] = useState(props.t('No branch found'));
  const [showDocumentsButton, setShowDocumentsButton] = useState(false);
  const [showCategoryButton, setShowCategoryButton] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [inventoryCategory, setInventoryCategory] = useState(undefined);
  const [categorySelcetWarning, setcategorySelcetWarning] = useState(false);
  const [categoriesList, setCategoriesList] = useState([])

  const [fromDate, setFromDate] = useState(undefined);
  const [fromDateError, setFromDateError] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [toDateError, setToDateError] = useState(undefined);

  const [selectedCountry, setSelctedCountry] = useState({ value: 'all', label: "ALL" });
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [nonIndianSates, setNonIndianSates] = useState([]);
  const [showSubscription, setShowSubscription] = useState(false);


  useEffect(() => {
    if (props.franchise) {
      if (props.franchise.attributes.features &&
        props.franchise.attributes.features.length > Constants.FRANCHISE_DOCUMENT_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_DOCUMENT_INDEX] > 0) {
        setShowDocumentsButton(true)
      }
      if (props.franchise.attributes.features &&
        props.franchise.attributes.features.length > Constants.FRANCHISE_INVENTORY_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_INVENTORY_INDEX] > 1) {
        setShowCategoryButton(true)
      }
      if (props.franchise.attributes.features &&
        props.franchise.attributes.features.length > Constants.FRANCHISE_MANAGE_BRANCH_SUBSCRIPTION &&
        props.franchise.attributes.features[Constants.FRANCHISE_MANAGE_BRANCH_SUBSCRIPTION] > 0) {
        setShowSubscription(true)
      }
    }
  }, [props.franchise]);

  useEffect(() => {
    if (props.franchise && props.franchise) {
      props.getInventoryCategoriesForOwnerId(props.franchise.id, 'franchise');
    }
  }, [props.franchise]);

  useEffect(() => {
    if (props.franchise && props.franchise.attributes.features &&
      props.franchise.attributes.features.length > Constants.FRANCHISE_INVENTORY_INDEX &&
      props.franchise.attributes.features[Constants.FRANCHISE_INVENTORY_INDEX] > 1) {
      let list = [];
      if (props.inventoryCategories) {
        for (const s of props.inventoryCategories) {
          list.push({
            value: s.id,
            label: s.attributes.name,
          })
        }
      }
      setCategoriesList(list)
    }
  }, [props.franchise]);


  useEffect(() => {
    if (Parse.User.current().attributes.franchiseId) {
      setAllCountryCodeList()
      if (!props.franchise) {
        props.getFranchise(Parse.User.current().attributes.franchiseId);
      }
      var tempList = [...props.schools];
      setSchoolList(tempList);
      props.getSchoolAnalytics(Parse.User.current().attributes.franchiseId, "franchise");
    } else {
      props.history.push("/pages-404")
    }

  }, [props.schools]);

  const getCategoryName = (categoryId) => {
    if (props.inventoryCategories && props.inventoryCategories.length > 0) {
      let temp = props.inventoryCategories.filter(
        (category) => category.id === categoryId
      );
      if (temp.length > 0) {
        return (temp[0].attributes.name ?? "-")
      }
    }
  }

  const setAllCountryCodeList = () => {
    let allCountries = [{ value: "all", label: "ALL" }]
    for (const country of COUNTRY_JSON) {
      let countryObject = {
        value: country.code,
        label: country.name,
      }
      allCountries.push(countryObject)
    }
    setCountryCodeList(allCountries)
  }

  useEffect(() => {
    props.handelSchoolCount(schoolList ? schoolList.length : 0);
  }, [schoolList]);

  useEffect(() => {
    handelSearch(props.searchString);
  }, [props.searchString]);

  const handelSearch = (search) => {
    var tempList = [...props.schools];
    if (!tempList || tempList.length == 0) {
      return [];
    }
    if (search === undefined || search === null || search.length === 0) {
      setSchoolList(tempList);
    } else if (tempList) {
      var filter = tempList.filter(function (item) {
        return (item.attributes.Name.toLowerCase()).includes(search.toLowerCase());
      });
      if (!filter || filter.length == 0) {
        setError(props.t('No branch found for the search'));
      }
      setSchoolList(filter);
    }
  }
  const filterSchools = (sCountry, stCode, city, area, fromDate, toDate) => {

    let displayLis = [];
    let tempList = [...props.schools];
    if (sCountry.value != "all") {
      tempList = tempList.filter(function (item) {
        return (item.attributes.countryCode === sCountry.value);
      });
    }
    if (stCode && city && area) {


      let filterS = tempList.filter(function (item) {
        return (sCountry.value == "IN" ? item.attributes.areaCode === stCode.value : item.attributes.state === stCode.value);
      });

      let filterC = filterS.filter(function (item) {
        return (item.attributes.city === city.value);
      });
      let filterA = filterC.filter(function (item) {
        return (item.attributes.locality === area.value);
      });

      displayLis = [...filterA];
    } else if (stCode && city) {


      let filter1 = tempList.filter(function (item) {
        return (sCountry.value == "IN" ? item.attributes.areaCode === stCode.value : item.attributes.state === stCode.value);
      });

      let filter = filter1.filter(function (item) {
        return (item.attributes.city === city.value);
      });

      if (filter && filter.length > 0) {
        let arealist = [];
        for (const s of filter) {
          if (s.attributes.locality && !arealist.includes(s.attributes.locality)) {
            arealist.push({
              value: s.attributes.locality,
              label: s.attributes.locality
            });
          }
        }
        // console.log("arealist", arealist)
        setAllAreaList(arealist);

      }
      displayLis = [...filter]

    } else if (stCode) {

      let filter = tempList.filter(function (item) {
        return (sCountry.value == "IN" ? item.attributes.areaCode === stCode.value : item.attributes.state === stCode.value);
      });

      if (filter && filter.length > 0) {
        const uniqueCities = new Set();
        let citylist = [];

        for (const s of filter) {
          const city = s.attributes.city;

          if (!uniqueCities.has(city)) {
            uniqueCities.add(city);
            citylist.push({
              value: city,
              label: city,
            });
          }
        }
        setAllCityList(citylist)
      }
      displayLis = [...filter]
    } else {
      displayLis = [...tempList]
    }


    if (fromDate && displayLis) {
      let filter = displayLis.filter(function (item) {
        return (item.attributes.createdAt >= fromDate);
      });
      displayLis = [...filter]
    }

    if (toDate && displayLis) {
      let filter = displayLis.filter(function (item) {
        return (item.attributes.createdAt <= toDate);
      });
      displayLis = [...filter]
    }

    setSchoolList(displayLis);

  }

  const getAnalyticsObject = (schoolId) => {
    if (props.schoolAnalytics && props.schoolAnalytics.length > 0) {
      for (const sa of props.schoolAnalytics) {

        if (sa.attributes.schoolId == schoolId) {
          return sa;
        }
      }
    }

    return undefined;
  }

  const getNonIndianStates = (sCountry) => {
    let tempList = [...props.schools];

    if (sCountry.value != "all") {
      tempList = tempList.filter(function (item) {
        return (item.attributes.countryCode === sCountry.value);
      });
    }
    let tempStates = [];
    let list = []
    for (let school of tempList) {
      if (school.attributes.state && !tempStates.includes(school.attributes.state)) {
        tempStates.push(school.attributes.state)
        list.push({
          value: school.attributes.state,
          label: school.attributes.state
        })
      }

    }

    setNonIndianSates(list)

  }

  const getAnalyticsUI = (schoolId) => {
    let analyticsObject = getAnalyticsObject(schoolId)
    if (analyticsObject) {
      return (
        <Media className="overflow-hidden ml-4" body>
          <p className="text-muted mb-0">{"All Students: " + analyticsObject.attributes.studentCount}</p>
          <p className="text-muted mb-0">{"Daycare Students: " + analyticsObject.attributes.daycareStudentCount}</p>
          <p className="text-muted mb-0">{"Directors/Admins: " + analyticsObject.attributes.directorCount}</p>
          <p className="text-muted mb-0">{"Teachers: " + analyticsObject.attributes.teacherCount}</p>
        </Media>
      )
    }
  }

  function getDays(d) {
    if (d === "") {
      return "";
    } else {
      let today = new Date();
      let diffTime = today - d;
      let diffDays = diffTime / (1000 * 3600 * 24);
      if (diffDays > 0) {
        return parseInt(diffDays + "") + " day's ago "
      } else {
        return "";
      }

    }
  }

  const subscriptionExpiryDateUpdate = (school, date) => {
    console.log("subscriptionExpiryDate", date);
    school.set("subscriptionExpiryDate", date);
    props.editSchool(school);
  };

  const handleSaveCategory = (school) => {
    if (!inventoryCategory) {
      console.error("No inventory category selected.");
      return;
    }

    let list = [];
    for (const s of props.schools) {
      if (s.attributes.inventoryCategories && s.attributes.inventoryCategories.includes(inventoryCategory)) {
        list.push(s.id);
      }
    }

    if (!list.includes(school.id)) {
      list.push(school.id);
    }

    const selectedCategory = props.inventoryCategories.find(category => category.id === inventoryCategory);

    if (selectedCategory) {
      selectedCategory.set("schools", list);

      selectedCategory.save().then(() => {
        console.log(`Successfully updated selected category: ${selectedCategory.id}`);
      }).catch((error) => {
        console.error("Error updating selected category:", error);
      });
    } else {
      console.error("Selected category not found.");
    }

    school.set("inventoryCategories", [inventoryCategory]);
    props.editSchool(school);
  };

  const handleUnSetCategory = (school) => {
    if (!school.attributes.inventoryCategories) {
      console.error("No inventory category associated with the school.");
      return;
    }

    const inventoryCategory = props.inventoryCategories.find(
      (category) => category.id === school.attributes.inventoryCategories[0]
    );

    if (!inventoryCategory) {
      console.error("Inventory category not found.");
      return;
    }

    const schoolsList = inventoryCategory.attributes.schools || [];
    const updatedSchoolsList = schoolsList.filter((id) => id !== school.id);

    inventoryCategory.set("schools", updatedSchoolsList);

    inventoryCategory.save().then(() => {
      console.log(`Successfully updated inventory category: ${inventoryCategory.id}`);
    }).catch((error) => {
      console.error("Error updating inventory category:", error);
    });

    school.unset("inventoryCategories");
    props.editSchool(school);
  };

  const handleGrantAccess = (school) => {
    // const navigate = useNavigate();
    // const history = useHistory();

    console.log("function_InstituteAccessToUser")

    if (Parse.User.current().attributes.franchiseId == school.attributes.franchiseId) {
      Parse.Cloud.run("function_InstituteAccessToUser", {
        instituteId: school.attributes.instituteId,
      }).then((result) => {
        // window.location.href = "/dashboard/" + school.id;
        // navigate("/dashboard/" + school.id)
        // history.push("/dashboard/" + school.id)

        console.log("function_InstituteAccessToUser",result)
      },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  return (
    <React.Fragment>


      {/* <TopBar title={schoolList != null ? "ALL BRANCHES (" + schoolList.length + ")" : "(0)"} search={true} onSearch={handelSearch} /> */}


      <Card>
        <CardBody>
          <AvForm
            className="outer-repeater"
            style={{ border: "1px" }}
          // onSubmit={isFormValid}
          >
            <Row
              data-repeater-list="outer-group"
              className="outer"
            >
              <Col lg={4} >
                <FormGroup>
                  <Label >{props.t('Country')} <span className="text-danger">*</span> </Label>
                  <Select
                    value={selectedCountry}
                    onChange={(v) => {

                      getNonIndianStates(v)
                      setSelctedCountry(v)
                      setSelectedState(undefined);
                      setSelectedCity(undefined);
                      setAllCityList([]);
                      setSelectedArea(undefined);
                      setAllAreaList([]);
                      filterSchools(v)
                    }}
                    options={countryCodeList}
                    classNamePrefix="select2-selection"
                  />

                </FormGroup>
              </Col>
              <Col lg={4} data-repeater-item className="outer">
                <Label className="mt-2 mb-0">State</Label>
                {/* <select
                  className="custom-select"
                  value={selectedState}
                  onChange={(e) => {
                    if (e.target.value === "all_state") {
                      setSelectedState(undefined);
                      setSelectedCity(undefined);
                      setAllCityList([]);
                      setSelectedArea(undefined);
                      setAllAreaList([]);
                      filterSchools(selectedCountry,undefined, undefined);
                    } else {
                      setSelectedState(e.target.value);
                      setSelectedCity(undefined);
                      setAllCityList([]);
                      setSelectedArea(undefined);
                      setAllAreaList([]);
                      filterSchools(selectedCountry,e.target.value, undefined);
                    }

                  }}
                >
                  <option disabled selected value>-- Pick State --</option>
                  <option value={"all_state"}>
                    {"All"}
                  </option>
                  {selectedCountry.value == "IN" ? Constants.stateName.map((val, key) => (
                    <option key={key} value={val.value}>
                      {val.label}
                    </option>
                  )) :
                    nonIndianSates.map((val, key) => (
                      <option key={key} value={val.value}>
                        {val.label}
                      </option>
                    ))
                  }
                </select> */}

                <Select
                  value={selectedState}
                  onChange={(v) => {

                    if (v === "all_state") {
                      setSelectedState(undefined);
                      setSelectedCity(undefined);
                      setAllCityList([]);
                      setSelectedArea(undefined);
                      setAllAreaList([]);
                      filterSchools(selectedCountry, undefined, undefined);
                    } else {
                      setSelectedState(v);
                      setSelectedCity(undefined);
                      setAllCityList([]);
                      setSelectedArea(undefined);
                      setAllAreaList([]);
                      filterSchools(selectedCountry, v, undefined);
                    }

                  }}
                  options={selectedCountry.value == "IN" ? Constants.stateName : nonIndianSates}
                  classNamePrefix="select2-selection"
                />

              </Col>
              <Col lg={4} data-repeater-item className="outer">
                <Label className="mt-2 mb-0">City</Label>
                {/* <select
                  className="custom-select"
                  value={selectedCity}
                  onChange={(e) => {
                    if (e.target.value === "all_city") {
                      setSelectedCity(undefined);
                      setSelectedArea(undefined);
                      setAllAreaList([])
                      filterSchools(selectedCountry,selectedState, undefined);

                    } else {
                      setSelectedCity(e.target.value);
                      setSelectedArea(undefined);
                      setAllAreaList([])
                      filterSchools(selectedCountry,selectedState, e.target.value);

                    }
                  }}
                >
                  <option disabled selected value>-- Pick City --</option>
                  {
                    <option value={"all_city"}>
                      {"All"}
                    </option>
                  }
                  {allCityList.map((val, key) => (
                    <option value={val} key={key}>
                      {val}
                    </option>
                  ))}
                </select> */}
                <Select
                  value={selectedCity}
                  onChange={(v) => {

                    if (v === "all_city") {
                      setSelectedCity(undefined);
                      setSelectedArea(undefined);
                      setAllAreaList([])
                      filterSchools(selectedCountry, selectedState, undefined);

                    } else {
                      setSelectedCity(v);
                      setSelectedArea(undefined);
                      setAllAreaList([])
                      filterSchools(selectedCountry, selectedState, v);

                    }

                  }}
                  options={allCityList}
                  classNamePrefix="select2-selection"
                />

              </Col>
              <Col lg={4} data-repeater-item className="outer">
                <Label className="mt-2 mb-0">Area</Label>
                {/* <select
                  className="custom-select"
                  value={selectedArea}
                  onChange={(e) => {
                    if (e.target.value === "all_area") {
                      setSelectedArea(undefined);
                      setSelectedArea(selectedState, selectedCity, undefined)
                    } else {
                      setSelectedArea(e.target.value);
                      filterSchools(selectedCountry,selectedState, selectedCity, e.target.value)
                    }
                  }}
                >

                  <option disabled selected value>-- Pick Area --</option>
                  {
                    <option value={"all_area"}>
                      {"All"}
                    </option>
                  }
                  {allAreaList.map((val, key) => (
                    <option value={val} key={key}>
                      {val}
                    </option>
                  ))}
                </select> */}
                <Select
                  value={selectedArea}
                  onChange={(v) => {

                    if (v === "all_area") {
                      setSelectedArea(undefined);
                      setSelectedArea(selectedState, selectedCity, undefined)
                    } else {
                      setSelectedArea(v);
                      filterSchools(selectedCountry, selectedState, selectedCity, v)
                    }
                  }}
                  options={allAreaList}
                  classNamePrefix="select2-selection"
                />
              </Col>




              <Col lg={4} xs={6}>
                <FormGroup className="mt-2 mb-2 p-1">
                  <Label className="mb-0">
                    {props.t("From")} :
                  </Label>
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => {
                      setFromDateError(false);
                      setFromDate(date)
                      filterSchools(selectedCountry, selectedState, selectedCity, selectedArea, date, toDate)
                    }}
                    className="form-control"
                    placeholderText={props.t("Select Date")}
                    startDate={fromDate}
                    endDate={toDate}
                    maxDate={toDate}
                    dateFormat="dd/MM/yyyy"
                    required
                    errorMessage="Please select"
                  />
                </FormGroup>
                {fromDateError && <p className="text-danger ml-2">Please select</p>}
              </Col>
              <Col lg={4} xs={6}>
                <FormGroup className="mt-2 mb-2 p-1">
                  <Label className="mb-0">
                    {props.t("To")} :
                  </Label>
                  <DatePicker
                    selected={toDate}
                    onChange={(date) => {
                      setToDateError(false);
                      setToDate(date)
                      filterSchools(selectedCountry, selectedState, selectedCity, selectedArea, fromDate, date)
                    }}
                    className="form-control"
                    placeholderText={props.t("Select Date")}
                    startDate={fromDate}
                    endDate={toDate}
                    minDate={fromDate}
                    dateFormat="dd/MM/yyyy"
                    required
                    errorMessage="Please select"
                  />
                </FormGroup>
                {toDateError && <p className="text-danger ml-2">Please select</p>}
              </Col>




            </Row>
          </AvForm>

        </CardBody>
      </Card>

      {schoolList != null &&
        schoolList.length > 0 ?
        schoolList.map((school, key) => (
          <Col key={"_school_" + key}>
            <Card
              onClick={() => {
                // props.history.push("/dashboard/" + school.id)
              }}
            >
              <CardBody>

                <Row>
                  <Col lg={7}>
                    <Row className='d-flex justify-content-between'>
                      <Col lg={2}>
                        {school.attributes.logo != undefined ? (
                          <div className="avatar-md  mr-4 mt-0">
                            <img
                              src={
                                school.attributes.logo == undefined
                                  ? logo
                                  : school.attributes.logo._url
                              }
                              alt=""
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        ) : (
                          <div className="avatar-md mr-4">
                            <span className="avatar-title rounded-circle bg-light text-primary font-size-16">
                              <div style={{ fontSize: "48px" }}>
                                <i className="bx bx-buildings"></i>
                              </div>
                            </span>
                          </div>
                        )}
                      </Col>
                      <Col lg={6}>
                        {/* <Media className="overflow-hidden" body> */}
                        <h5 className="text-truncate font-size-15 mt-1">

                          <Link
                            to={"/dashboard/" + school.id}
                            target="_blank"
                            className="text-dark"
                            disabled={props.isSupervisor}
                          >
                            {school.attributes.Name}
                          </Link>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>{school.attributes.locality && school.attributes.locality + ", "}</span>
                          <span>{school.attributes.city}</span>
                          {school.attributes.countryCode == "IN" && school.attributes.areaCode && <span>
                            {", " + Constants.stateNameCode[school.attributes.areaCode]}
                          </span>
                          }
                          {school.attributes.countryCode != "IN" && school.attributes.satate && <span>
                            {", " + school.attributes.satate}
                          </span>
                          }
                          <span>{school.attributes.country && ", " + school.attributes.country}</span>
                        </p>
                        <small className="text-muted mb-4">
                          <span>{"Added On: " + Moment(school.attributes.createdAt).format("DD/MM/YYYY") + " (" + getDays(school.attributes.createdAt) + ")"}</span>
                        </small>
                        <br />
                        {showCategoryButton && <small  >Inventory category:&nbsp;</small>}
                        {showCategoryButton && (school.attributes.inventoryCategories == undefined || school.attributes.inventoryCategories.length == 0) && <small className='text-success' style={{ cursor: 'pointer' }}
                          onClick={(() => {
                            setShowCategoryModal(school)
                            setInventoryCategory(undefined)
                          })}
                        ><u>Set</u></small>}
                        {showCategoryButton && school.attributes.inventoryCategories && <small className='text-success' style={{ cursor: 'pointer' }}
                          onClick={(() => {
                            setShowCategoryModal(school)
                            setInventoryCategory(school.attributes.inventoryCategories[0])
                          })}
                        ><u><strong>{getCategoryName(school.attributes.inventoryCategories[0])}</strong></u></small>}
                        {/* </Media> */}
                      </Col>
                      <Col lg={4}>
                        {getAnalyticsUI(school.id)}
                      </Col>

                      <div>

                      </div>

                    </Row>
                  </Col>

                  { showSubscription ? <Col lg={3}>
                    <Media className="overflow-hidden" body>
                      <p>Subscription info</p>

                      <Editable
                        className={'d-flex'}
                        onValidated={(value) => subscriptionExpiryDateUpdate(school, value)}
                        disabled={false}
                        editText={school.attributes.subscriptionExpiryDate ? " ✎" : "Set Subscription Expiry ✎"}
                        id={null}
                        isValueClickable={false}
                        mode="inline"
                        placement="top"
                        initialValue={school.attributes.subscriptionExpiryDate ? school.attributes.subscriptionExpiryDate : undefined}
                        showText
                        type="date"
                        validate={(value) => {
                          if (!value) {
                            return props.t("You have entered an invalid Date!")
                          }
                        }}

                      />
                      {school.attributes.subscriptionExpiryDate && <p>{"(15 days grace period)"}</p>}
                    </Media>
                  </Col> : <Col lg={3}></Col>}
                  <Col lg={2}>
                    {!props.isSupervisor && <Media className="overflow-hidden" body>
                      <Link
                        to={"/dashboard/" + school.id}
                        target="_blank"
                        className="text-dark"
                      >
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          className="btn-rounded waves-effect waves-light mt-2"
                          onClick={() =>{
                            handleGrantAccess(school)
                          }}
                        
                        >
                          {props.t('Go to branch')}
                        </Button>
                      </Link>
                    </Media>}
                    {showDocumentsButton && <Media className="overflow-hidden" body>
                      <Link
                        to={"/franchise-school-documents/" + Parse.User.current().attributes.franchiseId + "/" + school.id}
                        className="text-dark"
                      >
                        <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light mt-2">{props.t('Details and Documents')}</Button>
                      </Link>
                    </Media>}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))

        :
        <div> <h5 className="text-center text-info mt-4">{error}</h5></div>

      }
      <Modal isOpen={showCategoryModal ? true : false} scrollable={true}>
        <ModalHeader isOpen={showCategoryModal}>
          <div className="modal-title mt-0">Inventory Categories</div>
        </ModalHeader>
        <ModalBody className="modal-body">
          <p>
            {props.t(
              "Please select the Inventory Categories to which school has been taken to particular category, On saving category will moved to selected category"
            )}
          </p>
          {categoriesList &&
            categoriesList.map((item, i) => (
              <div
                className="custom-control custom-checkbox mb-2 ml-3"
                key={item.value}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={item.value}
                  onChange={() => { setInventoryCategory(item.value) }}
                  checked={item.value == inventoryCategory}
                />
                <label
                  className="custom-control-label m-1"
                  onClick={(e) => {
                    setInventoryCategory(item.value);
                  }}
                >
                  {item.label}
                </label>
              </div>
            ))}
          {inventoryCategory && <div className="custom-control custom-checkbox mb-2 ml-3">
            <input
              type="checkbox"
              className="custom-control-input"
              value={"rc"}
              onChange={() => { setInventoryCategory('rc') }}
              checked={inventoryCategory == 'rc'}
            />
            <label
              className="custom-control-label m-1"
              onClick={(e) => {
                setInventoryCategory('rc')
              }}
            >
              Remove category
            </label>
          </div>}
          {categorySelcetWarning && (
            <p className="text-danger ml-2">
              {props.t("Please select the category")}
            </p>
          )}
        </ModalBody>

        <ModalFooter>
          {(
            <div>
              <button
                type="button"
                className="btn btn-secondary m-1"
                onClick={() => {
                  setcategorySelcetWarning(false);
                  // setSelectedKid(undefined);
                  // onSelectClass(undefined);
                  setShowCategoryModal(undefined);
                }}
              >
                {props.t("Close")}
              </button>
              <button
                type="button"
                className="btn btn-primary m-1"
                onClick={() => {
                  if (inventoryCategory) {
                    if (inventoryCategory == 'rc') {
                      handleUnSetCategory(showCategoryModal)
                    } else {
                      handleSaveCategory(showCategoryModal);
                    }

                    setcategorySelcetWarning(false);
                    setShowCategoryModal(undefined);
                  } else {
                    setcategorySelcetWarning(true);
                  }
                }}
              >
                {props.t("Save")}
              </button>
            </div>
          )}
        </ModalFooter>
      </Modal>

    </React.Fragment>
  );
}

const mapStatetoProps = state => {
  const { schools, schoolAnalytics } = state.School;
  const { franchise } = state.Franchise;
  const { inventoryCategories } = state.InventoryCategories;
  return {
    schools, schoolAnalytics,
    franchise,
    inventoryCategories
  };
}
export default withNamespaces()(withRouter(connect(mapStatetoProps, { getSchoolAnalytics, editSchool, getFranchise, getInventoryCategoriesForOwnerId })(SchoolList))); 