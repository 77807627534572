import React, { useState, useEffect } from "react";
import { Table,} from "reactstrap";
import { withNamespaces } from "react-i18next";
import Switch from "react-switch";
import * as Constants from "../../Constents";
import Parse from "parse";
import { getFranchise } from "../../store/actions";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

const TeacherPermissionCount = 12;

const FranchisePermissions = (props) => {
    const [franchiseFeaturesList, setFranchiseFeaturesList] = useState([]);
    const [featureList, setFeatureList] = useState([]);

    const isSuperUser = Parse.User.current() && Parse.User.current().attributes.isSuperUser && props.canEdit;

    useEffect(() => {
        if (props.franchise && props.franchise.attributes.features) {
            let newList = [
                "All Branches",
                "Admins",
                "Messaging",
                "LMS",
                "Story Cards",
                "Forms",
            ]
    
            const features = props.franchise.attributes.features || [];
    
            if (features.length > Constants.FRANCHISE_CORPORATE_PARTNERS_INDEX &&
                features[Constants.FRANCHISE_CORPORATE_PARTNERS_INDEX] > 0 ) {
                newList.push("Corporate Partners");
            }
            if (features.length > Constants.FRANCHISE_TICKETS_INDEX && 
                features[Constants.FRANCHISE_TICKETS_INDEX] > 0 ) {
                newList.push("Service Desk");
            }
            if (features.length > Constants.FRANCHISE_INVENTORY_INDEX && 
                features[Constants.FRANCHISE_INVENTORY_INDEX] > 0 ) {
                newList.push("Inventory Management");
            }
            if (features.length > Constants.FRANCHISE_NEW_BRANCH_ENQUIRY && 
                features[Constants.FRANCHISE_NEW_BRANCH_ENQUIRY] > 0 ) {
                newList.push("New Branch Enquiry");
            }
            if (!newList.includes("Reports")) {
                newList.push("Reports");
            }
    
            if (features.length > Constants.FRANCHISE_CUSTOM_REPORTS_INDEX && 
                features[Constants.FRANCHISE_CUSTOM_REPORTS_INDEX] > 0 ) {
                newList.push("Custom Reports");
            }
    
            setFeatureList(newList);
        }
    }, [props.franchise]);


    useEffect(() => {
        prepareFeatureList();
    }, [featureList]);

    function prepareFeatureList() {
        var franchiseFeaturesList = [];
        for (const i in featureList) {
            var featureObject = null;
            if (featureList[i] === "All Branches") {
                featureObject = checkTeacherForFeature(Constants.FRANCHISE_ADMIN_BRANCH_INDEX);
                featureObject.index = Constants.FRANCHISE_ADMIN_BRANCH_INDEX;
                featureObject.title = props.t('All Branches');
                featureObject.type = 'All Branches';
                franchiseFeaturesList.push(featureObject);
            } else if (featureList[i] === "Admins") {
                featureObject = checkTeacherForFeature(Constants.FRANCHISE_ADMIN_ADMINS_INDEX);
                featureObject.index = Constants.FRANCHISE_ADMIN_ADMINS_INDEX;
                featureObject.title = props.t('Admins');
                featureObject.type = 'Admins';
                franchiseFeaturesList.push(featureObject);
            } else if (featureList[i] === "Messaging") {
                featureObject = checkTeacherForFeature(Constants.FRANCHISE_ADMIN_MESSAGING_INDEX);
                featureObject.index = Constants.FRANCHISE_ADMIN_MESSAGING_INDEX;
                featureObject.title = props.t('Messaging');
                featureObject.type = 'Messaging';
                franchiseFeaturesList.push(featureObject);
            } else if (featureList[i] === "LMS") {
                featureObject = checkTeacherForFeature(Constants.FRANCHISE_ADMIN_LMS_INDEX);
                featureObject.index = Constants.FRANCHISE_ADMIN_LMS_INDEX;
                featureObject.title = props.t('LMS');
                featureObject.type = 'LMS';
                franchiseFeaturesList.push(featureObject);
            } else if (featureList[i] === "Story Cards") {
                featureObject = checkTeacherForFeature(Constants.FRANCHISE_ADMIN_CARDS_INDEX);
                featureObject.index = Constants.FRANCHISE_ADMIN_CARDS_INDEX;
                featureObject.title = props.t('Story Cards');
                featureObject.type = 'Story Cards';
                franchiseFeaturesList.push(featureObject);
            } else if (featureList[i] === "Service Desk") {
                featureObject = checkTeacherForFeature(Constants.FRANCHISE_TICKETS_INDEX);
                featureObject.index = Constants.FRANCHISE_TICKETS_INDEX;
                featureObject.title = props.t('Service Desk');
                featureObject.type = 'Service Desk';
                franchiseFeaturesList.push(featureObject);
            } else if (featureList[i] === "Inventory Management") {
                featureObject = checkTeacherForFeature(Constants.FRANCHISE_INVENTORY_INDEX);
                featureObject.index = Constants.FRANCHISE_INVENTORY_INDEX;
                featureObject.title = props.t('Inventory Management');
                featureObject.type = 'Inventory Management';
                franchiseFeaturesList.push(featureObject);
            }  else if (featureList[i] === "New Branch Enquiry") {
                featureObject = checkTeacherForFeature(Constants.FRANCHISE_NEW_BRANCH_ENQUIRY);
                featureObject.index = Constants.FRANCHISE_NEW_BRANCH_ENQUIRY;
                featureObject.title = props.t('New Branch Enquiry');
                featureObject.type = 'New Branch Enquiry';
                franchiseFeaturesList.push(featureObject);
            } else if (featureList[i] === "Forms") {
                featureObject = checkTeacherForFeature(Constants.FRANCHISE_ADMIN_FORMS_INDEX);
                featureObject.index = Constants.FRANCHISE_ADMIN_FORMS_INDEX;
                featureObject.title = props.t('Forms');
                featureObject.type = 'Forms';
                franchiseFeaturesList.push(featureObject);
            }  else if (featureList[i] === "Reports") {
                featureObject = checkTeacherForFeature(Constants.FRANCHISE_ADMIN_REPORTS_INDEX);
                featureObject.index = Constants.FRANCHISE_ADMIN_REPORTS_INDEX;
                featureObject.title = props.t('Reports');
                featureObject.type = 'Reports';
                franchiseFeaturesList.push(featureObject);
            } else if (featureList[i] === "Custom Reports") {
                featureObject = checkTeacherForFeature(Constants.FRANCHISE_CUSTOM_REPORTS_INDEX);
                featureObject.index = Constants.FRANCHISE_CUSTOM_REPORTS_INDEX;
                featureObject.title = props.t('Custom Reports');
                featureObject.type = 'Custom Reports';
                franchiseFeaturesList.push(featureObject);
            } else if (featureList[i] === "Corporate Partners") {
                featureObject = checkTeacherForFeature(Constants.FRANCHISE_ADMIN_CORPORATE_INDEX);
                featureObject.index = Constants.FRANCHISE_ADMIN_CORPORATE_INDEX;
                featureObject.title = props.t('Corporate Partners');
                featureObject.type = 'Corporate Partners';
                franchiseFeaturesList.push(featureObject);
            }  
        }
        setFranchiseFeaturesList(franchiseFeaturesList);
    }

    function checkTeacherForFeature(teacherIndex) {
        const featureObject = {
            title: "",
            type: "",
            read: false,
            write: false,
            enabled: false,
            schoolIndex: -1,
            teacherIndex,
        };

        if (props.selectedTeacher && props.selectedTeacher.attributes) {
            const teacherPermissions =
                props.selectedTeacher.attributes.franchiseFeaturesList;
            if (
                teacherPermissions &&
                teacherPermissions.length > 0 &&
                teacherIndex >= 0
            ) {
                if (teacherPermissions[teacherIndex] === 0) {
                    featureObject.read = false;
                    featureObject.write = false;
                } else if (teacherPermissions[teacherIndex] === 1) {
                    featureObject.read = true;
                    featureObject.write = false;
                } else if (teacherPermissions[teacherIndex] === 2) {
                    featureObject.read = true;
                    featureObject.write = true;
                }
            }
        }

        return featureObject;
    }

    const Offsymbol = () => (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2,
            }}
        >
            No
        </div>
    );

    const OnSymbol = () => (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2,
            }}
        >
            Yes
        </div>
    );

    const handelWriteSwitch = (item, index) => {
        const list = [...franchiseFeaturesList];
        const tempObject = { ...item, write: !item.write };
        if (tempObject.write) {
            tempObject.read = true;
        }
        list[index] = tempObject;
        setFranchiseFeaturesList(list);
        prepareList();
    };

    function prepareList() {
        const teacherList = [...franchiseFeaturesList];
        const newFeatureList = [];

        for (let i = 0; i < TeacherPermissionCount; i++) {
            const current = teacherList.filter((item) => item.index === i);
            if (current.length > 0) {
                if (current[0].write) {
                    newFeatureList.push(2);
                }
                // else if (current[0].read) {
                //     newFeatureList.push(1);
                // } 
                else {
                    newFeatureList.push(0);
                }
            } else {
                newFeatureList.push(0);
            }
        }

        props.onChangeFeatureList(newFeatureList);
    }

    return (
        <React.Fragment>
            <Table className="table-centered table-nowrap table-hover">
                <thead className="thead-light">
                    <tr>
                        <th scope="col" style={{ width: "70px" }}>#</th>
                        <th scope="col" style={{ width: "450px" }}>{props.t("Feature Name")}</th>
                        <th scope="col">{props.t("Status")}</th>
                    </tr>
                </thead>
                <tbody>
                    {franchiseFeaturesList.map((item, i) => (
                        <tr key={`_user_${i}`}>
                            <td>{i + 1}</td>
                            <td>{item.title}</td>
                            <td>
                                <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() => handelWriteSwitch(item, i)}
                                    checked={item.write || false}
                                    disabled={isSuperUser}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </React.Fragment>
    );
};

const mapStatetoProps = state => {
    const { franchise } = state.Franchise;
    return { franchise};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, { getFranchise, })(FranchisePermissions)));

