import Parse from "parse";
import { printLog } from "./loghelper";
export const getParentFromParse = (parentId) => {
  printLog("HL: parent_helper : getParentFromParse")

  try {
    return new Promise((resolve, reject) => {
      var Parent = Parse.Object.extend("Parent");
      var query = new Parse.Query(Parent);
      query.get(parentId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

export const getParentsForIds = (parentIds, updatedDate, isLoacal) => {
  printLog("HL: parent_helper : getParentsForIds")
  try {
    return new Promise((resolve, reject) => {
      var parentQueryObj = Parse.Object.extend("Parent");
      var mainQuery = new Parse.Query(parentQueryObj);
      mainQuery.containedIn("objectId", parentIds);

      if (updatedDate) {
        mainQuery.greaterThanOrEqualTo("updatedAt", updatedDate);
      }

      if (isLoacal) {
        mainQuery.fromLocalDatastore();
      }
      mainQuery.limit(parentIds.length)
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const getParentsForFamilyId = (familyId) => {
  printLog("HL: parent_helper : getParentsForFamilyId")
  try {
    return new Promise((resolve, reject) => {
      var parentQueryObj = Parse.Object.extend("Parent");
      var mainQuery = new Parse.Query(parentQueryObj);
      mainQuery.equalTo("FamilyID", familyId);
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};
export const getParentsForFamilyIds = (familyIds,isLocal ) => {
  printLog("HL: parent_helper : getParentsForFamilyIds")

  try {
    return new Promise((resolve, reject) => {
      var parentQueryObj = Parse.Object.extend("Parent");
      var mainQuery = new Parse.Query(parentQueryObj);
      mainQuery.containedIn("FamilyID", familyIds);
      mainQuery.limit(5000);
      if (isLocal) {
        mainQuery.fromLocalDatastore()
      }
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const updateParentToParse = (parentObj) => {
  printLog("HL: parent_helper : updateParentToParse")
  try {
    return new Promise((resolve, reject) => {
      parentObj.save().then((parent) => {
        resolve(parent);
      },(error) => {
        console.log(error)
        reject(null);
      });
    });
  } catch (error) {
    console.log(error)
  }
};

export const deleteParentFromParse = (parentId) => {
  printLog("HL: parent_helper : deleteParentFromParse")
  try {
    return new Promise((resolve, reject) => {
      const Parent = Parse.Object.extend("Parent");
      var parent = new Parent();
      parent.id = parentId;
      parent.set("isDeleted", true);
      // if (Parse.User.current().attributes.teacherId) {
      //   parent.set("deletedBy", Parse.User.current().attributes.teacherId);
      // } else {
      //   parent.set("deletedBy", Parse.User.current().id);
      // }

      parent.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

export const saveParentObjects = (objects) => {
  printLog("HL: parent_helper : saveParentObjects")
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(objects).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
          console.log("Error", error);
        }
      );
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export const addParentsToParse = (parents) => {
  printLog("HL: parent_helper : addParentsToParse")
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(parents).then((results) => {
        resolve(results);
      });
    });
  } catch (error) {}
};

