import React, { useEffect, useState } from 'react';
import { Container, FormGroup, Label, Button, Row, Col, Card, CardBody,Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Badge } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool, getLeaveModuleForOwnerId, getTeachersForSchool, editLeaveDetails } from '../../store/actions';
import { matchPath } from "react-router";
import Moment from 'moment';
import DatePicker from "react-datepicker";
import Parse from 'parse';
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from 'toastr';

const LeavesList = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/leaves/:id",
        exact: true,
        strict: false,
    });

    const match2 = matchPath(props.history.location.pathname, {
        path: "/franchise-all-leaves/:franchiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.id;
    const franchiseId = match2 && match2.params.franchiseId;
    
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedLeave, setSelectedLeave] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterType, setFilterType] = useState('');
    const [filteredLeaves, setFilteredLeaves] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [selectedStatusFilters, setSelectedStatusFilters] = useState([]);
    const [isUploadImage, setIsUploadImage] = useState(false);
    const [isLop, setIsLop] = useState(false);
    const [leaveModes, setLeaveModes] = useState('full day');
    const [halfDayOption, setHalfDayOption] = useState('first half'); 
    const [startDayOption, setStartDayOption] = useState('full day'); 
    const [endDayOption, setEndDayOption] = useState('full day'); 
    const [showAlert, setShowAlert] = useState(false);
    const [newStatus, setNewStatus] = useState(null);
    const [confirming, setConfirming] = useState(false);
    
    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool,schoolId]);

    useEffect(() => {
        props.getLeaveModuleForOwnerId({ ownerType: "School", ownerId: schoolId });
        props.getTeachersForSchool(schoolId);
    }, [schoolId]);

    
    const getLeaveMode = (leaveMode) => {
        if (leaveMode == 1) {
            return "Half Day(1 st half) ";
        } else if (leaveMode == 2) {
            return "Half Day(2 nd half) ";
        } else if (leaveMode == 3) {
            return "Full day";
        } else if (leaveMode > 3) {
            return "Multiple Days";
        }  
    }

    const getStatus = (status) => {
        if (status == 0) {
            return "Requested";
        } else if (status == 1) {
            return "Approved";
        } else if (status == 2) {
            return "Pending";
        } else if (status == 3) {
            return "Rejected";
        }
    };

    const getStatusColor = (status) => {

        if (status == 0) {
          return "primary";
        } else if (status == 1) {
          return "success ";
        } else if (status == 2) {
          return "warning";
        } else if (status == 3) {
          return "danger";
        }
    }

    const filterList =  [
        { value: 'all', label: 'All'},
        { value: 'today', label: 'Today'},
        { value: 'week', label: '1 Week'},
        { value: 'month', label: '1 Month'},
        { value: 'custom', label: 'Custom'},
        { value: 'requested', label: 'Requested'},
        { value: 'approved', label: 'Approved'},
        { value: 'pending', label: 'Pending'},
        { value: 'rejected', label: 'Rejected'},
    ];

    
    const handleFilterClick = (type) => {
        setFilterType(type);
        if (type !== 'custom') {
            setStartDate(null);
            setEndDate(null);
        }
    };

    const handleStatusFilterClick = (status) => {
        setSelectedStatusFilters(prev => {
            if (prev.includes(status)) {
                return prev.filter(s => s !== status); 
            } else {
                return [...prev, status];
            }
        });
    };
    
    const filterLeaves = () => {
        let leavesToFilter = props.leavesApplied;
        const currentYear = Moment().year();
        leavesToFilter = leavesToFilter.filter(leave => 
            Moment(leave.attributes.createdAt).year() === currentYear
        );
    
        if (filterType === 'today') {
            const today = Moment().startOf('day');
            leavesToFilter = leavesToFilter.filter(leave => Moment(leave.attributes.createdAt).isSame(today, 'day'));
        } else if (filterType === 'week') {
            const weekStart = Moment().subtract(7, 'days').startOf('day');
            const weekEnd = Moment().endOf('day');
            leavesToFilter = leavesToFilter.filter(leave => Moment(leave.attributes.createdAt).isBetween(weekStart, weekEnd, null, '[]'));
        } else if (filterType === 'month') {
            const monthStart = Moment().subtract(30, 'days').startOf('day');
            const monthEnd = Moment().endOf('day');
            leavesToFilter = leavesToFilter.filter(leave =>
                Moment(leave.attributes.createdAt).isBetween(monthStart, monthEnd, null, '[]')
            );
        } else if (filterType === 'custom') {
            if (startDate && endDate) {
                leavesToFilter = leavesToFilter.filter(leave => 
                    Moment(leave.attributes.startDate).isBefore(endDate, 'day') &&
                    Moment(leave.attributes.endDate).isAfter(startDate, 'day')
                );
            }
        }
    
        if (selectedStatusFilters.length > 0) {
            leavesToFilter = leavesToFilter.filter(leave => selectedStatusFilters.includes(leave.attributes.status));
        }
    
        return leavesToFilter;
    };

    const getTimeStringForDate = (date) => {
        return Moment(date).format('DD-MMM-YYYY');
    };

    const getTeacherName = (teacherId) => {
        const teacher = props.teachers.find(teacher => teacher.id === teacherId);
        return teacher ? teacher.attributes.Name : "";
    };

    const handleViewDetails = (leave) => {
        setSelectedLeave(leave);
        setModalOpen(true);
        setCurrentStatus(leave.attributes.status);
        setIsUploadImage(leave.attributes.uploadImage);
        setIsLop(leave.attributes.lop)
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedLeave(null);

    };

    useEffect(() => {
        if (selectedLeave) {
            const leave = props.leavesApplied.find(leave => leave.id === selectedLeave.id);
            if (leave) {
                setCurrentStatus(leave.attributes.status);
            }
        }
    }, [props.leavesApplied, selectedLeave]);
    
    const handleStatusChange = (status) => {
        if (confirming) return;
        if (selectedLeave) {
            let confirmationMessage = "";
            if (status === 1) confirmationMessage = "Are you sure you want to change the leave to approved?";
            else if (status === 2) confirmationMessage = "Are you sure you want to change the leave to pending?";
            else if (status === 3) confirmationMessage = "Are you sure you want to change the leave to rejected?";
    
            setNewStatus(status);
            setShowAlert(confirmationMessage);
        }
    };
    
    const confirmStatusChange = (newStatus) => {
        if (selectedLeave) {
            const leaveToEdit = props.leavesApplied.find(leave => leave.id === selectedLeave.id);
            if (leaveToEdit) {
                let oldStatus= leaveToEdit.attributes.status;
                leaveToEdit.set('status', newStatus);
                if (newStatus === 1) { 
                    leaveToEdit.set("approvedBy", props.userTeacher.id);
                    leaveToEdit.set("approvedOn", new Date());
                } else if (newStatus === 3) { 
                    leaveToEdit.set("rejectedBy", props.userTeacher.id);
                }
                props.editLeaveDetails(leaveToEdit);
                updateLeaveLog(leaveToEdit.id,oldStatus,newStatus);
                setCurrentStatus(newStatus);
            }
        }
        setShowAlert(false);
        setConfirming(false); 
    };

    const updateLeaveLog=(leaveId,from,to,)=>{
        if(from!=to){
            const LeaveLog = Parse.Object.extend("LeaveLog");
            const leaveLog = new LeaveLog();
            leaveLog.set("createdBy", props.userTeacher.id);
            leaveLog.set("leaveId",leaveId)
            leaveLog.set("fromState",from)
            leaveLog.set("toState",to)
            leaveLog.set("operationNote",getStatus(from)+" to "+getStatus(to))
            leaveLog.save();
        }
    }

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        setIsUploadImage(checked);
    
        if (selectedLeave) {
            const leaveToEdit = props.leavesApplied.find(leave => leave.id === selectedLeave.id);
            if (leaveToEdit) {
                leaveToEdit.set('uploadImage', checked);
                props.editLeaveDetails(leaveToEdit);
            }
        }
    };

    const handleLOPCheckboxChange = (event) => {
        if (selectedLeave && selectedLeave.attributes.leavePolicyItemId) {
            const checked = event.target.checked;
            setIsLop(checked);
            const leaveToEdit = props.leavesApplied.find(leave => leave.id === selectedLeave.id);
            if (leaveToEdit) {
                leaveToEdit.set('lop', checked);
                props.editLeaveDetails(leaveToEdit);
                const leaveId = selectedLeave.id;  

                const LeaveLog = Parse.Object.extend("LeaveLog");
                const leaveLog = new LeaveLog();
                leaveLog.set("createdBy", props.userTeacher.id);
                leaveLog.set("leaveId",leaveId)
                
                leaveLog.set("operationNote",checked? "Marked as LOP": "Unmarked as LOP")
                leaveLog.save()
                    .then(() => {
                        toastr.success("Successfully Updated");
                    })
                    .catch(error => {
                        toastr.error("Failed to update. Please try again.");
                        console.error("Error saving leave log:", error);
                    });
            }
        }
    };

    const getLeaveModeLabel = (type) => {
        if (leaveModes === 'half day') {
            return halfDayOption === 'first half' ? 'First Half' : 'Second Half';
        } else if (leaveModes === 'full day') {
            return 'Full Day';
        } else if (leaveModes === 'multiple days') {
            if (type === 'start') {
                return startDayOption === 'full day' ? 'Full Day' : 'Second Half';
            } else if (type === 'end') {
                return endDayOption === 'full day' ? 'Full Day' : 'First Half';
            }
        }
        return '';
    };
    
    const searchLeaves = () => {
        const query = searchString.toLowerCase();
        return filterLeaves().filter((leave) => {
            const teacherName = getTeacherName(leave.attributes.userId).toLowerCase();
            return teacherName.includes(query);
        });
    };

    useEffect(() => {
        const leaves = filterLeaves();
        if (leaves.length > 0) {
            const today = Moment().startOf('day');
            const todayLeaves = leaves.filter(leave => Moment(leave.attributes.createdAt).isSame(today, 'day'));
            if (todayLeaves.length > 0) {
                setFilterType('today');
            } else {
                const weekStart = Moment().subtract(7, 'days').startOf('day');
                const weekLeaves = leaves.filter(leave => Moment(leave.attributes.createdAt).isBetween(weekStart, today, null, '[]'));
                if (weekLeaves.length > 0) {
                    setFilterType('week');
                } else {
                    setFilterType('month');
                }
            }
        }
    }, [props.leavesApplied]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex justify-content-between align-items-start">
                    <Col lg={6} className="d-flex align-items-start">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { window.history.back() }}>
                            <ul className="list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className='m-2'>
                            <h4 className="font-size-18">Applied Leaves</h4>
                        </div>
                    </Col>
                    <Col lg={4} className='d-flex justify-content-end align-items-center'>
                        <div className="search-box flex-grow-0">
                            <div className="position-relative">
                                <Input
                                    type="text"
                                    className="form-control border-0"
                                    placeholder="Search..."
                                    value={searchString}
                                    onChange={(e) => {
                                        setSearchString(e.target.value);
                                    }}
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <Link to={"/apply-leave/" + schoolId}>
                                <button className="btn btn-primary">Apply Leave</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row className='justify-content-center gx-2'>
                            <Col lg='1'>
                                <p onClick={() => handleFilterClick('today')}
                                    className={`cursor-pointer ${filterType === 'today' ? 'text-primary font-weight-bold' : 'text-dark'}`}>
                                    Today
                                </p>
                                {filterType === 'today' && <div className="border-bottom border-primary" style={{ width: '80%', }} />}
                            </Col>
                            <Col lg='1'>
                                <p onClick={() => handleFilterClick('week')}
                                    className={`cursor-pointer ${filterType === 'week' ? 'text-primary font-weight-bold' : 'text-dark'}`}>
                                    1 Week
                                </p>
                                {filterType === 'week' && <div className="border-bottom border-primary" style={{ width: '80%',}} />}
                            </Col>
                            <Col lg='1'>
                                <p onClick={() => handleFilterClick('month')}
                                    className={`cursor-pointer ${filterType === 'month' ? 'text-primary font-weight-bold' : 'text-dark'}`}>
                                    Month
                                </p>
                                {filterType === 'month' && <div className="border-bottom border-primary" style={{ width: '80%', }} />}
                            </Col>
                            <Col lg='1'>
                                <p onClick={() => handleFilterClick('custom')}
                                    className={`cursor-pointer ${filterType === 'custom' ? 'text-primary font-weight-bold' : 'text-dark'}`}>
                                    Custom
                                </p>
                                {filterType === 'custom' && <div className="border-bottom border-primary" style={{ width: '100%',}} />}
                            </Col>
                            <Col lg='1'>
                                <p onClick={() => handleStatusFilterClick(0)}
                                    className={`cursor-pointer ${selectedStatusFilters.includes(0) ? 'text-white font-weight-bold' : 'text-white'}`}>
                                    <Badge className={`bg-${getStatusColor(0)} font-size-12 `}>Requested</Badge>
                                </p>
                                {selectedStatusFilters.includes(0) && <div className="border-bottom border-primary" style={{ width: '100%', }} />}
                            </Col>
                            <Col lg='1' >
                                <p onClick={() => handleStatusFilterClick(1)}
                                    className={`cursor-pointer ${selectedStatusFilters.includes(1) ? 'text-primary font-weight-bold' : 'text-dark'}`}>
                                    <Badge className={`bg-${getStatusColor(1)} font-size-12`}>Approved</Badge>
                                </p>
                                {selectedStatusFilters.includes(1) && <div className="border-bottom border-primary" style={{ width: '100%', }} />}
                            </Col>
                            <Col lg='1'>
                                <p onClick={() => handleStatusFilterClick(2)}
                                    className={`cursor-pointer ${selectedStatusFilters.includes(2) ? 'text-primary font-weight-bold' : 'text-dark'}`}>
                                    <Badge className={`bg-${getStatusColor(2)} font-size-12`}>Pending</Badge>
                                </p>
                                {selectedStatusFilters.includes(2) && <div className="border-bottom border-primary" style={{ width: '100%', }} />}
                            </Col>
                            <Col lg='1'>
                                <p onClick={() => handleStatusFilterClick(3)}
                                    className={`cursor-pointer ${selectedStatusFilters.includes(3) ? 'text-primary font-weight-bold' : 'text-dark'}`}>
                                    <Badge className={`bg-${getStatusColor(3)} font-size-12`}>Rejected</Badge>
                                </p>
                                {selectedStatusFilters.includes(3) && <div className="border-bottom border-primary" style={{ width: '100%',  }} />}
                            </Col>
                            <hr />
                        </Row>
                        {filterType === 'custom' && (
                            <Row className='d-flex justify-content-end'>
                                <Col lg='2'>
                                    <FormGroup>
                                        <Label for="startDate">Start Date</Label>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={date => setStartDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg='2'>
                                    <FormGroup>
                                        <Label for="endDate">End Date</Label>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={date => setEndDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col className="table-responsive">
                                <Table className="table table-nowrap m-0">
                                    {searchLeaves().length > 0 && (
                                        <thead>
                                            <tr>
                                                <th>{props.t("#")}</th>
                                                <th>{props.t("Created At")}</th>
                                                <th>{props.t("Applied By")}</th>
                                                <th>{props.t("Leave Date")}</th>
                                                <th>{props.t("Duration")}</th>
                                                <th>{props.t("Leave Type")}</th>
                                                <th>{props.t("Status")}</th>
                                                <th>{props.t("View Details")}</th>
                                            </tr>
                                        </thead>
                                    )}
                                    <tbody>
                                        {searchLeaves().length === 0 ? (
                                            <tr>
                                                <td className="text-center text-info"><h4>No Leaves Found</h4></td>
                                            </tr>
                                        ) : (
                                            searchLeaves().map((leave, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{getTimeStringForDate(leave.attributes.createdAt)}</td>
                                                    <td>{getTeacherName(leave.attributes.userId)}</td>
                                                    <td>{getTimeStringForDate(leave.attributes.startDate)} - {getTimeStringForDate(leave.attributes.endDate)}</td>
                                                    <td>{leave.attributes.leavePeriod} days</td>
                                                    <td>{leave.attributes.lop ? "LOP": leave.attributes.policyItemTitle }
                                                    
                                                    </td>
                                                    <td>
                                                        <Badge 
                                                            onClick={() => handleViewDetails(leave)}
                                                            className={"font-size-12 badge-soft-" + getStatusColor(leave.attributes.status)} pill>
                                                        {getStatus(leave.attributes.status)}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className='btn-rounded waves-effect waves-light'
                                                            onClick={() => handleViewDetails(leave)}>
                                                            View Details
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
            <Modal isOpen={modalOpen} toggle={handleCloseModal}>
                    <ModalHeader>Leave Details</ModalHeader>
                    <ModalBody>
                        {selectedLeave ? (
                            <div>

                            <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th scope="row">Applied on</th>
                                        <td>{Moment(selectedLeave.attributes.createdAt).format('DD-MMM-YYYY hh:mm a')}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Reason</th>
                                        <td>{selectedLeave.attributes.reason}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Status</th>
                                        <td>
                                            <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                                                <DropdownToggle className="bg-white border-0" caret>
                                                    <Badge className={"font-size-12 badge-soft-" + getStatusColor(currentStatus)} pill>
                                                        {getStatus(currentStatus)} <i className="mdi mdi-chevron-down"></i>
                                                    </Badge>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {[1, 2, 3].map(status => (
                                                        <DropdownItem key={status} onClick={() => handleStatusChange(status)}>
                                                            {getStatus(status)}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </Dropdown>
                                            {currentStatus === 1 && <p className='m-0'><strong>Approved By</strong> : {props.userTeacher.attributes.Name} </p>}
                                            {currentStatus === 1 && <p className='mb-1'><strong>Approved On</strong> : {getTimeStringForDate(selectedLeave.attributes.approvedOn)} </p>}
                                            {currentStatus === 3 && <p className='m-0'>Rejected By : {props.userTeacher.attributes.Name} </p>}
                                            {currentStatus === 1 && (
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={isUploadImage}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <span style={{ marginLeft: '10px' }}>
                                                            Ask employee to upload reference doc/image
                                                        </span>
                                                    </div>

                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={isLop}
                                                            onChange={handleLOPCheckboxChange}
                                                            disabled={!selectedLeave.attributes.leavePolicyItemId}
                                                        />
                                                        <span style={{ marginLeft: '10px' }}>
                                                            {selectedLeave.attributes.leavePolicyItemId ? "Mark Leave as LOP" : "Leave applied as LOP"}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}

                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Leave Mode</th>
                                        <td>{getLeaveMode(selectedLeave.attributes.leaveMode)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Dates</th>
                                        <td>{getTimeStringForDate(selectedLeave.attributes.startDate)}({getLeaveModeLabel('start')}) - {getTimeStringForDate(selectedLeave.attributes.endDate)}({getLeaveModeLabel('start')})</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        ) : (
                            <p>Leave details not found.</p>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleCloseModal}>Close</Button>
                    </ModalFooter>
                </Modal>
                {showAlert && !confirming && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes"
                        cancelBtnText="No"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        title={showAlert}
                        onConfirm={() => confirmStatusChange(newStatus)}
                        onCancel={() => setShowAlert(false)}
                    />
                )}

        </React.Fragment>
    );
};

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { leavesApplied } = state.LeaveModule;
    const { teachers } = state.Teacher;
    const { userTeacher } = state.Login;
    return { selectedSchool, leavesApplied, teachers, userTeacher};
};

export default connect(mapStatetoProps, {
    setSelecetdSchool, getLeaveModuleForOwnerId, getTeachersForSchool, editLeaveDetails
})(withRouter(withNamespaces()(LeavesList)));

