import React, { useEffect, useState } from 'react';
import { Card, CardBody, FormGroup, Row, Col, Button, Label, Media } from "reactstrap";
import { connect } from "react-redux";
import { showRightSidebarAction } from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import "./rightbar.scss";
import * as WhiteLabel from "../../components/Common/WhiteLable";
import QRCode from 'qrcode'
import EnquiryApp from '../../assets/images/Enquiry App.webp';
import LilTripsApp from '../../assets/images/lilTrips.webp'
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const RightSidebar = (props) => {

  const [formlilTriangleQRCode, setFormlilTriangleQRCode] = useState(undefined);
  const [formlilTripsQRCode, setFormlilTripsQRCode] = useState(undefined);
  const [formEnquiryQRCode, setFormEnquiryQRCode] = useState(undefined);
  const [showAppQrCode, setShowAppQrCode] = useState(false);
  const [showEnquiryQrCode, setShowEnquiryQrCode] = useState(false);
  const [showTripsQrCode, setShowTripsQrCode] = useState(false);

  function formlilTriangleQr() {
    QRCode.toDataURL(WhiteLabel.getAppOneLink(), { errorCorrectionLevel: 'H' })
      .then(url => {
        //console.log(url)
        setFormlilTriangleQRCode(url)
      })
      .catch(err => {
        console.error(err)
      })
  }

  function formlilTripsQr() {
    QRCode.toDataURL("https://onelink.to/qweb3c", { errorCorrectionLevel: 'H' })
      .then(url => {
        setFormlilTripsQRCode(url)
      })
      .catch(err => {
        console.error(err)
      })
  }

  function formEnquiryQr() {
    QRCode.toDataURL("https://onelink.to/4dhqeq", { errorCorrectionLevel: 'H' })
      .then(url => {
        setFormEnquiryQRCode(url)
      })
      .catch(err => {
        console.error(err)
      })
  }

  function lilTriangleCopyToClipboard() {
    formlilTriangleQr()
    setShowAppQrCode(!showAppQrCode);
    navigator.clipboard.writeText("Namaste.! Please download "+WhiteLabel.getAppName()+" app by clicking this link " + WhiteLabel.getAppOneLink() + " to get all the updates from" + props.selectedSchool.attributes.Name + "Please use this phone number to register.");
    if (!showAppQrCode) {
      toastr.options.positionClass = "toast-top-center";
      toastr.success("Link copied to clipboard", { timeOut: 1000 });
    }
  }

  function lilTripsCopyToClipboard() {
    formlilTripsQr()
    setShowTripsQrCode(!showTripsQrCode);
    navigator.clipboard.writeText("Namaste.!Please download lilTrips App by clicking this link https://onelink.to/qweb3c");
    if (!showTripsQrCode) {
      toastr.options.positionClass = "toast-top-center";
      toastr.success("Link copied to clipboard", { timeOut: 1000 });
    }
  }

  function EnquiryCopyToClipboard() {
    formEnquiryQr()
    setShowEnquiryQrCode(!showEnquiryQrCode);
    navigator.clipboard.writeText("Namaste.!Please download Enquiry App by clicking this link https://onelink.to/4dhqeq to maanage your School Enquiries ");
    if (!showEnquiryQrCode) {
      toastr.options.positionClass = "toast-top-center";
      toastr.success("Link copied to clipboard", { timeOut: 1000 });
    }
  }

  return (
    <React.Fragment>
      <div className="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div >
            <div className="rightbar-title px-3 py-4">
              <Row>
                <Col className='d-flex justify-content-between'>
                  <h5 className="m-0">{WhiteLabel.getAppName()}</h5>
                  <Link to="#" onClick={(e) => { e.preventDefault(); props.showRightSidebarAction(false); }} className="right-bar-toggle float-right">
                    <i className="mdi mdi-close noti-icon"></i>
                  </Link>
                </Col>
              </Row>
            </div>
            <hr className="my-0" />
            <div className="p-4">
              <div className="radio-toolbar">
                <Card>
                  <CardBody>
                    <Media>
                      <Row>
                        <Col className='d-flex justify-content-start'>
                          <div className="avatar-md mr-4 text-center">
                            <span className={`avatar-title  ${(WhiteLabel.isSanfortApp || WhiteLabel.isJumpStartApp) ? "bg-white" : "bg-light"} text-primary font-size-16`} style={{ border: (WhiteLabel.isSanfortApp || WhiteLabel.isJumpStartApp) ? "none" : "1px solid lightGray", borderRadius: "10px", padding: "10px" }}>
                              <img src={WhiteLabel.getAppLogo()} height="60px" style={{ borderRadius: "10px", padding: "10px" }} alt="App Logo"></img>
                            </span>
                          </div>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                          <Media className="overflow-hidden" body>
                            <h5 className="text-truncate font-size-12">{WhiteLabel.getAppName()} App</h5>
                            <button className='btn btn-outline-primary' style={{ cursor: "pointer", display: 'flex', alignItems: 'center' }} onClick={() => {
                              lilTriangleCopyToClipboard()
                            }}>
                              <i className="bx bx-upload bx-fw "></i>{showAppQrCode ? 'Close' : 'Share'}
                            </button>
                          </Media>
                        </Col>
                      </Row>
                    </Media>
                    {showAppQrCode && <Row>
                      <Col xs={12} className="d-flex justify-content-center mt-3">
                        <img className="img-responsive" src={formlilTriangleQRCode} alt="QR Code" onClick={() => { lilTriangleCopyToClipboard() }} style={{ maxWidth: "80%" }} />
                      </Col>
                    </Row>}
                    {showAppQrCode && <Row><small className='text-center text-muted'>Use your mobile camera or Google Lens to scan</small></Row>}

                    <Link
                      className="btn btn-primary float-right end m-1"
                      target="_blank" rel="noopener noreferrer"
                      to={"/app-poster/" + props.selectedSchool.id}>
                      Download App Poster
                    </Link>
                  </CardBody>
                </Card>
              </div>
              <hr className="mt-1" />
             {(WhiteLabel.getAppName() == "lilTriangle") && (<div className="radio-toolbar">
                <Card>
                  <CardBody>
                    <Media>
                      <Row>
                        <Col className='justify-content-start'>
                          <div className="avatar-md mr-4 text-center">
                            <span className="avatar-title  bg-light text-primary font-size-16" style={{ border: "1px solid lightGray", borderRadius: "10px", padding: "10px" }}>
                              <img src={EnquiryApp} height="80px" style={{ borderRadius: "10px", padding: "10px" }} alt="App Logo"></img>
                            </span>
                          </div>
                        </Col>
                        <Col>
                          <Media className="overflow-hidden" body>
                            <h5 className="text-truncate font-size-12">Enquiry App</h5>
                            <button className='btn btn-outline-primary' style={{ cursor: "pointer", display: 'flex', alignItems: 'center' }} onClick={() => { EnquiryCopyToClipboard(); }}>
                              <i className="bx bx-upload bx-fw mr-2"></i>
                              {showEnquiryQrCode ? 'Close' : 'Share'}
                            </button>

                          </Media>
                        </Col>
                      </Row>
                    </Media>
                    {showEnquiryQrCode && <Row>
                      <Col xs={12} className="d-flex justify-content-center mt-3">
                        <img className="img-responsive" src={formEnquiryQRCode} onClick={() => { EnquiryCopyToClipboard() }} alt="QR Code" style={{ maxWidth: "80%" }} />
                      </Col>
                    </Row>}
                    {showEnquiryQrCode && <Row><small className='text-center text-muted'>Use your mobile camera or Google Lens to scan</small></Row>}
                  </CardBody>
                </Card>
              </div>)}
              {(WhiteLabel.getAppName() == "lilTriangle") && (<hr className="mt-1" />)}
              {(WhiteLabel.getAppName() == "lilTriangle") && (<div className="radio-toolbar">
                <Card>
                  <CardBody>
                    <Media>
                      <Row>
                        <Col className='justify-content-start'>
                          <div className="avatar-md mr-4 text-center">
                            <span className="avatar-title  bg-light text-primary font-size-16" style={{ border: "1px solid lightGray", borderRadius: "10px", padding: "10px" }}>
                              <img src={LilTripsApp} height="60px" style={{ borderRadius: "10px", padding: "10px" }} alt="App Logo"></img>
                            </span>
                          </div>
                        </Col>
                        <Col>
                          <Media className="overflow-hidden" body>
                            <h5 className="text-truncate font-size-12">lilTrips</h5>
                            <button className='btn btn-outline-primary' style={{ cursor: "pointer", display: 'flex', alignItems: 'center' }} onClick={() => {

                              lilTripsCopyToClipboard()
                            }}>
                              <i className="bx bx-upload bx-fw mr-2"></i>{showTripsQrCode ? 'Close' : 'Share'}
                            </button>
                          </Media>
                        </Col>
                      </Row>
                    </Media>
                    {showTripsQrCode && <Row>
                      <Col xs={12} className="d-flex justify-content-center mt-3">
                        <img className="img-responsive" src={formlilTripsQRCode} alt="QR Code" onClick={() => { lilTripsCopyToClipboard() }} style={{ maxWidth: "80%" }} />
                      </Col>
                    </Row>}
                    {showTripsQrCode && <Row><small className='text-center text-muted'>Use your mobile camera or Google Lens to scan</small></Row>}
                  </CardBody>
                </Card>
              </div>)}
              {(WhiteLabel.getAppName() == "lilTriangle") && (<hr className="mt-1" />)}
              {WhiteLabel.getAppName()=="lilTriangle"&&<div className="radio-toolbar">
                <Col >
                  <div className="text-center">
                    <h6 className="mb-0 mt-0">Follow us </h6>
                    <a href=" https://www.linkedin.com/company/lil-triangle/" target="_blank"><i className="mdi mdi-linkedin m-1 font-size-24"></i></a>
                    <a href=" https://www.facebook.com/lilTriangle/" target="_blank"><i className="mdi mdi-facebook m-1 font-size-24"></i></a>
                    <a href=" https://www.instagram.com/liltriangleapp/" target="_blank"><i className="mdi mdi-instagram m-1 font-size-24"></i></a>
                    <a href=" https://mobile.twitter.com/triangle_lil" target="_blank"><i className="mdi mdi-twitter m-1 font-size-24"></i></a>
                  </div>
                </Col>

              </div>}

              <hr className="mt-1" />
              <div style={{ height: "300px" }}></div>
            </div>
          </div>

        </SimpleBar>
      </div>
      <div className="rightbar-overlay"></div>
    </React.Fragment>
  );
}

const mapStatetoProps = state => {
  const { selectedSchool } = state.School;
  return { selectedSchool, ...state.Layout };

};

export default connect(mapStatetoProps, {
  showRightSidebarAction
})(RightSidebar);

