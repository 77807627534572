import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip, Input, Dropdown, Table, DropdownItem, Form, Container, Label, Card, CardBody, FormGroup } from "reactstrap";
import * as WhiteLable from "../../components/Common/WhiteLable";
import Parse from 'parse';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Moment from "moment";

const feedbackFormPopup = (props) => {

    const [loading, setLoading] = useState(1)
    const [feedback, setFeedBack] = useState()


    useEffect(() => {

        if (props.kidObject.attributes.exitFeedbackSubmittedOn) {
            let query = new Parse.Query("KidExitFeedback");

            query.equalTo('kidId', props.kidObject.id);

            query.first()
                .then((result) => {
                    // console.log(result);
                    if (result) {
                        setFeedBack(result);
                        setLoading(0)
                    } else {
                        setLoading(-1)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            setLoading(2);
        }

    }, [props.kidObject]);


    const getFeedbackShareLink = () => {
        if (props.kidObject) {
            return (
                WhiteLable.getHost() + "/exit-feedback-form/" + props.kidObject.id
            )
        }
    }

    function copyFeedbackShareLink(str, event) {
        document.oncopy = function (event) {
            event.clipboardData.setData("Text", str);
            event.preventDefault();
        };
        document.execCommand("Copy");
        document.oncopy = undefined;
        toastr.success("Link copied to clipboard", { timeOut: 1000 });
    }


    return (
        <React.Fragment>
            <div>
                <Modal isOpen={props.feedbackFormPopup}>
                    <ModalHeader
                        toggle={() => {
                            props.closeFeedbackFormPopup()
                        }}
                    >
                        Exit Feedback Form
                    </ModalHeader>
                    <ModalBody>
                        {(loading == 0 || loading == 2) && <div>
                            {props.kidObject && props.kidObject.attributes.exitFeedbackSubmittedOn ? (

                                <div style={{ position: 'relative', zIndex: 1 }}>
                                    <Row>
                                        <Table className=" table mb-0 table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th scope="row" style={{ width: "200px" }}>Name</th>
                                                    <td>{props.kidObject && props.kidObject.attributes.Name}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{ width: "200px" }}>Exit Reason</th>
                                                    <td>{feedback && feedback.attributes.exitReason}</td>
                                                </tr>
                                                {/* <tr>
                                                <th scope="row" style={{ width: "200px" }}>Rating 10 Out Of</th>
                                                <td>{feedback && feedback.attributes.rating}</td>
                                            </tr> */}
                                                <tr>
                                                    <th scope="row" style={{ width: "200px" }}>Recommend</th>
                                                    <td>{feedback && feedback.attributes.recommend == true ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{ width: "200px" }}>Service Satisfaction</th>
                                                    <td>{feedback && feedback.attributes.rating + "/10"}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{ width: "200px" }}>Feedback</th>
                                                    <td>{feedback && feedback.attributes.feedback}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{ width: "200px" }}>Submitted On</th>
                                                    <td>{Moment(props.kidObject && props.kidObject.attributes.exitFeedbackSubmittedOn).format("DD MMM YYYY")}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>
                                </div>

                            ) : (
                                <div style={{ position: 'relative', zIndex: 1 }}>
                                    <Row>
                                        <Col lg={10}>
                                            <p
                                                disabled
                                                type="text"
                                                className=" m-2 ml-4 text-primary"
                                                id="linkarea"
                                            >
                                                {getFeedbackShareLink()}
                                            </p>
                                        </Col>
                                        <Col
                                            lg={2}
                                            id="enquiryLink"
                                            onClick={(e) => {
                                                copyFeedbackShareLink(getFeedbackShareLink());
                                            }}
                                        >
                                            <div className="px-2 border">
                                                <div className="d-flex flex-column align-items-center">
                                                    <h1
                                                        style={{ cursor: "pointer" }}
                                                        className="text-primary"
                                                    >
                                                        <i className="bx bxs-copy"></i>
                                                    </h1>
                                                </div>
                                                <p className='' style={{ cursor: "pointer", marginTop: '30px' }}>copy</p>
                                                <UncontrolledTooltip
                                                    placement="auto"
                                                    target="enquiryLink"
                                                >
                                                    copy to clipboard
                                                </UncontrolledTooltip>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )}

                            {!props.kidObject.attributes.exitFeedbackSubmittedOn && (<p className="m-4">
                                <b>Note:</b> This link will be valid until the parent submits. </p>)}
                        </div>}
                        {loading == -1 && <p className='text-danger text-center'>No Data Found</p>}
                        {loading == 1 && <p className='text-succes text-center'>Loading...</p>}
                    </ModalBody>

                    <ModalFooter>
                        <Row>
                            <Col>
                                {(!props.fatherMobileNo || !props.motherMobileNo) && props.kidObject && !props.kidObject.attributes.exitFeedbackSubmittedOn && <button
                                    type="button"
                                    className="btn btn-success btn-sm m-1"
                                    onClick={() => {
                                        let link = getFeedbackShareLink();
                                        const message = `Dear Parent,\n\nPlease take a moment to share your thoughts through this feedback form on our institutes\n\nPlease click the link below to fill it:\n${link}\n\nPowered by ${WhiteLable.getAppName()}`;
                                        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
                                        window.open(whatsappUrl);
                                        // web.whatsapp.com
                                    }}
                                ><a className="bx bxl-whatsapp text-white"></a>Send via Whatsapp</button>}
                                {props.motherMobileNo && !props.kidObject.attributes.exitFeedbackSubmittedOn && <button
                                    type="button"
                                    className="btn btn-success btn-sm m-1"
                                    onClick={() => {
                                        let phoneNumber = props.motherMobileNo;
                                        let link = getFeedbackShareLink();
                                        const message = `Dear Parent,\n\nPlease take a moment to share your thoughts through this feedback form on our institutes\n\nPlease click the link below to fill it:\n${link}\n\nPowered by ${WhiteLable.getAppName()}`;
                                        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
                                        window.open(whatsappUrl);
                                        // web.whatsapp.com
                                    }}
                                ><a className="bx bxl-whatsapp text-white"></a>Mother</button>}
                                {props.fatherMobileNo && !props.kidObject.attributes.exitFeedbackSubmittedOn && <button
                                    type="button"
                                    className="btn btn-success btn-sm m-1"
                                    onClick={() => {
                                        let phoneNumber = props.fatherMobileNo;
                                        let link = getFeedbackShareLink();
                                        const message = `Dear Parent,\n\nPlease take a moment to share your thoughts through this feedback form on our institutes\n\nPlease click the link below to fill it:\n${link}\n\nPowered by ${WhiteLable.getAppName()}`;
                                        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
                                        window.open(whatsappUrl);
                                        // web.whatsapp.com
                                    }}
                                ><a className="bx bxl-whatsapp text-white"></a>Father</button>}
                                <button
                                    type="button"
                                    onClick={() => {
                                        props.closeFeedbackFormPopup()
                                    }}
                                    className="btn btn-secondary waves-effect btn-sm m-1"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default feedbackFormPopup
